import {ChangeEvent, Fragment, FC, useCallback, useMemo, useState, useEffect} from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import {Form, Checkbox, Button} from 'components/form';
import Icon from 'components/icon';
import {numberToMoney} from 'helpers/numbers';
import {otherLinkTypes, personalTypesLinks} from 'const';
import {
  SectionData, TableData,
  TableDataItem, TableDataYear,
  TableSection
} from 'hooks/common/mtdcAdapter';
import styles from './collapseTable.module.css';
import {Link} from 'react-router-dom';
import {get, concat, set} from 'lodash';
import useUI from 'hooks/useUI';

type Props = {
  data: TableData;
  rows: TableSection[];
  grantId: string;
  onBlur: (data: IUpdateMTDCFlagsInput) => void;
  onSubmit: (data: IUpdateMTDCFlagsInput) => void;
  loading: boolean;
}

const labels:Record<string, string> = {
  otherDirectCosts: 'Other Direct Costs',
  personnel: 'Personnel',
  equipment: 'Equipment',
  domestic: 'Domestic travels',
  foreign: 'Foreign travels',
  postDocPersonnel: 'Postdoctoral Research Associates',
  seniorPersonnel: 'Senior Personnel',
  academicResearchAssociate: 'Academic Research Associates',
  graduateStudents: 'Graduate students',
  undergraduateStudents: 'Undergraduate Students',
  civilService: 'Civil Service',
  unionPersonnel: 'Union',
  tempAndCasual: 'Temp and Casual',
  otherPersonnel: 'Other Personnel',
  undergraduateStudentsAcademic: 'Undergraduate Students (Academic)',
  undergraduateStudentsSummer: 'Undergraduate Students (Summer)',
  graduateStudentsStipend: 'Graduate Students (Stipend)',
  graduateStudentsTuition: 'Graduate Students (Tuition)',
  graduateStudentsSummer: 'Graduate Students (Summer)',
  graduateStudentsAcademic: 'Graduate Students (Academic)',
  materialsAndSupplies: 'Materials and Supplies',
  publications: 'Publications',
  consultantServices: 'Consultant Services',
  computerServices: 'Computer Services',
  subawards: 'Subawards',
  contracts: 'Contracts',
  incentivePayments: 'Incentive Payments',
  allOtherCosts: 'All Other Costs',
}

export type IEditMTDCFlagInput = {
  id: string;
  isMTDC: boolean;
}

export interface IUpdateMTDCFlagsInput {
  personnel?: {
    salaries?: {
      seniorPersonnel?: IEditMTDCFlagInput[];
      academicResearchAssociate?: IEditMTDCFlagInput[];
      postDocPersonnel?: IEditMTDCFlagInput[];
      civilService?: IEditMTDCFlagInput[];
      unionPersonnel?: IEditMTDCFlagInput[];
      tempAndCasual?: IEditMTDCFlagInput[];
      otherPersonnel?: IEditMTDCFlagInput[];

      undergraduateStudentsAcademic?: boolean;
      undergraduateStudentsSummer?: boolean;
      graduateStudentsStipend?: boolean;
      graduateStudentsAcademic?: boolean;
      graduateStudentsSummer?: boolean;
    };
    fringeBenefits?: {
      seniorPersonnel?: IEditMTDCFlagInput[];
      academicResearchAssociate?: IEditMTDCFlagInput[];
      postDocPersonnel?: IEditMTDCFlagInput[];
      civilService?: IEditMTDCFlagInput[];
      unionPersonnel?: IEditMTDCFlagInput[];
      tempAndCasual?: IEditMTDCFlagInput[];
      otherPersonnel?: IEditMTDCFlagInput[];

      graduateStudentsStipend?: boolean;
      graduateStudentsTuition?: boolean;
    };
  };
  equipment?: IEditMTDCFlagInput[] | Record<string, IEditMTDCFlagInput[]>;
  participantSupport?: IEditMTDCFlagInput[] | Record<string, IEditMTDCFlagInput[]>;
  travel?: {
    types?: {
      domestic: IEditMTDCFlagInput[];
      foreign: IEditMTDCFlagInput[];
    };
  }
  otherDirectCosts?: {
    materialsAndSupplies?: IEditMTDCFlagInput[];
    publications?: IEditMTDCFlagInput[];
    computerServices?: IEditMTDCFlagInput[];
    subawards?: IEditMTDCFlagInput[];
    other?: {
      contracts?: IEditMTDCFlagInput[];
      incentivePayments?: IEditMTDCFlagInput[];
      allOtherCosts?: IEditMTDCFlagInput[];
    };
  };
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const CollapseTable:FC<Props> = ({data, onSubmit, loading, onBlur, rows, grantId}) => {
  const [ closed, onChangeClosed ] = useState<string[]>([]);
  const [ opened, onChangeOpened ] = useState<string[]>([]);
  const { changeMTDCGrantId } = useUI();

  useEffect(() => {
    changeMTDCGrantId('');
  }, []);

  const [ changedMTDC, onChangeChangedMTDC ] = useState<IUpdateMTDCFlagsInput>({});
  const special = useMemo(() => ([
    'undergraduateStudentsAcademic',
    'undergraduateStudentsSummer',
    'graduateStudentsStipend',
    'graduateStudentsAcademic',
    'graduateStudentsSummer',
    'graduateStudentsStipend',
    'graduateStudentsTuition',
  ]), []);

  const preview = useCallback((data: IUpdateMTDCFlagsInput) => {
    const submitData = {
      ...data,
      equipment: concat(...Object.values(data.equipment || {})),
      participantSupport: concat(...Object.values(data.participantSupport || {})),
    };
    onBlur(submitData);
  }, [onBlur]);

  const submit = useCallback(() => {
    const submitData = {
      ...changedMTDC,
      equipment: concat(...Object.values(changedMTDC.equipment || {})),
      participantSupport: concat(...Object.values(changedMTDC.participantSupport || {}))
    };
    onSubmit(submitData);
  }, [changedMTDC, onSubmit]);

  const makeLink = useCallback((item: TableDataItem, section: string) => {
    if (['personnel.salaries', 'personnel.fringeBenefits'].includes(section)) {
      const personnelLink = personalTypesLinks[item.key];
      return `/grants/${grantId}/personnel/${personnelLink}/${item.id}/edit`;
    }
    if (['otherDirectCosts', 'other'].includes(section)) {
      const odcLink = otherLinkTypes[item.key];
      return `/grants/${grantId}/otherDC/${odcLink}/${item.id}/edit`;
    }
    if (['travel'].includes(section)) {
      return `/grants/${grantId}/travel/grantPersonnel/${item.id}/edit`;
    }
    if (['participantSupport'].includes(section)) {
      return `/grants/${grantId}/events/${item.id}/edit`;
    }
    return `/grants/${grantId}/${section}/${item.id}/edit`;
  }, [grantId]);

  const handleChangeMTDC = useCallback((way: string, key: string, id: string, isMTDC: boolean) => {
    const existingMTDC:IEditMTDCFlagInput[] = get(changedMTDC, [...way.split('.'), key], []);
    const overdriveMTDC = existingMTDC.map((item) => item.id).includes(id)
      ? existingMTDC.map((item) => item.id === id ? ({...item, isMTDC}) : item)
      : [...existingMTDC, {id, isMTDC }];
    const newMTDC = set({...changedMTDC}, [...way.split('.'), key], overdriveMTDC);
    onChangeChangedMTDC(newMTDC);
    preview(newMTDC);
  }, [preview, changedMTDC]);

  const handleChangeMTDCSection = useCallback((section: SectionData, isMTDC: boolean) => {
    if (special.includes(section.name)) {
      const existingSection = get(changedMTDC, section.section.split('.'), {});
      const newMTDC = set({...changedMTDC}, section.section.split('.'), {
        ...existingSection,
        [section.name]: isMTDC
      });
      onChangeChangedMTDC(newMTDC);
      preview(newMTDC);
      return;
    }
    const newSectionMTDCs = section.items.reduce((res: Record<string, IEditMTDCFlagInput[]>, item: TableDataItem) => {
      const existingItems = res[item.key] || [];
      return {
        [item.key]: [...existingItems, {id: item.id, isMTDC}]
      }
    }, {});
    const existingSection = get(changedMTDC, section.section.split('.'), {});
    const newMTDC = set({...changedMTDC}, section.section.split('.'), {
      ...existingSection,
      ...newSectionMTDCs
    });
    onChangeChangedMTDC(newMTDC);
    preview(newMTDC);
  }, [preview, special, changedMTDC]);

  const handleClick = useCallback((id: string) => {
    const newClosed = closed.includes(id) ? closed.filter((item: string) => item !== id) : [...closed, id];
    onChangeClosed(newClosed);
  }, [closed]);

  const setBackToMTDC = useCallback(() => {
    changeMTDCGrantId(grantId);
  }, [grantId, changeMTDCGrantId]);

  const handleClickSubsection = useCallback((id: string) => {
    const newOpened = opened.includes(id) ? opened.filter((item: string) => item !== id) : [...opened, id];
    onChangeOpened(newOpened);
  }, [opened]);

  const list = useMemo(() => {
    return rows.map((item: TableSection, idx: number) => {
      const sectionsA = Object.values(item.sections);
      return (
        <tbody key={idx}>
          <tr key={`section-${idx}`}>
            <td className={cx(styles.icon, styles.first, {[styles.closed]: closed.includes(`section-${idx}`)})}>
              <Icon icon={closed.includes(`section-${idx}`) ? 'plus-square' : 'minus-square'}
                    onClick={() => handleClick(`section-${idx}`)} size={20}
              />
            </td>
            <td className={cx(styles.icon, styles.dummy)}></td>
            <td className={cx(styles.black, styles.big)} colSpan={data.years.length + 2}>
              {`${alphabet[idx]}. ${item.name}`}
            </td>
            <td className={styles.chbox}/>
          </tr>
          {
            sectionsA.map((section: SectionData, secIdx: number) => {
              let checkedItemsInMTDC = section.items.reduce((res: number, item: TableDataItem) => res + (item.isMTDC ? 1 : 0), 0);
              let isSectionChecked = special.includes(section.name) ? section.totalsItem.isMTDC : (checkedItemsInMTDC > 0 && checkedItemsInMTDC === section.items.length);
              const checkedItemsInState = get(changedMTDC, [...section.section.split('.'), section.items[0]?.key], []).reduce((res: number, item: IEditMTDCFlagInput) => res + (item.isMTDC ? 1 : 0), 0);
              let isSectionCheckedInState = checkedItemsInState > 0 && checkedItemsInState === section.items.length;
              if (special.includes(section.name)) {
                isSectionCheckedInState = get(changedMTDC, [...section.section.split('.'), section.name]);
              }
              const disabled = !special.includes(section.name) && section.items.length < 1;
              return (
                <Fragment key={`section-${idx}-${secIdx}`}>
                  <tr className={cx({[styles.hide]: closed.includes(`section-${idx}`)})}>
                    <td className={cx(styles.icon, {
                      [styles.last]: secIdx === sectionsA.length - 1 && closed.includes(`section-${idx}-${secIdx}`)
                    })}/>
                    <td
                      className={cx(styles.icon, {[styles.closed]: !opened.includes(`section-${idx}-${secIdx}`) || section.items.length < 1})}>
                      {section.items.length > 0
                        ? <Icon onClick={() => handleClickSubsection(`section-${idx}-${secIdx}`)}
                                icon={!opened.includes(`section-${idx}-${secIdx}`) ? 'plus-square' : 'minus-square'}
                                size={20}/>
                        : null
                      }
                    </td>
                    <td className={cx(styles.name, {[styles.disabled]: disabled})}>{labels[section.name] || section.name}</td>
                    {section.totalsItem.years.map((year: TableDataYear, yearIdx: number) => (
                      <td className={cx(styles.value, {[styles.disabled]: disabled})} key={yearIdx}>{numberToMoney(year.value)}</td>
                    ))}
                    <td className={cx(styles.value, {[styles.disabled]: disabled})}>{numberToMoney(section.totalsItem.totals)}</td>
                    <td className={styles.chbox}>
                      <Checkbox value={isSectionCheckedInState || isSectionChecked} disabled={disabled}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeMTDCSection(section, event.target.checked)}
                                name={`section-${idx}-${secIdx}-checkbox`}/>
                    </td>
                  </tr>
                  {
                    section.items.map((item: TableDataItem, itIdx: number) => {
                      const sectionMTDC:IEditMTDCFlagInput[] = get(changedMTDC, [...section.section.split('.'), item.key], []);
                      const isCheckedInState = sectionMTDC.find((sectionItem) => sectionItem.id === item.id)?.isMTDC;
                      return (
                        <Fragment key={`section-${idx}-${secIdx}-${itIdx}`}>
                          {
                            item.key === 'subawards'
                              ?
                                <Fragment>
                                  <tr
                                    className={cx({[styles.hide]: closed.includes(`section-${idx}`) || !opened.includes(`section-${idx}-${secIdx}`)})}
                                  >
                                    <td className={styles.icon}/>
                                    <td className={styles.icon}/>
                                    <td className={cx(styles.greenName, styles.grey)} colSpan={data.years.length + 2}>
                                      <Link to={makeLink(item, section.section)} onClick={setBackToMTDC}>
                                        Subaward {itIdx + 1}
                                      </Link>
                                    </td>
                                    <td className={cx(styles.grey, styles.chbox)}/>
                                  </tr>

                                  <tr
                                    className={cx({[styles.hide]: closed.includes(`section-${idx}`) || !opened.includes(`section-${idx}-${secIdx}`)})}
                                  >
                                    <td className={styles.icon}/>
                                    <td className={styles.icon}/>
                                    <td className={styles.primary}>
                                      Initial value
                                    </td>
                                    {item.years.map((year: TableDataYear, yearIdx: number) => (
                                      <td className={cx(styles.value, styles.primary)} key={yearIdx}>{numberToMoney(year.initialValue || 0)}</td>
                                    ))}
                                    <td className={cx(styles.value, styles.primary)}>{numberToMoney(item.initialTotals || 0)}</td>
                                    <td className={cx(styles.primary, styles.chbox)}/>
                                  </tr>

                                  <tr
                                    className={cx({[styles.hide]: closed.includes(`section-${idx}`) || !opened.includes(`section-${idx}-${secIdx}`)})}
                                  >
                                    <td className={styles.icon}/>
                                    <td className={styles.icon}/>
                                    <td className={styles.grey}>
                                      Can be included in MTDC
                                    </td>
                                    {item.years.map((year: TableDataYear, yearIdx: number) => (
                                      <td className={cx(styles.value, styles.grey)} key={yearIdx}>{numberToMoney(year.value)}</td>
                                    ))}
                                    <td className={cx(styles.value, styles.grey)}>{numberToMoney(item.totals)}</td>
                                    <td className={cx(styles.grey, styles.chbox)}>
                                      <Checkbox value={isCheckedInState ?? item.isMTDC} name={`section-${idx}-${secIdx}-${itIdx}-checkbox-${item.isMTDC || isCheckedInState}`}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeMTDC(section.section, item.key, item.id, event.target.checked)}
                                      />
                                    </td>
                                  </tr>
                                </Fragment>
                              :
                                <tr
                                  className={cx({[styles.hide]: closed.includes(`section-${idx}`) || !opened.includes(`section-${idx}-${secIdx}`)})}
                                >
                                  <td className={styles.icon}/>
                                  <td className={styles.icon}/>
                                  <td className={styles.greenName}>
                                    <Link to={makeLink(item, section.section)} onClick={setBackToMTDC}>
                                      {item.name || 'N/A'}
                                    </Link>
                                  </td>
                                  {item.years.map((year: TableDataYear, yearIdx: number) => (
                                    <td className={cx(styles.value, styles.grey)} key={yearIdx}>{numberToMoney(year.value)}</td>
                                  ))}
                                  <td className={cx(styles.value, styles.grey)}>{numberToMoney(item.totals)}</td>
                                  <td className={cx(styles.grey, styles.chbox)}>
                                    <Checkbox value={isCheckedInState ?? item.isMTDC} name={`section-${idx}-${secIdx}-${itIdx}-checkbox-${item.isMTDC || isCheckedInState}`}
                                              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeMTDC(section.section, item.key, item.id, event.target.checked)}
                                    />
                                  </td>
                                </tr>
                            }
                        </Fragment>
                      )
                    })
                  }
                </Fragment>
              )
            })
          }
          {item.totalsItem
            ?
              <tr key={`section-${idx}-totals`} className={cx({[styles.hide]: closed.includes(`section-${idx}`)})}>
                <td className={styles.icon}/>
                <td className={styles.icon}/>
                <td className={cx(styles.green, styles.big)}>{item.totalsItem.name}</td>
                {item.totalsItem.years.map((year: TableDataYear, yearIdx: number) => (
                  <td className={cx(styles.value, styles.green)} key={yearIdx}>{numberToMoney(year.value)}</td>
                ))}
                <td className={cx(styles.value, styles.green)}>{numberToMoney(item.totalsItem.totals)}</td>
                <td className={styles.chbox}/>
              </tr>
            : null
          }
        </tbody>
      )
    });
  }, [
    rows, opened, special, handleChangeMTDCSection,
    changedMTDC, handleChangeMTDC, makeLink, setBackToMTDC,
    handleClick, closed, data, handleClickSubsection
  ]);


  return (
    <Form>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
          <tr>
            <td colSpan={3}></td>
            {
              data.years.map((year: TableDataYear, idx: number) => (
                <td key={idx} className={styles.yearCell}>
                  <span className={styles.yearWrapper}>
                    <span className={styles.yearTitle}>Year {year.serialNumber}</span>
                    <span className={styles.year}>
                      <span>{dayjs(year.year[0]).format('MM/DD/YYYY')}</span>
                      <span>{dayjs(year.year[1]).format('MM/DD/YYYY')}
                    </span>
                    </span>
                  </span>
                </td>
              ))
            }
            <td>Total</td>
            <td>Attributed  to MTDC</td>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td className={cx(styles.icon, styles.dummy)}/>
              <td className={cx(styles.icon, styles.dummy)} />
              <td className={cx(styles.green, styles.big)}>{data.totalsItem.name}</td>
              {data.totalsItem.years.map((year: TableDataYear, yearIdx: number) => (
                <td className={cx(styles.value, styles.green)} key={yearIdx}>{numberToMoney(year.value)}</td>
              ))}
              <td className={cx(styles.value, styles.green)}>{numberToMoney(data.totalsItem.totals)}</td>
              <td className={styles.chbox}/>
            </tr>
          </tbody>
          {list}
        </table>
      </div>
      <div className={styles.footer}>
        <Button htmlType="submit" onClick={submit} loading={loading} name="save-mtdc">Save</Button>
      </div>
    </Form>
  );
}

export default CollapseTable;

