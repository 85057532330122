import { isNaN } from 'lodash';
import numeral from 'numeral';

export const percentToFixed = (value: string | number = 0):any => {
  const number = Number(value);
  if (isNaN(number)) return 0;
  return Number(number * 100).toFixed(2);
}

export const numberToMoney = (value: string | number | undefined, format?: string):string => {
  if (value === '' || value === null || value === undefined) return '';
  return `$${numeral(value).format(format ?? '0,0.00')}`;
}
