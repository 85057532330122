type Action<T> = {
  type: string,
  payload?: T,
};

function makeActionDefault<T>(payload?: T) {
  return {
    payload,
  };
}

function makeActionCreator<T>(type: string, makeAction: (payload?: T) => {}) {
  const actionCreator = (arg?: T): Action<T> => ({ ...makeAction(arg), type });
  return Object.assign(actionCreator, { type });
}

export function createAction<T>(
  base: string,
  makeAction: (payload?: T) => {} = makeActionDefault,
) {
  return makeActionCreator(base, makeAction);
}
