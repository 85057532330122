import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import { uniq, floor, times } from 'lodash';
import dayjs from 'dayjs';
import Field from 'components/field';
import Month from 'pages/grant/one/travel/cost/common/month';
import { Input, Form, Button, Checkbox } from 'components/form';
import styles from './fourtyElight.module.css';
import {TravelCostLodgingData, TravelCostLodgingMonth} from 'store/grants/types';
import {ListItem} from 'helpers/utils';
import useTravel from 'hooks/useTravel';
import { FormProps } from '../index';
const allMonths:ListItem[] = [
  "January", "February", "March", "April",
  "May", "June", "July", "August",
  "September", "October", "November", "December"
].map((item: string, idx: number) => ({ value: idx + 1, label: item }));

const getNights = (startDate: string, endDate: string, month: number, part: boolean) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const endMonth = end.month() + 1;
  const startMonth = start.month() + 1;
  if (part) {
    if (month === endMonth) {
      const value = end.diff(end.startOf('month'), 'days') + 1 ?? 1;
      return value < 1 ? 1 : value;
    }
    if (month === startMonth) {
      const value = start.endOf('month').diff(start, 'days');
      return value < 1 ? 1 : value;
    }
  }
  return dayjs().month(month - 1).daysInMonth();
}

const FortyEightLodgingForm:FC<FormProps> = ({cost, travelId, grantId}) => {
  const { updateTravelCost, previewTravelCost, errors, loading,trackError,
    trackExit,
    trackFormStarted } = useTravel({
    form_page_type: "Lodging Breakdown"
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultMonthData:TravelCostLodgingMonth = useMemo(() => ({
    month: '',
    index: 0,
    dailyRate: '',
    nights: '',
    monthTotal: '',
  }), []);

  const monthsOptions = useMemo(() => {
    if (cost.startDate && cost.endDate) {
      const startMonth = dayjs(cost.startDate).month() + 1;
      const diff = Math.ceil(dayjs(cost.endDate).diff(dayjs(cost.startDate), 'months', true)) + 1;
      if (diff > 12) return allMonths;
      const months = uniq(times(diff, (n: number) => {
        const month = n + startMonth;
        if (month > 12) {
          const roundedAfterYearMonth = month - 12 * (floor(month/12));
          return roundedAfterYearMonth < 1 ? 12 : roundedAfterYearMonth;
        }
        return month;
      }));
      return allMonths.filter((item: ListItem) => months.includes(item.value));
    }
    return allMonths;
  }, [cost]);

  const defaultMonths = useMemo(() => {
    if (cost.startDate && cost.endDate && cost.isStartEndDateKnown) {
      const startMonth = dayjs(cost.startDate).month() + 1;
      const endMonth = dayjs(cost.endDate).month() + 1;
      const floatDiff = dayjs(cost.endDate).diff(dayjs(cost.startDate), 'months', true);
      const diff = Math.ceil(floatDiff);
      const months = times(diff >= 12 ? diff + 1 : diff, (n: number) => {
        const month = n + startMonth;
        if (month > 12) {
          const roundedAfterYearMonth = month - 12 * (floor(month/12));
          return roundedAfterYearMonth < 1 ? 12 : roundedAfterYearMonth;
        }
        return month;
      });
      if (startMonth === endMonth && diff < 12) {
        return [
          {
            ...defaultMonthData,
            month: startMonth,
            nights: dayjs(cost.endDate).diff(dayjs(cost.startDate), 'days'),
            index: 0
          }
        ]
      }
      if (startMonth !== endMonth && diff < 2) {
        return [
          {
            ...defaultMonthData,
            month: startMonth,
            nights: dayjs(cost.startDate).endOf('month').diff(dayjs(cost.startDate), 'days'),
            index: 0
          },
          {
            ...defaultMonthData,
            month: endMonth,
            nights: dayjs(cost.endDate).diff(dayjs(cost.endDate).startOf('month'), 'days'),
            index: 1
          }
        ]
      }
      return months.map((month: number, idx: number) => ({
        ...defaultMonthData,
        month: month,
        nights: getNights(cost.startDate, cost.endDate, month, idx < 1 || idx === months.length - 1),
        index: idx
      }));
    }
    return [defaultMonthData];
  } , [defaultMonthData, cost]);

  const [ data, onChangeData ] = useState<TravelCostLodgingData>({
    months: cost.lodgingBreakdown.months.length > 0 ? cost.lodgingBreakdown.months : defaultMonths,
    doBudgetMaxAmount: cost.lodgingBreakdown.doBudgetMaxAmount ?? false,
    maxTotalAdjust: cost.lodgingBreakdown.maxTotalAdjust ?? '',
    maxTotal: cost.lodgingBreakdown.maxTotal ?? '',
  });

  const canBlur = useCallback((data: TravelCostLodgingData) => {
    if (data.doBudgetMaxAmount && data.maxTotalAdjust < 1) return false;
    let monthAreValid = true;
    data.months.forEach((item: TravelCostLodgingMonth) => {
      const isValid = item.month > 0 && item.nights > 0;
      if (!isValid) monthAreValid = false;
    });
    return monthAreValid;
  }, []);

  const onBlur = useCallback((event?: ChangeEvent<HTMLInputElement>, value?: TravelCostLodgingData) => {
    const submitData = value ?? data;
    const blurIsAvailable = canBlur(submitData);
    if (!blurIsAvailable) return;
    if (grantId && cost) {
      previewTravelCost(grantId, travelId, cost.id, {
        lodgingBreakdown: submitData
      }, (response: any) => {
        onChangeData({
          ...submitData,
          ...response.lodgingBreakdown
        });
      });
    }
  }, [previewTravelCost, canBlur, data, cost, grantId, travelId]);

  const onChangeMonth = useCallback((idx: number) => (field: Record<string, any>, preview?: boolean) => {
    const nextMonths = data.months.map((item: TravelCostLodgingMonth) => {
      if (item.index === idx) return {
        ...item,
        ...field
      }
      return item;
    });
    onChangeData({
      ...data,
      months: nextMonths
    });
    if (preview) {
      onBlur(undefined, {
        ...data,
        months: nextMonths
      });
    }
  }, [data, onBlur]);

  const onDeleteMonth = useCallback((idx: number) => {
    const newData = {
      ...data,
      months: data.months.filter((item: TravelCostLodgingMonth) => item.index !== idx)
    };
    onChangeData(newData);
    onBlur(undefined, newData)
  }, [onBlur, data]);

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onAddMonth = useCallback(() => {
    const [tail] = [...data.months].reverse();
    onChangeData({
      ...data,
      months: [...data.months, {...defaultMonthData, index: tail.index + 1}]
    });
  }, [defaultMonthData,data]);

  const handleChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const doBudgetMaxAmount = event.target.checked;
    onChangeData({
      ...data,
      doBudgetMaxAmount,
      maxTotalAdjust: doBudgetMaxAmount ? data.maxTotal : ''
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    //@ts-ignore
    updateTravelCost(grantId, travelId, cost.id, {
      lodgingBreakdown: data
    });
  }, [data, cost, updateTravelCost, grantId, travelId]);

  const maxLodgingHelp = (
    <span>
      Based on the numbers entered, we calculated the maximum amount possible for lodging.
      However, if you want to change this value, uncheck the box and enter the adjusted value in the Adjusted max lodging total.
      Please note that the value entered may not be greater than Max lodging total.
    </span>
  );

  const disabled = useMemo(() => {
    if (data.doBudgetMaxAmount) {
      return data.maxTotalAdjust > data.maxTotal;
    }
    return false;
  }, [data]);

  const maxTotalError = ["The entered value is over the maximum amount allowed according to the website. Please double-check to ensure the values for each daily rate and number of nights are correct."];
  const totalHelp = (
    <span>
      Please double check if the total lodging amount displayed here, is the same as the amount you received on the <a href="https://www.gsa.gov/" target="_blank" rel="noreferrer">Home</a> website.
      If not please go back and double check the number you input.
    </span>
  );
  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      {data.months.map((item: TravelCostLodgingMonth, idx: number) => (
        <Month data={item} onBlur={onBlur} errors={errors} field="lodgingBreakdown" index={idx} months={monthsOptions} key={item.index} onChange={onChangeMonth(item.index)}
               onDelete={data.months.length === 1 ? undefined : () => onDeleteMonth(item.index)}
        />
      ))}

      <div className={styles.addWrapper}>
        <Button onClick={onAddMonth} name="add-month" preIcon="plus" htmlType="button">Add month</Button>
      </div>

      <Field withHelp last>
        <Input name="maxTotal" errors={errors['lodgingBreakdown.maxTotal']} type="number"
               value={data.maxTotal} disabled readOnly help={totalHelp} helpDebounce={3000}
               label="Max lodging total" placeholder="Enter max lodging total" money

        />
        <Checkbox name="doBudgetMaxAmount" label="I want to budget the maximum amount possible for lodging"
                  value={data.doBudgetMaxAmount} field
                  onChange={handleChangeCheckbox}
        />
      </Field>
      <Field withHelp last>
        <Input name="maxTotalAdjust" errors={disabled ? maxTotalError : errors['lodgingBreakdown.maxTotalAdjust']} type="number"
               help={maxLodgingHelp} helpDebounce={1000}
               value={data.maxTotalAdjust}
               max={Number(data.maxTotal)} money
               disabled={!data.doBudgetMaxAmount}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({maxTotalAdjust: event.target.value})}
               label="Adjusted max lodging total" placeholder="Enter adjusted max lodging total"

        />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} disabled={disabled} htmlType="submit" name="save-travel-lodging">Save</Button>
      </div>
    </Form>
  );
}

export default FortyEightLodgingForm;
