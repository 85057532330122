import mixpanel from 'mixpanel-browser';

interface IUserTrackingData {
    email?: string;
    firstName?: string;
    lastName?: string;
}

export const trackUserAction = (userAction: string ) => {
    return mixpanel.track(userAction)
}

export const identifyUserForTracking = (userId: string ) => {
    return mixpanel.identify(userId)
}

export const setUserTrackingData = ({ email, firstName, lastName}: IUserTrackingData ) => {
    return mixpanel.people.set_once({ "$name": `${firstName} ${lastName}`, "$first_name": firstName, "$last_name": lastName , "$email": email });
}
export const stopUserTracking = () => {
    return mixpanel.reset()
}
export const trackUserPageview = (pageName: string) => {
    return mixpanel.track_pageview({ page: pageName });
}
