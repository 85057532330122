import {FC, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
import {CivilService, CivilServiceNSF, FullGrant, PersonalNSFCompensation, SeniorPersonal} from 'store/grants/types';
import useUrl from 'hooks/useUrl';
import useEnums from 'hooks/useEnums';
import {ListItem} from 'helpers/utils';
import Item from 'pages/grant/paper/card/item';

type Props = {
  person: SeniorPersonal;
  active?: boolean;
  to: string;
  type: string;
  side: FullGrant;
  onDelete: () => void;
  grantId: string;
  idx: number;
  emptyPlaceholder: string;
}

const Person: FC<Props> = ({person, side, onDelete, to, type, idx, grantId, active, emptyPlaceholder}) => {
  const url = useUrl();
  const { personRoles } = useEnums();

  const name = useMemo(() => {
    if (person.firstName && person.lastName) {
     return `${person.firstName[0]}. ${person.lastName}`
    }
    return 'Unnamed person'
  }, [person]);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);


  const role = useMemo(() => {
    if (person.role) {
      const role = personRoles.find((item: ListItem) => item.value === person.role);
      return role?.label ?? undefined;
    }
    return undefined
  }, [personRoles, person]);

  const links = [
    {
      to: to,
      label: 'Position info',
      active: active && url.section === 'edit',
    },
    ...(
      person.isRequestingSupport && person.nsfCompensation.length > 0
      ? person.nsfCompensation.map((item: PersonalNSFCompensation, idx) => ({
        to: `/grants/${grantId}/personnel/${type}/${person.id}/compensation/${idx}`,
        label: `${dayjs(side.years[idx][0]).format('MM/DD/YYYY')} - ${dayjs(side.years[idx][1]).format('MM/DD/YYYY')}`,
        active: active && url.sectionId === `${idx}`
      }))
      : []
    ),
  ];

  return (
    <Item
      title={name}
      icon={person.isAccountOwner ? 'check-verified-02' : undefined}
      iconText={person.isAccountOwner ? 'Marked as Account owner' : undefined}
      role={role}
      onDelete={handleDelete}
      links={links}
      active={active}
    />
  );
}

type OtherProps = Props & {
  person: CivilService;
}

export const OtherPerson: FC<OtherProps> = ({person, side, onDelete, to, type, idx, grantId, active, emptyPlaceholder}) => {
  const url = useUrl();

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const isYearsDisabled = useMemo(() => {
    switch (type) {
      // @ts-ignore
      case 'post-doc': return !Boolean(person.personsFringeBenefit)
      default: return !Boolean(person.fringeBenefit)
    }
  }, [person, type]);

  const links = [
    {
      to: to,
      label: 'Position info',
      active: active && url.section === 'edit',
    },
    ...person.nsfCompensation.map((item: CivilServiceNSF, idx) => ({
      to: `/grants/${grantId}/personnel/${type}/${person.id}/compensation/${idx}`,
      label: `${dayjs(side.years[idx][0]).format('MM/DD/YYYY')} - ${dayjs(side.years[idx][1]).format('MM/DD/YYYY')}`,
      active: active && url.sectionId === `${idx}`,
      isDisabled: isYearsDisabled
    })),
  ];

  return (
    <Item
      icon={person.isAccountOwner ? 'check-verified-02' : undefined}
      iconText={person.isAccountOwner ? 'Marked as Account owner' : undefined}
      title={person.positionTitle || 'Untitled position'}
      onDelete={handleDelete}
      links={links}
      active={active}
    />
  );
}

export default Person;
