import {ChangeEvent, FC, useState} from 'react';
import cx from 'classnames';
import styles from './subawardee.module.css';
import { numberToMoney } from 'helpers/numbers';

type Props = {
  name: string;
  placeholder?: string;
  value: any;
  min?: any;
  disabled?: boolean;
  max?: any;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Input:FC<Props> = (props: Props) => {
  const {
    name, min = 0, max, placeholder = 'Enter value',
    value, onChange, type,
    className, disabled
  } = props;
  const [ focus, onChangeFocus ] = useState<boolean>(false);

  if (type) {
    return (
      <input
        type={type}
        name={name}
        min={min}
        disabled={disabled}
        max={max}
        placeholder={placeholder}
        onChange={onChange}
        className={cx(className, styles.input)}
        value={value ?? ''}
      />
    );
  }

  return (
    <input
      type={focus ? 'number' : 'text'}
      name={name}
      disabled={disabled}
      min={0}
      onChange={onChange}
      placeholder={placeholder}
      className={cx(className, styles.input)}
      onFocus={() => onChangeFocus(true)}
      onBlur={() => onChangeFocus(false)}
      value={focus ? value : numberToMoney(value)}
    />
  );
}

export default Input;
