import {FC, ReactElement} from 'react';
import cx from 'classnames';
import styles from './card.module.css';

type Props = {
  children: ReactElement | ReactElement[];
  className?: string;
}

const Card:FC<Props> = ({ children, className }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      {children}
    </div>
  )
}

export default Card;
