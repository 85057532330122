import {
  Redirect
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { FC } from 'react';

type Props = {
  children: any;
}
const PaidRoute:FC<Props> = ({ children }) => {
  const { user } = useAuth();

  if (!user.paid) {
    return <Redirect from="*" to="/grants" />;
  }

  return user.id ? children : null;
};

export default PaidRoute;
