import {ChangeEvent, FC, useCallback} from 'react';
import cx from 'classnames';
import {Input, Button, Form, Radio, Select} from 'components/form'
import styles from './personForm.module.css';
import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import useEnums from 'hooks/useEnums';
import {FieldErrors} from "react-hook-form";

type Props = {
  data: T.GraduatedStudent;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  edit: boolean;
  onChange: (field: Record<string, any>) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const GraduatedStudentPersonForm: FC<Props> = ({
                                                 trackExit,
                                                 trackError,
                                                 data,
                                                 edit,
                                                 errors,
                                                 loading,
                                                 onSubmit,
                                                 onChange
                                               }) => {

  const {annualSchedules} = useEnums();
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form prompt onSubmit={handleSubmit} className={styles.wrapper} trackError={trackError} trackExit={trackExit}>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Select name="academicTerm" label="Type of Academic Term" required placeholder="Select type academic term"
                className={styles.input} value={data.academicTerm} options={annualSchedules}
                errors={errors.academicTerm}
                onChange={(academicTerm: any) => onChange({academicTerm})}
        />
        <Input name="numberOfAcademicTerms" type="number" label="Number of Academic Terms (excluding summer term)"
               required placeholder="Enter number of academic terms"
               className={styles.input} value={data.numberOfAcademicTerms} errors={errors.numberOfAcademicTerms}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfAcademicTerms: event.target.value})}/>
      </div>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="fringeBenefit" type="number" label="Fringe Benefit Rate" required
               placeholder="Enter fringe benefit rate"
               className={styles.input} value={data.fringeBenefit} errors={errors.fringeBenefit}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({fringeBenefit: event.target.value})}
        />
      </div>
      <div className={styles.fieldWithRadiosAndInputs}>
        <div>
          <Radio name="stipendAwardPeriod" value="annual" checked={data.stipendAwardPeriod === 'annual'}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({stipendAwardPeriod: event.target.value})}
                 errors={errors.stipendAwardPeriod}
                 label="Stipends are awarded annually" required/>
          <Input name="stipendPerYear"
                 help="This number is likely set by your department. It is import to use this value"
                 disabled={data.stipendAwardPeriod !== 'annual'} label="Stipend per Year"
                 required={data.stipendAwardPeriod === 'annual'}
                 placeholder="Enter stipend per year" money type="number"
                 className={cx(styles.fullInput, styles.pl20)} value={data.stipendPerYear}
                 errors={errors.stipendPerYear}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({stipendPerYear: event.target.value})}
          />
        </div>
        <Radio name="stipendAwardPeriod" errors={errors.stipendAwardPeriod} value="academicTerm"
               checked={data.stipendAwardPeriod === 'academicTerm'}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({stipendAwardPeriod: event.target.value})}
               label="Stipends are awarded by academic term" required/>
        <div className={cx(styles.twoInputsFieldWithHelp, styles.pl20, styles.field, styles.withoutBorder)}>
          <Input name="stipendForEachAcademicTerm" money type="number"
                 disabled={data.stipendAwardPeriod !== 'academicTerm'} label="Stipend for Each Academic Term"
                 placeholder="Enter stipend for each academic term"
                 required={data.stipendAwardPeriod === 'academicTerm'}
                 className={styles.input}
                 help="Your department should be able to provide you with this information needed to complete the prompts below. You should use that exact value."
                 value={data.stipendForEachAcademicTerm} errors={errors.stipendForEachAcademicTerm}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({stipendForEachAcademicTerm: event.target.value})}
          />
          <Input name="stipendForSummer" money type="number" disabled={data.stipendAwardPeriod !== 'academicTerm'}
                 label="Stipend for Summer" placeholder="Enter stipend for summer"
                 required={data.stipendAwardPeriod === 'academicTerm'}
                 className={styles.input}
                 help="Your department should be able to provide you with this information needed to complete the prompts below. You should use that exact value."
                 value={data.stipendForSummer} errors={errors.stipendForSummer}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({stipendForSummer: event.target.value})}
          />
        </div>
      </div>
      <Input name="tuitionForEachAcademicTerm" money type="number" label="Tuition For Each Academic Term" required
             placeholder="Enter tuition"
             className={styles.fullInput} value={data.tuitionForEachAcademicTerm}
             errors={errors.tuitionForEachAcademicTerm}
             onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({tuitionForEachAcademicTerm: event.target.value})}
      />
      <div className={styles.footer}>
        <Button name="submit" loading={loading} htmlType="submit">{edit ? 'Save' : 'Add personnel'}</Button>
      </div>
    </Form>
  );
}

export default GraduatedStudentPersonForm;
