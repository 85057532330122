import {FC, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
// hooks
import useCostSharing from 'hooks/useCostSharing';
import useUrl from 'hooks/useUrl';
// types
import {CostSharingExpenseItemType, CostSharingExpenseYearType} from 'store/grants/costSharing/types';
// components
import Typo from 'components/typo';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
// styles
import styles from './costSharing.module.css';
import CreateBtn from 'pages/grant/paper/card/create';
import {FullGrant, SidebarTotals} from 'store/grants/types';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}
const CostSharing:FC<Props> = ({ side, totals }) => {
  const url = useUrl();
  const {isLoading, createExpense, deleteExpense} = useCostSharing();

  const isGeneralInfoFolderActive =
    url.category === 'costSharing' && url.subCategory === 'general-info';
  const isTotalFolderActive = url.category === 'costSharing' && url.subCategory === 'totals';

  const handleDeleteExpense = useCallback(
    (id?: string) => {
      if (id) deleteExpense(side.id, id, url.subCategory === id);
    },
    [deleteExpense, url, side],
  );
  const list = useMemo(() => {
    return side.costSharingExpenses.map((item: CostSharingExpenseItemType) => {
      const links = [
        {
          to: `/grants/${side.id}/costSharing/${item.id}/edit`,
          label: 'Expense info',
          active: url.subCategory === item.id && url.categoryId === 'edit'
        },
          ...item.years.map((yearItem: CostSharingExpenseYearType, idx: number) => ({
            to: `/grants/${side.id}/costSharing/${item.id}/year/${idx}`,
            label: `${dayjs(yearItem.year[0]).format('MM/DD/YYYY')} - ${dayjs(yearItem.year[1]).format('MM/DD/YYYY')}`,
            active: url.subCategory === item.id && url.categoryId === 'year' && url.section === `${idx}`,
            isDisabled: !item.type
          }))
      ]

      return (
        <Item key={item.id}
              active={url.subCategory === item.id}
              title={item.name || 'Untitled expense'}
              onDelete={() => handleDeleteExpense(item.id)}
              links={links}
        />
      )
    });
  }, [side, handleDeleteExpense, url]);

  return (
    <>
      <Anchor thin to={`/grants/${side.id}/costSharing/general-info`} active={isGeneralInfoFolderActive}>
        Cost sharing info
      </Anchor>

      {list.length > 0 ?
        list
        : (
          <Typo className={styles.placeholder} size={14} type="div">
            No expenses have been created yet
          </Typo>
        )}

      {list.length > 0 ? (
        <TotalAnchor total={totals.costSharingTotalAmount} active={isTotalFolderActive} to={`/grants/${side.id}/costSharing/totals`}>
          Totals
        </TotalAnchor>
      ) : null}

      <CreateBtn
        loading={isLoading} side={side}
        tip="To add expense, you must add grant general information"
        onClick={() => createExpense(side.id)}
        name="add-cost-sharing-expense"
      >
        Add expense
      </CreateBtn>
    </>
  )
}

export default CostSharing;
