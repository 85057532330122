import {CSSProperties, FC, ReactElement} from 'react';
import cx from 'classnames';
import styles from './tip.module.css';

type Props = {
  text: string | ReactElement;
  when?: boolean;
  top?: boolean;
  debounced?: boolean;
  left?: boolean;
  textClassName?: string;
  className?: string;
  style?: CSSProperties;
  children: ReactElement;
}

const Tip:FC<Props> = ({ text, style, debounced, top, textClassName, left, className, when = true, children }) => {
  return (
    <div className={cx(styles.wrapper, className, {
      [styles.hovered]: when,
      [styles.top]: top,
      [styles.left]: left,
      [styles.debounced]: debounced,
    })}
         style={style}
    >
      {children}
      {when ? <div className={cx(styles.text, textClassName)}>{text}</div> : null}
    </div>
  )
}

export default Tip;
