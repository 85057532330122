import {FC, useState, useCallback, ChangeEvent} from 'react';
import {Input, Autocomplete, Form, Button} from 'components/form';
import cx from 'classnames';
import styles from './form.module.css';
import * as T from 'hooks/requestTypes';
import useEnums from 'hooks/useEnums';
import {Errors} from 'helpers/errors';
import {Equip} from 'store/grants/types';
import {FieldErrors} from "react-hook-form";

type Props = {
  onSubmit: (data: T.EquipmentData) => void;
  loading: boolean;
  unit?: Equip;
  errors: Errors;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

const EquipmentForm: FC<Props> = ({
                                    trackError,
                                    trackExit,
                                    trackFormStarted, onSubmit, unit, errors, loading
                                  }) => {
  const {getEquipmentCategories, equipmentCategories, isEquipmentCategoriesLoading} = useEnums();
  const [data, onChangeData] = useState<T.EquipmentData>({
    name: unit?.name ?? '',
    category: unit?.category ?? '',
    cost: unit?.cost ?? ''
  });

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const handleSubmit = useCallback(() => {
    onSubmit(data);
  }, [onSubmit, data]);

  return (
    <Form prompt onSubmit={handleSubmit} trackFormStarted={trackFormStarted} trackError={trackError}
          trackExit={trackExit}>
      <div className={cx(styles.field, styles.twoInputFields)}>
        <Autocomplete
          value={data.category}
          options={equipmentCategories}
          onLoad={getEquipmentCategories}
          isLoading={isEquipmentCategoriesLoading}
          min={3}
          onEnter={(category: any) => handleChangeData({category})}
          onChange={(category: any) => handleChangeData({category})}
          name="category"
          errors={errors.category}
          label="Equipment category"
          required
          placeholder="Select or enter equipment category"
          className={styles.input}
        />
      </div>
      <div className={cx(styles.field, styles.last, styles.twoInputFields)}>
        <Input name="name" errors={errors.name} label="Unit Name"
               placeholder="Enter unit name" className={styles.input}
               value={data.name} required
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
        />
        <Input name="cost" money errors={errors.cost} label="Current unit cost"
               placeholder="Enter current unit cost" className={styles.input}
               value={data.cost} type="number" required
               help="This is the price of one unit of the equipment."
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({cost: event.target.value})}
        />
      </div>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit-equip">{unit ? 'Save' : 'Add'}</Button>
      </div>
    </Form>
  );
}

export default EquipmentForm;
