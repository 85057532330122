import {useCallback, FC, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
import useMaterialsSupplies from 'hooks/useMaterialsSupplies';
// components
import CreateBtn from 'pages/grant/paper/card/create';
// styles
import styles from 'pages/grant/paper/card/folders/otherDirectCosts/otherDirectCosts.module.css';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import {MaterialsSuppliesUnitType} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
import dayjs from 'dayjs';
import Typo from 'components/typo';
import {FullGrant} from 'store/grants/types';

type Props = {
  side: FullGrant;
}

const MaterialsAndSupplies:FC<Props> = ({ side }) => {
  const { createUnit, deleteUnit } = useMaterialsSupplies();
  const url = useUrl();
  const isGeneralInfoFolderActive = url.subCategory === 'materials-and-supplies' && url.categoryId === 'general-info';
  const isTotalFolderActive = url.subCategory === 'materials-and-supplies' && url.categoryId === 'totals';
  const isCreationUnitDisabled = side.otherDirectCosts.materialsAndSupplies.hasCapitalizationLevel === null;

  const handleDeleteUnit = useCallback(
    (id?: string) => {
      if (id) deleteUnit(id, side.id, url.categoryId === id);
    },
    [deleteUnit, url, side])

  const list = useMemo(() => {
    return side.otherDirectCosts.materialsAndSupplies.units.map((unit: MaterialsSuppliesUnitType) => {
      const links = [
        {
          label: 'Unit info',
          to: `/grants/${side.id}/otherDC/materials-and-supplies/${unit.id}/edit`,
          active: url.categoryId === unit.id && url.section === 'edit'
        },
        ...unit.years.map((year, idx) => ({
          label: `${dayjs(year.year[0]).format('MM/DD/YYYY')} - ${dayjs(year.year[1]).format('MM/DD/YYYY')}`,
          to: `/grants/${side.id}/otherDC/materials-and-supplies/${unit.id}/year/${idx}`,
          active: url.categoryId === unit.id && url.section === 'year' && url.sectionId === `${idx}`,
          isDisabled: !unit.name
        }))
      ]
      return (
        <Item
          title={unit.name || 'Untitled unit'}
          onDelete={() => handleDeleteUnit(unit.id)}
          links={links}
          key={unit.id}
          active={url.categoryId === unit.id}
        />);
    })
  }, [side, handleDeleteUnit, url])

  return (
    <>
      <Anchor thin active={isGeneralInfoFolderActive} to={`/grants/${side.id}/otherDC/materials-and-supplies/general-info`}>
        General info
      </Anchor>
      {list
        ? list
        : <Typo className={styles.placeholder} size={14} type="div">No units have been created yet</Typo>
      }

      {side.otherDirectCosts.materialsAndSupplies.units.length > 0
        ?
          <TotalAnchor total={side.otherDirectCosts.materialsAndSuppliesTotalAmount} active={isTotalFolderActive} to={`/grants/${side.id}/otherDC/materials-and-supplies/totals`}>
            Totals
          </TotalAnchor>
        : null
      }
      <CreateBtn tip="To add a unit, you must save general info first" when={isCreationUnitDisabled}
                 name="add-grant-mas" onClick={() => createUnit(side.id)}
                 disabled={isCreationUnitDisabled} side={side}
      >
        Add unit
      </CreateBtn>
    </>
  );
};

export default MaterialsAndSupplies;
