import { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import {numberToMoney} from 'helpers/numbers';
import styles from './anchor.module.css';
import Icon from 'components/icon';
import useUI from 'hooks/useUI';

type Props = {
  to: string;
  icon?: string;
  active?: boolean;
  asFolder?: boolean;
  className?: string;
  disabled?: boolean;
  children?: any;
  thin?: boolean;
  blocked?: boolean;
}

const Anchor:FC<Props> = ({to, blocked, icon, asFolder, className, disabled, children, thin, active}) => {
  const { onShowPaywall } = useUI();
  if (blocked) {
    return (
      <button onClick={onShowPaywall} disabled={disabled} className={cx(styles.anchor, {[styles.active]: active, [styles.asFolder]: asFolder, [styles.asFolderWithIcon]: icon, [styles.blocked]: blocked, [styles.thin]: thin, [styles.disabled]: disabled})}>
        {asFolder && icon ? <Icon icon={icon} className={styles.icon}/> : null} {children} <Icon icon="lock-01" size={20} className={cx(styles.icon, styles.lock)}/>
      </button>
    )
  }
  return (
    disabled
      ?
      <button disabled={disabled} className={cx(styles.anchor, {[styles.active]: active, [styles.asFolder]: asFolder, [styles.asFolderWithIcon]: icon, [styles.thin]: thin, [styles.disabled]: disabled})}>
        {asFolder && icon ? <Icon icon={icon} className={styles.icon}/> : null} {children}
      </button>
      : <Link to={to} className={className}>
        <button className={cx(styles.anchor, {[styles.active]: active, [styles.thin]: thin, [styles.asFolder]: asFolder, [styles.asFolderWithIcon]: icon})}>
          {asFolder && icon ? <Icon icon={icon} className={styles.icon}/> : null} {children}
        </button>
      </Link>
  )
}

type TotalProps = {
  to: string;
  active?: boolean;
  children?: any;
  total?: number | string;
}

export const TotalAnchor:FC<TotalProps> = ({to, total = 0, children, active}) => (
  <Link to={to}>
    <button className={cx(styles.anchor, {[styles.active]: active})}>
      {children} { total !== undefined ? <span title={numberToMoney(total)} className={styles.total}>{numberToMoney(total)}</span> : null}
    </button>
  </Link>
);

export default Anchor;
