import {FC, MouseEvent} from 'react';
import cx from 'classnames';
import styles from './icon.module.css';

type Props = {
  className?: string;
  size?: number;
  disabled?: boolean;
  onClick?: (event?: any | MouseEvent<HTMLElement>) => void;
  type?: 'info' | 'success' | 'warning' | 'error';
  icon: string;
}
const Icon:FC<Props> = ({icon, type, onClick, className, disabled, size = 16}) => {
  const body = <i role={onClick ? 'button' : undefined} onClick={onClick} style={{fontSize: size}} className={cx(`bmicon-${icon}`, {[styles.disabled]: disabled}, styles.icon, className)}/>;
  return (
    type ? <div className={cx(styles.iconWrapper, styles[type])}>{body}</div> : body
  );
}

export default Icon;
