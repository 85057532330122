import {ChangeEvent, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useFees from 'hooks/useFees';
import useGrants from 'hooks/useGrants';
import useEnums from 'hooks/useEnums';
// helpers
import {getFeeItemFromGrant} from 'helpers/getFromGrant';
// types
import {FeeItemType} from 'store/grants/fees/types';
// components
import {Button, Form, Input, Select} from 'components/form';
import Field from 'components/field';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// styles
import styles from '../fees.module.css';

type FormProps = {
  item: FeeItemType;
  grantId: string;
};

const inflationFieldHelpText = 'Inflation is normally applied to fees. This should be specified or able to be deduced' +
  ' from your program solicitation. The next update to this program should have more information on specific details about specific program solicitations to further help you in unique cases such as this';

const FeeItemForm = ({item, grantId}: FormProps) => {
  const [data, setData] = useState<FeeItemType>(item);
  const {
    isLoading, updateFee,
    trackError,
    trackExit,
    trackFormStarted
  } = useFees({
    form_page_type: 'Fee info'
  });

  const {yesNoOptions} = useEnums()

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      setData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const onSubmit = useCallback(() => {
    updateFee(grantId, item.id, {
      ...item,
      ...data,
    })
  }, [data, grantId, item, updateFee]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit}
          trackFormStarted={trackFormStarted}
    >
      <Field last>
        <Input
          name="name"
          label="Fee name"
          placeholder="Enter Fee name"
          className={styles.input}
          value={data.name}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
          type="text"
        />
        <Select
          className={styles.fullInput}
          options={yesNoOptions}
          value={data.isInflationApplied}
          name="isInflationApplied"
          label="Is inflation applied to this fee each year?"
          placeholder="Select the item"
          bool
          help={inflationFieldHelpText}
          required
          onChange={(value) => handleChangeData({isInflationApplied: value})}
        />
      </Field>

      <div className={styles.footer}>
        <Button
          htmlType="submit"
          name="submit-fee-item"
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

const FeeItemPage = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getFeeItemFromGrant(grant, params.feeId);

  return (
    <GrantFormWrapper title="Fees" subtitle={`Fee ${index > 0 ? index : ''}`}>
      {item ? (
        <FeeItemForm
          grantId={grant.id}
          key={`${grant.id}-fees-${params.feeId}`}
          item={item}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
};

export default FeeItemPage;
