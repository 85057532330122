import {ChangeEvent, FC} from 'react';
import {Props} from './index';
import Field from 'components/field';
import {Button, Form, Checkbox, Input, Select} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import useEnums from 'hooks/useEnums';
import helps from './common/helps';

const Car:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {
  const { travelExpensesTypes } = useEnums();

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense}
               errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name car expense" required placeholder="Enter name car expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense}
                errors={errors.typeOfExpense} help={helps.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field withHelp top={Boolean(errors.startLocation || errors.endLocation)}>
        <Input placeholder="Enter starting location" label="Starting location"
               name="startLocation"
               value={data.startLocation}
               errors={errors.startLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ startLocation: event.target.value })}
        />
        <Input placeholder="Enter ending location" label="Ending location"
               name="endLocation"
               value={data.endLocation}
               errors={errors.endLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ endLocation: event.target.value })}
        />
      </Field>
      <Field last top={Boolean(errors['details.miles'] || errors['details.milesPerGallon'])}>
        <Input name="miles" value={details.miles} errors={errors['details.miles']} type="number"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ miles: event.target.value })}
               label="Number of miles that will be used for this trip (input number of miles ONE WAY)" required
               onBlur={onBlur}
               placeholder="Number of miles"/>
        <Input name="milesPerGallon" value={details.milesPerGallon} errors={errors['details.milesPerGallon']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ milesPerGallon: event.target.value })}
               label="Expected miles per gallon of gas" required onBlur={onBlur} type="number"
               placeholder="Expected miles per gallon"/>
      </Field>
      <Field>
        <Input name="gallons" value={details.gallons}
               disabled readOnly
               label="Number of gallons of fuel that will be needed for trip"
               placeholder="Number of gallons of fuel for trip" onBlur={onBlur}/>
        <Checkbox name="isRoundTrip" value={details.isRoundTrip} field
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ isRoundTrip: event.target.checked }, true)}
                  label="Double this value if it is a round trip" />
      </Field>
      <Field last top={Boolean(errors['details.gasPrice'] || errors['details.totalCost'])}>
        <Input name="gasPrice" money value={details.gasPrice} errors={errors['details.gasPrice']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ gasPrice: event.target.value })}
               label="Expected price per gallon of gas" required type="number"
               placeholder="Price of gas" onBlur={onBlur}/>
        <Input name="totalCost" money value={details.totalCost} errors={errors['details.totalCost']} type="number" onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
               label="Miscellaneous expenses for car travel for all trip attendees"
               placeholder="Enter total cost of misc expenses"/>
      </Field>
      <Field>
        <Input name="compensationBeforeInflation" value={data.compensationBeforeInflation}
               disabled readOnly money
               label="Amount budgeted before inflation for fuel + miscellaneous for this type of trip"
               placeholder="Amount of compensation before inflation"/>
      </Field>

      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} data={data.years} errors={errors} onBlur={onBlur} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Car;
