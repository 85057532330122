import {FC, useEffect, useMemo} from 'react';
import styles from './totals.module.css';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import Table, {Cell} from 'components/table';
import { FullGrant } from 'store/grants/types';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {numberToMoney} from 'helpers/numbers';
import useTravel, { AllTotal } from 'hooks/useTravel';
import cx from 'classnames';
import {getTotalsTableData} from 'helpers/table';


type Props = {
  totals: AllTotal[];
}

const renderCell = (cell: string, idx: number, isGrayData: boolean) => {
  if (idx < 1) return cell;
  return <span className={cx({[styles.gray]: isGrayData})}>{numberToMoney(cell)}</span>;
};

const Totals:FC<Props> = ({ totals }) => {

  const list = useMemo(() => {
    return totals.map((item: AllTotal) => {
      const {data, headers} = getTotalsTableData(item.data, renderCell, [item.title, 'Totals']);
      return {
        title: item.title,
        data,
        headers
      }
    })
  }, [totals]);

  return (
    <div className={styles.tables}>
      <div className={styles.tables}>
        {list.map((table, idx: number) => (
          <Table title={table.title} key={idx} className={styles.table} data={table.data} headers={table.headers} />
        ))}
      </div>
    </div>
  );
}

const TotalsTravelPage:FC = () => {
  const { getTotalsForAllTrips, allTotals, loading } = useTravel();
  const params:Record<string, string> = useParams();
  const { sidebarWidth } = useUI();

  useEffect(() => {
    if (params.id) getTotalsForAllTrips(params.id);
  }, [params.id]);

  return (
    <>
      <GrantFormWrapper title="Travel expenses" subtitle="Totals">
        <Totals totals={allTotals}/>
      </GrantFormWrapper>
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  )
}

export default TotalsTravelPage;
