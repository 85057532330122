import {FC, useState, useCallback} from 'react';
import {times} from 'lodash';
import styles from './alaska.module.css';
import {TravelCostData, TravelCostMealExpensesSeason} from 'store/grants/types';
import Typo from 'components/typo';
import {Button} from 'components/form';
import Season from './season';
import useTravel from 'hooks/useTravel';
import {stringOrUndefined} from 'helpers/date';


type Props = {
  cost: TravelCostData;
  travelId: string;
  inter?: boolean;
  grantId: string;
}

const AlaskaHawaiiBreakdown: FC<Props> = ({cost, inter, travelId, grantId}) => {
  const {
    updateTravelCost, previewTravelCost, errors,
    trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: "M&IE Breakdown"
  });

  const defaultSeason = {
    days: '',
    serialNumber: 1,
    total: '',
    maxTotal: '',
    startDate: undefined,
    endDate: undefined,
    rate: '',
    doBudgetMaxAmount: false,
    maxTotalAdjust: '',
    discount: {
      amount: '',
      threeQuarters: '',
      isStartDateFollowsEndDate: false,
      dailyAmountOfExpenses: '',
      maxAmount: '',
    }
  };

  const [seasons, onChangeSeasons] = useState<TravelCostMealExpensesSeason[]>(() => {
    const list = inter ? (cost.mAndIeBreakdown.seasons ?? []) : (cost.mealsExpenses?.seasons ?? []);
    return times(Number(cost.numberOfSeasons || 1), (i: number) => {
      let seasonInCost = list.find((item: TravelCostMealExpensesSeason) => item.serialNumber === i + 1);
      if (seasonInCost) {
        seasonInCost = {
          ...seasonInCost,
          startDate: seasonInCost.startDate ? new Date(seasonInCost.startDate) : undefined,
          endDate: seasonInCost.endDate ? new Date(seasonInCost.endDate) : undefined,
        }
      }
      return seasonInCost ?? {
        ...defaultSeason,
        serialNumber: i + 1,
      };
    })
  });

  const zero = Number(cost.numberOfSeasons) < 1;


  const onSubmitOne = useCallback((season: TravelCostMealExpensesSeason, index: number | string) => {
    if (cost) {
      const list = inter ? (cost.mAndIeBreakdown.seasons ?? []) : (cost.mealsExpenses?.seasons ?? []);
      const key = inter ? 'mAndIeBreakdown' : 'mealsExpenses';
      let submitData = list.map((item: TravelCostMealExpensesSeason) => {
        if (item.serialNumber === index) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: TravelCostMealExpensesSeason) => item.serialNumber === index);
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: TravelCostMealExpensesSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }));
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        [key]: {
          seasons: submitData
        }
      })
    }
  }, [cost, inter, zero, grantId, travelId, updateTravelCost]);

  const onBlur = useCallback((event: any, season: TravelCostMealExpensesSeason, index: number | string) => {
    if (cost) {
      let canMakeRequest = season.startDate && season.endDate;
      if (zero) canMakeRequest = Boolean(season.rate && season.days);
      if (season.doBudgetMaxAmount) canMakeRequest = canMakeRequest && !!season.maxTotalAdjust;
      if (!canMakeRequest) return;
      const list = inter ? (cost.mAndIeBreakdown.seasons ?? []) : (cost.mealsExpenses?.seasons ?? []);
      const key = inter ? 'mAndIeBreakdown' : 'mealsExpenses';
      let submitData = list.map((item: TravelCostMealExpensesSeason) => {
        if (index === item.serialNumber) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: TravelCostMealExpensesSeason) => item.serialNumber === index)
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: TravelCostMealExpensesSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }));
      previewTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        [key]: {
          seasons: submitData
        }
      }, (response: any) => {
        const responseSeasons: TravelCostMealExpensesSeason[] = response?.[key]?.seasons ?? [];
        const newSeasons = seasons.map((item: TravelCostMealExpensesSeason, idx: number) => {
          const seasonFromResponse = responseSeasons.find((item: TravelCostMealExpensesSeason) => item.serialNumber === index)
          if (index === item.serialNumber) {
            return {
              ...item,
              ...(seasonFromResponse ?? {}),
              startDate: seasonFromResponse?.startDate ? new Date(seasonFromResponse.startDate) : item.startDate,
              endDate: seasonFromResponse?.endDate ? new Date(seasonFromResponse.endDate) : item.endDate,
            }
          }
          return item;
        });
        onChangeSeasons(newSeasons);
      })
    }
  }, [cost, inter, zero, grantId, seasons, onChangeSeasons, travelId, previewTravelCost]);

  const handleChangeSeason = useCallback((idx: number | string) => (field: Record<string, any>, blur?: boolean) => {
    const newSeasons = seasons.map((item: TravelCostMealExpensesSeason) => {
      if (item.serialNumber === idx) return {...item, ...field};
      return item;
    });
    onChangeSeasons(newSeasons);
    const season = newSeasons.find((item: TravelCostMealExpensesSeason) => item.serialNumber === idx);
    if (blur && season) {
      onBlur(undefined, season, Number(idx));
    }
  }, [seasons, onBlur]);

  const saveAll = useCallback(() => {
    if (cost) {
      const key = inter ? 'mAndIeBreakdown' : 'mealsExpenses';
      const submitData = seasons.map((item: TravelCostMealExpensesSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }))
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        [key]: {
          seasons: submitData
        }
      })
    }
  }, [cost, inter, grantId, zero, seasons, travelId, updateTravelCost]);

  const errorField = inter ? 'mAndIeBreakdown' : 'mealsExpenses';

  const onClear = useCallback((idx: number | string) => {
    onSubmitOne(defaultSeason, idx);
    const newSeasons = seasons.map((item: TravelCostMealExpensesSeason) => {
      if (item.serialNumber === idx) {
        return {
          ...defaultSeason,
          serialNumber: idx
        }
      }
      return item;
    });
    onChangeSeasons(newSeasons);
  }, [defaultSeason, seasons, onSubmitOne])

  const texts = inter ? (
      <>
        <Typo className={styles.text}>If your trip extends to more than one season, please split your travel dates by
          season.</Typo>
        <Typo className={styles.text}>For example, if the season changes in the middle of your trip, please provide us
          with two date ranges. First one: from the starting date of your trip to the end date of the first season. Second
          one: from the first day of the second season until the last day of your trip. If your trip extends across more
          than 2 seasons, split your travel dates analogically into 3, 4, etc. date ranges.</Typo>
      </>
    )
    : (
      <>
        <Typo className={styles.text}>If your trip extends to more than one season, please split your travel dates by
          season.</Typo>
        <Typo className={styles.text}>For example, if the season changes in the middle of your trip, please provide us
          with two date ranges. First one: from the starting date of your trip to the end date of the first season.
          Second
          one: from the first day of the second season until the last day of your trip. If your trip extends across more
          than 2 seasons, split your travel dates analogically into 3, 4, etc. date ranges.</Typo>
        <Typo className={styles.text}>In some cases, one or two government meals can be "available or provided" each day
          of the trip. If you're going to use them, please enter the proportional meal rate. Otherwise, please enter a
          local meal rate.</Typo>
        <Typo className={styles.text}>If one government meal is available but is not required for consumption, you are
          eligible to use the regular meal rate. Please check with your Grant Officer.</Typo>
      </>
    );

  return (
    <div>
      {
        zero
          ? null
          : texts
      }
      <div className={styles.seasons}>
        {seasons.map((item: TravelCostMealExpensesSeason, idx: number) => (
          <Season item={item} errorField={errorField} zero={zero} onChange={handleChangeSeason(item.serialNumber)}
                  key={idx}
                  idx={idx} onSubmit={onSubmitOne} onBlur={onBlur} errors={errors}
                  last={idx === seasons.length - 1} inter={inter}
                  first={idx === 0} onClear={onClear}
                  trackError={trackError}
                  trackExit={trackExit}
                  trackFormStarted={trackFormStarted}
          />
        ))}
      </div>
      {
        zero || seasons.length < 2
          ? null
          :
          <div className={styles.footer}>
            <Button name="submit-all-seasons" onClick={saveAll}>Save all</Button>
          </div>
      }
    </div>
  );
}

export default AlaskaHawaiiBreakdown;
