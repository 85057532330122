import {FC, useEffect} from 'react';
import {Route, useParams} from 'react-router-dom';
import SubawardsInfo from './info';
import SubawardsTotals from './totals';
import SubawardeePage from './data';
import SubawardPage from './subaward';
import useSubawards from 'hooks/useSubawards';

const SubawardsIndex:FC = () => {
  const { getPrivateSubaward } = useSubawards();
  const params:Record<string, string> = useParams();


  useEffect(() => {
    getPrivateSubaward(params.id)
  }, [params.id]);

  return (
    <>
      <Route
        path="/grants/:id/otherDC/subawards/general-info"
        exact
        component={SubawardsInfo}
      />
      <Route
        path="/grants/:id/otherDC/subawards/:subawardId/edit"
        exact
        component={SubawardPage}
      />
      <Route
        path="/grants/:id/otherDC/subawards/:subawardId/data"
        exact
        component={SubawardeePage}
      />
      <Route
        path="/grants/:id/otherDC/subawards/totals"
        exact
        component={SubawardsTotals}
      />
    </>
  )
}

export default SubawardsIndex;
