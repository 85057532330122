import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useMaterialsSupplies from 'hooks/useMaterialsSupplies';
import useUI from 'hooks/useUI';
// components
import Spinner from 'components/spinner';
import Table from 'components/table';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// helpers
import { numberToMoney } from 'helpers/numbers';
import { getTotalsTableData } from 'helpers/table';
// styles
import cx from 'classnames';
import styles from 'pages/grant/one/otherDirectCosts/otherDC.module.css';

const MaterialsSuppliesTotals = () => {
    const params: Record<string, string> = useParams();
    const { totals, getTotals, isLoading } = useMaterialsSupplies();
    const { sidebarWidth } = useUI();

    const renderCell = (cell: string, idx: number, isGrayData: boolean) => {
        if (idx < 1) return cell;
        return <span className={cx({ [styles.gray]: isGrayData })}>{numberToMoney(cell)}</span>;
    };
    const { data, headers } = getTotalsTableData(totals, renderCell, ['Units', 'Totals']);

    useEffect(() => {
        getTotals(params.id);
    }, []);

    return (
        <GrantFormWrapper title={'Other Direct Costs'} subtitle={'Materials and Supplies. Totals'}>
            <div className={styles.tables}>
                <Table title={'Units'} className={styles.table} data={data} headers={headers} />
                {isLoading ? (
                    <Spinner
                        className={styles.spinner}
                        style={{ width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth }}
                        full
                    />
                ) : null}
            </div>
        </GrantFormWrapper>
    );
};

export default MaterialsSuppliesTotals;
