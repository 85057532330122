import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { useHistory, useParams} from 'react-router-dom';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {getEventFromGrant, getParticipantFromGrantEvent} from 'helpers/getFromGrant';
import useGrants from 'hooks/useGrants';
import useParticipants from 'hooks/useParticipants';
import Form from './form';
import Tabs, { Tab } from 'components/tabs';
import {FullGrant, GrantEventParticipant, GrantEventParticipantTransportationMethod} from 'store/grants/types';

type Props = {
  grant: FullGrant;
  participant: GrantEventParticipant;
  eventId: string;
}

const GrantEventParticipantTravels:FC<Props> = ({grant, participant, eventId}) => {
  const {
    addGrantEventParticipantTransportationMethod,
    deleteGrantEventParticipantTransportationMethod,
    updateGrantEventParticipantTransportationMethod,
    errors, loading, clearErrors,
    trackError,
    trackExit,
    trackFormStarted
  } = useParticipants({
    form_page_type: 'Participant`s travel'
  });

  const params:Record<string, any> = useParams();
  const history = useHistory();
  const [ methods, onChangeMethods ] = useState<GrantEventParticipantTransportationMethod[]>(participant.travelMethods ?? []);


  const onSubmit = useCallback((id: string, data: GrantEventParticipantTransportationMethod) => {
    updateGrantEventParticipantTransportationMethod(grant.id, eventId, participant.id, id, data, (newData: GrantEventParticipantTransportationMethod) => {
      const newMethods = methods.map((item: GrantEventParticipantTransportationMethod) => {
        if (item.id === id) return newData;
        return item;
      });
      onChangeMethods(newMethods);
    });
  }, [grant, eventId, methods, participant, updateGrantEventParticipantTransportationMethod]);

  const handleDeleteMethod = useCallback((id: string) => {
    deleteGrantEventParticipantTransportationMethod(grant.id, eventId, participant.id, id, () => {
      const newMethods = methods.filter((item: GrantEventParticipantTransportationMethod) => item.id !== id);
      onChangeMethods(newMethods);
    });
  }, [grant, methods, eventId, participant, deleteGrantEventParticipantTransportationMethod]);

  const list:Tab[] = useMemo(() => {
    return methods.map((item: GrantEventParticipantTransportationMethod, idx: number) => ({
      label: item.nameExpense || `Transportation method ${idx + 1}`,
      id: item.id ?? 'new',
      onDelete: () => handleDeleteMethod(item.id),
      element: participant
        ? <Form grantId={grant.id} years={participant.years}
                onSubmit={(data: GrantEventParticipantTransportationMethod) => onSubmit(item.id, data)}
                item={item} key={item.id} clearErrors={clearErrors}
                errors={errors} loading={loading}
                trackExit={trackExit} trackError={trackError} trackFormStarted={trackFormStarted}
        />
        : <span/>
    }));
  }, [methods, participant, grant.id, clearErrors, errors, loading, trackExit, trackError, handleDeleteMethod, onSubmit]);

  const onAddMethod = useCallback(() => {
    addGrantEventParticipantTransportationMethod(grant.id, params.eventId, params.participantId, (data: any) => {
      const newMethods = [...methods, data];
      onChangeMethods(newMethods);
      history.push(`/grants/${grant.id}/events/${params.eventId}/participants/${params.participantId}/travels?travel=${data.id}`)
    });
  }, [methods, grant, addGrantEventParticipantTransportationMethod, history, params]);

  return (
    <Tabs smallPadding onEnter={clearErrors} list={list} name="travel" action={onAddMethod} actionTitle="Add transportation method" />
  );
}

const GrantEventParticipantTravelsPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, any> = useParams();
  const { item, index } = getEventFromGrant(grant, params.eventId);
  const participant = item && getParticipantFromGrantEvent(item, params.participantId);
  const key = `${params.id}-event-${params.eventId}-participant-${participant?.item?.id}-travels`;

  const subtitle = useMemo(() => {
    return `Participant's Travel. Event ${index}. Participant ${participant?.index ?? ''}`
  }, [index, participant]);

  return (
    <GrantFormWrapper title="Participants Support" subtitle={subtitle}>
      {participant?.item
        ? <GrantEventParticipantTravels grant={grant} participant={participant.item} eventId={params.eventId} key={key} />
        : <span/>
      }
    </GrantFormWrapper>
  );
}

export default GrantEventParticipantTravelsPage;
