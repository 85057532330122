import {ChangeEvent, FC, useCallback, useMemo} from 'react';
import cx from 'classnames';
import {Input, Select, Form, Button} from 'components/form'
import {Errors} from 'helpers/errors';
import useEnums from 'hooks/useEnums';
import styles from './personForm.module.css';
import {FieldErrors} from "react-hook-form";

export type Person = {
  positionTitle?: string;
  jobCategory?: string;
  fringeBenefit?: string | number;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
};


type Props = {
  data: Person;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  type: string;
  edit: boolean;
  onChange: (field: Record<string, any>) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

const OtherPersonForm: FC<Props> = ({
                                      trackError,
                                      trackExit, data, type, edit, errors, loading, onSubmit, onChange
                                    }) => {

  const {fetchZIP, jobCategory, countries} = useEnums();

  const onBlurZip = useCallback(() => {
    if (data.zipCode) {
      fetchZIP(data.zipCode, ({state, city}) => onChange({
        ...data,
        state,
        city
      }));
    }
  }, [data, onChange, fetchZIP]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const onChangeCountry = useCallback((country: string) => {
    onChange({
      country,
      zipCode: country === 'US' ? data.zipCode : '',
      state: country === 'US' ? data.state : '',
    });
  }, [data, onChange]);

  const positionTitleLabel = useMemo(() => {
    if (type === 'civil-service') return 'Name for this Civil personnel Position';
    if (type === 'union') return 'Name for this Union personnel Position';
    if (type === 'temp-n-casual') return 'Name for this Temp and Casual personnel Position';
    return 'Name for this Other personnel Position'
  }, [type]);

  return (
    <Form prompt onSubmit={handleSubmit} className={styles.wrapper} trackError={trackError} trackExit={trackExit}>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="positionTitle" label={positionTitleLabel} required placeholder={`Enter ${positionTitleLabel}`}
               className={styles.input} value={data.positionTitle} errors={errors.positionTitle}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({positionTitle: event.target.value})}/>
        <Select name="jobCategory" value={data.jobCategory} options={jobCategory.civilService} className={styles.input}
                onChange={(jobCategory: any) => onChange({jobCategory})}
                help="Universities and research institutions often manage their budgets with finer detail than required by the NSF's Final Form. This software facilitates granular control over budget sections, aligning with both your institution's detailed approach and NSF's broader categorization requirements. For detailed information on the personnel categorization process, use the built-in guide (“Show a guide” button)"
                placeholder="Select a job category" label="Job category (according to NSF)" required
                errors={errors.jobCategory}
        />
      </div>

      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="fringeBenefit" label="Fringe Benefit Rate,%" required type="number"
               placeholder="Enter benefit rate"
               className={styles.input} value={data.fringeBenefit} errors={errors.fringeBenefit}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({fringeBenefit: event.target.value})}/>
      </div>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Select name="country" className={styles.input} options={countries} value={data.country}
                onChange={onChangeCountry} label="Select country" required
                errors={errors.country}
        />
      </div>

      <div className={cx(styles.field, styles.threeInputsField, styles.withoutBorder)}>
        <Input name="zipCode" label="ZIP code" required={data.country === 'US'} placeholder="Enter ZIP code"
               disabled={data.country !== 'US'}
               className={styles.input} value={data.zipCode} errors={errors.zipCode}
               onBlur={onBlurZip}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({zipCode: event.target.value})}/>

        <Input name="state" auto label="State" required={data.country === 'US'} placeholder="Enter state"
               disabled={data.country !== 'US'}
               className={styles.input} value={data.state} errors={errors.state}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({state: event.target.value})}/>

        <Input name="city" auto label="City" required={data.country === 'US'} placeholder="Enter city"
               className={styles.input} value={data.city} errors={errors.city}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({city: event.target.value})}/>


      </div>
      <div className={styles.footer}>
        <Button name="submit" loading={loading} htmlType="submit">{edit ? 'Save' : 'Add personnel'}</Button>
      </div>
    </Form>
  );
}

export default OtherPersonForm;
