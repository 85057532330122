import {useEffect, useMemo} from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useGrants from 'hooks/useGrants';
// types
import { MaterialsSuppliesUnitYearType } from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import MaterialsSuppliesUnitYearForm from './form';
// helpers
import { getMaterialsSuppliesUnitFromGrant } from 'helpers/getFromGrant';
import useMaterialsSupplies from "../../../../../../hooks/useMaterialsSupplies";

const CreateEditMSUnitYear = () => {
    const { grant } = useGrants();
    const params: Record<string, string> = useParams();

    const {
        trackError,
        trackExit,
        trackFormStarted
    } = useMaterialsSupplies({form_page_type: 'Date'});

    const { item: currentUnit, index: currentUnitIdx } = getMaterialsSuppliesUnitFromGrant(grant, params.unitId)
    const currentUnitYear:MaterialsSuppliesUnitYearType | undefined = currentUnit?.years[Number(params.yearId)];

    const pageKey = `${params.id}-unit-${params.unitId}-year${params.yearId}`;
    const subtitle = useMemo(() => {
        return `Materials and Supplies. Unit ${currentUnitIdx}. Year ${Number(params.yearId) + 1}`
    }, [currentUnitIdx, params]);

    return (
        <GrantFormWrapper
            subtitle={subtitle}
            title={'Other direct costs'}
            key={pageKey}

        >
            {currentUnitYear
              ? <MaterialsSuppliesUnitYearForm currentUnitYear={currentUnitYear} currentUnit={currentUnit!} trackError={trackError}
                                               trackExit={trackExit}
                                               trackFormStarted={trackFormStarted}
              />
              : <span/>}
        </GrantFormWrapper>
    );
};

export default CreateEditMSUnitYear;
