import { createAction } from 'helpers/actions';

export const minimizeSidebarAction = createAction('ui/minimize_sidebar');
export const maximizeSidebarAction = createAction('ui/maximize_sidebar');
export const changeSidebarWidthAction = createAction('ui/change_sidebar_width');
export const startLoadingAction = createAction('ui/start_loading');
export const endLoadingAction = createAction('ui/end_loading');
export const showInfoSidebarAction = createAction('ui/show_info_sidebar');
export const hideInfoSidebarAction = createAction('ui/hide_info_sidebar');
export const addModalToHiddenModals = createAction('ui/add_modal_to_hidden_modals');
export const setPrevUrlAction = createAction('ui/set_prev_url');
export const setOpenedSteps = createAction('ui/set_opened_steps');
export const clearUI = createAction('ui/clear');
export const setMtdcGrantId = createAction('ui/set_mtdc_grantId');
export const setICGrantId = createAction('ui/set_indirect_costs_grantId');
export const setFinalGrantId = createAction('ui/set_final_grantId');
export const addHiddenRate = createAction('ui/add_hidden_rate');
export const showNavigationMessageAction = createAction('ui/show_navigation_message');
export const hideNavigationMessageAction = createAction('ui/hide_navigation_message');
export const showPromptAction = createAction('ui/show_prompt');
export const hidePromptAction = createAction('ui/hide_prompt');
export const showPaywallAction = createAction('ui/show_paywall');
export const hidePaywallAction = createAction('ui/hide_paywall');
export const minimizeBudgetBarAction = createAction('ui/minimize_budget_bar');
export const maximizeBudgetBarAction = createAction('ui/maximize_budget_bar');
export const showSettingsModalAction = createAction('ui/show_settings_modal');
export const hideSettingsModalAction = createAction('ui/hide_settings_modal');
export const hideDisplayMessageAction = createAction('ui/hide_display_message');
