import { ComputerServiceYear, ComputerServices, ComputerService } from './types';

export const computerServiceYearAdapter = (data: ComputerServiceYear, idx: number) => {
  return {
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    compensationBeforeInflationCalculated: data.compensationBeforeInflationCalculated ?? '',
    multiplier: data.multiplier ?? undefined,
    calculatedCompensationAfterInflation: data.calculatedCompensationAfterInflation ?? '',
    calculatedCompensationBeforeInflation: data.calculatedCompensationBeforeInflation ?? '',
    isUserCompensation: Boolean(data.isUserCompensation),
    userCompensationBeforeInflation: data.userCompensationBeforeInflation ?? undefined,
    userCompensationAfterInflation: data.userCompensationAfterInflation ?? undefined,
  }
}


export const computerServicesAdapter = (data: ComputerServices) => {
  return {
    isChargedAsDirectCost: data?.isChargedAsDirectCost,
    services: data?.services ? data.services.map(computerServiceAdapter) : []
  }
}
export const computerServiceAdapter = (data: ComputerService) => {
  return {
    name: data.name ?? '',
    cost: data.cost ?? '',
    id: data.id ?? '',
    years: data.years ? data.years.map(computerServiceYearAdapter) : []
  }
}
