import {useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import useApi from './useApi';
import {FullGrant, List, SidebarTotals, Stat, StatGrant} from 'store/grants/types';
import {
  grantAdapter
} from 'store/grants/adapters';
import {
  grantsListSelector,
  grantsStatsSelector, grantSelector,
  activeGrantIdSelector,
  editableGrantSelector, sidesGrantSelector,
  grantSidebarSelector
} from 'store/grants/selector';
import {
  setListGrants,
  setGrantsStats,
  removeGrantAction,
  setOneGrant,
  clearOneGrant,
  addSideGrant,
  updateGrantInListAction,
  addNewGrantOnDuplicateToList,
  addNewGrantToList,
  setGrantActiveId,
  clearGrantActiveId, setEditableGrant, clearEditableGrant, clearGrantsAction,
  addGrantSidebarTotals
} from 'store/grants/actions';
import {AxiosError, AxiosResponse} from 'axios';
import {trackUserAction} from 'helpers/trackUserActions';
import parseErrors, {Errors} from 'helpers/errors';
import * as T from './requestTypes';
import toast from 'components/toast';
import {stepNames} from 'const';
import {confirm} from 'components/confirmation';
import useEnums from './useEnums';
import useDashboard from './useDashboard';
import useUI from './useUI';
import useAuth from './useAuth';
import {useMixPanel} from "./useMixPanel";

export type MovedGrant = StatGrant & { step: string };

type NotificationBody = {
  title: string;
  text: string;
}

type PreviewData = {
  startDate?: string;
  endDate?: string;
}

type iUseGrants = {
  getList: (search?: string, step?: string, cb?: () => void) => void;
  getStats: (search?: string) => void;
  create: () => void;
  duplicate: (id: string, cb: () => void) => void;
  getSide: (id: string, cb?: () => void) => void;
  clearOne: () => void;
  clearActiveGrantId: () => void;
  getEditable: (id: string, cb: () => void) => void;
  clearEditableOne: () => void;
  grantPreview: (data: PreviewData, cb: (response: any) => void) => void;
  changeGrantStep: (item: MovedGrant, step: string, acceptForm?: T.AcceptForm, cb?: () => void, status?: string) => void;
  getOne: (id: string) => void;
  getOneWithSide: (id: string, cb?: () => void) => void;
  getOneWithSideGlobal: (id: string) => void;
  getOneSilent: (id: string, cb: (grant: FullGrant) => void) => void;
  removeGrant: (id: string, cb?: () => void) => void;
  updateSettings: (id: string, data: any, cb?: () => void) => void;
  updateGrantSettings: (id: string, data: T.GrantBasicInfo, cb?: () => void) => void;
  updateGrantEmailSettings: (id: string, data: T.GrantBasicInfo, cb?: () => void) => void;
  updateGrantAuthorSettings: (id: string, data: T.UpdateUserInfo, cb?: () => void) => void;
  updateGrantOrganizationSettings: (id: string, data: T.OrganizationPureUserData, cb?: () => void, notification?: NotificationBody) => void;
  list: List;
  errors: Errors;
  loading: boolean;
  activeGrantId: string;
  grant: FullGrant;
  getSideFromStore: (id: string) => FullGrant;
  getSidebarTotalsFromStore: (id: string) => SidebarTotals;
  sides: FullGrant[];
  editable: FullGrant;
  stats: Stat[];
  clearGrants: () => void;
}

const doNotaskToMoveFromHiddenKey = 'doNotAskToMoveFromHidden';

const useGrants = (): iUseGrants => {
  const {grantBudgetCreated, grantBudgetDeleted, grantBudgetStatusUpdated, setGrantGroupProperties} = useMixPanel();

  const api = useApi();
  const dashboard = useDashboard();
  const dispatch = useDispatch();
  const {grantStatus} = useEnums();
  const {loader} = useUI();
  const {me} = useAuth();
  const list = useSelector(grantsListSelector);
  const stats = useSelector(grantsStatsSelector);
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const history = useHistory();
  const grant = useSelector(grantSelector);
  const sides = useSelector(sidesGrantSelector);
  const editable = useSelector(editableGrantSelector);
  const activeGrantId = useSelector(activeGrantIdSelector);
  const sidebarTotals = useSelector(grantSidebarSelector);
  const doNotAskToMoveFromHidden = Boolean(localStorage.getItem(doNotaskToMoveFromHiddenKey));

  const getSidebarTotals = useCallback((grantId: string) => {
    api.getSidebar(grantId)
      .then((response: AxiosResponse) => {
        dispatch(addGrantSidebarTotals(response.data));
      });
  }, [dispatch, api]);

  const getList = useCallback((search?: string, step?: string, cb?: () => void) => {
    const query = new URLSearchParams({
      search: search || '',
      step: step || ''
    }).toString();
    api.getGrants(`?${query}`)
      .then((response: AxiosResponse) => {
        dispatch(setListGrants(response.data));
        if (cb) cb();
      });
  }, [api, dispatch]);

  const getStats = useCallback((search?: string) => {
    const q = new URLSearchParams({
      search: search || ''
    }).toString();
    if (search) loader.start();
    const query = (search) ? `?${q}` : undefined;
    api.getGrantsStats(query)
      .then((response: AxiosResponse) => {
        loader.stop();
        dispatch(setGrantsStats(response.data));
      })
      .catch(() => loader.stop());
  }, [api, loader, dispatch]);

  const create = useCallback(() => {
    api.createGrant()
      .then((response: AxiosResponse) => {
        dispatch(setOneGrant(response.data));
        dispatch(addSideGrant(response.data));

        dispatch(addNewGrantToList(response.data));
        trackUserAction("Starts a new grant")

        //track mix panel Grant Budget Created event
        grantBudgetCreated(response.data.id)

        history.push(`/grants/${response.data.id}/create`);
      });
  }, [api, dispatch, history]);

  const duplicate = useCallback((id: string, cb: () => void) => {
    confirm({
      title: 'Duplicate grant',
      text: 'Are you sure you want to duplicate this grant?',
      icon: 'copy-01',
      type: 'info',
      okText: 'Yes',
      onConfirm: () => {
        api.duplicateGrant(id)
          .then((response: AxiosResponse) => {
            if (cb) cb();
            toast.success({
              title: 'Grant has been duplicated',
              message: 'Changes have been successfully saved'
            });
            dispatch(setOneGrant(response.data));
            dispatch(addNewGrantOnDuplicateToList(response.data));
            history.push(`/grants/${response.data.id}/edit`);
          });
      }
    });
  }, [api, dispatch, history]);

  const getOne = useCallback((id: string) => {
    onChangeLoading(true);
    getSidebarTotals(id);
    loader.start();
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        loader.stop();
        dispatch(setGrantActiveId(id));
        dispatch(setOneGrant(response.data));
      })
      .then(() => {
        onChangeLoading(false);
        loader.stop();
      });
  }, [api, loader, getSidebarTotals, dispatch]);

  const getOneWithSide = useCallback((id: string, cb?: () => void) => {
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        getSidebarTotals(id);
        dispatch(addSideGrant(response.data));
        dispatch(setOneGrant(response.data));
        if (cb) cb();
      });
  }, [api, getSidebarTotals, dispatch]);

  const getOneWithSideGlobal = useCallback((id: string) => {
    const existedInSides: FullGrant | undefined = sides.find((item: FullGrant) => item.id === id);
    if (existedInSides) {
      getSidebarTotals(id);
      dispatch(setOneGrant(existedInSides));
      return;
    }
    onChangeLoading(true);
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        getSidebarTotals(id);
        dispatch(addSideGrant(response.data));
        dispatch(setOneGrant(response.data));
        onChangeLoading(false);
      })
      .finally(() => onChangeLoading(false));
  }, [api, sides, getSidebarTotals, dispatch]);

  const getEditable = useCallback((id: string, cb: () => void) => {
    onChangeLoading(true);
    loader.start();
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        dispatch(setEditableGrant(response.data));
        onChangeLoading(false);
        loader.stop();
        cb();
      })
      .then(() => {
        loader.stop();
        onChangeLoading(false)
      });
  }, [api, loader, dispatch]);

  const getSide = useCallback((id: string, cb?: () => void) => {
    const existedInSides: FullGrant | undefined = sides.find((item: FullGrant) => item.id === id);
    if (existedInSides) {
      getSidebarTotals(id);
      dispatch(setOneGrant(existedInSides));
      return;
    }
    onChangeLoading(true);
    loader.start();
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        dispatch(addSideGrant(response.data));
        loader.stop();
        getSidebarTotals(id);
        if (cb) cb();
        onChangeLoading(false);
      })
      .then(() => {
        loader.stop();
        onChangeLoading(false)
      });
  }, [api, getSidebarTotals, sides, loader, dispatch]);

  const getOneSilent = useCallback((id: string, cb: (grant: FullGrant) => void) => {
    onChangeLoading(true);
    getSidebarTotals(id);
    loader.start();
    api.getOneGrant(id)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data);
      })
      .then(() => {
        loader.stop();
        onChangeLoading(false)
      });
  }, [api, getSidebarTotals, loader]);

  const removeGrant = useCallback((id: string, cb?: () => void) => {
    confirm({
      title: 'Delete grant',
      text: 'Are you sure you want to delete this grant? This action will not be reversible.',
      cancelText: 'No, take me back',
      icon: 'trash-01',
      type: 'error',
      okText: 'Please delete',
      onConfirm: () => {
        api.removeGrant(id)
          .then(() => {
            if (cb) cb();
            dashboard.get(true);
            if (grant.id === id) history.push('/grants');
            dispatch(removeGrantAction(id));
            toast.success({
              title: 'Grant has been removed',
              message: 'Changes have been successfully saved'
            });
            grantBudgetDeleted(id)
          });
      }
    });
  }, [api, dashboard, grant.id, history, dispatch, grantBudgetDeleted]);

  const updateSettings = useCallback((id: string, data: any, cb?: () => void, notification?: NotificationBody) => {
    if(data.organization){
      setGrantGroupProperties(id, data)
    }

    onChangeLoading(true);
    loader.start();
    onChangeErrors({});

    api.updateGrantSettings(id, data)
      .then((response: AxiosResponse) => {
        if (cb) cb();
        dispatch(setOneGrant(response.data));
        if (response.data.id === grant.id) dispatch(setOneGrant(response.data));
        loader.stop();
        if (data.doUpdateUserProfile) {
          me();
        }
        dispatch(updateGrantInListAction(response.data));
        onChangeLoading(false);
        toast.success({
          title: notification?.title ?? 'Grant info has been updated',
          message: notification?.text ?? 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        loader.stop();
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [setGrantGroupProperties, dispatch, me, grant, loader, api]);

  const updateGrantSettings = useCallback((id: string, data: T.GrantBasicInfo, cb?: () => void) => {
    onChangeErrors({});
    onChangeLoading(true);
    loader.start();
    updateSettings(id, data, cb);
  }, [loader, updateSettings]);

  const updateGrantEmailSettings = useCallback((id: string, data: T.GrantBasicInfo, cb?: () => void) => {
    onChangeErrors({});
    onChangeLoading(true);
    updateSettings(id, data, cb, {
      title: 'Request sent successfully',
      text: "We'll notify you once the selected grant type becomes available"
    });
  }, [updateSettings]);

  const updateGrantAuthorSettings = useCallback((id: string, data: T.UpdateUserInfo, cb?: () => void) => {
    onChangeErrors({});
    updateSettings(id, {author: data}, cb);
  }, [updateSettings]);

  const updateGrantOrganizationSettings = useCallback((id: string, data: T.OrganizationPureUserData, cb?: () => void) => {
    onChangeErrors({});
    updateSettings(id, {organization: data}, cb);
  }, [updateSettings]);

  const clearOne = useCallback(() => {
    dispatch(clearOneGrant());
  }, [dispatch]);

  const clearEditableOne = useCallback(() => {
    dispatch(clearEditableGrant());
  }, [dispatch]);

  const trackChangeGrantStep = (grant_id: string, status_origin: string, status_destination: string) => {
    grantBudgetStatusUpdated(grant_id, status_origin, status_destination)
  }

  const changeGrantStep = useCallback((item: StatGrant, step: string, acceptForm?: T.AcceptForm, cb?: () => void, status?: string) => {
    if (acceptForm) {
      onChangeErrors({});
      onChangeLoading(true);
      api.changeGrantStep(item.id, step, {
        acceptedForm: {
          awardNumber: acceptForm.awardNumber,
          uniqueEntityIdentifier: acceptForm.uniqueEntityIdentifier,
          totalDirectCosts: acceptForm.totalDirectCosts,
          totalIndirectCosts: acceptForm.totalIndirectCosts,
          awardDate: acceptForm.awardDate
        },
        status
      })
        .then((response: AxiosResponse) => {
          trackChangeGrantStep(item.id, item.step, step)
          onChangeLoading(false);
          toast.info({
            title: response.data?.title || 'Info',
            message: response.data?.notification || `Grant "${item.title}" was successfully moved to "${stepNames[step]}".`
          });
          dashboard.get(true);
          if (cb) cb();
          getStats();
        })
        .catch((error: AxiosError) => {
          //@ts-ignore
          if (error?.response?.data?.errors) { // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
          onChangeLoading(false);
        })
      return;
    }
    if (item.step === 'hidden' && !doNotAskToMoveFromHidden) {
      confirm({
        text: [
          'Please confirm that the grant should be moved from the "Hidden Past / Present Projects" step.',
          'In case you would like to duplicate the grant without moving please use a context menu.'
        ],
        plain: true,
        label: 'Please do not show this notification again',
        type: 'info',
        onConfirm: (checked: boolean) => {
          if (checked) localStorage.setItem(doNotaskToMoveFromHiddenKey, 'true');
          api.changeGrantStep(item.id, step)
            .then((response: AxiosResponse) => {
              trackChangeGrantStep(item.id, item.step, step)
              dashboard.get(true);
              toast.info({
                title: response.data?.title || 'Info',
                message: response.data?.notification || `Grant "${item.title}" was successfully moved to "${stepNames[step]}".`
              });
              getStats();
            });
        }
      });
      return;
    }
    if (item.step === 'accepted' && item.isOngoing) {
      confirm({
        text: 'Please confirm that ongoing grant should be moved from Accepted step',
        plain: true,
        type: 'info',
        onConfirm: () => {
          api.changeGrantStep(item.id, step, status ? {status} : undefined)
            .then((response: AxiosResponse) => {
              trackChangeGrantStep(item.id, item.step, step)
              dashboard.get(true);
              toast.info({
                title: response.data?.title || 'Info',
                message: response.data?.notification || `Grant "${item.title}" was successfully moved to "${stepNames[step]}".`
              });
              getStats();
            });
        }
      });
      return;
    }
    if (step === 'hidden') {
      if (status) {
        api.changeGrantStep(item.id, step, {status})
          .then((response: AxiosResponse) => {
            dashboard.get(true);
            toast.info({
              title: response.data?.title || 'Info',
              message: response.data?.notification || `Grant "${item.title}" was successfully moved to "${stepNames[step]}".`
            });
            getStats();
          });
        return;
      }
    } else if (step !== 'hidden') {
      api.changeGrantStep(item.id, step)
        .then((response: AxiosResponse) => {
          trackChangeGrantStep(item.id, item.step, step)
          dashboard.get(true);
          toast.info({
            title: response.data?.title || 'Info',
            message: response.data?.notification || `Grant "${item.title}" was successfully moved to "${stepNames[step]}".`
          });
          getStats();
        });
    }
  }, [doNotAskToMoveFromHidden, api, trackChangeGrantStep, dashboard, getStats]);

  const grantPreview = useCallback((data: PreviewData, cb: (response: any) => void) => {
    const q = new URLSearchParams(data).toString();
    api.grantPreview(q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, [api])

  const clearGrants = useCallback(() => {
    dispatch(clearGrantsAction());
  }, [dispatch]);

  const emptyGrant = grantAdapter({} as FullGrant);

  const getSideFromStore = useCallback((id: string): FullGrant => {
    const side = sides.find((item: FullGrant) => item.id === id);
    if (side) return side;
    //@ts-ignore
    return emptyGrant;
  }, [sides, emptyGrant]);

  const getSidebarTotalsFromStore = useCallback((id: string): SidebarTotals => {
    const totals = sidebarTotals.find((item: SidebarTotals) => item.id === id);
    if (totals) return totals;
    return {
      id: '',
      createdAt: '',
      isAccepted: false,
      isOngoing: false,
      rolesCount: {},
      primaryInvestigator: {},
      years: [],
      isPrimaryGeneralInfoComplete: false,
      yearsInGrant: 0,
      monthsInGrant: 0,
      daysInGrant: 0,
      seniorPersonnelTotalAmount: 0,
      academicResearchAssociateTotalAmount: 0,
      postDocPersonnelTotalAmount: 0,
      civilServiceTotalAmount: 0,
      otherPersonnelTotalAmount: 0,
      tempAndCasualTotalAmount: 0,
      unionPersonnelTotalAmount: 0,
      travelsTotalAmount: 0,
      equipmentTotalAmount: 0,
      participantSupportTotalAmount: 0,
      feesTotalAmount: 0,
      costSharingTotalAmount: 0,
      hasDurationLeftover: false,
      completion: 0,
    }
  }, [sidebarTotals]);

  const clearActiveGrantId = useCallback(() => dispatch(clearGrantActiveId()), [dispatch]);

  return {
    clearEditableOne,
    getSidebarTotalsFromStore,
    clearGrants,
    getOneSilent,
    getSideFromStore,
    grantPreview,
    changeGrantStep,
    getList,
    updateSettings,
    updateGrantOrganizationSettings,
    loading,
    errors,
    getSide,
    updateGrantEmailSettings,
    editable,
    updateGrantAuthorSettings,
    clearActiveGrantId,
    duplicate,
    updateGrantSettings,
    clearOne,
    stats,
    sides,
    getOneWithSide,
    getEditable,
    getOneWithSideGlobal,
    activeGrantId,
    getOne,
    removeGrant,
    list,
    grant,
    getStats,
    create
  };
}

export default useGrants;
