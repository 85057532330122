import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import Field from 'components/field';
import {Button, Textarea, Input, Form, Autocomplete} from 'components/form';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {getSubawardFromODC} from 'helpers/getFromGrant';
import {Subaward} from 'store/grants/otherDirectCosts/Subawards/types';
import useSubawards from 'hooks/useSubawards';
import useOrganizations from 'hooks/useOrganizations';
import styles from './subawards.module.css';

type Props = {
  grantId: string;
  item: Subaward;
}

const SubawardForm: FC<Props> = ({item, grantId}) => {
  const [data, onChangeData] = useState<Subaward>(item);
  const {
    loading, errors, update,
    trackError,
    trackExit,
    trackFormStarted
  } = useSubawards({
    form_page_type: "Subaward info"
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {fetchApi, fetchOptions, isLoading} = useOrganizations();

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field,
    })
  }, [data]);

  const handleChangeSubRecipient = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      subRecipient: {
        ...data.subRecipient,
        ...field,
      }
    })
  }, [data]);

  const onSubmit = useCallback(() => {
    update(grantId, item.id, data);
  }, [update, data, item, grantId]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input errors={errors['name']} name="name" label="Name of subaward" placeholder="Enter name of subaward"
               required
               value={data.name}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
        />
      </Field>
      <Field>
        <Input errors={errors['subRecipient.name']} name="subRecipient.name" label="Name of subrecipient"
               placeholder="Enter name of subrecipient"
               value={data.subRecipient.name} required
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeSubRecipient({name: event.target.value})}
        />
        <Autocomplete
          errors={errors['subRecipient.primaryOrganization']}
          value={data.subRecipient.primaryOrganization}
          onChange={(value: string | number) => handleChangeSubRecipient({primaryOrganization: value})}
          name="primaryOrganization"
          options={fetchOptions}
          placeholder="Select organization"
          label="Subrecipient primary organization"
          required
          onLoad={fetchApi}
          isLoading={isLoading}
          clearable
        />
      </Field>
      <Field>
        <Input errors={errors['subRecipient.department']} name="subRecipient.department"
               label="Subrecipient's department" placeholder="Enter sub-recipient’s department"
               value={data.subRecipient.department}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeSubRecipient({department: event.target.value})}
        />
        <Input errors={errors['subRecipient.email']} name="subRecipient.email" label="Subrecipient's email"
               placeholder="Enter sub-recipient’s email"
               value={data.subRecipient.email} type="email" required
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeSubRecipient({email: event.target.value})}
        />
      </Field>
      <Field last full>
        <Textarea name="description" label="Brief description of work to be done"
                  value={data.description}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({description: event.target.value})}
        />
      </Field>
      <div className={styles.footer}>
        <Button name="submit" loading={loading} htmlType="submit">Save</Button>
      </div>
    </Form>
  );
}

const SubawardPage: FC = () => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants();
  const {item, index} = getSubawardFromODC(grant, params.subawardId);

  const subtitle = useMemo(() => {
    if (item) return `${item.name || `Subaward ${index > 0 ? index : ''}`}`
    return 'Untitled Subaward'
  }, [item, index]);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={subtitle}>
      {item
        ? <SubawardForm item={item} grantId={grant.id} key={`${grant.id}-odc-subawards-${params.subawardId}`}/>
        : <span/>}
    </GrantFormWrapper>
  )
}


export default SubawardPage;
