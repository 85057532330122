import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import useGrants from 'hooks/useGrants';
import {useHistory, useParams} from 'react-router-dom';
import usePersonnel, {SeniorPreview} from 'hooks/usePersonnel';
import {getAcademicResearchPersonFromGrant, getSeniorPersonFromGrant} from 'helpers/getFromGrant';
import * as T from 'hooks/requestTypes';
import {PersonalNSFCompensation} from 'store/grants/types';
import {stringOrUndefined} from 'helpers/date';
import {trackUserAction} from 'helpers/trackUserActions';
import {personalTypes} from 'const';
import {confirm} from 'components/confirmation';
import styles from './editPerson.module.css';
import Typo from 'components/typo';
import {Form, Toggler} from 'components/form';
import Help from 'components/help';
import PersonForm from 'containers/forms/personForm';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';

type Field = Record<string, any>;

const CreateEditPerson: FC = () => {
  const {grant} = useGrants();
  const history = useHistory();

  const {
    getSeniorPersonnelPreview,
    editSeniorPerson,
    getAcademicResearchPersonnelPreview,
    editAcademicResearchPerson,
    errors,
    loading,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({form_page_type: 'Date'});

  const params: Record<string, string> = useParams();

  const type = params.personnelType;

  const {person, index} = type === personalTypes.seniorPersonal
    ? getSeniorPersonFromGrant(grant, params.personId)
    : getAcademicResearchPersonFromGrant(grant, params.personId);

  const defaultData = useMemo(() => ({
    firstName: person?.firstName ?? '',
    lastName: person?.lastName ?? '',
    positionTitle: person?.positionTitle ?? '',
    subGroup: person?.subGroup ?? '',
    jobCategory: person?.jobCategory ?? '',
    role: person?.role ?? undefined,
    department: person?.department ?? '',
    monthCompensatedByOrganization: person?.salary?.monthCompensatedByOrganization ?? '',
    salary: {
      amount: person?.salary.amount ?? '',
      startDate: person?.salary.startDate ? new Date(person?.salary.startDate) : null,
      endDate: person?.salary.endDate ? new Date(person?.salary.endDate) : null,
    },
    fringeBenefitRate: person?.fringeBenefitRate ? percentToFixed(person?.fringeBenefitRate) : undefined,
    isRequestingSupport: Boolean(person?.isRequestingSupport),
    isAccountOwner: person?.isAccountOwner,
    nsfCompensation: person?.nsfCompensation && person.nsfCompensation.length > 0
      ? person.nsfCompensation
      : grant.years.map((year: string[], idx: number) => ({year: year, serialNumber: idx + 1, kind: undefined})),
  }), [person, grant]);

  const [dates, onChangeDates] = useState({
    startDate: person?.salary.startDate ? new Date(person?.salary.startDate) : null,
    endDate: person?.salary.endDate ? new Date(person?.salary.endDate) : null,
  });

  const [data, onChangeData] = useState<T.Person>(defaultData);

  const [nsfCompensation, onChangeNSFCompensation] = useState<PersonalNSFCompensation[]>(() => {
    return defaultData.nsfCompensation ?? [];
  });

  const [previewFields, onChangePreviewFields] = useState<T.PersonnelPreviewFields>({
    salaryPaidPerMonthByOrganization: '',
    percentOfSalary: '',
  });

  const handleChangePreviewFields = useCallback((event?: any, previewData?: T.Person) => {
    const submitData = previewData || data;
    if (!submitData.salary.amount || !submitData.salary.startDate || !submitData.salary.endDate) return;
    const func = type === 'academic-research-personal' ? getAcademicResearchPersonnelPreview : getSeniorPersonnelPreview;
    func(grant.id, {
      isAccountOwner: Boolean(submitData.isAccountOwner),
      salaryStartDate: stringOrUndefined(dates.startDate),
      salaryEndDate: stringOrUndefined(dates.endDate),
      salary: submitData.salary.amount,
      monthCompensatedByOrganization: submitData.monthCompensatedByOrganization
    }, (preview: SeniorPreview) => {
      onChangePreviewFields({
        salaryPaidPerMonthByOrganization: preview.salaryPaidPerMonthByOrganization,
        percentOfSalary: preview.percentOfSalary,
      });
      onChangeData({
        ...submitData,
        monthCompensatedByOrganization: preview.monthCompensatedByOrganization,
      });
      const newNSFArray = preview.nsfCompensation.map((item: T.PersonalNSFCompensation, idx: number) => {
        const existingNSF = submitData.nsfCompensation?.find((existingItem: T.PersonalNSFCompensation) => existingItem.serialNumber === item.serialNumber);
        return {
          ...(existingNSF || {}),
          year: item.year,
          kind: nsfCompensation[idx]?.kind
        }
      });
      onChangeNSFCompensation(newNSFArray);
    });
  }, [dates, data, type, getAcademicResearchPersonnelPreview,
    getSeniorPersonnelPreview, grant, nsfCompensation
  ]);

  const onBlurDates = useCallback((field: 'startDate' | 'endDate', value: any) => {
    const salary = {
      ...data.salary,
      ...dates
    };
    const func = type === 'academic-research-personal' ? getAcademicResearchPersonnelPreview : getSeniorPersonnelPreview;
    const onConfirm = () => {
      func(grant.id, {
        isAccountOwner: Boolean(data.isAccountOwner),
        salaryStartDate: stringOrUndefined(salary.startDate),
        salaryEndDate: stringOrUndefined(salary.endDate), //@ts-ignore
        salary: salary.amount,
        monthCompensatedByOrganization: undefined
      }, (preview: SeniorPreview) => {
        onChangePreviewFields({
          salaryPaidPerMonthByOrganization: preview.salaryPaidPerMonthByOrganization,
          percentOfSalary: preview.percentOfSalary,
        });
        onChangeData({
          ...data,
          salary: salary,
          monthCompensatedByOrganization: preview.monthCompensatedByOrganization,
        });
        if (!person?.id) {
          const newNSFArray = preview.nsfCompensation.map((item: T.PersonalNSFCompensation, idx: number) => ({
            year: item.year,
            serialNumber: item.serialNumber ?? idx + 1,
            kind: nsfCompensation[idx]?.kind
          }));
          onChangeNSFCompensation(newNSFArray);
        }
      });
    }
    if (!person?.salary?.startDate && !person?.salary?.endDate && (salary.startDate && salary.endDate)) {
      onConfirm();
      return;
    }
    if (!salary.startDate && !salary.endDate) {
      return
    }
    if (dates[field] !== value) {
      if (!salary.amount || !dates.startDate || !dates.endDate) return;
      confirm({
        plain: true,
        okText: 'Recalculate',
        pureConfirm: true,
        cancelText: 'Leave unchanged',
        text: 'The number of months compensated by university per year will be recalculated based on the new date(s). ',
        onCancel: () => {
          func(grant.id, {
            isAccountOwner: Boolean(data.isAccountOwner),
            salaryStartDate: stringOrUndefined(salary.startDate),
            salaryEndDate: stringOrUndefined(salary.endDate), //@ts-ignore
            salary: salary.amount,
            monthCompensatedByOrganization: data.monthCompensatedByOrganization
          }, (preview: SeniorPreview) => {
            onChangePreviewFields({
              salaryPaidPerMonthByOrganization: preview.salaryPaidPerMonthByOrganization,
              percentOfSalary: preview.percentOfSalary,
            });
            onChangeData({
              ...data,
              salary: salary,
              monthCompensatedByOrganization: preview.monthCompensatedByOrganization,
            });
            const newNSFArray = preview.nsfCompensation.map((item: T.PersonalNSFCompensation, idx: number) => ({
              year: item.year,
              serialNumber: item.serialNumber || idx + 1,
              kind: nsfCompensation[idx]?.kind
            }));
            onChangeNSFCompensation(newNSFArray);
          });
        },
        onConfirm: onConfirm
      })
    }
  }, [dates, person, data, type, getAcademicResearchPersonnelPreview,
    getSeniorPersonnelPreview, grant, nsfCompensation
  ]);

  useEffect(() => {
    if (person?.id) {
      handleChangePreviewFields();
    }
  }, [person?.id])

  const handleChangeData = useCallback((field: Field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const handleChangeDates = useCallback((field: Field) => {
    onChangeDates({
      ...dates,
      ...field
    });
  }, [dates]);

  const title = useMemo(() => {
    if (type === personalTypes.seniorPersonal) {
      return 'Senior personnel';
    }
    return 'Academic Research Associates';
  }, [type]);

  const buttonText = useMemo(() => {
    if (type === personalTypes.seniorPersonal) {
      return 'Account owner is Senior personnel';
    }
    return 'Account owner is Academic Researcher ';
  }, [type]);

  const subtitle = useMemo(() => {
    if (type === personalTypes.seniorPersonal) {
      const id = index < 1 ? grant.seniorPersonal.length + 1 : index;
      return `Person ${id}`;
    }
    const id = index < 1 ? grant.academicResearchAssociate.length + 1 : index
    return `Person ${id}`;
  }, [index, grant, type]);

  const onDuplicateFromProfile = useCallback(() => {
    const {author, organization} = grant;
    const ownerIsStudent = [
      'graduateStudents',
      'undergraduateStudents'
    ].includes(author.personalReference?.kind as string);
    const ownerIsPerson = (author.personalReference && author.personalReference.id !== params.personId);
    if (author.personalReference !== undefined && (ownerIsStudent || ownerIsPerson)) {
      const personnelType = personalTypes[author.personalReference.kind];
      let link = `/grants/${grant.id}/personnel/${personnelType}/${author.personalReference?.id}/edit`;
      if (ownerIsStudent) {
        link = `/grants/${grant.id}/personnel/${personnelType}/edit`
      }
      confirm({
        text: 'Another person/personnel type is marked as Account owner. Do you want to remove the mark?',
        plain: true,
        onConfirm: () => {
          history.push(link)
        },
        onCancel: () => onChangeData({
          ...data,
          isAccountOwner: false
        })
      })
      return;
    }
    const newData = {
      ...data,
      firstName: author.firstName,
      lastName: author.lastName,
      positionTitle: author.positionTitle,
      subGroup: organization.subGroup,
      department: organization.department,
      salary: {
        ...data.salary,
        ...dates,
        amount: author.yearSalary
      },
      isAccountOwner: true
    };
    onChangeData(newData);
    handleChangePreviewFields(undefined, newData);

  }, [params, dates, data, handleChangePreviewFields, history, grant]);

  const onRemoveDuplicateFromProfile = useCallback(() => {
    confirm({
      title: 'Please confirm autocomplete revocation.',
      text: 'After this you can apply autocomplete again.',
      type: 'success',
      icon: 'check-circle',
      onConfirm: () => {
        const newData = {
          ...data,
          firstName: '',
          lastName: '',
          positionTitle: '',
          subGroup: '',
          department: '',
          salary: {
            ...data.salary,
            ...dates,
            amount: ''
          },
          isAccountOwner: false
        };
        onChangeData(newData);
        handleChangePreviewFields(undefined, newData);
      }
    });
  }, [data, dates, handleChangePreviewFields]);


  const onSubmit = useCallback(() => {
    const submitData = {
      ...data,
      ...previewFields,
      fringeBenefitRate: Number(data.fringeBenefitRate) / 100,
      salary: {
        ...data.salary,
        ...dates,
        monthCompensatedByOrganization: data.monthCompensatedByOrganization,
        amount: Number(data.salary.amount),
      },
      nsfCompensation: data.isRequestingSupport
        ? nsfCompensation.map((item: PersonalNSFCompensation, idx: number) => ({
          ...item,
          serialNumber: item.serialNumber || idx + 1
        }))
        : undefined,
    };
    if (type === personalTypes.seniorPersonal) {
      editSeniorPerson(grant.id, params.personId, submitData);
      trackUserAction("Adds Senior Personnel")
    }
    if (type === personalTypes.academicResearchAssociate) {
      editAcademicResearchPerson(grant.id, params.personId, submitData);
      trackUserAction("Adds Academic Research Associate")
    }
  }, [
    dates,
    data,
    editAcademicResearchPerson,
    type,
    nsfCompensation,
    previewFields,
    params,
    grant,
    editSeniorPerson
  ]);

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GrantFormWrapper title={title} subtitle={(
      <div className={styles.subtitleWrapper}>
        <Typo type="h4">{subtitle}</Typo>
        <div className={styles.btnWrapper}>
          <Form>
            <Toggler
              label={buttonText}
              name="isAccountOwner"
              className={styles.toggler}
              checked={data.isAccountOwner}
              onChange={data.isAccountOwner ? onRemoveDuplicateFromProfile : onDuplicateFromProfile}
            />
          </Form>
          <Help contentClassName={styles.help}>
            Use this button if you are the Account owner. This is necessary to
            display the "Account owner salary
            budget" on the Dashboard. This button can only be used once for all types of personnel. “First name”,
            “Last
            name”, “Position title”, “College (if a subgroup of your university)”, “Department”, “Year salary” will be
            duplicated in the form below from Grant settings.
          </Help>
        </div>
      </div>
    )}>
      <>
        <PersonForm
          loading={loading}
          onSubmit={onSubmit}
          data={data}
          onChange={handleChangeData}
          onBlurDates={onBlurDates}
          dates={dates}
          onChangeDates={handleChangeDates}
          errors={errors}
          nsfCompensation={nsfCompensation}
          isSenior={type === personalTypes.seniorPersonal}
          isUpdate={Boolean(person?.id)}
          onBlurYearSalary={handleChangePreviewFields}
          onChangeNSFCompensation={onChangeNSFCompensation}
          previewFields={previewFields}
          trackError={trackError}
          trackExit={trackExit}
        />
      </>
    </GrantFormWrapper>
  )
}
export default CreateEditPerson;
