import {useCallback, useRef, useState} from 'react';
import {AxiosResponse} from 'axios';
import useApi from './useApi';
import useUI from './useUI';
import toast from 'components/toast';
import mtdcAdapter, { TableData } from './common/mtdcAdapter';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";

type iUseMtdc  = {
  loading: boolean;
  submitLoading: boolean;
  tableData: TableData;
  preview: (grantId: string, data: any) => void;
  update: (grantId: string, data: any, cb?: () => void) => void;
  getIndirectCosts: (grantId: string) => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useIndirect = (props: Props = {}):iUseMtdc => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'Indirect costs'
  const form_subcategory = null;
  const {formStarted, formSaveAttempted, formSaved} = useMixPanel();
  const {grant} = useGrants();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const { getOneWithSide } = useGrants();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [previewLoading, onChangePreviewLoading] = useState<boolean>(false);
  const [submitLoading, onChangeSubmitLoading] = useState<boolean>(false);
  const [tableData, onChangeTableData] = useState<TableData>(mtdcAdapter());
  const { loader, onShowNavigationMessage } = useUI();
  const controller = useRef(new AbortController());

  const getIndirectCosts = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.getIndirectCosts(grantId)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        const correctData = mtdcAdapter(response.data);
        onChangeTableData(correctData);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api]);

  const preview = useCallback((grantId: string, data: any) => {
    if (previewLoading) {
      controller.current.abort();
      controller.current = new AbortController();
    }
    loader.start();
    onChangePreviewLoading(true);
    api.previewIndirectCosts(grantId, data, controller.current.signal)
      .then((response: AxiosResponse) => {
        loader.stop();
        const correctData = mtdcAdapter(response.data);
        onChangeTableData(correctData);
        onChangePreviewLoading(false);
      })
      .catch((err) => {
        loader.stop();
        onChangePreviewLoading(false);
      });
  }, [api, previewLoading, controller, loader]);

  const update = useCallback((grantId: string, data: any, cb?: () => void) => {
    onChangeSubmitLoading(true);
    api.updateIndirectCosts(grantId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeSubmitLoading(false);
        const correctData = mtdcAdapter(response.data);
        onChangeTableData(correctData);
        onShowNavigationMessage();
        getOneWithSide(grantId);
        if (cb) cb();
        toast.success({
          title: 'The indirect costs have been updated',
          message: 'Changes have been successfully saved',
        });
      })
      .catch(() => {
        onChangeSubmitLoading(false);
      });

    trackFormSaveAttempted()
  }, [api, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage, trackFormSaveAttempted]);

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    preview,
    submitLoading,
    getIndirectCosts,
    update,
    tableData,
    loading,
    trackFormStarted
  };
}

export default useIndirect;
