import {ChangeEvent, FC} from 'react';
import Field from 'components/field';
import { Select, Input, Button } from 'components/form';
import styles from './month.module.css';
import {ListItem} from 'helpers/utils';
import {MaIEBreakdownMonth, TravelCostLodgingMonth} from 'store/grants/types';
import {Errors} from 'helpers/errors';

type Props = {
  data: TravelCostLodgingMonth | MaIEBreakdownMonth;
  maie?: boolean;
  onChange: (field: Record<string, any>, blur?: boolean) => void;
  onDelete?: () => void;
  onBlur?: () => void;
  months: ListItem[];
  withoutHelp?: boolean;
  errors: Errors;
  field: 'mAndIeBreakdown.fullDays' | 'lodgingBreakdown';
  index: number;
}

const MonthForm:FC<Props> = ({ data, withoutHelp, onBlur, maie, field, errors, index, months, onChange, onDelete }) => {

  const totalHelp = (
    <span>
      Please double check if the total lodging amount displayed here, is the same as the amount you received on the <a href="https://www.gsa.gov/" target="_blank" rel="noreferrer">Home</a> website.
      If not please go back and double check the number you input.
    </span>
  );

  const value = maie ? data as MaIEBreakdownMonth : data as TravelCostLodgingMonth;

  return (
    <div className={styles.month}>
      <Field last>
        <Select name={`month_${index}`} errors={errors[`${field}.months[${index}].month`]} label="Month" required options={months} placeholder="Select a month"
                value={value.month} onChange={(month) => onChange({ month }, true)}
        />
        <Input name={`dailyRate_${index}`} errors={errors[`${field}.months[${index}].dailyRate`]} type="number" label="Daily Rate" required placeholder="Enter a daily rate"
               onBlur={onBlur} money
               value={value.dailyRate} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({dailyRate: event.target.value})}
        />
      </Field>
      <Field last withHelp={!withoutHelp}>
        <Input name={`nights_${index}`} errors={errors[`${field}.months[${index}].${maie ? 'numberOfDays' : 'nights'}`]} type="number"
               help={withoutHelp ? undefined : "If you input just a fiscal year, then you need to manually calculate the number of nights"}
               label="Number of nights" required onBlur={onBlur} placeholder="Enter a number of nights" //@ts-ignore
               value={maie ? value.numberOfDays : value.nights} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({[maie ? 'numberOfDays' : 'nights']: event.target.value})}
        />
        <Input name={`total_${index}`} //@ts-ignore
               value={value.total === undefined ? '' : value.total} disabled readOnly help={totalHelp}
               label="Total for month" helpDebounce={1000} money auto
               placeholder="Total for month"
        />
      </Field>
      <div className={styles.btns}>
        <Button onClick={onDelete} disabled={!onDelete} name="delete-month" preIcon="trash-01" type="bordered" htmlType="button">Delete month</Button>
      </div>
    </div>
  );
}

export default MonthForm;
