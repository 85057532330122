import {FC, ChangeEvent, useState} from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import Icon from 'components/icon';
import styles from 'components/form/form.module.css';
import {useFormContext} from 'react-hook-form';
import {findInputError, RHErrors} from '../../../helpers/errors';

type Props = {
  name: string;
  value?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errors?: string[];
  field?: boolean;
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  help?: string;
  inputClassName?: string;
  invert?: boolean;
  className?: string;
}

const Checkbox:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    className, errors = [], required,
    disabled, inputClassName, hint, invert,
    help, field
  } = props;
  const {
    register,
    formState,
  } = useFormContext();

  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;

  const validation = register(name,
    {
      required: {value: Boolean(required), message: 'Field is required'},
      onChange: disabled ? () => {} : onChange,
      value
    })
  return (
    <label cypress-id={`${name}-checkbox`} className={cx(styles.togglerWrapper, className, { [styles.invert]: invert})}>
      <input
        type="checkbox"
        checked={value} className={cx(styles.hidden, styles.togglerCheckbox)}
        disabled={disabled}
        {...validation}
      />
      <div className={cx(styles.customCheckbox, inputClassName)}>
        <Icon icon="check" size={14} className={styles.check} />
      </div>
      <Field required={required} className={cx(styles.checkboxLabel, 'checkbox-label', {[styles.inField]: field})} helpInLabel help={help} label={label} hint={hint} errors={errorsList} name={`${name}-field`}/>
    </label>
  )
}

export default Checkbox;
