import {Switch, Route} from 'react-router-dom';
import {useEffect} from 'react';
import useEnums from 'hooks/useEnums';
import useAuth from 'hooks/useAuth';
import ProtectedRoute, {AuthRoute} from 'containers/protectedRoute';
import Grant from 'pages/grant';
import Home from 'pages/home';
import SignIn from 'pages/auth/signin';
import SignUp from 'pages/auth/signUp';
import ConfirmEmail from 'pages/auth/signUp/confirm';
import Forgot from 'pages/auth/forgot';
import Recovery from 'pages/auth/recovery';
import ForgotSuccess from 'pages/auth/forgot-success';
import Settings from 'pages/settings';
import CompleteSubAward from './pages/completeSubaward';
import {useIntercom} from "react-use-intercom";
import {useLocation} from "react-router";

const AppRoutes = () => {
  const location = useLocation();
  const {me} = useAuth();
  const {getEnums} = useEnums();
  const {
    shutdown,
  } = useIntercom();

  useEffect(() => {
    getEnums();
    me();
    shutdown()
  }, []);

  useEffect(() => {
    const pagePath = location.pathname + location.search;

    // @ts-ignore
    window.dataLayer.push({
      event: 'pageview',
      page: pagePath
    });
  }, [location]);

  return (
    <Switch>
      <Route path="/complete-subaward/:token" component={CompleteSubAward}></Route>
      <Route path="/login" render={() => <AuthRoute><SignIn/></AuthRoute>}></Route>
      <Route path="/password-change/:hash" render={() => <AuthRoute><Recovery/></AuthRoute>}></Route>
      <Route path="/sign-up" render={() => <AuthRoute><SignUp/></AuthRoute>}></Route>
      <Route path="/confirm-email/:hash" render={() => <AuthRoute><ConfirmEmail/></AuthRoute>}></Route>
      <Route path="/forgot" render={() => <Forgot/>}></Route>
      <Route path="/forgot-success" render={() => <AuthRoute><ForgotSuccess/></AuthRoute>}></Route>

      <Route path="/grants">
        <ProtectedRoute><Grant/></ProtectedRoute>
      </Route>
      <Route path="/settings">
        <ProtectedRoute><Settings/></ProtectedRoute>
      </Route>
      <Route path="/" exact render={() => <ProtectedRoute><Home/></ProtectedRoute>}></Route>
    </Switch>
  );
}

export default AppRoutes;
