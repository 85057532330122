import {FC, useEffect, useMemo} from 'react';
import cx from 'classnames';
import useGrants from 'hooks/useGrants';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import {getEventFromGrant} from 'helpers/getFromGrant';
import { GrantEventParticipantsTotal, GrantEventParticipantTotalYear} from 'store/grants/types';
import Table, { Cell } from 'components/table';
import {numberToMoney} from 'helpers/numbers';
import styles from './totals.module.css';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import useParticipants from '../../../../../hooks/useParticipants';


type Props = {
  totals: GrantEventParticipantsTotal[];
}

type ListItem = {
  title: string;
  headers: string[];
  data: Array<Cell[]>;
}

const prepareCells = (items: GrantEventParticipantTotalYear[], black?: boolean):Cell[] => {
  return items.map((item:GrantEventParticipantTotalYear) => (
    <span className={cx({[styles.gray]: !black})}>{numberToMoney(item.value)}</span>
  ))
}

const prepareTable = (item: GrantEventParticipantsTotal):ListItem => {
  const years = item.stipends.years.map((year: GrantEventParticipantTotalYear) => year.label);
  return {
    title: item.category || 'No category yet',
    headers: ['Reimbursement type', ...years, 'Total'],
    data: [
      ['Stipend', ...prepareCells(item.stipends.years), numberToMoney(item.stipends.totals)],
      ['Travel', ...prepareCells(item.travel.years), numberToMoney(item.travel.totals)],
      ['Expenses', ...prepareCells(item.expenses.years), numberToMoney(item.expenses.totals)],
      ['Total', ...prepareCells(item.totals.years, true), numberToMoney(item.totals.totals)],
    ]
  }
}

const EventTotals:FC<Props> = ({totals}) => {

  const list:ListItem[] = useMemo(() => {
    return totals.map(prepareTable)
  }, [totals]);

  return (
    <div className={styles.tables}>
      {list.map((table: ListItem, idx: number) => (
        <Table className={styles.table} title={table.title} headers={table.headers} data={table.data} key={idx}/>
      ))}
    </div>
  );
}


const EventTotalsPage:FC = () => {
  const { grant } = useGrants();
  const {
    getEventTotals,
    loading,
    eventTotals
} = useParticipants();
  const params:Record<string, any> = useParams();
  const { sidebarWidth } = useUI();

  const { item, index } = getEventFromGrant(grant, params.eventId);
  const key = `${params.id}-event-${params.eventId}-totals`;

  useEffect(() => {
    if (params.eventId) getEventTotals(params.id, params.eventId);
  }, [params.eventId])

  return (
    <GrantFormWrapper title={`Participants Support. Event ${index}`} key={key} subtitle="Totals">
      <>
        {item ? <EventTotals totals={eventTotals} /> : <span></span>}
        {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
      </>
    </GrantFormWrapper>
  );
}

export default EventTotalsPage;
