import {PublicationsUnit, PublicationsUnitYear, Publications} from './types';

export const publicationsYearAdapter = (data: PublicationsUnitYear, idx: number) => {
  return {
    numberOfUnits: data.numberOfUnits ?? '',
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    calculatedCompensationAfterInflation: data.calculatedCompensationAfterInflation ?? '',
    calculatedCompensationBeforeInflation: data.calculatedCompensationBeforeInflation ?? '',
    isUserCompensation: Boolean(data.isUserCompensation),
    userCompensationBeforeInflation: data.userCompensationBeforeInflation ?? undefined,
    userCompensationAfterInflation: data.userCompensationAfterInflation ?? undefined,
  }
}

export const publicationsUnitAdapter = (data: PublicationsUnit) => {
  return {
    name: data.name ?? '',
    currentCost: data.currentCost ?? '',
    id: data.id ?? '',
    years: data.years ? data.years.map(publicationsYearAdapter) : []
  }
}

export const publicationsAdapter = (data: Publications) => {
  return {
    units: data?.units ? data.units.map(publicationsUnitAdapter) : []
  }
}
