import {
  ConsultantServiceType,
  ConsultantServicesType, ConsultantServiceYear, ConsultantServiceProvider
} from './types';

const consultantServiceProviderAdapter = (data: ConsultantServiceProvider) => ({
  numberOfConsultantsInOrganization: data.numberOfConsultantsInOrganization ?? '',
  id: data.id ?? '',
  isDailyCompensation: data.isDailyCompensation ?? undefined,
  dailyCompensationRate: data.dailyCompensationRate ?? '',
  numberOfDays: data.numberOfDays ?? '',
  yearlyLumpSum: data.yearlyLumpSum ?? '',
  additionalCompensation: data.additionalCompensation ?? '',
  isForeignConsultant: Boolean(data.isForeignConsultant),
  foreignConsultantInfo: data.foreignConsultantInfo ?
    {
      needSupportExplanation: data.foreignConsultantInfo?.needSupportExplanation,
      receivedSupportExplanation: data.foreignConsultantInfo?.receivedSupportExplanation,
      isContributes: data.foreignConsultantInfo?.isContributes,
      isOffers: data.foreignConsultantInfo?.isOffers,
    }
    : null,
  trips: data.trips ?? [],
  travelCostsType: data.travelCostsType ?? '',
  estimatedTravelCosts: data.estimatedTravelCosts ?? '',
  name: data.name ?? '',
  regularCompensationBeforeInflation: data.regularCompensationBeforeInflation ?? undefined,
  regularCompensationAfterInflation: data.regularCompensationAfterInflation ?? undefined,
  additionalCompensationBeforeInflation: data.additionalCompensationBeforeInflation ?? undefined,
  additionalCompensationAfterInflation: data.additionalCompensationAfterInflation ?? undefined,
  travelCostsCompensationBeforeInflation: data.travelCostsCompensationBeforeInflation ?? undefined,
  travelCostsCompensationAfterInflation: data.travelCostsCompensationAfterInflation ?? undefined,
  compensationBeforeInflation: data.compensationBeforeInflation ?? undefined,
  compensationAfterInflation: data.compensationAfterInflation ?? undefined,
})

export const consultantServiceItemAdapter = (data: ConsultantServiceType): ConsultantServiceType => {
  return {
    id: data.id ?? '',
    name: data.name ?? '',
    providersType: data.providersType ?? undefined,
    isRegularCostInflation: Boolean(data.isRegularCostInflation),
    isAdditionalCostInflation: Boolean(data.isAdditionalCostInflation),
    isTravelCostInflation: Boolean(data.isTravelCostInflation),
    years: data.years.map((item: ConsultantServiceYear, idx: number) => ({
      year: item.year ?? [],
      serialNumber: item.serialNumber ?? idx + 1,
      providers: item.providers ? item.providers.map(consultantServiceProviderAdapter) : []
    }))
  }
}

export const consultantServicesAdapter = (data: ConsultantServicesType) => {
  return data ? data.map(consultantServiceItemAdapter) : []
}
