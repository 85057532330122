import { FC } from 'react';
import { toast as notification, TypeOptions, ToastContent, ToastContentProps, ToastOptions, Id } from 'react-toastify';
import cx from 'classnames';
import IconComponent from 'components/icon';
import close from './close.svg';
import styles from './toast.module.css';

type Data = {
  title: string;
  message: string;
}

type NotifyFunc = <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id

type BMToast = {
  info: (content: Data | string) => (props: ToastContentProps) => void;
  success: (content: Data | string) => (props: ToastContentProps) => void;
  warning: (content: Data | string) => (props: ToastContentProps) => void;
  error: (content: Data | string) => (props: ToastContentProps) => void;
  default: (content: Data | string) => (props: ToastContentProps) => void;
}

const icons:Record<TypeOptions, string> = {
  info: 'alert-circle',
  success: 'check-verified-01',
  warning: 'alert-triangle',
  error: 'alert-circle',
  default: 'alert-circle',
}
interface Props extends ToastContentProps {
  data: Data | string;
}

const Content:FC<Props> = (props: Props) => {
  const {toastProps, closeToast, data} = props;
  let title = '';
  let message = data as string;
  if (typeof data !== 'string') {
    title = data.title;
    message = data.message;
  }
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.iconWrapper, styles[toastProps.type])}>
        <IconComponent className={styles.icon} size={20} icon={icons[toastProps.type]} />
      </div>
      <div className={styles.content}>
        {title ? <p className={styles.title}>{title}</p> : null}
        <p className={styles.message}>{message}</p>
      </div>
      <img src={close} className={styles.close} alt="close" onClick={closeToast} draggable={false} role="button"/>
    </div>
  )
}
const methods:TypeOptions[] = ['info', 'success', 'warning', 'error', 'default'];//@ts-ignore
const toast:BMToast = methods.reduce((result: Record<TypeOptions, NotifyFunc>, key: TypeOptions) => ({
  ...result,
  [key]: (data: Data | string) => {
    let func:NotifyFunc = notification;
    if (key !== 'default') func = notification[key];
    return func((props: ToastContentProps) => <Content {...props} data={data}/>, {
      icon: false,
      closeButton: false
    });
  }
}), {} as BMToast);

export default toast;
