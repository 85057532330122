import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button} from 'components/form'
import {ComputerService, ComputerServiceYear} from 'store/grants/otherDirectCosts/ComputerService/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getComputerServiceFromODC} from 'helpers/getFromGrant';
import useCS from 'hooks/useCS';
import styles from './cs.module.css';
import useUI from 'hooks/useUI';

type Props = {
  item: ComputerService;
  grantId: string;
}

const ComputerServiceInfoForm: FC<Props> = ({item, grantId}) => {
  const [data, onChangeData] = useState<ComputerService>(item);
  const {
    updateService,
    trackError,
    trackExit,
    trackFormStarted
  } = useCS({
    form_page_type: 'Service info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {confirmWithHide} = useUI();

  const onSubmit = useCallback(() => {
    const shouldRecalculate = item.years.reduce((res: number, item: ComputerServiceYear) => res + Number(item.multiplier ?? 0), 0) > 0;
    confirmWithHide({
      title: 'Compensation will be recalculated',
      text: 'The total cost before and after inflation for all years will be recalculated based on the new cost',
      type: 'warning',
      icon: 'alert-hexagon',
      when: shouldRecalculate && data.cost !== item.cost,
      okText: 'Recalculate',
      id: `odc-update-cs-${grantId}`,
      onConfirm: () => updateService(grantId, item.id, {
        name: data.name,
        cost: data.cost
      })
    });
  }, [data, confirmWithHide, grantId, item, updateService])

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field last>
        <Input name="name"
               placeholder="Enter a name"
               value={data.name}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, name: event.target.value})}
               label="Name of Computer Payment" required/>
        <Input name="cost"
               placeholder="Enter current cost"
               value={data.cost} type="number" money
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, cost: event.target.value})}
               label="Current cost per service" required/>
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" name="submit">Save</Button>
      </div>
    </Form>
  );
}

const ComputerServiceInfoPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getComputerServiceFromODC(grant, params.serviceId);

  return (
    <GrantFormWrapper title="Other Direct Costs"
                      subtitle={`Computer Services. Computer Service ${index > 0 ? index : ''}`}>
      {
        item
          ? <ComputerServiceInfoForm grantId={grant.id} key={`${grant.id}-odc-cs-service-${params.serviceId}`}
                                     item={item}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default ComputerServiceInfoPage;
