import {FC, useCallback, useMemo} from 'react';
import Typo from 'components/typo';
import CreateBtn from 'pages/grant/paper/card/create';
import styles from './subawards.module.css';
import useUrl from 'hooks/useUrl';
import useSubawards from 'hooks/useSubawards';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import {Subaward as SubawardType} from 'store/grants/otherDirectCosts/Subawards/types';
import {FullGrant} from 'store/grants/types';

type Props = {
  side: FullGrant;
}


const OtherSubawards:FC<Props> = ({ side }) => {
  const url = useUrl();
  const { loading, destroy, create } = useSubawards();

  const handleDeleteAward = useCallback((id?: string) => {
    if (id) destroy(side.id, id, url.categoryId === id)
  }, [url, destroy, side]);

  const list = useMemo(() => {
    return side.otherDirectCosts.subawards.map((item: SubawardType) => {
      const links = [
        {
          label: 'Subaward info',
          to: `/grants/${side.id}/otherDC/subawards/${item.id}/edit`,
          active: url.categoryId === item.id && url.section === 'edit'
        },
        {
          label: 'Subawardee form',
          to: `/grants/${side.id}/otherDC/subawards/${item.id}/data`,
          active: url.categoryId === item.id && url.section === 'data',
          isDisabled: !item.name
        }
      ]
      return (
        <Item
          title={item.name || 'Untitled subaward'}
          onDelete={() => handleDeleteAward(item.id)}
          links={links}
          key={item.id}
          active={url.categoryId === item.id}
        />);
    })
  }, [side, handleDeleteAward, url]);

  return (
    <>
      <Anchor thin active={url.category === 'otherDC' && url.subCategory === 'subawards' && url.categoryId === 'general-info'} to={`/grants/${side.id}/otherDC/subawards/general-info`}>
          General info
      </Anchor>
      {list.length > 0
        ? list
        : <Typo className={styles.placeholder} size={14} type="div">No subawards have been created yet</Typo>
      }
      {list.length > 0 ?
        <TotalAnchor total={side.otherDirectCosts.subawardsTotalAmount} to={`/grants/${side.id}/otherDC/subawards/totals`} active={url.categoryId === 'totals' && url.subCategory === 'subawards'}>
          Totals
        </TotalAnchor>
        : null
      }
      <CreateBtn tip="To add a subaward, you must add grant years first"
                 name="add-grant-subaward"
                 loading={loading} side={side}
                 onClick={() => create(side.id)}
      >
        Add subaward
      </CreateBtn>
    </>
  );
}

export default OtherSubawards;
