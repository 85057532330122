import {ChangeEvent, FC, useCallback, useMemo} from 'react';
import Field from 'components/field';
import {Input, Tags} from 'components/form';
import Typo from 'components/typo';
import useEnums from 'hooks/useEnums';
import {Errors} from 'helpers/errors';
import {FullGrant} from 'store/grants/types';
import styles from './personnel.module.css';
import Icon from '../../../../../../components/icon';

type Props = {
  data: {
    seniorPersonnel: any;
    academicResearchAssociate: any;
    postDocs: number | string;
    graduateStudents: number | string;
    undergraduateStudents: number | string;
    civilService: number | string;
    union: number | string;
    tempAndCasual: number | string;
    otherPersonnel: number | string;
  };
  hint?: string;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
  onBlur?: (event: any, data?: any) => void;
  errors?: Errors;
  grant: FullGrant;
}

const TravelPersonnel:FC<Props> = ({data, hint, grant, onBlur, onChange}) => {
  const { seniorPersonnel, academicPersonnel } = useEnums();

  const showPlaceholder = useMemo(() => {
    return grant.seniorPersonal.length < 1 &&
      grant.academicResearchAssociate.length < 1 &&
      grant.postDocPersonnel.length < 1 &&
      !grant.graduateStudents.academicTerm &&
      !grant.undergraduateStudents.academicTerm &&
      grant.civilService.length < 1 &&
      grant.unionPersonnel.length < 1 &&
      grant.tempAndCasual.length < 1 &&
      grant.otherPersonnel.length < 1;
  }, [grant]);

  const moreThanInTheGrant = useMemo(() => {
    return (
      grant.postDocPersonnel.length < data.postDocs ||
      grant.civilService.length < data.civilService ||
      grant.unionPersonnel.length < data.union ||
      grant.tempAndCasual.length < data.tempAndCasual ||
      grant.otherPersonnel.length < data.otherPersonnel
    );
  }, [data, grant]);

  const title = 'Add required personnel type to enable';

  return (
    <>
      {showPlaceholder
      ?
        <div className={styles.placeholder}>
          <Icon icon="alert-triangle" className={styles.placeholderIcon} />
          <div className={styles.placeholderTexts}>
            <Typo bold>To add Trip attendees, first, make sure to add grant personnel in the Personnel
              section.</Typo>
            <Typo>If you cannot change the number of trip attendees, it means that type of personnel has not been added
              yet. Please add the necessary personnel in the Personnel section before proceeding to add Trip
              attendees.</Typo>
          </div>
        </div>
      : null
      }
      <Field top>
        <Tags options={seniorPersonnel} value={data.seniorPersonnel} onChange={(seniorPersonnel) => onChange({seniorPersonnel}, true)}
              disabled={seniorPersonnel.length < 1} title={title}
              name="seniorPersonnel" label={`Senior personnel (${seniorPersonnel.length})`}
              placeholder="Select senior personnel"
        />
        <Tags options={academicPersonnel} disabled={academicPersonnel.length < 1}
              name="academicResearchAssociate" label={`Academic Research Associates (${academicPersonnel.length})`}
              value={data.academicResearchAssociate} onChange={(academicResearchAssociate) => onChange({academicResearchAssociate}, true)}
              placeholder="Select academic research associates" title={title}
        />
      </Field>

      {hint ? <Typo className={styles.mb20}>{hint}</Typo> : null}
      <Field last className={styles.fourInputs}>
        <Input name="postDocs" value={data.postDocs} onBlur={onBlur} title={title}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({postDocs: event.target.value})}
               label={`Post docs`} type="number" disabled={grant.postDocPersonnel.length < 1} />
        <Input name="graduateStudents" value={data.graduateStudents}
               title={title}
               onBlur={onBlur} disabled={!grant.graduateStudents.academicTerm}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({graduateStudents: event.target.value})}
               label="Graduate students" type="number" />
        <Input name="undergraduateStudents" value={data.undergraduateStudents} onBlur={onBlur} title={title}
               disabled={!grant.undergraduateStudents.academicTerm}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({undergraduateStudents: event.target.value})}
               label="Undergraduate students" type="number" />
        <Input name="civilService" value={data.civilService} onBlur={onBlur} title={title}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({civilService: event.target.value})}
               label={`Civil service`} type="number" disabled={grant.civilService.length < 1} />
      </Field>
      {
        moreThanInTheGrant ?
          <div className={styles.notification}>
            <Icon icon="alert-triangle" size={16} className={styles.notificationIcon} />
            <Typo className={styles.notificationText}>Please note that more members have been added to the trip than are in the grant.</Typo>
          </div>
          :
          null
      }
      <Field>
        <Input name="union" onBlur={onBlur}
               value={data.union} title={title}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({union: event.target.value})}
               label={`Union`} type="number" disabled={grant.unionPersonnel.length < 1} />
        <Input name="tempAndCasual" onBlur={onBlur}
               value={data.tempAndCasual} title={title}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({tempAndCasual: event.target.value})}
               label={`Temp and casual`} type="number" disabled={grant.tempAndCasual.length < 1} />
        <Input name="otherPersonnel" onBlur={onBlur}
               value={data.otherPersonnel} title={title}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({otherPersonnel: event.target.value})}
               label={`Other personnel`} type="number" disabled={grant.otherPersonnel.length < 1} />
        <div></div>
      </Field>
    </>
  );
}

export default TravelPersonnel;
