import { FC, useState, Fragment, useEffect, useRef, useCallback, useMemo, ReactElement, ChangeEvent } from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import Icon from 'components/icon';
import styles from 'components/form/form.module.css';
import {useFormContext, Controller} from 'react-hook-form';
import {findInputError, RHErrors} from '../../../helpers/errors';

type Option = {
  label: string | ReactElement | number;
  value: any;
}

type Props = {
  name: string;
  options: Option[];
  placeholder?: string;
  value?: any[];
  onChange?: (value: any[]) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  title?: string;
  min?: number;
  inputClassName?: string;
  help?: ReactElement | string;
  className?: string;
}

const Tags:FC<Props> = (props: Props) => {
  const {
    name, value = [], onChange, label,
    className, errors = [], required,
    disabled, inputClassName, hint,
    options, placeholder = 'Enter to find',
    help, title
  } = props;
  const [show, onChangeShow] = useState<boolean>(false);
  const [search, onChangeSearch] = useState<string>('');
  const input = useRef<HTMLDivElement | null>(null);

  const {
    formState
  } = useFormContext();
  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const uniqueClassName = useMemo(() => `bm-autocomplete-${name}`, [name]);
  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;
  const handleClick = useCallback((event: any) => {
    const inSelect = event.target?.closest(`.${uniqueClassName}`);
    if (show && !inSelect) {
      onChangeShow(false);
    }
    return;
  }, [show, uniqueClassName]);


  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [show, handleClick]);

  const handleChange = useCallback((item: Option) => {
    if (onChange) {
      onChange([...value, item.value]);
      onChangeSearch('');
    }
    onChangeShow(false);
  }, [onChangeShow, value, onChangeSearch, onChange]);

  const handleChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(event.target.value);
  }, [onChangeSearch]);

  const handleTrigger = useCallback(() => {
    if (disabled) return;
    onChangeShow(true);
  }, [onChangeShow, disabled]);

  const onDeleteTag = useCallback((tag: Option) => {
    const newValue = value.filter((item: any) => item!== tag.value);
    if (onChange) onChange(newValue);
  }, [onChange, value]);

  const tags:Option[] = useMemo(() => {
    return options.filter((item: Option) => value.includes(item.value));
  }, [value, options]);

  const list:Option[] = useMemo(() => {
    const preparedSearch = search.replace(/ /ig, '').toLowerCase();
    return options
      .filter((item: Option) => !value.includes(item.value))
      .filter((item: Option) => String(item.label).replace(/ /ig, '').toLowerCase().includes(preparedSearch));
  },[search, value, options]);

  const rules = {
    validate: {
      required: () => {
        if (required) return value.length < 1 ? 'Field is required' : undefined;
        return undefined;
      }
    }
  }

  return (
    <Field help={help} className={cx(className, styles.tagsFieldWrapper)} required={required} label={label} hint={hint} errors={errorsList} name={name}>
      <Controller
        defaultValue={value} name={name}
        rules={rules}
        render={({field}) => (
          <div ref={input} className={cx(styles.selectWrapper, styles.tagsWrapper, styles.withPreIcon, uniqueClassName)}>
            <div className={styles.tagsInput}>
              <input onFocus={handleTrigger} title={disabled ? title : undefined} disabled={disabled} placeholder={placeholder} value={search}
                     cypress-id={field.name}
                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                       handleChangeSearch(event);
                       field.onChange(value);
                     }}
                     ref={field.ref}
                     className={cx(styles.input, styles.autocomplete, styles.select, inputClassName, {
                       [styles.hasError]: invalid,
                       [styles.disabled]: disabled,
                       [styles.open]: show
                     })}/>
              <Icon icon="search-lg" className={cx(styles.preicon, styles.icon)}/>
            </div>
            <div className={styles.tags}>
              {tags.map((tag: Option, idx: number) => (
                <span key={idx} className={styles.tag}>{tag.label} <Icon onClick={() => onDeleteTag(tag)} icon="x-close" className={styles.deleteBtn}/></span>
              ))}
            </div>
            <Fragment>
              {show ?
                <ul className={styles.list}>
                  {list.length > 0 ? list.map((item: Option, idx: number) => (
                      <li role="button" className={cx(styles.selectItem, {[styles.activeSelectItem]: item.value === value})}
                          key={idx}
                          onClick={() => {
                            handleChange(item);
                            field.onChange({target: { value: ''}});
                          }}>{item.label}</li>
                    ))
                    : <li role="button" className={cx(styles.selectItem, styles.emptyAutocompleteItem)}>No suggestions...</li>
                  }
                </ul>
                : null
              }
            </Fragment>
          </div>
        )}
      />
    </Field>
  )
}

export default Tags;
