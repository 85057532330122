import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import { omit } from 'lodash';
import cx from 'classnames';
import useAuth from 'hooks/useAuth';
import dayjs from 'dayjs';
import Head from 'containers/head';
import Typo from 'components/typo';
import useQuery from 'helpers/query';
import logo from 'images/logo_white.svg';
import mobileLogo from 'images/logo.svg';
import Wizard, { steps, Step } from './wizard';
import SignUpStepOne from './stepOne';
import SignUpStepTwo from './stepThree';
import b from '../images/b.svg';
import styles from './signUp.module.css';
import AuthDisplayMessage from '../components/message';
import { trackUserAction, trackUserPageview } from 'helpers/trackUserActions';
import {useMixPanel} from "../../../hooks/useMixPanel";

const year = dayjs().get('year').toString();

export type FormData = {
  password: string;
  email: string;
}

export type Field = Record<string, string | boolean | null | Date>;
const SignUp:FC = () => {
  const {pageViewed,accountCreated} = useMixPanel();
  const query:ParsedQuery = useQuery();
  const history = useHistory();
  const { register, handleChangeErrors, msLogin, msLoading, googleLoading, resendEmail, loading, errors } = useAuth();

  const [ data, onChangeData ] = useState<FormData>({
    password: '',
    email: query.email as string || '',
  });

  const currentStep:Step = useMemo(() => {
    if (query.step && steps.includes(query.step as Step)) {
      return query.step as Step;
    }
    return 'personal';
  }, [query]);

  const handleChangeData = useCallback((field: Field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data, onChangeData]);

  const onSubmit = useCallback(() => {
    register({
      password: data.password,
      email: data.email,
    }, () => {
      //track mix panel Account Created event
      accountCreated("email")

      history.push(`/sign-up?step=verify&email=${data.email}`)
    });
    trackUserAction("Second step for Sign up is submitted")
  }, [register,accountCreated, history, data]);

  const onResend = useCallback(() => {
    resendEmail({ email: data.email }, `We sent a confirmation link to ${data.email}`);
    trackUserAction("Successfully completes the registration process")
  }, [data, resendEmail]);

  useEffect(()=>{
    trackUserPageview("Sign Up");
    //track mix panel Page Viewed event
    pageViewed("Sign Up")
  }, [])

  return (
    <div className={styles.wrapper}>
      <Head title="Sign Up"/>
      <div className={styles.smallHeader}>
        <img src={mobileLogo} alt="logo" draggable={false} className={styles.smallLogo}/>
      </div>
      <div className={cx(styles.leftSide, styles.side)}>
        <img src={logo} alt="logo" draggable={false} className={styles.logo}/>
        <img src={b} alt="b" draggable={false} className={styles.b}/>
        <Wizard current={currentStep} />
        <Typo className={styles.year}>{year} © Budget Mentor</Typo>
      </div>
      <div className={cx(styles.side, styles.rightSide)}>
        <div className={styles.rightSideContent}>
          {currentStep === 'personal'
            ? <SignUpStepOne errors={errors}
                             handleChangeErrors={handleChangeErrors} loading={loading}
                             onSubmit={onSubmit} data={data} onChange={handleChangeData}
                             msLogin={msLogin} msLoading={msLoading} googleLoading={googleLoading}
            />
            : null
          }
          {currentStep === 'verify'
            ? <SignUpStepTwo onSubmit={onResend} />
            : null
          }
          <AuthDisplayMessage />
        </div>
        <Typo className={styles.pageFooter}>
          {year} © Budget Mentor
        </Typo>
      </div>
    </div>
  )
}

export default SignUp;
