import { FC, useCallback, useState, useRef } from 'react';
import useAuth from 'hooks/useAuth';
import useGrants from 'hooks/useGrants';
import ProfileForm from 'containers/forms/profile';
import Typo from 'components/typo';
import {Button, Input} from 'components/form';
import {useForm, FormProvider} from 'react-hook-form';
import Modal from 'components/modal';
import styles from './settings.module.css';
import Head from 'containers/head';
import { isEmpty } from 'lodash';
import Help from '../../../../../components/help';

export type Field = Record<string, any | Record<any, any>>;

type Props = {
  visible: boolean;
  onCancel: () => void;
  grantId: string;
}
type FormProps = {
  onCancel: () => void;
}

const GrantSettingsForm:FC<FormProps> = ({ onCancel }) => {
  const { user, updateMe, updateOrganization, ...auth } = useAuth();
  const { updateSettings, errors, editable, loading }  = useGrants();
  const modal = useRef<HTMLDivElement | null>(null);
  const methods = useForm();

  const [ personal, onChangePersonal ] = useState(() => {
    const author = editable.author.firstName ? editable.author : user;
    return {
      ...author,
      yearSalary: author.yearSalary ? author.yearSalary : null,
      yearlyMonthsSalaryCompensation: author.yearlyMonthsSalaryCompensation ? author.yearlyMonthsSalaryCompensation : null,
      oneMonthCompensationAmount: author.oneMonthCompensationAmount ? author.oneMonthCompensationAmount : null,
      birthdate: author.birthdate ? new Date(author.birthdate) : null,
    }
  });

  const handleChangePersonalData = useCallback((field: Field) => {
    onChangePersonal({
      ...personal,
      ...field
    });
  }, [personal, onChangePersonal]);

  const submitUser = methods.handleSubmit(() => {
    //@ts-ignore
    updateMe(personal);
  })

  const handleSubmitStep = methods.handleSubmit(() => {
    updateSettings(editable.id as string, {author: personal}, onCancel);
  });


  return (
    <div className={styles.modal} ref={modal}>
      <Typo className={styles.tabTitle} semi>Personal information</Typo>
      <FormProvider {...methods}>
        <ProfileForm user={user} short embed errors={isEmpty(errors) ? auth.errors : errors} onSubmit={(data: any) => updateMe(data)} data={personal} onChange={handleChangePersonalData}>
          <>
            <Typo type="div" className={styles.tabTitle} semi>
              <>
                Budget Inflation Rate
                <Help>
                  <div className={styles.smallHelp}>
                    For most costs, NSF requires that inflation in the amount of 4% per year be applied after Year 1.
                    This will be automatically calculated for you when necessary.
                  </div>
                </Help>
              </>
            </Typo>
            <Input
              name="inflationRate"
              value={4}
              className={styles.fullInput}
              disabled
              readOnly
              label="Inflation Rate (%)"
              placeholder="Inflation rate, %"
            />
            <div className={styles.personalInfoWrapper}>
              <div className={styles.personalInfo}>
                <Typo className={styles.greenText}>
                  Updating this information and saving this to Account settings will NOT
                  affect any of the values or numbers that you have already used on prior grants
                </Typo>
                <Button size="lg" //@ts-ignore
                        onClick={() => submitUser(personal)} loading={auth.loading} htmlType="submit" name="update-account-submit">
                  Update Account settings info
                </Button>
              </div>
            </div>
          </>
        </ProfileForm>
        <div className={styles.commonFooter}>
          <div className={styles.footerBtns}>
            <Button htmlType="submit" loading={loading} onClick={handleSubmitStep} postIcon="arrow-narrow-right" name="save" size="lg">Save and continue</Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}

const GrantSettings:FC<Props> = ({visible, onCancel}) => {
  const handleCloseModal = useCallback(() => {
    onCancel();
  }, [onCancel])

  const { editable }  = useGrants();
  return (
    <>
      <Head title={editable.title}/>
      <Modal
        title="Budget information" wide visible={visible} onCancel={handleCloseModal}
        subtitle="Please verify and update your information so that we can ensure that your data on this grant is compliant with government guidelines."
        className={'bm-grant-settings-modal'}
      >
        <>
          { editable ? <GrantSettingsForm onCancel={handleCloseModal}/> : null }
        </>
      </Modal>
    </>
  )
}



export default GrantSettings;
