import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {Button, Form, Autocomplete} from 'components/form';
import Field from 'components/field';
import styles from './create.module.css';
import useParticipants from 'hooks/useParticipants';
import {useParams} from 'react-router-dom';
import useEnums from 'hooks/useEnums';
import useGrants from 'hooks/useGrants';
import {getEventFromGrant, getParticipantFromGrantEvent} from 'helpers/getFromGrant';
import {GrantEventParticipant} from 'store/grants/types';

type Props = {
  item: GrantEventParticipant;
  grantId: string;
  eventId: string;
}

const ParticipantForm: FC<Props> = ({item, eventId, grantId}) => {
  const {
    updateEventParticipant, errors, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = useParticipants({
    form_page_type: 'Participant info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getGrantEventParticipantCategories,
    eventParticipantCategories,
    isEventParticipantCategoriesLoading
  } = useEnums();
  const [data, onChange] = useState({
    category: item.category ?? ''
  });

  const onSubmit = useCallback(() => {
    updateEventParticipant(grantId, eventId, item.id, data);
  }, [data, item, eventId, grantId, updateEventParticipant]);

  return (
    <Form prompt onSubmit={onSubmit} trackExit={trackExit} trackError={trackError}>
      <Field withHelp last full>
        <Autocomplete
          value={data.category}
          options={eventParticipantCategories}
          onLoad={getGrantEventParticipantCategories}
          isLoading={isEventParticipantCategoriesLoading}
          min={3} required
          help="E.g., Workshop participant or Classroom observation participant, Curriculum design participant, Field study participant, etc."
          onEnter={(category: any) => onChange({...data, category})}
          onChange={(category: any) => onChange({...data, category})}
          name="category" errors={errors.category} label="Participant category"
          placeholder="Select or enter participant category"
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="save-participant">Save</Button>
      </div>
    </Form>
  );
}

const CreateEventParticipant: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, any> = useParams();

  const {item, index} = getEventFromGrant(grant, params.eventId);
  const participant = item && getParticipantFromGrantEvent(item, params.participantId);

  const subtitle = useMemo(() => {
    return `Event ${index}. Participant ${participant?.index || ''}`
  }, [index, participant]);

  const key = `${params.id}-event-${params.eventId}-participant-${participant?.item?.id}`;

  return (
    <GrantFormWrapper title="Participants Support" subtitle={subtitle}>
      <div>
        {item && participant?.item ?
          <ParticipantForm eventId={item.id} item={participant.item} key={key} grantId={grant.id}/> : null}
      </div>
    </GrantFormWrapper>
  );
}

export default CreateEventParticipant;
