import {ChangeEvent, FC, useCallback, useState, useEffect, useMemo} from 'react';
import cx from 'classnames';
import Field from 'components/field';
import Modal from 'components/modal';
import {Input, Select, Form, Autocomplete, Button, Checkbox} from 'components/form';
import Spinner from 'components/spinner';
import TransportationYears from 'pages/grant/one/travel/common/years';
import useEnums from 'hooks/useEnums';
import { Props } from './index';
import styles from './forms.module.css';
import {ListItem} from 'helpers/utils';
import Help from 'components/help';
import helps from './common/helps';

const PlaneComponent:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {
  const { airlines, airports, travelExpensesTypes, eligibility, isAirportsLoading, isAirlinesLoading } = useEnums();
  const [show, onChangeShow] = useState<boolean>(false);
  const [eligibilitySaved, onChangeEligibilitySaved] = useState<boolean>(Boolean(isUpdate));

  const handleChangeEligibility = useCallback((item: string) => {
    const eligibility = details.eligibility.includes(item)
      ? details.eligibility.filter((eligibil: string) => eligibil !== item)
      : [...details.eligibility, item];
    onChangeDetails({ eligibility });
  }, [onChangeDetails, details]);

  const handleCloseVerifyModal = useCallback(() => {
    onChangeShow(false);
    onChangeEligibilitySaved(true);
  }, []);

  const verifyLabel = useMemo(() => {
    if (details.isAirCarrierVerified === undefined) return 'Verify air carrier';
    return details.eligibility.length > 0
      ? 'You are eligible to use a foreign airline'
      : 'You are NOT eligible to use a foreign airline';
  }, [details, eligibilitySaved]);

  const handleOpenVerifyModal = useCallback(() => {
    onChangeShow(true);
    onChangeDetails({isAirCarrierVerified: true});
  }, [onChangeDetails])

  const carrierHelp = (
    <div className={styles.verifyBtn}>
      <Button onClick={handleOpenVerifyModal}
              className={cx({
                [styles.green]: eligibilitySaved && details.eligibility.length > 0,
                [styles.red]: details.isAirCarrierVerified !== undefined && eligibilitySaved && details.eligibility.length < 1,
              })}
              type="link" name="verify-aircarrier">{verifyLabel}</Button>
      {details.isAirCarrierVerified !== undefined && eligibilitySaved && details.eligibility.length < 1 ?
        <Help red contentClassName={styles.carrierRedHelp}>
          It is okay if we don’t show that you are eligible. Be extra careful when budgeting that you
          meet requirements or have confirmation from the proper authority that you are allowed to use
          a foreign airline outside this travel.
        </Help> : null}
    </div>
  );

  return (
    <>
      <Form prompt onSubmit={onSubmit}>
        <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
          <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
                 label="Name plane expense" required placeholder="Enter name plane expense"/>
          <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                  onChange={(typeOfExpense: any) => onChange({ typeOfExpense })}
                  label="Type of expenses" required placeholder="Enter type of expenses"
                  help={helps.typeOfExpense}
          />
        </Field>
        <Field withHelp top={Boolean(errors.startLocation || errors.endLocation)}>
          <Autocomplete
            placeholder="Select starting location"
            label="Starting location"
            options={airports}
            isLoading={isAirportsLoading}
            noLoader
            name="startLocation"
            clearable
            value={data.startLocation}
            errors={errors.startLocation}
            onChange={(startLocation: any) => onChange({ startLocation })}
            help={helps.planeLocation}
          />
          <Autocomplete
            placeholder="Select starting location"
            label="Ending location"
            options={airports}
            isLoading={isAirportsLoading}
            noLoader
            name="endLocation"
            value={data.endLocation}
            errors={errors.endLocation}
            onChange={(endLocation: any) => onChange({ endLocation })}
            help={helps.planeLocation}
            clearable
          />
        </Field>
        <Field>
          <Autocomplete
            placeholder="Select air carrier"
            label="Air carrier"
            options={airlines}
            isLoading={isAirlinesLoading}
            noLoader
            name="airCarrier"
            errors={errors['details.airCarrier']}
            value={details.airCarrier}
            onChange={(airCarrier) => onChangeDetails({ airCarrier })}
            help={helps.aircarrier}
            helpDebounce={3000}
            hint={carrierHelp}
          />
        </Field>
        <Field top={Boolean(errors['details.price'] || errors['details.travellers'])}>
          <Input name="price" label="Current price of a flight" required money
                 value={details.price} errors={errors['details.price']} type="number" onBlur={onBlur}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ price: event.target.value })}
                 placeholder="Enter current price of a flight"/>
          <Input name="travellers" label="Estimated number of travelers" required
                 value={details.travellers} errors={errors['details.travellers']} type="number" onBlur={onBlur}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ travellers: event.target.value })}
                 placeholder="Estimated number of travelers"/>
          <Input name="totalPrice" value={details.totalPrice} money label="Total price of flights" disabled readOnly placeholder="Total price of flights"/>
        </Field>
        <Field full>
          <Input name="totalCost" value={details.totalCost} errors={errors['details.totalCost']} label="Miscellaneous expenses for plane travel for all trip attendees"
                 placeholder="Total cost of misc expense" type="number" onBlur={onBlur}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
                 help={helps.planeTotalCost} money
          />
        </Field>
        <Field full>
          <Input name="compensationBeforeInflation" label="Amount budgeted for this method of travel before inflation "
                 placeholder="Amount of compensation before inflation"
                 value={data.compensationBeforeInflation}
                 disabled readOnly money
          />
        </Field>
        <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
        <div className={styles.footer}>
          <Button loading={loading} htmlType="submit" name="submit-transportation-method">
            {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
          </Button>
        </div>
      </Form>
      <Modal title="Check to see if I am eligible to use a non U.S. Certified Air Carrier" visible={show}>
        <Form>
          {eligibility.map((item:ListItem, idx: number) => (
            <Checkbox key={idx} label={item.label}
                      value={details.eligibility.includes(item.value)}
                      name={`eligibility[${idx}]`}
                      onChange={() => handleChangeEligibility(item.value)}
            />
          ))}
          <div className={styles.footer}>
            <Button onClick={handleCloseVerifyModal} name="save-verify">Save</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export const Plane:FC<Props> = (props) => {
  const { getAirports, getAirlines, airlines, airports } = useEnums();

  useEffect(() => {
    getAirports();
    getAirlines();
  }, []);

  return (
    airlines.length > 0 && airports.length > 0
      ? <PlaneComponent {...props}/>
      : <Spinner className={styles.spinner}/>
  );
}

export default Plane;
