import {
  FeesType,
  FeeItemType,
  FeeItemYearType
} from './types';

export const feesItemYearAdapter = (data: FeeItemYearType, idx: number) => {
  return {
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    value: data.value ?? '',
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    costJustification: data.costJustification ?? '',
  }
}

export const feesItemAdapter = (data: FeeItemType) => {
  return {
    name: data.name ?? '',
    isInflationApplied: data.isInflationApplied ?? '',
    id: data.id ?? '',
    years: data.years ? data.years.map(feesItemYearAdapter) : []
  }
}

export const feesAdapter = (data: FeesType) => {
  return data ? data?.map(feesItemAdapter) : [];
}
