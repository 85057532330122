import {ChangeEvent, useCallback, useState} from 'react';
// hooks
import useUI from 'hooks/useUI';
// types
import {
  MaterialsSuppliesUnitType,
  MaterialsSuppliesUnitFormType,
  MaterialsSuppliesGeneralInfoType,
} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// components
import {Input, Form, Button} from 'components/form';
import Field from 'components/field';
// styles
import styles from 'pages/grant/one/otherDirectCosts/form.module.css';
import {FieldErrors} from "react-hook-form";

type Props = {
  unit?: MaterialsSuppliesUnitType;
  grantId: string;
  onSubmit: (data: MaterialsSuppliesUnitType) => void;
  generalInfo: MaterialsSuppliesGeneralInfoType;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
};

const currentCostErrors = {
  currentUnitCostAboveUniversityCapitalizationLevel: [
    'This amount is over your university capitalization level and you should instead include this item under the "Equipment" section',
  ],
  currentUnitCostAboveUDefaultCapitalizationLevel: [
    'This amount is over the $5,000 threshold level, and you should instead include this item under the "Equipment" section',
  ],
};

const MaterialsSuppliesUnitForm = (props: Props) => {
  const {
    trackError,
    trackExit,
    unit,
    onSubmit,
    grantId,
    generalInfo: {universityCapitalizationLevel, defaultCapitalizationLevel, hasCapitalizationLevel},
  } = props;
  const [data, onChangeData] = useState<MaterialsSuppliesUnitFormType>({
    name: unit?.name ?? '',
    currentCost: unit?.currentCost ?? '',
  });
  const {confirmWithHide} = useUI();

  const isCurrentUnitCostAboveUniversityCapitalizationLevel =
    hasCapitalizationLevel && universityCapitalizationLevel! < Number(data.currentCost);
  const isCurrentUnitCostAboveUDefaultCapitalizationLevel =
    !hasCapitalizationLevel && defaultCapitalizationLevel! < Number(data.currentCost);
  let currentCostError: Array<string> = [];
  if (isCurrentUnitCostAboveUniversityCapitalizationLevel) {
    currentCostError = currentCostErrors.currentUnitCostAboveUniversityCapitalizationLevel;
  } else if (isCurrentUnitCostAboveUDefaultCapitalizationLevel) {
    currentCostError = currentCostErrors.currentUnitCostAboveUDefaultCapitalizationLevel;
  }

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const handleSubmit = () => {
    const yearsAfterInflationSum = unit!.years.reduce((acc, cur) => {
      return acc + cur.compensationAfterInflation
    }, 0)
    const shouldRecalculate = yearsAfterInflationSum > 0;

    confirmWithHide({
      title: 'Compensation will be recalculated',
      text: 'The total cost before and after inflation for all years will be recalculated based on the new cost',
      type: 'warning',
      icon: 'alert-hexagon',
      when: shouldRecalculate && data.currentCost !== unit?.currentCost,
      okText: 'Recalculate',
      id: `odc-materials-and-supplies-update-${grantId}`,//@ts-ignore
      onConfirm: () => onSubmit({...unit, ...data, currentCost: Number(data.currentCost)})
    });
  };

  return (
    <Form prompt onSubmit={handleSubmit} trackExit={trackExit} trackError={trackError}>
      <Field last>
        <Input
          name="name"
          label="Unit name"
          placeholder="Enter unit name"
          className={styles.input}
          value={data.name}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
          type="text"
        />
        <Input
          name="currentCost"
          money
          label="Current cost of unit"
          placeholder="Enter current cost of unit"
          className={styles.input}
          value={data.currentCost}
          min={0}
          type="number"
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({currentCost: event.target.value})
          }
          errors={currentCostError}
        />
      </Field>
      <div className={styles.footer}>
        <Button
          htmlType="submit"
          name="submit-ms-general-info"
          disabled={
            isCurrentUnitCostAboveUDefaultCapitalizationLevel ||
            isCurrentUnitCostAboveUniversityCapitalizationLevel
          }
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MaterialsSuppliesUnitForm;
