import {ChangeEvent, useState, FC, useCallback, useMemo} from 'react';
import Typo from 'components/typo';
import Field from 'components/field';
import Modal from 'components/modal';
import {Button, Checkbox, Form, DatePicker, Input} from 'components/form';
import { IEBreakdownSeason } from 'store/grants/types';
import styles from './alaska.module.css';
import {Errors, localEmptyValidate} from 'helpers/errors';
import {isEmpty} from 'lodash';

type Props = {
  item: IEBreakdownSeason;
  errors: Errors;
  zero?: boolean;
  last?: boolean;
  first?: boolean;
  idx: number;
  onChange: (field: Record<string, any>, blur?: boolean) => void;
  onSubmit: (season: IEBreakdownSeason, idx: number | string) => void;
  onClear: (idx: number | string) => void;
  onBlur: (event: any, season: IEBreakdownSeason, idx: number | string) => void;
}

const Season:FC<Props> = ({item, onClear, first, last, zero, onBlur, errors, idx, onSubmit, onChange}) => {
  const [show, onChangeShow] = useState<boolean>(false);
  const [localErrors, onChangeLocalErrors] = useState<Errors>({});

  const handleSubmit = useCallback(() => {
    onSubmit(item, item.serialNumber);
  }, [onSubmit, item]);

  const clearSeason = useCallback(() => {
    onClear(item.serialNumber)
  }, [item, onClear]);

  const errorKey = `ieBreakdown.seasons[${idx}]`;

  const handleBlur = useCallback((event: any) => {
    onBlur(event, item, item.serialNumber);
  }, [onBlur, item]);

  const onCloseModal = useCallback(() => {
    onChangeShow(false);
  }, []);

  const disableDiscountButton = useMemo(() => {
    if (item.doBudgetMaxAmount) {
      return !item.maxTotalAdjust;
    }
    if (zero) {
      return !(item.days);
    }
    return Boolean(
      !item.startDate ||
      !item.endDate ||
      !item.rate
    );
  }, [zero, item]);

  const onChangeDiscount = useCallback((field: Record<string, any>, blur?: boolean) => {
    onChange({
      discount: {
        ...item.discount,
        ...field
      }
    }, blur);
  }, [item, onChange]);


  const maxError = ['The entered value is over the maximum amount allowed according to the website. Please double-check to ensure the values for each daily rate and number of nights are correct.'];
  const disabledMax = useMemo(() => {
    return Boolean(item.doBudgetMaxAmount && item.maxTotalAdjust && (item.maxTotalAdjust > item.maxTotal))
  }, [item]);

  const discountModalTitle = useMemo(() => {
    if (first) return 'Amount that needs to be subtracted for departure day';
    return 'Amount that needs to be subtracted for the day you return home';
  }, [first]);

  const discountBtnTitle = useMemo(() => {
    if (first) return 'Check discount for departure day';
    return 'Check discount for the day you return home';
  }, [first]);

  const discountInputLabel = useMemo(() => {
    if (first) return 'Max amount to budget departure day';
    return 'Max amount to budget arrival day';
  }, [first]);

  const disableModal = !item.discount?.isStartDateFollowsEndDate;

  return (
    <>
      <Form onSubmit={handleSubmit} className={styles.season}>
        {
          zero
            ?
              <Field full>
                <Input value={item.days} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({days: event.target.value})}
                       name="days" label="Number of days of travel" required
                       errors={errors[`${errorKey}.days`] || localErrors.days}
                       type="number" onBlur={handleBlur}
                       placeholder="Enter number of days"
                />
              </Field>
            :
            <>
              <Typo type="h5" className={styles.seasonTitle} bold>{`Season ${idx + 1}`}</Typo>
              <Field>
                <DatePicker errors={errors[`${errorKey}.startDate`] || localErrors.startDate} value={item.startDate}
                            onChange={(startDate: Date | null) => onChange({startDate}, true)} name={`startDate_${idx}`}
                            label="Start date of travel in the season" required placeholder="Select a start date"/>
                <DatePicker errors={errors[`${errorKey}.endDate`] || localErrors.endDate} value={item.endDate}
                            onChange={(endDate: Date | null) => onChange({endDate}, true)} name={`endDate_${idx}`}
                            minDate={item.startDate || undefined}
                            label="End date of travel in the season" required placeholder="Select a end date"/>
                <Input value={item.days} disabled readOnly
                       name="days" label="Number of days of travel in the season" placeholder="Should be calculated"
                       type="number"
                />
              </Field>
            </>
        }
        <Field>
          <Input name={`rate_${idx}`} onBlur={handleBlur} money errors={errors[`${errorKey}.rate`] || localErrors.rate} type="number" label="Incidental Rate" required placeholder="Enter Rate"
                 value={item.rate} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({rate: event.target.value})}
          />
        </Field>
        <Field>
          <Input name={`maxTotal_${idx}`} type="number" money value={item.maxTotal} disabled readOnly label="Total for incidentals for one person" placeholder="Total for meals for one person"/>
          <Checkbox name={`doBudgetMaxAmount_${idx}`} field value={item.doBudgetMaxAmount} label="I want to budget the maximum amount possible for IE"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({doBudgetMaxAmount: event.target.checked, maxTotalAdjust: event.target.checked ? item.maxTotal : ''})}
          />
        </Field>
        <Field last>
          <Input name={`maxTotalAdjust_${idx}`} money onBlur={handleBlur}
                 errors={disabledMax ? maxError: (errors[`${errorKey}.maxTotalAdjust`] || localErrors.maxTotalAdjust)}
                 disabled={!item.doBudgetMaxAmount} type="number" required={item.doBudgetMaxAmount}
                 help="Based on the numbers entered, we calculated the total for meals for one person. However, if you wish to change this value, uncheck the box and enter the value in Adjusted total for ie filed. Please note that the value entered may be greater than Total for Meals for one Person"
                 label="Adjusted total for incidentals expenses" placeholder="Enter adjusted total for incidentals expenses"
                 value={item.maxTotalAdjust} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({maxTotalAdjust: event.target.value})}
          />
        </Field>
        <div className={styles.seasonFooter}>
          {
            (first || last || zero)
            ?
              <Button htmlType="button" disabled={disableDiscountButton}
                      onClick={() => onChangeShow(true)} name={`show-discount-modal-${idx}`}
                      type={zero ? 'primary' : 'bordered'}>
                {discountBtnTitle}
              </Button>
              : null
          }
          <Button htmlType="submit" name={`submit-season-${idx}`}>Save</Button>
          <div className={styles.clear}>
            <Button htmlType="button" onClick={clearSeason} type="link" name={`clear-season-${idx}`}>Clear and save</Button>
          </div>
        </div>
      </Form>

      <Modal visible={show} title={discountModalTitle} onCancel={onCloseModal}>
        <Form onSubmit={handleSubmit}>
          <Checkbox name="isStartDateFollowsEndDate" value={item.discount.isStartDateFollowsEndDate}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDiscount({isStartDateFollowsEndDate: event.target.checked}, true)}
                    label="The start date of this trip does not immediately follow the end date of another part of this trip"/>
          <Field>
            <Input name="dailyAmountOfExpenses" money value={disableModal ? '' : item.discount.dailyAmountOfExpenses} label="Daily amount for IE" placeholder="Daily amount for IE" disabled/>
            <Input name="threeQuarters" value={disableModal ? '' : item.discount.threeQuarters} label="75% of IE" placeholder="75% of IE" disabled/>
          </Field>
          <Field>
            <Input name="maxAmount" money value={disableModal ? '' : item.discount.maxAmount} label={discountInputLabel}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDiscount({maxAmount: event.target.value})}
                   onBlur={handleBlur} disabled={disableModal}
                   placeholder="Enter amount to budget" help="For the first day, you can only budget 75% of the expenses"/>
          </Field>
          <Field last>
            <Input name="amount" money value={disableModal ? '' : item.discount.amount} label="Discount amount"
                   help={item.discount.amount === 0 ? `The discount can be only applied if the "Max amount to budget" is less or equal to 75% of expenses` : undefined}
                   placeholder="Discount amount" disabled/>
          </Field>
          <div className={styles.modalFooter}>
            <Button htmlType="button" name="close-discount-modal" type="bordered" onClick={onCloseModal}>Cancel</Button>
            <Button htmlType="submit" disabled={disableModal} name="save-discount-modal" onClick={onCloseModal}>Submit form</Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default Season;
