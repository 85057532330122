import {FC, useCallback, ChangeEvent} from 'react';
import {Input, Form, DatePicker, Select, Textarea, RadioGroup, Button} from 'components/form';
import {Link} from 'react-router-dom';
import FormField from 'components/field';
import useEnums from 'hooks/useEnums';
import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import styles from './create.module.css';
import {ETravelType} from "store/grants/types";
import {FieldErrors} from "react-hook-form";

const travelTypeHelpText = 'Please mark this item if you need to budget for consultants\' travel expenses. In the' +
  ' "Consulting Services" section, you can apply the travel expenses of the consultant(s). Please note that two different templates are used to plan travel expenses for grantees and consultants. Travel for consultants will not be added to the final form';

type Props = {
  onSubmit: () => void;
  onChange: (field: Record<string, any>) => void;
  handleChangeTripTypeData: (type: ETravelType) => void;
  data: T.CreateTravelData;
  grantId: string;
  errors: Errors
  loading: boolean;
  isUpdate: boolean;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

const TravelForm: FC<Props> = ({
                                 trackError,
                                 trackExit,
                                 trackFormStarted,
                                 onSubmit,
                                 errors,
                                 grantId,
                                 loading,
                                 data,
                                 onChange,
                                 handleChangeTripTypeData
                               }) => {
  const {yesNoOptions, travelExpensesTypes, travelTypes} = useEnums();
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const locationHelpText = "It's completely okay if you don't or won't know the day your trip will have to begin before your budget needs to be submitted. This is very common. A best guess will help us remind you of important rules regarding maximum allowable amounts for certain budgeting categories as they occur.";

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <FormField full>
        <Input name="tripName" errors={errors.tripName} value={data.tripName}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({tripName: event.target.value})}
               label="Trip name" required placeholder="Enter trip name"/>
      </FormField>
      <FormField full>
        <Textarea name="tripDescription" helpInLabel errors={errors.tripDescription} label="Trip description" required
                  help="Provide a short description as to why this trip is important to either conducting research or conveying the information to others that resulted from your proposed research. Don’t forget to include an explanation for why this trip is 1) important to accomplish the tasks needed to fully complete the research proposal you are submitting or 2) the way in which this trip helps to share the results of your research from this proposal. Also, remember to justify the expenses that you add throughout the process of budgeting for the trip in your budget justification."
                  placeholder="Enter a description..."
                  value={data.tripDescription}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange({tripDescription: event.target.value})}
        />
      </FormField>
      <FormField full>
        <Textarea name="tripPurpose" helpInLabel errors={errors.tripPurpose} label="Trip type / purpose" required
                  help="What is one or two words to describe the type of trip or the purpose of trip that you'd like to use for a description for this type of travel?"
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange({tripPurpose: event.target.value})}
                  placeholder="Enter type / purpose..."
                  value={data.tripPurpose}
        />
      </FormField>
      <FormField full>
        <RadioGroup name="isConferenceTrip" errors={errors.isConferenceTrip} value={data.isConferenceTrip}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({isConferenceTrip: event.target.value})}
                    inline options={yesNoOptions} required bool
                    label="Is the sole purpose of this trip to travel to a conference or will a stop on this trip be to a conference?"
                    help={`This is very important when it comes to budgeting elements later on in this section. Please consult the NSF guidelines for the definition of a "conference”`}
        />
      </FormField>
      <FormField last className={styles.consultantsField}>
        <Select
          name="type"
          errors={errors.type}
          options={travelTypes}
          label="Type of the trip"
          required
          help={travelTypeHelpText}
          placeholder="Select type of trip"
          value={data.type}
          onChange={(type: ETravelType) => handleChangeTripTypeData(type)}
        />
      </FormField>

      <div className={styles.consultantsSection}>
        {data.consultantServiceProviders.length ? (
          <>
            <div>Consultants associated with this trip</div>
            <div className={styles.consultantsList}>
              {data.consultantServiceProviders.map((provider, idx) => {
                return (
                  <Link
                    to={`/grants/${grantId}/otherDC/consultantServices/${provider.consultantServiceId}/years/${provider.yearSerialNumber}?provider=${provider.id}`}>
                    {provider.name} #{idx + 1}
                  </Link>
                )
              })}
            </div>
          </>
        ) : (
          <span>Consultants list associated with this trip is empty</span>
        )}
      </div>

      <FormField>
        <Select name="tripDefaultLocation" errors={errors.tripDefaultLocation} options={travelExpensesTypes}
                label="Default location of travel for this trip" required
                help="This will automatically fill the type of travel for each of your transportation methods. Don’t worry. You can always override the default type of travel for any method of transportation on a case-by-case basis."
                placeholder="Enter default location of travel" value={data.tripDefaultLocation}
                onChange={(tripDefaultLocation: any) => onChange({tripDefaultLocation})}
        />
      </FormField>
      <FormField withHelp last>
        <DatePicker name="estimatedStartDate" errors={errors.estimatedStartDate} help={locationHelpText}
                    label="Estimated Start Date"
                    placeholder="Select an estimated start date"
                    value={data.estimatedStartDate}
                    onChange={(estimatedStartDate) => onChange({estimatedStartDate})}
        />
        <DatePicker name="estimatedEndDate" errors={errors.estimatedEndDate} help={locationHelpText}
                    label="Estimated End Date"
                    placeholder="Select an estimated end date" //@ts-ignore
                    value={data.estimatedEndDate} minDate={data.estimatedStartDate || undefined}
                    onChange={(estimatedEndDate) => onChange({estimatedEndDate})}
        />
      </FormField>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-equip">Save</Button>
      </div>
    </Form>
  );
}

export default TravelForm;
