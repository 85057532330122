import {FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Typo from 'components/typo';
import styles from './status.module.css';
import {getTravelFromGrant} from 'helpers/getFromGrant';
import {ETravelType, FullGrant, Travel, TravelStatus} from 'store/grants/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import useUrl from 'hooks/useUrl';
import useTravel from 'hooks/useTravel';
import AttendeesForm from '../common/attendee';
import ConsultantStatusForm from './consultantStatus';

type Props = {
  grant: FullGrant;
  trip: Travel;
}

const TravelStatusComponent: FC<Props> = ({trip, grant}) => {
  const {
    updateStatus, loading, errors, trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: 'Trip Status'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const url = useUrl();
  const {categoryId, grantId, section} = useUrl();

  const [data, onChangeData] = useState<TravelStatus>({
    ...trip.status,
    years: trip.status?.years?.length > 0 ? trip.status.years : grant.years.map((year: string[], serialNumber: number) => ({
      year,
      serialNumber: serialNumber + 1,
      isEnabled: false
    })),
    seniorPersonnel: trip.status.seniorPersonnel.map((item: any) => item?.id),
    academicResearchAssociate: trip.status.academicResearchAssociate.map((item: any) => item?.id),
  });

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    updateStatus(grant.id, trip.id, {
      status: {
        ...data,
        seniorPersonnel: data.seniorPersonnel.map((id: string) => ({id})),
        academicResearchAssociate: data.academicResearchAssociate.map((id: string) => ({id})),
      }
    });
  }, [updateStatus, data, grant, trip]);

  if (trip.type === ETravelType.Consultant) return <ConsultantStatusForm key={`${grantId}-${categoryId}-${section}`}
                                                                         trip={trip} grant={grant}/>

  return (
    <div>
      <Typo className={styles.mb16}>
        Please check all of the PI's, Co-PI's, and Academic Research Associates who you would like to list as "on
        travel status" for this trip. This is a formality and will have little result in the final budget however
        if at any point they will be on a leg of this trip you are budgeting for, you should be sure to check
        their name.
      </Typo>
      <Typo className={styles.mb20}>
        Only people who are "on travel status" can have costs on trips covered. All exceptions for this rule are
        taken into account in a later section under this trip.
      </Typo>
      <AttendeesForm
        status
        loading={loading}
        errors={errors}
        onSubmit={onSubmit}
        data={data}
        onChange={handleChangeData}
        trackError={trackError}
        trackExit={trackExit}
      />
    </div>
  );
}

const TravelStatusPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {index, trip} = getTravelFromGrant(grant, params.travelId);
  return (
    <GrantFormWrapper title="Travel" key={`${grant.id}-${trip?.id}-status`} subtitle={`Trip ${index}. Trip status`}>
      {trip ? <TravelStatusComponent trip={trip} grant={grant}/> : <span/>}
    </GrantFormWrapper>
  )
}


export default TravelStatusPage;
