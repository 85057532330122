import { FC } from 'react';

import { GoogleOAuthProvider } from "@react-oauth/google";

type Props = {
  children: any;
}

const GoogleAuthProvider:FC<Props> = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
      {children}
    </GoogleOAuthProvider>
  )
}

export default GoogleAuthProvider;
