import {
  MaterialsSupplies,
  MaterialsSuppliesUnitFormType,
  MaterialsSuppliesUnitYearType,
  MaterialsSuppliesUnitType,
  MaterialsSuppliesGeneralInfoType
} from './types';

export const materialsSuppliesUnitPostAdapter = (unit: MaterialsSuppliesUnitFormType) => {
  return {
    name: unit.name,
    currentCost: unit.currentCost ?? ''
  }
}

export const materialsSuppliesUnitYearPostAdapter = (year: MaterialsSuppliesUnitYearType, idx?: number) => {
  return {
    compensationAfterInflation: year.compensationAfterInflation ?? '',
    compensationBeforeInflation: year.compensationBeforeInflation ?? '',
    numberOfUnits: year.numberOfUnits ?? '',
    serialNumber: year.serialNumber ?? (idx || 0) + 1,
    year: year.year ?? []
  };
};

export const materialAndSuppliesUnitAdapter = (data: MaterialsSuppliesUnitType) => {
  return {
    id: data.id ?? '',
    name: data.name ?? '',
    currentCost: data.currentCost ?? '',
    years: data.years ? data.years.map(materialsSuppliesUnitYearPostAdapter) : [],
  }
}

export const materialsSuppliesAdapter = (data: MaterialsSupplies | undefined) => {
  const hasCapitalizationLevel = data?.hasCapitalizationLevel ?? null;
  const capitalizationLevel = data?.capitalizationLevel ?? '';

  return {
    defaultCapitalizationLevel: hasCapitalizationLevel ? '' : capitalizationLevel,
    universityCapitalizationLevel: hasCapitalizationLevel ? capitalizationLevel : '',
    capitalizationLevel,
    hasCapitalizationLevel,
    units: data?.units ? data.units.map(materialAndSuppliesUnitAdapter) : [],
    totals: data?.totals ?? [],
  };
};

export const materialsSuppliesGeneralInfoPostAdapter = ({
  hasCapitalizationLevel,
  defaultCapitalizationLevel,
  universityCapitalizationLevel
}: MaterialsSuppliesGeneralInfoType) => {
  let capitalizationLevel = hasCapitalizationLevel ? universityCapitalizationLevel : defaultCapitalizationLevel;

  return {
    hasCapitalizationLevel,
    capitalizationLevel: Number(capitalizationLevel)
  }
}
