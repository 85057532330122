import {FC, useCallback, useState, ChangeEvent, useEffect} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import Field from 'components/field';
import {Select, RadioGroup, Input, Form, Button} from 'components/form'
import useEnums from 'hooks/useEnums'
import Typo from 'components/typo';
import {getConsultantServiceFromGrant} from 'helpers/getFromGrant';
import useGrants from 'hooks/useGrants';
import useConsultantServices from 'hooks/useConsultantServices';
import {ConsultantServiceType} from 'store/grants/otherDirectCosts/ConsultantServices/types';
import styles from './consultants.module.css';
import {confirm} from '../../../../../components/confirmation';

type Props = {
  consultant: ConsultantServiceType;
  grantId: string;
}

const ConsultantInfoForm: FC<Props> = ({consultant, grantId}) => {
  const [data, onChangeData] = useState({
    name: consultant.name ?? '',
    providersType: consultant.providersType,
    isRegularCostInflation: consultant.isRegularCostInflation,
    isAdditionalCostInflation: consultant.isAdditionalCostInflation,
    isTravelCostInflation: consultant.isTravelCostInflation,
  });

  const {
    updateService, isLoading,
    trackError,
    trackExit,
    trackFormStarted
  } = useConsultantServices({form_page_type: 'Service info'});

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {yesNoOptionsRevert, consultantServiceProviderTypes} = useEnums();

  const handleChange = useCallback((field: Record<string, any>) => {

    onChangeData({
      ...data,
      ...field
    });
  }, [data]);


  const onSubmit = useCallback(() => {
    if (consultant.providersType && consultant.providersType !== data.providersType) {
      confirm({
        title: 'Some data will be lost',
        text: 'Due to the update in the "Consultant services provided by" field, data on the year tabs will be lost',
        type: 'error',
        icon: 'trash-01',
        onConfirm: () => {
          updateService(grantId, consultant.id, data);
        }
      });
      return;
    }
    updateService(grantId, consultant.id, data);
  }, [updateService, data, grantId, consultant]);

  return (
    <>
      <Typo className={styles.subtitle}>
        Professional and consultant services are services rendered by persons who are members of a particular profession
        or possess a special skill, and who are not officers or employees of the proposing organization. Usually,
        consultant services do not include inflation adjustments. Therefore, the default setting for inflation-related
        fields indicates that it should not be applied. Despite this, you have the ability to adjust for inflation
        below.
      </Typo>
      <Form onSubmit={onSubmit} trackError={trackError}
            trackExit={trackExit} trackFormStarted={trackFormStarted}>
        <Field full>
          <Input name="name" label="Name of consultant service" required
                 placeholder="Enter service name"
                 value={data.name}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({name: event.target.value})}
          />
        </Field>
        <Field>
          <Select name="providersType" label="Consultant services will be provided by"
                  options={consultantServiceProviderTypes}
                  required placeholder="Select an option"
                  value={data.providersType} onChange={(providersType) => handleChange({providersType})}
          />
        </Field>
        <Field>
          <RadioGroup name="isRegularCostInflation" label="Is inflation applied to the regular costs?" required
                      bool options={yesNoOptionsRevert} inline
                      value={data.isRegularCostInflation}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({isRegularCostInflation: event.target.value})}
          />
        </Field>
        <Field>
          <RadioGroup name="isAdditionalCostInflation" label="Is inflation applied to the additional costs?" required
                      bool options={yesNoOptionsRevert} inline
                      value={data.isAdditionalCostInflation}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({isAdditionalCostInflation: event.target.value})}
          />
        </Field>
        <Field last>
          <RadioGroup name="isTravelCostInflation" label="Is inflation applied to the travel costs?" required
                      bool options={yesNoOptionsRevert} inline
                      value={data.isTravelCostInflation}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({isTravelCostInflation: event.target.value})}
          />
        </Field>
        <div className={styles.footer}>
          <Button htmlType="submit" loading={isLoading} name="submit-consultant">Save</Button>
        </div>
      </Form>
    </>
  );
}

const ConsultantInfoPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getConsultantServiceFromGrant(grant, params.serviceId);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={`Consultant service ${index > 0 ? index : ''}`}>
      {item ? <ConsultantInfoForm grantId={grant.id} consultant={item}
                                  key={`${grant.id}-consultant-service-${params.serviceId}`}/> : <></>}
    </GrantFormWrapper>
  )
}

export default ConsultantInfoPage;
