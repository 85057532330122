import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
// hooks
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import useOtherExpenses from 'hooks/useOtherExpenses';
// helpers
import {getOtherExpenseFromGrant} from 'helpers/getFromGrant';
// types
import {OtherExpenseYearType, OtherExpenseType} from 'store/grants/otherDirectCosts/OtherExpenses/types';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Textarea} from 'components/form';
// styles
import styles from '../otherExpense.module.css';


type Props = {
  item: OtherExpenseYearType;
  expense: OtherExpenseType;
  grantId: string;
};

const numberOfUnitsInYearsInputHelpText = 'If you want to enter in a whole sum to cover this category for the full year, all you have to do is leave the unit number at 1'
const totalCostAfterInflationHelpText = 'Inflation does not apply. You can change it in the Information tab';
const totalCostAfterInflationFirstYearHelpText = 'Inflation is not applied in Year 1 for any costs, but will be' +
  ' applied in subsequent years.'

const OtherExpenseYearForm: FC<Props> = ({item, expense, grantId}) => {
  const [data, onChangeData] = useState<OtherExpenseYearType>(item);
  const {
    isLoading, updateExpense, previewExpenseYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useOtherExpenses({
    form_page_type: 'Date'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    if (expense.id && data.numberOfUnits !== '') {
      previewExpenseYear(grantId, expense.id, {
        numberOfUnits: data.numberOfUnits,
        serialNumber: data.serialNumber,
        cost: data.cost
      }, (data: any) => {
        onChangeData({
          ...data,
          compensationAfterInflation: data.compensationAfterInflation,
          compensationBeforeInflation: data.compensationBeforeInflation,
        });
      })
    }
  }, [previewExpenseYear, expense.id, grantId, data]);

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const onSubmit = useCallback(() => {
    const newYears = expense.years.map((year: OtherExpenseYearType) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...year,
          numberOfUnits: Number(data.numberOfUnits),
          compensationBeforeInflation: Number(data.compensationBeforeInflation),
          compensationAfterInflation: Number(data.compensationAfterInflation),
          cost: Number(data.cost),
          costJustification: data.costJustification
        }
      }
      return year;
    });
    updateExpense(grantId, expense.id, {
      ...expense,
      years: newYears
    })
  }, [data, item, expense, grantId]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input
          className={styles.input}
          name="cost"
          label="Full amount or the cost per unit of item or service"
          placeholder="Enter amount"
          onBlur={onBlur}
          value={data.cost}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({cost: event.target.value})
          }
          money
          type="number"
        />
        <Input
          name="numberOfUnits"
          label={`Number of units in year`}
          placeholder="Enter number of units"
          className={styles.input}
          onBlur={onBlur}
          value={data.numberOfUnits}
          required
          help={numberOfUnitsInYearsInputHelpText}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({numberOfUnits: event.target.value})
          }
          type="number"
        />
      </Field>

      <Field>
        <Input
          name="compensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          value={data.compensationBeforeInflation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({compensationBeforeInflation: event.target.value})
          }
          disabled
          type="number"
        />
        <Input
          name="compensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${data.serialNumber}`}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.compensationAfterInflation}
          help={
            !expense.isInflationApplied
              ? totalCostAfterInflationHelpText
              : data.serialNumber === 1
                ? totalCostAfterInflationFirstYearHelpText
                : ""
          }
          type="number"
          readOnly
          disabled
        />
      </Field>

      <Field last full>
        <Textarea
          name="definition"
          label="Justification for this cost"
          placeholder="Enter a description..."
          value={data.costJustification}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeData({costJustification: event.target.value})
          }
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={isLoading} name="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};


const OtherExpenseYearPage = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getOtherExpenseFromGrant(grant, params.expenseId);
  const year = item?.years[Number(params.yearId)];

  return (
    <GrantFormWrapper
      title="Other Direct Costs"
      subtitle={`All other costs. Expense ${index}. Year ${Number(params.yearId) + 1}`}
    >
      {year ? (
        <OtherExpenseYearForm
          grantId={grant.id}
          expense={item}
          key={`${grant.id}-publications-${params.serviceId}-${params.yearId}-${year?.compensationAfterInflation}`}
          item={year}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
}

export default OtherExpenseYearPage;
