import { FC, Fragment } from 'react';
import cx from 'classnames';
import {Button} from 'components/form';
import styles from './infoSidebar.module.css';
import Icon from 'components/icon';
import Typo from 'components/typo';
import {InfoField} from './info';

type Props = {
  info?: InfoField;
  onChange: (id: number) => void;
  nsf?: boolean;
}

const Sections:FC<Props> = ({ info, onChange, nsf }) => {
  const link = info?.link ?? 'https://beta.nsf.gov/policies/pappg/23-1/ch-2-proposal-preparation';

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <Button type="ghost" onClick={() => onChange(-1)} name="prev" preIcon="arrow-left">Previous section</Button>
        <Button type="ghost" onClick={() => onChange(1)} name="next" postIcon="arrow-right">Next section</Button>
      </div>
      <div key={info?.title} className={cx(styles.content, {[styles.emptySection]: !info})}>
        {info
          ?
            <>
              {info.title ? <Typo className={styles.title} bold size={18}>{info.title}</Typo> : null}
              {info.content ?
                info.content.map((field: (string | JSX.Element)[], idx: number) => (
                  <Fragment key={idx}>
                    {field.map((line: (string | JSX.Element), index: number) => (
                      line === ''
                        ? undefined
                        : <Typo className={cx(styles.field, {
                          [styles.nsfField]: index < 1,
                          [styles.withPadding]: field[0] !== ''
                          })} key={index}>{line}</Typo>
                    ))}
                  </Fragment>
                ))
                :
                <div className={cx(styles.sectionPlaceholder, styles.mt40)}>
                  <Icon icon="help-circle" type="info" size={23} />
                  <Typo size={16} className={styles.placeholderText}>
                    There is currently no help text for this section.
                  </Typo>
                </div>
              }
              {
                nsf
                  ?
                  <div className={styles.sectionFooter}>
                    <a href={link} target="_blank" rel="noreferrer">
                      <Button name="see-full-guide" type="link" full>
                        See the full version of the guidelines
                      </Button>
                    </a>
                  </div>
                  : null
              }
            </>
          :
            <div className={styles.sectionPlaceholder}>
              <Icon icon="help-circle" type="info" size={23} />
              <Typo size={16} className={styles.placeholderText}>
                There is currently no help text for this section.
              </Typo>
            </div>
        }
      </div>
    </div>
  );
}

export default Sections;
