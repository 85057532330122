import {
  CostSharingExpenseYearType,
  CostSharingExpenseItemType,
  CostSharingExpensesType
} from './types';

export const costSharingItemYearAdapter = (data: CostSharingExpenseYearType, idx: number) => {
  return {
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    expense: data.expense ?? '',
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    costJustification: data.costJustification ?? '',
  }
}

export const costSharingItemAdapter = (data: CostSharingExpenseItemType) => {
  return {
    name: data.name ?? '',
    isInflationApplied: data.isInflationApplied ?? '',
    type: data.type ?? '',
    id: data.id ?? '',
    years: data.years ? data.years.map(costSharingItemYearAdapter) : []
  }
}

export const costSharingAdapter = (data: CostSharingExpensesType) => {
  return data ? data?.map(costSharingItemAdapter) : [];
}
