import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import Typo from 'components/typo';
import PasswordCheck from 'pages/auth/components/passwordCheck';
import checkPassword from 'helpers/password';
import { Input, Form, Button } from 'components/form';
import styles from '../account.module.css';
import Head from 'containers/head';

export type FormData = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const ForgotPasswordHelp:FC = () => (
  <Typo className={styles.forgot} type="div">
    <>
      If you have forgotten your password, please use <Link to="/forgot?back=settings">recovering link.</Link>
    </>
  </Typo>
)

const defaultFormData = {
  oldPassword: '',
  password: '',
  confirmPassword: ''
};

export type Field = Record<string, string | boolean | null | Date>;
const Passwords:FC = () => {
  const { errors, handleChangeErrors, updatePassword, loading } = useAuth();
  const { prevUrl } = useUI();
  const [ showHelp, onChangeShowHelp ] = useState<boolean>(false);

  const [ data, onChangeData ] = useState<FormData>(defaultFormData);

  const handleChangeData = useCallback((field: Field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data, onChangeData]);

  const onSubmit = useCallback(() => {
    if (data.password !== data.confirmPassword) {
      handleChangeErrors({
        confirmPassword: ['Passwords do not match'],
        password: ['']
      });
      return;
    }

    if (!checkPassword(data.password).all) {
      handleChangeErrors({
        password: ['The password is not strong enough']
      });
      return;
    }
    updatePassword(data, () => onChangeData(defaultFormData));
  }, [data, handleChangeErrors, updatePassword]);

  return (
    <div className={styles.passwordWrapper}>
      <Head title="Change password"/>
      <div className={styles.header}>
        <Typo type="h5" className={styles.tabTitle} semi>Change Password</Typo>
      </div>
      <Form onSubmit={onSubmit}>
        <div className={styles.field}>
          <Input name="password" errors={errors.oldPassword} className={styles.fullInput} value={data.oldPassword}
                 label="Old Password" required placeholder="Input your current password"
                 type="password" helpDebounce={3000} help={<ForgotPasswordHelp/>}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({oldPassword: event.target.value})}
          />
        </div>
        <div className={styles.field}>
          <Input name="newPassword" errors={errors.password} className={styles.fullInput} value={data.password}
                 label="New Password" required showHelp={showHelp} placeholder="Input your new password"
                 onBlur={() => onChangeShowHelp(false)} onFocus={() => onChangeShowHelp(true)}
                 type="password" help={<PasswordCheck password={data.password}/>}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({password: event.target.value})}
          />
        </div>
        <div className={styles.field}>
          <Input name="confirmPassword" errors={errors.confirmPassword} className={styles.fullInput} value={data.confirmPassword}
                 label="Confirm Password" required placeholder="Confirm your new password"
                 type="password"
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({confirmPassword: event.target.value})}
          />
        </div>

        <div className={styles.goBackFooter}>
          <Link to={prevUrl}>
            <Button type="bordered" size="lg" name="back" preIcon="arrow-left">Go back</Button>
          </Link>
          <Button htmlType="submit" size="lg" loading={loading} name="back">Update password</Button>
        </div>
      </Form>
    </div>
  )
}

export default Passwords;
