import {FC, FunctionComponent, createElement, useMemo} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {Travel, TravelCostData} from 'store/grants/types';
import IEAlaskaHawaiiBreakdown from './alaska';
import {Kind} from 'const';


export type FormProps = {
  cost: TravelCostData;
  travelId: string;
  grantId: string;
}

const CostIEBreakdownComponent:FC = () => {
  const params:Record<string, string> = useParams();
  const { grant } = useGrants();
  const travel = getTravelFromGrant(grant, params.travelId);
  const { cost } = getCostFromTravel(travel.trip as Travel, params.costId);
  const key = `${grant.id}-${params.travelId}-${params.costId ?? 'cost'}`;
  const subtitle = useMemo(() => {
    if (cost && cost.kind === 'alaskaHawaii') {
      return `Travel Costs. Alaska, Hawaii. Incidental Expenses (IE) Breakdown per person`
    }
    return 'Travel Costs';
  }, [cost]);

  const form:Record<Kind, FunctionComponent<FormProps>> = {
    other: IEAlaskaHawaiiBreakdown,
    international: IEAlaskaHawaiiBreakdown,
    fourty8states: IEAlaskaHawaiiBreakdown,
    alaskaHawaii: IEAlaskaHawaiiBreakdown
  };

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      {cost && cost.kind && form[cost.kind as Kind] ? createElement(form[cost.kind as Kind], {
        key,
        grantId: grant.id,
        cost,
        travelId: params.travelId
      }) : undefined}
    </GrantFormWrapper>
  );
}

export default CostIEBreakdownComponent;
