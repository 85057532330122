import { PaymentYear, Payment } from './types';

export const paymentYearAdapter = (data: PaymentYear, idx: number) => {
  return {
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    totalAmount: data.totalAmount ?? '',
    numberOfPayments: data.numberOfPayments ?? '',
    justification: data.justification ?? '',
  }
}


export const paymentsAdapter = (data: Payment[] = []) => {
  return data.map(paymentAdapter);
}
export const paymentAdapter = (data: Payment) => {
  return {
    name: data.name ?? '',
    amount: data.amount ?? '',
    isInflationAppliedEachYear: data.isInflationAppliedEachYear,
    id: data.id ?? '',
    years: data.years ? data.years.map(paymentYearAdapter) : []
  }
}
