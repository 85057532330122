import { FC, useCallback, useState } from 'react';
// types
import { FullGrant, Travel, TravelYear } from 'store/grants/types';
// hooks
import useTravel from 'hooks/useTravel';
// components
import Typo from 'components/typo';
import { Form, Checkbox, Input, Button } from 'components/form';
// styles
import styles from './status.module.css';

type Props = {
  grant: FullGrant;
  trip: Travel;
};

const ConsultantStatusForm: FC<Props> = ({ trip, grant }) => {
  const { updateStatus, loading } = useTravel();
  const [data, setData] = useState({
    ...trip.status,
    years:
      trip.status.years.length > 0
        ? trip.status.years
        : grant.years.map((year: string[], serialNumber: number) => ({
          year,
          serialNumber: serialNumber + 1,
          isEnabled: false,
          numberOfConsultants: '',
        })),
  });

  const onChangeYears = useCallback(
    (year: Record<string, any>, idx: number | string) => {
      const newYears = data.years.map((item: TravelYear) => {
        if (item.serialNumber === idx)
          return {
            ...item,
            ...year,
          };
        return item;
      });
      setData((prev) => ({
        ...prev,
        years: newYears,
      }));
    },
    [data, setData],
  );

  const handleSubmit = useCallback(() => {
    updateStatus(grant.id, trip.id, {
      status: {
        ...data,
      }
    });
  }, [data, grant, trip]);

  return (
    <Form prompt onSubmit={handleSubmit}>
      <Typo className={styles.mb16}>
        Please check the year(s) of this grant proposal that this trip will take place in. Also, please
        specify the maximum number of consultants you would like to put on trip status. These numbers are
        used as a baseline multiplier later on in the trip budget and can be overridden when you reach that
        section.
      </Typo>

      <div className={styles.yearsFormWrapper}>
        {data.years.map((year, idx) => {
          return (
            <div className={styles.yearField}>
              <Checkbox
                className={styles.yearCheckbox}
                onChange={(event) =>
                  onChangeYears({ isEnabled: event.target.checked }, year.serialNumber || idx)
                }
                value={year.isEnabled}
                name={`years[${year.serialNumber}].isEnabled`}
                label={`Year ${year.serialNumber}`}
              />
              <Input
                className={styles.yearInput}
                name={`years[${idx}].numberOfConsultants`}
                label={'Number of consultants in this trip'}
                type="number"
                value={year.numberOfConsultants}
                disabled={!year.isEnabled}
                onChange={(event) =>
                  onChangeYears({ numberOfConsultants: event.target.value }, year.serialNumber || idx)
                }
                placeholder="Number of consultants"
              />
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-equip">
          Add a travel status
        </Button>
      </div>
    </Form>
  );
};

export default ConsultantStatusForm;
