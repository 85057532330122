import {FC, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import useUrl from 'hooks/useUrl';
import styles from './folders.module.css';
import Tip from 'components/tip';
import useUI from 'hooks/useUI';

export type FolderItem = {
  type: string;
  open?: boolean;
  paid?: boolean;
  disabled?: boolean;
  label: string | ReactElement;
  tip?: string;
  id?: string
}

export type FolderProps = {
  item: FolderItem;
  grantId?: string;
  children?: ReactElement | ReactElement[];
  icon?: boolean;
  blocked?: boolean;
  customIcon?: string;
  hint?: string;
  onDelete?: () => void;
  onCopy?: () => void;
}

export type Props = FolderProps & {
  type: 'category' | 'section' | 'subCategory' | 'categoryId';
}

const Folder:FC<Props> = (props: Props) => {
  const {
    item, hint, customIcon,
    onCopy, onDelete, type,
    children, grantId, blocked
  } = props;
  const { onShowPaywall } = useUI();
  const parsedUrl = useUrl();
  const primeOpen = (grantId === parsedUrl.grantId && parsedUrl[type] === item.type) || Boolean(item.open);
  const [ open, onChangeOpen ] = useState<boolean>(primeOpen);

  const handleDelete = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onDelete) onDelete();
  }, [onDelete]);

  useEffect(() => {
    if (primeOpen) onChangeOpen(true);
  }, [primeOpen])

  const handleCopy = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCopy) onCopy();
  }, [onCopy]);

  const customIconElement = useMemo(() => {
    if (hint && customIcon) {
      return (
        <Tip left text={hint} className={cx(styles.custom, {[styles.withDelete]: onDelete})}>
          <Icon size={20} className={styles.icon} icon={customIcon}/>
        </Tip>
      );
    }
    if (customIcon) {
      return (
        <Icon size={20} className={cx(styles.icon, styles.custom, {[styles.withDelete]: onDelete})} icon={customIcon}/>
      );
    }
    return null;
  }, [onDelete, customIcon, hint])

  const btn = (
    item.disabled ?
      <Tip top className={styles.tip} when={item.disabled} text={item.tip || ''}>
        <button disabled={item.disabled} className={styles.btn} onClick={() => onChangeOpen(!open)}>
          <Icon icon="chevron-right" className={cx(styles.icon, styles.chevron)}/>
          <span className={styles.label}>
            {item.label}
          </span>
          {customIconElement}
          {onCopy ? <Icon size={20} className={cx(styles.icon, styles.copy)} onClick={handleCopy} icon="copy-01"/> : null}
          {onDelete ? <Icon size={20} className={cx(styles.icon, styles.delete)} onClick={handleDelete} icon="trash"/> : null}
        </button>
      </Tip>
      :
        <button className={styles.btn} onClick={() => onChangeOpen(!open)}>
          <Icon icon="chevron-right" className={cx(styles.icon, styles.chevron)}/>
          <span className={styles.label}>
            {item.label}
          </span>
          {customIconElement}
          {onCopy ? <Icon size={20} className={cx(styles.icon, styles.copy)} onClick={handleCopy} icon="copy-01"/> : null}
          {onDelete ? <Icon size={20} className={cx(styles.icon, styles.delete)} onClick={handleDelete} icon="trash-01"/> : null}
        </button>
  );

  if (blocked) {
    return (
      <div className={cx(styles.wrapper)}>
        <button className={cx(styles.btn, styles.blocked)} onClick={onShowPaywall}>
          <Icon icon="chevron-right" className={cx(styles.icon, styles.chevron)}/>
          <span className={styles.label}>
            {item.label}
          </span>
          <Icon size={20} className={styles.icon} icon="lock-01"/>
        </button>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, {[styles.open]: open, [styles.primeOpen]: primeOpen })}>
      {btn}
      {open && children ?
        <div className={styles.childWrapper}>
          {children}
        </div>
        : null}
    </div>
  )
}

export default Folder;
