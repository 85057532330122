import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {Input, Form, Button, Autocomplete} from 'components/form';
import Field from 'components/field';
import Typo from 'components/typo';
import styles from './create.module.css';
import useParticipants from 'hooks/useParticipants';
import {useParams} from 'react-router-dom';
import useEnums from 'hooks/useEnums';
import useGrants from 'hooks/useGrants';
import {getEventFromGrant} from 'helpers/getFromGrant';
import {GrantEvent} from 'store/grants/types';

type Props = {
  item: GrantEvent;
  grantId: string;
}

const ParticipantForm: FC<Props> = ({item, grantId}) => {
  const {
    updateEvent, errors, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = useParticipants({
    form_page_type: 'Event info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {getGrantEventCategories, eventCategories, isEventCategoriesLoading} = useEnums();
  const [data, onChange] = useState({
    name: item.name ?? '',
    category: item.category ?? ''
  });

  const onSubmit = useCallback(() => {
    updateEvent(grantId, item.id, data);
  }, [data, item, grantId, updateEvent]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field withHelp last>
        <Input name="name" value={data.name} errors={errors.name}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({...data, name: event.target.value})}
               placeholder="Enter event name" label="Event name" required/>
        <Autocomplete
          value={data.category}
          options={eventCategories}
          onLoad={getGrantEventCategories}
          isLoading={isEventCategoriesLoading}
          min={3}
          onEnter={(category: any) => onChange({...data, category})}
          onChange={(category: any) => onChange({...data, category})}
          name="category" errors={errors.category} label="Event category"
          required
          help={`Some examples of category names could be "Conference", "Seminar", "Professional Development Opportunity", "Expedition", etc.`}
          placeholder="Select or enter event category"
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="save-participant-event">Save</Button>
      </div>
    </Form>
  );
}

const CreateEvent: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, any> = useParams();

  const {item, index} = getEventFromGrant(grant, params.eventId);
  const key = `${params.id}-event-${params.eventId}`;

  return (
    <GrantFormWrapper title="Participants Support" subtitle={`Event ${index > 0 ? index : ''}`}>
      <div>
        <div className={styles.textWrapper}>
          <Typo className={styles.text}>Participants are people who attend events, participate in research, or help
            distribute the results of research in some manner. Participants differ from those supported in the Personnel
            section because the personnel section is meant for people who are technically considered "Employees" of the
            grant. Participant support can be thought of as budgeting for bundles of money to be set aside to reimburse
            people who are involved in the grant in some manner but not to the extent that they would be considered
            employees. It is your job to determine how much each bundle of money should be for someone that would need
            to be incentivised to participate in depth to further your progress towards achieving the specified outcome
            of your proposal. It's important to note that participant support is bundles of money that are normally
            larger than a small incentive payment to entice people to participate in a survey. Typically a bundle of
            participant support money will be to reimburse someone for their participation in an event or program. When
            budgeting for a participant there are three areas of compensation that should be considered per
            participant.</Typo>
          <Typo className={styles.text}>1) Reimbursement for the person's time,</Typo>
          <Typo className={styles.text}>2) Reimbursement for that person's travel costs,</Typo>
          <Typo className={styles.text}>3) Reimbursement for expenses that a participant is expected to incur while
            participating in the event or program.</Typo>
          <Typo className={styles.text}>It is not necessary to budget for all categories so you can skip any section
            that won't be required for your research. Please note that averages of what expenses are expected to be
            should be used because it is very difficult to predict exactly how much each person will spend to
            participate. Each person of the same type who is being budgeted for as a participant will be handed the same
            amount for their participation in their event. This amount will be distributed to each person.</Typo>
          <Typo className={styles.text}>A qualified participant is someone from whom you are collecting ongoing data and
            can be classified as a participant needing participant support if the data collection is multiple types of
            data collected over time and requires effort on the part of the participant. This is a complicated way of
            saying that people who fill out 1-3 short interviews or 1-2 surveys, although they are participants, are not
            eligible for "participant support" and compensation for these types of participants who require less support
            will be budget in, in a future section as an "incentive payment". These would be considered "Small data
            collection events.</Typo>
        </div>
        {item ? <ParticipantForm item={item} key={key} grantId={grant.id}/> : null}
      </div>
    </GrantFormWrapper>
  );
}

export default CreateEvent;
