import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { groupBy, times } from 'lodash';
import Head from 'containers/head';
import cx from 'classnames';
import dayjs from 'dayjs';
import {numberToMoney} from 'helpers/numbers';
import { Link } from 'react-router-dom';
import { Button } from 'components/form';
import NoGrants from 'containers/nogrants';
import Spinner from 'components/spinner';
import Typo from 'components/typo';
import Tip from 'components/tip';
import Icon from 'components/icon';
import Card from 'components/card';
import useDashboard from 'hooks/useDashboard';
import useUI from 'hooks/useUI';
import {stepNames, stepNamesList} from 'const';
import { Stat, StatGrant} from 'store/grants/types';
import styles from './home.module.css';
import useGrants from 'hooks/useGrants';
import { trackUserPageview } from 'helpers/trackUserActions';
import {useMixPanel} from "../../../hooks/useMixPanel";

type HeaderCard = {
  label: string;
  icon: string;
  value: number | string;
}

type ChartItem = {
  step: string;
  label: string;
  count: number;
  grants: StatGrant[] | undefined[];
}

const Home:FC = () => {
  const { get, dashboard, loading } = useDashboard();
  const {pageViewed} = useMixPanel();
  const { clearGrants } = useGrants();
  const [ grant, onChangeGrant ] = useState<StatGrant | undefined>(undefined);
  const { sidebarWidth, onChangeOpenedSteps } = useUI();
  const getN = () => {
    const height = window.innerHeight;
    if (height > 950) return 10;
    if (height > 750 && height < 950) return 6;
    if (height < 750) return 4;
    return 10;
  };

  const [N, onChangeN] = useState<number>(getN());

  const onResize = useCallback(() => {
    const newN = getN();
    if (newN !== N) onChangeN(newN);
  }, [N]);

  useEffect(() => {
    //track mix panel Page Viewed event
    pageViewed('Home')
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize])

  useEffect(() => {
    get();
    onChangeOpenedSteps([]);
    clearGrants();
    trackUserPageview("Grant Home");
  }, []);

  const cards:HeaderCard[] = useMemo(() => ([
    {
      label: 'Total number of grants',
      icon: 'coins-hand',
      value: dashboard.countTotal,
    },
    {
      label: 'Number of grants won',
      icon: 'check-verified-02',
      value: dashboard.countAccepted
,    },
    {
      label: 'Total funding received',
      icon: 'coins-stacked-03',
      value: `${numberToMoney(dashboard.totalFunding)}`,
    },
    {
      label: 'Account owner\'s earned money',
      icon: 'wallet-02',
      value: `${numberToMoney(dashboard.personalBudget)}`
    },
  ]), [dashboard]);

  const charts:ChartItem[] = useMemo(() => {
    const groupedByStepGrants:Record<string, Stat[]> = groupBy(dashboard.grantsStats, 'step');
    const defaultList = times(N, (idx: number) => idx);
    return stepNamesList.reduce((result: ChartItem[], item: string, idx: number) => {
      const grantsStats = groupedByStepGrants[item] ?? [];
      const grants = grantsStats.reduce((res: StatGrant[], stat: Stat) => ([...res, ...stat.grants]), []);
      return [
        ...result,
        {
          step: item,
          label: stepNames[item] ?? `STEP ${idx + 1}`,
          count: grants.length,
          grants: defaultList.map((idx: number) => grants[idx])
        }
      ]
    }, [])
  }, [dashboard, N]);

  const onShowInfo = useCallback((item: StatGrant) => {
    if (item.id === grant?.id) {
      onChangeGrant(undefined);
      return;
    }
    onChangeGrant(item);
  }, [grant]);

  return (
    <>
      <Head title="Dashboard" />
      <div className={cx('container', styles.container, {[styles.center]: dashboard.countTotal < 1})}>
        <div className={styles.wrapper}>
          {dashboard.countTotal < 1
            ? <NoGrants />
            :
            <>
              <div className={styles.header}>
                {cards.map((item: HeaderCard, idx: number) => (
                  <Card className={styles.card} key={idx}>
                    <Icon size={24} icon={item.icon} type="info"/>
                    <Typo className={styles.cardTitle} size={12} semi>{item.label}</Typo>
                    <Typo bold className={styles.cardValue} size={24}>{item.value}</Typo>
                  </Card>
                ))}
              </div>
              <div className={styles.chartWrapper}>
                <Card className={styles.chart}>
                  <Typo type="h4" bold>A dot plot of grants and stages of funding</Typo>
                  <div className={styles.bars}>
                    {
                      charts.map((chart: ChartItem, idx: number) => (
                        <div className={styles.bar} key={idx}>
                          <Typo className={styles.barTitle}>{chart.label}</Typo>
                          {chart.grants.map((item: StatGrant | undefined, index: number) => (
                            <div key={index} className={styles.cell}>
                              {item
                                ?
                                <Tip text={item.title}>
                                <span onClick={() => onShowInfo(item)}
                                      role="button" className={cx(styles.dot, {[styles.blue]: item.id === grant?.id})}
                                />
                                </Tip>
                                : null}
                            </div>
                          ))}
                          <div className={styles.cell}>
                            {chart.count > N ?
                              <span className={styles.blueDot}>+{Number(chart.count - N)}</span>
                              : null
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </Card>
                {grant
                  ?
                  <div className={styles.infoWrapper}>
                    <Typo size={16} bold className={styles.infoTitle}>{grant.title}</Typo>
                    {grant.nsf?.solicitationName ? <Typo className={styles.infoField}>{grant.nsf.solicitationName}</Typo> : null}
                    {grant.primaryInvestigator?.firstName ? <Typo className={styles.infoField}>{`${grant.primaryInvestigator.firstName} ${grant.primaryInvestigator.lastName}`}</Typo> : null}
                    {grant.organization?.name ? <Typo className={styles.infoField}>{grant.organization.name}</Typo> : null}
                    {
                      grant.startDate ?
                        <>
                          <Typo type="div" className={styles.infoField}>
                            <>
                              <strong>Start date: </strong> {dayjs(grant.startDate).format('MM/DD/YYYY')}
                            </>
                          </Typo>
                          <Typo type="div" className={styles.infoField}>
                            <>
                              <strong>End date: </strong> {dayjs(grant.endDate).format('MM/DD/YYYY')}
                            </>
                          </Typo>
                        </>
                        : null
                    }
                    {
                      grant.status && grant.step === 'hidden'
                        ?
                        <Typo type="div" className={styles.infoField}>
                          <>
                            <strong>Subcategory: </strong>
                            <span className={styles.capitalizedField}>{grant.status}</span>
                          </>
                        </Typo>
                        : null
                    }
                    <Link to={`/grants/${grant.id}/edit`}>
                      <Button name="link-to-grant" size="xs" full type="bordered" postIcon="arrow-narrow-right">
                        Go to a grant
                      </Button>
                    </Link>
                  </div>
                  : null
                }
              </div>
            </>
          }
          { loading
            ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full />
            : null
          }
        </div>
      </div>
    </>
  );
}

export default Home;
