import {ChangeEvent, FC} from 'react';
import Field from 'components/field';
import {Button, Form, Input, Select} from 'components/form';
import Dropdown, {ListItem} from 'components/dropdown';
import styles from './rates.module.css';


type Search = {
  organizationName: string;
  isAccepted: boolean | undefined;
}

type Props = {
  search: Search;
  onChange: (field: Record<string, any>) => void;
  onClear: () => void;
  onClickAdd: () => void;
  menu: ListItem[];
  hideAdd?: boolean;
  grant?: boolean;
}
const grantOptions = [
  {
    value: true,
    label: 'Won'
  },
  {
    value: false,
    label: 'Ongoing'
  }
];

const Filters:FC<Props> = ({search, onClear, hideAdd, grant, onClickAdd, menu, onChange}) => {

  const addBtn = (
    grant
      ?
      <Dropdown label="Add rate" hideChevron triggerClassName={styles.dropdownTrigger}
                name="rates-dropdown" list={menu} key={0}>
        <Button name="add" postIcon="chevron-down" preIcon="plus" htmlType="button">
          Add new rate
        </Button>
      </Dropdown>
      :
      <Button key={0} name="add" onClick={onClickAdd} preIcon="plus" htmlType="button">Add rate</Button>
  );

  const input = (
    <Input name="organizationName" className={styles.searchAutocomplete}
           placeholder="Enter organization name" label="Organization"
           value={search.organizationName} key={1}
           onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({organizationName: event.target.value})}
    />
  );

  const select = (
    <Select options={grantOptions} name="grantOptions" placeholder="Select the item" label="Won/Ongoing grants"
            help="Hide or show rates associated with grants that have been won or are in progress"
            helpInLabel bool key={`${search.isAccepted}-2`}
            value={search.isAccepted}
            onChange={(isAccepted: any) => onChange({isAccepted})}
    />
  );

  const btns = (
    <div className={styles.searchBtns} key={3}>
      <Button name="clear" onClick={onClear} htmlType="button" type="bordered">Clear filters</Button>
      {hideAdd ? null : addBtn}
    </div>
  );

  const grantFilters = (
    [
      input,
      btns
    ]
  );

  const userFilters = (
    [
      input,
      select,
      btns
    ]
  );

  return (
    <Form className={styles.search}>
      <Field last className={styles.searchField}>
        {grant ? grantFilters : userFilters}
      </Field>
    </Form>
  );
}

export default Filters;
