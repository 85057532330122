import {useCallback, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parseErrors, { Errors } from 'helpers/errors';
import {AxiosError, AxiosResponse} from 'axios';
import useApi from './useApi';
import {
  addSubawardAction
} from 'store/grants/otherDirectCosts/Subawards/actions';
import { subAwardSchemeSelector } from 'store/grants/selector';
import {useHistory} from 'react-router-dom';
import {confirm} from 'components/confirmation';
import * as T from './requestTypes';
import toast from 'components/toast';
import useUI from './useUI';
import {setSubAwardScheme} from '../store/grants/actions';
import useGrants from './useGrants';
import dayjs from 'dayjs';
import {percentToFixed} from 'helpers/numbers';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";

type iUseSubawards  = {
  errors: Errors;
  loading: boolean;
  create: (grantId: string) => void;
  getTotals: (grantId: string) => void;
  publicSubaward: PublicSubAward;
  privateSubaward: SubAwardeeForm;
  forbidden: boolean;
  totals: string[][];
  submitted: boolean;
  getPublicSubaward: (token: string) => void;
  getPrivateSubaward: (grantId: string) => void;
  updatePublicSubaward: (token: string, data: PublicSubAward) => void;
  getLink: (grantId: string, id: string, cb: (link: string) => void) => void;
  destroy: (grantId: string, subawardId: string, redirect: boolean) => void;
  update: (grantId: string, id: string, data: T.Subaward) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type SubAwardeeForm = {
  years: Array<{
    year: string[];
    serialNumber: number;
    directCost: string | number;
    indirectCost: string | number;
    modifiedDirectCost: string | number;
    fee: string | number;
  }>;
  rates: Array<{
    name: string;
    rate: string | number | null;
    startDate: string | null;
    endDate: string | null;
  }>;
}

export type PublicSubAward = {
  primaryInvestigator: {
    firstName: string;
    lastName: string;
  };
  grant: {
    title: string;
  };
  expiresAt: string | null;
  isEditable: boolean;
  author: {
    email: string;
  };
  subRecipient: {
    name: string;
    primaryOrganization: string;
    department: string;
    email: string;
  };
  subAwardeeForm: SubAwardeeForm
}

const subAwardeeFormAdapter = (data: SubAwardeeForm):SubAwardeeForm => {
  return {
    years: (data?.years || []).map((item, idx: number) => ({
      year: item.year ?? [],
      serialNumber: item.serialNumber ?? idx + 1,
      directCost: item.directCost ?? '',
      indirectCost: item.indirectCost ?? '',
      modifiedDirectCost: item.modifiedDirectCost ?? '',
      fee: item.fee ?? '',
    })),
    rates: (data?.rates || []).map((item) => ({
      name: item.name ?? '',
      rate: item.rate ? percentToFixed(item.rate) : null,
      startDate: item.startDate ? dayjs(item.startDate).format('YYYY-MM-DD') : null,
      endDate: item.endDate ? dayjs(item.endDate).format('YYYY-MM-DD') : null,

    }))
  }
}
const publicSubAwardAdapter = (data: PublicSubAward):PublicSubAward => {
  return {
    primaryInvestigator: {
      firstName: data.primaryInvestigator?.firstName ?? '',
      lastName: data.primaryInvestigator?.lastName ?? '',
    },
    grant: {
      title: data.grant?.title ?? 'Grant Title'
    },
    isEditable: Boolean(data.isEditable),
    expiresAt: data.expiresAt ?? null,
    author: {
      email: data.author?.email ?? '',
    },
    subRecipient: {
      name:data.subRecipient?.name ?? '',
      primaryOrganization:data.subRecipient?.primaryOrganization ?? '',
      department:data.subRecipient?.department ?? '',
      email:data.subRecipient?.email ?? '',
    },
    subAwardeeForm: subAwardeeFormAdapter(data?.subAwardeeForm ?? {})
  }
}

type Props = {
  form_page_type?: string
}

const useSubawards = (props: Props = {}):iUseSubawards => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'subawards';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const dispatch = useDispatch();
  const api = useApi();
  const { loader, onShowNavigationMessage } = useUI();
  const { getOneWithSide } = useGrants();
  const history = useHistory();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const [forbidden, onChangeForbidden] = useState<boolean>(false);
  const [totals, onChangeTotals] = useState<string[][]>([]);
  const [submitted, onChangeSubmitted] = useState<boolean>(false);
  const [publicSubaward, onChangePublicSubaward] = useState<PublicSubAward>(publicSubAwardAdapter({} as PublicSubAward));
  const privateSubaward = useSelector(subAwardSchemeSelector);

  const create = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.createSubaward(grantId, {})
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/otherDC/subawards/${response.data.id}/edit`);
        setTimeout(() => {
          dispatch(addSubawardAction(response.data));
        }, 300);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api, history, dispatch]);

  const destroy = useCallback((grantId: string, subawardId: string, redirect: boolean) => {
    confirm({
      title: 'Delete subaward info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteSubaward(grantId, subawardId)
          .then(() => {
            getOneWithSide(grantId, () => {
              if (redirect) history.push(`/grants/${grantId}/edit`);
              toast.success({
                title: 'The subaward has been deleted',
                message: 'Changes have been successfully saved'
              });
            });
          })
      }
    })
  }, [api, history, getOneWithSide]);

  const update = useCallback((grantId: string, id: string, data: T.Subaward) => {
    onChangeLoading(true);
    onChangeErrors({});
    api.updateSubaward(grantId, id, data)
      .then(() => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'The subaward details have been updated',
          message: 'Changes have been successfully saved'
        });
        getOneWithSide(grantId);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const getLink = useCallback((grantId: string, id: string, cb: (link: string) => void) => {
    loader.start();
    api.getSubawardFormLink(grantId, id)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data.link)
      });
  }, [api, loader]);

  const getPublicSubaward = useCallback((token: string) => {
    onChangeLoading(true);
    api.getPublicSubaward(token)
      .then((response: AxiosResponse) => {
        onChangePublicSubaward(publicSubAwardAdapter(response.data));
        onChangeLoading(false);
      })
      .catch((error: AxiosError) => {
        onChangeForbidden(true);
        onChangeLoading(false);
      });
  }, [api]);

  const getPrivateSubaward = useCallback((grantId: string) => {
    onChangeLoading(true);
    loader.start();
    api.getPrivateSubaward(grantId)
      .then((response: AxiosResponse) => {
        dispatch(setSubAwardScheme(response.data));
        onChangeLoading(false);
        loader.stop();
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        loader.stop();
      });
  }, [api, dispatch, loader]);


  const updatePublicSubaward = useCallback((token: string, data: PublicSubAward) => {
    confirm({
      title: 'Subawardees form submission',
      text: 'Make sure all data is correct before final submission. To resubmit the form, you will need to contact the Primary Investigator of the grant',
      type: 'success',
      okText: 'Submit',
      onConfirm: () => {
        onChangeLoading(true);
        api.updatePublicSubaward(token, data)
          .then(() => {
            onChangeSubmitted(true);
            toast.success({
              title: 'The subaward details have been updated',
              message: 'Changes have been successfully saved'
            })
            onChangeLoading(false);
          })
          .catch((error: AxiosError) => {
            onChangeLoading(false);
          });
      }
    })
  }, [api]);

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.subawardeesTotals(grantId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data)
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [api])

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    errors,
    getLink,
    publicSubaward,
    updatePublicSubaward,
    update,
    getTotals,
    totals,
    submitted,
    forbidden,
    getPublicSubaward,
    getPrivateSubaward,
    destroy,
    loading,
    privateSubaward,
    create,
    trackError,
    trackExit,
    trackFormStarted
  };
}

export default useSubawards;
