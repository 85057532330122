import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Textarea} from 'components/form'
import styles from './cs.module.css';
import {ContractYear, Contract} from 'store/grants/otherDirectCosts/Contracts/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getContractFromODC} from 'helpers/getFromGrant';
import useContracts from 'hooks/useContracts';

type Props = {
  item: ContractYear;
  contract: Contract;
  grantId: string;
}

const totalCostAfterInflationHelpText = 'Inflation does not apply. You can change it in the Information tab';
const totalCostAfterInflationFirstYearHelpText = 'Inflation is not applied in Year 1 for any costs, but will be' +
  ' applied in subsequent years.'

const ContractYearForm: FC<Props> = ({item, contract, grantId}) => {
  const [data, onChangeData] = useState<ContractYear>(item);
  const {
    loading, updateContract, previewContractYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useContracts({
    form_page_type: 'Date'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    if (data.value !== '' && Number(data.value) > -1) {
      previewContractYear(grantId, contract.id, {
        serialNumber: item.serialNumber,
        value: data.value
      }, (data: any) => {
        onChangeData({
          ...data,
          compensationBeforeInflation: data.compensationBeforeInflation,
          compensationAfterInflation: data.compensationAfterInflation,
        })
      });
    } else {
      onChangeData({
        ...data,
        value: '',
        compensationBeforeInflation: '',
        compensationAfterInflation: '',
      })
    }
  }, [item, previewContractYear, contract.id, grantId, data]);


  const onSubmit = useCallback(() => {
    const newYears = contract.years.map((year: ContractYear) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...year,
          value: data.value,
          justification: data.justification,
          compensationBeforeInflation: data.compensationBeforeInflation,
          compensationAfterInflation: data.compensationAfterInflation,
        }
      }
      return year;
    });
    updateContract(grantId, contract.id, {
      years: newYears
    })
  }, [data, item, contract, grantId, updateContract]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input name="value"
               placeholder="Enter amount" type="number" money
               value={data.value} onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, value: event.target.value})}
               label={`Contract value in Year ${item.serialNumber}`} required/>

      </Field>
      <Field>
        <Input name="compensationBeforeInflation"
               placeholder="Total cost before inflation"
               value={data.compensationBeforeInflation}
               type="number" money auto disabled readOnly
               label={`Total cost before inflation for Year ${item.serialNumber}`}/>
        <Input name="totalCostAfterInflation"
               placeholder="Total cost after inflation"
               help={
                 !contract.isInflationAppliedEachYear
                   ? totalCostAfterInflationHelpText
                   : item.serialNumber === 1
                     ? totalCostAfterInflationFirstYearHelpText
                     : ""
               }
               value={data.compensationAfterInflation} auto
               disabled readOnly type="number" money
               label={`Total cost after inflation for Year ${item.serialNumber}`}
        />

      </Field>
      <Field last full>
        <Textarea name="justification"
                  label="Justification for this cost"
                  placeholder="Enter a description..."
                  value={data.justification}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeData({
                    ...data,
                    justification: event.target.value
                  })}
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const ContractYearPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getContractFromODC(grant, params.contractId);

  const year = item?.years[Number(params.year)];

  return (
    <GrantFormWrapper title="Other Direct Costs"
                      subtitle={`Contracts. Contract ${index}. Year ${Number(params.year) + 1}`}>
      {
        year
          ? <ContractYearForm grantId={grant.id} contract={item}
                              key={`${grant.id}-odc-contract-${params.contractId}-${params.year}`} item={year}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default ContractYearPage;
