import {FC} from 'react';
import Typo from 'components/typo';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import styles from './costSharing.module.css';

export const CostSharingGeneralInfo: FC = () => {
  return (
    <GrantFormWrapper title="Cost sharing">
      <>
        <Typo className={styles.mb16}>
          <>
            Check this
            {' '}
            <a
              title="US NSF - Policy"
              href="https://www.nsf.gov/bfa/dias/policy/"
              target="_blank"
              className={styles.textLink}>
              link
            </a>
            {' '}
            to see if your program requires some form of cost sharing. Cost sharing is prohibited unless
            specifically asked for in your program solicitation.
          </>
        </Typo>

        <Typo className={styles.mb16}>
          Please double check your solicitation. However, if you are part of one of the following programs, please be
          aware that cost sharing is likely a requirement for your proposal.
        </Typo>

        <a className={styles.mb16} title="Cost Sharing Report"
           href="https://www.nsf.gov/bfa/dias/policy/csdocs/principles.pdf" target="_blank">
          Policies surrounding cost sharing
        </a>

        <Typo bold>NSF Programs with Mandatory Cost Sharing: </Typo>
        <ul className={styles.list}>
          <li>
            <Typo className={styles.li}>
              Enabling Access to Cloud Computing Resources for CISE Research and Education (Cloud Access)
            </Typo></li>
          <li><Typo className={styles.li}>Innovation Corps Program (I-Corps)</Typo></li>
          <li><Typo className={styles.li}>Major Research Instrumentation Program</Typo></li>
          <li><Typo className={styles.li}>Robert Noyce Scholarship Program</Typo></li>
          <li><Typo className={styles.li}>Engineering Research Centers</Typo></li>
          <li><Typo className={styles.li}>Established Program to Stimulate Competitive Research</Typo></li>
        </ul>

        <Typo bold>Individual NSF-funded Projects with Mandatory Cost Sharing</Typo>
        <ul className={styles.list}>
          <li>
            <Typo className={styles.li}>
              Computing Innovation Fellows Project 2021, Award No. 2127309, Prime Awardee:
              Computing Research Association (subawardees' indirect cost recovery limited to 35%)
            </Typo>
          </li>
          <li>
            <Typo className={styles.li}>
              Computing Innovation Fellows Project 2020, Award No. 2030859, Prime Awardee: Computing Research
              Association (subawardees' indirect cost recovery limited to 35%)
            </Typo>
          </li>
        </ul>

        <Typo className={styles.mb16}>
          Please note that NSF Program Officers are not allowed to "impose or encourage" mandatory cost sharing unless
          it says it can be directly and explicitly in your program solicitation. However, you may out of your own
          pocket contribute to your proposal.
        </Typo>

        <Typo className={styles.mb16}>
          The NSF states this in this manner: "Continue to contribute voluntary uncommitted cost sharing to
          NSF-sponsored projects." In more straightforward terms, if you plan to contribute your own personal money,
          these amounts do NOT go in this section. This section is primarily to create a binding contract between either
          the NSF and your organization or the NSF and a third party who is going to be committed to share costs on this
          project with the NSF.
        </Typo>

        <Typo className={styles.mb16}>
          All "Uncommitted Cost Sharing" does not have to be reported. Uncommitted cost sharing is your own personal
          contribution. They aren't required by contract; hence they are uncommitted which the NSF says not to report
          under this section.
        </Typo>
      </>
    </GrantFormWrapper>
  );
};

export default CostSharingGeneralInfo;
