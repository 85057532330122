import {FC, ReactElement, useMemo} from 'react';
import cx from 'classnames';
import styles from './table.module.css';
import Typo from 'components/typo';
import Icon from '../icon';

type SortProps = {
  sort: {
    field: string;
    type: 'asc' | 'desc';
  };
  field: string;
  onClick: () => void;
  children: Cell;
}

export const Sort:FC<SortProps> = ({sort, field, onClick, children}) => {
  return (
    <div role="button" onClick={onClick} className={cx(styles.sort, {[styles.active]: sort.field === field}, styles[sort.type])}>
      <span className={styles.sortChildWrapper}>{children}</span> <Icon className={styles.sortIcon} icon="arrow-down" />
    </div>
  )
}

export type Cell = string | ReactElement | number | undefined;

type Props = {
  data: Array<Cell[]>;
  headers: Cell[];
  title?: string;
  placeholder?: string | ReactElement;
  className?: string;
}

const Table:FC<Props> = ({ title, placeholder = 'No data to display', className, headers, data }) => {

  const placehold = useMemo(() => {
    if (!placeholder) return null;
    if (typeof placeholder === 'string') {
      return (
        <Typo size={14} type="div" className={styles.placeholder}>{placeholder}</Typo>
      )
    }
    return placeholder;
  }, [placeholder]);

  return (
    <div className={cx(styles.wrapper, className)}>
      {title ? <h5 className={styles.title}>{title}</h5> : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
          <tr>
            {headers.map((item: Cell, idx: number) => (
              <td key={idx}>{item}</td>
            ))}
          </tr>
          </thead>
          <tbody>
          {data.map((row: Cell[], rowIdx) => (
            <tr key={rowIdx}>
              {row.map((cell: Cell, cellIdx: number) => (
                <td key={cellIdx}>{cell}</td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
        {data.length < 1 ? placehold : null}
      </div>
    </div>
  );
}

export default Table;
