import {FC} from 'react';
// types
import {ETravelType, FullGrant} from 'store/grants/types';
import {EmptyTravelData} from 'hooks/requestTypes';
// helpers
import {getTravelFromGrant} from 'helpers/getFromGrant';
// components
import Folder, {FolderProps} from 'pages/grant/paper/card/folder';
import Typo from 'components/typo';
import CreateBtn from 'pages/grant/paper/card/create';
// styles
import styles from '../travel.module.css';

type Props = {
  grant: FullGrant;
  trips: Array<FolderProps>;
  tripsType: ETravelType;
  listTitle: string;
  listId: string;
  activeTripId: string;
  handleDeleteTrip: (id: string) => void;
  handleDuplicateTrip: (id: string) => void;
  handleCreateTrip: (data: EmptyTravelData) => void;
};

const TripsList: FC<Props> = ({
  listTitle,
  trips,
  tripsType,
  handleCreateTrip,
  handleDeleteTrip,
  handleDuplicateTrip,
  listId,
  activeTripId,
  grant
}) => {
  const {trip: activeTrip} = getTravelFromGrant(grant, activeTripId);

  const listItem = {
    label: listTitle,
    type: tripsType,
    id: listId,
    active: activeTrip?.type === tripsType,
    open: activeTrip?.type === tripsType
  };



  return (
    <Folder item={listItem} grantId={grant.id} type="category">
      <div>
        {trips.map((folder: FolderProps, idx: number) => (
          <Folder
            key={idx}
            onCopy={() => handleDuplicateTrip(folder.item.id as string)}
            item={folder.item} grantId={grant.id}
            type="categoryId"
            onDelete={() => handleDeleteTrip(folder.item.id as string)}
          >
            {folder.children}
          </Folder>
        ))}

        {!trips.length && (
          <Typo className={styles.placeholder} size={14} type="div">
            No trips have been created yet
          </Typo>
        )}

        <CreateBtn
          name="add-grant-travel" side={grant}
          onClick={() => handleCreateTrip({type: tripsType})}
          tip="To add a trip, you must save general info first"
        >
          Add a trip
        </CreateBtn>
      </div>
    </Folder>
  );
};

export default TripsList;
