import {FC, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
// hooks
import useUrl from 'hooks/useUrl';
// types
import {ETravelType, Travel, TravelCostData, TravelCostYear} from 'store/grants/types';
// constants
import {Kind, travelCostKinds} from 'const';
// components
import Item from 'pages/grant/paper/card/item';

type OtherCostYear = {
  idx: number;
  label: string;
};

type Props = {
  sideId: string;
  trip: Travel;
  cost: TravelCostData;
  showCostKind?: boolean;
  handleRemoveCost: (id: string) => void;
};

const Cost: FC<Props> = ({ sideId, trip, cost, showCostKind = false, handleRemoveCost }) => {
  const url = useUrl();

  const costYears = useCallback((cost: TravelCostData): OtherCostYear[] => {
    if (cost.isPerPerson) {
      return (cost.years || [])
        .map((item: TravelCostYear) => {
          const yearsLabel = `${dayjs(item.year[0]).format('MM/DD/YYYY')} - ${dayjs(item.year[1]).format(
            'MM/DD/YYYY',
          )}`;
          return {
            idx: Number(item.serialNumber),
            label: yearsLabel,
          };
        });
    }
    return [];
  }, []);

  const links = useMemo(() => {
    const lodging = ['alaskaHawaii', 'fourty8states', 'international'].includes(cost.kind)
      ? [{
          label: 'Lodging Breakdown',
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/lodging`,
          active: url.sectionId === cost.id && url.subSection === 'lodging'
        },
        {
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/breakdown`,
          label: cost.kind === 'alaskaHawaii' ? 'Meals Breakdown' : 'M&IE Breakdown',
          active: url.sectionId === cost.id && url.subSection === 'breakdown'
        }]
      : [];

    const years = cost.kind === 'other' && cost.isPerPerson
      ? costYears(cost).map((year: OtherCostYear) => ({
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/year/${year.idx}`,
          label: year.label,
          active: url.sectionId === cost.id && url.subSectionId === `${year.idx}`
        }))
      : [];

    const ie = cost.kind === 'alaskaHawaii'
      ? [{
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/ie`,
          label: 'IE Breakdown',
          active: url.sectionId === cost.id && url.subSection === 'ie',
        }]
      : [];

    const otherTotals = cost.kind === 'other' && trip.type !== 'consultant'
      ?
      [{
        to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/totals`,
        active: url.sectionId === cost.id && url.subSection === 'totals',
        label: 'Totals'
      }]
      : []

    const totals = ['alaskaHawaii', 'international'].includes(cost.kind)
      ? [{
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/totals`,
          active: url.sectionId === cost.id && url.subSection === 'totals',
          label: 'Totals'
        }]
      : otherTotals;

    const attendeeCondition = (cost.lodgingBreakdown.maxTotal &&
      cost.mAndIeBreakdown.maxTotal &&
      cost.kind === 'fourty8states') ||
      ['alaskaHawaii', 'international'].includes(cost.kind) ||
      (trip.type === ETravelType.Consultant && cost.kind === 'other');

    const attendee = attendeeCondition
      ?
        [{
          to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/attendee`,
          active: url.sectionId === cost.id && url.subSection === 'attendee',
          label: (trip.type === ETravelType.Consultant && cost.kind === 'other') ? 'Totals' : 'Trip attendees',
        }]
      : [];

    return [
      {
        label: 'Travel info',
        to: `/grants/${sideId}/travel/${trip.type}/${trip.id}/cost/${cost.id}/edit`,
        active: url.sectionId === cost.id && url.subSection === 'edit'
      },
      ...lodging,
      ...years,
      ...ie,
      ...attendee,
      ...totals,
    ];
  }, [url, costYears, cost, trip, sideId]);

  const role = ['fourty8states', 'alaskaHawaii', 'international']
    .includes(cost.kind as Kind)
      ? ['Per diem', travelCostKinds[cost.kind as Kind]]
      : travelCostKinds[cost.kind as Kind];

  return (
    <Item key={cost.id}
          onDelete={() => handleRemoveCost(cost.id)}
          links={links}
          active={url.sectionId === cost.id}
          role={role ?? undefined}
          title={cost.nameOfTrip}
    />
  );
};

export default Cost;
