import {FC, useCallback, useMemo} from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import useUI from 'hooks/useUI';
import styles from './wrapper.module.css';
import {Button} from 'components/form';
import Dropdown, { ListItem } from 'components/dropdown';
import useGrants from 'hooks/useGrants';
import { numberToMoney } from 'helpers/numbers';
import Tip from 'components/tip';
import {Link} from 'react-router-dom';
import RatesWarning from 'pages/grant/common/ratesWarning';
import useAuth from 'hooks/useAuth';
import { trackUserAction } from 'helpers/trackUserActions';

type Line = {
  visible: boolean;
  className: string;
  percent: string | number;
  value: string | number;
}

type Legend = {
  visible: boolean;
  className: string;
  label: string;
}

const getNumber = (value: string | number) => {
  return Math.floor(Number(value));
}

const BudgetBar:FC = () => {
  const { minimizedBudgetBar, minimizeBudgetBar, addHiddenRate, hiddenRatesInBar, maximizeBudgetBar } = useUI();
  const { grant } = useGrants();
  const { paid } = useAuth();

  const handleClickCollapse = useCallback(() => {
    if (minimizedBudgetBar) {
      maximizeBudgetBar();
      trackUserAction("Opens Budget Bar")
    } else {
      minimizeBudgetBar();
    }
  },[minimizedBudgetBar, minimizeBudgetBar, maximizeBudgetBar]);

  const list:ListItem[] = useMemo(() => {
    return [
      {
        label: 'Budget Settings',
        to: `/grants/${grant.id}/edit#budget`
      },
      {
        label: 'MTDC',
        to: `/grants/${grant.id}/mtdc`
      },
      {
        label: 'F&A rates',
        to: `/grants/${grant.id}/facilities/rates`
      },
      {
        label: 'Indirect Costs',
        to: `/grants/${grant.id}/indirect`
      },
    ]
  }, [grant]);

  const lines:Line[] = useMemo(() => {
    const { budgetCap, budgetBar } = grant;
    const sum = getNumber(budgetBar.directCost) + getNumber(budgetBar.directCostOverbudget) + getNumber(budgetBar.indirectCost) + getNumber(budgetBar.indirectCostOverbudget);
    const max = Math.max(sum, getNumber(budgetCap));
    return [
      {
        className: styles.teal,
        value: budgetBar.directCost,
        percent: (getNumber(budgetBar.directCost)/max) * 100,
        visible: getNumber(budgetBar.directCost) > 0,
      },
      {
        className: styles.red,
        value: budgetBar.directCostOverbudget,
        percent: (getNumber(budgetBar.directCostOverbudget)/max) * 100,
        visible: getNumber(budgetBar.directCostOverbudget) > 0,
      },
      {
        className: styles.primary,
        value: budgetBar.indirectCost,
        percent: (getNumber(budgetBar.indirectCost)/max) * 100,
        visible: getNumber(budgetBar.indirectCost) > 0,
      },
      {
        className: styles.red,
        value: budgetBar.indirectCostOverbudget,
        percent: (getNumber(budgetBar.indirectCostOverbudget)/max) * 100,
        visible: getNumber(budgetBar.indirectCostOverbudget) > 0,
      },
    ]
      .filter((item: Line) => item.visible);
  }, [grant]);

  const overBudget = getNumber(grant.budgetBar.directCostOverbudget) + getNumber(grant.budgetBar.indirectCostOverbudget);

  const legends:Legend[] = useMemo(() => {
    return [
      {
        className: styles.teal,
        label: 'Direct Costs',
        visible: getNumber(grant.budgetBar.directCost) > 0
      },
      {
        className: styles.primary,
        label: 'Indirect Costs',
        visible: getNumber(grant.budgetBar.indirectCost) > 0
      },
      {
        className: styles.red,
        label: 'Over Budget',
        visible: getNumber(grant.budgetBar.directCostOverbudget) > 0 || getNumber(grant.budgetBar.indirectCostOverbudget) > 0
      },
    ]
      .filter((item: Legend) => item.visible)
  }, [grant]);

  const onCloseRatesMessage = useCallback(() => {
    addHiddenRate(grant.id);
  }, [addHiddenRate, grant]);

  const showRates = useMemo(() => {
    if (!paid) return false;
    return getNumber(grant.budgetCap) > 0 && getNumber(grant.budgetBar.directCost) > 0 && !hiddenRatesInBar.includes(grant.id);
  }, [grant, paid, hiddenRatesInBar]);

  const rest = useMemo(() => {
    const value = getNumber(grant.budgetCap) - (getNumber(grant.budgetBar.directCost) + getNumber(grant.budgetBar.indirectCost));
    return {
      value,
      percent: value/getNumber(grant.budgetCap) * 100
    };
  }, [grant])

  return (
    <div className={cx(styles.barWrapper, {[styles.big]: !minimizedBudgetBar})}>
      <div className={styles.header}>
        <button className={styles.collapse} onClick={handleClickCollapse} type="button">
          Budget Progress <Icon icon="chevron-left" size={20} className={styles.collapseIcon}/>
        </button>
      </div>
      <div className={styles.bar}>
        <div className={styles.lineWrapper}>
          <div className={styles.line}>
            {lines.map((line: Line, idx: number) => (
              <Tip className={styles.sublineWrapper} key={idx} top text={numberToMoney(line.value, '0,0')} style={{width: `${line.percent}%`, zIndex: lines.length - idx}} when={line.percent < 6}>
                <span className={cx(styles.subline, line.className)}>
                {line.percent > 8 ? <span className={styles.sublineText}>{numberToMoney(line.value, '0,0')}</span> : null}
              </span>
              </Tip>
            ))}
            {rest.value > 0 && lines.length > 0
              ?
                <Tip className={styles.sublineWrapper} top when={rest.percent < 6} text={numberToMoney(rest.value, '0,0')} style={{width: `${rest.percent}%`}}>
                  <span className={cx(styles.subline, styles.transparent)}>
                  {rest.percent > 5 ? <span className={styles.sublineText}>{numberToMoney(rest.value, '0,0')}</span> : null}
                </span>
                </Tip>
              : null
            }
            {
              lines.length < 1
                ? <span className={styles.placeholder}>$0</span>
                : null
            }
          </div>
          <Dropdown hideChevron triggerClassName={styles.trigger} name="budget-bar" list={list}>
            <Button name="dropdown-toggler" className={styles.dropdownToggler} preIcon="coins-stacked-01" type="bordered"/>
          </Dropdown>
        </div>
        <div className={styles.legendsWrapper}>
          <div className={styles.legends}>
            {legends.map((item: Legend, idx: number) => (
              <div className={styles.legend} key={idx}>
                <span className={cx(styles.dot, item.className)}/>
                {item.label}
              </div>
            ))}
            {
              legends.length < 1 && getNumber(grant.budgetCap) < 1
                ?
                  <div className={styles.empty}>
                    <Icon size={20} className={styles.emptyIcon} icon="info-circle" />
                    Boost Your Progress!
                    <Link to={`/grants/${grant.id}/edit#budget`} className={styles.emptyLink}>Add your budget</Link>
                    to fuel the progress bar.
                  </div>
                : null
            }
          </div>
          <div className={styles.budgets}>
            {overBudget > 0
              ?
                <div className={cx(styles.budget, styles.over)}>
                  <Tip text="Your expenses have exceeded your current budget." top className={styles.overIconTip}>
                    <Icon icon="alert-triangle" className={styles.overIcon}/>
                  </Tip>
                  Total over budget: {numberToMoney(overBudget || 0, '0,0')}
                </div>
              : null}
            <div className={styles.budget}>
              Total budget: {numberToMoney(grant.budgetCap || 0, '0,0')}
            </div>
          </div>
        </div>
        {showRates ? <RatesWarning className={styles.rates} onClose={onCloseRatesMessage} /> : null}
      </div>
    </div>
  );
}

export default BudgetBar;
