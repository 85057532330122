import React, {ChangeEvent, FC, useCallback} from 'react';
import Field from 'components/field';
import { Button, Form, Checkbox, DatePicker, Input } from 'components/form';
import Typo from 'components/typo';
import styles from './forms.module.css';
import {Location} from './common';
import { Props } from './index';

const AlaskaHawaiiForm:FC<Props> = ({ data, travel, onSubmit, errors, isUpdate, loading, onChange }) => {
  const handleChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let newData = {
      startDate: null,
      endDate: null,
      numberOfSeasons: '',
    }
    if (event.target.checked) {
      newData = {//@ts-ignore
        startDate: travel.estimatedStartDate ? new Date(travel.estimatedStartDate) : null,//@ts-ignore
        endDate: travel.estimatedEndDate ? new Date(travel.estimatedEndDate) : null,//@ts-ignore
        numberOfSeasons: data.numberOfSeasons ? data.numberOfSeasons : ''
      }
    }
    onChange({
      isStartEndDateKnown: event.target.checked,
      ...newData
    });
  }, [travel, data, onChange]);

  return (
    <Form prompt onSubmit={onSubmit}>
      <Typo className={styles.text} type="div" >
        <>
          Please go to the website below to find per diem rates or amounts that would apply to this trip. Please note, if
          this trip involves a major switch in location, please use another separate per diem rate to account for the
          location change. This is important because the change in location might alter the location rates that are
          allowed to be used. <>Rates can be found here: <a href="https://www.defensetravel.dod.mil/site/perdiemCalc.cfm" target="_blank"
                                                            rel="noreferrer">www.defensetravel.dod.mil/site/perdiemCalc.cfm</a></>
        </>
      </Typo>

      <Typo className={styles.text} >On the Defense Travel website, you should see a box with a header titled
        "Per Diem Rates Query". Please pay attention to the box directly below on the same page that is titled, "Outside
        Conus, Non-Foreign Overseas and Foreign". Please enter the Country/State you are planning on traveling to and
        under the input prompt titled "Published" the most recent date of publication. If your travel will be to a
        military installation, please check the box that when checked will include military installation results. Press
        Calculate.</Typo>

      <Typo className={styles.text} >Once you have pressed Calculate a new tab should be loaded titled "OCONUS
        and OVERSEAS Per Diem" and a header titled Maximum Per Diem Rates Outside the Continental United States along
        with the Country/State you are searching for with the publication date in the white box just below it.</Typo>

      <Typo className={styles.text} >Please read the 5 bullet points in the notes box. These will help you know
        how to handle situations that may result from irregular budgeting needs. Don't worry! The prompts below should
        help you with easily getting to your final allowable amount with as little trouble as possible. We will take
        care of many unique cases to template as many possible requirements to take as much stress off of you as
        possible. Please locate the row of your locality. If you have two rows titled with the exact same locality,
        please look to column directly to the right of the name of your locality. You should see a date range. This date
        range should correspond with column title "Seasons (Beg - End)". In certain cases rates in locations will differ
        based on the time of year that you plan to travel to your location. Please use the row of your locality that
        best reflects what time of year you plan to travel to your location. Don't worry, the year your travel will end
        up taking place will be accounted for once all rates have been entered.</Typo>

      <Typo className={styles.text} >There should be a total of four columns with blue cells. Please use the
        information in the blue cells in the row of your locality to fill out the prompts below.</Typo>

      <Typo className={styles.text} >At the end of this section we will ask you which years of this grant this
        trip will apply to. You will have options to select all of the years to which this travel section will apply.
        Inflation will be calculated for you based on each of these years.</Typo>

      <Field full>
        <Input name="nameOfTrip" errors={errors.nameOfTrip} value={data.nameOfTrip}
               label="Name of travel" required placeholder="Enter a name of travel"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({nameOfTrip: event.target.value})}
        />
      </Field>

      <Location optional data={data} errors={errors} onChange={onChange} />

      <Field last>
        <Checkbox name="isStartEndDateKnown" label="I know the start and end dates"
                  value={data.isStartEndDateKnown} onChange={handleChangeCheckbox}
        />
      </Field>
      <Field withHelp>
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} name="startDate"
                    errors={errors.startDate} label="Start date" required={data.isStartEndDateKnown}
                    placeholder="Select a start date" value={data.startDate}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab”"
                    onChange={(date: Date | null) => onChange({startDate: date})}
        />
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} minDate={data.startDate || undefined} name="endDate"
                    errors={errors.endDate} label="End date"
                    placeholder="Select an end date" value={data.endDate} required={data.isStartEndDateKnown}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab”"
                    onChange={(date: Date | null) => onChange({endDate: date})}
        />
        <Input type="number" name="numberOfSeasons" label="Number of seasons" required={data.isStartEndDateKnown}
               placeholder="Enter number of seasons" className={styles.input}
               help={`The word "Seasons" here refers to when you look up a travel rate at the link given. They may provide two rates to represent what time of the year you will be visiting an area. If your travel dates extend from one season to another, by entering the second rate amounts separately by the reimbursement that they qualify for and sum both together to get a total amount that includes both rates for this trip.`}
               value={data.numberOfSeasons} errors={errors.numberOfSeasons} disabled={!data.isStartEndDateKnown}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfSeasons: event.target.value})}
        />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add'} travel costs`}
        </Button>
      </div>
    </Form>
  );
}

export default AlaskaHawaiiForm;
