import {ChangeEvent, FC, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useGrants from 'hooks/useGrants';
import useFees from 'hooks/useFees';
// helpers
import {getFeeItemFromGrant} from 'helpers/getFromGrant';
// types
import {
  FeeItemType,
  FeeItemYearType
} from 'store/grants/fees/types';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Textarea} from 'components/form';
// styles
import styles from '../fees.module.css';

type Props = {
  item: FeeItemYearType;
  fee: FeeItemType;
  grantId: string;
};

const totalCostAfterInflationHelpText = 'Inflation does not apply. You can change it in the Information tab';
const totalCostAfterInflationFirstYearHelpText = 'Inflation is not applied in Year 1 for any costs, but will be' +
  ' applied in subsequent years.'

const FeeItemYearForm: FC<Props> = ({item, fee, grantId}) => {
  const [data, onChangeData] = useState<FeeItemYearType>(item);
  const {
    isLoading, updateFee, previewFeeYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useFees({
    form_page_type: 'Date'
  });

  const onBlur = useCallback(() => {
    if (fee.id && data.value !== '') {
      previewFeeYear(grantId, fee.id, {
        value: data.value,
        serialNumber: data.serialNumber
      }, (response: any) => {
        onChangeData({
          ...data,
          compensationAfterInflation: response.compensationAfterInflation,
          compensationBeforeInflation: response.compensationBeforeInflation,
        });
      })
    }
  }, [previewFeeYear, fee.id, grantId, data]);

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const onSubmit = useCallback(() => {
    const newYears = fee.years.map((year: FeeItemYearType) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...data,
          value: Number(data.value),
          compensationBeforeInflation: Number(data.compensationBeforeInflation),
          compensationAfterInflation: Number(data.compensationAfterInflation),
        }
      }
      return year;
    });
    updateFee(grantId, fee.id, {
      ...fee,
      years: newYears
    })
  }, [data, item, fee, updateFee, grantId]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input
          name="value"
          label="Fee value"
          placeholder="Enter amount"
          className={styles.input}
          onBlur={onBlur}
          value={data.value}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({value: event.target.value})
          }
          type="number"
          money
        />
      </Field>
      <Field>
        <Input
          name="compensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          value={data.compensationBeforeInflation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({compensationBeforeInflation: event.target.value})
          }
          type="number"
          readOnly
          disabled
        />
        <Input
          name="compensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${data.serialNumber}`}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.compensationAfterInflation}
          help={
            !fee.isInflationApplied
              ? totalCostAfterInflationHelpText
              : data.serialNumber === 1
                ? totalCostAfterInflationFirstYearHelpText
                : ""
          }
          type="number"
          readOnly
          disabled
        />
      </Field>
      <Field last full>
        <Textarea
          name="costJustification"
          label="Justification for this cost"
          placeholder="Enter a description..."
          value={data.costJustification}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeData({costJustification: event.target.value})
          }
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={isLoading} name="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

const FeeItemYearPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getFeeItemFromGrant(grant, params.feeId);
  const year = item?.years[Number(params.yearId)];

  return (
    <GrantFormWrapper
      title="Fees"
      subtitle={`Fee ${index}. Year ${Number(params.yearId) + 1}`}
    >
      {year ? (
        <FeeItemYearForm
          grantId={grant.id}
          fee={item}
          key={`${grant.id}-fees-${params.serviceId}-${params.yearId}`}
          item={year}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
};

export default FeeItemYearPage;
