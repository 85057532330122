import {ChangeEvent, FC, useCallback, useState, useEffect} from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import { trackUserPageview } from 'helpers/trackUserActions';
import mobileLogo from 'images/logo.svg';
import Typo from 'components/typo';
import useAuth from 'hooks/useAuth';
import Head from 'containers/head';
import { Input, Form, Button, Checkbox } from 'components/form';
import useQuery from 'helpers/query';
import styles from './signin.module.css';
import Slideshow from './slideshow';
import AuthDisplayMessage from '../components/message';
import Social from '../components/social';
import {useMixPanel} from "../../../hooks/useMixPanel";

type FormData = {
  email: string;
  password: string;
}

type Field = Record<string, string>
const SignIn:FC = () => {
  const {pageViewed} = useMixPanel();
  const { login, loading, onSuccessOAuth, msLogin, msLoading, googleLoading, errors } = useAuth();
  const query:ParsedQuery = useQuery();
  const year = dayjs().get('year').toString();

  useEffect(() => {
    if (query.code) {
      onSuccessOAuth(String(query.code), query.client_info ? 'ms' : 'google');
    }

    //track mix panel Page Viewed event
    pageViewed("Sign In")
  }, [])

  const [ remember, onChangeRemember ] = useState<boolean>(true);
  const [ data, onChangeData ] = useState<FormData>({
    email: query.email as string || '',
    password: ''
  });

  const handleChangeData = useCallback((field: Field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data, onChangeData]);

  const onSubmit = useCallback(() => {
    login(data, remember);
  }, [login, data, remember]);

  useEffect(()=>{
    trackUserPageview("Sign In");
  }, []);

  return (
    <div className={styles.wrapper}>
      <Head title="Sign In"/>
      <img src={mobileLogo} alt="mobile_logo" className={styles.mobileLogo}/>
      <Slideshow/>
      <div className={cx(styles.side, styles.rightSide)}>
        <Form onSubmit={onSubmit} className={styles.form}>
          <Typo type="h3" className={styles.title}>Sign in</Typo>
          <Input required errors={errors.email} placeholder="Enter your email" value={data.email} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({email: event.target.value})} type="email" name="email" label="Email" />
          <Input required errors={errors.password} placeholder="Enter your password" value={data.password} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({password: event.target.value})} type="password" name="password" label="Password" />
          <div className={styles.checkboxAndLink}>
            <Checkbox value={remember} onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeRemember(event.target.checked)} className={styles.rememberCheckbox} name="remember" label="Remember me"/>
            <Link to="/forgot">Forgot password</Link>
          </div>
          <Button loading={loading} name="login" htmlType="submit">Sign in</Button>
          <div className={styles.divider}>OR</div>
          <Social msLogin={msLogin} msLoading={msLoading} googleLoading={googleLoading} />
          <Typo className={styles.signUpLinkWrap}>
            <>Don’t have an account? <Link to={`/sign-up?step=personal&email=${data.email || ''}`}>Sign up</Link></>
          </Typo>
        </Form>
        <div className={styles.message}>
          <AuthDisplayMessage />
        </div>
      </div>
      <Typo className={styles.footer}>
        {year} © Budget Mentor
      </Typo>
    </div>
  );
};

export default SignIn;
