import {useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AxiosResponse} from 'axios';
// hooks
import useUI from './useUI';
import useApi from './useApi';
// types
import {OtherExpenseType} from 'store/grants/otherDirectCosts/OtherExpenses/types';
// actions
import {
  addOtherExpenseAction
} from 'store/grants/otherDirectCosts/OtherExpenses/actions';
// components
import toast from 'components/toast';
import {confirm} from '../components/confirmation';
import useGrants from './useGrants';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";

type UseOtherExpensesType = {
  isLoading: boolean;
  createExpense: (grantId: string) => void;
  updateExpense: (grantId: string, unitId: string, data: OtherExpenseType) => void;
  deleteExpense: (grantId: string, id: string, redirect: boolean) => void;
  previewExpenseYear: (
    grantId: string,
    expenseId: string,
    data: any,
    cb: (data: any) => void
  ) => void;
  totals: string[][];
  getTotals: (grantId: string) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useOtherExpenses = (props: Props = {}): UseOtherExpensesType => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'other expenses';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();
  const history = useHistory();
  const dispatch = useDispatch();
  const {loader, onShowNavigationMessage} = useUI();
  const {getOneWithSide} = useGrants();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState<string[][]>([])

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const {
    addOtherExpense,
    updateOtherExpense,
    deleteOtherExpense,
    previewOtherExpenseYear,
    getOtherExpensesTotals
  } = useApi();

  const createExpense = useCallback((grantId: string) => {
    const emptyExpense = {name: null, isInflationApplied: true};
    setIsLoading(true);
    addOtherExpense(grantId, emptyExpense)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        setIsLoading(false);
        history.push(`/grants/${grantId}/otherDC/otherExpenses/${response.data.id}/edit`);
        setTimeout(() => {
          dispatch(addOtherExpenseAction(response.data));
        }, 300);
      })
      .catch(() => {
        setIsLoading(false);
      });
    trackFormSaveAttempted()
  }, [addOtherExpense, trackFormSaveAttempted, formSuccessSaveCallBack, history, dispatch])

  const updateExpense = useCallback((grantId: string, unitId: string, data: OtherExpenseType) => {
    setIsLoading(true);
    updateOtherExpense(grantId, unitId, data)
      .then(() => {
        formSuccessSaveCallBack()
        toast.success({
          title: 'The  expense details have been updated',
          message: 'Changes have been successfully saved',
        });
        getOneWithSide(grantId);
        setIsLoading(false);
        onShowNavigationMessage();
      })
      .catch(() => {
        setIsLoading(false);
      });
    trackFormSaveAttempted()
  }, [updateOtherExpense, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage])

  const deleteExpense = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete expense info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        deleteOtherExpense(grantId, id).then(() => {
          getOneWithSide(grantId, () => {
            if (redirect) history.push(`/grants/${grantId}/edit`);
            toast.success({
              title: 'The expense has been deleted',
              message: 'Changes have been successfully saved',
            });
          });
        });
      },
    });
  }, [deleteOtherExpense, history, getOneWithSide]);

  const previewExpenseYear = useCallback(
    (grantId: string, unitId: string, data: any, cb: (data: any) => void) => {
      loader.start();
      const q = new URLSearchParams(data).toString();
      previewOtherExpenseYear(grantId, unitId, `?${q}`)
        .then((response: AxiosResponse) => {
          loader.stop();
          cb(response.data);
        })
        .catch(() => {
          loader.stop();
        });
    },
    [previewOtherExpenseYear, loader],
  );

  const getTotals = useCallback((grantId: string) => {
    setIsLoading(true);
    getOtherExpensesTotals(grantId)
      .then((response: AxiosResponse) => {
        setTotals(response.data.totals)
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [getOtherExpensesTotals])
  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    isLoading,
    createExpense,
    updateExpense,
    deleteExpense,
    totals,
    previewExpenseYear,
    getTotals,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useOtherExpenses;
