import MaterialsSuppliesIndex from './materialsAndSupplies/MaterialsSuppliesIndex';
import ComputerServicesIndex from './computerServices';
import Publications from './publications';
import Other from './otherExpenses';
import Contracts from './contracts';
import Payments from './payments';
import SubawardsIndex from './subawards';
import ConsultantServices from './consultants';

const OtherDirectCostsIndex = () => {
  return (
    <>
      <SubawardsIndex/>
      <MaterialsSuppliesIndex/>
      <ComputerServicesIndex/>
      <Publications/>
      <Contracts/>
      <Payments/>
      <Other/>
      <ConsultantServices/>
    </>
  );
};

export default OtherDirectCostsIndex;
