import {ChangeEvent, FC, useState, useCallback, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';
import {Input, Button, Form} from 'components/form';
import { isEmpty } from 'lodash';
import Wrapper from 'pages/auth/components/wrapper';
import PasswordCheck from 'pages/auth/components/passwordCheck';
import checkPassword from 'helpers/password';
import useAuth from 'hooks/useAuth';
import useQuery from 'helpers/query';
import { ParsedQuery } from 'query-string';
import { localEmptyValidate } from 'helpers/errors';
import styles from './recovery.module.css';
import {useMixPanel} from "../../../hooks/useMixPanel";

const RecoveryPassword:FC = () => {
  const {pageViewed} = useMixPanel();
  const { errors, reset, resetSuccess, resendEmail, handleChangeErrors, loading } = useAuth();
  const params:Record<string, string> = useParams();
  const query:ParsedQuery = useQuery();
  const [ password, onChangePassword ] = useState<string>('');
  const [ passwordConfirmation, onChangePasswordConfirmation ] = useState<string>('');
  const [ focus, onChangeFocus ] = useState<boolean>(false);

  useEffect(() => {
    //track mix panel Page Viewed event
    pageViewed("Recovery Password")
  }, []);

  const onSubmit = useCallback(() => {
    const data = {
      password
    };

    if (!checkPassword(data.password).all) {
      handleChangeErrors({
        password: ['The password is not strong enough']
      });
      return;
    }

    if (params.hash) {
      reset({
        password,
        hash: params.hash
      });
    }
  }, [handleChangeErrors, password, reset, params]);

  const onResend = useCallback(() => {
    if (params.hash) {
      resendEmail({ hash: params.hash }, 'We sent a password reset link to your email');
    }
  }, [params, resendEmail]);

  const success = (
    <Wrapper
      title="Password reset"
      subtitle="Your password has been successfully reset. Click below to log in."
      icon="check"
      greenIcon
    >
      <Link to="/login">
        <Button full name="continue">Continue</Button>
      </Link>
    </Wrapper>
  );

  const resetInvalid = (
    <Wrapper
      title="Password reset"
      red
      subtitle="The password reset link is invalid"
      icon="link-broken-02"
      greenIcon
    >
      <Button full onClick={onResend} name="resend">Get active link</Button>
    </Wrapper>
  );

  const form = (
    <Wrapper
      title="Password Recovery"
      subtitle="Enter your new password to finish resetting your password."
      icon="key-01"
      backToLogin
    >
      <Form onSubmit={onSubmit} className={styles.wrapper}>
        <Input label="Password" required showHelp={focus} help={<PasswordCheck password={password}/>}
               onBlur={() => onChangeFocus(false)} onFocus={() => onChangeFocus(true)}
               value={password} errors={errors.password} fullError
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangePassword(event.target.value)}
               placeholder="Password" type="password" name="password"/>
        <Button full htmlType="submit" loading={loading} name="submit">Reset password</Button>
      </Form>
    </Wrapper>
  );

  if (query.invalid) return resetInvalid;

  return (resetSuccess ? success : form);
}

export default RecoveryPassword;
