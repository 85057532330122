import {ChangeEvent, FC, useCallback} from 'react';
import cx from 'classnames';
import {Input, Button, Form, Textarea} from 'components/form'
import styles from './personForm.module.css';
import {Errors} from '../../../helpers/errors';
import {FieldErrors} from "react-hook-form";

export type PostDoctoralData = {
  personsFringeBenefit: any;
  isAccountOwner: boolean;
  responsibilities: string;
  positionTitle: string;
  currentBaseSalary: any;
};


type Props = {
  data: PostDoctoralData;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  edit: boolean;
  onChange: (field: Record<string, any>) => void;
  trackError: (errors: FieldErrors) => void
  trackExit: () => void
}

const PostDoctorPersonForm: FC<Props> = ({data, edit, errors, loading, onSubmit, onChange, trackError, trackExit}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form prompt onSubmit={handleSubmit} className={styles.wrapper} trackError={trackError} trackExit={trackExit}>
      <div className={styles.field}>
        <Input name="positionTitle" auto label="Name for this Postdoc position" required
               placeholder="Enter position title"
               className={styles.fullInput} value={data.positionTitle} errors={errors.positionTitle}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({positionTitle: event.target.value})}
        />
      </div>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="currentBaseSalary" auto money label="Current Base Salary" required placeholder="Enter year salary"
               className={styles.input} value={data.currentBaseSalary} type="number" errors={errors.currentBaseSalary}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({currentBaseSalary: event.target.value})}
        />
        <Input name="personsFringeBenefit" label="Person's Fringe Benefit Rate" required
               placeholder="Enter fringe benefit rate"
               className={styles.input} value={data.personsFringeBenefit} type="number"
               errors={errors.personsFringeBenefit}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({personsFringeBenefit: event.target.value})}
        />
      </div>
      <Textarea name="responsibilities" value={data.responsibilities}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange({responsibilities: event.target.value})}
                label="Responsibilities" placeholder="Enter a description..." className={styles.fullInput}/>
      <div className={styles.footer}>
        <Button name="submit" loading={loading} htmlType="submit">{edit ? 'Save' : 'Add personnel'}</Button>
      </div>
    </Form>
  );
}

export default PostDoctorPersonForm;
