import {ChangeEvent, FC, useCallback, useState} from 'react';
import cx from 'classnames';
import {Input, Button, Form, Select} from 'components/form'
import styles from './personForm.module.css';
import {Errors} from 'helpers/errors';
import useEnums from 'hooks/useEnums';
import {FieldErrors} from "react-hook-form";

export type UGStudentData = {
  isAccountOwner: boolean;
  academicTerm?: string;
  numberOfAcademicTerms?: number;
  wageInAcademicTerm?: number;
  numberOfWeekAcademicTerms?: number;
  wageInSummer?: number;
  loadLimitDuringAcademicTerm?: number;
  loadLimitDuringSummer?: number;
};


type Props = {
  data: UGStudentData;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  edit: boolean;
  onChange: (field: Record<string, any>) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const UngraduatedStudentPersonForm: FC<Props> = ({
                                                   trackError,
                                                   trackExit, data, edit, errors, loading, onSubmit, onChange
                                                 }) => {

  const {annualSchedules} = useEnums();
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form prompt onSubmit={handleSubmit} className={styles.wrapper} trackError={trackError} trackExit={trackExit}>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Select name="academicTerm" label="Type of Academic Term" required placeholder="Select type academic term"
                className={styles.input} value={data.academicTerm} options={annualSchedules}
                errors={errors.academicTerm}
                onChange={(academicTerm: any) => onChange({academicTerm})}
        />
        <Input name="numberOfAcademicTerms" type="number"
               label="Number of Academic Terms per Year (Summers not included)" required
               placeholder="Enter number of academic terms"
               className={styles.input} value={data.numberOfAcademicTerms} errors={errors.numberOfAcademicTerms}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfAcademicTerms: event.target.value})}/>
      </div>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="numberOfWeekAcademicTerms" type="number" label="Number of Weeks/Academic Term" required
               placeholder="Enter number of week / academic terms"
               className={styles.input} value={data.numberOfWeekAcademicTerms} errors={errors.numberOfWeekAcademicTerms}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfWeekAcademicTerms: event.target.value})}/>
      </div>
      <div className={cx(styles.field)}>
        <Input name="wageInAcademicTerm" money type="number"
               label="Department's current hourly wage set for Undergraduate Students in the academic year" required
               placeholder="Enter department's current hourly wage in the academic year"
               className={styles.fullInput} value={data.wageInAcademicTerm} errors={errors.wageInAcademicTerm}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({wageInAcademicTerm: event.target.value})}
        />
      </div>
      <div className={cx(styles.field)}>
        <Input name="wageInSummer" type="number" money
               label="Department's current hourly wage set for Undergraduate Students during the summer" required
               placeholder="Enter department's current hourly wage during the summer"
               className={styles.fullInput} value={data.wageInSummer} errors={errors.wageInSummer}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({wageInSummer: event.target.value})}
        />
      </div>
      <div className={styles.field}>
        <Input name="loadLimitDuringAcademicTerm" type="number"
               label="What is the maximum number of hours that undergraduate students can work during academic terms?"
               placeholder="Enter max number of hours that undergraduate students can work during academic terms"
               help="Please enter a value if your department has a maximum number of hours that undergraduate students can work during academic terms. Using this value, we will validate the data."
               className={styles.fullInput} value={data.loadLimitDuringAcademicTerm}
               errors={errors.loadLimitDuringAcademicTerm}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({loadLimitDuringAcademicTerm: event.target.value})}
        />
      </div>
      <div className={cx(styles.field, styles.withoutBorder)}>
        <Input name="loadLimitDuringSummer" type="number"
               help="Please enter a value if your department has a maximum number of hours that undergraduate students can work during the summer. Using this value, we will validate the data."
               label="What is the maximum number of hours that undergraduate students can work during summer?"
               placeholder="Enter max number of hours that undergraduate students can work during summer"
               className={styles.fullInput} value={data.loadLimitDuringSummer} errors={errors.loadLimitDuringSummer}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({loadLimitDuringSummer: event.target.value})}
        />
      </div>
      <div className={styles.footer}>
        <Button name="submit" loading={loading} htmlType="submit">{edit ? 'Save' : 'Add personnel'}</Button>
      </div>
    </Form>
  );
}

export default UngraduatedStudentPersonForm;
