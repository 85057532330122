import {FC, useCallback, useMemo} from 'react';
import CreateBtn from 'pages/grant/paper/card/create';
import Folder, { FolderProps } from 'pages/grant/paper/card/folder';
import Typo from 'components/typo';
import useUrl from 'hooks/useUrl';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import Event from './event';
import styles from './participant.module.css';
import useParticipants from 'hooks/useParticipants';
import {FullGrant, GrantEvent, SidebarTotals} from 'store/grants/types';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}
const ParticipantSupport:FC<Props> = ({ side, totals }) => {
  const { addEvent, deleteEvent, loading } = useParticipants();
  const url = useUrl();

  const list = useMemo(() => {
    return side.events.map((item: GrantEvent, idx: number) => {
      return {
        item: {
          label: item.name || 'Untitled event',
          type: 'events',
          id: item.id,
          open: url.subCategory === item.id
        },
        children: <Event item={item} idx={idx + 1} grant={side}/>
      }
    })
  }, [side, url]);


  const handleDelete = useCallback((id?: string) => {
    if (id) deleteEvent(side.id, id, url.subCategory === id);
  }, [deleteEvent, side, url]);

  return (
    <>
      <div>
        {
          list.length > 0
            ?
            <>
              {list.map((folder: FolderProps, idx: number) => (
                <Folder grantId={side.id} key={idx} onDelete={() => handleDelete(folder.item.id)} item={folder.item} type="subCategory">{folder.children}</Folder>
              ))}
              <TotalAnchor total={totals.participantSupportTotalAmount} to={`/grants/${side.id}/events/totals`}
                      active={url.category === 'events' && url.subCategory === 'totals'}>
                Totals. All events
              </TotalAnchor>
            </>
            :
            <Typo className={styles.placeholder} size={14} type="div">No events have been created yet</Typo>
        }
        <CreateBtn loading={loading} onClick={() => addEvent(side.id)}
                   name="add-grant-participant" side={side}
                   tip="To add an event, you must add grant general information"
        >
          Add an event
        </CreateBtn>
      </div>
    </>
  )
}

export default ParticipantSupport;
