import {FC, useEffect} from 'react';
import Table from 'components/table';
import cx from 'classnames';
import Spinner from 'components/spinner';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useUI from 'hooks/useUI';
import styles from './consultants.module.css';
import {numberToMoney} from 'helpers/numbers';
import {getTotalsTableData} from 'helpers/table';
import useConsultantServices from 'hooks/useConsultantServices';

const ConsultantsTotals:FC = () => {
  const params:Record<string, string> = useParams();
  const { getTotals, isLoading, totals } = useConsultantServices();
  const { sidebarWidth } = useUI();
  //
  useEffect(() => {
    getTotals(params.id);
  }, []);

  const renderCell = (cell: string, idx: number, isGrayData: boolean) => {
    if (idx < 1) return cell;
    return <span className={cx({[styles.gray]: isGrayData})}>{numberToMoney(cell)}</span>;
  };

  const {data, headers} = getTotalsTableData(totals, renderCell, ['Consultant services', 'Totals']);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Consultant services. Totals">
      <div className={styles.wrapper}>
        <Table title="Consultant services" headers={headers} data={data}/>
        {isLoading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full/> : null}
      </div>
    </GrantFormWrapper>
  )
}

export default ConsultantsTotals;
