import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import useApi from './useApi';
import {queryToString} from 'helpers/query';
import {
  setPrimaryInvestigatorToStatGrant,
} from 'store/grants/actions';
import {AxiosError, AxiosResponse} from 'axios';
import parseErrors, {Errors} from 'helpers/errors';
import * as T from './requestTypes';
import {isNumber, omit} from 'lodash';
import toast from 'components/toast';
import {ListItem} from 'helpers/utils';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {SeniorPersonal} from 'store/grants/types';
import {Person} from './requestTypes';
import {personalTypes} from 'const';
import useUI from './useUI';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";
import {useParams} from "react-router-dom";

type PreviewData = {
  isAccountOwner: boolean,
  salaryStartDate?: string;
  salaryEndDate?: string;
  salary: number | string;
  monthCompensatedByOrganization?: number | string;
}

export type SeniorPreview = {
  nsfCompensation: T.PersonalNSFCompensation[];
  monthCompensatedByOrganization: number;
  salaryPaidPerMonthByOrganization: number;
  percentOfSalary: number;
}
type iUsePersonnel = {
  createSeniorPerson: (id: string, type: string) => void;
  createAcademicResearchPerson: (id: string, type: string) => void;
  createPostDoctoralPerson: (id: string, type: string) => void;
  createGraduatedStudent: (id: string, type: string) => void;
  createUndergraduateStudent: (id: string, type: string) => void;
  createOtherPerson: (id: string, type: string) => void;
  getSeniorPersonnelPreview: (grantId: string, data: PreviewData, cb: (data: SeniorPreview) => void) => void;
  getAcademicResearchPersonnelPreview: (grantId: string, data: PreviewData, cb: (data: SeniorPreview) => void) => void;
  editSeniorPerson: (id: string, personId: string, data: T.Person) => void;
  editSeniorPersonNSFCompensation: (id: string, personId: string, data: T.PersonNSFCompensationUpdate) => void;
  editAcademicResearchPerson: (id: string, personId: string, data: T.Person) => void;
  editAcademicResearchPersonNSFCompensation: (id: string, personId: string, data: T.PersonNSFCompensationUpdate) => void;
  calculatePercentCompensation: (grantId: string, type: string, data: T.PercentCompensation, cb: (data: T.PercentCompensationResponse) => void) => void;
  calculateMonthCompensation: (grantId: string, type: string, data: T.MonthCompensation, cb: (data: T.MonthCompensationResponse) => void) => void;
  calculateGraduatedStudentByTerm: (grantId: string, data: any, cb: (data: T.GraduatedStudentCalcByTerm) => void) => void;
  calculateGraduatedStudentByAnnual: (grantId: string, data: any, cb: (data: T.GraduatedStudentCalcByAnnual) => void) => void;
  calculateUnderGraduatedStudentByAnnual: (grantId: string, data: T.UnderGraduatedStudentDataForCalcByAnnual, cb: (data: T.UnderGraduatedStudentDataForCalcAcademTermResponse) => void) => void;
  calculateUnderGraduatedStudentSummer: (grantId: string, data: T.UnderGraduatedStudentDataForCalcSummer, cb: (data: T.UnderGraduatedStudentDataForCalcSummerResponse) => void) => void;
  getTotals: (grantId: string, type: string) => void;
  updateUnderGraduateNsfCompensation: (grantId: string, data: T.UnderGraduatedStudentNSF, owner: boolean, type: 'academTerm' | 'summer') => void;
  deletePerson: (grantId: string, type: string, person: SeniorPersonal, redirect?: boolean) => void;
  errors: Errors;
  totals: any;
  updateGraduatedStudentAnnualInfo: (grantId: string, data: any, type: string, owner: boolean) => void;
  updatePostDoctoralPersonnel: (id: string, personId: string, data: T.CreatePostDoctorPersonnelData) => void;
  calculatePostDocNSF: (id: string, personId: string, data: T.CalculatePostDocNSF, cb: (data: T.CalculatePostDocNSFResponse) => void) => void;
  loading: boolean;
  onChangeErrors: (errors: Errors) => void;
  updatePostDocNsf: (id: string, personId: string, data: T.PostDocNsf, nsfIndex: number) => void;
  deleteUndergraduatedStudents: (id: string, owner: boolean, redirect: boolean) => void;
  deleteGraduatedStudents: (id: string, owner: boolean, redirect: boolean) => void;
  addOrUpdateStudent: (grantId: string, type: string, data: T.GraduatedStudent, update?: boolean) => void;
  addOrUpdateUndergraduatedStudent: (grantId: string, type: string, data: T.UnderGraduatedStudent, update?: boolean) => void;
  addOrEditOtherPersonnel: (grantId: string, type: string, data: T.OtherPerson, personId?: string) => void;
  calculateOtherPersonnel: (grantId: string, type: string, data: T.OtherPersonCalculationRequest, cb: (data: T.OtherPersonCalculationResponse) => void) => void;
  updateOtherNSF: (grantId: string, personId: string, type: string, data: any) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

export type Total = {
  fullName: string;
  years: ListItem[];
  total: number;
  totals: number;
}

export type Totals = {
  salaries: Total[];
  fringeBenefits: Total[];
  salariesAndFringeBenefits: Total[];
  stipends: Total[];
  fringeBenefitsOnStipend: Total[];
  tuition: Total[];
  fringeBenefitsOnTuition: Total[];
  totalAmount: Total[];
  stipendsDuringAcademicTerms: Total[];
  stipendsDuringSummer: Total[];
  academicYear: Total[];
  academicYearAndSummer: Total[];
  summer: Total[];
}

type Props = {
  form_page_type?: string
}

const usePersonnel = (props: Props = {}): iUsePersonnel => {
  const form_page_type = props.form_page_type || '';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'personnel',
      params.personnelType,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id, params.personnelType])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'personnel',
      params.personnelType,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id, params.personnelType])

  const defaultTotals = useMemo(() => ({
    salaries: [],
    fringeBenefits: [],
    salariesAndFringeBenefits: [],
    stipends: [],
    fringeBenefitsOnStipend: [],
    tuition: [],
    stipendsDuringAcademicTerms: [],
    stipendsDuringSummer: [],
    fringeBenefitsOnTuition: [],
    totalAmount: [],
    academicYear: [],
    academicYearAndSummer: [],
    summer: [],
  }), []);

  const api = useApi();
  const {getOneWithSide} = useGrants();
  const dispatch = useDispatch();
  const history = useHistory();
  const {onShowNavigationMessage} = useUI();

  const [errors, onChangeErrors] = useState<Errors>({});
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [totals, onChangeTotals] = useState<Totals>(defaultTotals);

  const createSeniorPerson = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptySeniorPerson = {};
    api.addSeniorPerson(grantId, (emptySeniorPerson as unknown as Person))
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/personnel/${type}/${response.data.id}/edit`);
        getOneWithSide(grantId);
        if (response.data.role === 'pi') {
          dispatch(setPrimaryInvestigatorToStatGrant(response.data));
        }
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
        //@ts-ignore
        if (error?.response?.data?.message) {
          toast.error({
            title: 'Error', //@ts-ignore
            message: error?.response?.data?.message
          });
        }
      });
  }, [api, getOneWithSide, dispatch, history]);

  const createAcademicResearchPerson = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyAcademicResearchPerson = {};
    api.addAcademicResearchPerson(grantId, (emptyAcademicResearchPerson as unknown as Person))
      .then((response: AxiosResponse) => {
        history.push(`/grants/${grantId}/personnel/${type}/${response.data.id}/edit`);
        getOneWithSide(grantId);
        onChangeLoading(false);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, history, getOneWithSide])

  const createPostDoctoralPerson = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyPostDoctoralPerson = {};
    api.addPostDoctoralPersonnel(grantId, emptyPostDoctoralPerson)
      .then((response: AxiosResponse) => {
        history.push(`/grants/${grantId}/personnel/${type}/${response.data.id}/edit`);
        getOneWithSide(grantId);
        onChangeLoading(false);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, history, getOneWithSide]);

  const createGraduatedStudent = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyGraduatedStudent = {};
    api.addStudent(grantId, type, emptyGraduatedStudent)
      .then(() => {
        history.push(`/grants/${grantId}/personnel/graduate-students/edit`);
        getOneWithSide(grantId);
        onChangeLoading(false);
        toast.success({
          title: 'Added information about the person',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, history, getOneWithSide]);

  const createUndergraduateStudent = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyUndergraduateStudent = {};
    api.addStudent(grantId, type, emptyUndergraduateStudent)
      .then(() => {
        history.push(`/grants/${grantId}/personnel/${type}/edit`);
        getOneWithSide(grantId);
        onChangeLoading(false);
        toast.success({
          title: 'Added information about the person',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, history, getOneWithSide])

  const createOtherPerson = useCallback((grantId: string, type: string) => {
    onChangeErrors({});
    onChangeLoading(true);

    const emptyOtherPerson = {};
    api.addOtherPersonnel(grantId, type, emptyOtherPerson)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        history.push(`/grants/${grantId}/personnel/${type}/${response.data.id}/edit`)
        getOneWithSide(grantId);
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, history, getOneWithSide]);

  const editSeniorPerson = useCallback((grantId: string, personId: string, data: T.Person) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.editSeniorPerson(grantId, personId, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        getOneWithSide(grantId);
        if (response.data.role === 'pi') {
          dispatch(setPrimaryInvestigatorToStatGrant({
            grantId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          }));
        }
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Information about the person has been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }//@ts-ignore
        if (error?.response?.data?.message) {
          toast.error({
            title: 'Error', //@ts-ignore
            message: error?.response?.data?.message
          });
        }
      });
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage, dispatch]);

  const editSeniorPersonNSFCompensation = useCallback((grantId: string, personId: string, data: T.PersonNSFCompensationUpdate) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.editSeniorPerson(grantId, personId, data)
      .then(() => {
        getOneWithSide(grantId);
        onChangeLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'Information about compensation to a person has been updated',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [trackFormSaveAttempted, formSuccessSaveCallBack, api, onShowNavigationMessage, getOneWithSide]);

  const editAcademicResearchPerson = useCallback((grantId: string, personId: string, data: T.Person) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.editAcademicResearchPerson(grantId, personId, data)
      .then((response: AxiosResponse) => {
        getOneWithSide(grantId);
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Information about the person has been updated',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, trackFormSaveAttempted, api, onShowNavigationMessage, getOneWithSide]);

  const editAcademicResearchPersonNSFCompensation = useCallback((grantId: string, personId: string, data: T.PersonNSFCompensationUpdate) => {
    onChangeLoading(true);
    onChangeErrors({});
    api.editAcademicResearchPerson(grantId, personId, data)
      .then(() => {
        getOneWithSide(grantId);
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Information about compensation to a person has been updated',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, trackFormSaveAttempted, api, onShowNavigationMessage, getOneWithSide]);

  const getSeniorPersonnelPreview = useCallback((grantId: string, data: PreviewData, cb: (data: SeniorPreview) => void) => {
    const q = queryToString(data);
    api.seniorPersonPreview(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, [api]);

  const getAcademicResearchPersonnelPreview = useCallback((grantId: string, data: PreviewData, cb: (data: SeniorPreview) => void) => {
    const q = queryToString(data);
    api.seniorPersonPreview(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, [api]);

  const calculatePercentCompensation = useCallback((grantId: string, type: string, data: T.PercentCompensation, cb: (data: T.PercentCompensationResponse) => void) => {
    const personType = type === 'senior-personal' ? 'senior-personal' : 'academic-research-personal';
    const q = queryToString(data);
    onChangeErrors({});
    api.calculatePercentCompensation(grantId, personType, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const calculateMonthCompensation = useCallback((grantId: string, type: string, data: T.MonthCompensation, cb: (data: T.MonthCompensationResponse) => void) => {
    const personType = type === 'senior-personal' ? 'senior-personal' : 'academic-research-personal';
    if (isNumber(data.availableMonths) && data.monthsFromCurrentGrant && data.monthsFromCurrentGrant > data.availableMonths) {
      onChangeErrors({
        monthsFromCurrentGrant: [
          'You can not request more months than specified in the "Number of available months' +
          ' to receive government compensation". To continue, change the number of months or use the percentage method to calculate',
        ],
      });
      return;
    }
    onChangeErrors({});
    const q = queryToString(omit(data, 'monthsAvailableAfterRequest'));
    api.calculateMonthCompensation(grantId, personType, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const getTotals = useCallback((id: string, type: string) => {
    onChangeLoading(true);
    api.getPersonalTotals(id, type)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        onChangeTotals({
          salaries: response.data.salaries ?? [],
          fringeBenefits: response.data.fringeBenefits ?? [],
          salariesAndFringeBenefits: response.data.salariesAndFringeBenefits ?? [],
          stipends: response.data.stipends ?? [],
          fringeBenefitsOnStipend: response.data.fringeBenefitsOnStipend ?? [],
          tuition: response.data.tuition ?? [],
          fringeBenefitsOnTuition: response.data.fringeBenefitsOnTuition ?? [],
          totalAmount: response.data.totalAmount ?? [],
          stipendsDuringAcademicTerms: response.data.stipendsDuringAcademicTerms ?? [],
          stipendsDuringSummer: response.data.stipendsDuringSummer ?? [],
          academicYear: response.data.academicYear ?? [],
          academicYearAndSummer: response.data.academicYearAndSummer ?? [],
          summer: response.data.summer ?? [],
        });
      })
      .catch(() => {
        onChangeTotals(defaultTotals);
        onChangeLoading(false);
      })
  }, [defaultTotals, api]);

  const deletePerson = useCallback((grantId: string, type: string, person: SeniorPersonal, redirect?: boolean) => {
    const positionType = ['civil-service', 'post-doc', 'union', 'temp-n-casual', 'other-personnel'];
    const titleElement = positionType.includes(type) ? 'position' : 'person';
    confirm({
      title: 'Delete personnel info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deletePerson(grantId, type, person.id)
          .then(() => {
            getOneWithSide(grantId, () => {
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
            toast.success({
              title: `The ${titleElement} has been deleted`,
              message: 'Changes have been successfully saved'
            });
          });
      }
    })
  }, [api, getOneWithSide, history]);

  const updatePostDoctoralPersonnel = useCallback((id: string, personId: string, data: T.CreatePostDoctorPersonnelData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updatePostDoctoralPersonnel(id, personId, data)
      .then(() => {
        onChangeLoading(false);
        getOneWithSide(id);
        onShowNavigationMessage();
        toast.success({
          title: 'Information about the position has been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });

    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, getOneWithSide, onShowNavigationMessage]);

  const calculatePostDocNSF = useCallback((id: string, personId: string, data: T.CalculatePostDocNSF, cb: (data: T.CalculatePostDocNSFResponse) => void) => {
    const q = queryToString(data);
    api.calculatePostDoc(id, personId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const updatePostDocNsf = useCallback((id: string, personId: string, data: T.PostDocNsf, nsfIndex: number) => {
    onChangeLoading(true);
    onChangeErrors({});
    api.updatePostDocNsf(id, personId, data)
      .then(() => {
        onChangeLoading(false);
        getOneWithSide(id);
        onShowNavigationMessage();
        toast.success({
          title: 'Information about compensation has been updated',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack?.()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [trackFormSaveAttempted, formSuccessSaveCallBack, api, onShowNavigationMessage, getOneWithSide]);

  const addOrUpdateStudent = useCallback((grantId: string, type: string, data: T.GraduatedStudent, update?: boolean) => {
    onChangeErrors({});
    onChangeLoading(true);
    const func = update ? api.updateStudent : api.addStudent;
    func(grantId, type, data)
      .then(() => {
        if (!update) history.push(`/grants/${grantId}/personnel/${type}/edit`);
        getOneWithSide(grantId);
        if (update) onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Information about Graduate Student has been added',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack();
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, trackFormSaveAttempted, api, history, onShowNavigationMessage, getOneWithSide]);

  const addOrUpdateUndergraduatedStudent = useCallback((grantId: string, type: string, data: T.UnderGraduatedStudent, update?: boolean) => {
    onChangeErrors({});
    onChangeLoading(true);
    const func = update ? api.updateStudent : api.addStudent;
    func(grantId, type, data)
      .then(() => {
        if (!update) history.push(`/grants/${grantId}/personnel/${type}/edit`);
        getOneWithSide(grantId);
        if (update) onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'Information about Undergraduate Students has been added',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });

    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, trackFormSaveAttempted, api, history, onShowNavigationMessage, getOneWithSide]);

  const calculateGraduatedStudentByTerm = useCallback((grantId: string, data: any, cb: (data: T.GraduatedStudentCalcByTerm) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.calculateGraduatedStudentWithTerm(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const calculateUnderGraduatedStudentByAnnual = useCallback((grantId: string, data: T.UnderGraduatedStudentDataForCalcByAnnual, cb: (data: T.UnderGraduatedStudentDataForCalcAcademTermResponse) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.calculateUnderGraduatedStudentWithAnnual(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const calculateUnderGraduatedStudentSummer = useCallback((grantId: string, data: T.UnderGraduatedStudentDataForCalcSummer, cb: (data: T.UnderGraduatedStudentDataForCalcSummerResponse) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.calculateUnderGraduatedStudentSummer(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const calculateGraduatedStudentByAnnual = useCallback((grantId: string, data: any, cb: (data: T.GraduatedStudentCalcByAnnual) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.calculateGraduatedStudentWithAnnual(grantId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api]);

  const updateUnderGraduateNsfCompensation = useCallback((grantId: string, data: T.UnderGraduatedStudentNSF, owner: boolean, type: 'academTerm' | 'summer') => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateUnderGraduateNsfCompensation(grantId, data)
      .then(() => {
        getOneWithSide(grantId);
        onChangeLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'Information about compensation has been updated',
          message: 'Changes have been successfully saved'
        });
        formSuccessSaveCallBack()
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, getOneWithSide, onShowNavigationMessage, formSuccessSaveCallBack]);

  const updateGraduatedStudentAnnualInfo = useCallback((grantId: string, data: any, type: string, owner: boolean) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateAnnualInfoGraduatedStudent(grantId, data)
      .then(() => {
        onChangeLoading(false);
        getOneWithSide(grantId);
        onShowNavigationMessage();
        toast.success({
          title: 'Added information about the person',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, onShowNavigationMessage, getOneWithSide]);

  const deleteUndergraduatedStudents = useCallback((id: string, owner: boolean, redirect: boolean) => {
    confirm({
      title: 'Delete personnel info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteUnderGraduatedStudents(id)
          .then(() => {
            getOneWithSide(id, () => {
              if (redirect) history.push(`/grants/${id}/personnel/${personalTypes.undergraduateStudents}/create`);
            });
            toast.success({
              title: 'The Undergraduate Student information has been deleted',
              message: 'Changes have been successfully saved'
            });
          });
      }
    });
  }, [api, getOneWithSide, history]);

  const deleteGraduatedStudents = useCallback((id: string, owner: boolean, redirect: boolean) => {
    confirm({
      title: 'Delete personnel info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteGraduatedStudents(id)
          .then(() => {
            getOneWithSide(id, () => {
              if (redirect) history.push(`/grants/${id}/personnel/${personalTypes.graduateStudents}/create`);
            });
            toast.success({
              title: 'The Graduate Student information has been deleted',
              message: 'Changes have been successfully saved'
            });
          });
      }
    });
  }, [api, getOneWithSide, history]);

  const addOrEditOtherPersonnel = useCallback((grantId: string, type: string, data: T.OtherPerson, personId?: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const func = personId ? api.updateOtherPersonnel(grantId, type, personId, data) : api.addOtherPersonnel(grantId, type, data);
    func.then((response: AxiosResponse) => {
      formSuccessSaveCallBack()
      onChangeLoading(false);
      if (personId) onShowNavigationMessage();
      if (!personId) history.push(`/grants/${grantId}/personnel/${type}/${response.data.id}/edit`);
      getOneWithSide(grantId, () => {
        if (personId) {
          toast.success({
            title: 'Information about the position has been updated',
            message: 'Changes have been successfully saved'
          });
        }
      });
    })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });

    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, trackFormSaveAttempted, api, history, onShowNavigationMessage, getOneWithSide]);

  const calculateOtherPersonnel = useCallback((grantId: string, type: string, data: T.OtherPersonCalculationRequest, cb: (data: T.OtherPersonCalculationResponse) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.getOtherCalculate(grantId, type, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      });
  }, [api]);

  const updateOtherNSF = useCallback((grantId: string, personId: string, type: string, data: T.OtherNSFRequestData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateOtherNSF(grantId, type, personId, data)
      .then(() => {
        onChangeLoading(false);
        onShowNavigationMessage();
        getOneWithSide(grantId);
        toast.success({
          title: 'Information about compensation has been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      });
  }, [api, onShowNavigationMessage, getOneWithSide]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'personnel',
      params.personnelType,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id, params.personnelType])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'personnel',
      params.personnelType,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id, params.personnelType])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'personnel',
      params.personnelType,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id, params.personnelType])

  return {
    createSeniorPerson,
    createAcademicResearchPerson,
    createGraduatedStudent,
    createUndergraduateStudent,
    createPostDoctoralPerson,
    createOtherPerson,
    errors,
    addOrUpdateStudent,
    updateOtherNSF,
    calculateOtherPersonnel,
    loading,
    updateGraduatedStudentAnnualInfo,
    calculateGraduatedStudentByAnnual,
    deletePerson,
    calculatePostDocNSF,
    calculateGraduatedStudentByTerm,
    updatePostDoctoralPersonnel,
    calculateUnderGraduatedStudentByAnnual,
    updatePostDocNsf,
    getTotals,
    editSeniorPersonNSFCompensation,
    calculateUnderGraduatedStudentSummer,
    addOrEditOtherPersonnel,
    editAcademicResearchPersonNSFCompensation,
    updateUnderGraduateNsfCompensation,
    calculatePercentCompensation,
    onChangeErrors,
    deleteUndergraduatedStudents,
    deleteGraduatedStudents,
    addOrUpdateUndergraduatedStudent,
    totals,
    getAcademicResearchPersonnelPreview,
    editAcademicResearchPerson,
    calculateMonthCompensation,
    getSeniorPersonnelPreview,
    editSeniorPerson,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default usePersonnel;
