import React, { FC } from 'react';
import Icon from 'components/icon';
import Typo from 'components/typo';
import { Button } from 'components/form';
import useGrants from 'hooks/useGrants';
import styles from './nogrants.module.css';

const NoGrants:FC = () => {
  const { create } = useGrants();

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Icon icon="edit-04" size={20} />
      </div>
      <Typo type="h5" semi className={styles.title}>No grants yet</Typo>
      <Typo type="p" className={styles.subtitle}>No grants have been created in your account yet.</Typo>
      <Typo type="p" className={styles.subtitle}>Please proceed to create a grant</Typo>
      <Button size="lg" onClick={create} type="primary" className={styles.btn} name="nogrants-add" preIcon="plus">Create a New Budget</Button>
    </div>
  )
}

export default NoGrants;
