import {ChangeEvent, FC, useCallback, useMemo} from 'react';
import cx from 'classnames';
import { Input, Checkbox } from 'components/form';
import FormField from 'components/form/field';
import styles from './years.module.css';
import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import helps from './helps';

type Props = {
  data: T.TransportationMethodYear[];
  totalAmountForTrip: any;
  onBlur?: () => void;
  participant?: boolean;
  expense?: boolean;
  errors?: Errors;
  label?: string;
  errorsPrefix?: string;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
}


const TransportationYears:FC<Props> = ({ data, expense, participant, label, errorsPrefix, totalAmountForTrip, errors = {}, onBlur, onChange }) => {
  const handleChangeInput = useCallback((field: Record<string, any>, idx: number, preview?: boolean) => {
    const newData = data.map((item: T.TransportationMethodYear, index: number) => {
      if (index === idx) {
        return {
        ...item,
        ...field,
        }
      }
      return item;
    });
    onChange({ years: newData }, preview);
  }, [data, onChange]);

  const defaultLabel = label ? label : 'Please check the year(s) of this grant proposal that this trip will take place in';

  const numberOfTripsLabel = useMemo(() => {
    if (expense) return 'Expense frequency per year';
    if (participant) return 'Number of trips of this type per year';
    return 'Number of trips per year';
  }, [expense, participant]);

  const amountLabel = useCallback((year: number | string) => {
    if (participant) return `Amount for year ${year} before inflation`;
    return `Amount for year ${year} with inflation applied`
  }, [participant])

  return (
    <FormField name="transportation-method-years-label" label={defaultLabel}
               help={helps.years} helpInLabel
    >
      <div className={styles.lines}>
        {data.map((item: T.TransportationMethodYear, idx: number) => (
          <div className={styles.line} key={idx}>
            <Checkbox className={styles.checkbox}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeInput({isEnabled: event.target.checked}, idx, true)}
                      value={item.isEnabled} name={`years[${idx}].year`} label={`Year ${idx + 1}`} />
            <Input className={styles.input} name={`years[${idx}].numberOfOccurrence`}
                   label={numberOfTripsLabel} type="number"
                   errors={errors[`${errorsPrefix ? errorsPrefix + '.' : ''}years[${idx}].numberOfOccurrence`]}
                   value={data[idx]?.numberOfOccurrence} auto
                   onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeInput({numberOfOccurrence: event.target.valueAsNumber}, idx)}
                   disabled={!item.isEnabled} onBlur={onBlur} required={item.isEnabled}
                   placeholder="Enter number of trips"
            />
            {
              participant
                ?
                  <Input className={styles.input} name={`years[${idx}].numberOfParticipants`}
                         label="Number of participants" type="number"
                         errors={errors[`${errorsPrefix ? errorsPrefix + '.' : ''}years[${idx}].numberOfParticipants`]}
                         value={data[idx]?.numberOfParticipants} auto
                         onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           handleChangeInput({numberOfParticipants: event.target.value}, idx)
                         }}
                         disabled={!item.isEnabled} onBlur={onBlur} required={item.isEnabled}
                         placeholder="Enter number of travelers"
                  />
                : null
            }
            <Input className={cx(styles.input, styles.longInput)} name={`years[${idx}].amountWithInflation`}
                   label={amountLabel(idx + 1)}
                   value={item.isEnabled ? (participant ? data[idx]?.compensationBeforeInflation : data[idx]?.compensationAfterInflation) : ''} money
                   disabled readOnly placeholder={participant ? 'Amount of compensation before inflation' : 'Amount of compensation after inflation'}
            />
          </div>
        ))}
        <div className={styles.line}>
          <div className={styles.checkbox}/>
          <div className={styles.input}/>
          {participant ? <div className={styles.input}/> : null}
          <Input className={cx(styles.input, styles.longInput)} name="totalAmountForTrip"
                 label={participant ? 'Total Amount' : 'Total Amount for trip'}
                 value={totalAmountForTrip} type="number"
                 placeholder="Total amount" money disabled readOnly
          />
        </div>
      </div>
    </FormField>
  );
}

export default TransportationYears;
