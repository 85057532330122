import { FC } from 'react';
import Typo from 'components/typo';
import styles from './cs.module.css'
import GrantFormWrapper from 'pages/grant/common/wrapper';

const ComputerServicesInfo:FC = () => {
  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Contracts">
      <div>
        <Typo className={styles.mb16}>How is this different from a subaward? The NSF defines a contractor as an entity which a contract is signed with for the purpose of obtaining goods and services... and creates a procurement relationship with the contractor.</Typo>
        <Typo className={styles.mb16}>What is a procurement relationship? The NSF defines characteristics indicative of a procurement relationship between the non-Federal entity and a contractor are when the contractor:</Typo>
        <ul className={styles.list}>
          <li><Typo className={styles.li}>provides the goods and services within normal business operations;</Typo></li>
          <li><Typo className={styles.li}>provides similar goods or services to many different purchasers;</Typo></li>
          <li><Typo className={styles.li}>normally operates in a competitive environment;</Typo></li>
          <li><Typo className={styles.li}>provides goods or services that are ancillary to the operation of the Federal program;</Typo></li>
          <li><Typo className={styles.li}>is not subject to compliance requirements of the Federal program as a result of the agreement, though similar requirements may apply for other reasons.</Typo></li>
        </ul>
        <Typo className={styles.mb16}>The NSF specifically notes that you need to use your use of judgment. The substance of the relationship is more important than the form of the agreement. All of the characteristics listed above about defining a contractor may not be present in all cases. The pass-through entity (Your institution) must use judgment in classifying each agreement as a subaward or a procurement contract.This is very vague language however we highlight the fact that it is up to your best judgment and if you are unsure, you should contact your institutions resources to determine what you should budget under a subaward vs a contract. We understand not everyone has these resources available or it would be easier to have more guidance so our official guidelines state, if you are working with another institution to carry out some of the research, this would be a subaward, but if you are working with a business that is offering goods or services to other businesses or institutions in the public market to gain cashflow as their main source of funding, this is when we strongly suggest you categorize the acquisition relationship as one with a contractor.</Typo>
        <Typo className={styles.mb20}>Will your proposal require the need to obtain any goods and services from a contractor? If so, please continue with the section. Otherwise,  no need to worry about this section, you can move to the next section.</Typo>
      </div>
    </GrantFormWrapper>
  )
}

export default ComputerServicesInfo;
