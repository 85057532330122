import { FC } from 'react';
import {Button} from 'components/form';
import { Link } from 'react-router-dom';
import Tip from 'components/tip';
import useGrants from 'hooks/useGrants';
import styles from './create.module.css';
import {FullGrant} from 'store/grants/types';

type Props = {
  loading?: boolean;
  onClick?: () => void
  name: string;
  children: any;
  tip?: string;
  disabled?: boolean;
  when?: boolean
  side: FullGrant;
  to?: string;
}

const CreateBtn:FC<Props> = ({ loading, side, to, disabled, when, onClick, name, tip = 'You must add grant general information', children }) => {
  const globalWhen = side.years.length < 1;
  const btnDisabled = disabled || globalWhen || when;

  const btn = (
    <Button size="xs" loading={loading} full onClick={onClick}
            name={name} preIcon="plus" disabled={btnDisabled}
    >
      {children}
    </Button>
  );

  return (
    <Tip when={Boolean(globalWhen || when)} top text={tip}>
      {to && !btnDisabled ? <Link className={styles.link} to={to}>{btn}</Link> : btn}
    </Tip>
  );
}

export default CreateBtn;
