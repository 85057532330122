import { ChangeEvent, FC, ReactElement, useCallback } from 'react';
import { Autocomplete, Form, DatePicker, Input, Select, Textarea } from 'components/form';
import cx from 'classnames';
import { trackUserAction } from 'helpers/trackUserActions';
import Typo from 'components/typo';
import Help from 'components/help';
import { OrganizationPureUserData } from 'hooks/requestTypes';
import { Errors } from 'helpers/errors';
import useOrganizations from 'hooks/useOrganizations';
import useEnums from 'hooks/useEnums';
import { stringOrUndefined } from 'helpers/date';
import styles from './form.module.css';

export type Field = Record<string, any | Record<any, any>>;

type DivProps = {
  children: any[] | any;
  onSubmit: (data: OrganizationPureUserData) => void;
}

const Div:FC<DivProps> = ({children}) => {
  return (
    <div>
      {children}
    </div>
  )
}
type Props = {
  data: OrganizationPureUserData;
  onSubmit: (data: OrganizationPureUserData) => void;
  onChange: (value: Field) => void;
  errors: Errors;
  embed?: boolean;
  children: ReactElement;
}

const OrganizationForm:FC<Props> = (props: Props) => {
  const { onSubmit, errors, data, embed, onChange, children } = props;
  const { fetchApi, fetchOptions, isLoading } = useOrganizations();
  const { annualSchedules } = useEnums();

  const handleBlurInput= useCallback(() => {
    trackUserAction(`User start filling the “Organization Information” form`)
  }, [])

  const handleSubmit = useCallback(() => {
    onSubmit({
      id: data.id || undefined,
      name: data.name,
      subGroup: data.subGroup,
      department: data.department,
      address: {
        address1: data.address.address1,
        address2: data.address.address2,
        city: data.address.city,
        country: data.address.country,
        state: data.address.state,
        zip: data.address.zip,
      },
      academicYear: {
        startDate: stringOrUndefined(data.academicYear.startDate),
        endDate: stringOrUndefined(data.academicYear.endDate)
      },
      fiscalYear: {
        startDate: stringOrUndefined(data.fiscalYear.startDate),
        endDate: stringOrUndefined(data.fiscalYear.endDate),
        definition: data.fiscalYear.definition,
      },
      annualSchedule: data.annualSchedule,
    });
  }, [onSubmit, data]);


  const handleChangeZip = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const zip = event.target.value.replace(/\D/g,'');
    onChange({address: {...data.address, zip }})
  }, [data, onChange]);

  const Parent = embed ? Div : Form;

  return (
    <Parent onSubmit={handleSubmit}>
      <div className={styles.field}>
        <Autocomplete
          className={styles.fullInput}
          clearable
          errors={errors.name}
          value={data.name}
          onChange={(value: string | number) => onChange({name: String(value)})}
          name="organizationId"
          options={fetchOptions}
          placeholder="Select organization name"
          label="Organization name"
          onLoad={fetchApi}
          isLoading={isLoading}
        />
      </div>
      <div className={cx(styles.field, styles.withoutBorder)}>
        <Input className={styles.fullInput} name="address1" label="Address line 1" required
               value={data.address.address1} placeholder="123 N main st" errors={errors['address.address1']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({address: {...data.address, address1: event.target.value}})}
               onBlur={handleBlurInput}
        />
      </div>
      <div className={cx(styles.field, styles.withoutBorder)}>
        <Input className={styles.fullInput} name="address2" label="Address line 2"
               placeholder="123 N main st" value={data.address.address2} errors={errors['address.address2']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({address: {...data.address, address2: event.target.value}})}
        />
      </div>

      <div className={cx(styles.field, styles.withoutBorder)}>
        <Input className={styles.input} name="city" label="City" required
               placeholder="Flagstaff" value={data.address.city} errors={errors['address.city']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({address: {...data.address, city: event.target.value}})}
        />
        <Input className={styles.input} name="state" label="State/Province/Region" required
               placeholder="AZ" value={data.address.state} errors={errors['address.state']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({address: {...data.address, state: event.target.value}})}
        />
      </div>

      <div className={styles.field}>
        <Input className={styles.input} name="zip" label="ZIP" required
               placeholder="85015" value={data.address.zip} errors={errors['address.zip']}
               onChange={handleChangeZip}
        />
        <Input className={styles.input} name="country" label="Country*"
               placeholder="United States of America" disabled value={data.address.country}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({address: {...data.address, country: event.target.value}})}
        />
      </div>

      <div className={styles.field}>
        <Input className={styles.fullInput} name="department" label="Department"
               placeholder="Enter department" value={data.department} errors={errors.department}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({department: event.target.value})}
        />
      </div>

      <div className={cx(styles.field, styles.withoutBorder, styles.mb40)}>
        <Input className={styles.fullInput} name="subgroup" label="College (if a subgroup of your organization)"
               placeholder="Enter subgroup of Organization" value={data.subGroup} errors={errors.subGroup}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({subGroup: event.target.value})}
        />
      </div>

      <div className={styles.header}>
        <Typo type="div" className={styles.tabTitle} semi>
          <>
            Academic Year Schedule
            <Help>
              <div className={styles.smallHelp}>This is only relevant if your organization is a university</div>
            </Help>
          </>
        </Typo>
      </div>
      <div className={cx(styles.field, styles.withoutBorder)}>
        <DatePicker className={styles.input} name="startDate" label="Start date of academic year" required
                    placeholder="Choose date" value={data.academicYear.startDate} errors={errors['academicYear.startDate']}
                    onChange={(date: Date | null) => onChange({academicYear: {...data.academicYear, startDate: date}})}
        />
        <DatePicker className={styles.input} name="endDate" label="End date of academic year" required //@ts-ignore
                    minDate={data.academicYear.startDate || undefined}
                    placeholder="Choose date" value={data.academicYear.endDate} errors={errors['academicYear.endDate']}
                    onChange={(date: Date | null) => onChange({academicYear: {...data.academicYear, endDate: date}})}
        />
      </div>
      <div className={cx(styles.field, styles.mb40)}>
        <Select className={styles.fullInput} options={annualSchedules} name="annualSchedule" label="Annual Schedule of Organization (or the best match)"
                placeholder="Select annual schedule" value={data.annualSchedule} errors={errors.annualSchedule}
                required
                onChange={(annualSchedule: string | number | undefined) => onChange({annualSchedule})}

        />
      </div>

      <div className={styles.header}>
        <Typo type="div" className={styles.tabTitle} semi>Fiscal Year</Typo>
      </div>
      <div className={cx(styles.field, styles.withoutBorder)}>
        <DatePicker className={styles.input} name="fiscalStartDate" label="Organization start of fiscal year" required
                    placeholder="Choose date" value={data.fiscalYear.startDate} errors={errors['fiscalYear.startDate']}
                    onChange={(date: Date | null) => onChange({fiscalYear: {...data.fiscalYear, startDate: date}})}
        />
        <DatePicker className={styles.input} name="fiscalEndDate" label="Organization end of fiscal year" required
                    //@ts-ignore
                    minDate={data.fiscalYear.startDate || undefined}
                    placeholder="Choose date" value={data.fiscalYear.endDate} errors={errors['fiscalYear.endDate']}
                    onChange={(date: Date | null) => onChange({fiscalYear: {...data.fiscalYear, endDate: date}})}
        />
      </div>
      <div className={cx(styles.field, styles.withoutBorder, styles.mb40)}>
        <Textarea className={styles.fullInput} name="definition" label="Organization definition of Fiscal year" required
                  placeholder="Enter a description..." value={data.fiscalYear.definition} errors={errors['fiscalYear.definition']}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange({fiscalYear: {...data.fiscalYear, definition: event.target.value}})}
        />
      </div>

      {children}
    </Parent>
  )
}

export default OrganizationForm;
