import {FC, useState, useCallback, ChangeEvent} from 'react';
import Typo from 'components/typo';
import styles from './attendee.module.css';
import {ETravelType, TravelYear, TripAttendees} from 'store/grants/types';
import {useParams} from 'react-router-dom';
import useTravel from 'hooks/useTravel';
import { Props } from './index';
import TravelPersonnel from 'pages/grant/one/travel/common/personnel';
import TransportationYears from 'pages/grant/one/travel/common/years';
import { Button, Input, Form } from 'components/form';
import Field from 'components/field';
import TravelCostInterConsultantAttendee from './interConsultant';
import TravelCostAlaskaConsultantAttendee from './alaskaConsultant';

const TravelCostAlaskaAttendee:FC<Props> = ({trip, inter, cost, grant}) => {
  const { loading, previewTravelCost, updateTravelCost, errors } = useTravel();
  const params:Record<string, string> = useParams();
  const defaultYears = trip.status.years.length > 0
    ? trip.status.years.map((year: TravelYear) => ({
      year: year.year,
      serialNumber: year.serialNumber,
      isEnabled: false,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }))
    :
    grant.years.map((year: string[], serialNumber: number) => ({
      year,
      serialNumber: serialNumber + 1,
      isEnabled: false,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }));

  const defaultData = {
    postDocs: trip.status.postDocs ?? 0,
    graduateStudents: trip.status.graduateStudents ?? 0,
    undergraduateStudents: trip.status.undergraduateStudents ?? 0,
    civilService: trip.status.civilService ?? 0,
    union: trip.status.union ?? 0,
    tempAndCasual: trip.status.tempAndCasual ?? 0,
    otherPersonnel: trip.status.otherPersonnel ?? 0,
    seniorPersonnel: trip.status.seniorPersonnel.map((item: any) => item?.id) ?? [],
    academicResearchAssociate: trip.status.academicResearchAssociate.map((item: any) => item?.id) ?? [],
  }

  const [data, onChangeData] = useState<TripAttendees>({
    postDocs: cost?.tripAttendees?.postDocs || 0,
    compensationBeforeInflation: cost?.tripAttendees?.compensationBeforeInflation || 0,
    graduateStudents: cost?.tripAttendees?.graduateStudents || 0,
    undergraduateStudents: cost?.tripAttendees?.undergraduateStudents || 0,
    civilService: cost?.tripAttendees?.civilService || 0,
    totalAmountForTrip: cost?.tripAttendees?.totalAmountForTrip || 0,
    union: cost?.tripAttendees?.union || 0,
    lodgingBreakdownAmount: cost?.tripAttendees?.lodgingBreakdownAmount || '',
    totalMAndIeBeforeInflation: cost?.tripAttendees?.totalMAndIeBeforeInflation || '',
    totalLodgingBeforeInflation: cost?.tripAttendees?.totalLodgingBeforeInflation || '',
    totalMealsBeforeInflation: cost?.tripAttendees?.totalMealsBeforeInflation || '',
    totalIeBeforeInflation: cost?.tripAttendees?.totalIeBeforeInflation || '',
    totalDiscount: cost?.tripAttendees?.totalDiscount || '',
    tempAndCasual: cost?.tripAttendees?.tempAndCasual || 0,
    otherPersonnel: cost?.tripAttendees?.otherPersonnel || 0,
    years: cost && cost.tripAttendees.years.length > 0 ? cost.tripAttendees.years : defaultYears,
    seniorPersonnel: cost?.tripAttendees?.seniorPersonnel.length > 0 ? cost?.tripAttendees?.seniorPersonnel.map((item: any) => item?.id) : [],
    academicResearchAssociate: cost?.tripAttendees?.academicResearchAssociate.length > 0 ? cost?.tripAttendees?.academicResearchAssociate.map((item: any) => item?.id) : [],
  });

  const onBlur = useCallback((event?: ChangeEvent<HTMLInputElement>, value?: TripAttendees) => {
    const submitData = value ?? data;
    if (grant.id && cost) {
      previewTravelCost(grant.id, trip.id, cost.id, {
        tripAttendees: {
          ...submitData,
          seniorPersonnel: submitData.seniorPersonnel.map((id: string) => ({id})),
          academicResearchAssociate: submitData.academicResearchAssociate.map((id: string) => ({id})),
        }
      }, (response: any) => {
        onChangeData({
          ...submitData,
          ...response.tripAttendees,
          seniorPersonnel: response.tripAttendees.seniorPersonnel.map((item: Record<'id', string>) => item.id),
          academicResearchAssociate: response.tripAttendees.academicResearchAssociate.map((item: Record<'id', string>) => item.id),
        });
      });
    }
  }, [previewTravelCost, data, cost, grant.id, trip]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    onChangeData({
      ...data,
      ...field
    });
    if (preview) {
      onBlur(undefined,{
        ...data,
        ...field
      })
    }
  }, [data, onBlur]);

  const onSubmit = useCallback(() => {
    if (trip.id && cost) {
      updateTravelCost(grant.id, trip.id, params.costId, {
        tripAttendees: {
          ...data,
          seniorPersonnel: data.seniorPersonnel.map((id: string) => ({id})),
          academicResearchAssociate: data.academicResearchAssociate.map((id: string) => ({id})),
        }
      })
    }
  }, [data, updateTravelCost, cost, params, grant.id, trip]);

  const personnelData = {
    seniorPersonnel: data.seniorPersonnel,
    academicResearchAssociate: data.academicResearchAssociate,
    postDocs: data.postDocs,
    graduateStudents: data.graduateStudents,
    undergraduateStudents: data.undergraduateStudents,
    civilService: data.civilService,
    union: data.union,
    tempAndCasual: data.tempAndCasual,
    otherPersonnel: data.otherPersonnel,
  };

  const onCopy = useCallback(() => {
    let newYears = data.years;
    if (trip.status.years.length > 0) {
      newYears = trip.status.years.map((year: TravelYear) => ({
        year: year.year,
        serialNumber: year.serialNumber,
        isEnabled: year.isEnabled,
        numberOfOccurrence: '',
        compensationAfterInflation: '',
        compensationBeforeInflation: '',
        amountWithInflation: '',
      }));
    }
    const newData = {
      ...data,
      ...defaultData,
      years: newYears
    };
    onChangeData(newData);
    onBlur(undefined, newData);
  }, [data, onBlur, trip, defaultData]);

  const hintText = `Please list the maximum number of other personnel by type that you would like to put on travel status`;

  const fields = (
    inter ?
      <div>
        <Field last>
          <Input name="totalLodgingBeforeInflation" money type="number" value={data.totalLodgingBeforeInflation} disabled readOnly label="Lodging for all people before inflation" placeholder="Amount of lodging compensation before inflation" />
          <Input name="totalMAndIeBeforeInflation" money type="number" value={data.totalMAndIeBeforeInflation} disabled readOnly label="M&IE for all people before inflation" placeholder="Amount of meals compensation before inflation" />
        </Field>
        <Field last>
          <Input name="totalDiscount" money type="number" value={data.totalDiscount} disabled readOnly label="Total start/end day discount for all personnel" placeholder="Amount of compensation before inflation" />
          <Input name="compensationBeforeInflation" money type="number" value={data.compensationBeforeInflation} disabled readOnly label="Total expense (Lodging, M&IE) for all people before inflation with applied discount" placeholder="Amount of compensation before inflation with discount" />
        </Field>
      </div>
      :
      <div>
        <Field last>
          <Input name="totalLodgingBeforeInflation" money type="number" value={data.totalLodgingBeforeInflation} disabled readOnly label="Lodging for all people before inflation" placeholder="Amount of lodging compensation before inflation" />
          <Input name="totalMealsBeforeInflation" money type="number" value={data.totalMealsBeforeInflation} disabled readOnly label="Meals for all people before inflation" placeholder="Amount of meals compensation before inflation" />
        </Field>
        <Field last>
          <Input name="totalIeBeforeInflation" money type="number" value={data.totalIeBeforeInflation} disabled readOnly label="IE for all people before inflation" placeholder="Amount of IE compensation before inflation" />
          <Input name="totalDiscount" money type="number" value={data.totalDiscount} disabled readOnly label="Total start/end day discount for all personnel" placeholder="Amount of compensation before inflation" />
        </Field>
        <Field full>
          <Input name="compensationBeforeInflation" money type="number" value={data.compensationBeforeInflation} disabled readOnly label="Total expense (Lodging, M&IE) for all people before inflation with applied discount" placeholder="Amount of compensation before inflation with discount" />
        </Field>
      </div>
  );

  if (trip.type === ETravelType.Consultant && cost.kind === 'alaskaHawaii') {
    return <TravelCostAlaskaConsultantAttendee grant={grant} cost={cost} trip={trip}/>
  }
  if (trip.type === ETravelType.Consultant && cost.kind === 'international') {
    return <TravelCostInterConsultantAttendee grant={grant} cost={cost} trip={trip}/>
  }

  return (
    <div>
      <Typo className={styles.mb16}>Please check all of the PI's, Co-PI's, and Academic Research Associates who you
        would like to list as "on travel status" for this trip. This is a formality and will have little result in the
        final budget however if at any point they will be on a leg of this trip you are budgeting for, you should be
        sure to check their name.</Typo>
      <Typo className={styles.mb20}>Only people who are "on travel status" can have costs on trips covered. All
        exceptions for this rule are taken into account in a later section under this trip.</Typo>
      <Typo type="div" bold className={styles.title}>
        <>
          <span>
            Trip attendees
          </span>
          <Button htmlType="button" onClick={onCopy} name="duplicate-from-status" type="mint">Duplicate data from Travel status</Button>
        </>
      </Typo>
      <Form prompt onSubmit={onSubmit}>
        <TravelPersonnel data={personnelData} grant={grant} onChange={handleChangeData} onBlur={onBlur} hint={hintText} />
        {fields}
        <TransportationYears errorsPrefix="tripAttendees" onBlur={onBlur} totalAmountForTrip={data.totalAmountForTrip} //@ts-ignore
                             data={data.years} onChange={handleChangeData} errors={errors} />
        <div className={styles.footer}>
          <Button loading={loading} htmlType="submit" name="submit-equip">Save</Button>
        </div>
      </Form>
    </div>
  );
}


export default TravelCostAlaskaAttendee;
