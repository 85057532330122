import {createElement, FC, ReactElement} from 'react';
import cx from 'classnames';
import styles from './field.module.css';
import Help from 'components/help';

type Props = {
  name: string;
  label?: string | JSX.Element;
  className?: string;
  hint?: string;
  errors?: string[];
  required?: boolean;
  children?: ReactElement | ReactElement[];
  help?: ReactElement | string;
  helpDebounce?: number;
  helpInLabel?: boolean;
  triangleHelp?: boolean;
  element?: 'span' | 'label';
  pos?: 'left' | 'right';
  showHelp?: boolean;
  fullError?: boolean;
}
const Field:FC<Props> = (props: Props) => {
  const {
    name, hint, className, label, helpDebounce,
    showHelp, help, required, errors = [], children,
    triangleHelp, helpInLabel, element = 'label',
    pos, fullError
  } = props;

  const invalid = errors.length > 0;
  const formattedErrors = errors.join('; ');

  const helpComponent = (
    <Help triangle={triangleHelp} pos={pos} contentClassName={styles.helpContent} show={showHelp} debounce={helpDebounce} className={cx(styles.help, 'input-help')}>{help || ''}</Help>
  );

  return (
    <div className={cx(styles.wrapper, className, {[styles.hasError]: invalid})}>
      {label ?
        createElement(element, {className: styles.label, htmlFor: name, children: <><span title={label as unknown as string}>{label}</span>{required ? '*' : ''} {help && helpInLabel ? helpComponent : null}</>})
        : null}
      {children ?
        <div className={styles.childWrapper}>
          {children}
          {help && !helpInLabel ? helpComponent : null}
        </div>
        : null
      }
      {hint && !invalid ? <div className={styles.hint}>{hint}</div> : null}
      {invalid ? <div title={formattedErrors} className={cx(styles.errors, {[styles.fullError]: fullError})}>{formattedErrors}</div> : null}
    </div>
  )
}

export default Field;
