import { Action } from 'store/types';
import { UIState } from './types';
import { uiStateAdapter, uiClearedState } from './adapters';
import {
  minimizeSidebarAction,
  maximizeSidebarAction,
  startLoadingAction,
  endLoadingAction,
  changeSidebarWidthAction,
  showInfoSidebarAction,
  hideInfoSidebarAction,
  addModalToHiddenModals,
  setPrevUrlAction,
  setOpenedSteps,
  clearUI,
  setMtdcGrantId,
  setICGrantId,
  setFinalGrantId,
  minimizeBudgetBarAction,
  maximizeBudgetBarAction,
  showPromptAction,
  hidePromptAction,
  showPaywallAction,
  hidePaywallAction,
  showSettingsModalAction,
  hideSettingsModalAction,
  hideDisplayMessageAction,
  hideNavigationMessageAction, showNavigationMessageAction, addHiddenRate
} from './actions';


const initialStore = uiStateAdapter();

const reducer = (state:UIState = initialStore, action: Action) => {
  switch (action.type) {
    case minimizeSidebarAction.type: {
      localStorage.setItem('bm-sidebar-minimized', 'true');
      return state.set('minimizeSidebar', true);
    }
    case maximizeSidebarAction.type: {
      localStorage.removeItem('bm-sidebar-minimized');
      return state.set('minimizeSidebar', false);
    }
    case changeSidebarWidthAction.type: {
      localStorage.setItem('bm-sidebar-width', `${action.payload as number}`)
      return state.set('sidebarWidth', action.payload);
    }
    case addModalToHiddenModals.type: {
      const newHiddenModals = state.hiddenModals.asMutable().concat(action.payload);
      localStorage.setItem('bm-hidden-modals', JSON.stringify(newHiddenModals));
      return state.set('hiddenModals', newHiddenModals);
    }
    case addHiddenRate.type: {
      const newHiddenRates = state.hiddenRatesInBar.asMutable().concat(action.payload);
      localStorage.setItem('bm-stored-hr', JSON.stringify(newHiddenRates));
      return state.set('hiddenRatesInBar', newHiddenRates);
    }
    case startLoadingAction.type: {
      return state.set('loading', true);
    }
    case setOpenedSteps.type: {
      return state.set('openedSteps', action.payload);
    }
    case showInfoSidebarAction.type: {
      return state.set('infoSidebar', true);
    }
    case hideInfoSidebarAction.type: {
      return state.set('infoSidebar', false);
    }
    case endLoadingAction.type: {
      return state.set('loading', false);
    }
    case setPrevUrlAction.type: {
      return state.set('prevUrl', action.payload);
    }
    case setMtdcGrantId.type: {
      return state.set('mtdcGrantId', action.payload);
    }
    case setICGrantId.type: {
      return state.set('icGrantId', action.payload);
    }
    case setFinalGrantId.type: {
      return state.set('finalGrantId', action.payload);
    }
    case showNavigationMessageAction.type: {
      return state.set('showNavigationMessage', true);
    }
    case hideNavigationMessageAction.type: {
      return state.set('showNavigationMessage', false);
    }
    case minimizeBudgetBarAction.type: {
      localStorage.setItem('bm-small-bb', JSON.stringify(1));
      return state.set('smallBudgetBar', true);
    }
    case maximizeBudgetBarAction.type: {
      localStorage.setItem('bm-small-bb', JSON.stringify(0));
      return state.set('smallBudgetBar', false);
    }
    case hidePromptAction.type: {
      localStorage.setItem('bm-hide-prompt', JSON.stringify(1));
      return state.set('hidePrompt', true);
    }
    case showPromptAction.type: {
      localStorage.setItem('bm-hide-prompt', JSON.stringify(0));
      return state.set('hidePrompt', false);
    }
    case showPaywallAction.type: {
      return state.set('showPaywall', true);
    }
    case hidePaywallAction.type: {
      return state.set('showPaywall', false);
    }
    case showSettingsModalAction.type: {
      return state.set('showSettingsModal', true);
    }
    case hideSettingsModalAction.type: {
      return state.set('showSettingsModal', false);
    }
    case hideDisplayMessageAction.type: {
      return state.set('showDisplayMessage', false);
    }
    case clearUI.type: {
      return uiClearedState;
    }


    default: {
      return state;
    }
  }
};

export default reducer;
