import {FC, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
// helpers
import {getTransportationMethodFromGrant} from 'helpers/getFromGrant';
// types
import {FullGrant, TransportationMethod as TransportationMethodType, Travel} from 'store/grants/types';
// components
import TransportationMethod from './transportationMethod';
import Folder from 'pages/grant/paper/card/folder';

type Props = {
  side: FullGrant;
  trip: Travel;
  list: {
    items: Array<TransportationMethodType>;
    listId: string;
    value: string;
    label: string;
  };
  handleRemoveTransportationMethod: (id: string) => void;
};

const GroupedByMethodList: FC<Props> = ({side, trip, list, handleRemoveTransportationMethod}) => {
  const url = useUrl();
  const {method: activeMethod} = getTransportationMethodFromGrant(side, trip.id, url.sectionId as string);

  const folderData = useMemo(() => ({
    label: list.label,
    type: list.value,
    id: list.listId,
    active: activeMethod?.method === list.value,
    open: activeMethod?.method === list.value
  }), [activeMethod, list]);

  return (
    <>
      {list.items.length === 1 && (
        <TransportationMethod
          item={list.items[0]}
          linkTo={`/grants/${side.id}/travel/${trip.type}/${trip.id}/transportation/${list.items[0].id}/edit?method=${list.items[0].method}`}
          isActive={url.sectionId === list.items[0].id}
          showMethod
          handleRemoveTransportationMethod={handleRemoveTransportationMethod}
        />
      )}

      {list.items.length > 1 && (
        <Folder type="categoryId" grantId={side.id} icon item={folderData}>
          {list.items.map((tMethod: TransportationMethodType) => {

            return (
              <TransportationMethod
                key={tMethod.id}
                item={tMethod}
                linkTo={`/grants/${side.id}/travel/${trip.type}/${trip.id}/transportation/${tMethod.id}/edit?method=${tMethod.method}`}
                isActive={url.sectionId === tMethod.id}
                handleRemoveTransportationMethod={handleRemoveTransportationMethod}
              />
            )
          })}
        </Folder>
      )}
    </>
  );
};

export default GroupedByMethodList;
