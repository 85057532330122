import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { useHistory, useParams} from 'react-router-dom';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {getEventFromGrant, getParticipantFromGrantEvent} from 'helpers/getFromGrant';
import useGrants from 'hooks/useGrants';
import useParticipants from 'hooks/useParticipants';
import Form from './form';
import Tabs, { Tab } from 'components/tabs';
import {
  FullGrant,
  GrantEventParticipant,
  GrantEventParticipantExpense,
} from 'store/grants/types';

type Props = {
  grant: FullGrant;
  participant: GrantEventParticipant;
  eventId: string;
}

const GrantEventParticipantExpenses:FC<Props> = ({grant, participant, eventId}) => {
  const {
    addGrantEventParticipantExpense,
    deleteGrantEventParticipantExpense,
    errors, loading, clearErrors,
    updateGrantEventParticipantExpense,
    previewParticipantExpense,
    trackError,
    trackExit,
    trackFormStarted
  } = useParticipants({
    form_page_type: 'Participant`s expenses'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params:Record<string, any> = useParams();
  const history = useHistory();
  const [ expenses, onChangeExpenses ] = useState<GrantEventParticipantExpense[]>(participant.expenses ?? []);


  const onSubmit = useCallback((id: string, data: GrantEventParticipantExpense) => {
    updateGrantEventParticipantExpense(grant.id, eventId, participant.id, id, data, (newData: any) => {
      const newExpenses = expenses.map((item: GrantEventParticipantExpense) => {
        if (item.id === id) return newData;
        return item;
      });
      onChangeExpenses(newExpenses);
    });
  }, [grant, eventId, expenses, updateGrantEventParticipantExpense, participant]);

  const handleDeleteExpense = useCallback((id: string) => {
    deleteGrantEventParticipantExpense(grant.id, eventId, participant.id, id, () => {
      const newExpenses = expenses.filter((item: GrantEventParticipantExpense) => item.id !== id);
      onChangeExpenses(newExpenses);
    });
  }, [grant, expenses, eventId, participant, deleteGrantEventParticipantExpense]);

  const onBlur = useCallback((data: GrantEventParticipantExpense, id: string, cb: (cbData: GrantEventParticipantExpense) => void) => {
    previewParticipantExpense(grant.id, eventId, participant.id, data, cb);
  }, [previewParticipantExpense, grant, eventId, participant,]);

  const list:Tab[] = useMemo(() => {
    return expenses.map((item: GrantEventParticipantExpense, idx: number) => ({
      label: item.name || `Expense ${idx + 1}`,
      id: item.id ?? 'new',
      onDelete: () => handleDeleteExpense(item.id),
      element: participant
        ? <Form onSubmit={(data: GrantEventParticipantExpense) => onSubmit(item.id, data)}
                item={item} key={item.id} onBlur={onBlur}
                errors={errors} loading={loading}
                trackExit={trackExit} trackError={trackError}
        />
        : <span/>
    }));
  }, [expenses, participant, onBlur, errors, loading, trackExit, trackError, handleDeleteExpense, onSubmit]);

  const onAddMethod = useCallback(() => {
    addGrantEventParticipantExpense(grant.id, params.eventId, params.participantId, (data: GrantEventParticipantExpense) => {
      const newMethods = [...expenses, data];
      onChangeExpenses(newMethods);
      history.push(`/grants/${grant.id}/events/${params.eventId}/participants/${params.participantId}/expenses?expense=${data.id}`)
    });
  }, [expenses, grant, addGrantEventParticipantExpense, history, params]);

  return (
    <Tabs smallPadding onEnter={clearErrors} list={list} name="expense" action={onAddMethod} actionTitle="Add expense" />
  );
}

const GrantEventParticipantExpensesPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, any> = useParams();
  const { item, index } = getEventFromGrant(grant, params.eventId);
  const participant = item && getParticipantFromGrantEvent(item, params.participantId);
  const key = `${params.id}-event-${params.eventId}-participant-${participant?.item?.id}-travels`;

  const subtitle = useMemo(() => {
    return `Participant's Expenses. Event ${index}. Participant ${participant?.index ?? ''}`
  }, [index, participant]);

  return (
    <GrantFormWrapper title="Participants Support" subtitle={subtitle}>
      {participant?.item
        ? <GrantEventParticipantExpenses grant={grant} participant={participant.item} eventId={params.eventId} key={key} />
        : <span/>
      }
    </GrantFormWrapper>
  );
}

export default GrantEventParticipantExpensesPage;
