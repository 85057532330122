import {FC, useState, useCallback, ChangeEvent, useMemo} from 'react';
import Field from 'components/field';
import {Input, Form, Toggler, Textarea, Button} from 'components/form';
import {
  GrantEventParticipantYear
} from 'store/grants/types';
import {Errors} from 'helpers/errors';
import styles from './travel.module.css';
import useParticipants from 'hooks/useParticipants';
import {FieldErrors} from "react-hook-form";

type Props = {
  year: GrantEventParticipantYear;
  grantId: string;
  eventId: string;
  participantId: string;
  errors: Errors;
  loading: boolean;
  notFirst: boolean;
  clearErrors: () => void;
  onSubmit: (data: GrantEventParticipantYear) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const GrantEventParticipantTravelForm: FC<Props> = ({
                                                      trackError,
                                                      trackExit,
                                                      trackFormStarted,
                                                      year,
                                                      loading,
                                                      notFirst,
                                                      participantId,
                                                      grantId,
                                                      eventId,
                                                      onSubmit
                                                    }) => {
  const {
    previewParticipant
  } = useParticipants();
  const [data, onChangeData] = useState<GrantEventParticipantYear>(year);

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onBlur = useCallback(() => {
    if (data.kind && data.numberOfPeople) {
      if (data.averageCompensationHours && data.kind === 'hourly') {
        previewParticipant(grantId, eventId, {
          ...data,
          doUse1stYearValue: false,
          participantId
        }, (data) => {
          handleChangeData({
            totalStipend: data.totalStipend
          });
        });
        return;
      }
      if (data.averageCompensationForPerson && data.kind === 'totalSum') {
        previewParticipant(grantId, eventId, {
          ...data,
          doUse1stYearValue: false,
          participantId
        }, (data) => {
          handleChangeData({
            totalStipend: data.totalStipend
          });
        });
      }
    }

  }, [handleChangeData, grantId, participantId, eventId, previewParticipant, data]);

  const onUserFirstYearValues = useCallback(() => {
    previewParticipant(grantId, eventId, {
      ...data,
      doUse1stYearValue: true,
      participantId
    }, (data) => {
      handleChangeData({
        kind: data.kind,
        hourlyRate: data.hourlyRate,
        averageCompensationHours: data.averageCompensationHours,
        averageCompensationForPerson: data.averageCompensationForPerson,
        numberOfPeople: data.numberOfPeople,
        doUse1stYearValue: true,
        totalStipend: data.totalStipend
      });
    });
  }, [handleChangeData, grantId, participantId, eventId, previewParticipant, data]);

  const handleSubmit = useCallback(() => {
    onSubmit(data);
  }, [onSubmit, data]);

  const handleChangeToggler = useCallback((kind: string) => {
    handleChangeData({
      kind: data.kind === kind ? undefined : kind,
      hourlyRate: '',
      averageCompensationHours: '',
      numberOfPeople: '',
      totalStipend: '',
    });
  }, [data, handleChangeData]);
  const requiredSum = data.kind === 'totalSum';
  const requiredHourly = data.kind === 'hourly';
  const requiredKind = useMemo(() => {
    if (data.doUse1stYearValue && data.kind) return false;
    return true;
  }, [data]);

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      {
        notFirst
          ? <Field last>
            <Button name="doUse1stYearValue" onClick={onUserFirstYearValues} htmlType="button" type="mint">
              Use year 1 values
            </Button>
          </Field>
          : null
      }
      <Field last>
        <Toggler green required={requiredKind} name="kind" value="hourly" checked={data.kind === 'hourly'}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeToggler(event.target.value)}
                 label="Budget hourly"/>
      </Field>
      <Field last>
        <Input name="hourlyRate" value={data.kind !== 'hourly' ? '' : data.hourlyRate}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({hourlyRate: event.target.value})}
               money type="number" onBlur={onBlur} placeholder="Enter hourly rate" disabled={data.kind !== 'hourly'}
               label="Hourly rate" required={requiredHourly}/>
        <Input name="averageCompensationHours" value={data.kind !== 'hourly' ? '' : data.averageCompensationHours}
               auto
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({averageCompensationHours: event.target.value})}
               type="number" placeholder="Enter average compensation hours" disabled={data.kind !== 'hourly'}
               label="Average compensation hours" required={requiredHourly} onBlur={onBlur}/>
      </Field>
      <Field>
        <Input name="numberOfPeople_totalSum" value={data.kind !== 'hourly' ? '' : data.numberOfPeople}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({numberOfPeople: event.target.value})}
               type="number" onBlur={onBlur} placeholder="Enter number of people" disabled={data.kind !== 'hourly'}
               label="Number of people to cover stipends for" required={requiredHourly} auto/>
        <Input name="totalStipend" value={data.kind !== 'hourly' ? '' : data.totalStipend}
               type="number" placeholder="Total stipend" disabled readOnly money auto
               label={`Total participants stipend for Year ${year.serialNumber}`}/>
      </Field>

      <Field last>
        <Toggler green required={requiredKind} name="kind" value="totalSum" checked={data.kind === 'totalSum'}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeToggler(event.target.value)}
                 label="Budget a total sum"/>
      </Field>
      <Field last>
        <Input name="averageCompensationForPerson" onBlur={onBlur}
               value={data.kind !== 'totalSum' ? '' : data.averageCompensationForPerson}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({averageCompensationForPerson: event.target.value})}
               money type="number" placeholder="Enter compensation amount" disabled={data.kind !== 'totalSum'}
               label="Average compensation amount for a person" auto required={requiredSum}/>
        <Input name={data.kind !== 'totalSum' ? 'numberOfPeople_extra' : 'numberOfPeople'} onBlur={onBlur}
               value={data.kind !== 'totalSum' ? '' : data.numberOfPeople}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({numberOfPeople: event.target.value})}
               type="number" placeholder="Enter number of people" disabled={data.kind !== 'totalSum'}
               label="Number of people to cover stipends for" auto required={requiredSum}/>
        <Input name="totalStipend" value={data.kind !== 'totalSum' ? '' : data.totalStipend}
               type="number" placeholder="Total stipend" disabled readOnly money auto
               label={`Total participants stipend for Year ${year.serialNumber}`}/>
      </Field>

      <Field full last>
        <Textarea name="justification" label="Justification for this cost"
                  placeholder="Enter a description..." value={data.justification}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({justification: event.target.value})}/>
      </Field>

      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

export default GrantEventParticipantTravelForm;
