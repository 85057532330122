import {get, omit, omitBy} from 'lodash';


export type TableDataYear = {
  year: string[];
  serialNumber: number;
  value: number;
  initialValue?: number;
  initialIDCValue: number;
  overriddenIDCValue: number;
}

export type TableDataItem = {
  name: string;
  key: string;
  years: TableDataYear[];
  id: string;
  isMTDC: boolean;
  totals: number;
  initialTotals?: number;
  isOverriddenIDC: boolean;
  isNotCalculatedMTDC: boolean;
  commentIDC: string;
  overriddenTotals: number;
}

export type SectionData = {
  totalsItem: TableDataItem;
  name: string;
  section: string;
  items: TableDataItem[];
}

export type TableSections = Record<string, SectionData>;

export type TableSection = {
  name: string;
  section?: string;
  sections: TableSections;
  categories?: TableSections;
  totalsItem: TableDataItem;
}

export type TableData = {
  sections: {
    equipment: TableSection;
    participantSupport: TableSection;
    otherDirectCosts: TableSection;
    other: TableSection;
    personnel: {
      name: string;
      sections: TableSections;
    };
    otherPersonnel: {
      name: string;
      sections: TableSections;
    };
    fringeBenefits: {
      name: string;
      sections: TableSections;
    }
    travel: {
      name: string;
      sections: {
        domestic: SectionData;
        foreign: SectionData;
      };
      totalItems: TableDataItem[];
    };
  };
  totalsItem: TableDataItem;
  years: TableDataYear[];
}

export type TableDataRequest = {
  sections: {
    equipment: TableSection;
    participantSupport: TableSection;
    otherDirectCosts: TableSection;
    personnel: {
      sections: {
        fringeBenefits: TableSection;
        salaries: TableSection;
      };
    };
    travel: {
      types: {
        domestic: SectionData;
        foreign: SectionData;
      };
      totalItems: TableDataItem[];
    };
  };
  totalsItem: TableDataItem;
  years: TableDataYear[];
}

const tableDataYearAdapter = (data: TableDataYear, idx: number) => {
  return {
    year: data.year ?? [],
    serialNumber: data.serialNumber ?? idx + 1,
    value: data.value ?? 0,
    initialIDCValue: data.initialIDCValue ?? 0,
    overriddenIDCValue: data.overriddenIDCValue ?? 0,
    ...('initialValue' in data ? {initialValue: data.initialValue ?? 0} : {})
  }
}

export const tableDataYearsAdapter = (data: TableDataYear[] = []) => {
  return data.map(tableDataYearAdapter);
}

export const tableDataItemAdapter = (data?: TableDataItem, key?: string) => {
  return {
    name: data?.name ?? '',
    id: data?.id ?? '',
    key: key ?? '',
    isMTDC: Boolean(data?.isMTDC),
    years: tableDataYearsAdapter(data?.years),
    totals: data?.totals ?? 0,
    isOverriddenIDC: Boolean(data?.isOverriddenIDC),
    isNotCalculatedMTDC: Boolean(data?.isNotCalculatedMTDC),
    commentIDC: data?.commentIDC ?? '',
    overriddenTotals: data?.overriddenTotals ?? 0,
    ...(data && 'initialTotals' in data ? {initialTotals: data?.initialTotals ?? 0} : {})
  }
}

export const tableSectionAdapter = (data?: SectionData, name?: string, section?: string) => {
  return {
    name,
    section,
    totalsItem: tableDataItemAdapter(data?.totalsItem),
    items: data?.items?.map((item: TableDataItem) => tableDataItemAdapter(item, name)) ?? [],
  }
}

export const tableDataSectionsAdapter = (data: Record<string, SectionData> | undefined = {}, section: string) => {//@ts-ignore
  return Object.keys(data || {}).reduce((res: TableSections, key: string) => ({
    ...res,
    [key]: tableSectionAdapter(get(data, key, undefined), key, section)
  }), {})
}

export const tableDataSectionAdapter = (data: TableSection | undefined, name: string, section: string) => {
  return {
    name: name,
    sections: tableDataSectionsAdapter(data?.sections || data?.categories, section),
    categories: tableDataSectionsAdapter(data?.categories, section),
    totalsItem: tableDataItemAdapter(data?.totalsItem),
  }
}

const tableDataAdapter = (data?: TableDataRequest):TableData => {
  const otherPersonnel = omitBy({
    academicResearchAssociate: data?.sections?.personnel?.sections?.salaries?.sections?.academicResearchAssociate,
    postDocPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.postDocPersonnel,
    graduateStudentsStipend: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsStipend,
    graduateStudentsAcademic: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsAcademic,
    graduateStudentsSummer: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsSummer,
    undergraduateStudentsAcademic: data?.sections?.personnel?.sections?.salaries?.sections?.undergraduateStudentsAcademic,
    undergraduateStudentsSummer: data?.sections?.personnel?.sections?.salaries?.sections?.undergraduateStudentsSummer,
    civilService: data?.sections?.personnel?.sections?.salaries?.sections?.civilService,
    tempAndCasual: data?.sections?.personnel?.sections?.salaries?.sections?.tempAndCasual,
    unionPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.unionPersonnel,
    otherPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.otherPersonnel,
  }, item => item === undefined);

  const fringeBenefits = omitBy({
    seniorPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.seniorPersonnel,
    academicResearchAssociate: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.academicResearchAssociate,
    postDocPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.postDocPersonnel,
    graduateStudentsStipend: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.graduateStudentsStipend,
    graduateStudentsTuition: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.graduateStudentsTuition,
    civilService: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.civilService,
    tempAndCasual: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.tempAndCasual,
    unionPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.unionPersonnel,
    otherPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.otherPersonnel,
  }, item => item === undefined)

  return {
    totalsItem: tableDataItemAdapter(data?.totalsItem),
    years: tableDataYearsAdapter(data?.years),
    sections: {//@ts-ignore
      equipment: tableDataSectionAdapter(data?.sections?.equipment, 'Equipment', 'equipment'),//@ts-ignore
      participantSupport: tableDataSectionAdapter(data?.sections?.participantSupport, 'Participant Support', 'participantSupport'),//@ts-ignore
      otherDirectCosts: {
        name: 'Other Direct Costs',
        sections: {
          //@ts-ignore
          ...tableDataSectionsAdapter(omit(data?.sections?.otherDirectCosts?.sections || {}, 'other'), 'otherDirectCosts'),
          //@ts-ignore
          ...tableDataSectionsAdapter(data?.sections?.otherDirectCosts?.sections?.other?.sections || {}, 'otherDirectCosts.other')
        },
        totalsItem: tableDataItemAdapter(data?.sections?.otherDirectCosts?.totalsItem),
      },
      personnel: {
        name: 'Personnel',//@ts-ignore
        sections: tableDataSectionsAdapter({seniorPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.seniorPersonnel}, 'personnel.salaries'),//@ts-ignore
      },
      otherPersonnel: {
        name: 'Other Personnel',//@ts-ignore
        sections: tableDataSectionsAdapter(otherPersonnel, 'personnel.salaries'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.personnel?.sections?.salaries?.totalsItem),
      },
      fringeBenefits: {
        name: 'Fringe benefits',//@ts-ignore
        sections: tableDataSectionsAdapter(fringeBenefits, 'personnel.fringeBenefits'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.personnel?.sections?.fringeBenefits?.totalsItem),
      },
      travel: {
        name: 'Travel',//@ts-ignore
        sections: tableDataSectionsAdapter(data?.sections?.travel?.types, 'travel'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.travel?.totalsItem),
      },
    }
  }
}

export default tableDataAdapter;
