import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
// hooks
import useApi from './useApi';
import useUI from './useUI';
// actions
import {
  updateMaterialsSuppliesGeneralInfoAction,
  createMaterialsSuppliesUnitAction
} from 'store/grants/otherDirectCosts/MaterialsSupplies/actions';
// adapters
import {
  materialsSuppliesGeneralInfoPostAdapter,
} from 'store/grants/otherDirectCosts/MaterialsSupplies/adapters';
// types
import {AxiosResponse, AxiosError} from 'axios';
import {
  MaterialsSuppliesGeneralInfoType,
  MaterialsSuppliesUnitType,
} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// helpers
import toast from 'components/toast';
import parseErrors, {Errors} from 'helpers/errors';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";

type UseMaterialsSuppliesType = {
  createGeneralInfo: (payload: MaterialsSuppliesGeneralInfoType, grantId: string) => void,
  updateGeneralInfo: (payload: MaterialsSuppliesGeneralInfoType, grantId: string) => void,
  isLoading: boolean,
  errors: Errors,
  //
  createUnit: (sideId: string) => void,
  updateUnit: (updatedUnitData: MaterialsSuppliesUnitType, updatedUnitId: string, grantId: string) => void,
  deleteUnit: (deletedUnitId: string, grantId: string, redirect: boolean) => void,
  //
  previewUnitYear: (grantId: string, unitId: string, serialNumber: number, data: any, cb: (data: any) => void) => void,
  //
  totals: string[][],
  getTotals: (grantId: string) => void,
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useMaterialsSupplies = (props: Props = {}): UseMaterialsSuppliesType => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'materials and supplies';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();

  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, onShowNavigationMessage } = useUI();
  const { getOneWithSide } = useGrants();
  //
  const [errors, onChangeErrors] = useState<Errors>({});
  const [isLoading, onChangeLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState<string[][]>([]);
  //
  const {
    getMaterialsSuppliesTotals,
    addMaterialsSuppliesGeneralInfo,
    updateMaterialsSuppliesGeneralInfo,
    addMaterialsSuppliesUnit,
    updateMaterialsSuppliesUnit,
    deleteMaterialsSuppliesUnit,
    previewMaterialsSuppliesUnitYear,
  } = useApi();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    getMaterialsSuppliesTotals(grantId)
      .then((response: AxiosResponse) => {
        setTotals(response.data.totals)
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [getMaterialsSuppliesTotals])

  const createGeneralInfo = useCallback(
    (payload: MaterialsSuppliesGeneralInfoType, grantId: string) => {
      onChangeErrors({});
      onChangeLoading(true);

      addMaterialsSuppliesGeneralInfo(grantId, materialsSuppliesGeneralInfoPostAdapter(payload))
        .then((res: AxiosResponse) => {
          formSuccessSaveCallBack()
          const {data} = res;
          const createdUnits = data?.units;
          dispatch(updateMaterialsSuppliesGeneralInfoAction(data));
          dispatch(createMaterialsSuppliesUnitAction(createdUnits));
          onChangeLoading(false);
        })
        .catch((error: AxiosError) => {
          //@ts-ignore
          if (error?.response?.data?.errors) { //@ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
          onChangeLoading(false);
        });
      trackFormSaveAttempted()
    },
    [addMaterialsSuppliesGeneralInfo, trackFormSaveAttempted, formSuccessSaveCallBack, dispatch],
  );

  const updateGeneralInfo = useCallback(
    (payload: MaterialsSuppliesGeneralInfoType, grantId: string) => {
      onChangeErrors({});
      onChangeLoading(true);

      updateMaterialsSuppliesGeneralInfo(grantId, materialsSuppliesGeneralInfoPostAdapter(payload))
        .then(() => {
          formSuccessSaveCallBack()

          getOneWithSide(grantId);
          toast.success({
            title: 'The materials and supplies information has been updated',
            message: 'Changes have been successfully saved',
          });
          onShowNavigationMessage();
          onChangeLoading(false);
        })
        .catch((error: AxiosError) => {
          //@ts-ignore
          if (error?.response?.data?.errors) { //@ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
          onChangeLoading(false);
        });
      trackFormSaveAttempted()
    },
    [updateMaterialsSuppliesGeneralInfo, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage],
  );

  const createUnit = useCallback(
    (sideId: string) => {
      onChangeErrors({});
      onChangeLoading(true);

      addMaterialsSuppliesUnit(sideId, {name: null, currentCost: null})
        .then((res: AxiosResponse) => {
          const {data} = res;
          history.push(`/grants/${sideId}/otherDC/materials-and-supplies/${data.id}/edit`);
          setTimeout(() => {
            dispatch(createMaterialsSuppliesUnitAction(data));
          }, 300);
          onChangeLoading(false);
        })
        .catch((error: AxiosError) => {
          // @ts-ignore
          if (error?.response?.data?.errors) { // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
          onChangeLoading(false);
        });
    },
    [dispatch, history, addMaterialsSuppliesUnit],
  );

  const updateUnit = useCallback(
    (updatedUnitData: MaterialsSuppliesUnitType, updatedUnitId: string, grantId: string) => {
      onChangeErrors({});
      onChangeLoading(true);
      updateMaterialsSuppliesUnit(grantId, updatedUnitId, updatedUnitData)
        .then(() => {
          formSuccessSaveCallBack()
          getOneWithSide(grantId);
          toast.success({
            title: `The unit details have been updated`,
            message: 'Changes have been successfully saved',
          });
          onShowNavigationMessage();
          onChangeLoading(false);
        })
        .catch((error: AxiosError) => {
          // @ts-ignore
          if (error?.response?.data?.errors) { // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
          onChangeLoading(false);
        });
      trackFormSaveAttempted()
    },
    [formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage, trackFormSaveAttempted, updateMaterialsSuppliesUnit],
  );

  const deleteUnit = useCallback(
    (deletedUnitId: string, grantId: string, redirect: boolean) => {
      const onConfirm = () => {
        onChangeErrors({});
        onChangeLoading(true);

        deleteMaterialsSuppliesUnit(grantId, deletedUnitId)
          .then(() => {
            onChangeLoading(false);
            getOneWithSide(grantId, () => {
              toast.success({
                title: `The unit has been deleted`,
                message: 'Changes have been successfully saved',
              });
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
          })
          .catch((error: AxiosError) => {
            // @ts-ignore
            if (error?.response?.data?.errors) { // @ts-ignore
              onChangeErrors(parseErrors(error.response.data?.errors));
            }
            toast.error({
              title: 'Unable to delete',
              // @ts-ignore
              message: error?.response?.data?.message
            });
            onChangeLoading(false);
          });
      }

      confirm({
        title: `Delete unit`,
        text: 'Are you sure you want to delete this data? This action will not be reversible.',
        type: 'error',
        icon: 'trash-01',
        okText: 'Delete',
        onConfirm: onConfirm
      })
    },
    [history, getOneWithSide, deleteMaterialsSuppliesUnit],
  );

  const previewUnitYear = useCallback((grantId: string, unitId: string, serialNumber: number, data: any, cb: (data: any) => void) => {
    loader.start();
    const q = new URLSearchParams(data).toString();
    previewMaterialsSuppliesUnitYear(grantId, unitId, `${serialNumber}`, `?${q}`)
      .then((response: AxiosResponse) => {
        loader.stop();
        cb(response.data);
      })
      .catch(() => {
        loader.stop();
      })
  }, [previewMaterialsSuppliesUnitYear, loader])

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    createGeneralInfo,
    updateGeneralInfo,
    isLoading,
    errors,
    //
    createUnit,
    updateUnit,
    deleteUnit,
    //
    previewUnitYear,
    //
    totals,
    getTotals,
    trackError,
    trackExit,
    trackFormStarted
  };
};

export default useMaterialsSupplies;
