import { FC, useMemo, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './avatar.module.css';
import Icon from '../icon';

type Props = {
  src?: string;
  alt: string;
  big?: boolean;
  onDelete?: () => void;
  className?: string;
}
const Avatar:FC<Props> = ({ src, onDelete, big, alt, className }) => {
  const [show, onChangeShow] = useState<boolean>(false);
  const placeholder = useMemo(() => {
    const [first, last] = alt.split(' ').map((item: string) => item[0]);
    return <span className={styles.placeholder}>{first}{last}</span>
  }, [alt]);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        onChangeShow(true);
      }
      img.onerror = () => {
        onChangeShow(false);
      }
    }
  }, [src]);

  return (
    <div className={cx(styles.wrapper, {[styles.big]: big}, className)}>
      {src && show ? <img src={src} alt={alt} className={styles.img}/> : placeholder}
      {onDelete ?
        <span className={styles.delete}>
          <Icon icon="trash-01" className={styles.deleteIcon} size={36} onClick={onDelete}/>
        </span>
      : null}

    </div>
  );
}

export default Avatar;
