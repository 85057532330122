import React, {ChangeEvent, FC, useCallback} from 'react';
import cx from 'classnames';
import Field from 'components/field';
import {Button, Form, Checkbox, DatePicker, Input, Select} from 'components/form';
import Typo from 'components/typo';
import styles from './forms.module.css';
import {Location} from './common';
import { Props } from './index';
import useGrants from 'hooks/useGrants';
import dayjs from 'dayjs';

const StatesForm:FC<Props> = ({ data, travel, onSubmit, errors, isUpdate, loading, onChange }) => {
  const { grant } = useGrants();
  const years = grant.years.map((year: string[]) => ({
    value: year[0],
    label: dayjs(year[0]).format('YYYY')
  }));

  const handleChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let dates = {
      startDate: null,
      endDate: null,
    }
    if (event.target.checked) {
      dates = {//@ts-ignore
        startDate: travel.estimatedStartDate ? new Date(travel.estimatedStartDate) : null,//@ts-ignore
        endDate: travel.estimatedEndDate ? new Date(travel.estimatedEndDate) : null,
        fiscalYear: undefined,
      }
    }
    onChange({
      isStartEndDateKnown: event.target.checked,
      ...dates
    });
  }, [travel, onChange]);


  return (
    <Form prompt onSubmit={onSubmit}>
      <Typo className={styles.text} >
        Please go to the website below to find a per diem rate or amount that would apply to this trip. Please note, if
        this trip involves a major switch in location, please use another separate per diem rate to account for the
        location change. This is important because the change in location might alter the location rates that are
        allowed to be used. It is very strongly adviced to use estimated date ranges when using the U.S. General
        Services Administration's website because they will provide a complete total for your whole trip broken down by
        day's including rate changes between months. If you simply enter the year that the rates will apply, you may
        have to manually estimate how many days of travel are located in different months manually.
      </Typo>
      <Typo className={styles.text} >
        <>Rates can be found here: <a href="https://www.gsa.gov/travel?gsaredirect=travel" target="_blank"
                                      rel="noreferrer">www.gsa.gov/travel-resources</a></>
      </Typo>
      <Typo className={styles.text} >
        If the website mentions that there is a rate that is currently unavailable (one that is far off in the future)
        don't worry. Simply use the rate for the current month in the same location or the most future rate available
        for that same location.
      </Typo>

      <Field full>
        <Input name="nameOfTrip" errors={errors.nameOfTrip} value={data.nameOfTrip}
               label="Name of travel" required placeholder="Enter a name of travel"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({nameOfTrip: event.target.value})}
        />
      </Field>

      <Location optional data={data} errors={errors} onChange={onChange} />

      <Field last>
        <Checkbox name="isStartEndDateKnown" label="I know the start and end dates"
                  value={data.isStartEndDateKnown} onChange={handleChangeCheckbox}
        />
      </Field>
      <Field withHelp>
        <Select name="fiscalYear" disabled={data.isStartEndDateKnown} errors={errors.fiscalYear} value={data.fiscalYear} options={years} label="Fiscal Year" required={!data.isStartEndDateKnown}
                placeholder="Select fiscal year" onChange={(fiscalYear: string) => onChange({fiscalYear})}
        />
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} name="startDate"
                    errors={errors.startDate} label="Start date" required={data.isStartEndDateKnown}
                    placeholder="Select a start date" value={data.startDate}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab”"
                    onChange={(date: Date | null) => onChange({startDate: date})}
        />
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} minDate={data.startDate || undefined} name="endDate"
                    errors={errors.endDate} label="End date"
                    placeholder="Select an end date" value={data.endDate} required={data.isStartEndDateKnown}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab”"
                    onChange={(date: Date | null) => onChange({endDate: date})}
        />
      </Field>
      <Typo className={styles.text} >The following questions are appearing because you entered a date range
        into the U.S. General Services Administration's website. You should see a light blue box telling you the
        "Estimated per diem total" for your trip. It should say this number is based on your (Max lodging total + M&IE
        total). M&IE stands for "Meals and Incidental Expenses". M&IE is a complicated way or writing "Per Diem" or
        simply put, the total amount of money one person will have per day to spend on food and miscellaneous expenses
        excluding lodging.</Typo>
      <Typo className={cx(styles.text, styles.mb60)} >If you don’t see questions that relate to the response
        your search on the GSA.gov website gave you, please double check to see if you entered just a year or a date
        range in the GSA website and our website that renders prompts dynamically based on if you entered just the year
        or a date range. Please note that in order to check if you entered a date range or just the year, you may have
        to re-do the same search to see if you get the same results.</Typo>

      <Typo className={cx(styles.text, styles.mb60)} >If you selected just a fiscal year (not range of dates),
        you will need to manually calculate the # of nights.</Typo>

      <Typo className={styles.text} >At the end of this section we will ask you which years of this grant this
        trip will apply to. You will have options to select all of the years to which this travel section will apply.
        Inflation will be calculated for you based on each of these years.</Typo>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add'} travel costs`}
        </Button>
      </div>
    </Form>
  );
}

export default StatesForm;
