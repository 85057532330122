import { FC, ChangeEvent, useCallback } from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import {useFormContext} from 'react-hook-form';
import {findInputError, RHErrors} from 'helpers/errors';

type Props = {
  name: string;
  value?: string | boolean;

  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  inputClassName?: string;
  invert?: boolean;
  bool?: boolean;
  className?: string;
  onInput?: () => void;
}

const Radio:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    className, errors = [], required, checked,
    disabled, inputClassName, hint, invert, bool
  } = props;

  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    if (bool) {
      onChange({
        ...event,
        target: {
          ...event.target, //@ts-ignore
          value: event.target.value === 'true'
        }
      })
      return;
    }
    onChange(event);
  }, [bool, onChange]);

  const {
    register,
    formState,
  } = useFormContext();
  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const validation = required
    ? register(name,
      {
        required: {value: true, message: 'Field is required'},
        onChange: disabled ? () => {} : handleChangeValue
      })
    : {};

  return (
    <label className={cx(styles.togglerWrapper, { [styles.invert]: invert})}>
      <input
        type="radio" name={name} onChange={handleChangeValue} //@ts-ignore
        checked={checked} value={value} className={cx(styles.hidden, styles.togglerRadio)}
        disabled={disabled} cypress-id={`${name}-radio`}
        {...validation}
      />
      <div className={cx(styles.customRadio, inputClassName)}>
        <span className={styles.radioDot}></span>
      </div>
      <Field required={required} className={className} label={label} hint={hint} errors={errorsList} name={`${name}-field`}/>
    </label>
  )
}

export default Radio;
