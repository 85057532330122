export const tokenKey = 'bm-auth-token';
export const head = 'Budget Mentor';

export type UploadTypes = 'uploadProfile';

export const stepNames:Record<string, string> = {
  inProgress: 'In progress',
  pendingOrganizationApproval: 'Pending organization approval',
  pendingNsfApproval: 'Pending NSF approval',
  reviseResubmit: 'Revise and Resubmit',
  accepted: 'Accepted',
  hidden: 'Achieved',
}

type PersonalType = 'postDocPersonnel' | 'civil' | 'union' | 'temp' |
  'other' | 'seniorPersonal' | 'academicResearchAssociate' |
  'graduateStudents' | 'undergraduateStudents' | string;

export const personalTypes:Record<PersonalType, string> = {
  postDocPersonnel: 'post-doc',
  seniorPersonal: 'senior-personal',
  academicResearchAssociate: 'academic-research-personal',
  graduateStudents: 'graduate-students',
  undergraduateStudents: 'undergraduate-students',
  civil: 'civil-service',
  union: 'union',
  temp: 'temp-n-casual',
  other: 'other-personnel',
}
export const personalFreeRoutes:PersonalType[] = [
  'post-doc',
  'senior-personal',
  'academic-research-personal',
]

export const personalTypesLinks:Record<string, string> = {
  seniorPersonnel: 'senior-personal',
  academicResearchAssociate: 'academic-research-personal',
  postDocPersonnel: 'post-doc',
  civilService: 'civil-service',
  unionPersonnel: 'union',
  tempAndCasual: 'temp-n-casual',
  otherPersonnel: 'other-personnel',
  undergraduateStudentsAcademic: 'undergraduate-students',
  undergraduateStudentsSummer: 'undergraduate-students',
  graduateStudentsStipend: 'graduate-students',
}

export const otherLinkTypes:Record<string, string> = {
  materialsAndSupplies: 'materials-and-supplies',
  publications: 'publications',
  computerServices: 'computer-services',
  consultans: 'consultantServices',
  subawards: 'subawards',
  contracts: 'contracts',
  incentivePayments: 'payments',
  allOtherCosts: 'otherExpenses',
}

export const personalTypesList:string[] = Object.values(personalTypes);

export const personnelFieldInGrant = {
  [personalTypes.civil]: 'civilService',
  [personalTypes.union]: 'unionPersonnel',
  [personalTypes.temp]: 'tempAndCasual',
  [personalTypes.other]: 'otherPersonnel',
}

export type Kind = 'fourty8states' | 'alaskaHawaii' | 'other' | 'international';

export const travelCostKinds:Record<Kind, string> = {
  fourty8states: '48 States',
  other: 'Other',
  alaskaHawaii: 'Alaska/Hawaii',
  international: 'International'
}

export const stepNamesList:string[] = Object.keys(stepNames)
