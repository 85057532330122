import { useCallback, useState } from 'react';
import useApi from 'hooks/useApi';
import toast from 'components/toast';
import { AxiosResponse, AxiosProgressEvent } from 'axios';

export type UploadTypes = 'uploadProfileImage';
export type Uploads = Record<UploadTypes, (file: File) => void>;


type iUseUpload = {
  key: string;
  uploads: Uploads;
  progress: number;
}

const useUpload = ():iUseUpload => {
  const api = useApi();
  const [ key, onChangeKey ] = useState<string>('');
  const [ progress, onChangeProgress ] = useState<number>(0);

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 100));
    onChangeProgress(percentCompleted);
  }, []);

  const uploadProfileImage = useCallback((file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    onChangeProgress(1);
    api.uploadProfileImage(formData)
      .then((response: AxiosResponse) => {
        const url = response.data.url || '';
        api.upload(url, file, { onUploadProgress })
          .then(() => {
            onChangeProgress(0);
            onChangeKey(response.data.key);
          })
          .catch(() => {
            onChangeProgress(0);
            toast.error({
              title: 'Error',
              message: `Something went wrong`
            });
          });
      })
      .catch(() => {
        onChangeProgress(0);
        toast.error({
          title: 'Error',
          message: `Something went wrong`
        });
      });
  }, [api, onUploadProgress]);

  return {
    uploads: {
      uploadProfileImage
    },
    progress,
    key
  }
}

export default useUpload;
