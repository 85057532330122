import { FC } from 'react';
import Typo from 'components/typo';
import styles from './cs.module.css'
import GrantFormWrapper from 'pages/grant/common/wrapper';

const PaymentsInfo:FC = () => {
  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Incentive Payments">
      <div>
        <Typo className={styles.mb16}>Your university or organization likely has specific guidelines on what is or isn't allowable as incentive payments. It is suggested you check in with any available resources if they are available. Any other guidelines as specified by the NSF can be found to the right.</Typo>
        <Typo className={styles.mb16}>The NSF specifically notes that performance based incentive payments to employees are not allowable. Performance based incentive payments include the following: incentive compensation based on cost reduction, efficient performance, suggestion awards, safety awards, ect.</Typo>
        <Typo className={styles.mb16}>Incentive payments for tasks and actions such as incentivizing the completion of surveys is an example of what should be included in this section.</Typo>
        <Typo className={styles.mb16}>If your proposal requires incentive payments, please continue with this section. Otherwise, no need to worry about this section, you can move to the next section.</Typo>
      </div>
    </GrantFormWrapper>
  )
}

export default PaymentsInfo;
