import {
  FC,
  useState,
  Fragment,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  ReactElement,
  ChangeEvent,
  MouseEvent
} from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';
import Field from 'components/form/field';
import Icon from 'components/icon';
import styles from 'components/form/form.module.css';
import {useFormContext} from 'react-hook-form';
import {findInputError, RHErrors} from '../../../helpers/errors';

type Option = {
  label: string | ReactElement | number;
  value: string | number;
}

type Props = {
  name: string;
  options: Option[];
  placeholder?: string;
  value?: any;
  onChange?: (value: string | number) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  isLoading?:boolean;
  noLoader?:boolean;
  onEnter?: (value: string) => void;
  hint?: string | any;
  min?: number;
  helpDebounce?: number;
  inputClassName?: string;
  onLoad?: (search: string) => void;
  help?: ReactElement | string;
  className?: string;
}

const Autocomplete:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    className, errors = [], required,
    disabled, inputClassName, hint,
    min = 0, clearable, helpDebounce,
    options, placeholder = 'Enter to find',
    onLoad, help, onEnter, isLoading = false,
    noLoader
  } = props;
  const currentOption = useMemo(() => options.find((item: Option) => item.value === value), [options, value]);
  const [show, onChangeShow] = useState<boolean>(false);
  const [isDebounceLoading, setIsDebounceLoading] = useState<boolean>();
  const [search, onChangeSearch] = useState<string>(String(currentOption?.label || value || ''));
  const input = useRef<HTMLDivElement | null>(null);
  const uniqueClassName = useMemo(() => `bm-autocomplete-${name}`, [name]);

  const {
    register,
    formState,
    setValue,
    clearErrors
  } = useFormContext();
  const handleClick = useCallback((event: any) => {
    const inSelect = event.target?.closest(`.${uniqueClassName}`);
    if (show && !inSelect) {
      onChangeShow(false);
    }
    return;
  }, [show, uniqueClassName]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [show, handleClick]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const makeDebouncedRequest = useCallback(debounce((search: string) => {
    if (onLoad) {
      onLoad(search);
      setIsDebounceLoading(false)
    } else {
      setIsDebounceLoading(false)
    }
  }, 500 , { trailing: true }), [onLoad]);

  useEffect(() => {
    if (search !== '' && search.length >= min) {
      if (!noLoader) setIsDebounceLoading(true);
      makeDebouncedRequest(search);
    }
  }, [search]);

  const handleChange = useCallback((event: MouseEvent<HTMLElement>, item: Option) => {
    event.stopPropagation();
    if (onChange) {
      onChange(item.value);
      onChangeSearch(String(item.label));
    }
    setValue(`${name}-hidden`, item.value);
    if (required && item.value) clearErrors(`${name}-hidden`);
    onChangeShow(false);
  }, [onChangeShow, setValue, name, required, clearErrors, onChangeSearch, onChange]);

  const handleChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (onEnter) {
      onEnter(value);
      setValue(`${name}-hidden`, value);
      if (required && value) clearErrors(`${name}-hidden`);
    }
    if (value === '') {
      setValue(`${name}-hidden`, value);
      if (onChange) onChange('');
      if (required && value) clearErrors(`${name}-hidden`);
    }
    onChangeSearch(event.target.value);
  }, [onChangeSearch, onChange, setValue, name, clearErrors, required, onEnter]);

  const handleTrigger = useCallback(() => {
    if (disabled) return;
    onChangeShow(true);
  }, [onChangeShow, disabled]);

  const clear = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onChangeSearch('');
    setValue(`${name}-hidden`, '');
    if (onChange) onChange('');
  }, [onChangeSearch, setValue, name, onChange]);

  const list:Option[] = useMemo(() => {
    const preparedSearch = search.replace(/ /ig, '').toLowerCase();
    return options.filter((item: Option) => String(item.label).replace(/ /ig, '').toLowerCase().includes(preparedSearch));
  },[search, options]);

  const inputErrors = findInputError(formState.errors as RHErrors, `${name}-hidden`);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;

  const validation = register(`${name}-hidden`,
    {
      required: {value: Boolean(required), message: 'Field is required'},
      onChange: disabled ? () => {} : handleChangeSearch,
      value
    })
  const invalid = errorsList.length > 0;

  const showLoader = useMemo(() => {
    return show && (isLoading || isDebounceLoading)
  }, [show, isLoading, isDebounceLoading]);

  const showNoSuggestions = useMemo(() => {
    return show && !isLoading && !isDebounceLoading && !list.length
  }, [show, isLoading, isDebounceLoading, list]);

  const showData = useMemo(() => {
    return show && Boolean(list.length) && !isDebounceLoading && !isLoading;
  }, [show, list, isDebounceLoading, isLoading]);

  return (
    <Field help={help} helpDebounce={helpDebounce} className={className} required={required} label={label} hint={hint} errors={errorsList} name={name}>
      <div ref={input} className={cx(styles.selectWrapper, styles.withPreIcon, {[styles.withPostIcon]: clearable}, uniqueClassName)}>
        <input onFocus={handleTrigger} disabled={disabled} placeholder={placeholder} value={search}
               cypress-id={`${name}-autocomplete`}
               autoComplete="off"
               {...validation}
               className={cx(styles.input, styles.autocomplete, styles.select, inputClassName, {
                 [styles.hasError]: invalid,
                 [styles.disabled]: disabled,
                 [styles.open]: show
               })}/>
        <Fragment>
          {showLoader && (
            <ul className={cx(styles.list, styles.autocompleteList)}>
              <li role="button" className={cx(styles.selectItem, styles.emptyAutocompleteItem)}>
                Data is loading...
              </li>
            </ul>
          )}

          {showNoSuggestions && (
            <ul className={cx(styles.list, styles.autocompleteList)}>
              <li role="button" className={cx(styles.selectItem, styles.emptyAutocompleteItem)}>
                No suggestions...
              </li>
            </ul>
          )}

          {showData && (
            <ul className={cx(styles.list, styles.autocompleteList)}>
              {list.map((item: Option, idx: number) => (
                <li
                  role="button"
                  className={cx(styles.selectItem, { [styles.activeSelectItem]: item.value === value })}
                  key={idx}
                  onClick={(event) => handleChange(event, item)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </Fragment>
        <input type="hidden" value={value} />
        <Icon icon="search-lg" className={cx(styles.preicon, styles.icon)}/>
        {clearable && value && !disabled ? <Icon icon="x-close" onClick={clear} className={cx(styles.posticon, styles.icon)}/> : null}
      </div>
    </Field>
  )
}

export default Autocomplete;
