import { FC } from 'react';
import Typo from 'components/typo';
import styles from './subawards.module.css';
import GrantFormWrapper from 'pages/grant/common/wrapper';

const SubawardsInfo:FC = () => {
  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Subawards">
      <div>
        <Typo className={styles.text}>To make the process of completing this section easier, you can share a link to the
          form with a subrecipient. Please add a subaward to get the link. When the subrecipient completes the form, you
          will receive an email notification. The data will be automatically added to the specific subaward.</Typo>
        <Typo className={styles.text}>As the grantee and proposor, you have a responsibility of either creating a budget
          for your subrecipient or reviewing and ensuring that if your subrecipient creates their own budget, it adheres
          to NSF guidelines and is considered reasonable and allowable. For this reason, we highly recommend that you
          invite your subrecipient to draft their budget using Budget Mentor to ensure they meet the same guidelines you
          are adhering to.</Typo>
        <Typo className={styles.text}>Normally as a subreciepent of funds from an organization that isn't the NSF, it is
          unusual to have a subaward under a subaward. Please double-check with your NSF solicitation or other resources
          to confirm you can create your own subaward for your budget.</Typo>
      </div>
    </GrantFormWrapper>
  )
}

export default SubawardsInfo;
