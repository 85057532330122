import { Action } from 'store/types';
import I from 'seamless-immutable';
import { AuthState } from './types';
import {authStateAdapter, RateAdapter, RatesAdapter, userAdapter} from './adapters';
import {
  setUser,
  setToken,
  clearAuth, setRates, setPlansActions, updateRate, deleteRate, addRate,
} from './actions';

const initialStore = authStateAdapter();

const reducer = (state:AuthState = initialStore, action: Action) => {
  switch (action.type) {
    case setUser.type: {
      return state.set('user', userAdapter(action.payload));
    }
    case setToken.type: {
      return state.set('token', action.payload);
    }
    case setRates.type: {
      return state.set('rates', RatesAdapter(action.payload));
    }
    case updateRate.type: {
      return state.updateIn(['rates', 'data'], data => data.map((rate) => {
        if (rate.id === action.payload.id) return RateAdapter(action.payload);
        return rate;
      }));
    }
    case deleteRate.type: {
      return state
        .updateIn(['rates', 'data'], data => data.filter((rate) => rate.id !== action.payload));
    }
    case addRate.type: {
      return state
        // @ts-ignore
        .updateIn(['rates', 'data'], data => I([RateAdapter(action.payload)]).concat(data));
    }
    case clearAuth.type: {
      return state
        .set('user', userAdapter())
        .set('token', '');
    }
    case setPlansActions.type: {
      return state
        .set('plans', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
