import {FC, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useTravel from 'hooks/useTravel';
import useGrants from 'hooks/useGrants';
// types
import * as T from 'hooks/requestTypes';
// helpers
import {getTravelFromGrant} from 'helpers/getFromGrant';
import {stringOrUndefined} from 'helpers/date';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import TravelForm from './form';

import {ETravelType, Travel} from 'store/grants/types';
import {confirm} from "components/confirmation";

const toGrantPersonnelTypeConfirmationText = 'Two different templates are used to plan travel expenses for grantees and consultants. Some data may be lost. Please check all calculations for this trip. Travel expenses will be removed from the related consulting services';
const toConsultantTypeConfirmationText = 'Two different templates are used to plan travel expenses for grantees and consultants. Some data might be lost. Please check all calculations for this trip. Travel for consultants will not be added to the final form.';

type Props = {
  trip?: Travel
}

const CreateEditTravel:FC<Props> = ({trip}) => {
  const { update, errors, loading,
    trackError,
    trackExit,
    trackFormStarted } = useTravel({
    form_page_type:'Trip Info'
  });
  const { grant } = useGrants();

  const [ data, onChangeData ] = useState<T.CreateTravelData>({
    tripName: trip?.tripName ?? '',
    tripDescription: trip?.tripDescription ?? '',
    tripPurpose: trip?.tripPurpose ?? '',
    consultantServiceProviders: trip?.consultantServiceProviders ?? [],
    tripDefaultLocation: trip?.tripDefaultLocation ?? '',
    estimatedStartDate: trip?.estimatedStartDate ? new Date(trip.estimatedStartDate) : null,
    estimatedEndDate: trip?.estimatedEndDate ? new Date(trip.estimatedEndDate) : null,
    isConferenceTrip: trip?.isConferenceTrip,
    type: trip?.type ?? '',
    consultantServices: trip?.consultantServices ?? []
  });

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const handleChangeTripTypeData = useCallback((value: ETravelType) => {
    confirm({
      title: 'Trip template will be changed',
      text: value === ETravelType.Consultant ? toConsultantTypeConfirmationText : toGrantPersonnelTypeConfirmationText,
      type: 'success',
      icon: 'alert-hexagon',
      okText: 'Confirm',
      onConfirm: () => onChangeData(prev => ({
        ...prev,
        type: value,
        consultantServices: value === ETravelType.Consultant ? prev.consultantServices : []
      })),
    });
  }, [])

  const onSubmit = useCallback(() => {
    const submitData = {
      ...data,
      estimatedStartDate: stringOrUndefined(data.estimatedStartDate),
      estimatedEndDate: stringOrUndefined(data.estimatedEndDate),
    };
    trip?.id && update(grant.id, trip.id, submitData);
  }, [update, trip, grant, data]);

  return (
    <TravelForm
      isUpdate={Boolean(trip?.id)}
      grantId={grant.id}
      errors={errors} loading={loading}
      data={data}
      handleChangeTripTypeData={handleChangeTripTypeData}
      onChange={handleChangeData}
      onSubmit={onSubmit}
      trackError={trackError}
      trackExit={trackExit}
      trackFormStarted={trackFormStarted}
    />
  );
}

const CreateEditGrantPage:FC = () => {
  const params:Record<string, string> = useParams();
  const { grant } = useGrants();
  const { index, count, trip } = getTravelFromGrant(grant, params.travelId);

  const subtitle = `Trip ${ index > 0 ? index : count + 1}`

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      <CreateEditTravel trip={trip} key={`grant-${grant.id}-travel-${params.travelId ?? count}`} />
    </GrantFormWrapper>
  )
}

export default CreateEditGrantPage;
