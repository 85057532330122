import { FC } from 'react';
import Anchor from 'pages/grant/paper/card/anchor';
import useUrl from 'hooks/useUrl';
import Tip from 'components/tip';
import styles from './facilities.module.css';
import {FullGrant, SidebarTotals} from 'store/grants/types';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}
const Facilities:FC<Props> = ({ side }) => {
  const url = useUrl();

  const isActive = url.category === 'facilities' && url.subCategory === 'rates' && url.grantId === side.id;
  const disabled = side.years.length < 1;

  return (
    <Tip when={disabled} top text="To add rates, you must add grant general info">
      <Anchor thin className={styles.link} disabled={disabled} to={`/grants/${side.id}/facilities/rates`} active={isActive}>
        Rates info
      </Anchor>
    </Tip>
  );
}

export default Facilities;
