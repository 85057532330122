import {useCallback, useMemo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {confirm, ConfirmProps} from 'components/confirmation';
import {
  minimizedSidebarSelector,
  sidebarWidthSelector,
  loadingSelector,
  infoSidebarSelector,
  hiddenModalsSelector,
  prevUrlSelector,
  openedStepsSelector,
  mtdcGrantIdSelector,
  icGrantIdSelector,
  showNavigationMessageSelector, finalGrantIdSelector,
  budgetBarSelector,
  hiddenRatesInBarSelector,
  hiddenPromptSelector,
  paywallSelector,
  showSettingsModalSelector,
  showDisplayMessageSelector
} from 'store/ui/selector';
import {
  minimizeSidebarAction,
  maximizeSidebarAction,
  startLoadingAction,
  endLoadingAction,
  changeSidebarWidthAction,
  showInfoSidebarAction,
  hideInfoSidebarAction,
  addModalToHiddenModals,
  setPrevUrlAction,
  setICGrantId,
  setOpenedSteps,
  setMtdcGrantId,
  showNavigationMessageAction,
  hideNavigationMessageAction, setFinalGrantId,
  minimizeBudgetBarAction,
  addHiddenRate,
  maximizeBudgetBarAction,
  hidePromptAction,
  showPromptAction,
  showPaywallAction,
  showSettingsModalAction,
  hideSettingsModalAction,
  hidePaywallAction,
  hideDisplayMessageAction
} from 'store/ui/actions';
import {authUserSelector} from 'store/auth/selector';

const nsfCategoryInfoSectionItems = ['events', 'fees', 'costSharing'];
const nsfSubCategoryInfoSectionItems = [
  'materials-and-supplies',
  'publications',
  'consultantServices',
  'computer-services',
  'subawards',
  'contracts',
  'payments',
  'otherExpenses',
  'rates'
];

interface HideConfirmProps extends ConfirmProps {
  id: string;
  when: boolean;
}

type iUseUI  = {
  minimizedSidebar: boolean;
  minimizedBudgetBar: boolean;
  infoSidebar: boolean;
  showPaywall: boolean;
  mtdcGrantId: string;
  finalGrantId: string;
  changeMTDCGrantId: (grantId: string) => void;
  indirectCostGrantId: string;
  changeICGrantId: (grantId: string) => void;
  changeFinalGrantId: (grantId: string) => void;
  hideInfoSidebar: () => void;
  showInfoSidebar: () => void;
  onShowPaywall: () => void;
  onHidePaywall: () => void;
  getInfoSectionLocation: (pathname: string, category?: string, subCategory?: string) => string;
  onChangeOpenedSteps: (list: number[]) => void;
  maximizeSidebar: () => void;
  confirmWithHide: (props: HideConfirmProps) => void;
  minimizeSidebar: () => void;
  changeSidebarWidth: (width: number) => void;
  sidebarWidth: number;
  openedSteps: number[];
  hiddenRatesInBar: string[];
  loader: {progress: boolean; start: () => void; stop: () => void};
  prevUrl: string;
  showNavigationMessage: boolean;
  onShowNavigationMessage: () => void;
  addHiddenRate: (id: string) => void;
  onHideNavigationMessage: () => void;
  minimizeBudgetBar: () => void;
  maximizeBudgetBar: () => void;
  onShowSettingsModal: () => void;
  onHideSettingsModal: () => void;
  hidePrompt: boolean;
  showSettingsModal: boolean;
  onHidePrompt: () => void;
  onShowPrompt: () => void;
  onHideDisplayMessage: () => void;
  showDisplayMessage: boolean;
  savePreviousPage: (saveUrl: string, isIgnoreRouteChanging: boolean) => void;
}

const useUI = ():iUseUI => {
  const dispatch = useDispatch();
  const minimizedSidebar = useSelector(minimizedSidebarSelector);
  const sidebarWidth = useSelector(sidebarWidthSelector);
  const loading = useSelector(loadingSelector);
  const hiddenModals = useSelector(hiddenModalsSelector);
  const infoSidebar = useSelector(infoSidebarSelector);
  const prevUrl = useSelector(prevUrlSelector);
  const user = useSelector(authUserSelector);
  const openedSteps = useSelector(openedStepsSelector);
  const mtdcGrantId = useSelector(mtdcGrantIdSelector);
  const finalGrantId = useSelector(finalGrantIdSelector);
  const showNavigationMessage = useSelector(showNavigationMessageSelector);
  const indirectCostGrantId = useSelector(icGrantIdSelector);
  const minimizedBudgetBar = useSelector(budgetBarSelector);
  const hiddenRatesInBar = useSelector(hiddenRatesInBarSelector);
  const hidePrompt = useSelector(hiddenPromptSelector);
  const showPaywall = useSelector(paywallSelector);
  const showDisplayMessage = useSelector(showDisplayMessageSelector);
  const showSettingsModal = useSelector(showSettingsModalSelector);

  const minimizeSidebar = useCallback(() => {
    dispatch(minimizeSidebarAction());
    dispatch(changeSidebarWidthAction(82));
  }, [dispatch]);

  const maximizeSidebar = useCallback(() => {
    dispatch(maximizeSidebarAction());
    dispatch(changeSidebarWidthAction(448));
  }, [dispatch]);

  const getInfoSectionLocation = useCallback((pathname: string, category: string = '', subCategory: string = '') => {
    if (nsfCategoryInfoSectionItems.includes(category)) {
      return pathname + '?info-section=nsf'
    }
    if (nsfSubCategoryInfoSectionItems.includes(subCategory)) {
      return pathname + '?info-section=nsf'
    }
    return pathname
  }, [])

  const changeSidebarWidth = useCallback((width: number) => {
    const newWidth = width < 82 ? 82 : width;
    dispatch(newWidth < 220 ? minimizeSidebarAction() : maximizeSidebarAction());
    dispatch(changeSidebarWidthAction(newWidth > 448 ? 448 : newWidth));
  }, [dispatch]);

  const hideInfoSidebar = useCallback(() => {
    dispatch(hideInfoSidebarAction());
  }, [dispatch]);
  const showInfoSidebar = useCallback(() => {
    dispatch(showInfoSidebarAction());
  }, [dispatch]);


  const loader = useMemo(() => ({
    progress: loading,
    start: () => dispatch(startLoadingAction()),
    stop: () => dispatch(endLoadingAction()),
  }), [loading, dispatch]);

  const confirmWithHide = useCallback((props: HideConfirmProps) => {
    if (props.id && !hiddenModals.includes(props.id) && props.when) {
      confirm({
        ...props,
        label: 'Don’t show again',
        hide: true,
        onConfirm: (value?: boolean) => {
          if (value) dispatch(addModalToHiddenModals(props.id));
          props.onConfirm();
        }
      });
      return;
    }
    props.onConfirm();
  }, [dispatch, hiddenModals]);

  const savePreviousPage = useCallback((saveUrl: string, isIgnoreRouteChanging: boolean) => {
    if (!isIgnoreRouteChanging) {
      dispatch(setPrevUrlAction(saveUrl))
    }
  }, [dispatch]);

  const onChangeOpenedSteps = useCallback((list: number[]) => {
    dispatch(setOpenedSteps(list));
  },[dispatch]);


  const changeMTDCGrantId = useCallback((grantId: string) => {
    dispatch(setMtdcGrantId(grantId));
    if (grantId !== '') {
      dispatch(setICGrantId(''));
      dispatch(setFinalGrantId(''));
    }
  }, [dispatch]);


  const changeICGrantId = useCallback((grantId: string) => {
    dispatch(setICGrantId(grantId));
    if (grantId !== '') {
      dispatch(setMtdcGrantId(''));
      dispatch(setFinalGrantId(''));
    }
  }, [dispatch]);

  const changeFinalGrantId = useCallback((grantId: string) => {
    dispatch(setFinalGrantId(grantId));
    if (grantId !== '') {
      dispatch(setMtdcGrantId(''));
      dispatch(setICGrantId(''));
    }
  }, [dispatch]);

  const onShowNavigationMessage = useCallback(() => {
    if (!user.profile.doShowHints) return;
    dispatch(showNavigationMessageAction());
  }, [dispatch, user]);

  const onHideNavigationMessage = useCallback(() => {
    if (!user.profile.doShowHints) return;
    dispatch(hideNavigationMessageAction());
  }, [dispatch, user]);

  const minimizeBudgetBar = useCallback(() => {
    dispatch(minimizeBudgetBarAction());
  }, [dispatch]);

  const maximizeBudgetBar = useCallback(() => {
    dispatch(maximizeBudgetBarAction());
  }, [dispatch]);

  const addHiddenRateInBar = useCallback((id: string) => {
    dispatch(addHiddenRate(id));
  }, [dispatch]);

  const onHidePrompt = useCallback(() => {
    dispatch(hidePromptAction());
  }, [dispatch]);

  const onShowPrompt = useCallback(() => {
    dispatch(showPromptAction());
  }, [dispatch]);

  const onShowPaywall = useCallback(() => {
    dispatch(showPaywallAction());
  }, [dispatch]);

  const onHidePaywall = useCallback(() => {
    dispatch(hidePaywallAction());
  }, [dispatch]);

  const onShowSettingsModal = useCallback(() => {
    dispatch(showSettingsModalAction());
  }, [dispatch]);

  const onHideSettingsModal = useCallback(() => {
    dispatch(hideSettingsModalAction());
  }, [dispatch]);

  const onHideDisplayMessage = useCallback(() => {
    dispatch(hideDisplayMessageAction());
  }, [dispatch]);

  return {
    hidePrompt,
    onHidePrompt,
    onShowPaywall,
    onHidePaywall,
    onShowSettingsModal,
    onHideSettingsModal,
    onShowPrompt,
    minimizedSidebar,
    addHiddenRate: addHiddenRateInBar,
    loader,
    indirectCostGrantId,
    changeICGrantId,
    changeFinalGrantId,
    minimizeBudgetBar,
    maximizeBudgetBar,
    maximizeSidebar,
    confirmWithHide,
    showSettingsModal,
    showNavigationMessage,
    onShowNavigationMessage,
    onHideNavigationMessage,
    infoSidebar,
    openedSteps,
    changeMTDCGrantId,
    onChangeOpenedSteps,
    hideInfoSidebar,
    showInfoSidebar,
    getInfoSectionLocation,
    minimizeSidebar,
    finalGrantId,
    minimizedBudgetBar,
    hiddenRatesInBar,
    showDisplayMessage,
    onHideDisplayMessage,
    mtdcGrantId,
    showPaywall,
    changeSidebarWidth,
    sidebarWidth,
    prevUrl,
    savePreviousPage
  };
}

export default useUI;
