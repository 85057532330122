import {FC, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
// types
import {FullGrant, Travel, TravelCostData,} from 'store/grants/types';
// components
import GroupedByCostKindList from 'pages/grant/paper/card/folders/travel/components/groupedByCostKindList';
import Typo from 'components/typo';
import CreateBtn from 'pages/grant/paper/card/create';
import Folder from 'pages/grant/paper/card/folder';
// styles
import styles from '../travel.module.css';
import {TotalAnchor} from '../../../anchor';

type Props = {
  side: FullGrant;
  trip: Travel;
  costs: Array<TravelCostData>;
  handleRemoveCost: (id: string) => void;
};

const travelCostEnums = [
  {label: '48 States', value: 'fourty8states'},
  {label: 'Alaska, Hawaii', value: 'alaskaHawaii'},
  {label: 'International', value: 'international'},
  {label: 'Other', value: 'other'},
];

const CostList: FC<Props> = ({side, trip, costs, handleRemoveCost}) => {
  const url = useUrl();

  const groupedByPlaceLists = useMemo(() => {
    return travelCostEnums.map((item, idx) => {
      return {
        ...item,
        listId: `${idx}_${item.value}`,
        items: costs.filter((i) => i.kind === item.value),
      };
    });
  }, [costs]);

  const folderData = useMemo(() => ({
    label: `Travel costs`,
    type: 'cost',
  }), []);

  return (
    <Folder type="section" grantId={side.id} icon item={folderData}>
      <>
        {costs.length > 0 ? (
          <>
            {groupedByPlaceLists.map((list) => {
              return (
                <GroupedByCostKindList
                  key={list.listId}
                  side={side}
                  trip={trip}
                  list={list}
                  handleRemoveCost={handleRemoveCost}
                />
              );
            })}

            <TotalAnchor total={trip.travelCostsTotalAmount} to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/cost/totals`} active={url.categoryId === trip.id && url.sectionId === 'totals' && url.section === 'cost'}>
              Totals. All costs
            </TotalAnchor>
          </>
        ) : (
          <Typo className={styles.placeholder} size={14} type="div">
            No travel costs have been created yet
          </Typo>
        )}
      </>
      <CreateBtn to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/cost/create`}
                 name={`travel-${trip.id}-add-travel-cost`} side={side}
                 tip="To add a cost, you must save general info first"
      >
        Add travel cost
      </CreateBtn>
    </Folder>
  );
};

export default CostList;
