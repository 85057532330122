import {FC, useEffect, useMemo} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import {
  GrantEventParticipantTotalYear, GrantEventTotal,
  GrantEventTotalEvent, GrantEventTotalEventYear
} from 'store/grants/types';
import Table, { Cell } from 'components/table';
import {numberToMoney} from 'helpers/numbers';
import styles from './totals.module.css';
import useUI from 'hooks/useUI';
import Spinner from 'components/spinner';
import useParticipants from 'hooks/useParticipants';


type Props = {
  events: GrantEventTotal[];
}

type ListItem = {
  title: string;
  headers: string[];
  data: Array<Cell[]>;
}

const prepareCells = (totalEvent: GrantEventTotalEvent, maxYearsEvent: GrantEventParticipantTotalYear[]):Cell[] => {
  const labels = maxYearsEvent.map((item:GrantEventParticipantTotalYear) => item.label);
  return labels.map((item: string) => {
    const year = totalEvent.years.find((year: GrantEventParticipantTotalYear) => (year.label === item));
    return (<span className={styles.gray}>{year?.value ? numberToMoney(year.value) : '-'}</span>)
  });
}

const prepareTable = (item: GrantEventTotal):ListItem => {
  const [maxYearsEvent] = item.events.sort((a: GrantEventTotalEvent, b: GrantEventTotalEvent) => {
    if (a.years.length > b.years.length) return -1;
    if (a.years.length < b.years.length) return 1;
    return 0;
  });
  const years = maxYearsEvent.years.map((year: GrantEventTotalEventYear) => year.label);
  return {
    title: item.category ?? 'No category yet',
    headers: ['Event', ...years, 'Total'],
    data: item.events.map((event: GrantEventTotalEvent) => ([
      event.fullName ?? 'No name',
      ...prepareCells(event, maxYearsEvent.years),
      numberToMoney(event.totals)
    ]))
  }
}

const EventsTotals:FC<Props> = ({events}) => {

  const list:ListItem[] = useMemo(() => {
    return events.map(prepareTable)
  }, [events]);

  return (
    <div className={styles.tables}>
      {list.map((table: ListItem, idx: number) => (
        <Table className={styles.table} title={table.title} headers={table.headers} data={table.data} key={idx}/>
      ))}
    </div>
  );
}


const EventsTotalsPage:FC = () => {
  const {
    getTotals,
    totals,
    loading,
} = useParticipants();
  const params:Record<string, any> = useParams();
  const { sidebarWidth } = useUI();

  const key = `${params.id}-events-totals`;

  useEffect(() => {
    if (params.id) {
      getTotals(params.id);
    }
  }, [])


  return (
    <GrantFormWrapper title="Participant Support. All events" key={key} subtitle="Totals">
      <>
        <EventsTotals events={totals} />
        {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
      </>
    </GrantFormWrapper>
  );
}

export default EventsTotalsPage;
