import {FC} from 'react';
import {Route} from 'react-router-dom';
// components
import CostSharingGeneralInfo from './info';
import CostSharingItemPage from './costSharingExpense';
import CostSharingItemYearPage from './costSharingExpense/year';
import CostSharingTotal from './totals';

const CostSharingIndex: FC = () => {

  return (
    <>
      <Route
        path="/grants/:id/costSharing/general-info"
        exact
        component={CostSharingGeneralInfo}
      />
      <Route
        path="/grants/:id/costSharing/:expenseId/edit"
        exact
        component={CostSharingItemPage}
      />
      <Route
        path="/grants/:id/costSharing/:expenseId/year/:yearId"
        exact
        component={CostSharingItemYearPage}
      />
      <Route
        path="/grants/:id/costSharing/totals"
        exact
        component={CostSharingTotal}
      />
    </>
  )
}

export default CostSharingIndex;
