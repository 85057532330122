import { FC } from 'react';
import { Route } from 'react-router-dom';
import PaymentsInfo from './info';
import PaymentPage from './payment';
import PaymentYearPage from './payment/year';
import PaymentsTotals from './totals';

const ComputerServicesIndex:FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/payments/general-info"
        exact
        component={PaymentsInfo}
      />
      <Route
        path="/grants/:id/otherDC/payments/:paymentId/edit"
        exact
        component={PaymentPage}
      />
      <Route
        path="/grants/:id/otherDC/payments/:paymentId/year/:year"
        exact
        component={PaymentYearPage}
      />
      <Route
        path="/grants/:id/otherDC/payments/totals"
        exact
        component={PaymentsTotals}
      />
    </>
  )
}

export default ComputerServicesIndex;
