import {FC} from 'react';
import {Route} from 'react-router-dom';
// components
import PublicationsInfo from './info';
import PublicationsUnitPage from './unit';
import PublicationsYearPage from './unit/year';
import PublicationsTotals from './totals';

const PublicationIndex: FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/publications/general-info"
        exact
        component={PublicationsInfo}
      />
      <Route
        path="/grants/:id/otherDC/publications/:unitId/edit"
        exact
        component={PublicationsUnitPage}
      />
      <Route
        path="/grants/:id/otherDC/publications/:unitId/year/:yearId"
        exact
        component={PublicationsYearPage}
      />
      <Route
        path="/grants/:id/otherDC/publications/totals"
        exact
        component={PublicationsTotals}
      />
    </>
  )
}

export default PublicationIndex;
