import I from 'seamless-immutable';
import { EnumsState, Enums } from './types';

export const enumsStateAdapter = (data?: Enums):EnumsState => {
 const airports = localStorage.getItem('bm_enum_airports');
 const airlines = localStorage.getItem('bm_enum_airlines');
 if (data) {
  return I({
   systemStatus: data.systemStatus,
   gender: [{value: null, label: 'Select gender'}, ...data.gender],
   race: [{value: null, label: 'Select race'}, ...data.race],
   role: data.race,
   annualSchedule: [{value: null, label: 'Select schedule'}, ...data.annualSchedule],
   nsfDerectorate: data.nsfDerectorate,
   FARateLocations: data.FARateLocations,
   grantType: data.grantType,
   eligibility: data.eligibility,
   grantStep: data.grantStep,
   travelExpensesTypes: data.travelExpensesTypes,
   airports: airports ? JSON.parse(airports) : [],
   airlines: airlines ? JSON.parse(airlines) : [],
   transportationMethod: data.transportationMethod,
   grantStatus: data.grantStatus,
   roleInGrant: data.roleInGrant,
   consultantServiceProviderTypes: data.consultantServiceProviderTypes,
   nsfCompensation: data.nsfCompensation,
   consultantServiceProviderTravelCostsTypes: data.consultantServiceProviderTravelCostsTypes,
   travelTypes: data.travelTypes,
   jobCategory: {
    academicResearchAssociate: data.jobCategory?.academicResearchAssociate ?? [],
    civilService: data.jobCategory?.civilService ?? [],
   },
   travelCostsTypes: data.travelCostsTypes,
   FARateStatuses: data.FARateStatuses,
   costSharingExpenseTypes: data.costSharingExpenseTypes,
   stipendAwardPeriod: data.stipendAwardPeriod,
   civilServiceCompensation: data.civilServiceCompensation,
  });
 }
 return I({
  systemStatus: [],
  gender: [{value: null, label: 'Select gender'}],
  nsfDerectorate: [],
  FARateLocations: [],
  grantType: [],
  eligibility: [],
  travelExpensesTypes: [],
  travelTypes: [],
  airports: airports ? JSON.parse(airports) : [],
  airlines: airlines ? JSON.parse(airlines) : [],
  transportationMethod: [],
  grantStep: [],
  grantStatus: [],
  consultantServiceProviderTypes: [],
  consultantServiceProviderTravelCostsTypes: [],
  nsfCompensation: [],
  roleInGrant: [],
  jobCategory: {
   academicResearchAssociate: [],
   civilService: [],
  },
  travelCostsTypes: [],
  FARateStatuses: [],
  costSharingExpenseTypes: [],
  stipendAwardPeriod: [],
  civilServiceCompensation: [],
  role: [],
  race: [{value: null, label: 'Select race'}],
  annualSchedule: [{value: null, label: 'Select schedule'}],
 });
}
