import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import Car from './car';
import Taxi from './taxi';
import Plane from './plane';
import Rideshare from './rideshare';
import Subway from './subway';
import Other from './other';
import Boat from './boat';
import Charter from './charter';
import Train from './train';

export interface Props {
  data: T.TransportationMethodCommonData;
  details: T.TransportationMethodPlainDetails;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
  onChangeDetails: (field: Record<string, any>, preview?: boolean) => void;
  onSubmit: () => void;
  onBlur: () => void;
  loading: boolean;
  participant?: boolean;
  isUpdate?: boolean;
  errors: Errors;
}

export default {
  Car,
  Taxi,
  Plane,
  Rideshare,
  Subway,
  Other,
  Boat,
  Charter,
  Train,
}
