import axios, { AxiosResponse, AxiosRequestConfig, GenericAbortSignal } from 'axios';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import client, { downloadClient } from 'helpers/apiClient';
import { authTokenSelector } from 'store/auth/selector';
import * as T from './requestTypes';
import { clearAuth } from 'store/auth/actions';
import { clearAll } from 'store/grants/actions';
// types
// Materials Supplies
import {
  MaterialsSuppliesUnitPostRequestType,
  MaterialsSuppliesUnitYearPostRequestType,
  MaterialsSuppliesGeneralInfoRequestType,
} from './requestTypes/grant/otherDirectCosts/materialsAndSupplies/types';
// Publications
import {
  PublicationsEmptyUnit,
  PublicationsUnit
} from './requestTypes/grant/otherDirectCosts/publications/types';
// Other Expenses
import {
  OtherExpenseRequestType,
  EmptyOtherExpenseRequestType
} from './requestTypes/grant/otherDirectCosts/otherExpenses/types';
// Consultant Services
import {
  ConsultantServiceType,
  EmptyConsultantServiceType, ForeignConsultantInfoType
} from './requestTypes/grant/otherDirectCosts/consultantServices/types';
// Fees
import {
  FeeItemType,
  EmptyFeeItemType
} from './requestTypes/grant/fees/types';
// Cost Sharing
import {EmptyCostSharingExpenseItemType, CostSharingExpenseItemType} from './requestTypes/grant/costSharing/types';
import {ConsultantServiceFormType} from '../store/grants/otherDirectCosts/ConsultantServices/types';

type iUseApi = {
  healthCheck: () => Promise<AxiosResponse>;
  getEnums: () => Promise<AxiosResponse>;
  login: (data: T.LoginData) => Promise<AxiosResponse>;
  getMSAuthLink: () => Promise<AxiosResponse>;
  socialLogin: (type: 'ms' | 'google', data: Record<string, string>) => Promise<AxiosResponse>;
  forgot: (data: T.ForgotData) => Promise<AxiosResponse>;
  reset: (data: T.ResetPasswordData) => Promise<AxiosResponse>;
  register: (data: T.RegisterData) => Promise<AxiosResponse>;
  validateRegister: (data: T.ValidateRegisterData) => Promise<AxiosResponse>;
  resendEmail: (data: T.ResendEmail) => Promise<AxiosResponse>;
  confirmEmail: (data: T.ConfirmEmail) => Promise<AxiosResponse>;
  me: (token?: string) => Promise<AxiosResponse>;
  uploadProfileImage: (data: FormData, config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  upload: (url: string, data: File, config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  updateMe: (data: T.UpdateUserInfo | T.OrganizationUserData) => Promise<AxiosResponse>;
  updatePassword: (data: T.UpdatePassword) => Promise<AxiosResponse>;
  organizations: (name: string) => Promise<AxiosResponse>;
  fetchOrganizations: (name: string) => Promise<AxiosResponse>;
  getGrants: (query?: string) => Promise<AxiosResponse>;
  grantPreview: (q: string) => Promise<AxiosResponse>;
  getGrantsStats: (search?: string) => Promise<AxiosResponse>;
  createGrant: () => Promise<AxiosResponse>;
  getOneGrant: (id: string) => Promise<AxiosResponse>;
  duplicateGrant: (id: string) => Promise<AxiosResponse>;
  removeGrant: (id: string) => Promise<AxiosResponse>;
  updateGrantSettings: (id: string, data: any) => Promise<AxiosResponse>;
  changeGrantStep: (id: string, step: string, data?: T.ChangeStepData) => Promise<AxiosResponse>;
  getDashboard: () => Promise<AxiosResponse>;
  addSeniorPerson: (grantId: string, data: T.Person) => Promise<AxiosResponse>;
  addAcademicResearchPerson: (grantId: string, data: T.Person) => Promise<AxiosResponse>;
  editSeniorPerson: (grantId: string, personId: string, data: T.Person | T.PersonNSFCompensationUpdate) => Promise<AxiosResponse>;
  seniorPersonPreview: (grantId: string, q: string) => Promise<AxiosResponse>;
  editAcademicResearchPerson: (grantId: string, personId: string, data: T.Person | T.PersonNSFCompensationUpdate) => Promise<AxiosResponse>;
  academicResearchPersonPreview: (grantId: string, q: string) => Promise<AxiosResponse>;
  calculatePercentCompensation: (grantId: string, type: string, q: string) => Promise<AxiosResponse>;
  calculateMonthCompensation: (grantId: string, type: string, q: string) => Promise<AxiosResponse>;
  getPersonalTotals: (grantId: string, type: string) => Promise<AxiosResponse>;
  deletePerson: (grantId: string, type: string, personId: string) => Promise<AxiosResponse>;
  addPostDoctoralPersonnel: (grantId: string, data: any) => Promise<AxiosResponse>;
  updatePostDoctoralPersonnel: (grantId: string, personId: string, data: any) => Promise<AxiosResponse>;
  calculatePostDoc: (grantId: string, personId: string, q: string) => Promise<AxiosResponse>;
  calculateGraduatedStudentWithTerm: (grantId: string, q: string) => Promise<AxiosResponse>;
  calculateGraduatedStudentWithAnnual: (grantId: string, q: string) => Promise<AxiosResponse>;
  calculateUnderGraduatedStudentWithAnnual: (grantId: string, q: string) => Promise<AxiosResponse>;
  calculateUnderGraduatedStudentSummer: (grantId: string, q: string) => Promise<AxiosResponse>;
  updateAnnualInfoGraduatedStudent: (grantId: string, data: any) => Promise<AxiosResponse>;
  updatePostDocNsf: (grantId: string, personId: string, data: T.PostDocNsf) => Promise<AxiosResponse>;
  fetchZIP: (zip: string) => Promise<AxiosResponse>;
  deleteUnderGraduatedStudents: (grantId: string) => Promise<AxiosResponse>;
  deleteGraduatedStudents: (grantId: string) => Promise<AxiosResponse>;
  updateUnderGraduateNsfCompensation: (grantId: string, data: any) => Promise<AxiosResponse>;
  addStudent: (grantId: string, type: string, data: any) => Promise<AxiosResponse>;
  updateStudent: (grantId: string, type: string, data: any) => Promise<AxiosResponse>;
  requestSupport: (data: T.RequestSupportData) => Promise<AxiosResponse>;
  getOtherCalculate: (grantId: string, type: string, q: string) => Promise<AxiosResponse>;
  updateOtherNSF: (grantId: string, type: string, personId: string, data: T.OtherNSFRequestData) => Promise<AxiosResponse>;
  addOtherPersonnel: (grantId: string, type: string, data: T.OtherPerson) => Promise<AxiosResponse>;
  updateOtherPersonnel: (grantId: string, type: string, personId: string, data: T.OtherPerson) => Promise<AxiosResponse>;
  addEquipment: (grantId: string, data: T.EquipmentData) => Promise<AxiosResponse>;
  updateEquipment: (grantId: string, unitId: string, data: T.EquipmentData) => Promise<AxiosResponse>;
  getEquipmentCategories: (name: string) => Promise<AxiosResponse>;
  getTravelCostCategories: (name: string) => Promise<AxiosResponse>;
  getTotalsForAllTrips: (grantId: string) => Promise<AxiosResponse>;
  getTravelTotals: (grantId: string, id: string) => Promise<AxiosResponse>;
  getTravelCostTotals: (grantId: string, id: string) => Promise<AxiosResponse>;
  getTravelMethodsTotals: (grantId: string, id: string) => Promise<AxiosResponse>;
  deleteEquipment: (grantId: string, unitId: string) => Promise<AxiosResponse>;
  getEquipmentCalculations: (grantId: string, equipmentId: string, q: string) => Promise<AxiosResponse>;
  updateEquipmentNSFCompensation: (grantId: string, equipmentId: string, data: T.EquipNsfCompensation) => Promise<AxiosResponse>;
  equipmentTotals: (grantId: string) => Promise<AxiosResponse>;
  createTravel: (grantId: string, data: T.EmptyTravelData) => Promise<AxiosResponse>;
  duplicateTravel: (grantId: string, travelId: string) => Promise<AxiosResponse>;
  updateTravel: (grantId: string, travelId: string, data: T.CreateTravelData | T.StatusTravelData) => Promise<AxiosResponse>;
  removeTravel: (grantId: string, travelId: string) => Promise<AxiosResponse>;
  addTransportationMethod: (grantId: string, travelId: string, method: string, data: T.TransportationMethodData) => Promise<AxiosResponse>;
  removeTransportationMethod: (grantId: string, travelId: string, methodId: string) => Promise<AxiosResponse>;
  getAirports: () => Promise<AxiosResponse>;
  updateTransportationMethod: (grantId: string, travelId: string, methodId: string, data: T.TransportationMethodData) => Promise<AxiosResponse>;
  getAirlines: () => Promise<AxiosResponse>;
  previewTransportationMethod: (grantId: string, travelId: string, data: T.TransportationMethodData) => Promise<AxiosResponse>;
  getTransportationMethodTotals: (grantId: string, travelId: string) => Promise<AxiosResponse>;
  addTravelCost: (grantId: string, travelId: string, kind: string, data: T.TravelCostData) => Promise<AxiosResponse>;
  getTravelCost: (grantId: string, travelId: string, costId: string) => Promise<AxiosResponse>;
  previewTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => Promise<AxiosResponse>;
  previewOnCreateTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => Promise<AxiosResponse>;
  updateTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => Promise<AxiosResponse>;
  removeTravelCost: (grantId: string, travelId: string, costId: string) => Promise<AxiosResponse>;
  addGrantEvent: (grantId: string) => Promise<AxiosResponse>;
  getGrantEventTotals: (grantId: string) => Promise<AxiosResponse>;
  getEventTotals: (grantId: string, eventId: string) => Promise<AxiosResponse>;
  addGrantEventParticipant: (grantId: string, eventId: string, data: any) => Promise<AxiosResponse>;
  updateGrantEventParticipant: (grantId: string, eventId: string, participantId: string, data: T.UpdateGrantEventParticipant) => Promise<AxiosResponse>;
  updateGrantEvent: (grantId: string, eventId: string, data: T.GrantEvent) => Promise<AxiosResponse>;
  deleteGrantEvent: (grantId: string, eventId: string) => Promise<AxiosResponse>;
  deleteGrantEventParticipant: (grantId: string, eventId: string, participantId: string) => Promise<AxiosResponse>;
  addGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string) => Promise<AxiosResponse>;
  addGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string) => Promise<AxiosResponse>;
  previewParticipant: (grantId: string, eventId: string, q: string) => Promise<AxiosResponse>;
  deleteGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, methodId: string) => Promise<AxiosResponse>;
  deleteGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, expenseId: string) => Promise<AxiosResponse>;
  updateGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, methodId: string, data:  any) => Promise<AxiosResponse>;
  updateGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, methodId: string, data:  any) => Promise<AxiosResponse>;
  previewGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, data: any) => Promise<AxiosResponse>;
  getGrantEventCategories: (name: string) => Promise<AxiosResponse>;
  getGrantEventParticipantCategories: (name: string) => Promise<AxiosResponse>;
  // MATERIALS AND SUPPLIES
  addMaterialsSuppliesGeneralInfo: (grantId: string, data: MaterialsSuppliesGeneralInfoRequestType) => Promise<AxiosResponse>;
  updateMaterialsSuppliesGeneralInfo: (grantId: string, data: MaterialsSuppliesGeneralInfoRequestType) => Promise<AxiosResponse>;
  addMaterialsSuppliesUnit: (grantId: string, data: MaterialsSuppliesUnitPostRequestType) => Promise<AxiosResponse>;
  updateMaterialsSuppliesUnit: (grantId: string, unitId: string, data: MaterialsSuppliesUnitPostRequestType) => Promise<AxiosResponse>;
  deleteMaterialsSuppliesUnit: (grantId: string, unitId: string) => Promise<AxiosResponse>;
  updateMaterialsSuppliesUnitYear: (grantId: string, unitId: string, serialNumber: string, data: MaterialsSuppliesUnitYearPostRequestType) => Promise<AxiosResponse>;
  previewMaterialsSuppliesUnitYear: (grantId: string, unitId: string, serialNumber: string, q: string) => Promise<AxiosResponse>;
  getMaterialsSuppliesTotals: (grantId: string) => Promise<AxiosResponse>;
  // ODC PUBLICATIONS
  addPublicationsUnit: (grantId: string, data: PublicationsEmptyUnit) => Promise<AxiosResponse>;
  updatePublicationsUnit: (grantId: string, unitId: string, data: PublicationsUnit) => Promise<AxiosResponse>;
  deletePublicationsUnit: (grantId: string, unitId: string) => Promise<AxiosResponse>;
  previewPublicationsUnitYear: (grantId: string, unitId: string, q: string) => Promise<AxiosResponse>;
  getPublicationsTotals: (grantId: string) => Promise<AxiosResponse>;
  // ODC OTHER EXPENSES
  addOtherExpense: (grantId: string, data: EmptyOtherExpenseRequestType) => Promise<AxiosResponse>;
  updateOtherExpense: (grantId: string, expenseId: string, data: OtherExpenseRequestType) => Promise<AxiosResponse>;
  deleteOtherExpense: (grantId: string, expenseId: string) => Promise<AxiosResponse>;
  previewOtherExpenseYear: (grantId: string, expenseId: string, q: string) => Promise<AxiosResponse>;
  getOtherExpensesTotals: (grantId: string) => Promise<AxiosResponse>;
  //ODC COMPUTER SERVICES
  addODCComputerService: (grantId: string, data: T.ODCCSInfo) => Promise<AxiosResponse>;
  ODCComputerServiceTotals: (grantId: string) => Promise<AxiosResponse>;
  updateODCComputerService: (grantId: string, data: T.ODCCSInfo) => Promise<AxiosResponse>;
  addServiceODCCS: (grantId: string) => Promise<AxiosResponse>;
  updateServiceODCCS: (grantId: string, id: string, data: T.ODCCSServiceData) => Promise<AxiosResponse>;
  previewServiceODCCS: (grantId: string, id: string, q: string) => Promise<AxiosResponse>;
  deleteServiceODCCS: (grantId: string, id: string) => Promise<AxiosResponse>;
  //ODC CONTRACTS
  addContract: (grantId: string, data: T.ContractCreate) => Promise<AxiosResponse>;
  updateContract: (grantId: string, contractId: string, data: T.ContractCreate) => Promise<AxiosResponse>;
  deleteContract: (grantId: string, contractId: string) => Promise<AxiosResponse>;
  previewContractYear: (grantId: string, contractId: string, q: string) => Promise<AxiosResponse>;
  contractsTotals: (grantId: string) => Promise<AxiosResponse>;

  //ODC PAYMENTS
  createODCPayment: (grantId: string, data: T.ODCPaymentCreate) => Promise<AxiosResponse>;
  deletePaymentODC: (grantId: string, paymentId: string) => Promise<AxiosResponse>;
  updatePaymentODC: (grantId: string, paymentId: string, data: T.ODCPaymentUpdate) => Promise<AxiosResponse>;
  previewYearPaymentODC: (grantId: string, paymentId: string, q: string) => Promise<AxiosResponse>;
  paymentODCTotals: (grantId: string) => Promise<AxiosResponse>;

  // ODC SUBAWARDS
  createSubaward: (grantId: string, data: any) => Promise<AxiosResponse>;
  deleteSubaward: (grantId: string, id: string) => Promise<AxiosResponse>;
  updateSubaward: (grantId: string, id: string, data: T.Subaward) => Promise<AxiosResponse>;
  getSubawardFormLink: (grantId: string, id: string) => Promise<AxiosResponse>;
  getPublicSubaward: (token: string) => Promise<AxiosResponse>;
  getPrivateSubaward: (grantId: string) => Promise<AxiosResponse>;
  subawardeesTotals: (grantId: string) => Promise<AxiosResponse>;
  updatePublicSubaward: (token: string, data: any) => Promise<AxiosResponse>;

  // ODC CONSULTANT SERVICES
  addConsultantServiceItem: (grantId: string, data: EmptyConsultantServiceType) => Promise<AxiosResponse>;
  updateConsultantServiceItem: (grantId: string, serviceId: string, data: ConsultantServiceFormType) => Promise<AxiosResponse>;
  updateForeignConsultantInfoItem: (grantId: string, serviceId: string, data: ForeignConsultantInfoType) => Promise<AxiosResponse>;
  deleteConsultantServiceItem: (grantId: string, serviceId: string) => Promise<AxiosResponse>;
  getConsultantServiceTotals: (grantId: string) => Promise<AxiosResponse>;
  previewConsultantServiceData: (grantId: string, data: any) => Promise<AxiosResponse>;
  addConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, data: any) => Promise<AxiosResponse>;
  updateConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string, data: any) => Promise<AxiosResponse>;
  deleteConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string) => Promise<AxiosResponse>;
  getConsultantTotals: (grantId: string) => Promise<AxiosResponse>;

  // FEES
  addFeeItem: (grantId: string, data: EmptyFeeItemType) => Promise<AxiosResponse>;
  updateFeeItem: (grantId: string, feeId: string, data: FeeItemType) => Promise<AxiosResponse>;
  deleteFeeItem: (grantId: string, feeId: string) => Promise<AxiosResponse>;
  previewFeeItemYear: (grantId: string, feeId: string, q: string) => Promise<AxiosResponse>;
  getFeesTotals: (grantId: string) => Promise<AxiosResponse>;

  // COST SHARING
  addCostSharingItem: (grantId: string, data: EmptyCostSharingExpenseItemType) => Promise<AxiosResponse>;
  updateCostSharingItem: (grantId: string, expenseId: string, data: CostSharingExpenseItemType) => Promise<AxiosResponse>;
  deleteCostSharingItem: (grantId: string, expenseId: string) => Promise<AxiosResponse>;
  previewCostSharingItemYear: (grantId: string, expenseId: string, q: string) => Promise<AxiosResponse>;
  getCostSharingTotals: (grantId: string) => Promise<AxiosResponse>;

  //RATES
  createUserRate: (data: T.RateData) => Promise<AxiosResponse>;
  createGrantRate: (grantId: string, data: T.RateData) => Promise<AxiosResponse>;
  previewGrantRate: (grantId: string, q: string) => Promise<AxiosResponse>;
  updateUserRate: (id: string, data: T.RateData) => Promise<AxiosResponse>;
  updateGrantRate: (grantId: string, id: string, data: T.RateData) => Promise<AxiosResponse>;
  deleteUserRate: (id: string) => Promise<AxiosResponse>;
  deleteGrantRate: (grantId: string, id: string) => Promise<AxiosResponse>;
  getUserRates: (q?: string) => Promise<AxiosResponse>;
  addUserRatesToGrant: (grantId: string, data: T.UserRateGrants) => Promise<AxiosResponse>;
  getGrantRates: (grantId: string) => Promise<AxiosResponse>;

  //MTDC
  getGrantMTDC: (id: string) => Promise<AxiosResponse>;
  previewGrantMTDC: (id: string, data: any) => Promise<AxiosResponse>;
  updateGrantMTDC: (id: string, data: any) => Promise<AxiosResponse>;

  //INDIRECT COSTS
  getIndirectCosts: (grantId: string) => Promise<AxiosResponse>;
  previewIndirectCosts: (id: string, data: any, signal?: GenericAbortSignal) => Promise<AxiosResponse>;
  updateIndirectCosts: (id: string, data: any) => Promise<AxiosResponse>;

  // FINAL FORM
  getFinalForm: (grantId: string) => Promise<AxiosResponse>;
  exportFinalForm: (grantId: string, type: T.FinalFormExportType) => Promise<AxiosResponse>;
  exportFinalFormCSV: (grantId: string) => Promise<AxiosResponse>;
  exportSummaryFinalForm: (grantId: string, type: T.FinalFormExportType) => Promise<AxiosResponse>;
  exportSummaryFinalFormCSV: (grantId: string) => Promise<AxiosResponse>;

  // BILLING
  getPlans: () => Promise<AxiosResponse>;
  checkout: (productId: string) => Promise<AxiosResponse>;
  changePlan: (productId: string) => Promise<AxiosResponse>;
  managePlan: () => Promise<AxiosResponse>;
  getAwardedGrant: () => Promise<AxiosResponse>;
  noticeConfirmPayment: () => Promise<AxiosResponse>;
  getSidebar: (grantId: string) => Promise<AxiosResponse>;
}

const useApi = (): iUseApi => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector(authTokenSelector);
  client.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const status = error.response?.status;
    if (status === 401) {
      dispatch(clearAuth());
      dispatch(clearAll());
      localStorage.clear();
      sessionStorage.clear();
      history.push('/login');
    }
    return Promise.reject(error);
  });
  //@ts-ignore
  client.defaults.headers = {
    Authorization: token ? `Bearer ${token}` : null
  };
  //@ts-ignore
  downloadClient.defaults.headers = {
    Authorization: token ? `Bearer ${token}` : null
  };
  return {
    healthCheck: () => client.get('/health-check'),
    requestSupport: (data: T.RequestSupportData) => client.post('/support-requests', data),
    getEnums: () => client.get('/app-data/enums'),
    fetchOrganizations: (where: string) => axios.get(`https://parseapi.back4app.com/classes/Usuniversitieslist_University?count=1&limit=30&order=name&include=state&keys=name,state,state.name&where=${where}`, {
      headers: {
        'X-Parse-Application-Id': process.env.REACT_APP_ORGANIZATIONS_APP_ID,
        'X-Parse-REST-API-Key': process.env.REACT_APP_ORGANIZATIONS_APIKEY,
      }
    }),
    upload: (url: string, file: File, config?: AxiosRequestConfig) => axios.put(url, file, config),
    fetchZIP: (zip: string) => axios.get(`https://www.zipcodeapi.com/rest/${process.env.REACT_APP_ZIPCODE_API}/info.json/${zip}`),

    //AIRLABS
    getAirports: () => axios.get(`https://airlabs.co/api/v9/airports?api_key=${process.env.REACT_APP_AIRCARRIER_API}`),
    getAirlines: () => axios.get(`https://airlabs.co/api/v9/airlines?api_key=${process.env.REACT_APP_AIRCARRIER_API}`),

    // AUTH
    login: (data: T.LoginData) => client.post('/auth/email/login', data),
    socialLogin: (type: 'ms' | 'google', data: Record<string, string>) =>  client.post(`/auth/${type}/login`, data),
    getMSAuthLink: () =>  client.get(`/auth/ms/auth-url`),
    forgot: (data: T.ForgotData) => client.post('/auth/forgot/password', data),
    reset: (data: T.ResetPasswordData) => client.post('/auth/reset/password', data),
    register: (data: T.RegisterData) => client.post('/auth/email/register', data),
    validateRegister: (data: T.ValidateRegisterData) => client.post(`/auth/email/register/validate`, data),
    resendEmail: (data: T.ResendEmail) => client.post('/auth/email/confirm/resend', data),
    confirmEmail: (data: T.ConfirmEmail) => client.post('/auth/email/confirm', data),
    me: (token?: string) => client.get('/auth/me', token ? {
      headers: {
        Authorization: token ? `Bearer ${token}` : null
      }
    } : undefined),
    updateMe: (data: T.UpdateUserInfo | T.OrganizationUserData) => client.patch('/auth/me', data),
    updatePassword: (data: T.UpdatePassword) => client.patch('/auth/me', data),
    uploadProfileImage: (data: FormData, config?: AxiosRequestConfig) => client.post('/auth/me/profile-image-signed-url', data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),

    // ORGANIZATIONS
    organizations: (name: string) => client.get(`/organizations?name=${name}`),

    //DASHBOARD
    getDashboard: () => client.get('/me/dashboard'),

    //GRANTS
    getGrants: (query?: string) => client.get(`/me/grants${query || ''}`),
    grantPreview: (q: string) => client.get(`/me/grants/preview?${q || ''}`),
    getGrantsStats: (query?: string) => client.get(`/me/grants/stats${query || ''}`),
    createGrant: () => client.post('/me/grants'),
    getOneGrant: (id: string) => client.get(`/me/grants/${id}`),
    duplicateGrant: (id: string) => client.post(`/me/grants/${id}`),
    removeGrant: (id: string) => client.delete(`/me/grants/${id}`),
    changeGrantStep: (id: string, step: string, data?: T.ChangeStepData) => client.patch(`/me/grants/${id}/${step}`, data),
    updateGrantSettings: (id: string, data: any) => client.patch(`/me/grants/${id}/settings`, data),

    //PERSONNEL
    calculatePercentCompensation: (grantId: string, type: string, q: string) => client.get(`me/grants/${grantId}/${type}/calculate-percent?${q}`),
    calculateMonthCompensation: (grantId: string, type: string, q: string) => client.get(`/me/grants/${grantId}/${type}/calculate-months?${q}`),
    deletePerson: (grantId: string, type: string, personId: string) => client.delete(`me/grants/${grantId}/${type}/${personId}`),
    getPersonalTotals: (grantId: string, type: string) => client.get(`/me/grants/${grantId}/${type}/totals`),
    //SENIOR
    addSeniorPerson: (grantId: string, data: T.Person) => client.post(`/me/grants/${grantId}/senior-personal`, data),
    seniorPersonPreview: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/senior-personal/preview?${q}`),
    editSeniorPerson: (grantId: string, personId: string, data: T.Person | T.PersonNSFCompensationUpdate) => client.patch(`/me/grants/${grantId}/senior-personal/${personId}`, data),
    //ACADEMIC RESEARCH
    addAcademicResearchPerson: (grantId: string, data: T.Person) => client.post(`/me/grants/${grantId}/academic-research-personal`, data),
    academicResearchPersonPreview: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/academic-research-personal/preview?${q}`),
    editAcademicResearchPerson: (grantId: string, personId: string, data: T.Person | T.PersonNSFCompensationUpdate) => client.patch(`/me/grants/${grantId}/academic-research-personal/${personId}`, data),

    //GRADUATED/UNDERGRADUATED STUDENTS
    calculateGraduatedStudentWithTerm: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/graduate-students/calculate/academic-term?${q}`),
    calculateGraduatedStudentWithAnnual: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/graduate-students/calculate/annual?${q}`),
    updateAnnualInfoGraduatedStudent: (grantId: string, data: any) => client.put(`/me/grants/${grantId}/graduate-students/annual-info`, data),
    calculateUnderGraduatedStudentWithAnnual: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/undergraduate-students/calculate/academic-term?${q}`),
    calculateUnderGraduatedStudentSummer: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/undergraduate-students/calculate/summer?${q}`),
    updateUnderGraduateNsfCompensation: (grantId: string, data: any) => client.put(`/me/grants/${grantId}/undergraduate-students/nsf-compensation`, data),
    deleteUnderGraduatedStudents: (grantId: string) => client.delete(`/me/grants/${grantId}/undergraduate-students`),
    deleteGraduatedStudents: (grantId: string) => client.delete(`/me/grants/${grantId}/graduate-students`),
    //POST DOCS
    addPostDoctoralPersonnel: (grantId: string, data: T.CreatePostDoctorPersonnelData) => client.post(`/me/grants/${grantId}/post-doc`, data),
    updatePostDoctoralPersonnel: (grantId: string, personId: string, data: T.CreatePostDoctorPersonnelData) => client.patch(`/me/grants/${grantId}/post-doc/${personId}`, data),
    calculatePostDoc: (grantId: string, personId: string, q: string) => client.get(`/me/grants/${grantId}/post-doc/${personId}/calculate?${q}`),
    updatePostDocNsf: (grantId: string, personId: string, data: T.PostDocNsf) => client.put(`/me/grants/${grantId}/post-doc/${personId}/nsf-compensation`, data),

    //STUDENTS
    addStudent: (grantId: string, type: string, data: any) => client.post(`/me/grants/${grantId}/${type}`, data),
    updateStudent: (grantId: string, type: string, data: any) => client.patch(`/me/grants/${grantId}/${type}`, data),

    //OTHER PERSONNEL
    addOtherPersonnel: (grantId: string, type: string, data: T.OtherPerson) => client.post(`/me/grants/${grantId}/${type}`, data),
    updateOtherPersonnel: (grantId: string, type: string, personId: string, data: T.OtherPerson) => client.patch(`/me/grants/${grantId}/${type}/${personId}`, data),
    getOtherCalculate: (grantId: string, type: string, q: string) => client.get(`/me/grants/${grantId}/${type}/calculate?${q}`),
    updateOtherNSF: (grantId: string, type: string, personId: string, data: T.OtherNSFRequestData) => client.put(`/me/grants/${grantId}/${type}/${personId}/nsf-compensation`, data),


    //EQUIPMENT
    addEquipment: (grantId: string, data: T.EquipmentData) => client.post(`/me/grants/${grantId}/equipment`, data),
    updateEquipment: (grantId: string, unitId: string, data: T.EquipmentData) => client.patch(`/me/grants/${grantId}/equipment/${unitId}`, data),
    deleteEquipment: (grantId: string, unitId: string) => client.delete(`/me/grants/${grantId}/equipment/${unitId}`),
    getEquipmentCategories: (name: string) => client.get(`/grants/equipment/categories?name=${name}`),
    getEquipmentCalculations: (grantId: string, equipmentId: string, q: string) => client.get(`/me/grants/${grantId}/equipment/${equipmentId}/calculate?${q}`),
    updateEquipmentNSFCompensation: (grantId: string, equipmentId: string, data: T.EquipNsfCompensation) => client.put(`/me/grants/${grantId}/equipment/${equipmentId}/nsf-compensation`, data),
    equipmentTotals: (grantId: string) => client.get(`/me/grants/${grantId}/equipment/totals`),

    // TRAVEL
    createTravel: (grantId: string, data: T.EmptyTravelData) => client.post(`/me/grants/${grantId}/travel`, data),
    duplicateTravel: (grantId: string, travelId: string) => client.post(`/me/grants/${grantId}/travel/${travelId}/duplicate`),
    updateTravel: (grantId: string, travelId: string, data: T.CreateTravelData | T.StatusTravelData) => client.patch(`/me/grants/${grantId}/travel/${travelId}`, data),
    removeTravel: (grantId: string, travelId: string) => client.delete(`/me/grants/${grantId}/travel/${travelId}`),
    addTransportationMethod: (grantId: string, travelId: string, method: string, data: T.TransportationMethodData) => client.post(`/me/grants/${grantId}/travel/${travelId}/method/${method}`, data),
    updateTransportationMethod: (grantId: string, travelId: string, methodId: string, data: T.TransportationMethodData) => client.put(`/me/grants/${grantId}/travel/${travelId}/method/${methodId}`, data),
    removeTransportationMethod: (grantId: string, travelId: string, methodId: string) => client.delete(`/me/grants/${grantId}/travel/${travelId}/method/${methodId}`),
    previewTransportationMethod: (grantId: string, travelId: string, data: T.TransportationMethodData) => client.post(`/me/grants/${grantId}/travel/${travelId}/method/preview`, data),
    getTransportationMethodTotals: (grantId: string, travelId: string) => client.get(`/me/grants/${grantId}/travel/${travelId}/method/totals`),
    addTravelCost: (grantId: string, travelId: string, kind: string, data: T.TravelCostData) => client.post(`/me/grants/${grantId}/travel/${travelId}/costs/${kind}`, data),
    updateTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => client.put(`/me/grants/${grantId}/travel/${travelId}/costs/${costId}`, data),
    previewTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => client.post(`/me/grants/${grantId}/travel/${travelId}/costs/preview/${costId}`, data),
    previewOnCreateTravelCost: (grantId: string, travelId: string, costId: string, data: T.TravelCostData) => client.post(`/me/grants/${grantId}/travel/${travelId}/costs/preview/${costId}`, data),
    removeTravelCost: (grantId: string, travelId: string, costId: string) => client.delete(`/me/grants/${grantId}/travel/${travelId}/costs/${costId}`),
    getTravelCost: (grantId: string, travelId: string, costId: string) => client.get(`/me/grants/${grantId}/travel/${travelId}/costs/${costId}`),
    getTravelCostCategories: (name: string) => client.get(`/grants/travel/costs/other/categories?name=${name}`),
    getTravelTotals: (grantId: string, id: string) => client.get(`/me/grants/${grantId}/travel/${id}/totals`),
    getTravelCostTotals: (grantId: string, id: string) => client.get(`/me/grants/${grantId}/travel/${id}/costs/totals`),
    getTravelMethodsTotals: (grantId: string, id: string) => client.get(`/me/grants/${grantId}/travel/${id}/method/totals`),
    getTotalsForAllTrips: (grantId: string) => client.get(`me/grants/${grantId}/travel/totals/costs-and-methods/domestic-and-foreign`),
    //EVENTS
    addGrantEvent: (grantId: string) => client.post(`/me/grants/${grantId}/events`),
    getGrantEventTotals: (grantId: string) => client.get(`/me/grants/${grantId}/events/totals`),
    getEventTotals: (grantId: string, eventId: string) => client.get(`/me/grants/${grantId}/events/${eventId}/totals`),
    updateGrantEvent: (grantId: string, eventId: string, data: T.GrantEvent) => client.patch(`/me/grants/${grantId}/events/${eventId}`, data),
    addGrantEventParticipant: (grantId: string, eventId: string, data: Record<'category', string>) => client.post(`/me/grants/${grantId}/events/${eventId}/participants`, data),
    updateGrantEventParticipant: (grantId: string, eventId: string, participantId: string, data: T.UpdateGrantEventParticipant) => client.put(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}`, data),
    deleteGrantEvent: (grantId: string, eventId: string) => client.delete(`/me/grants/${grantId}/events/${eventId}`),
    getGrantEventCategories: (name: string) => client.get(`/grants/event-categories?name=${name}`),
    getGrantEventParticipantCategories: (name: string) => client.get(`/grants/events/participant-categories?name=${name}`),
    deleteGrantEventParticipant: (grantId: string, eventId: string, participantId: string) => client.delete(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}`),
    addGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string) => client.post(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/travel-methods`),
    previewParticipant: (grantId: string, eventId: string, q: string) => client.get(`/me/grants/${grantId}/events/${eventId}/participants/preview/year${q}`),
    deleteGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, methodId: string) => client.delete(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/travel-methods/${methodId}`),
    updateGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, methodId: string, data: any) => client.patch(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/travel-methods/${methodId}`, data),
    addGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string) => client.post(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/expenses`),
    deleteGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, expenseId: string) => client.delete(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/expenses/${expenseId}`),
    updateGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, expenseId: string, data: any) => client.patch(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/expenses/${expenseId}`, data),
    previewGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, data: any) => client.post(`/me/grants/${grantId}/events/${eventId}/participants/${participantId}/expenses/preview`, data),

    // ODC Materials and Supplies
    addMaterialsSuppliesGeneralInfo: (grantId: string, data: MaterialsSuppliesGeneralInfoRequestType) => client.post(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies`, data),
    updateMaterialsSuppliesGeneralInfo: (grantId: string, data: MaterialsSuppliesGeneralInfoRequestType) => client.patch(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies`, data),
    addMaterialsSuppliesUnit: (grantId: string, data: MaterialsSuppliesUnitPostRequestType) => client.post(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies/units`, data),
    updateMaterialsSuppliesUnit: (grantId: string, unitId: string, data: MaterialsSuppliesUnitPostRequestType) => client.patch(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies/units/${unitId}`, data),
    deleteMaterialsSuppliesUnit: (grantId: string, unitId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies/units/${unitId}`),
    updateMaterialsSuppliesUnitYear: (grantId: string, unitId: string, serialNumber: string, data: MaterialsSuppliesUnitYearPostRequestType) => client.patch(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies/units/${unitId}/years/${serialNumber}`, data),
    getMaterialsSuppliesTotals: (grantId: string) => client.get(`me/grants/${grantId}/other-direct-costs/materials-n-supplies/totals`),
    previewMaterialsSuppliesUnitYear: (grantId: string, unitId: string, serialNumber: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/materials-n-supplies/units/${unitId}/years/${serialNumber}/preview${q}`),

    //ODC Computer services
    addODCComputerService: (grantId: string, data: T.ODCCSInfo) => client.post(`/me/grants/${grantId}/other-direct-costs/computer-services`, data),
    ODCComputerServiceTotals: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/computer-services/totals`),
    updateODCComputerService: (grantId: string, data: T.ODCCSInfo) => client.patch(`/me/grants/${grantId}/other-direct-costs/computer-services`, data),
    addServiceODCCS: (grantId: string) => client.post(`/me/grants/${grantId}/other-direct-costs/computer-services/services`),
    updateServiceODCCS: (grantId: string, id: string, data: T.ODCCSServiceData) => client.patch(`/me/grants/${grantId}/other-direct-costs/computer-services/services/${id}`, data),
    previewServiceODCCS: (grantId: string, id: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/computer-services/services/${id}/years/preview${q}`),
    deleteServiceODCCS: (grantId: string, id: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/computer-services/services/${id}`),

    // ODC Publications
    addPublicationsUnit: (grantId: string, data) => client.post(`/me/grants/${grantId}/other-direct-costs/publications/units`, data),
    updatePublicationsUnit: (grantId: string, unitId: string, data: PublicationsUnit) => client.patch(`/me/grants/${grantId}/other-direct-costs/publications/units/${unitId}`, data),
    deletePublicationsUnit: (grantId: string, unitId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/publications/units/${unitId}`),
    getPublicationsTotals: (grantId: string) => client.get(`me/grants/${grantId}/other-direct-costs/publications/totals`),
    previewPublicationsUnitYear: (grantId: string, unitId: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/publications/units/${unitId}/years/preview${q}`),

    // ODC Other Expenses
    addOtherExpense: (grantId: string, data) => client.post(`/me/grants/${grantId}/other-direct-costs/all-other-costs/expenses`, data),
    updateOtherExpense: (grantId: string, expenseId: string, data: OtherExpenseRequestType) => client.patch(`/me/grants/${grantId}/other-direct-costs/all-other-costs/expenses/${expenseId}`, data),
    deleteOtherExpense: (grantId: string, expenseId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/all-other-costs/expenses/${expenseId}`),
    getOtherExpensesTotals: (grantId: string) => client.get(`me/grants/${grantId}/other-direct-costs/all-other-costs/totals`),
    previewOtherExpenseYear: (grantId: string, expenseId: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/all-other-costs/expenses/${expenseId}/years/preview${q}`),

    //ODC CONTRACTS
    addContract: (grantId: string, data: T.ContractCreate) => client.post(`me/grants/${grantId}/other-direct-costs/contracts`, data),
    updateContract: (grantId: string, contractId: string, data: T.ContractCreate) => client.patch(`me/grants/${grantId}/other-direct-costs/contracts/${contractId}`, data),
    deleteContract: (grantId: string, contractId: string) => client.delete(`me/grants/${grantId}/other-direct-costs/contracts/${contractId}`),
    previewContractYear: (grantId: string, contractId: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/contracts/${contractId}/year/preview${q}`),
    contractsTotals: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/contracts/totals`),

    //ODC PAYMENTS
    createODCPayment: (grantId: string, data: T.ODCPaymentCreate) => client.post(`/me/grants/${grantId}/other-direct-costs/incentive-payments`, data),
    deletePaymentODC: (grantId: string, paymentId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/incentive-payments/${paymentId}`),
    updatePaymentODC: (grantId: string, paymentId: string, data: T.ODCPaymentUpdate) => client.patch(`/me/grants/${grantId}/other-direct-costs/incentive-payments/${paymentId}`, data),
    previewYearPaymentODC: (grantId: string, paymentId: string, q: string) => client.get(`/me/grants/${grantId}/other-direct-costs/incentive-payments/${paymentId}/year/preview${q}`),
    paymentODCTotals: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/incentive-payments/totals`),

    // ODC SUBAWARDS
    createSubaward: (grantId: string, data: any) => client.post(`/me/grants/${grantId}/other-direct-costs/subawards`, data),
    deleteSubaward: (grantId: string, id: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/subawards/${id}`),
    updateSubaward: (grantId: string, id: string, data: T.Subaward) => client.patch(`/me/grants/${grantId}/other-direct-costs/subawards/${id}`, data),
    getSubawardFormLink: (grantId: string, id: string) => client.get(`/me/grants/${grantId}/other-direct-costs/subawards/${id}/completion-link`),
    getPublicSubaward: (token: string) => client.get(`/subawards/preview/${token}`),
    getPrivateSubaward: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/subawards/subawardee-form/preview`),
    subawardeesTotals: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/subawards/totals`),
    updatePublicSubaward: (token: string, data: any) => client.put(`/subawards/${token}`, data),

    // ODC CONSULTANT SERVICES
    addConsultantServiceItem: (grantId: string, data: EmptyConsultantServiceType) => client.post(`/me/grants/${grantId}/other-direct-costs/consultant-services`, data),
    updateConsultantServiceItem: (grantId: string, serviceId: string, data: ConsultantServiceFormType) => client.patch(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}`, data),
    updateForeignConsultantInfoItem: (grantId: string, serviceId: string, data: ForeignConsultantInfoType) => client.patch(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}/foreign-consultant-info`, data),
    deleteConsultantServiceItem: (grantId: string, serviceId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}`),
    getConsultantServiceTotals: (grantId: string) => client.get(`me/grants/${grantId}/other-direct-costs/consultant-services/totals`),
    previewConsultantServiceData: (grantId: string, data: any) => client.post(`/me/grants/${grantId}/other-direct-costs/consultant-services/years/providers/preview`, data),
    addConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, data: any) => client.post(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}/years/${serialNumber}/providers`, data),
    updateConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string, data: any) => client.patch(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}/years/${serialNumber}/providers/${providerId}`, data),
    deleteConsultantServiceProvider: (grantId: string, serviceId: string, serialNumber: string | number, providerId: string) => client.delete(`/me/grants/${grantId}/other-direct-costs/consultant-services/${serviceId}/years/${serialNumber}/providers/${providerId}`),
    getConsultantTotals: (grantId: string) => client.get(`/me/grants/${grantId}/other-direct-costs/consultant-services/totals`),

    // FEES
    addFeeItem: (grantId: string, data: EmptyFeeItemType) => client.post(`/me/grants/${grantId}/fees`, data),
    updateFeeItem: (grantId: string, feeId: string, data: FeeItemType) => client.patch(`/me/grants/${grantId}/fees/${feeId}`, data),
    deleteFeeItem: (grantId: string, feeId: string) => client.delete(`/me/grants/${grantId}/fees/${feeId}`),
    getFeesTotals: (grantId: string) => client.get(`me/grants/${grantId}/fees/totals`),
    previewFeeItemYear: (grantId: string, feeId: string, q: string) => client.get(`/me/grants/${grantId}/fees/${feeId}/years/preview${q}`),

    // COST SHARING
    addCostSharingItem: (grantId: string, data: EmptyCostSharingExpenseItemType) => client.post(`/me/grants/${grantId}/cost-sharing-expenses`, data),
    updateCostSharingItem: (grantId: string, expenseId: string, data: CostSharingExpenseItemType) => client.patch(`/me/grants/${grantId}/cost-sharing-expenses/${expenseId}`, data),
    deleteCostSharingItem: (grantId: string, expenseId: string) => client.delete(`/me/grants/${grantId}/cost-sharing-expenses/${expenseId}`),
    getCostSharingTotals: (grantId: string) => client.get(`me/grants/${grantId}/cost-sharing-expenses/totals`),
    previewCostSharingItemYear: (grantId: string, expenseId: string, q: string) => client.get(`/me/grants/${grantId}/cost-sharing-expenses/${expenseId}/years/preview${q}`),

    //RATES
    createUserRate: (data: T.RateData) => client.post(`/me/fa-rates`, data),
    createGrantRate: (grantId: string, data: T.RateData) => client.post(`/me/grants/${grantId}/fa-rates`, data),
    updateUserRate: (id: string, data: T.RateData) => client.patch(`/me/fa-rates/${id}`, data),
    updateGrantRate: (grantId: string, id: string, data: T.RateData) => client.patch(`/me/grants/${grantId}/fa-rates/${id}`, data),
    addUserRatesToGrant: (grantId: string, data: T.UserRateGrants) => client.post(`/me/grants/${grantId}/fa-rates/user-rates`, data),
    deleteUserRate: (id: string) => client.delete(`/me/fa-rates/${id}`),
    deleteGrantRate: (grantId: string, id: string) => client.delete(`/me/grants/${grantId}/fa-rates/${id}`),
    previewGrantRate: (grantId: string, q: string) => client.get(`/me/grants/${grantId}/fa-rates/preview/timeline${q}`),
    getUserRates: (q?: string) => client.get(`/me/fa-rates${q ? q : ''}`),
    getGrantRates: (grantId: string) => client.get(`/me/grants/${grantId}/fa-rates`),

    //MTDC
    getGrantMTDC: (grantId: string) => client.get(`/me/grants/${grantId}/mtdc`),
    previewGrantMTDC: (grantId: string, data: any) => client.post(`/me/grants/${grantId}/mtdc/preview`, data),
    updateGrantMTDC: (grantId: string, data: any) => client.patch(`/me/grants/${grantId}/mtdc/flags`, data),

    //INDIRECT COSTS
    getIndirectCosts: (grantId: string) => client.get(`/me/grants/${grantId}/indirect-costs`),
    updateIndirectCosts: (grantId: string, data: any) => client.patch(`/me/grants/${grantId}/indirect-costs/override`, data),
    previewIndirectCosts: (grantId: string, data: any, signal?: GenericAbortSignal) => client.post(`/me/grants/${grantId}/indirect-costs/preview`, data, { signal }),

    //FINAL FORM
    getFinalForm: (grantId: string) => client.get(`/me/grants/${grantId}/final-form`),
    exportFinalForm: (grantId: string, type: T.FinalFormExportType) => downloadClient.get(`/me/grants/${grantId}/final-form/${type}`, {responseType: 'blob'}),
    exportFinalFormCSV: (grantId: string) => downloadClient.get(`/me/grants/${grantId}/final-form/csv`),
    exportSummaryFinalForm: (grantId: string, type: T.FinalFormExportType) => downloadClient.get(`/me/grants/${grantId}/proposal-budget/${type}`, {responseType: 'blob'}),
    exportSummaryFinalFormCSV: (grantId: string) => downloadClient.get(`/me/grants/${grantId}/proposal-budget/csv`),

    //BILLING
    getPlans: () => client.get('/usage-plans'),
    checkout: (productId: string) => client.post(`/me/usage-plans/checkout`, { productId }),
    changePlan: (productId: string) => client.put(`/me/usage-plans/change`, { productId }),
    managePlan: () => client.get(`/me/usage-plans/manage`),
    getAwardedGrant: () => client.get(`/me/dashboard/awarded-grant`),
    noticeConfirmPayment: () => client.post(`/me/usage-plans/notice-confirm`),

    getSidebar: (grantId: string) => client.get(`/me/grants/${grantId}/sidebar`)
  }
}
export default useApi;
