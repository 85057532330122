import {FC, Children, ReactElement, useMemo} from 'react';
import cx from 'classnames';
import styles from './field.module.css';

type Props = {
  last?: boolean;
  full?: boolean;
  top?: boolean;
  id?: string;
  withHelp?: boolean;
  refProp?: any;
  className?: string;
  children: ReactElement | ReactElement[] | any;
}

const FormField:FC<Props> = ({last, refProp, id, top, withHelp, className, full, children}) => {
  const wrapperClassList = useMemo(() => {
    return cx(styles.field, className, {
      [styles.last]: last,
      [styles.top]: top,
      [styles.fieldWithHelps]: withHelp,
      [styles.twoInputFields]: Children.count(children) === 2,
      [styles.threeInputFields]: Children.count(children) === 3,
      [styles.fourInputFields]: Children.count(children) === 4,
    })
  }, [last, top, className, children, withHelp]);

  return (
    <div className={wrapperClassList} ref={refProp} id={id}>
      {Children.map(children, (item: ReactElement, idx: number) => (
        <div className={cx(styles.input, {[styles.full]: full})} key={idx}>
          {item}
        </div>
      ))}
    </div>
  )
}

export default FormField;
