import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useGrants from 'hooks/useGrants';
import useCostSharing from 'hooks/useCostSharing';
// helpers
import {getCostSharingExpenseFromGrant} from 'helpers/getFromGrant';
// types
import {
  CostSharingExpenseItemType,
  CostSharingExpenseYearType
} from 'store/grants/costSharing/types';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Textarea} from 'components/form';
// styles
import styles from '../costSharing.module.css';

type Props = {
  item: CostSharingExpenseYearType;
  expense: CostSharingExpenseItemType;
  grantId: string;
};

const totalCostAfterInflationHelpText = 'Inflation does not apply. You can change it in the Information tab';
const totalCostAfterInflationFirstYearHelpText = 'Inflation is not applied in Year 1 for any costs, but will be' +
  ' applied in subsequent years.'

const CostSharingExpenseYearForm: FC<Props> = ({item, expense, grantId}) => {
  const [data, onChangeData] = useState<CostSharingExpenseYearType>(item);
  const {
    isLoading, updateExpense, previewExpenseYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useCostSharing({
    form_page_type: 'Date'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    if (expense.id && data.expense !== '') {
      previewExpenseYear(grantId, expense.id, {
        expense: data.expense,
        serialNumber: data.serialNumber
      }, (response: any) => {
        onChangeData({
          ...data,
          compensationAfterInflation: response.compensationAfterInflation,
          compensationBeforeInflation: response.compensationBeforeInflation,
        });
      })
    }
  }, [previewExpenseYear, expense.id, grantId, data]);

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const onSubmit = useCallback(() => {
    const newYears = expense.years.map((year: CostSharingExpenseYearType) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...data,
          expense: Number(data.expense),
          compensationBeforeInflation: Number(data.compensationBeforeInflation),
          compensationAfterInflation: Number(data.compensationAfterInflation),
        }
      }
      return year;
    });
    updateExpense(grantId, expense.id, {
      ...expense,
      years: newYears
    })
  }, [data, item, expense, updateExpense, grantId]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input
          name="expense"
          label={`Expense for Year ${data.serialNumber}`}
          placeholder="Enter amount"
          className={styles.input}
          onBlur={onBlur}
          value={data.expense}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({expense: event.target.value})
          }
          type="number"
          money
        />
      </Field>
      <Field>
        <Input
          name="compensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          value={data.compensationBeforeInflation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({compensationBeforeInflation: event.target.value})
          }
          type="number"
          readOnly
          disabled
        />
        <Input
          name="compensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${data.serialNumber}`}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.compensationAfterInflation}
          type="number"
          help={
            !expense.isInflationApplied
              ? totalCostAfterInflationHelpText
              : data.serialNumber === 1
                ? totalCostAfterInflationFirstYearHelpText
                : ""
          }
          readOnly
          disabled
        />
      </Field>
      <Field last full>
        <Textarea
          name="costJustification"
          label="Justification for this cost"
          placeholder="Enter a description..."
          value={data.costJustification}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeData({costJustification: event.target.value})
          }
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={isLoading} name="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

const CostSharingItemYearPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getCostSharingExpenseFromGrant(grant, params.expenseId);
  const year = item?.years[Number(params.yearId)];

  return (
    <GrantFormWrapper
      title="Cost sharing"
      subtitle={(item?.name || `Untitled expense ${index}`) + `. Year ${Number(params.yearId) + 1}`}
    >
      {year ? (
        <CostSharingExpenseYearForm
          grantId={grant.id}
          expense={item}
          key={`${grant.id}-costSharing-${params.expenseId}-${params.yearId}`}
          item={year}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
};

export default CostSharingItemYearPage;
