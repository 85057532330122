import { FC, useEffect, useMemo } from 'react';
import Typo from 'components/typo';
import { sortBy } from 'lodash';
import Table, { Cell } from 'components/table';
import styles from './total.module.css';
import { ListItem } from 'helpers/utils';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {numberToMoney} from 'helpers/numbers';
import useTravel, { Total } from 'hooks/useTravel';
import {useParams} from 'react-router-dom';
import GrantFormWrapper from '../../../../common/wrapper';
import cx from 'classnames';
import {getTotalsTableData} from '../../../../../../helpers/table';


const prepareHeaders = (items: Total[] = []) => {
  const [yearsItem] = sortBy(items, (item: Total) => item.years.length).reverse();
  const years = yearsItem?.years.map((item: ListItem) => item.label);
  return [
    'Transportation methods', 'Expense name',
    ...(years ? years.map((_item: string, idx: number) => `Year ${idx + 1}` ) : []),
    'Total'
  ];
}

const prepareData = (items: Total[] = []):Array<Cell[]> => {
  return items.map((item: Total) => {
    const [yearsItem] = sortBy(items, (item: Total) => item.years.length).reverse();
    const keys = yearsItem.years.map((yearItem: ListItem) => yearItem.label)
    const yearsValues = keys.map((key: string) => {
      const currentYear:ListItem | undefined = item.years.find((item: ListItem) => item.label === key);
      return <span className={styles.gray}>{numberToMoney(currentYear?.value)}</span>
    })
    return [
      <span className={styles.capitalize}>{item.method}</span>,
      item.name,
      ...(yearsValues ?? []),
      numberToMoney(item.total || item.totals)
    ]
  });
}

const TotalsPage:FC = () => {
  const { sidebarWidth } = useUI();
  const params:Record<string, string> = useParams();
  const { getTransportationMethodsTotals, transportationTotals, loading } = useTravel();

  useEffect(() => {
    if (params.id && params.travelId) {
      getTransportationMethodsTotals(params.id, params.travelId);
    }
  }, [params.travelId]);

  const renderCell = (cell: string, idx: number, isGrayData: boolean) => {
    if (idx < 2) return cell;
    return <span className={cx({[styles.gray]: isGrayData})}>{numberToMoney(cell)}</span>;
  };

  const domestic = useMemo(() => {
    const {data, headers} = getTotalsTableData(transportationTotals.domestic, renderCell, ['Domestic transportation methods', 'Totals']);
    return {
      title: 'Domestic transportation methods',
      headers: headers,
      data: data,
    }
  }, [transportationTotals]);

  const foreign = useMemo(() => {
    const {data, headers} = getTotalsTableData(transportationTotals.foreign, renderCell, ['Foreign transportation methods', 'Totals']);
    return {
      title: 'Foreign transportation methods',
      headers,
      data,
    }
  }, [transportationTotals]);

  const domesticAndForeign = useMemo(() => {
    const {data, headers} = getTotalsTableData(transportationTotals.domesticAndForeign, renderCell, ['Domestic And Foreign transportation methods', 'Totals']);
    return {
      title: 'Domestic And Foreign transportation methods',
      headers,
      data,
    }
  }, [transportationTotals]);

  return (
    <>
      <GrantFormWrapper title="Transportation methods" subtitle="Totals">
        <div className={styles.tables}>
          <Table title={domestic.title} className={styles.table} data={domestic.data} headers={domestic.headers} />
          <Table title={foreign.title} className={styles.table} data={foreign.data} headers={foreign.headers} />
          <Table title={domesticAndForeign.title} className={styles.table} data={domesticAndForeign.data} headers={domesticAndForeign.headers} />
        </div>
      </GrantFormWrapper>
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  )
}

export default TotalsPage;
