import {FC, ChangeEvent, ReactElement, useCallback} from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import {useFormContext} from 'react-hook-form';
import {findInputError, RHErrors} from '../../../helpers/errors';

type Option = {
  label: string;
  value: string | number | undefined | boolean;
}

type Props = {
  name: string;
  options: Option[];
  value?: string | number | boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  helpDebounce?: number;
  help?: ReactElement | string;
  disabled?: boolean;
  bool?: boolean;
  inline?: boolean;
  hint?: string;
  triangleHelp?: boolean;
  inputClassName?: string;
  className?: string;
}

const RadioGroup:FC<Props> = (props: Props) => {
  const {
    name, value, label,
    className, errors = [], required,
    disabled, inputClassName, hint,
    options, onChange, helpDebounce,
    help, inline, triangleHelp,
    bool
  } = props;
  const {
    register,
    formState,
  } = useFormContext();
  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    if (bool) {
      onChange({
        ...event,
        target: {
          ...event.target, //@ts-ignore
          value: event.target.value === 'true'
        }
      })
      return;
    }
    onChange(event);
  }, [bool, onChange]);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const validation = register(name,
    {
      required: {value: Boolean(required), message: 'Field is required'},
      onChange: handleChangeValue
    })

  return (
    <Field help={help} triangleHelp={triangleHelp} helpInLabel helpDebounce={helpDebounce}
           className={className} required={required} label={label} hint={hint}
           errors={errorsList} name={name}>
        <div className={cx(styles.radios, {[styles.inlineRadios]: inline})}>
          {options.map((item: Option, idx: number) => (
            <label className={styles.togglerWrapper} key={idx}>
              <input
                type="radio"
                className={cx(styles.hidden, styles.togglerRadio)}
                disabled={disabled} cypress-id={`${name}-radio`}
                {...validation} //@ts-ignore
                value={bool ? `${item.value}` : item.value}
                checked={item.value === value}
              />
              <div className={cx(styles.customRadio, inputClassName)}>
                <span className={styles.radioDot}></span>
              </div>
              <span className={styles.label} title={item.label}>{item.label}</span>
            </label>
          ))}
        </div>
    </Field>
  )
}

export default RadioGroup;
