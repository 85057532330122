import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import iMask from 'imask';
import {
  Input, Button, Select, Autocomplete,
  RadioGroup, DatePicker, Textarea, Checkbox, Form
} from 'components/form';
import Typo from 'components/typo';
import Icon from 'components/icon';
import Modal from 'components/modal';
import useAuth from 'hooks/useAuth';
import useOrganizations from 'hooks/useOrganizations';
import useEnums from 'hooks/useEnums';
import {Errors} from 'helpers/errors';
import {FullGrant, SeniorPersonal} from 'store/grants/types';
import {stringOrUndefined} from 'helpers/date';
import {isEqual} from 'lodash';
import Prompt from 'containers/prompt';
import styles from './form.module.css';
import useGrants from 'hooks/useGrants';
import {confirm} from 'components/confirmation';
import useUI from 'hooks/useUI';
import Message from 'pages/grant/common/message';
import Help from 'components/help';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useLocation} from 'react-router';
import {trackUserAction} from 'helpers/trackUserActions';

type DateCalculations = {
  days: string | number;
  years: string | number;
  months: string | number;
}

export type FieldType = Record<string, any>;

type Props = {
  errors: Errors;
  loading: boolean;
  grant: FullGrant;
  onSubmit: (data: any, cb?: () => void) => void;
  onSubmitEmail: (data: any, cb?: () => void) => void;
}

const CreateGrantForm: FC<Props> = ({errors, onSubmitEmail, onSubmit, loading, grant}) => {
  const {user} = useAuth();
  const {grantPreview} = useGrants();
  const {onShowNavigationMessage} = useUI();
  const {derectorates, getSubs, grantTypes} = useEnums();
  const [years, onChangeYears] = useState<string[][]>([]);
  const [showYears, onChangeShowYears] = useState<boolean>(false);
  const [hasDurationLeftover, onChangeHasDurationLeftover] = useState<boolean>(false);
  const location = useLocation();
  const budgetRef = useRef<HTMLDivElement | null>(null);
  const [datesCalculations, onChangeDatesCalculations] = useState<DateCalculations>({
    days: grant.daysInGrant ?? '',
    years: grant.yearsInGrant ?? '',
    months: grant.monthsInGrant ?? ''
  });
  const [show, onChangeShow] = useState<boolean>(false);
  const [doUpdateUserProfile, onChangeDoUpdateUserProfile] = useState<boolean | undefined>(undefined);
  const {fetchApi, fetchOptions, isLoading} = useOrganizations();
  const [notifyData, onChangeNotifyData] = useState({
    contactEmail: user.email,
    useAccountEmail: true,
    notes: ''
  });

  const [dates, onChangeDates] = useState({
    startDate: grant.startDate ? new Date(grant.startDate) : undefined,
    endDate: grant.endDate ? new Date(grant.endDate) : undefined,
  });

  const [primaryInvestigator, onChangePrimaryInvestigator] = useState(grant.primaryInvestigator.id);

  const defaultData = useMemo(() => ({
    title: grant.title,
    step: grant.step,
    type: grant.type,
    budgetCap: grant.budgetCap ?? '',
    doUseDurationLeftover: grant.doUseDurationLeftover,
    isBudgetSubwardOnAnother: grant.isBudgetSubwardOnAnother,
    notes: undefined,
    nsf: {
      solicitationName: grant.nsf.solicitationName,
      solicitationNumber: grant.nsf.solicitationNumber,
      directorate: grant.nsf.directorate,
      division: grant.nsf.division,
      directorateOther: grant.nsf.directorateOther,
      divisionOther: grant.nsf.divisionOther,
    }
  }), [grant]);

  const [organization, onChangeOrganization] = useState({
    name: grant.organization.name ?? '',
    subGroup: grant.organization.subGroup ?? '',
    department: grant.organization.department ?? ''
  });

  const [data, onChangeData] = useState(defaultData);

  useEffect(() => {
    if (location.hash === '#budget') {
      budgetRef?.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [location]);

  const handleChangeData = useCallback((field: FieldType) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data, onChangeData]);

  const handleChangeOrganization = useCallback((field: FieldType) => {
    onChangeOrganization({
      ...organization,
      ...field
    });
  }, [organization, onChangeOrganization]);

  const handleBlurTitleInput = useCallback(() => {
    trackUserAction(`Starts changing a grant general information form`)
  }, [])

  const handleChangeDates = useCallback((field: FieldType) => {
    onChangeDates({
      ...dates,
      ...field
    });
  }, [dates, onChangeDates]);

  const handleChangeNSFData = useCallback((field: FieldType) => {
    onChangeData({
      ...data,
      nsf: {
        ...data.nsf,
        ...field
      }
    });
  }, [data, onChangeData]);

  const handleBlurBudgetCap = useCallback(() => {
    trackUserAction(`Clicks Sets a Budget Cap`)
  }, [])

  const applyOptions = useMemo(() => ([
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
  ]), []);

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      grantPreview({
        startDate: stringOrUndefined(dates.startDate),
        endDate: stringOrUndefined(dates.endDate),
      }, (response: any) => {
        onChangeYears(response.years ?? []);
        onChangeDatesCalculations({
          days: response.daysInGrant,
          years: response.yearsInGrant,
          months: response.monthsInGrant
        });
        onChangeHasDurationLeftover(response.hasDurationLeftover);
      });
    }
  }, [dates]);

  const divisions = useMemo(() => {
    return getSubs(derectorates, data.nsf.directorate);
  }, [getSubs, data, derectorates]);

  const handleSubmit = useCallback(() => {
    const showConfirm = grant.years.length < 1;
    const firstConfirmation = () => confirm({
      icon: 'check-circle-broken',
      hideCancel: true,
      title: 'Complete Your Grant Details',
      type: 'success',
      okText: 'Continue',
      text: (
        <div>
          <div className={styles.centerTitle}>Great!</div>
          <div className={styles.center}>Let's keep the momentum going. Fill in the remaining details in the left
            sidebar to ensure accurate grant calculations.
          </div>
        </div>
      ),
      onConfirm: () => {
        window.scrollTo(0, 0);
      }
    });

    onSubmit({
      derectoratesFullName: derectorates.find(i => i.value === data.nsf.directorate)?.label || '',
      divisionsFullName: divisions.find(i => i.value === data.nsf.division)?.label || '',
      datesCalculations,
      title: data.title,
      step: data.step,
      budgetCap: Number(data.budgetCap),
      startDate: stringOrUndefined(dates.startDate),
      endDate: stringOrUndefined(dates.endDate),
      type: data.type,
      doUseDurationLeftover: data.doUseDurationLeftover,
      isBudgetSubwardOnAnother: data.isBudgetSubwardOnAnother,
      notes: data.notes,
      organization: organization,
      nsf: {
        solicitationName: data.nsf.solicitationName,
        solicitationNumber: data.nsf.solicitationNumber,
        directorate: data.nsf.directorate || null,
        directorateOther: data.nsf.directorateOther,
        divisionOther: data.nsf.divisionOther,
        division: data.nsf.division || null,
      },
      doUpdateUserProfile,
      ...(primaryInvestigator ?? primaryInvestigator !== grant.primaryInvestigator.id ? {primaryInvestigator: {id: primaryInvestigator}} : {})
    }, showConfirm ? firstConfirmation : onShowNavigationMessage);
    trackUserAction(`User completes changing a grant general information form`)
  }, [datesCalculations, onSubmit, dates, doUpdateUserProfile, primaryInvestigator, organization, onShowNavigationMessage, grant, data]);

  const handleSubmitNotify = useCallback(() => {
    onSubmitEmail({
      contactEmail: notifyData.contactEmail,
      notes: notifyData.notes === '' ? undefined : notifyData.notes
    }, () => onChangeShow(false));
  }, [onSubmitEmail, notifyData]);

  const disabled = useMemo(() => {
    return Boolean(data.type && data.type !== 'nsf');
  }, [data]);

  const mask = iMask.createPipe({
    mask: 'TITLE (ABR)',
    from: 12,
    to: 71,
    blocks: {
      TITLE: {
        mask: '************************************************************'
      },
      ABR: {
        mask: '**********',
      },
    }
  });

  const pi = grant.primaryInvestigator?.firstName ? `${grant.primaryInvestigator.firstName || ''} ${grant.primaryInvestigator.lastName || ''}` : undefined;

  const lessThanYearHint = useMemo(() => {
    return dates.startDate && dates.endDate && datesCalculations.years < 1
  }, [datesCalculations, dates]);

  const durationHint = useMemo(() => {
    const text = data.doUseDurationLeftover
      ? `Depending on the duration of the grant, the number of years for compensation calculation is defined as ${Number(datesCalculations.years) + 1}.Of which ${datesCalculations.years} is a full year(s), and the remaining duration is added to year ${Number(datesCalculations.years) + 1}.`
      : `Based on your selection, we only calculate compensation for the full year(s). An incomplete year is not included.`;

    return (
      <div className={styles.hintText}>
        {text}
        <Button type="link" onClick={() => onChangeShowYears(true)} htmlType="button" name="notify-modal">
          Do you want to make any changes?
        </Button>
      </div>
    )
  }, [data, datesCalculations]);

  const piOptions = useMemo(() => {
    return grant.seniorPersonal.map((item: SeniorPersonal) => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`
    }));
  }, [grant]);

  return (
    <GrantFormWrapper helmet={`${data.title}: Edit`} title={`General information. ${data.title ?? ''}`}>
      <>
        <Form prompt onSubmit={handleSubmit} className={styles.wrapper}>
          <div className={styles.field}>
            <Input name="title" className={styles.fullInput} value={data.title}
                   placeholder="Enter grant title" label="Grant Title" required errors={errors.title}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({title: event.target.value})}
                   onBlur={handleBlurTitleInput}
            />
          </div>
          {
            pi
              ?
              <div className={styles.field}>
                <Select name="primaryInvestigator" className={styles.fullInput} value={primaryInvestigator}
                        placeholder="Primary Investigator Name" label="Primary Investigator Name"
                        options={piOptions}
                        onChange={(pi) => onChangePrimaryInvestigator(pi)}
                        help={`Please note that it is mandatory to indicate a Primary investigator for each grant. Only one PI can be added to each grant. This field will be automatically populated with the "First Name" and "Last Name" values of the "Person X" tab of the senior personnel member who is assigned the Primary investigator role`}
                />
              </div>
              : null
          }
          <div className={cx(styles.field, styles.last)}>
            <Select name="type" className={styles.fullInput} options={grantTypes}
                    placeholder="Select grant type" label="Grant Type" required
                    value={data.type} onChange={(type: any) => handleChangeData({type})}
            />
            {
              data.type && data.type !== 'nsf'
                ?
                <Typo type="div" className={styles.typeHint}>
                  <>
                    <Icon size={20} icon="alert-triangle" className={styles.hintIcon}/>
                    This type of grant budget is under development.
                    <Button type="link" onClick={() => onChangeShow(true)} htmlType="button" name="notify-modal">
                      Want to be notified when it's available?
                    </Button>
                  </>
                </Typo>
                : null
            }
          </div>

          <Typo type="h3" className={cx(styles.title, styles.subtitle)} semi>
            Organization information
          </Typo>
          <div className={styles.field}>
            <Autocomplete
              className={styles.fullInput}
              value={organization.name}
              name="organizationId"
              options={fetchOptions}
              onChange={(value: string | number) => handleChangeOrganization({name: String(value)})}
              placeholder="Choose an organization"
              label="Organization Name"
              clearable
              help="This field was automatically populated based on the grant settings. Please use the grant settings to update the auto-populated value."
              onLoad={fetchApi}
              isLoading={isLoading}
            />
          </div>
          <div className={styles.field}>
            <Input className={styles.fullInput} name="department" label="Department"
                   placeholder="Enter department" value={organization.department}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeOrganization({department: event.target.value})}
                   help="This field was automatically populated based on the grant settings. Please use the grant settings to update the auto-populated value."
            />
          </div>

          <div className={cx(styles.field)}>
            <Input className={styles.fullInput} name="subgroup" label="College (if a subgroup of your organization)"
                   placeholder="Enter subgroup of Organization" value={organization.subGroup}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeOrganization({subGroup: event.target.value})}
                   help="This field was automatically populated based on the grant settings. Please use the grant settings to update the auto-populated value."
            />
          </div>
          <div className={cx(styles.field, styles.last)}>
            <Checkbox className={styles.fullInput} name="doUpdateUserProfile"
                      label="Keep this organization information for my next grants"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDoUpdateUserProfile(event.target.checked)}
                      value={doUpdateUserProfile}
            />
          </div>

          <Typo type="h3" className={cx(styles.title, styles.subtitle)} semi>
            This Grant’s Information
          </Typo>
          <div className={styles.field}>
            <div className={styles.twoInputsField}>
              <DatePicker className={styles.input} name="startDate" label="Grant Start Date" required
                          disabled={disabled}
                          placeholder="Choose date" value={dates.startDate} errors={errors.startDate}
                          onChange={(date: Date | null) => handleChangeDates({startDate: date})}
              />
              <DatePicker className={styles.input} minDate={dates.startDate || undefined} name="endDate"
                          label="Grant End Date" required
                          placeholder="Choose date" value={dates.endDate} errors={errors.endDate} disabled={disabled}
                          onChange={(date: Date | null) => handleChangeDates({endDate: date})}
              />
            </div>
            {
              lessThanYearHint
                ?
                <Typo type="div" className={styles.typeHint}>
                  <>
                    <Icon size={20} icon="alert-triangle" className={styles.hintIcon}/>
                    Depending on the duration of the grant, the number of years for calculating compensation is
                    determined as one year.
                  </>
                </Typo>
                : null
            }
          </div>
          <div className={styles.field}>
            <div className={styles.threeInputsField}>
              <Input className={styles.input} disabled type="number" name="years" label="Number of years in Grant*"
                     placeholder="Enter number of years" value={datesCalculations.years}
              />
              <Input className={styles.input} disabled type="number" name="months" label="Number of months in Grant*"
                     placeholder="Enter number of months" value={datesCalculations.months}
              />
              <Input className={styles.input} disabled type="number" name="days" label="Number of days in Grant*"
                     placeholder="Enter number of days" value={datesCalculations.days}
              />
            </div>
            {
              hasDurationLeftover
                ?
                <Typo type="div" className={cx(styles.typeHint, styles.wrap)}>
                  <>
                    <Icon size={20} icon="alert-triangle" className={styles.hintIcon}/>
                    {durationHint}
                  </>
                </Typo>
                : null
            }
          </div>
          <div className={cx(styles.twoInputsField, styles.fieldWithHelps, styles.field)}>
            <Input className={styles.input}
                   help={`The NSF requests research in certain fields. These requests are called program solicitations. The title of the program solicitation is also known as the "NSF Grant Solicitation Name" Program solicitations can be found on the NSF website.`}
                   name="solicitationName"
                   label="NSF Grant Solicitation Name" errors={errors['nsf.solicitationName']}
                   placeholder="Enter solicitation name" value={data.nsf.solicitationName} disabled={disabled}
                   onChange={(event: ChangeEvent<HTMLInputElement>) =>
                     handleChangeNSFData({solicitationName: event.target.value})}
            />
            <Input className={styles.input}
                   help="NSF Grant solicitation number: NSF program solicitations (see previous help icon on this page) normally also have an identifying number. Program solicitation names and accompanying numbers can be found on the NSF website."
                   name="solicitationNumber"
                   label="NSF Grant Solicitation Number" errors={errors['nsf.solicitationNumber']}
                   placeholder="Enter solicitation number" value={data.nsf.solicitationNumber} disabled={disabled}
                   onChange={(event: ChangeEvent<HTMLInputElement>) =>
                     handleChangeNSFData({solicitationNumber: event.target.value})}
            />
          </div>
          <div className={styles.nsfInputs}>
            <div className={styles.nsfHeader}>
              <Select className={styles.input} name="directorate" options={derectorates} disabled={disabled}
                      label="NSF Directorate" errors={errors['nsf.directorate']}
                      placeholder="Select directorate" value={data.nsf.directorate}
                      onChange={(directorate: any) =>
                        handleChangeNSFData({directorate})}
              />
              <div className={styles.input}>
                <Select className={styles.fullInput} name="division" options={divisions}
                        label="NSF Division" errors={errors['nsf.division']}
                        disabled={disabled || divisions.length < 1}
                        placeholder="Select division" value={data.nsf.division}
                        onChange={(division: any) =>
                          handleChangeNSFData({division})}
                />
                {
                  data.nsf.division && data.nsf.division === 'opp'
                    ?
                    <Typo type="div" className={cx(styles.typeHint, styles.wrap)}>
                      <>
                        <Icon size={20} icon="alert-triangle" className={styles.hintIcon}/>
                        These grants are much more complex than the standard NSF grant budget. We provide a template
                        that
                        the vast majority of all NSF grants follow and is no different from OPP grants, but we want to
                        warn you in advance that OPP grants differ in some significant ways due to the complexity of
                        research planning.
                      </>
                    </Typo>
                    : null
                }
              </div>
            </div>
            <div className={styles.nsfBody}>
              <div className={styles.npsInput}>
                {
                  data.nsf.directorate === 'other'
                    ?
                    <Input className={styles.input} name="directorateOther" disabled={disabled}
                           label="NSF Directorate" required={data.nsf.directorate === 'other'}
                           errors={errors['nsf.directorateOther']}
                           placeholder="NSF Directorate (abbreviation)" value={data.nsf.directorateOther}
                           onChange={(event: ChangeEvent<HTMLInputElement>) =>
                             handleChangeNSFData({directorateOther: mask(event.target.value)})}
                    />
                    : null
                }
              </div>
              <div className={styles.npsInput}>
                {
                  data.nsf.division === 'other'
                    ?
                    <Input className={styles.input} name="divisionOther" disabled={disabled}
                           label="NSF Division" required={data.nsf.division === 'other'}
                           errors={errors['nsf.divisionOther']}
                           placeholder="NSF Division Name (abbreviation)" value={data.nsf.divisionOther}
                           onChange={(event: ChangeEvent<HTMLInputElement>) =>
                             handleChangeNSFData({divisionOther: mask(event.target.value)})}
                    />
                    : null
                }
              </div>
            </div>
          </div>

          <div className={cx(styles.field, styles.semilast)}>
            <RadioGroup label="Please check this box if this budget will be a sub-award on another budget"
                        name="subAward"
                        help="Subawards are used as a method of funding collaborative research. If two people are working together on research at two different institutions, one institution may submit both budgets. The budget of the person who is NOT employed by the submitting organization is known as the sub-award. If a collaborator's university will be submitting this budget as a sub-award, check this box. If you will be working with someone and their budget will be the sub-award DO NOT check this box."
                        triangleHelp bool
                        inline className={styles.fullInput} disabled={disabled}
                        value={data.isBudgetSubwardOnAnother}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({isBudgetSubwardOnAnother: event.target.value})}
                        options={applyOptions}
            />
          </div>
          <div ref={budgetRef} className={cx({[styles.glow]: location.hash === '#budget'})}>
            <Typo type="h3" className={cx(styles.title, styles.subtitle)} semi>
              <>Budget Settings <Help contentClassName={styles.help}>Here you can input a target budget amount for your
                total grant, covering both direct and indirect costs. This amount will determine the threshold for your
                target budget bar. While you can allocate funds beyond this target, the software will notify you upon
                reaching this amount and visually display any excess in the budget bar.</Help></>
            </Typo>
            <div className={cx(styles.field, styles.pb0, styles.twoInputsField, styles.last)}>
              <Input disabled={Boolean(grant.acceptedForm.awardNumber)} name="budgetCap" className={styles.input}
                     label="Maximum Target Budget Cap" placeholder="Enter a target budget cap"
                     money type="number" value={data.budgetCap} onBlur={handleBlurBudgetCap}
                     onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({budgetCap: event.target.value})}
              />
            </div>
            <Typo type="div" className={styles.typeHint}>
              <>
                <Icon size={20} icon="alert-triangle" className={styles.hintIcon}/>
                Budget cap includes Direct and Indirect Costs
              </>
            </Typo>
          </div>


          <div className={styles.formFooter}>
            <Button size="xl" loading={loading} disabled={disabled} htmlType="submit" name="grant-submit">
              Save Grant
            </Button>
          </div>
          <Message></Message>
        </Form>


        <Modal visible={show} small titleCenter icon="mail-02" onCancel={() => onChangeShow(false)}>
          <Form onSubmit={handleSubmitNotify}>
            <Typo type="div" size={18} className={styles.notifyTitle}>
              <div>Please enter your</div>
              <div>preferred contact email</div>
            </Typo>
            <Input name="contactEmail" value={notifyData.contactEmail} errors={errors.contactEmail}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeNotifyData({
                     ...notifyData, contactEmail: event.target.value
                   })} disabled={notifyData.useAccountEmail}
                   type="email" placeholder="Enter your email" label="Email" required={!notifyData.useAccountEmail}
            />
            <Checkbox name="useAccountEmail" value={notifyData.useAccountEmail} errors={errors.useAccountEmail}
                      label="Use my account email"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeNotifyData({
                        ...notifyData,
                        useAccountEmail: event.target.checked,
                        contactEmail: event.target.checked ? user.email : notifyData.contactEmail
                      })}/>
            <Textarea name="notes" value={notifyData.notes} label="Any additional thoughts or information"
                      placeholder="Enter a description..."
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeNotifyData({
                        ...notifyData, notes: event.target.value
                      })} required
            />
            <div className={styles.notifyFooter}>
              <Button htmlType="button" onClick={() => onChangeShow(false)} size="lg" name="notify-cancel"
                      type="bordered">Cancel</Button>
              <Button htmlType="submit" size="lg" name="notify-submit">Submit</Button>
            </div>
          </Form>
        </Modal>
        <Modal visible={showYears} small iconType="success" titleCenter icon="check-circle">
          <Form>
            <Typo type="div" size={18} className={styles.yearsTitle}>
              Number of years for compensation
            </Typo>
            <Typo type="div" size={14} className={styles.yearsSubTitle}>
              <>
                Grant duration: {datesCalculations.years} full
                year(s), {datesCalculations.months} month(s), {datesCalculations.days} day(s).
                Full years will automatically be added to calculate compensation. An incomplete year will only be added
                if it is checked.
              </>
            </Typo>
            <div>
              {years.map((year: string[], idx: number) => (
                <Checkbox disabled={idx < years.length - 1}
                          value={idx === years.length - 1 ? data.doUseDurationLeftover : true}
                          name={`years-checkbox-${idx}`}
                          key={idx}
                          label={`Year ${idx + 1} (${dayjs(year[0]).format('MM/DD/YYYY')} - ${dayjs(year[1]).format('MM/DD/YYYY')})`}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => idx === years.length - 1 ? handleChangeData({doUseDurationLeftover: event.target.checked}) : {}}
                />
              ))}
            </div>
            <div className={styles.yearsFooter}>
              <Button htmlType="button" onClick={() => onChangeShowYears(false)} size="lg"
                      name="years-save">Save</Button>
            </div>
          </Form>
        </Modal>
        <Prompt when={!isEqual(data, defaultData)}/>
      </>
    </GrantFormWrapper>
  )
}

export default CreateGrantForm;
