import {FC, useCallback, useMemo, useState} from 'react';
import styles from './editPerson.module.css';
import useGrants from 'hooks/useGrants';
import UngraduatedStudentPersonForm from 'containers/forms/personForm/ungraduatedStudent';
import {useHistory, useParams} from 'react-router-dom';
import usePersonnel from 'hooks/usePersonnel';
import Typo from 'components/typo';
import {confirm} from 'components/confirmation';
import {Form, Toggler} from 'components/form';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {personalTypes} from 'const';
import Help from 'components/help';

const CreateEditUnGraduatedStudents: FC = () => {
  const {grant} = useGrants();
  const history = useHistory();
  const params: Record<string, string> = useParams();

  const {
    errors, loading,
    addOrUpdateUndergraduatedStudent,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({form_page_type:'General Info'});
  const [data, onChangeData] = useState({
    isAccountOwner: Boolean(grant.undergraduateStudents?.isAccountOwner),
    academicTerm: grant.undergraduateStudents?.academicTerm ?? undefined,
    numberOfAcademicTerms: grant.undergraduateStudents?.numberOfAcademicTerms ?? undefined,
    wageInAcademicTerm: grant.undergraduateStudents?.wageInAcademicTerm ?? undefined,
    wageInSummer: grant.undergraduateStudents?.wageInSummer ?? undefined,
    numberOfWeekAcademicTerms: grant.undergraduateStudents?.numberOfWeekAcademicTerms ?? undefined,
    loadLimitDuringAcademicTerm: grant.undergraduateStudents?.loadLimitDuringAcademicTerm ?? undefined,
    loadLimitDuringSummer: grant.undergraduateStudents?.loadLimitDuringSummer ?? undefined,
  });

  const onDuplicateFromProfile = useCallback(() => {
    const {author} = grant;
    const ownerIsStudent = [
      'graduateStudents',
      'undergraduateStudents'
    ].includes(author.personalReference?.kind as string);
    const ownerIsPerson = (author.personalReference && author.personalReference.id !== params.personId);
    if (author.personalReference !== undefined && (ownerIsStudent || ownerIsPerson)) {
      const personnelType = personalTypes[author.personalReference.kind];
      let link = `/grants/${grant.id}/personnel/${personnelType}/${author.personalReference?.id}/edit`;
      if (ownerIsStudent) {
        link = `/grants/${grant.id}/personnel/${personnelType}/edit`
      }
      if (ownerIsStudent && personnelType === personalTypes.undergraduateStudents) {
        onChangeData({
          ...data,
          isAccountOwner: true
        });
        return;
      }
      confirm({
        text: 'Another person/personnel type is marked as Account owner. Do you want to remove the mark?',
        plain: true,
        onConfirm: () => {
          history.push(link)
        },
        onCancel: () => onChangeData({
          ...data,
          isAccountOwner: false
        })
      })
      return;
    }
    onChangeData({
      ...data,
      isAccountOwner: true
    });

  }, [params, data, history, grant]);

  const onRemoveDuplicateFromProfile = useCallback(() => {
    onChangeData({
      ...data,
      isAccountOwner: false
    });
  }, [data]);

  const isEdit = useMemo(() => grant.undergraduateStudents.academicTerm !== undefined, [grant]);

  const onChange = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    addOrUpdateUndergraduatedStudent(grant.id, personalTypes.undergraduateStudents, data, isEdit);
  }, [data, addOrUpdateUndergraduatedStudent, grant, isEdit]);

  return (
    <GrantFormWrapper title={(
      <div className={styles.subtitleWrapper}>
        <Typo type="h3" bold className={styles.title}>Undergraduate Students</Typo>
        <div className={styles.btnWrapper}>
          <Form>
            <Toggler
              label="Account owner is an undergraduate student"
              name="isAccountOwner"
              green
              className={styles.toggler}
              checked={data.isAccountOwner}
              onChange={data.isAccountOwner ? onRemoveDuplicateFromProfile : onDuplicateFromProfile}
            />
          </Form>
          <Help contentClassName={styles.help}>Please use this button if you are the account owner. This is necessary
            to display the “Account owner salary budget” on the Dashboard. This button can only be used once for all
            types of personnel.
          </Help>
        </div>
      </div>
    )}>
      <UngraduatedStudentPersonForm edit={isEdit} errors={errors} loading={loading} data={data} onSubmit={onSubmit}
                                    onChange={onChange}
                                    trackError={trackError}
                                    trackExit={trackExit}
                                    trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  )
}

export default CreateEditUnGraduatedStudents;
