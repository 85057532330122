import {createElement, FC, useCallback, useMemo, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import styles from './cost.module.css';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {confirm} from 'components/confirmation';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {Button, Form, Select} from 'components/form';
import * as T from 'hooks/requestTypes';
import Field from 'components/field';
import {ListItem} from 'helpers/utils';
import costForms, {Props as FormProps} from './forms';
import useTravel from 'hooks/useTravel';
import {stringOrNull} from 'helpers/date';
import {Travel} from 'store/grants/types';

type Kind = 'fourty8states' | 'alaskaHawaii' | 'other' | 'international';

const forms: Record<Kind, FC<FormProps>> = {
  fourty8states: costForms.StatesForm,
  other: costForms.OtherForm,
  alaskaHawaii: costForms.AlaskaHawaiiForm,
  international: costForms.International
}

type Props = {
  travel: Travel;
  travelIndex: number;
}

type CostType = 'per_diem' | 'other';

const CostPageComponent: FC<Props> = ({travel, travelIndex}) => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants();
  const {
    loading, updateTravelCost, previewOtherTravelCost, addTravelCost, errors,
    trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: "Travel info"
  });
  const {cost} = getCostFromTravel(travel, params.costId);
  const [type, onChangeType] = useState<CostType | undefined>();
  const [kind, onChangeKind] = useState<Kind | undefined>(cost?.kind as Kind);
  const [data, onChangeData] = useState<T.TravelCostData>({
    nameOfTrip: cost?.nameOfTrip ?? '',
    zip: cost?.zip ?? '',
    country: cost?.country ?? '',
    state: cost?.state ?? '',
    category: cost?.category ?? '',
    totalAmount: cost?.totalAmount ?? '',
    typeOfExpenses: cost?.typeOfExpenses ? cost.typeOfExpenses : travel.tripDefaultLocation,
    price: cost?.price ?? '',
    numberOfOccurrence: cost?.numberOfOccurrence ?? '',
    isPerPerson: Boolean(cost?.isPerPerson),
    city: cost?.city ?? '',
    fiscalYear: cost?.fiscalYear ?? '',
    numberOfSeasons: cost?.numberOfSeasons ?? '',
    startDate: cost?.isStartEndDateKnown && cost?.startDate ? new Date(cost.startDate) : undefined,
    endDate: cost?.isStartEndDateKnown && cost?.endDate ? new Date(cost.endDate) : undefined,
    isStartEndDateKnown: Boolean(cost?.isStartEndDateKnown)
  });

  const secondOptions = useMemo(() => ([
    {label: '48 States', value: 'fourty8states'},
    {label: 'Alaska, Hawaii', value: 'alaskaHawaii'},
    {label: 'International', value: 'international'},
  ]), []);

  const options: ListItem[] = useMemo(() => ([
    {label: 'Per diem (Lodging and M&IE)', value: 'per_diem'},
    {label: 'Other', value: 'other'},
  ]), []);

  const onEraseForm = useCallback(() => {
    onChangeKind(undefined);
  }, []);

  const subtitle = useMemo(() => {
    if (kind) {
      const optionOther = options.find((item: ListItem) => item.value === kind);
      const option = secondOptions.find((item: ListItem) => item.value === kind);
      const label = option?.label || optionOther?.label;
      return <div className={styles.subtitle}>
        {`Trip ${travelIndex}. Travel Costs. ${label}`}
        {cost?.id ? null : <Button size="xs" type="bordered" onClick={onEraseForm} name="rollback">Change</Button>}
      </div>
    }
    return `Trip ${travelIndex}. Travel Costs.`;
  }, [kind, cost, travelIndex, onEraseForm, options]);

  const form = forms[kind as Kind];

  const onBlur = useCallback((event?: any, dataToSubmit?: any) => {
    const canBlur = kind === 'other' || (kind && cost);
    if (canBlur) {
      const submitData = dataToSubmit ?? data;
      const canBlur = Boolean(data.price && data.numberOfOccurrence);
      if (!canBlur) return;
      previewOtherTravelCost(grant.id, params.travelId, cost?.id ?? '', {
        kind,
        ...submitData,
        fiscalYear: submitData.fiscalYear ? submitData.fiscalYear : undefined,
        startDate: stringOrNull(submitData.startDate),
        endDate: stringOrNull(submitData.endDate),
      }, (response) => {
        const defaultEnabledYears = grant.years.map((year: string[], idx: number) => ({
          year,
          isEnabled: true,
          serialNumber: idx + 1
        }));
        const years = (response.years ?? defaultEnabledYears).map((item: any) => {
          return {
            ...item,
            isEnabled: true,
            typeOfExpenses: submitData.typeOfExpenses,
          }
        });
        onChangeData({
          ...submitData,
          years,
          totalAmount: response.totalAmount
        });
      });
    }
  }, [grant, travel, previewOtherTravelCost, data, kind, params]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    onChangeData({
      ...data,
      ...field
    });
    if (preview) {
      onBlur(undefined, {
        ...data,
        ...field
      });
    }
  }, [data, onBlur]);

  const onSubmit = useCallback(() => {
    if (kind) {
      if (cost) {
        const seasonsAreDecreased = Number(data.numberOfSeasons) < Number(cost.numberOfSeasons);
        const seasonsAreIncreased = Number(data.numberOfSeasons) > Number(cost.numberOfSeasons);
        const submitData = {
          kind,
          ...data,
          years: data.years?.map((item) => ({
            ...item,
            numberOfOccurrence: item.numberOfOccurrence === '' ? undefined : item.numberOfOccurrence,
          })),
          fiscalYear: data.fiscalYear ? data.fiscalYear : undefined,
          startDate: stringOrNull(data.startDate),
          endDate: stringOrNull(data.endDate),
        };
        if (seasonsAreDecreased && ['alaskaHawaii', 'international'].includes(kind)) {
          confirm({
            title: 'Seasonal division will not apply',
            icon: 'trash-01',
            type: 'error',
            text: 'The data of all seasons and the applied discount will be deleted',
            onConfirm: () => {
              updateTravelCost(grant.id, params.travelId, cost.id, submitData);
            }
          });
          return;
        }
        if (seasonsAreIncreased && ['alaskaHawaii', 'international'].includes(kind)) {
          confirm({
            title: 'Number of seasons will be changed',
            icon: 'alert-hexagon',
            type: 'success',
            text: "Some information will need to be re-entered. Don't forget to check Lodging, M&IE Breakdown and re-enter the discount information ",
            onConfirm: () => {
              updateTravelCost(grant.id, params.travelId, cost.id, submitData);
            }
          });
          return;
        }
        if (kind === 'other' && cost.isPerPerson !== data.isPerPerson) {
          const text = data.isPerPerson
            ? `These costs were calculated and applied based on base price for chosen years of the grant. If the toggle is turned on, the entered values in the Totals tabs will be deleted. You will need to re-enter the data in the Year 1,2,3, etc. tabs after confirmation`
            : `These costs were calculated per person. If the toggle is turned off, the entered values in the years tabs will be deleted. You will need to re-enter the data in the Total tab after confirmation`;
          confirm({
            icon: 'alert-hexagon',
            type: 'success',
            text,
            onConfirm: () => {
              updateTravelCost(grant.id, params.travelId, cost.id, submitData);
            }
          });
          return;
        }
        const datesAreChanged = submitData.isStartEndDateKnown && (submitData.startDate !== cost.startDate || submitData.endDate !== cost.endDate);
        if (kind === 'fourty8states' && datesAreChanged) {
          confirm({
            icon: 'alert-hexagon',
            type: 'success',
            pureConfirm: true,
            title: 'Some data may be lost',
            text: 'Some information in the Lodging breakdown will need to be re-entered',
            onConfirm: () => {
              updateTravelCost(grant.id, params.travelId, cost.id, submitData);
            },
            onCancel: () => {
              onChangeData({
                ...data,
                startDate: cost.startDate ? new Date(cost.startDate) : undefined,
                endDate: cost.endDate ? new Date(cost.endDate) : undefined
              });
            }
          });
          return;
        }
        updateTravelCost(grant.id, params.travelId, cost.id, submitData);
        return;
      }
      addTravelCost(grant.id, params.travelId, params.type, kind, {
        kind,
        ...data,
        fiscalYear: data.fiscalYear ? data.fiscalYear : undefined,
        startDate: stringOrNull(data.startDate),
        endDate: stringOrNull(data.endDate),
      });
    }
  }, [grant, updateTravelCost, cost, data, kind, params, addTravelCost]);

  const props = {
    data,
    onSubmit,
    travel,
    onBlur,
    isUpdate: Boolean(cost),
    errors,
    loading,
    onChange: handleChangeData
  };

  const handleChangeType = useCallback((type: CostType) => {
    if (type === 'other') {
      onChangeKind(type);
      return;
    }
    onChangeType(type);
  }, [])

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      {kind && form as FC
        ? createElement(form, props)
        :
        <Form trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
          <Field withHelp last>
            <Select name="type" options={options} placeholder="Select a travel costs type"
                    label="Choose a cost type to add"
                    value={type} onChange={handleChangeType}
                    help={`Travel costs include per diem and other allowable costs. Per diem includes lodging and MI&E(Meals, Incidentals, and Expenses). “Other” is for entering in government allowed costs such as conference registrations, dependent child travel or childcare. If your institution allows, you may choose to enter in a lump sum estimate for per diem by selecting the “Other” costs type.`}
            />
            {type === 'per_diem'
              ?
              <Select name="method" options={secondOptions} placeholder="Choose a location" label="Choose a location"
                      value={kind} onChange={onChangeKind}
                      help="The choice of location affects the government allowed lodging and M&IE costs."
              />
              : <span/>
            }
          </Field>
        </Form>
      }
    </GrantFormWrapper>
  );
}

const CostPage: FC = () => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants();
  const travel = getTravelFromGrant(grant, params.travelId);
  const costFromTravel = travel?.trip ? getCostFromTravel(travel.trip, params.costId) : undefined;
  const key = `${grant.id}-${params.travelId}-${costFromTravel?.cost?.id ?? 'cost'}`;
  return (
    travel.trip ? <CostPageComponent travelIndex={travel.index} travel={travel.trip} key={key}/> : null
  );
}

export default CostPage;
