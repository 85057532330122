import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Checkbox} from 'components/form'
import styles from './cs.module.css';
import {ComputerServiceYear, ComputerService} from 'store/grants/otherDirectCosts/ComputerService/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getComputerServiceFromODC} from 'helpers/getFromGrant';
import useCS from 'hooks/useCS';

type Props = {
  item: ComputerServiceYear;
  service: ComputerService;
  grantId: string;
}

const ComputerServiceYearForm: FC<Props> = ({item, service, grantId}) => {
  const [data, onChangeData] = useState<ComputerServiceYear>(item);
  const {
    loading, updateService, previewServiceYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useCS({
    form_page_type: 'Date'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    if (data.isUserCompensation && !data.userCompensationBeforeInflation) return;
    if (Number(data.multiplier) >= 0) {
      previewServiceYear(grantId, service.id, {
        serialNumber: item.serialNumber,
        isUserCompensation: Boolean(data.isUserCompensation) ? 1 : 0,
        multiplier: data.multiplier,
        ...(data.userCompensationBeforeInflation ? {userCompensationBeforeInflation: data.userCompensationBeforeInflation} : {})
      }, (response: any) => {
        onChangeData({
          ...response,
          isUserCompensation: data.isUserCompensation,
          calculatedCompensationAfterInflation: response.calculatedCompensationAfterInflation,
          calculatedCompensationBeforeInflation: response.calculatedCompensationBeforeInflation,
          userCompensationBeforeInflation: response.userCompensationBeforeInflation,
          userCompensationAfterInflation: response.userCompensationAfterInflation,
          compensationAfterInflation: response.compensationAfterInflation,
          compensationBeforeInflation: response.compensationBeforeInflation,
        });
      });
    }
  }, [item, previewServiceYear, service.id, grantId, data]);

  const onSubmit = useCallback(() => {
    const newYears = service.years.map((year: ComputerServiceYear) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...year,
          multiplier: Number(data.multiplier),
          isUserCompensation: Boolean(data.isUserCompensation),
          userCompensationBeforeInflation: Number(data.userCompensationBeforeInflation),
          userCompensationAfterInflation: Number(data.userCompensationAfterInflation),
          compensationBeforeInflation: data.compensationBeforeInflation,
          compensationAfterInflation: data.compensationAfterInflation,
        }
      }
      return year;
    });
    updateService(grantId, service.id, {
      ...service,
      years: newYears
    })
  }, [data, item, service, grantId, updateService]);

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    })
  }, [data]);

  const handleChangeCheckbox = useCallback((isUserCompensation: boolean) => {
    onChangeData({
      ...data,
      isUserCompensation,
      userCompensationAfterInflation: isUserCompensation ? data.userCompensationAfterInflation : undefined,
      userCompensationBeforeInflation: isUserCompensation ? data.userCompensationBeforeInflation : undefined,
    });
  }, [data]);

  const hint = `Inflation is not applied in Year 1 for any costs, but will be applied in subsequent years`;

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field full>
        <Input
          name="multiplier"
          placeholder="Enter amount"
          value={data.multiplier}
          onBlur={onBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({multiplier: event.target.value})}
          label="Amount multiplier for service" required/>

      </Field>
      <Field full>
        <Input
          name="calculatedCompensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          disabled
          auto
          value={data.calculatedCompensationBeforeInflation}
          type="number"
        />
      </Field>
      <Field full>
        <Input
          name="calculatedCompensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${data.serialNumber}`}
          help={data.serialNumber === 1 ? hint : undefined}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.calculatedCompensationAfterInflation}
          type="number"
          readOnly
          disabled
        />
      </Field>
      <Field full last>
        <Checkbox name="isUserCompensation" label="I want to adjust the total before inflation"
                  value={data.isUserCompensation}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCheckbox(event.target.checked)}
        />
      </Field>
      <Field full>
        <Input
          name="userCompensationBeforeInflation"
          label={`Adjusted total cost before inflation for Year ${data.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          min={0}
          required={data.isUserCompensation}
          disabled={!data.isUserCompensation}
          money
          onBlur={onBlur}
          value={data.userCompensationBeforeInflation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({userCompensationBeforeInflation: event.target.value})
          }
          type="number"
        />
      </Field>
      <Field last full withHelp>
        <Input
          name="userCompensationAfterInflation"
          label={`Adjusted total cost after inflation for Year ${data.serialNumber}`}
          placeholder="Total cost after inflation"
          className={styles.input}
          min={0}
          help={data.serialNumber === 1 ? hint : undefined}
          disabled
          money
          value={data.userCompensationAfterInflation}
          type="number"
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const ComputerServiceYearPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getComputerServiceFromODC(grant, params.serviceId);

  const year = item?.years[Number(params.year)];

  return (
    <GrantFormWrapper title="Other Direct Costs"
                      subtitle={`Computer Services. Computer Service ${index}. Year ${Number(params.year) + 1}`}>
      {
        year
          ? <ComputerServiceYearForm grantId={grant.id} service={item}
                                     key={`${grant.id}-odc-cs-service-${params.serviceId}-${params.year}`} item={year}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default ComputerServiceYearPage;
