import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import { personalTypes } from 'const';
import CreateEditGraduatedStudents from './graduateStudents';
import CreateEditPostDoctoral from './postdoctoral';
import CreateEditUnGraduatedStudents from './unGraduateStudents';
import CreateEditSeniorOrAcademicPerson from './seniorOrAcademic';
import CreateEditOtherPerson from './other';

const CreateEditPersonPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const key = `${grant.id}-${params.personId}-${params.personnelType}-${grant.updatedAt}`;
  if (params.personnelType === personalTypes.postDocPersonnel) return <CreateEditPostDoctoral key={key}/>
  if (params.personnelType === personalTypes.graduateStudents) return <CreateEditGraduatedStudents key={`${key}-${grant.graduateStudents.academicTerm}`}/>
  if (params.personnelType === personalTypes.undergraduateStudents) return <CreateEditUnGraduatedStudents key={`${key}-${grant.undergraduateStudents.academicTerm}`}/>
  if ([personalTypes.seniorPersonal, personalTypes.academicResearchAssociate].includes(params.personnelType)) return <CreateEditSeniorOrAcademicPerson key={key} />
  return <CreateEditOtherPerson key={key}/>;
}

export default CreateEditPersonPage;
