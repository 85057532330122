import {FC, useCallback, useState, ReactElement, useMemo, useEffect} from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import styles from './dropdown.module.css';
import {Link} from "react-router-dom";

export type ListItem = {
  label: string;
  icon?: string;
  disabled?: boolean;
  divider?: boolean;
  to?: string;
  onClick?: () => void;
}

type Props = {
  list: ListItem[];
  className?: string;
  name: string;
  disabled?: boolean;
  children?: ReactElement;
  hideChevron?: boolean;
  label?: string;
  triggerClassName?: string;
}

const Dropdown:FC<Props> = (props: Props) => {
  const { list, className, triggerClassName, name, hideChevron, disabled, label, children } = props;
  const [ show, onChangeShow ] = useState<boolean>(false);
  const uniqueClassName = useMemo(() => `bm-dropdown-${name}`, [name]);

  const handleClick = useCallback((event: any) => {
    const inElem = event.target?.closest(`.${uniqueClassName}`);
    if (show && !inElem) {
      onChangeShow(false);
    }
    return;
  }, [show, uniqueClassName]);

  const handleTrigger = useCallback(() => {
    if (disabled) return;
    onChangeShow(!show);
  }, [onChangeShow, disabled, show]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [show, handleClick]);

  const handleClickListItem = useCallback((onClick?: () => void) => {
    if (onClick) onClick();
    onChangeShow(false);
  }, [onChangeShow]);

  return (
    <div className={cx(className, styles.wrapper, uniqueClassName)}>
      <button
        disabled={disabled} onClick={handleTrigger} name={name}
        cypress-id={`${name}-dropdown`} className={cx(styles.trigger, {[styles.active]: show}, triggerClassName)}>
        {children || label}{hideChevron ? null : <Icon className={styles.chevron} icon="chevron-down"/>}
      </button>
      {show
        ?
        <ul className={styles.list}>
          {list.map((item: ListItem, idx: number) => (
            <li role="button" onClick={() => handleClickListItem(item.onClick)} className={cx(styles.listItem, {[styles.withDivider]: item.divider})} key={idx}>
              <div className={styles.listItemContent}>
                {item.to
                  ?
                  <Link to={item.to} className={styles.link}>
                    {item.icon ? <Icon icon={item.icon} className={styles.icon}/> : null}{item.label}
                  </Link>
                  :
                  <div className={styles.link}>
                    {item.icon ? <Icon icon={item.icon} className={styles.icon}/> : null}{item.label}
                  </div>
                }
              </div>
            </li>
          ))}
        </ul>
          :null
      }
    </div>
  )
}

export default Dropdown;
