import {ChangeEvent, FC} from 'react';
import {Props} from './index';
import useEnums from 'hooks/useEnums';
import Field from 'components/field';
import {Input, Select, Form, Textarea, Button} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import helps from './common/helps';

const Rideshare:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {
  const { travelExpensesTypes } = useEnums();

  const priceHelp = (
    <div>
      Please use one of the links to calculate your rideshare fare.
      <a href="https://ride.guru/" target="_blank" rel="noreferrer">RideGuru - Fare Estimates, Uber, Lyft, Taxis, Limos</a>,
      and more you can also use your frequently used rideshare app in order to estimate your trip costs
    </div>
  )


  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name rideshare expense" required placeholder="Enter name Rideshare expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field withHelp top={Boolean(errors['details.price'] || errors['details.companyName'])}>
        <Input placeholder="Price of a Rideshare" required label="Enter the cost of one ride"
               name="price" type="number" onBlur={onBlur}
               value={details.price} errors={errors['details.price']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ price: event.target.value })}
               help={priceHelp} helpDebounce={3000} money
        />
        <Input placeholder="Enter rideshare company name" label="Rideshare company name used" required
               name="companyName"
               value={details.companyName} errors={errors['details.companyName']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ companyName: event.target.value })}
        />
      </Field>
      <Field full>
        <Textarea placeholder="Enter a description..." label="Explain how you arrived at your rideshare price"
               name="priceExplanation"
               value={details.priceExplanation}
               onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeDetails({ priceExplanation: event.target.value })}
        />
      </Field>

      <Field withHelp top={Boolean(errors['details.numberOfRides'] || errors['details.totalCost'])}>
        <Input placeholder="Enter number of similar rides" label="Number of similar Rideshare rides needed for the trip" required
               name="numberOfRides" type="number"
               value={details.numberOfRides} onBlur={onBlur} errors={errors['details.numberOfRides']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfRides: event.target.value })}
               help={helps.rideShareSimilar}
        />
        <Input placeholder="Enter total cost of misc expenses" label="Miscellaneous expenses for rideshare travel for all trip attendees"
               name="totalCost" type="number" money
               value={details.totalCost} onBlur={onBlur} errors={errors['details.totalCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
        />
      </Field>

      <Field>
        <Input placeholder="Amount of compensation before inflation" label="Amount budgeted for Rideshares before inflation"
               name="amountOfCompensationBeforeInflation" money
               value={data.compensationBeforeInflation}
               disabled readOnly
        />
      </Field>
      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Rideshare;
