import React, { useEffect, FC, useCallback, useState } from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import { Button, Checkbox, Form } from 'components/form';
import { useHistory, useLocation } from 'react-router';
import useUI from 'hooks/useUI';
import styles from './prompt.module.css';

type Props = {
  when: boolean;
  name?: string;
  confirm?: () => void;
  cancel?: () => void;
  trackExit?: () => void
}

const Prompt:FC<Props> = (props: Props) => {
  const { when, confirm, cancel ,trackExit} = props;
  const { onHidePrompt, hidePrompt } = useUI();
  const [ hidePromptValue, onChangeHidePromptValue ] = useState<boolean>(hidePrompt);
  const [ show, onChangeShow ] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState('');
  const [nextPath, setNextPath] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (hidePrompt) return;
    if (when && !show) {
      history.block((prompt) => {
        if (location.pathname === prompt.pathname) return;
        setCurrentPath(location.pathname);
        setNextPath(prompt.pathname);
        onChangeShow(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }
    return () => {
      history.block(() => {});
    };
  }, [history, hidePrompt, show, location, when]);

  const handleOk = useCallback(() => {
    if (confirm) confirm();
    if (hidePromptValue) onHidePrompt();
    history.block(() => {});
    history.push(nextPath);
    onChangeShow(false);
  }, [nextPath, history, onHidePrompt, hidePromptValue, confirm]);

  const handleCancel = useCallback(async () => {
    if (cancel) cancel();
    history.block(() => {});
    onChangeShow(false);
  }, [currentPath, history, cancel]);

  useEffect(() => {
    if (hidePrompt) return;
    if(trackExit && show){
      trackExit()
    }
  }, [hidePrompt, show, trackExit]);

  return (
    <div className={cx(styles.wrapper, {[styles.show]: show})}>
      <div className={styles.overlay}/>
      <div className={styles.modal}>
        <div className={styles.iconWrapper}>
          <Icon icon="alert-hexagon" className={styles.icon} size={20}/>
        </div>
        <div className={styles.title}>Want to leave this page?</div>
        <div className={styles.message}>Your changes may not be saved</div>
        <div className={styles.footer}>
          <Button size="lg" onClick={handleCancel} type="bordered" name="cancel-prompt">Cancel</Button>
          <Button size="lg" onClick={handleOk} name="cancel-prompt">Confirm</Button>
        </div>
        <div className={styles.chboxWrapper}>
          <Form>
            <Checkbox value={hidePromptValue} onChange={() => onChangeHidePromptValue(!hidePromptValue)}
                      className={styles.chbox} name="hide-prompt" label="Don't show again"/>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Prompt;
