import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import styles from './compensation.module.css';
import {Button, Form, Input} from 'components/form';
import {isEqual} from 'lodash';
import cx from 'classnames';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

type Props = {
  onBlur: () => void
  onSubmit: () => void
  year: number;
  errors: Errors;
  loading: boolean;
  onChange: (value: any) => void;
  compensation: any;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const monthsFromCurrentGrantHelp = 'You can not request more months than specified in "Number of available months to' +
  ' receive government compensation". If you currently have two months of summer support already funded or you are being supported over nine months by your institution, your institution may suggest that you calculate support based on a percentage of annual salary. Return to the "Position Info" page to change the setting for each year.'

const CompensationMonthForm: FC<Props> = ({
                                            trackError,
                                            trackExit,
                                            trackFormStarted,
                                            compensation,
                                            loading,
                                            errors,
                                            year,
                                            onSubmit,
                                            onBlur,
                                            onChange
                                          }) => {
  const [showHint, setShowHint] = useState<boolean>(false);
  const hint = useMemo(
    () =>
      `On the "Position info" tab, we have already calculated the number of months compensated by the university per year. The calculated value is ${compensation.monthCompensatedByOrganization}. We recommend using this value here to continue the compensation calculation`,
    [compensation]
  );

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleChange = useCallback((field: Record<string, any>) => {
    onChange({
      ...compensation,
      ...field
    });
  }, [onChange, compensation]);

  const handleShowMonthsInYearHint = useCallback(() => {
    setShowHint(true);
  }, [setShowHint]);

  const handleHideMonthsInYearHint = useCallback(() => {
    setShowHint(false);
  }, [setShowHint]);

  const monthsInputLabel = <span>Number of months of compensation <b>already</b> being received by all government grants for summer in Year {year}</span>
  const uncappedMonthsInputLabel = <span>Number of <b>uncompensated</b> months in a Year {year} person is eligible to receive government compensation</span>

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={styles.wbField}>
        <Input name="months"
               label={monthsInputLabel}
               required
               value={compensation.months} type="number" errors={errors[`${year - 1}.months`] || errors.months}
               onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({months: event.target.value})}
               placeholder="Enter number of months of compensation"
        />
      </div>
      <div className={styles.wbField}>
        <Input
          name="monthsInYear"
          value={compensation.monthsInYear}
          type="number"
          hint={showHint ? hint : undefined}
          errors={errors[`${year - 1}.monthsInYear`] || errors.monthsInYear}
          onBlur={() => {
            onBlur();
            handleHideMonthsInYearHint();
          }}
          onFocus={handleShowMonthsInYearHint}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({monthsInYear: event.target.value})}
          label={`Number of months in year getting salary for Year ${year}`}
          required
          placeholder="Enter number of months in year "
        />
      </div>
      <div className={styles.wbField}>
        <Input name="uncappedMonths" value={compensation.uncappedMonths} className={styles.fullInput} disabled readOnly
               label={uncappedMonthsInputLabel}
               placeholder={`Number of uncompensated months in a Year ${year}`}
               help="The maximum number of NSF compensation months is limited to two months. So if there are more than two unlimited months, it must be two months"
        />
      </div>
      <div className={styles.wbField}>
        <Input name="nsfCompensatedMonths" value={compensation.nsfCompensatedMonths} className={styles.fullInput}
               disabled readOnly
               label="Number of months that can be compensated (according to NSF limit)"
               placeholder="Number of months that can be compensated"
        />
      </div>
      <div className={cx(styles.wbField, styles.bb)}>
        <Input name="availableMonths" value={compensation.availableMonths} className={styles.fullInput} disabled
               readOnly
               label={`Number of available months to receive government compensation for in Year ${year}`}
               placeholder="Number of available months to receive compensation"
        />
      </div>
      <div className={styles.wbField}>
        <Input
          name="monthsFromCurrentGrant"
          errors={errors[`${year - 1}.monthsFromCurrentGrant`] || errors.monthsFromCurrentGrant}
          value={compensation.monthsFromCurrentGrant}
          onBlur={onBlur}
          type="number"
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({monthsFromCurrentGrant: event.target.value})}
          label={`Number of months in Year ${year} being requested from current grant`}
          required
          placeholder={`Enter number of months in Year ${year}`}
          help={monthsFromCurrentGrantHelp}
        />
      </div>

      <div className={styles.wbField}>
        <Input name="monthsAvailableAfterRequest" value={compensation.monthsAvailableAfterRequest}
               className={styles.fullInput} disabled readOnly
               label="Number of months of compensation left available after request"
               placeholder="Number of months of compensation left available "
        />
      </div>
      <div className={styles.wbField}>
        <Input
          name="monthlyCompensationBasedOnSalary"
          money
          value={compensation.monthlyCompensationBasedOnSalary}
          className={styles.fullInput}
          disabled
          readOnly
          label="Monthly compensation amount permissible based on salary"
          placeholder="Monthly compensation amount"
        />
      </div>
      <div className={styles.wbField}>
        <Input name="compensationBeforeInflation" money value={compensation.compensationBeforeInflation}
               className={styles.fullInput} disabled readOnly
               label={`Total amount of compensation requested before inflation in Year ${year}`}
               placeholder="Total amount of compensation requested before inflation"
        />


      </div>
      <div className={styles.wbField}>
        <Input name="compensationAfterInflation" money value={compensation.compensationAfterInflation}
               className={styles.fullInput} disabled readOnly
               label={`Total amount of compensation requested after inflation in Year ${year}`}
               help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
               placeholder="Total amount of compensation requested after inflation"
        />
      </div>
      <div className={styles.formFooter}>
        <Button
          htmlType="submit"
          name="submit"
          loading={loading}
          disabled={!isEqual(errors, {})}
          size="xl"
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export default CompensationMonthForm;
