import { useEffect } from "react";
import useAuth from "hooks/useAuth";

type WindowWithChatBot = Window & {
  __be: any;
};
declare const window: WindowWithChatBot;

const ChatBotContainer = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.__be = window.__be || {};
      window.__be.id = "656f14e89705eb000712c5d5";
      const be = document.createElement("script");
      be.type = "text/javascript";
      be.async = true;
      be.id = "ChatBotWidget"
      be.src =
        ("https:" === document.location.protocol ? "https://" : "http://") +
        "cdn.chatbot.com/widget/plugin.js";
      var scriptBe = document.getElementsByTagName("script")[0];
      scriptBe &&
        scriptBe.parentNode &&
        scriptBe.parentNode.insertBefore(be, scriptBe);
    }
    return () => {
      const chatbot = document.getElementById('ChatBotWidget');
      chatbot && chatbot.remove();
    }
  }, []);
  return null;
};

const ChatBotWithUser = () => {
  const { user } = useAuth();
  return user.id ? <ChatBotContainer /> : null;
};

export default ChatBotWithUser;
