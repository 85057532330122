import {FC, useEffect} from 'react';
import {
  Redirect
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import Typo from 'components/typo';
import styles from './protectedRouter.module.css';
import {useIntercom} from "react-use-intercom";

type Props = {
  children: any;
}

type ProcessingProps = {
  onRequest: () => void;
}

const ProcessingModal: FC<ProcessingProps> = ({onRequest}) => {

  useEffect(() => {
    const timer = setInterval(() => {
      onRequest();
    }, 3000);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <Modal visible>
      <div className={styles.processing}>
        <Typo type="h3" className={styles.processingText}>Processing your payment information...</Typo>
        <Typo type="h6" className={styles.processingText}>
          Hold tight while we process your payment information.
          This could take a few minutes, please don't close this window until it completes.
        </Typo>
        <Spinner className={styles.spinner}/>
      </div>
    </Modal>
  )
}

const ProtectedRoute: FC<Props> = ({children}) => {
  const {token, user, paymentProcessing, softLogin} = useAuth();
  const {
    boot,
    shutdown,
  } = useIntercom();

  useEffect(() => {
    return () => {
      shutdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    shutdown()
    return <Redirect from="*" to="/login"/>;
  }

  if (user.id) {
    boot({
      name: user.firstName,
      email: user.email,
      userId: user.id,
    })
  }

  return (
    user.id ? (
      <>
        {children}
        {paymentProcessing ? <ProcessingModal onRequest={softLogin}/> : null}
      </>
    ) : null
  );
};

export const AuthRoute: FC<Props> = ({children}) => {
  const {
    shutdown,
  } = useIntercom();
  const {token} = useAuth();

  useEffect(() => {
    shutdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    return children;
  }
  return <Redirect from="*" to="/grants"/>;
};

export default ProtectedRoute;
