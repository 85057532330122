import React, { CSSProperties, FC } from 'react';
import cx from 'classnames';
import icon from './spinner.svg';
import whiteIcon from './white.svg';
import styles from './spinner.module.css';

type Props = {
  className?: string;
  white?: boolean;
  small?: boolean;
  full?: boolean;
  style?: CSSProperties;
}
const Spinner:FC<Props> = ({ className, style, full, small, white }) => {
  return (
    <div style={style} className={cx(className, {[styles.small]: small, [styles.full]: full}, styles.wrapper)}>
      <img src={white ? whiteIcon : icon} alt="spinner"/>
    </div>
  )
}

export default Spinner;
