import {FC, Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import Typo from 'components/typo';
import {get} from 'lodash';
import {Form, Button, Checkbox} from 'components/form';
import {ListItem} from 'components/dropdown';
import Icon from 'components/icon';
import Modal from 'components/modal';
import RateForm, {RateData, RatePreviewResponse} from 'containers/forms/rate';
import Table, {Sort} from 'components/table';
import useEnums from 'hooks/useEnums';
import styles from './rates.module.css';
import Head from 'containers/head';
import {stringOrNull, stringOrUndefined} from 'helpers/date'
import {trackUserPageview} from 'helpers/trackUserActions';
import useRates from 'hooks/useRates';
import dayjs from 'dayjs';
import {AppliedType, Rate} from 'store/auth/types';
import {FullGrant} from 'store/grants/types';
import {Link} from 'react-router-dom';
import Tip from 'components/tip';
import Filters from './filters';
import Placeholder from './placeholder';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {confirm} from 'components/confirmation';
import {useMixPanel} from "../../hooks/useMixPanel";

function intercept(start1: string, end1: string, start2: string, end2: string) {
  const start1Date = new Date(start1).valueOf();
  const start2Date = new Date(start2).valueOf();
  const end1Date = new Date(end1).valueOf();
  const end2Date = new Date(end2).valueOf();
  return (Math.max(0, Math.min(end2Date, end1Date) - Math.max(start1Date, start2Date) + 1)) > 0;
}

type Search = {
  organizationName: string;
  isAccepted: boolean | undefined;
}

const defaultSearch = {
  organizationName: '',
  isAccepted: undefined
};

const emptyRate = {
  rate: '',
  startDate: null,
  endDate: null,
  organizationName: '',
  location: '',
  comment: '',
}

type SortType = {
  field: string;
  type: 'asc' | 'desc';
}

type Props = {
  grant?: FullGrant;
  grantId?: string;
  titless?: boolean;
}

const defaultSort: SortType = {
  field: '',
  type: 'desc'
};

const defaultTimeline = {
  overlay: {
    startDate: [],
    endDate: [],
  },
  uncoveredPeriods: []
};

const Rates: FC<Props> = ({grant, titless, grantId}) => {
  const {pageViewed} = useMixPanel();
  const {FARateLocations} = useEnums();
  const {
    createUserRate, createGrantRate,
    deleteUserRate, loading, updateUserRate,
    getGrantRatesFromStore, rates, getUserRates,
    updateGrantRate, deleteGrantRate,
    addUserRatesToGrant,
    previewGrantRate, getUserRatesForGrant,
    userRatesForGrant, spinner,
    getGrantRatesPreviewFromStore,
    getGrantRates
  } = useRates();
  const {sidebarWidth} = useUI();
  const grantRates = getGrantRatesFromStore(grant?.id ?? '');
  const [rate, onChangeRate] = useState<RateData | undefined>(undefined);
  const [chosen, onChangeChosen] = useState<string[]>([]);
  const [search, onChangeSearch] = useState<Search>(defaultSearch);
  const [show, onChangeShow] = useState<boolean>(false);
  const [sort, onChangeSort] = useState<SortType>(defaultSort);
  const [timeline, onChangeTimeline] = useState<RatePreviewResponse>(defaultTimeline);
  const [modalSearch, onChangeModalSearch] = useState<Search>(defaultSearch);
  const [modalSort, onChangeModalSort] = useState<SortType>(defaultSort);
  const globalTimeline = getGrantRatesPreviewFromStore(grant?.id);

  useEffect(() => {
    if (grantId) {
      getGrantRates(grantId);
      previewGrantRate(grantId, {});
    } else {
      getUserRates();
    }
    trackUserPageview("Rates");
    //track mix panel Page Viewed event
    pageViewed("Rates")
  }, []);

  const handleChangeSearch = useCallback((field: Record<string, any>) => {
    onChangeSearch({
      ...search,
      ...field
    })
  }, [search]);

  const handleChangeModalSearch = useCallback((field: Record<string, any>) => {
    onChangeModalSearch({
      ...modalSearch,
      ...field
    })
  }, [modalSearch]);

  const onClearSearch = useCallback(() => {
    onChangeSearch(defaultSearch);
  }, []);

  const onClearModalSearch = useCallback(() => {
    onChangeModalSearch(defaultSearch);
  }, []);

  const handleClickSort = useCallback((field: string) => {
    const type = sort.type === 'asc' ? 'desc' : 'asc';
    onChangeSort({
      field: sort.field === field ? sort.field : field,
      type: sort.field === field ? type : sort.type
    });
  }, [sort]);

  const handleClickModalSort = useCallback((field: string) => {
    const type = modalSort.type === 'asc' ? 'desc' : 'asc';
    onChangeModalSort({
      field: modalSort.field === field ? modalSort.field : field,
      type: modalSort.field === field ? type : modalSort.type
    });
  }, [modalSort]);

  const headers = useMemo(() => {
    return [
      <Sort sort={sort} field="organizationName"
            onClick={() => handleClickSort('organizationName')}>Organization</Sort>,
      <Sort sort={sort} field="rate" onClick={() => handleClickSort('rate')}>Rate</Sort>,
      <Sort sort={sort} field="startDate" onClick={() => handleClickSort('startDate')}>Effective start date</Sort>,
      <Sort sort={sort} field="endDate" onClick={() => handleClickSort('endDate')}>Effective end date</Sort>,
      <Sort sort={sort} field="location" onClick={() => handleClickSort('location')}>Location</Sort>,
      'Comment',
      ''
    ]
  }, [sort, handleClickSort]);

  const modalHeaders = useMemo(() => {
    return [
      'Choose',
      <Sort sort={modalSort} field="organizationName"
            onClick={() => handleClickModalSort('organizationName')}>Organization</Sort>,
      <Sort sort={modalSort} field="rate" onClick={() => handleClickModalSort('rate')}>Rate</Sort>,
      <Sort sort={modalSort} field="startDate" onClick={() => handleClickModalSort('startDate')}>Effective start
        date</Sort>,
      <Sort sort={modalSort} field="endDate" onClick={() => handleClickModalSort('endDate')}>Effective end date</Sort>,
      <Sort sort={modalSort} field="location" onClick={() => handleClickModalSort('location')}>Location</Sort>,
      'Comment'
    ]
  }, [modalSort, handleClickModalSort]);

  const onShowAddNewRateModal = useCallback(() => {
    onChangeRate(emptyRate);
  }, []);

  const onCloseRateModal = useCallback(() => {
    onChangeRate(undefined);
    if (grant) onChangeTimeline(defaultTimeline);
  }, [grant]);

  const handleSubmitRate = useCallback(() => {
    if (rate) {
      const data = {
        ...rate,
        rate: Number(rate.rate) / 100,
        startDate: stringOrNull(rate.startDate),
        endDate: stringOrNull(rate.endDate)
      };
      if (rate.id) {
        if (rate.appliedToGrants && rate.appliedToGrants?.length > 0) {
          confirm({
            title: `Rate is applied to grant${rate.appliedToGrants.length > 1 ? 's' : ''}`,
            text: 'The rate value will be updated only in the repository. To update the rate value in each grant, please use the F&A Rates section for each grant',
            type: 'success',
            icon: 'alert-hexagon',
            okText: 'Continue',
            onConfirm: () => {
              if (rate?.id) updateUserRate(rate.id, data, onCloseRateModal)
            },
          });
          return;
        }
        updateUserRate(rate.id, data, onCloseRateModal);
        return;
      }
      createUserRate(data, onCloseRateModal);
    }
  }, [createUserRate, updateUserRate, onCloseRateModal, rate]);

  const handleSubmitGrantRate = useCallback(() => {
    if (rate && grant?.id) {
      const data = {
        ...rate,
        rate: Number(rate.rate) / 100,
        startDate: stringOrNull(rate.startDate),
        endDate: stringOrNull(rate.endDate)
      };
      if (rate.id) {
        const originalRate = grantRates.find((item: Rate) => item.id === rate.id);
        if (originalRate?.rate !== rate.rate) {
          confirm({
            title: `Rate value will be overridden`,
            text: 'This can result in your budget being over or under your planned target budget. Indirect costs will be recalculated.',
            type: 'success',
            icon: 'alert-hexagon',
            okText: 'Continue',
            onConfirm: () => {
              if (rate?.id) updateGrantRate(grant.id, rate.id, data, onCloseRateModal);
            },
          });
          return;
        }
        updateGrantRate(grant.id, rate.id, data, onCloseRateModal);
        return;
      }
      if (data.addToUser) {
        createUserRate(data, (response: RateData) => {
          addUserRatesToGrant(grant.id, {ids: [response.id as string]}, onCloseRateModal);
        });
        return;
      }
      createGrantRate(grant.id, data, onCloseRateModal);
    }
  }, [createUserRate, grantRates, addUserRatesToGrant, updateGrantRate, grant, createGrantRate, onCloseRateModal, rate]);

  const handleDeleteRate = useCallback((rate: Rate) => {
    const text = `Are you sure you want to delete this rate? For the period from ${dayjs(rate.startDate).format('MM/DD/YYYY')} to ${dayjs(rate.endDate).format('MM/DD/YYYY')} indirect costs won't be calculated`
    if (grant?.id) {
      confirm({
        title: 'Rate is applied to grant(s)',
        text,
        type: 'error',
        icon: 'trash-01',
        okText: 'Delete',
        onConfirm: () => {
          deleteGrantRate(grant.id, rate.id, () => previewGrantRate(grant.id, {}));
        }
      })
      return;
    }
    deleteUserRate(rate.id, rate.appliedToGrants.length > 0);
  }, [deleteUserRate, grant, previewGrantRate, deleteGrantRate])

  const handleEditRate = useCallback((rate: RateData) => {
    onChangeRate({
      ...rate,
      startDate: new Date(rate.startDate!),
      endDate: new Date(rate.endDate!)
    });
  }, []);

  const handleShowRatesModal = useCallback(() => {
    if (grant) {
      getUserRatesForGrant(grant.id, () => {
        onChangeShow(true);
      })
    }
  }, [grant, getUserRatesForGrant]);

  const menu: ListItem[] = [
    {
      label: 'Add new rate',
      icon: 'plus',
      onClick: onShowAddNewRateModal
    },
    {
      label: 'Choose from repository',
      icon: 'percent-02',
      onClick: handleShowRatesModal
    },
  ];

  const handleCloseRepoModal = useCallback(() => {
    onChangeModalSearch(defaultSearch);
    onChangeModalSort(defaultSort);
    onChangeChosen([]);
    onChangeShow(false);
    onChangeTimeline(defaultTimeline);
    if (grant) previewGrantRate(grant.id, {});
  }, [previewGrantRate, grant]);

  const handleChangeChosen = useCallback((rateId: string) => {
    const newChosen = chosen.includes(rateId) ? chosen.filter((id: string) => id !== rateId) : [...chosen, rateId];
    onChangeChosen(newChosen);
  }, [chosen]);

  const getAppliedList = useCallback((rate: Rate) => {
    return (
      <div className={styles.appliedListWrapper}>
        Applied to grants:
        <div className={styles.appliedList}>
          {rate.appliedToGrants.map((item: AppliedType, idx: number) => (
            <Link key={idx} to={`/grants/${item.id}/facilities/rates`}>{item.title}</Link>
          ))}
        </div>
      </div>
    );
  }, []);

  const isAccepted = useMemo(() => {
    if (grant) return Boolean(grant.acceptedForm.awardNumber);
    return false;
  }, [grant]);

  const tableData = useCallback((modal?: boolean) => {
    let data = grant?.id ? grantRates : rates.data;
    if (modal) {
      const grantRateIds = grantRates.map((item: Rate) => item.id);
      data = userRatesForGrant.data.filter((item: Rate) => !grantRateIds.includes(item.id));
    }
    const searchLine = modal ? modalSearch : search;
    const sortData = modal ? modalSort : sort;
    let list = data
      .filter((rate: Rate) => {
        const correctSearch = searchLine.organizationName.toLowerCase().replace(/ /ig, '');
        return rate.organizationName.toLowerCase().replace(/ /ig, '').includes(correctSearch)
      })
      .filter((rate: Rate) => {
        const {isAccepted} = searchLine;
        if (isAccepted !== undefined) {
          return !!rate.appliedToGrants.find((item: AppliedType) => item.isAccepted === isAccepted);
        }
        return true;
      })
    if (sortData.field) {
      list = list.sort((a: Rate, b: Rate) => {
        let bigger = sortData.type === 'desc' ? -1 : 1;
        let lower = sortData.type === 'desc' ? 1 : -1;
        let aValue = get(a, sortData.field);
        let bValue = get(b, sortData.field);
        if (['startDate', 'endDate'].includes(sortData.field)) {
          bigger = sortData.type === 'desc' ? 1 : -1;
          lower = sortData.type === 'desc' ? -1 : 1;
        }
        if (sortData.field === 'organizationName') {
          aValue = String(aValue).toLowerCase();
          bValue = String(bValue).toLowerCase();
        }
        if (sortData.field === 'rate') {
          aValue = Number(aValue);
          bValue = Number(bValue);
        }
        if (aValue === bValue) return 0;
        if (aValue > bValue) return bigger;
        return lower;
      });
    }
    return list
      .map((rate: Rate, index: number) => {
        const location = FARateLocations.find(item => item.value === rate.location);
        const acceptedGrants = rate.appliedToGrants.filter((applied: AppliedType) => applied.isAccepted);
        const userRateAccepted = !grant && acceptedGrants.length > 0;
        const array = [
          <span className={styles.gray}>{rate.organizationName}</span>,
          <span className={cx(styles.gray, styles.cell)}>
            {`${Number(rate.rate).toFixed(0)}%`}
            {(modal || !grant ? true : !grant) && rate.appliedToGrants.length
              ?
              <Tip debounced text={getAppliedList(rate)}>
                <span className={styles.greenDot}>{rate.appliedToGrants.length}</span>
              </Tip>
              : null
            }
          </span>,
          <span className={styles.gray}>{dayjs(rate.startDate).format('MM/DD/YYYY')}</span>,
          <span className={styles.gray}>{dayjs(rate.endDate).format('MM/DD/YYYY')}</span>,
          <span className={styles.gray}>{location?.label ?? '-'}</span>,
          <div className={styles.center}>
            {rate.comment ? <Icon className={styles.icon} icon="check"/> : ''}
          </div>
        ];

        const chosenRates = list
          .filter((item: Rate) => item.id !== rate.id)
          .filter((item: Rate) => chosen.includes(item.id));

        const disabled = chosenRates
          .filter((item: Rate) => {
            return intercept(rate.startDate, rate.endDate, item.startDate, item.endDate);
          })
          .length > 0;

        return modal
          ? [
            <Form>
              <Checkbox disabled={rate.isOverlays || disabled} name={`check-${index}`} value={chosen.includes(rate.id)}
                        onChange={() => handleChangeChosen(rate.id)}/>
            </Form>,
            ...array
          ]
          : [
            ...array,
            <div className={styles.rateIcons}>
              <Icon
                onClick={() => handleEditRate(rate)}
                className={cx(styles.icon, styles.rateIcon)}
                icon="edit-04"
              />
              <Tip when={isAccepted || userRateAccepted} textClassName={styles.tip} className={styles.rateIcon}
                   text="Rates applied to won grants with a completion Acceptance form cannot be deleted" left>
                <Icon disabled={isAccepted || userRateAccepted}
                      onClick={() => isAccepted || userRateAccepted ? null : handleDeleteRate(rate)}
                      className={cx(styles.icon, {[styles.disabled]: isAccepted || userRateAccepted})}
                      icon="trash-01"
                />
              </Tip>
            </div>
          ];
      });
  }, [
    chosen, handleChangeChosen,
    sort, modalSearch, modalSort, grant,
    grantRates, search, FARateLocations,
    rates, handleEditRate, handleDeleteRate,
    getAppliedList,
    isAccepted, userRatesForGrant
  ]);

  const onSubmitRepoModal = useCallback(() => {
    if (grant?.id) {
      addUserRatesToGrant(grant.id, {ids: chosen}, handleCloseRepoModal);
    }
  }, [grant, addUserRatesToGrant, chosen, handleCloseRepoModal]);

  const handleBlurDates = useCallback(() => {
    if (grant && rate) {
      const data = rate.startDate && rate.endDate
        ? {
          startDate: stringOrUndefined(rate.startDate),
          endDate: stringOrUndefined(rate.endDate),
          ...(rate.id ? {rateId: rate.id} : {})
        }
        : {};
      previewGrantRate(grant.id, data, onChangeTimeline);
    }
  }, [rate, previewGrantRate, grant]);

  const notFoundRates = useMemo(() => {
    const data = grant?.id ? grantRates : rates.data;
    return data.length > 0;
  }, [grant, grantRates, rates]);

  const timelinePlaceholder = useMemo(() => {
    if (grant && globalTimeline.uncoveredPeriods.length > 0) {
      const s = globalTimeline.uncoveredPeriods.length > 1 ? 's' : '';
      const title = `Rate${s} missed for the following grant period${s}:`
      return (
        <div className={styles.warning}>
          <div className={styles.warningTitle}>
            <Icon className={styles.warningIcon} size={20} icon="alert-triangle"/>
            <Typo className={styles.warningText}>{title}</Typo>
          </div>
          <ul className={styles.uncovered}>
            {globalTimeline.uncoveredPeriods.map((dates: string[], idx: number) => (
              <li key={idx}>
                <Typo className={styles.li}>
                  Rate is missed from {dayjs(dates[0]).format('MM/DD/YYYY')} to {dayjs(dates[1]).format('MM/DD/YYYY')}
                </Typo>
              </li>))
            }
          </ul>
        </div>
      )
    }
    return null;
  }, [grant, globalTimeline]);

  const body = (
    <Fragment>
      <div className={styles.body}>
        {timelinePlaceholder}
        <Filters grant={Boolean(grant?.id)} search={search} onChange={handleChangeSearch}
                 menu={menu} onClickAdd={onShowAddNewRateModal}
                 onClear={onClearSearch}
        />
        <Table data={tableData()} headers={headers}
               placeholder={<Placeholder notFound={notFoundRates} onAddRate={onShowAddNewRateModal}/>}
        />
      </div>
      {spinner ? <Spinner style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full/> : null}
      <Modal visible={Boolean(rate)} onCancel={onCloseRateModal} title={rate?.id ? 'F&A Rate' : 'Add a new F&A Rate'}>
        <>
          {rate
            ? <RateForm data={rate} update={Boolean(rate.id)} onChange={onChangeRate}
                        onSubmit={grant?.id ? handleSubmitGrantRate : handleSubmitRate}
                        loading={loading} onBlur={handleBlurDates} timeline={timeline}
                        onCancel={onCloseRateModal} grant={Boolean(grant)} accepted={isAccepted}
            />
            : null}
        </>
      </Modal>
      <Modal visible={show} fb onCancel={handleCloseRepoModal} title="F&A Rates">
        <>
          <div className={styles.body}>
            <Filters search={modalSearch} onChange={handleChangeModalSearch}
                     menu={menu} onClickAdd={onShowAddNewRateModal}
                     onClear={onClearModalSearch} hideAdd
            />
            <Table data={tableData(true)} headers={modalHeaders}
                   placeholder={<Placeholder notFound={rates.data.length > 0} onAddRate={onShowAddNewRateModal}
                                             redirect/>}
            />
          </div>
          <div className={styles.footer}>
            <Button name="close-repo-modal" type="bordered" onClick={handleCloseRepoModal}>Cancel</Button>
            <Button name="submit-repo-modal" loading={loading} onClick={onSubmitRepoModal}>Save</Button>
          </div>
        </>
      </Modal>
    </Fragment>
  );

  const head = (
    <div className={styles.titleWrapper}>
      <Typo type="h3" semi className={styles.title}>Repository. Facilities and Administrations Rates (F&A Rates)</Typo>
      <Typo type="p" size={16} className={styles.subtitle}>
        Facilities and Administrations Rate (F&A Rate) is a rate negotiated between your institution and the government
        to roughly determine what amount of money your university is entitled to perform services to not only your work
        but also others' work as well.
      </Typo>
      <Typo type="p" size={16} className={styles.subtitle}>
        Think of this as either a tax that goes to your university to keep the lights on or money distributed to the
        general staff, general maintenance, and university-wide resources. Normally the F&A rates could be found on the
        same page that their university has posted the rates on their website.
      </Typo>
    </div>
  )

  return (titless
      ? body
      : <>
        <Head title="F&A Rates"/>
        <div className="container">
          {head}
          {body}
        </div>
      </>
  );
}

export default Rates;
