import StatesForm from './48';
import International from './inter';
import AlaskaHawaiiForm from './alaska';
import OtherForm from './other';
import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import { Travel } from 'store/grants/types';

export type Props = {
  data: T.TravelCostData;
  loading?: boolean;
  isUpdate?: boolean;
  errors: Errors;
  travel: Travel;
  onSubmit: () => void;
  onBlur?: () => void;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
}
export type CommonProps = {
  data: T.TravelCostData;
  errors: Errors;
  inter?: boolean;
  hideText?: boolean;
  optional?: boolean;
  onChange: (field: Record<string, any>) => void;
}

export default {
  StatesForm,
  OtherForm,
  International,
  AlaskaHawaiiForm
}
