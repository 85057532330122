import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Select} from 'components/form'
import {Contract} from 'store/grants/otherDirectCosts/Contracts/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getContractFromODC} from 'helpers/getFromGrant';
import useContracts from 'hooks/useContracts';
import useEnums from 'hooks/useEnums';
import styles from './cs.module.css';

type Props = {
  item: Contract;
  grantId: string;
}

const ContractInfoForm: FC<Props> = ({item, grantId}) => {
  const [data, onChangeData] = useState<Contract>(item);
  const {yesNoOptions} = useEnums();
  const {
    updateContract, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = useContracts({
    form_page_type: 'Contract info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(() => {
    updateContract(grantId, item.id, {
      name: data.name,
      isInflationAppliedEachYear: data.isInflationAppliedEachYear
    })
  }, [data, grantId, item, updateContract])

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field last withHelp>
        <Input name="name"
               placeholder="Enter a name"
               value={data.name}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, name: event.target.value})}
               label="Contract Name" required/>
        <Select name="isInflationAppliedEachYear"
                placeholder="Select the item"
                help="Inflation is normally applied to contract values" bool
                value={data.isInflationAppliedEachYear} options={yesNoOptions}
                onChange={(isInflationAppliedEachYear: any) => onChangeData({...data, isInflationAppliedEachYear})}
                label="Is inflation applied to this contract each year?" required/>
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const ContractPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getContractFromODC(grant, params.contractId);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={`Contracts. Contract ${index > 0 ? index : ''}`}>
      {
        item
          ? <ContractInfoForm grantId={grant.id} key={`${grant.id}-odc-contracts-${params.contractId}`} item={item}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default ContractPage;
