import {FC, useCallback, useMemo, useState} from 'react';
import {times} from 'lodash';
import {FormProps} from '../index';
import Typo from 'components/typo';
import Season from './season';
import { TravelCostSeason} from 'store/grants/types';
import styles from './alaska.module.css';
import useTravel from 'hooks/useTravel';
import {stringOrUndefined} from 'helpers/date';
import {Button} from 'components/form';

const AlaskaHawaiiLodging: FC<FormProps> = ({cost, inter, travelId, grantId}) => {
  const {
    updateTravelCost, previewTravelCost, errors, trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: "Lodging Breakdown"
  });

  const defaultSeason = useMemo(() =>  ({
    serialNumber: 1,
    startDateOfTravel: null,
    endDateOfTravel: null,
    lodgingRate: '',
    maxTotal: '',
    nights: '',
    doBudgetMaxAmount: false,
    maxTotalAdjust: '',
    total: '',
  }),[])

  const [seasons, onChangeSeasons] = useState<TravelCostSeason[]>(() => {
    return times(Number(cost.numberOfSeasons || 1), (i: number) => {
      let seasonInCost = cost.lodgingBreakdown.seasons?.find((item: TravelCostSeason) => item.serialNumber === i + 1);
      if (seasonInCost) {
        seasonInCost = {
          ...seasonInCost,
          startDateOfTravel: seasonInCost.startDateOfTravel ? new Date(seasonInCost.startDateOfTravel) : undefined,
          endDateOfTravel: seasonInCost.endDateOfTravel ? new Date(seasonInCost.endDateOfTravel) : undefined,
        }
      }
      return seasonInCost ?? {
        ...defaultSeason,
        serialNumber: i + 1
      }
    })
  });
  const zero = Number(cost.numberOfSeasons) < 1;

  const onBlur = useCallback((event: any, season: TravelCostSeason) => {
    if (cost) {
      let canMakeRequest = season.startDateOfTravel && season.endDateOfTravel;
      if (zero) canMakeRequest = season.nights && Boolean(season.lodgingRate);
      if (season.doBudgetMaxAmount) canMakeRequest = canMakeRequest && !!season.maxTotalAdjust;
      if (!canMakeRequest) return;
      let submitData = (cost.lodgingBreakdown.seasons ?? [])?.map((item: TravelCostSeason) => {
        if (item.serialNumber === season.serialNumber) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: TravelCostSeason) => item.serialNumber === season.serialNumber);
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: TravelCostSeason) => ({
        ...item,
        nights: zero ? item.nights : undefined,
        startDateOfTravel: stringOrUndefined(item.startDateOfTravel),
        endDateOfTravel: stringOrUndefined(item.endDateOfTravel),
      }))
      previewTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        lodgingBreakdown: {
          seasons: submitData
        }
      }, (response: any) => {
        const responseSeasons: TravelCostSeason[] = response?.lodgingBreakdown?.seasons ?? [];
        const newSeasons = seasons.map((item: TravelCostSeason) => {
          const seasonFromResponse = responseSeasons.find((resp: TravelCostSeason) => resp.serialNumber === item.serialNumber);
          if (seasonFromResponse) {
            return {
              ...item,
              ...seasonFromResponse,
              startDateOfTravel: seasonFromResponse.startDateOfTravel ? new Date(seasonFromResponse.startDateOfTravel) : item.startDateOfTravel,
              endDateOfTravel: seasonFromResponse.endDateOfTravel ? new Date(seasonFromResponse.endDateOfTravel) : item.endDateOfTravel,
            }
          }
          return item;
        });
        onChangeSeasons(newSeasons);
      })
    }
  }, [cost, zero, grantId, seasons, onChangeSeasons, travelId, previewTravelCost]);

  const handleChangeSeason = useCallback((idx: number) => (field: Record<string, any>, blur?: boolean) => {
    const newSeasons = seasons.map((item: TravelCostSeason) => {
      if (item.serialNumber === idx) return {...item, ...field};
      return item;
    });
    onChangeSeasons(newSeasons);
    if (blur) {
      const season = newSeasons.find((item: TravelCostSeason) => (item.serialNumber === idx));
      if (season) onBlur(undefined, season);
    }
  }, [seasons, onBlur]);

  const onSubmitOneSeason = useCallback((season: TravelCostSeason) => {
    if (cost) {
      let submitData = (cost.lodgingBreakdown.seasons ?? [])?.map((item: TravelCostSeason) => {
        if (item.serialNumber === season.serialNumber) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: TravelCostSeason) => item.serialNumber === season.serialNumber);
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: TravelCostSeason) => ({
        ...item,
        nights: zero ? item.nights : undefined,
        startDateOfTravel: stringOrUndefined(item.startDateOfTravel),
        endDateOfTravel: stringOrUndefined(item.endDateOfTravel),
      }))
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        lodgingBreakdown: {
          seasons: submitData
        }
      })
    }
  }, [cost, zero, grantId, travelId, updateTravelCost]);

  const onSubmitAllSeasons = useCallback(() => {
    if (cost) {
      const submitData = seasons.map((item: TravelCostSeason) => ({
        ...item,
        nights: zero ? item.nights : undefined,
        startDateOfTravel: stringOrUndefined(item.startDateOfTravel),
        endDateOfTravel: stringOrUndefined(item.endDateOfTravel),
      }))
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        lodgingBreakdown: {
          seasons: submitData
        }
      })
    }
  }, [cost, zero, seasons, grantId, travelId, updateTravelCost]);


  const onClear = useCallback((idx: number | string) => {
    onSubmitOneSeason({
      ...defaultSeason,
      serialNumber: idx
    });
    const newSeasons = seasons.map((item: TravelCostSeason) => {
      if (item.serialNumber === idx) {
        return {
          ...defaultSeason,
          serialNumber: idx
        }
      }
      return item;
    });
    onChangeSeasons(newSeasons);
  }, [defaultSeason, seasons, onSubmitOneSeason])

  return (
    <div>
      {
        zero
          ? null
          :
          <>
            <Typo className={styles.text}>If your trip extends to more than one season, please split your travel
              dates by season.</Typo>
            <Typo className={styles.text}>For example, if the season changes in the middle of your trip, please
              provide us with two date ranges. First one: from the starting date of your trip to the end date of the
              first
              season. Second one: from the first day of the second season until the last day of your trip. If your trip
              extends across more than 2 seasons, split your travel dates analogically into 3, 4, etc. date
              ranges.</Typo>
          </>
      }
      <div className={styles.seasons}>
        {seasons.map((item: TravelCostSeason, idx: number) => (
          <Season inter={inter} onBlur={onBlur} zero={zero} errors={errors} idx={idx}
                  onSubmit={onSubmitOneSeason} item={item}
                  onChange={handleChangeSeason(Number(item.serialNumber))} key={idx}
                  onClear={onClear}
                  trackError={trackError}
                  trackExit={trackExit}
                  trackFormStarted={trackFormStarted}
          />
        ))}
      </div>
      {zero || seasons.length < 2
        ? null :
        <div className={styles.footer}>
          <Button onClick={onSubmitAllSeasons} name="submit-all-seasons">Save all</Button>
        </div>
      }
    </div>
  );
}

export default AlaskaHawaiiLodging;
