import { FC } from 'react';
import { Button } from 'components/form';
import Typo from 'components/typo';
import styles from './signUp.module.css';

type Props = {
  onSubmit: () => void;
}

const SignUpStepThree:FC<Props> = ({ onSubmit }) => {

  return (
    <div className={styles.content}>
      <Typo type="h3" className={styles.title}>Verify account</Typo>
      <Typo type="p" size={16} className={styles.verifySubtitle}>
        We’ve sent account verification link to your email address.
        Please click on the link given in the email to verify your account.
      </Typo>
      <Typo type="div" className={styles.verifyText}>
        <>If you didn't receive email,
          <Button type="link" name="resend" onClick={onSubmit} className={styles.greenText}>click here.</Button></>
      </Typo>
    </div>
  );
}

export default SignUpStepThree;
