import {
  FC,
  useState,
  CSSProperties,
  Fragment,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  ReactElement
} from 'react';
import cx from 'classnames';
import chevron from './chevron.svg';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import {useFormContext, Controller} from 'react-hook-form';
import {findInputError, RHErrors} from 'helpers/errors';

type Option = {
  label: string | ReactElement | number;
  value: any;
  disabled?: boolean;
}

type Props = {
  name: string;
  options: Option[];
  placeholder?: string;
  value?: string | number | null | boolean;
  onChange?: (value: any) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  scroll?: boolean;
  inputClassName?: string;
  help?: ReactElement | string;
  pure?: boolean;
  bool?: boolean;
  className?: string;
  defaultValueToScroll?: string;
  overflow?: boolean;
  helpInLabel?: boolean;
}

const DatepickerSelect:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label, defaultValueToScroll,
    className, errors = [], required,
    disabled, inputClassName, hint, pure,
    options, placeholder = 'Choose from list',
    help, overflow, helpInLabel, scroll, bool
  } = props;
  const [show, onChangeShow] = useState<boolean>(false);
  const input = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const uniqueClassName = useMemo(() => `bm-select-${name}`, [name]);

  const handleClick = useCallback((event: any) => {
    const inSelect = event.target?.closest(`.${uniqueClassName}`);
    if (show && !inSelect) {
      onChangeShow(false);
    }
    return;
  }, [show, uniqueClassName]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [show, handleClick]);

  const {
    formState,
    setValue,
    clearErrors,
    control
  } = useFormContext();

  const handleChange = useCallback((value: string | boolean | number | undefined | null) => {
    if (onChange) onChange(value);
    setValue(`${name}-datepicker-select`, value);
    if (required && (value !== undefined || value !== null)) clearErrors(`${name}-datepicker-select`);
    onChangeShow(false);
  }, [onChangeShow, setValue, clearErrors, name, onChange]);

  const handleTrigger = useCallback(() => {
    if (disabled) return;
    if (listRef.current && scroll) {
      const liClass = `${name}-li-${value ?? defaultValueToScroll}`;
      const elem = listRef.current?.getElementsByClassName(liClass)[0];
      const coords = elem?.getBoundingClientRect();
      const newY = coords?.y - 530;
      if (newY > 440) listRef.current.scrollTo(0, newY);
    }
    onChangeShow(true);
  }, [onChangeShow, scroll, defaultValueToScroll, name, listRef, value, disabled]);


  const overflowStyles:CSSProperties = useMemo(() => {
    if (input.current && overflow) {
      const rect = input.current?.getBoundingClientRect();
      return {
        left: rect.x,
        position: 'fixed',
        top: rect.y + rect.height,
        width: rect.width,
        minWidth: rect.width
      };
    }
    return {}
  }, [input.current, overflow]);
  const inputErrors = findInputError(formState.errors as RHErrors, `${name}-datepicker-select`);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;
  const rules = {
    required: {
      value: Boolean(required),
      message: 'Field is required'
    },
    value: bool ? options.map((item: Option) => item.value) : undefined
  };

  const currentOption = useMemo(() => options.find((item: Option) => item.value === value), [options, value]);

  return (
    <Field help={help} helpInLabel={helpInLabel} className={className} required={required} label={label} hint={hint}
           errors={errorsList} name={name}>
      <Controller
        name={`${name}-datepicker-select`}
        control={control}
        defaultValue={value}
        rules={rules}
        render={({ field }) => (
          <div ref={input} className={cx(styles.selectWrapper, {[styles.pureSelect]: pure}, uniqueClassName)}>
            <input type="hidden" value={field.value} ref={field.ref} name={`${name}-datepicker-select`}/>
            <div onClick={handleTrigger} cypress-id={`${name}-trigger`} className={cx(styles.input, styles.select, inputClassName, {
              [styles.hasError]: invalid,
              [styles.disabled]: disabled,
              [styles.placeholder]: placeholder && !currentOption?.label,
              [styles.open]: show
            })}>
              {currentOption?.label || placeholder}
              <img src={chevron} className={styles.chevron} alt="chevron"/>
            </div>

            <Fragment>
              <ul style={overflowStyles} ref={listRef} className={cx(styles.list, {[styles.hiddenList]: !show})}>
                {options.map((item: Option, idx: number) => (
                  <li role="button" cypress-id={`${name}-list-item-${item.value}`}
                      className={cx(styles.selectItem, `${name}-li-${item.value}`, {[styles.activeSelectItem]: item.value === value, [styles.disabledOption]: item.disabled})} key={idx}
                      onClick={() => {
                        if (item.disabled) {
                          return
                        }
                        handleChange(item.value);
                        field.onChange(item.value);
                      }}>{item.label}</li>
                ))}
              </ul>
            </Fragment>
          </div>
        )}
      />
    </Field>
  )
}

export default DatepickerSelect;
