import {FC, FunctionComponent, createElement, useMemo} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {Travel, TravelCostData} from 'store/grants/types';
import MiE48Breakdown from './48';
import MiEAlaskaHawaiiBreakdown from './alaska';
import {Kind} from 'const';


export type FormProps = {
  cost: TravelCostData;
  inter?: boolean;
  travelId: string;
  grantId: string;
}

const CostBreakdownComponent:FC = () => {
  const params:Record<string, string> = useParams();
  const { grant } = useGrants();
  const travel = getTravelFromGrant(grant, params.travelId);
  const { cost } = getCostFromTravel(travel.trip as Travel, params.costId);
  const key = `${grant.id}-${params.travelId}-${params.costId ?? 'cost'}`;
  const subtitle = useMemo(() => {
    if (cost && cost.kind === 'alaskaHawaii') {
      return `Travel Costs. Alaska, Hawaii. Meals Expenses Breakdown per person`
    }
    if (cost && cost.kind === 'fourty8states') {
      return `Travel Costs. 48 states. Meals & Incidental Expenses (M&IE) Breakdown per person`
    }
    if (cost && cost.kind === 'international') {
      return `Travel Costs. International. M&IE Breakdown per person`
    }
    return 'Travel Costs';
  }, [cost]);

  const form:Record<Kind, FunctionComponent<FormProps>> = {
    fourty8states: MiE48Breakdown,
    other: MiE48Breakdown,
    international: MiEAlaskaHawaiiBreakdown,
    alaskaHawaii: MiEAlaskaHawaiiBreakdown
  };

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      {cost && cost.kind && form[cost.kind as Kind] ? createElement(form[cost.kind as Kind], {
        key,
        grantId: grant.id,
        inter: cost.kind === 'international',
        cost,
        travelId: params.travelId
      }) : undefined}
    </GrantFormWrapper>
  );
}

export default CostBreakdownComponent;
