import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AxiosResponse} from 'axios';
// hooks
import useUI from './useUI';
import useApi from './useApi';
// types
import {PublicationsUnit} from 'hooks/requestTypes/grant/otherDirectCosts/publications/types';
// actions
import {
  addPublicationsUnitAction
} from 'store/grants/otherDirectCosts/Publications/actions';
// components
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";

type UsePublicationsType = {
  isLoading: boolean;
  createUnit: (grantId: string) => void;
  updateUnit: (grantId: string, unitId: string, data: PublicationsUnit) => void;
  deleteUnit: (grantId: string, id: string, redirect: boolean) => void;
  previewUnitYear: (
    grantId: string,
    unitId: string,
    data: any,
    cb: (data: any) => void
  ) => void;
  totals: string[][];
  getTotals: (grantId: string) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
};

type Props = {
  form_page_type?: string;
}

const usePublications = (props: Props = {}): UsePublicationsType => {
  const form_page_type = props.form_page_type || '';
  const form_subcategory = 'publications';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();

  const {getOneWithSide} = useGrants();
  const {
    addPublicationsUnit,
    updatePublicationsUnit,
    deletePublicationsUnit,
    previewPublicationsUnitYear,
    getPublicationsTotals
  } = useApi();
  const {loader, onShowNavigationMessage} = useUI();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState<string[][]>([]);

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      'other direct costs',
      form_subcategory || null,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      'other direct costs',
      form_subcategory || null,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const createUnit = useCallback(
    (grantId: string) => {
      const emptyUnit = {name: null, currentCost: null};
      setIsLoading(true);
      addPublicationsUnit(grantId, emptyUnit)
        .then((response: AxiosResponse) => {
          formSuccessSaveCallBack()
          setIsLoading(false);
          history.push(`/grants/${grantId}/otherDC/publications/${response.data.id}/edit`);
          setTimeout(() => {
            dispatch(addPublicationsUnitAction(response.data));
          }, 300);
        })
        .catch(() => {
          setIsLoading(false);
        });
      trackFormSaveAttempted()
    },
    [addPublicationsUnit, dispatch, formSuccessSaveCallBack, history, trackFormSaveAttempted],
  );

  const updateUnit = useCallback((grantId: string, unitId: string, data: PublicationsUnit) => {
    setIsLoading(true);
    updatePublicationsUnit(grantId, unitId, data)
      .then(() => {
        formSuccessSaveCallBack()
        setIsLoading(false);
        onShowNavigationMessage();
        toast.success({
          title: 'The unit details have been updated',
          message: 'Changes have been successfully saved',
        });
        getOneWithSide(grantId);
      })
      .catch(() => {
        setIsLoading(false);
      });
    trackFormSaveAttempted()
  }, [formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage, trackFormSaveAttempted, updatePublicationsUnit]);

  const deleteUnit = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete unit info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        deletePublicationsUnit(grantId, id).then(() => {
          toast.success({
            title: 'The unit has been deleted',
            message: 'Changes have been successfully saved',
          });
          getOneWithSide(grantId, () => {
            if (redirect) history.push(`/grants/${grantId}/edit`);
          });
        });
      },
    });
  }, [getOneWithSide, history, deletePublicationsUnit]);

  const previewUnitYear = useCallback(
    (grantId: string, unitId: string, data: any, cb: (data: any) => void) => {
      loader.start();
      const q = new URLSearchParams(data).toString();
      previewPublicationsUnitYear(grantId, unitId, `?${q}`)
        .then((response: AxiosResponse) => {
          loader.stop();
          cb(response.data);
        })
        .catch(() => {
          loader.stop();
        });
    },
    [previewPublicationsUnitYear, loader],
  );

  const getTotals = useCallback((grantId: string) => {
    setIsLoading(true);
    getPublicationsTotals(grantId)
      .then((response: AxiosResponse) => {
        setTotals(response.data.totals)
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [getPublicationsTotals])


  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      'other direct costs',
      form_subcategory || null,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      'other direct costs',
      form_subcategory || null,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      'other direct costs',
      form_subcategory || null,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    isLoading,
    createUnit,
    updateUnit,
    deleteUnit,
    previewUnitYear,
    totals,
    getTotals,
    trackError,
    trackExit,
    trackFormStarted
  };
};

export default usePublications;
