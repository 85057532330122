import {ChangeEvent, FC} from 'react';
import Field from 'components/field';
import {Input, Form, Select, Button} from 'components/form';
import useEnums from 'hooks/useEnums';
import styles from './breakdown.module.css';
import {MaIEBreakdownDay} from 'store/grants/types';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

type Props = {
  data: MaIEBreakdownDay;
  onSubmit: () => void;
  onBlur: () => void;
  errors: Errors;
  loading: boolean;
  field: 'firstDay' | 'lastDay';
  onChange: (field: Record<string, any>, preview?: boolean) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const FirstDayForm: FC<Props> = ({
                                   trackError,
                                   trackExit,
                                   data, onBlur, field, errors, loading, onSubmit, onChange
                                 }) => {
  const {yesNoOptions} = useEnums();

  const help = field === 'lastDay'
    ? "Please note that if you are adding rates that continue from a certain date, please make sure that you aren't using the start date for this rate as the end date for the prior rate. The end date of a date range should always be a different date than the start date of the following date range that continues the journey. If this trip does lie back to back with a previous date range, please start the following date range on the date directly after this ending date and check this box. By checking this box, we will do the calculation to make sure that you get the full amount of money you are entitled to budgeting for this day."
    : "Please note that if you are adding rates that continue from a certain date, please make sure that you aren't using the start date for this rate as the end date for the prior rate. The end date of the previous date range should always be a different date than the start date of your date range that continues the journey. If this trip does lie back to back with a previous date range, please select \"yes\". By selecting \"yes\", we will do the calculation to make sure that you get the full amount of money you are entitled to budgeting in for this day.";

  return (
    <Form prompt onSubmit={onSubmit} trackExit={trackExit} trackError={trackError}>
      <Field withHelp>
        <Input name="dailyRate" value={data.dailyRate} money onBlur={onBlur}
               errors={errors[`mAndIeBreakdown[${field}].dailyRate`]} type="number"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({dailyRate: event.target.value})}
               label="Daily Rate" required placeholder="Enter Daily Rate"/>
        <Select name="isBeforehand" value={data.isBeforehand} errors={errors[`mAndIeBreakdown[${field}].isBeforehand`]}
                onChange={(isBeforehand: any) => onChange({isBeforehand}, true)}
                placeholder="Choose one" required bool options={yesNoOptions}
                label="Does this first day lie back to back to a trip that finished the day beforehand"
                help={help}
        />
      </Field>
      <Field last>
        <Input name="total" money value={data.total} label="Total amount" placeholder="Total amount" disabled readOnly/>
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} name="submit-first-day" htmlType="submit">Save</Button>
      </div>
    </Form>
  );
}

export default FirstDayForm;
