import {ChangeEvent, FC, ReactElement, useCallback, useEffect, useState} from 'react';
import ReactSlider from 'react-slider'
import cx from 'classnames';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import {findInputError, RHErrors} from 'helpers/errors';
import {useFormContext, Controller} from 'react-hook-form';


type Props = {
  name: string;
  value?: number;
  onChange?: (value: number) => void;
  label?: string;
  step?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  auto?: boolean;
  hint?: string;
  help?: ReactElement | string;
  inputClassName?: string;
  multiplier?: number;
  postfix?: string;
  className?: string;
}

const Slider:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    className, errors = [], required,
    disabled, step = 1, inputClassName,
    hint, postfix = "%", help,
    onBlur, onFocus
  } = props;

  const [ localValue, onChangeLocalValue ] = useState<number | undefined>(value);
  const {
    control,
    formState,
    setValue,
    clearErrors
  } = useFormContext();

  const handleChange = useCallback((value: number) => {
    if (onChange) onChange(value);
    onChangeLocalValue(value);
    setValue(`${name}-hidden`, value);
    if (value) clearErrors(`${name}-hidden`);
  }, [onChange, setValue, clearErrors, name]);

  useEffect(() => {
    if (Number(value) !== 0) {
      setValue(`${name}-hidden`, value);
      // if (required) clearErrors(`${name}-hidden`);
    }
  }, [value, name]);

  const inputErrors = findInputError(formState.errors as RHErrors, `${name}-hidden`);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;
  const rules = {
    required: {value: Boolean(required), message: 'Field is required'}
  }

  return (
    <Field help={help} required={required} className={className} label={label} hint={hint} errors={errorsList} name={name}>
      <Controller
        name={`${name}-hidden`}
        control={control}
        rules={rules}
        render={({field}) => (
          <div className={cx(styles.sliderWrapper, { [styles.withErrors]: invalid })}>
            <ReactSlider disabled={disabled} step={step} value={field.value ?? value}
                         onAfterChange={onBlur} onBeforeChange={onFocus}
                         onChange={(value) => {
                           handleChange(value);
                           field.onChange(value)
                         }} className={cx(styles.slider, inputClassName)}
                         trackClassName={cx(styles.sliderTrack, {[styles.sliderTrackError]: invalid})}
                         renderThumb={(props) => (
                           <div {...props} className={cx(styles.sliderDragger, {[styles.sliderDraggerError]: invalid})}>
                             <span className={styles.sliderDraggerSpot}/>
                             <span className={styles.sliderDraggerValue}>{localValue}{postfix}</span>
                           </div>
                         )}
            />
            <div className={styles.sliderInputWrapper}>
              <input type="text" ref={field.ref} name={field.name}
                     onBlur={onBlur} onFocus={onFocus}
                     onKeyUp={(event) => {
                       if (event.key === 'Enter' || event.keyCode === 13) {
                         event.stopPropagation();
                         if (onBlur) onBlur();//@ts-ignore
                         const value = event.target.value;
                         handleChange(Number(value));
                         onChangeLocalValue(Number(value));
                         field.onChange(Number(value))
                       }
                     }}
                     value={localValue === 0 ? '' : localValue} placeholder="0" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                       handleChange(Number(event.target.value));
                       onChangeLocalValue(Number(event.target.value));
                       field.onChange(Number(event.target.value))
                     }}
                     className={cx(styles.input, styles.sliderInput, {[styles.hasError]: invalid})}/>
              <span className={styles.sliderInputPostfix}>{postfix}</span>
            </div>
          </div>
        )}
      />
    </Field>
  )
}

export default Slider;
