import { createSelector } from 'reselect';
import { Store } from '../types';
import { AuthState } from './types';

const authSelector = (store: Store) => store.auth;

export const authTokenSelector = createSelector(authSelector, (state: AuthState) => state.token);
export const authUserSelector = createSelector(authSelector, (state: AuthState) => state.user);
export const authRatesSelector = createSelector(authSelector, (state: AuthState) => state.rates.asMutable({deep: true}));
export const authPlansSelector = createSelector(authSelector, (state: AuthState) => state.plans.asMutable({deep: true}));
