import {Subaward, SubAwardeeForm, SubAwardeeRecipientForm} from './types';
import dayjs from 'dayjs';
import {percentToFixed} from 'helpers/numbers';

export const subawardAdapter = (data: Subaward) => {
  return {
    id: data.id ?? '',
    name: data.name ?? '',
    description: data.description ?? '',
    subRecipient: {
      name: data.subRecipient?.name ?? '',
      primaryOrganization: data.subRecipient?.primaryOrganization ?? '',
      department: data.subRecipient?.department ?? '',
      email: data.subRecipient?.email ?? '',
    },
    subAwardeeForm: data?.subAwardeeForm ? subawardeeFormAdapter(data.subAwardeeForm) : undefined,
    subAwardeeRecipientForm: data.subAwardeeRecipientForm ? publicSubAwardAdapter(data.subAwardeeRecipientForm || {}) : undefined,
  }
}

export const subawardsAdapter = (data: Subaward[] = []) => {
  return data.map(subawardAdapter);
}

export const subawardeeFormAdapter = (data: SubAwardeeForm) => {
  return {
    years: (data?.years || []).map((item, idx: number) => ({
      year: item.year ?? [],
      serialNumber: item.serialNumber ?? idx + 1,
      directCost: item.directCost ?? '',
      indirectCost: item.indirectCost ?? '',
      modifiedDirectCost: item.modifiedDirectCost ?? '',
      fee: item.fee ?? '',
    })),
    rates: (data?.rates || []).map((item) => ({
      name: item.name ?? '',
      rate: item.rate ? percentToFixed(item.rate) : null,
      startDate: item.startDate ? dayjs(item.startDate).format('YYYY-MM-DD') : null,
      endDate: item.endDate ? dayjs(item.endDate).format('YYYY-MM-DD') : null,
    }))
  }
}

const publicSubAwardAdapter = (data: SubAwardeeRecipientForm):SubAwardeeRecipientForm => {
  return {
    subRecipient: {
      name:data.subRecipient?.name ?? '',
      primaryOrganization:data.subRecipient?.primaryOrganization ?? '',
      department:data.subRecipient?.department ?? '',
      email:data.subRecipient?.email ?? '',
    },
    subAwardeeForm: subawardeeFormAdapter(data.subAwardeeForm)
  }
}
