import React, {ChangeEvent, FC, ReactElement, useCallback, useState} from 'react';
import cx from 'classnames';
import {debounce, isArray} from 'lodash';
import ReactDOM from 'react-dom/client';
import Modal from 'components/modal';
import Typo from 'components/typo';
import {Button, Select, Checkbox, Form} from 'components/form';
import styles from './confirmation.module.css';
import { ListItem } from 'helpers/utils';

interface Props {
  type?: 'success' | 'warning' | 'error' | 'info';
  title?: string;
  text: string | string[];
  okText?: string;
  icon?: string;
  hideCancel?: boolean;
  cancelText?: string;
  onConfirm: () => void;
  visible: boolean;
  plain?: boolean;
  children?: ReactElement;
  onCancel: () => void;
}

const Confirmation:FC<Props> = (props: Props) => {
  const {okText = 'Confirm', icon = "alert-hexagon", hideCancel, children, plain, cancelText = 'Cancel', onCancel, visible, onConfirm, text, title, type = 'info' } = props;
  const [ closing, onChangeClosing ] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onCancel();
    onChangeClosing(true);
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    onChangeClosing(true);
  }, [onConfirm]);

  return (
    <Modal icon={plain ? undefined : icon} confirmation iconType={plain ? undefined : type} className={cx({'ReactModal__Content--before-close': closing})}
           onCancel={handleClose} visible={visible}>
      <div className={cx(styles.body, {[styles.plain]: plain})}>
        {title ? <Typo type="h6" className={styles.title} size={18} semi>{title}</Typo> : null}
        <Typo type="p" className={styles.text} semi>
          {isArray(text)
            ? text.map((text: string, idx: number) => <div key={idx}  className={styles.textField}>{text}</div>)
            : text
          }
        </Typo>
        {children}
        <div className={cx(styles.footer, {[styles.oneBtn]: hideCancel})}>
          {hideCancel ? null : <Button type="bordered" onClick={handleClose} htmlType="button" name="cancel-confirm">{cancelText}</Button>}
          <Button type={type === 'error' ? 'danger' : 'primary'} onClick={handleConfirm} htmlType="button" name="confirm">{okText}</Button>
        </div>
      </div>
    </Modal>
  );
}

type ConfirmationWithCheckboxProps = Props & {
  label: string;
  hide?: boolean;
  onConfirm: (value: boolean) => void;
}

const ConfirmationWithCheckbox:FC<ConfirmationWithCheckboxProps> = (props: ConfirmationWithCheckboxProps) => {
  const {okText = 'Confirm', icon = "alert-hexagon", hide, label, plain, cancelText = 'Cancel', onCancel, visible, onConfirm, text, title, type = 'info' } = props;
  const [ closing, onChangeClosing ] = useState<boolean>(false);
  const [ value, onChangeValue ] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onCancel();
    onChangeClosing(true);
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm(value);
    onChangeClosing(true);
  }, [onConfirm, value]);

  const form = (
    <div className={cx(styles.formWrapper, {[styles.bottom]: hide})}>
      <Form>
        <Checkbox name="confirm-checkbox" className={styles.input} label={label} value={value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeValue(event.target.checked)}
        />
      </Form>
    </div>
  );

  return (
    <Modal icon={plain ? undefined : icon} confirmation iconType={plain ? undefined : type} className={cx({'ReactModal__Content--before-close': closing})}
           onCancel={handleClose} visible={visible}>
      <div className={cx(styles.body, {[styles.plain]: plain})}>
        {title ? <Typo type="h6" className={styles.title} size={18} semi>{title}</Typo> : null}
        <Typo type="p" className={styles.text} semi>
          {isArray(text)
            ? text.map((text: string, idx: number) => <div key={idx}  className={styles.textField}>{text}</div>)
            : text
          }
        </Typo>
        {hide ? null : form}
        <div className={styles.footer}>
          <Button type="bordered" onClick={handleClose} htmlType="button" name="cancel-confirm">{cancelText}</Button>
          <Button type={type === 'error' ? 'danger' : 'primary'} onClick={handleConfirm} htmlType="button" name="confirm">{okText}</Button>
        </div>
        {hide ? form : null}
      </div>
    </Modal>
  );
}

type ConfirmationWithSelectProps = Props & {
  label: string;
  options: ListItem[];
  placeholder: string;
  onConfirm: (value: any) => void;
}

const ConfirmationWithSelect:FC<ConfirmationWithSelectProps> = (props: ConfirmationWithSelectProps) => {
  const {
    okText = 'Confirm', icon = "alert-hexagon", placeholder, options,
    label, plain, cancelText = 'Cancel', onCancel, visible,
    onConfirm, text, title, type = 'info'
  } = props;
  const [ closing, onChangeClosing ] = useState<boolean>(false);
  const [ value, onChangeValue ] = useState<any>(undefined);

  const handleClose = useCallback(() => {
    onCancel();
    onChangeClosing(true);
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm(value);
    onChangeClosing(true);
  }, [onConfirm, value]);

  return (
    <Modal icon={plain ? undefined : icon} confirmation iconType={plain ? undefined : type} className={cx({'ReactModal__Content--before-close': closing})}
           onCancel={handleClose} visible={visible}>
      <div className={cx(styles.body, {[styles.plain]: plain})}>
        {title ? <Typo type="h6" className={styles.title} size={18} semi>{title}</Typo> : null}
        <Typo type="p" className={styles.text} semi>
          {isArray(text)
            ? text.map((text: string, idx: number) => <div key={idx}  className={styles.textField}>{text}</div>)
            : text
          }
        </Typo>
        <div className={styles.formWrapper}>
          <Select overflow name="confirm-select" placeholder={placeholder} className={styles.input} label={label} options={options} value={value}
                    onChange={(value: any) => onChangeValue(value)}
          />
        </div>
        <div className={styles.footer}>
          <Button type="bordered" onClick={handleClose} htmlType="button" name="cancel-confirm">{cancelText}</Button>
          <Button disabled={value === undefined} type={type === 'error' ? 'danger' : 'primary'} onClick={handleConfirm} htmlType="button" name="confirm">{okText}</Button>
        </div>
      </div>
    </Modal>
  );
}

export interface ConfirmProps {
  onConfirm: (value?: any) => void;
  onCancel?: () => void;
  title?: string;
  pureConfirm?: boolean;
  text: string | string[] | any;
  type?: 'success' | 'warning' | 'error' | 'info';
  okText?: string;
  label?: string;
  hide?: boolean;
  options?: ListItem[];
  optionsLabel?: string;
  plain?: boolean;
  hideCancel?: boolean;
  icon?: string;
  placeholder?: string;
  cancelText?: string;
}

export const confirm = (props: ConfirmProps) => {
  const { onConfirm, title, placeholder, hide, hideCancel, onCancel, pureConfirm, text, type, options, plain, label, icon, cancelText, okText } = props;
  const elem = document.getElementById('bm-confirm') as HTMLDivElement;
  const root = ReactDOM.createRoot(elem);

  const deleteFromDOM = debounce(() => {
    if (onCancel) onCancel()
    root.unmount();
  }, 200);

  const handleConfirm = (value?: any) => {
    onConfirm(value);
    if (pureConfirm) {
      root.unmount();
    } else {
      deleteFromDOM();
    }
  };

  const modalProps = {
    plain,
    title,
    hideCancel,
    icon: plain ? undefined : icon,
    cancelText,
    okText,
    type,
    onConfirm: handleConfirm,
    onCancel: deleteFromDOM,
    visible: true,
    text
  };

  if (!elem) return null;

  if (label && options) {
    return root.render(<ConfirmationWithSelect options={options} placeholder={placeholder || 'Select from list'} label={label} {...modalProps} />)
  }

  if (label) {
    return root.render(<ConfirmationWithCheckbox label={label} hide={hide} {...modalProps} />)
  }

  return root.render(<Confirmation {...modalProps} />)
}

export default Confirmation;
