import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Select} from 'components/form'
import {Payment, PaymentYear} from 'store/grants/otherDirectCosts/Payments/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getPaymentFromODC} from 'helpers/getFromGrant';
import useIncentivePayments from 'hooks/useIncentivePayments';
import useEnums from 'hooks/useEnums';
import styles from './cs.module.css';
import useUI from '../../../../../../hooks/useUI';

type Props = {
  item: Payment;
  grantId: string;
}

const PaymentInfoForm: FC<Props> = ({item, grantId}) => {
  const [data, onChangeData] = useState<Payment>(item);
  const {yesNoOptions} = useEnums();
  const {confirmWithHide} = useUI();
  const {
    updatePayment, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = useIncentivePayments({
    form_page_type: 'Payment info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(() => {
    const shouldRecalculate = item.years.reduce((res: number, item: PaymentYear) => res + Number(item.numberOfPayments || 0), 0) > 0;
    confirmWithHide({
      title: 'Compensation will be recalculated',
      text: 'The total cost before and after inflation for all years will be recalculated based on the person\'s new incentive amount',
      type: 'warning',
      icon: 'alert-hexagon',
      when: shouldRecalculate && item.amount !== data.amount,
      okText: 'Recalculate',
      id: `odc-update-ip-${grantId}`,
      onConfirm: () => updatePayment(grantId, item.id, {
        name: data.name,
        amount: data.amount,
        isInflationAppliedEachYear: data.isInflationAppliedEachYear,
      })
    });

  }, [data, confirmWithHide, grantId, item, updatePayment])

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input name="name"
               placeholder="Enter a name"
               value={data.name}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, name: event.target.value})}
               label="Payment Name" required/>
        <Input name="amount"
               placeholder="Enter an average amount "
               value={data.amount} type="number" money
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({...data, amount: event.target.value})}
               label="Total average amount of a person's incentive" required/>
      </Field>
      <Field withHelp last>
        <Select name="isInflationAppliedEachYear"
                placeholder="Select the item" bool
                help={`Inflation is not normally applied to incentive payment values. This is for two main reasons. The first is to keep incentive payments as fixed whole numbers across the duration of the grant. The other is because the payments are relatively fixed, everyone who participates regardless of what year they participate, will incentive payment as if they are being compensated equivalently`}
                value={data.isInflationAppliedEachYear} options={yesNoOptions}
                onChange={(isInflationAppliedEachYear: any) => onChangeData({...data, isInflationAppliedEachYear})}
                label="Is inflation applied to this incentive payment each year?" required/>
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const PaymentPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getPaymentFromODC(grant, params.paymentId);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={`Incentive Payments. Payment ${index > 0 ? index : ''}`}>
      {
        item
          ? <PaymentInfoForm grantId={grant.id} key={`${grant.id}-odc-payments-${params.paymentId}`} item={item}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default PaymentPage;
