import React, {FC, ReactElement, useEffect, useState, useRef, useCallback, useMemo} from 'react';
import cx from 'classnames';
import { debounce as lodashDebounce } from 'lodash';
import help from './help.svg';
import redHhelp from './red.svg';
import triangleIcon from './triangle.svg';
import styles from './hint.module.css';

type Props = {
  className?: string;
  contentClassName?: string;
  debounce?: number;
  show?: boolean;
  triangle?: boolean;
  red?: boolean;
  pos?: Position;
  children: string | ReactElement | string[] | ReactElement[];
}

type Position = 'right' | 'left';
const Help:FC<Props> = ({ children, red, pos, contentClassName, triangle, show, debounce, className }) => {
  const [ position, onChangePosition ] = useState<Position>(pos ? pos : 'right');
  const [ hover, onChangeHover ] = useState<boolean>(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    if (pos) return;
    if (wrapper.current && content.current) {
      const { left } = wrapper.current.getBoundingClientRect();
      const { width } = content.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      let newPosition:Position = 'right';
      if (width + left > windowWidth) newPosition = 'left';
      onChangePosition(newPosition);
    }
  }, [wrapper, pos, content, onChangePosition]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize]);

  const debouncedHoverOff = useCallback(lodashDebounce(() =>
    onChangeHover(false), debounce, {trailing: true})
  , [debounce]);

  const onMouseOut = useCallback(() => {
    if (debounce) {
      debouncedHoverOff();
    }
    return;
  }, [debounce, debouncedHoverOff]);

  const helpIcon = useMemo(() => red ? redHhelp : help, [red]);

  return (
    <div ref={wrapper} className={cx(styles.wrapper, {[styles.show]: show}, className)}>
      <img className={cx(styles.icon, {[styles.hover]: hover})} onMouseOut={onMouseOut}
           onMouseOver={() => debounce ? onChangeHover(true) : undefined}
           src={triangle ? triangleIcon : helpIcon} alt="help" draggable={false}
      />
      <div ref={content} className={cx(styles.content, contentClassName, styles[position] )}>{children}</div>
    </div>
  )
}

export default Help;
