import {FC, useCallback, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import { Select, Form, Button } from 'components/form'
import useEnums from 'hooks/useEnums';
import styles from './cs.module.css';
import {ComputerServices} from 'store/grants/otherDirectCosts/ComputerService/types';
import useGrants from 'hooks/useGrants';
import useCS from 'hooks/useCS';

type Props = {
  item: ComputerServices;
  grantId: string;
}

const ComputerServicesInfoForm:FC<Props> = ({ item, grantId }) => {
  const { yesNoOptions } = useEnums()
  const { createInfo, loading, updateInfo } = useCS();
  const [ data, onChangeData ] = useState<ComputerServices>(item);

  const onSubmit = useCallback(() => {
    const func = item.isChargedAsDirectCost === undefined ? createInfo : updateInfo;
    func(grantId, data);
  }, [updateInfo, data, item, grantId, createInfo]);

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field withHelp last>
        <Select name="isChargedAsDirectCost" options={yesNoOptions}
                placeholder="Select the item"
                help={`Some examples of computer services are "computer-based retrieval of scientific, technical and educational information. If your institution has established computer service rates, please be sure to use them when using the template down below. This is a question that can likely be googled along with your school name. It is okay if you are unsure of exactly what this means. If you still aren't sure what your university policy on how to charge computer services is, it is best to budget any of them here to account for them as needed properly.`}
                bool
                value={data.isChargedAsDirectCost}
                onChange={(isChargedAsDirectCost: any) => onChangeData({...data, isChargedAsDirectCost })}
                label="Is it your institution's policy to charge computer services as direct costs?" required
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const ComputerServicesInfo:FC = () => {
  const { grant } = useGrants();

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Computer Services">
      <ComputerServicesInfoForm grantId={grant.id} key={`${grant.id}-odc-cs`} item={grant.otherDirectCosts.computerServices} />
    </GrantFormWrapper>
  )
}

export default ComputerServicesInfo;
