import {ChangeEvent, FC, useCallback, useMemo} from 'react';
import {Props} from './index';
import Field from 'components/field';
import {Button, Form, Toggler, Input, Select} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import useEnums from 'hooks/useEnums';
import Typo from 'components/typo';
import helps from './common/helps';

const Charter:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {

  const { distanceUnits, travelExpensesTypes, timeUnits } = useEnums();

  const disabledDistance = useMemo(() => details.kind !== 'distance', [details]);
  const disabledTime = useMemo(() => details.kind !== 'time', [details]);

  const handleChangeKind = useCallback((kind: 'distance' | 'time') => {
    onChangeDetails({
      kind: kind === details.kind ? null : kind,
      numberOfUnits: undefined,
      unit: undefined,
      pricePerUnit: undefined,
      charterCost: undefined,
    }, true);
  }, [details, onChangeDetails])

  const requiredDistance = details.kind === 'distance';
  const requiredTime = details.kind === 'time';

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name of rental charter expense" required placeholder="Enter name of rental charter expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field>
        <Input placeholder="Enter a price of journey" label="Base price of journey" required
               name="price" type="number" onBlur={onBlur} errors={errors['details.price']}
               value={details.price} money help={helps.rentalBasePrice}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ price: event.target.value })}
        />
      </Field>
      <Typo className={styles.text}>
        You can skip the following steps of adding extra by distance/by time costs on top of your base price. And use only the base price for final calculations on the Totals.
      </Typo>
      <Typo type="h6" className={styles.text} bold>
        Rental is estimated by distance
      </Typo>

      <Toggler name="kind_distance" checked={details.kind === 'distance'} value='distance' label="Rental is estimated by distance" onChange={() => handleChangeKind('distance')}/>
      <Field last top={disabledDistance ? false : Boolean(errors['details.numberOfUnits'])}>
        <Input placeholder="Enter a number of distance units" label="Estimated number of distance units that will be traveled" required={requiredDistance}
               name="numberOfUnits_distance" errors={disabledDistance ? [] : errors['details.numberOfUnits']} type="number" onBlur={onBlur}
               value={disabledDistance ? '' : details.numberOfUnits} disabled={disabledDistance}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfUnits: event.target.value })}
        />
        <Select placeholder="Select distance unit" label="Distance unit"
               name="unit" options={distanceUnits}
               value={disabledDistance ? '' : details.unit} disabled={disabledDistance}
               onChange={(unit: any) => onChangeDetails({ unit })}
        />
      </Field>
      <Field top={disabledDistance ? false : Boolean(errors['details.pricePerUnit'])}>
        <Input placeholder="Enter an estimated price per distance" label="Estimated price per distance" required={requiredDistance}
               name="pricePerUnit_distance" errors={disabledDistance ? [] : errors['details.pricePerUnit']} type="number" onBlur={onBlur}
               value={disabledDistance ? '' : details.pricePerUnit} disabled={disabledDistance}
               money
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ pricePerUnit: event.target.value })}
        />
        <Input placeholder="Cost of charter by distance" label="Cost of charter by distance"
               name="charterCostByUnit" money
               value={disabledDistance ? '' : details.charterCost}
               disabled readOnly
        />
      </Field>

      <Typo type="h6" className={styles.text} bold>
        Rental is estimated by time
      </Typo>

      <Toggler name="kind_time" checked={details.kind === 'time'} value='time' label="Rental is estimated by time" onChange={() => handleChangeKind('time')}/>
      <Field last top={disabledTime ? false : Boolean(errors['details.numberOfUnits'])}>
        <Input placeholder="Enter an estimated number of units" label="Estimated number of time unit" required={requiredTime}
               name="numberOfUnits_time" type="number" onBlur={onBlur}
               errors={disabledTime ? [] : errors['details.numberOfUnits']}
               value={disabledTime ? '' : details.numberOfUnits} disabled={disabledTime}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfUnits: event.target.value })}
        />
        <Select placeholder="Select time unit" label="Time unit"
               name="unit" options={timeUnits}
               value={disabledTime ? '' : details.unit} disabled={disabledTime}
               onChange={(unit: any) => onChangeDetails({ unit })}
        />
      </Field>
      <Field top={disabledTime ? false : Boolean(errors['details.pricePerUnit'])}>
        <Input placeholder="Enter an estimated price per time unit" label="Estimated price per time unit" required={requiredTime}
               name="pricePerUnit_time" type="number" onBlur={onBlur} money
               errors={disabledTime ? [] : errors['details.pricePerUnit']}
               value={disabledTime ? '' : details.pricePerUnit} disabled={disabledTime}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ pricePerUnit: event.target.value })}
        />
        <Input placeholder="Cost of charter by time" label="Cost of charter by time"
               name="charterCostByUnit" money
               value={disabledTime ? '' : details.charterCost}
               disabled readOnly
        />
      </Field>

      <Typo type="h6" className={styles.text} bold>Total</Typo>
      <Field full>
        <Input placeholder="Cost of charter" label="Cost of charter by either method (by distance/by time)"
               name="totalCost" type="number" money
               value={details.charterCost}
               disabled readOnly
               help={helps.charterCost}
        />
      </Field>
      <Field last top={Boolean(errors['details.estimatedNumberOfCharters'] || errors['details.totalCost'])}>
        <Input placeholder="Enter estimated number of charters" label="Estimated Number of these Charters needed for trip in year" required
               name="estimatedNumberOfCharters" type="number"
               value={details.estimatedNumberOfCharters} onBlur={onBlur} errors={errors['details.estimatedNumberOfCharters']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ estimatedNumberOfCharters: event.target.value })}

        />
        <Input placeholder="Enter total cost of misc expenses" label="Miscellaneous expenses for charter/rental travel for all trip attendees"
               name="totalCost" type="number" onBlur={onBlur}
               value={details.totalCost} errors={errors['details.totalCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}

        />
      </Field>
      <Field last>
        <Input placeholder="Amount of compensation before inflation" label="Amount budgeted for charter/rental travel before inflation"
               name="amountOfCompensationBeforeInflation" type="number"
               value={data.compensationBeforeInflation}
               disabled readOnly money

        />
      </Field>

      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Charter;
