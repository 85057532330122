import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import IndirectCostsTable, {IUpdateMTDCFlagsInput} from 'containers/collapseTable/indirect';
import useIndirect from 'hooks/useIndirectCosts';
import useGrants from 'hooks/useGrants';
import {Button} from 'components/form';
import styles from './indirect.module.css';
import useRates from 'hooks/useRates';
import Icon from 'components/icon';
import Typo from 'components/typo';
import dayjs from 'dayjs';


export type RatePreviewResponse = {
  overlay: {
    startDate: string[];
    endDate: string[];
  };
  uncoveredPeriods: string[][];
}
const defaultTimeline = {
  overlay: {
    startDate: [],
    endDate: [],
  },
  uncoveredPeriods: []
};

const MTDCPage: FC = () => {
  const {grant} = useGrants();
  const {previewGrantRate, getGrantRatesPreviewFromStore, getGrantRatesFromStore, getGrantRates} = useRates();
  const grantRates = getGrantRatesFromStore(grant.id);
  const {getIndirectCosts, tableData, update, preview, submitLoading, loading, trackFormStarted} = useIndirect({
    form_page_type: 'General info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {sidebarWidth, changeICGrantId} = useUI();
  const params: Record<string, string> = useParams();
  const globalTimeline = getGrantRatesPreviewFromStore(grant.id);
  const [editable, onChangeEditable] = useState<boolean>(false);


  useEffect(() => {
    getIndirectCosts(params.id);
    getGrantRates(params.id);
    previewGrantRate(params.id, {});
  }, [params.id]);

  const rows = useMemo(() => {
    return [
      tableData.sections.personnel,
      tableData.sections.otherPersonnel,
      tableData.sections.fringeBenefits,
      tableData.sections.travel,
      tableData.sections.participantSupport,
      tableData.sections.equipment,
      tableData.sections.otherDirectCosts,
    ]
  }, [tableData]);

  const onSubmit = useCallback((data: IUpdateMTDCFlagsInput, cb: () => void) => {
    update(grant.id, data, () => {
      cb();
      onChangeEditable(false);
    });
  }, [grant, update]);

  const onBlur = useCallback((data: IUpdateMTDCFlagsInput) => {
    preview(grant.id, data);
  }, [grant, preview]);

  const timelinePlaceholder = useMemo(() => {
    const link = `/grants/${grant.id}/facilities/rates`;
    if (grantRates.length < 1) {
      return (
        <div className={styles.warning}>
          <div className={styles.warningTitle}>
            <Icon className={styles.warningIcon} size={20} icon="alert-triangle"/>
            <Typo className={styles.warningText}>
              <>
                Indirect costs can’t be displayed because F&A rates are absent. Follow the
                <Link className={styles.link} onClick={() => changeICGrantId(grant.id)} to={link}>link</Link>
                to add F&A rates and Indirect costs will be shown.
              </>
            </Typo>
          </div>
        </div>
      );
    }
    if (grant && globalTimeline.uncoveredPeriods.length > 0) {
      const s = globalTimeline.uncoveredPeriods.length > 1 ? 's' : '';
      return (
        <div className={styles.warning}>
          <div className={styles.warningTitle}>
            <Icon className={styles.warningIcon} size={20} icon="alert-triangle"/>
            <Typo className={styles.warningText}>
              <>
                There are no F&A rate{s} for the period{s} below. Follow the
                <Link className={styles.link} onClick={() => changeICGrantId(grant.id)} to={link}>link</Link>
                to add F&A rates and Indirect costs will be shown
              </>
            </Typo>
          </div>
          <ul className={styles.uncovered}>
            {globalTimeline.uncoveredPeriods.map((dates: string[], idx: number) => (
              <li key={idx}>
                <Typo className={styles.li}>
                  Rate is missed from {dayjs(dates[0]).format('MM/DD/YYYY')} to {dayjs(dates[1]).format('MM/DD/YYYY')}
                </Typo>
              </li>))
            }
          </ul>
        </div>
      );
    }
    return null;
  }, [grant, changeICGrantId, grantRates, globalTimeline]);

  return (
    <GrantFormWrapper title="Indirect costs">
      <>
        {timelinePlaceholder}
        <div className={styles.header}>
          <Button name="editable-mode" type="bordered" onClick={() => onChangeEditable(!editable)}>
            Override Indirect costs values
          </Button>
          <Button disabled name="override-modal" type="bordered">Override F&A Rate</Button>
        </div>
        <IndirectCostsTable //@ts-ignore
          rows={rows} data={tableData} onBlur={onBlur} editable={editable} onSubmit={onSubmit} loading={submitLoading}
          grantId={params.id}/>
        {loading ? <Spinner style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full/> : null}
      </>
    </GrantFormWrapper>
  );
}

export default MTDCPage;
