import { FC, useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { some } from 'lodash';
import Icon from 'components/icon';
import useUpload, { UploadTypes } from 'hooks/useUpload';
import Typo from 'components/typo';
import toast from 'components/toast';
import icon from './icon.svg';
import iconRed from './icon_red.svg';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import styles from './file.module.css';


type Data = {
  key: string;
  url: string;
}

type Props = {
  accept: string;
  className?: string;
  maxWidth?: number;
  maxHeight?: number;
  placeholder: string;
  maxSize?: number;
  onChange?: (data: Data) => void;
  name: string;
  value?: string;
  errors?: string[];
  type: UploadTypes;
  disabled?: boolean;
}

type DropFile = Record<any, any>;

const File:FC<Props> = (props: Props) => {
  const {
    accept, className, maxWidth,
    maxHeight, onChange, name,
    maxSize = 5242880, disabled,
    placeholder, type,
    errors = []
  } = props;

  const [ value, onChangeValue ] = useState<string>('');
  const [ localFile, onChangeLocalFile ] = useState<File>();
  const { key, uploads, progress } = useUpload();
  const upload = uploads[type];

  useEffect(() => {
    if (key && onChange) onChange({ key, url: value });
  }, [key, value]);

  const checkFileType = useCallback((file: File):boolean => {
    const [tail] = file.name.split('.').reverse();
    const supportFileType = accept.includes(tail.toLowerCase());
    if (!supportFileType) {
      toast.error({
        title: 'Error',
        message: 'File type is unsupported'
      });
      return false;
    }
    return true;
  }, [accept]);

  const fileIsImage:boolean = useMemo(() => {
    const imgTypes:string[] = ['.jpg', '.png', '.jpeg', '.svg'];
    const includes:boolean[] = imgTypes.map((type: string) => accept.includes(type));
    return some(includes, (item: boolean) => Boolean(item));
  }, [accept]);

  const checkImageSizeAndUpload = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        if (maxWidth && maxHeight) {
          const widthIsCorrect = maxWidth >= img.naturalWidth;
          const heightIsCorrect = maxHeight >= img.naturalHeight;
          if (!widthIsCorrect || !heightIsCorrect) {
            toast.error({
              title: 'Error',
              message: 'Image width or height is more then accepted'
            });
            return;
          }
        }
        onChangeValue(reader.result as string);
        upload(file);
        return;
      }
    }
  }, [maxWidth, upload, maxHeight]);

  const checkFileSize = useCallback((file: File):boolean => {
    if (file.size > maxSize) {
      toast.error({
        title: 'Error',
        message: 'Maximum file size is 5MB'
      });
      onChangeLocalFile(undefined);
      return false;
    }
    return true;
  }, [maxSize]);


  const checkFileAndUpload = useCallback((file: File) => {
    if (checkFileSize(file) && checkFileType(file)) {
      if (fileIsImage) {
        checkImageSizeAndUpload(file);
        return;
      }
      upload(file);
    }
  }, [checkFileType, checkImageSizeAndUpload, fileIsImage, checkFileSize, upload]);

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor<DropFile>) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        onChangeLocalFile(file);
        checkFileAndUpload(file);
      }
    }, [checkFileAndUpload]);


  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item: any, monitor: DropTargetMonitor) {
      if (disabled) return;
      handleFileDrop(monitor);
    },
    canDrop(item: any, monitor: DropTargetMonitor<DropFile>) {
      const files = monitor.getItem().files;
      return files?.length < 2 || !disabled;
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (event.target.files) {
      const file = event.target.files[0];
      onChangeLocalFile(file);
      checkFileAndUpload(file);
    }
  }, [checkFileAndUpload, disabled]);

  const onClearFile = useCallback(() => {
    if(onChange) onChange({ key: '', url: '' });
    onChangeLocalFile(undefined);
  }, [onChange]);

  const progressBar = useMemo(() => {
    return (
      <>
        <Typo className={styles.textField}>Uploading...</Typo>
        <div className={styles.progressWrapper}>
          <div className={styles.progress}>
            <span style={{width: `${progress}%`}} className={styles.bar}/>
          </div>
          <span className={styles.progressText}>{progress}%</span>
        </div>
      </>
    )
  }, [progress]);

  const uploader = useMemo(() => {
    const fileName = localFile?.name;
    return (
      <div className={styles.inputAndTrashWrapper}>
        <label role="button" className={styles.inputFileWrapper}>
          {fileName ? fileName : 'Click to upload'}
          <input className={styles.inputFile} disabled={disabled} type="file" accept={accept} onChange={onChangeInput} cypress-id={`${name}-component-browsefile`}/>
        </label>
        {fileName ? <Icon className={styles.trash} onClick={onClearFile} icon="trash-01"/> : null}
      </div>
    )
  }, [accept, localFile, onClearFile, onChangeInput, disabled, name])

  const body = useMemo(() => {
    const fileName = localFile?.name;
    return errors.length > 0 ?
      (
        <>
          <Typo className={styles.textField}>Upload failed, please try again</Typo>
          {uploader}
        </>
      )
      : (
        <>
          <Typo className={styles.textField} type="div">
            <>
              {uploader}
              {fileName ? null : 'or drag and drop'}
            </>
          </Typo>
          {fileName ? null : <Typo className={styles.textField}>{placeholder}</Typo>}
        </>
      )
  }, [placeholder, errors, uploader, localFile]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <div
        className={cx(styles.dndArea, {
          [styles.canDrop]: canDrop || isOver,
          [styles.disabled]: disabled,
          [styles.hasError]: errors.length > 0,
          [styles.loading]: progress > 0
        })}
        ref={drop}
      >
        <div className={styles.iconWrapper}>
          <img src={errors.length > 0 ? iconRed : icon} alt="upload" draggable={false} />
        </div>
        {progress > 0 ? progressBar : body}
      </div>
    </div>
  )
}

export default File;
