import {
  Redirect, useParams
} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { FC } from 'react';
import { personalFreeRoutes } from 'const';

type Props = {
  children: any;
}
const PaidPersonnelRoute:FC<Props> = ({ children }) => {
  const { user } = useAuth();
  const params:Record<string, string> = useParams();
  const isFree = personalFreeRoutes.includes(params.personnelType);

  if (!user.paid && !isFree) {
    return <Redirect from="*" to="/grants" />;
  }

  return user.id ? children : null;
};

export default PaidPersonnelRoute;
