export const getTotalsTableData = (
    totals: Array<Array<string>>,
    renderCell: (cell: string, idx: number, isGrayData: boolean) => JSX.Element | string,
    totalsEmptyHeaders: Array<string> = [],
): { data: Array<Array<string | JSX.Element>>; headers: Array<string | JSX.Element> } => {
    let data: Array<Array<string | JSX.Element>> = [];
    let headers: Array<string | JSX.Element> = totalsEmptyHeaders;

    if (totals.length > 0) {
        const [_headers, ...rows] = totals;
        headers = _headers;
        data = rows.map((row: string[], rowIdx: number) => {
            const isNotLastRow = rowIdx !== rows.length - 1;
            return row.map((cell: string, idx: number) => {
                const isNotTotalCell = idx !== row.length - 1;
                const isGrayData = isNotLastRow && isNotTotalCell;
                return renderCell(cell, idx, isGrayData)
            });
        });
    }

    return {
        headers,
        data,
    };
};

