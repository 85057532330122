import {ImmutableObject} from 'seamless-immutable';
// Materials and Supplies
import {MaterialsSupplies} from "./otherDirectCosts/MaterialsSupplies/types";
import {ComputerServices} from './otherDirectCosts/ComputerService/types';
import {Contract} from './otherDirectCosts/Contracts/types';
import {Payment} from './otherDirectCosts/Payments/types';
import {Publications} from './otherDirectCosts/Publications/types';
import {FeesType} from './fees/types';

import {OtherExpensesType} from './otherDirectCosts/OtherExpenses/types';
import {Subaward, SubAwardeeForm} from './otherDirectCosts/Subawards/types';
import {CostSharingExpensesType} from './costSharing/types';
import {ConsultantServicesType} from './otherDirectCosts/ConsultantServices/types';

export type ListGrant = any;

export type StatGrant = {
  id: string;
  title: string;
  type: string;
  startDate: string;
  organization: {
    name: string;
  };
  status: string;
  isOngoing: boolean;
  endDate: string;
  completion: number;
  acceptedForm: boolean;
  step: string;
  nsf: {
    solicitationName: string;
    solicitationNumber: number;
    directorate: string;
    division: string;
  };
  primaryInvestigator: {
    firstName: string;
    lastName: string;
  };
}

export type Stat = {
  count: number;
  grants: StatGrant[]
  step: string;
}

export type List = {
  data: ListGrant[];
  next: boolean;
  page: number;
  perPage: number;
  total: number;
}

export interface Grant {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  step: string;
  primaryInvestigator: {
    firstName: string;
    id: string;
    fullName: string;
    lastName: string;
  }
  startDate: string;
  endDate: string;
  status: string;
  type: string;
  isBudgetSubwardOnAnother?: boolean;
  notes: string;
  contactEmail: string;
  nsf: {
    solicitationName: string;
    solicitationNumber: string;
    directorate: string;
    division: string;
    directorateOther: string;
    divisionOther: string;
  }
}

export type GrantOrganization = {
  name: string;
  id: string;
  subGroup: string;
  department: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
  };
  academicYear: {
    startDate: string | null;
    endDate: string | null;
  };
  fiscalYear: {
    startDate: string | null;
    endDate: string | null;
    definition: string;
  };
  annualSchedule: string;
}

export type PersonalReference = {
  id: string;
  kind: string;
}

export type GrantAuthor = {
  firstName: string;
  race: string | undefined;
  gender: string | undefined;
  birthdate: string;
  calculateAverageBudget: boolean;
  personalReference?: PersonalReference;
  email: string;
  positionTitle: string;
  yearSalary: number | undefined;
  yearlyMonthsSalaryCompensation: number | undefined;
  oneMonthCompensationAmount: number | undefined;
  lastName: string;
}

export type PersonalNSFCompensation = {
  year: any;
  kind: string | undefined;
  serialNumber?: number;
  percent?: any;
  numberToReceiveStipends?: number;
  numberOfStudents?: number;
  numberToReceiveTuitionCoverage?: number;
  numberOfStudentsSummer?: number;
  availableMonths?: any;
  monthsFromCurrentGrant?: any;
  percentForAccountOwner?: any;
  requestedSalarySupportAfterInflation?: any;
  requestedSalarySupportBeforeInflation?: any;
  requestedSalarySupportAfterInflationForAccountOwner?: any;
  months?: any;
  monthsInYear?: any;
  monthlyCompensationBasedOnSalary?: any;
  monthsAvailableAfterRequest?: any;
  nsfCompensatedMonths?: any;
  totalCompensationAfterInflation?: any;
  totalCompensationBeforeInflation?: any;
  uncappedMonths?: any;
  equivalentNumberOfMonths?: any;
  compensationBeforeInflation?: any;
  compensationAfterInflation?: any;
}

export type SeniorPersonal = {
  id: string;
  firstName: string;
  lastName: string;
  positionTitle: string;
  subGroup: string;
  jobCategory: string;
  department: string;
  fringeBenefitRate: number | undefined;
  isRequestingSupport: boolean;
  isAccountOwner: boolean;
  annualInfo?: string[];
  salary: {
    amount: number | undefined;
    monthCompensatedByOrganization: string | number | undefined;
    startDate: any;
    endDate: any;
  };
  nsfCompensation: PersonalNSFCompensation[];
  role: string | undefined;
}

export type PostDoctoralPersonal = {
  currentBaseSalary: number | string | undefined;
  personsFringeBenefit: number | string | undefined;
  isAccountOwner: boolean;
  id: string;
  positionTitle?: string;
  responsibilities: string;
  nsfCompensation: PersonalNSFCompensation[];
}

export type GraduateStudent = {
  isAccountOwner: boolean;
  academicTerm?: string;
  numberOfAcademicTerms: string | number | undefined;
  fringeBenefit: string | number | undefined;
  stipendAwardPeriod: string | undefined;
  stipendPerYear: string | number | undefined;
  totalAmount: string | number;
  stipendForEachAcademicTerm: string | number | undefined;
  stipendForSummer: string | number | undefined;
  annualInfo: PersonalNSFCompensation[];
  tuitionForEachAcademicTerm: string | number | undefined;
}

export type UnGraduateStudentNSFCompensation = {
  year: string;
  serialNumber: number;
  academicTerm: {
    numberOfStudents?: number;
    numberOfHours?: number;
    numberOfWeeks?: number;
    percentForAccountOwner?: number;
    totalAmountBeforeInflation?: number;
    totalAmountAfterInflation?: number;
    totalAmountForAccountOwnerAfterInflation?: number;
  },
  summer: {
    numberOfStudents?: number;
    numberOfHours?: number;
    numberOfWeeks?: number;
    percentForAccountOwner?: number;
    totalAmountBeforeInflation?: number;
    totalAmountAfterInflation?: number;
    totalAmountForAccountOwnerAfterInflation?: number;
  }
}

export type UnGraduateStudent = {
  isAccountOwner: boolean;
  academicTerm: string | undefined;
  totalAmount: string | number;
  numberOfAcademicTerms: number | undefined;
  wageInAcademicTerm: number | undefined;
  wageInSummer: number | undefined;
  loadLimitDuringAcademicTerm: number | undefined;
  numberOfWeekAcademicTerms: number | undefined;
  nsfCompensation: UnGraduateStudentNSFCompensation[];
  loadLimitDuringSummer: number | undefined;
}

export type CivilServiceNSF = {
  year: string;
  amountOfPeople?: number;
  paidBy: string;
  totalBudgeted?: number;
  totalCompensation?: number;
  totalForPosition?: number;
  details: {
    isSpecificWage?: boolean;
    hourlyWage?: number;
    reasonableWage?: number;
    hoursPerWeek?: number;
    weeksPerYear?: number;
    annualSalary?: number;
    averagePercent?: number;
  };
}

export type CivilService = {
  id: string;
  positionTitle: string;
  fringeBenefit: number | undefined;
  city: string;
  country: string;
  jobCategory: string;
  zipCode: string;
  state: string;
  nsfCompensation: CivilServiceNSF[];
}

export type GrantAcceptedForm = {
  awardDate?: string;
  awardNumber: string;
  totalDirectCosts: string;
  totalIndirectCosts: string;
  uniqueEntityIdentifier: string;
}

export type EquipNsfCompensation = {
  year: string;
  justification: string;
  numberOfUnits: string | number;
}


export type Equip = {
  name: string;
  category: string;
  cost: number;
  nsfCompensation: EquipNsfCompensation[];
  id: string;
}

export type TravelYear = {
  year: string[];
  isEnabled: boolean;
  serialNumber: number | string;
  numberOfConsultants?: number | string;
}

export type TravelStatus = {
  seniorPersonnel: any;
  academicResearchAssociate: any;
  postDocs: number | string;
  graduateStudents: number | string;
  undergraduateStudents: number | string;
  civilService: number | string;
  union: number | string;
  tempAndCasual: number | string;
  otherPersonnel: number | string;
  totalAmountForTrip?: number | string;
  compensationBeforeInflation?: number | string;
  years: TravelYear[];
}

export type TransportationMethodDetails = {
  airCarrier: string;
  isAirCarrierVerified: any;
  price: number | string;
  compensationBeforeInflation: number | string;
  totalAmountForTickets: number | string;
  travellers: number | string;
  eligibility: string[];
  totalCost: number | string;
  numberOfPeopleWithTicket: number | string;
  numberOfTrips: number | string;
  ticketPrice: number | string;
  kind: string | null;
  numberOfUnits: string | number;
  unit: string;
  pricePerUnit: string | number;
  charterCostByUnit: string | number;
  estimatedNumberOfCharters: string | number;
  charterCost: string | number;
  tickets: number | string;
  totalPrice: number | string;
  companyName: string;
  numberOfRides: string | number;
  cabCost: string | number;
  priceExplanation: string;
  city: string;
  miles: string | number;
  milesPerGallon: string | number;
  isRoundTrip: boolean;
  amountOfCompensationBeforeInflation: number | string;
  gasPrice: number | string;
  gallons: number | string;
};

export type TransportationMethodYear = {
  year: string[];
  serialNumber?: number | string;
  compensationAfterInflation?: number | string;
  compensationBeforeInflation?: number | string;
  isEnabled: boolean;
  numberOfOccurrence: number | string;
  numberOfParticipants?: number | string;
  amountWithInflation: number | string;
}

export type TravelCostLodgingMonth = {
  month: number | string;
  index: number;
  nights: string | number;
  dailyRate: string | number;
  monthTotal: string;
  total?: string;
}

export type TravelCostSeason = {
  serialNumber: number | string;
  startDateOfTravel: any;
  endDateOfTravel: any;
  lodgingRate: number | string;
  nights?: number | string;
  doBudgetMaxAmount: boolean;
  maxTotalAdjust: number | string;
  total: number | string;
  maxTotal: number | string;
}

export type TravelCostLodgingData = {
  doBudgetMaxAmount: boolean;
  maxTotalAdjust: number | string;
  maxTotal: number | string;
  months: TravelCostLodgingMonth[];
  seasons?: TravelCostSeason[];
}

export type MaIEBreakdownMonth = {
  total: number | string;
  index?: number;
  month: number | string;
  dailyRate: number | string;
  numberOfDays: number | string;
}

export type MaIEBreakdownDay = {
  dailyRate: number | string;
  isBeforehand: boolean | undefined;
  total: number | string;
}

export type MaIEBreakdownFullDays = {
  months: MaIEBreakdownMonth[];
};

export type MaIEBreakdown = {
  firstDay: MaIEBreakdownDay;
  fullDays: MaIEBreakdownFullDays;
  lastDay: MaIEBreakdownDay;
  doBudgetMaxAmount: boolean;
  total: number | string;
  maxTotal: number | string;
  seasons?: TravelCostMealExpensesSeason[];
  maxTotalAdjust: number | string;
}

export type TripAttendees = {
  seniorPersonnel: any;
  academicResearchAssociate: any;
  postDocs: number | string;
  totalAmountForTrip: number | string;
  graduateStudents: number | string;
  undergraduateStudents: number | string;
  civilService: number | string;
  union: number | string;
  totalLodgingBeforeInflation?: string | number;
  totalMealsBeforeInflation?: string | number;
  totalIeBeforeInflation?: string | number;
  lodgingBreakdownAmount?: string | number;
  totalMAndIeBeforeInflation?: string | number;
  totalDiscount?: string | number;
  tempAndCasual: number | string;
  otherPersonnel: number | string;
  years: any;
  compensationBeforeInflation: number | string;
  totalDiscountAmount?: number | string;
  mAndIeBreakdownAmount?: number | string;
}

export type TravelCostMealExpensesSeason = {
  days?: number | string;
  total: number | string;
  maxTotal: number | string;
  startDate: any;
  serialNumber: number | string;
  endDate: any;
  rate: number | string;
  doBudgetMaxAmount: boolean;
  maxTotalAdjust?: number | string;
  discount: {
    amount: string | number;
    threeQuarters: string | number;
    isStartDateFollowsEndDate: boolean
    dailyAmountOfExpenses: string | number;
    maxAmount: string | number;
  }
}

export type TravelCostMealExpenses = {
  seasons: TravelCostMealExpensesSeason[];
}

export type IEBreakdownSeason = {
  days?: string | number;
  total: string | number;
  maxTotal: string | number;
  isDeparture: boolean;
  isReturn: boolean;
  startDate?: any;
  serialNumber: string | number;
  endDate?: any;
  rate: string | number;
  doBudgetMaxAmount: boolean;
  maxTotalAdjust?: string | number;
  discount: {
    amount: string | number;
    threeQuarters: string | number;
    isStartDateFollowsEndDate: boolean;
    dailyAmountOfExpenses: string | number;
    maxAmount: string | number;
  }
}

export type IEBreakdown = {
  seasons: IEBreakdownSeason[];
}

export type TravelCostYear = {
  totalAmountForTrip: number | string;
  compensationBeforeInflation: number | string;
  seniorPersonnel: any[];
  academicResearchAssociate: any[];
  postDocs: number | string;
  compensationBeforeInflationPreview: string | number;
  compensationAfterInflationPreview: string | number;
  numberOfOccurrence?: string | number;
  numberOfConsultants?: number | string;
  isEnabled: boolean;
  isNotForPersons?: boolean;
  graduateStudents: number | string;
  undergraduateStudents: number | string;
  civilService: number | string;
  union: number | string;
  tempAndCasual: number | string;
  otherPersonnel: number | string;
  years: TravelYear[];
  totalAmountOfPersons: any;
  totalAmount: string | number;
  compensationAfterInflation: any;
  typeOfExpenses: string | number;
  year: string[];
  serialNumber: string | number;
  justification: string | number;

}


export type TravelCostData = {
  id: string;
  kind: string;
  nameOfTrip: string;
  numberOfSeasons: string;
  zip: string;
  country: string;
  state: string;
  city: string;
  fiscalYear: string;
  startDate: any;
  category?: string;
  typeOfExpenses?: string;
  price?: string | number;
  totalAmount?: string | number;
  numberOfOccurrence?: string | number;
  years?: TravelCostYear[];
  isPerPerson?: boolean;
  tripAttendees: TripAttendees;
  totalsNote?: string | number;
  totalAmountForTrip?: string | number;
  endDate: any;
  mealsExpenses?: TravelCostMealExpenses;
  mAndIeBreakdown: MaIEBreakdown;
  isStartEndDateKnown: boolean;
  lodgingBreakdown: TravelCostLodgingData;
  ieBreakdown: IEBreakdown;
}

export type TransportationMethod = {
  method: string;
  id: string;
  nameExpense: string;
  typeOfExpense: string;
  startLocation: string;
  totalAmountForTrip: any;
  endLocation: string;
  compensationBeforeInflation: any;
  years: TransportationMethodYear[];
  details: TransportationMethodDetails;
}

export type TotalYear = {
  label: string;
  value: string | number;
}

export type Total = {
  kind: string;
  fullName: string;
  nameOfTrip: string;
  totals: string | number;
  total: string | number;
  years: TotalYear[];
}

export type TravelCostsTotals = {
  domestic: Total[],
  foreign: Total[];
  domesticAndForeign: Total[];
}

export enum ETravelType {
  GrantPersonnel = 'grantPersonnel',
  Consultant = 'consultant'
}

export type TravelConsultantServiceProviders = {
  id: string;
  name: string;
  consultantServiceId: string;
  yearSerialNumber: string;
}

export type Travel = {
  tripName: string;
  id: string;
  type: ETravelType;
  tripDescription: string;
  consultantServiceProviders: TravelConsultantServiceProviders[];
  tripDefaultLocation: string;
  totalAmount: number | string;
  travelCostsTotalAmount: number | string;
  travelMethodsTotalAmount: number | string;
  tripPurpose: string;
  estimatedStartDate?: string | null;
  estimatedEndDate?: string | null;
  isConferenceTrip?: boolean;
  status: TravelStatus;
  costs: TravelCostData[];
  travelCostsAndMethodsTotals: TravelCostsTotals;
  transportationMethods: TransportationMethod[];
  consultantServices?: Array<{id: string, name: string}>
}

export type GrantEventParticipantYear = {
  serialNumber: number;
  year: string[]
  kind: string;
  doUse1stYearValue: boolean;
  numberOfPeople: number | string;
  hourlyRate: string | number;
  totalStipend: string | number;
  averageCompensationHours: string | number;
  averageCompensationForPerson: string | number;
  justification: string;
}

export interface GrantEventParticipantTransportationMethod extends TransportationMethod {

}

export type GrantEventParticipantExpenseYear = {
  year: string[];
  serialNumber?: number | string;
  compensationAfterInflation?: number | string;
  compensationBeforeInflation?: number | string;
  isEnabled: boolean;
  numberOfOccurrence: number | string;
  numberOfParticipants?: number | string;
  amountWithInflation: number | string;
}

export type GrantEventParticipantExpense = {
  amount: any;
  id: string;
  isSubsistence: boolean;
  name: string;
  justification: string;
  price: string | number;
  totalAmountForTrip: string | number;
  years: GrantEventParticipantExpenseYear[];
}

export type GrantEventParticipant = {
  id: string;
  category: string;
  travelMethods: GrantEventParticipantTransportationMethod[]
  years: GrantEventParticipantYear[];
  expenses: GrantEventParticipantExpense[];
}

export type GrantEventParticipantTotalYear = {
  label: string;
  value: number;
}

export type GrantEventParticipantTotal = {
  totals: number;
  years: GrantEventParticipantTotalYear[];
  fullName: string;
}
export type GrantEventParticipantsTotal = {
  stipends: GrantEventParticipantTotal;
  travel: GrantEventParticipantTotal;
  expenses: GrantEventParticipantTotal;
  totals: GrantEventParticipantTotal;
  category: string;
}

export type GrantEvent = {
  name: string;
  participants: GrantEventParticipant[];
  category: string;
  totalAmount: string | number;
  id: string;
}

export type GrantEventTotalEventYear = {
  label: string;
  value: string | number | any;
}
export type GrantEventTotalEvent = {
  fullName: string;
  years: GrantEventTotalEventYear[];
  totals: number | string;
}

export type GrantEventTotal = {
  category: string;
  events: GrantEventTotalEvent[];
}

export interface FullGrant extends Grant {
  organization: GrantOrganization;
  author: GrantAuthor;
  doUseDurationLeftover: boolean;
  equipment: Equip[];
  otherDirectCosts: {
    materialsAndSupplies: MaterialsSupplies;
    publications: Publications;
    allOtherCosts: OtherExpensesType;
    computerServices: ComputerServices;
    contracts: Contract[];
    incentivePayments: Payment[];
    subawards: Subaward[];
    allOtherCostsTotalAmount: string | number;
    consultantServicesTotalAmount: string | number;
    computerServicesTotalAmount: string | number;
    contractsTotalAmount: string | number;
    incentivePaymentsTotalAmount: string | number;
    materialsAndSuppliesTotalAmount: string | number;
    publicationsTotalAmount: string | number;
    subawardsTotalAmount: string | number;
    consultantServices: ConsultantServicesType;
  };
  fees: FeesType;
  costSharingExpenses: CostSharingExpensesType;
  daysInGrant: string;
  budgetCap: string | number;
  budgetBar: {
    directCost: string | number;
    directCostOverbudget: string | number;
    indirectCost: string | number;
    indirectCostOverbudget: string | number;
  };
  equipmentTotalAmount: string | number;
  participantSupportTotalAmount: string | number;
  civilServiceTotalAmount: string | number;
  academicResearchAssociateTotalAmount: string | number;
  costSharingTotalAmount: string | number;
  feesTotalAmount: string | number;
  otherPersonnelTotalAmount: string | number;
  postDocPersonnelTotalAmount: string | number;
  seniorPersonnelTotalAmount: string | number;
  tempAndCasualTotalAmount: string | number;
  unionPersonnelTotalAmount: string | number;
  travel: Travel[];
  yearsInGrant: string;
  events: GrantEvent[];
  monthsInGrant: string;
  postdoctoralPersonal: PostDoctoralPersonal;
  postDocPersonnel: PostDoctoralPersonal[];
  years: string[][];
  rolesCount: {
    coPi: number;
    pi: number;
  };
  completion: number;
  travelsTotalAmount: number | string;
  seniorPersonal: SeniorPersonal[];
  graduateStudents: GraduateStudent;
  undergraduateStudents: UnGraduateStudent;
  academicResearchAssociate: SeniorPersonal[];
  civilService: CivilService[];
  unionPersonnel: CivilService[];
  tempAndCasual: CivilService[];
  acceptedForm: GrantAcceptedForm;
  otherPersonnel: CivilService[];
  participantSupportTotals: {
    events: GrantEventTotal[];
  }
}

export type Dashboard = {
  countAccepted: number;
  countTotal: number;
  totalFunding: number;
  personalBudget: number;
  grantsStats: Stat[];
}


export type AppliedType = {
  id: string;
  title: string;
  isAccepted: boolean;
}

export type Rate = {
  id: string;
  rate: string | number;
  startDate: string;
  isOverlays?: boolean;
  endDate: string;
  organizationName: string;
  location: string;
  comment: string;
  isGrantExclusive: boolean;
  createdAt: string;
  appliedToGrants: AppliedType[];
}


export type RatePreview = {
  overlay: {
    startDate: string[];
    endDate: string[];
  };
  uncoveredPeriods: string[][];
}

export type SidebarTotals = {
  id: string;
  createdAt: string;
  isAccepted: boolean;
  isOngoing: boolean;
  rolesCount: Record<string, number>;
  primaryInvestigator: any;
  years: string[];
  isPrimaryGeneralInfoComplete: boolean;
  yearsInGrant: number;
  monthsInGrant: number;
  daysInGrant: number;
  seniorPersonnelTotalAmount: number;
  academicResearchAssociateTotalAmount: number;
  postDocPersonnelTotalAmount: number;
  civilServiceTotalAmount: number;
  otherPersonnelTotalAmount: number;
  tempAndCasualTotalAmount: number;
  unionPersonnelTotalAmount: number;
  travelsTotalAmount: number;
  equipmentTotalAmount: number;
  participantSupportTotalAmount: number;
  feesTotalAmount: number;
  costSharingTotalAmount: number;
  hasDurationLeftover: boolean;
  completion: number;
}

export type GrantsState = ImmutableObject<{
  list: List;
  one: ImmutableObject<FullGrant>;
  side: ImmutableObject<FullGrant>;
  sides: Array<FullGrant>;
  editable: ImmutableObject<FullGrant>;
  stats: Stat[];
  sidebar: SidebarTotals[];
  rates: Record<string, Rate[]>;
  ratesPreview: Record<string, RatePreview>;
  subAwardScheme: SubAwardeeForm;
  dashboard: Dashboard;
  activeId: string;
}>
