import { createAction } from 'helpers/actions';

export const setUser = createAction('auth/set_user');
export const setRates = createAction('auth/set_rates');
export const addRate = createAction('auth/add_rate');
export const updateRate = createAction('auth/update_rate');
export const deleteRate = createAction('auth/delete_rate');
export const setToken = createAction('auth/set_token');
export const clearAuth = createAction('auth/clear');
export const setPlansActions = createAction('auth/set_plans');
