import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import Typo from 'components/typo';
import Field from 'components/field';
import {Button, Form, Checkbox, DatePicker, Input} from 'components/form';
import {TravelCostSeason} from 'store/grants/types';
import styles from './alaska.module.css';
import {Errors, localEmptyValidate} from 'helpers/errors';
import {isEmpty} from 'lodash';
import {FieldErrors} from "react-hook-form";

type Props = {
  item: TravelCostSeason;
  errors: Errors;
  idx: number;
  zero?: boolean;
  inter?: boolean;
  onChange: (field: Record<string, any>, blur?: boolean) => void;
  onSubmit: (season: TravelCostSeason) => void;
  onClear: (idx: string | number) => void;
  onBlur: (event: any, season: TravelCostSeason) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const Season: FC<Props> = ({
                             trackError,
                             trackExit,
                             trackFormStarted,
                             item,
                             onClear,
                             inter,
                             zero,
                             onBlur,
                             errors,
                             idx,
                             onSubmit,
                             onChange
                           }) => {
  const [localErrors, onChangeLocalErrors] = useState<Errors>({});
  const handleSubmit = useCallback(() => {
    let list: any[] = [];
    if (zero) {
      const fields = [
        {key: `nights`, label: 'nights'},
        {key: `lodgingRate`, label: 'lodging rate'},
        ...(item.doBudgetMaxAmount
          ? [{key: `maxTotalAdjust`, label: 'max total adjust'}]
          : [])
      ];
      const itIsRequired = Boolean(item.nights || item.lodgingRate || (item.doBudgetMaxAmount && item.maxTotalAdjust))
      list = [
        ...list,
        ...(itIsRequired ? fields : [])
      ];
    } else {
      const itIsRequired = Boolean(item.startDateOfTravel || item.endDateOfTravel || item.lodgingRate || (item.doBudgetMaxAmount && item.maxTotalAdjust))
      const fields = [
        {key: `startDateOfTravel`, label: 'start date'},
        {key: `endDateOfTravel`, label: 'end date'},
        {key: `lodgingRate`, label: 'lodging rate'},
        ...(item.doBudgetMaxAmount
          ? [{key: `maxTotalAdjust`, label: 'max total adjust'}]
          : [])
      ];
      list = [
        ...list,
        ...(itIsRequired ? fields : [])
      ];
    }
    const localErrors = localEmptyValidate(list, item, 'Please enter');
    onChangeLocalErrors(localErrors);
    if (!isEmpty(localErrors)) return;
    onSubmit(item);
  }, [onSubmit, zero, item]);

  const errorKey = `lodgingBreakdown.seasons[${idx}]`;

  const handleBlur = useCallback((event: any) => {
    onBlur(event, item);
  }, [onBlur, item]);

  const maxError = ['The entered value is over the maximum amount allowed according to the website. Please double check to make sure the values for each daily rate and number of nights are correct.'];
  const disabledMax = useMemo(() => {
    return item.doBudgetMaxAmount && item.maxTotalAdjust > item.maxTotal
  }, [item]);

  const clearSeason = useCallback(() => {
    onClear(item.serialNumber);
  }, [item, onClear]);

  // const disabled = useMemo(() => {
  //   if (zero) {
  //     return (
  //       !item.nights ||
  //       !item.lodgingRate ||
  //       !(item.doBudgetMaxAmount ? item.maxTotalAdjust <= item.maxTotal : true)
  //     );
  //   }
  //   return (
  //     !(item.startDateOfTravel && item.endDateOfTravel)
  //     ||
  //     !item.lodgingRate ||
  //     !(item.doBudgetMaxAmount ? item.maxTotalAdjust <= item.maxTotal : true)
  //   );
  // }, [item, zero]);

  return (
    <Form onSubmit={handleSubmit} className={styles.season} trackError={trackError} trackExit={trackExit}
          trackFormStarted={trackFormStarted}>
      {zero ? null : <Typo type="h5" className={styles.seasonTitle} bold>{`Season ${item.serialNumber}`}</Typo>}
      {
        zero
          ?
          <Field full>
            <Input value={item.nights}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({nights: event.target.value})}
                   name="nights" label="Number of nights of travel" placeholder="Enter number of nights"
                   type="number" onBlur={handleBlur} required
                   errors={errors[`${errorKey}.nights`] || localErrors.nights}
            />
          </Field>
          :
          <Field>
            <DatePicker errors={errors[`${errorKey}.startDateOfTravel`] || localErrors.startDateOfTravel}
                        value={item.startDateOfTravel}
                        onChange={(startDateOfTravel: Date | null) => onChange({startDateOfTravel}, true)}
                        name={`startDateOfTravel_${idx}`} label="Start date of travel in the season" required
                        placeholder="Select a start date"/>
            <DatePicker errors={errors[`${errorKey}.endDateOfTravel`] || localErrors.endDateOfTravel}
                        value={item.endDateOfTravel} minDate={item.startDateOfTravel || undefined}
                        onChange={(endDateOfTravel: Date | null) => onChange({endDateOfTravel}, true)}
                        name={`endDateOfTravel_${idx}`} label="End date of travel in the season" required
                        placeholder="Select a end date"/>
            <Input value={item.nights} disabled readOnly
                   name="nights" label="Number of nights of travel in the season" placeholder="Enter number of nights"
                   type="number"
                   errors={errors[`${errorKey}.nights`]}
            />
          </Field>
      }
      <Field>
        <Input name={`lodgingRate_${idx}`} onBlur={handleBlur}
               errors={errors[`${errorKey}.lodgingRate`] || localErrors.lodgingRate} type="number"
               label="Maximum lodging rate" required placeholder="Enter rate"
               value={item.lodgingRate} money
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({lodgingRate: event.target.value})}
        />
      </Field>
      <Field>
        <Input name={`maxTotal_${idx}`} type="number" money value={item.maxTotal} disabled readOnly
               label="Max lodging total" placeholder="Max lodging total"
        />
        <Checkbox name={`doBudgetMaxAmount_${idx}`} field value={item.doBudgetMaxAmount}
                  label="I want to budget the maximum amount possible for lodging"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({
                    doBudgetMaxAmount: event.target.checked,
                    maxTotalAdjust: event.target.checked ? item.maxTotal : ''
                  })}
        />
      </Field>
      <Field last full={zero} withHelp>
        <Input name={`maxTotalAdjust_${idx}`} money onBlur={handleBlur}
               errors={disabledMax ? maxError : (errors[`${errorKey}.maxTotalAdjust`] || localErrors.maxTotalAdjust)}
               disabled={!item.doBudgetMaxAmount} type="number"
               help={inter ? 'Based on the numbers entered, we calculated the maximum amount possible for lodging. However, ' +
                 'if you want to change this value, uncheck the box and enter the adjusted value in the Adjusted max ' +
                 'lodging total. Please note that the value entered may not be greater than Max lodging total.'
                 : 'For the first day, you can only budget 75% of the expenses'}
               required={item.doBudgetMaxAmount}
               label="Adjusted max lodging total" placeholder="Enter adjusted max lodging total"
               value={item.maxTotalAdjust}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({maxTotalAdjust: event.target.value})}
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" name={`submit-season-${item.serialNumber}`}>Save</Button>
        <div className={styles.clear}>
          <Button htmlType="button" onClick={clearSeason} type="link" name={`clear-season-${idx}`}>Clear and
            save</Button>
        </div>
      </div>
    </Form>
  )
}

export default Season;
