import {useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {confirm} from 'components/confirmation';
import {useHistory} from 'react-router';
import * as T from './requestTypes';
import useApi from './useApi';
import useGrants from './useGrants';
import {grantSelector} from 'store/grants/selector';
import {addEquipToGrantAction} from 'store/grants/actions';
import {Equip} from 'store/grants/types';
import {AxiosResponse, AxiosError} from 'axios';
import parseErrors, {Errors} from 'helpers/errors';
import {queryToString} from 'helpers/query';
import toast from 'components/toast';
import {ListItem} from 'helpers/utils';
import {EquipmentData} from './requestTypes';
import useUI from './useUI';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";

export type Total = {
  fullName: string;
  years: ListItem[];
  total: number;
  totals: number;
}

export type Totals = Record<string, Total[]>;

type iUseEquipment = {
  create: (grantId: string) => void;
  update: (grantId: string, unitId: string, data: T.EquipmentData) => void;
  destroy: (grantId: string, unitId: string, redirect?: boolean) => void;
  getTotals: (grantId: string) => void;
  equipment: Equip[];
  calculate: (grantId: string, unitId: string, data: T.EquipmentUnitCalculations, cb: (data: T.EquipNsfCompensation) => void) => void;
  errors: Errors;
  updateNSFCompensation: (grantId: string, unitId: string, data: T.EquipNsfCompensation) => void;
  loading: boolean;
  totals: Totals;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}


const useEquipment = (props: Props = {}): iUseEquipment => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'equipment';
  const form_subcategory = null;
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const api = useApi();
  const {equipment} = useSelector(grantSelector);
  const [errors, onChangeErrors] = useState<Errors>({});
  const {getOneWithSide} = useGrants();
  const {onShowNavigationMessage} = useUI();
  const [loading, onChangeLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [totals, onChangeTotals] = useState<Totals>({});

  const create = useCallback((grantId: string) => {
    onChangeErrors({});
    onChangeLoading(true);
    const emptyEquipUnit = {};
    api.addEquipment(grantId, (emptyEquipUnit as unknown as EquipmentData))
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        dispatch(addEquipToGrantAction(response.data))
        history.push(`/grants/${grantId}/equipment/${response.data.id}/edit`);
        onChangeLoading(false);
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
        onChangeLoading(false);
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, dispatch, history]);

  const update = useCallback((grantId: string, unitId: string, data: T.EquipmentData) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateEquipment(grantId, unitId, data)
      .then(() => {
        formSuccessSaveCallBack()
        getOneWithSide(grantId);
        onChangeLoading(false);
        toast.success({
          title: 'The equipment details have been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
        onChangeLoading(false);
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const updateNSFCompensation = useCallback((grantId: string, unitId: string, data: T.EquipNsfCompensation) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateEquipmentNSFCompensation(grantId, unitId, data)
      .then(() => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onShowNavigationMessage();
        getOneWithSide(grantId);
        toast.success({
          title: 'The equipment details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
        onChangeLoading(false);
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, onShowNavigationMessage, getOneWithSide]);

  const destroy = useCallback((grantId: string, unitId: string, redirect?: boolean) => {
    confirm({
      title: 'Delete equipment info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteEquipment(grantId, unitId)
          .then(() => {
            getOneWithSide(grantId, () => {
              if (redirect) history.push(`/grants/${grantId}/edit`);
            });
            toast.success({
              title: 'The unit has been deleted',
              message: 'Changes have been successfully saved'
            });
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const calculate = useCallback((grantId: string, unitId: string, data: T.EquipmentUnitCalculations, cb: (data: T.EquipNsfCompensation) => void) => {
    onChangeErrors({});
    const q = queryToString(data);
    api.getEquipmentCalculations(grantId, unitId, q)
      .then((response: AxiosResponse) => {
        cb(response.data);
      });
  }, [api]);

  const getTotals = useCallback((grantId: string) => {
    api.equipmentTotals(grantId)
      .then((response: AxiosResponse) => {
        onChangeTotals(response.data);
      })
  }, [api]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    create,
    destroy,
    totals,
    getTotals,
    updateNSFCompensation,
    calculate,
    errors,
    update,
    loading,
    equipment,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useEquipment;
