import {useState, useCallback, ChangeEvent} from 'react';
// types
import {MaterialsSuppliesGeneralInfoType} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// hooks
import useEnums from 'hooks/useEnums';
// components
import {Input, Select, Form, Button} from 'components/form';
import Field from 'components/field';
// styles
import styles from 'pages/grant/one/otherDirectCosts/form.module.css';
import {FieldErrors} from "react-hook-form";

const hasCapitalizationLevelHint = 'This is an amount that your university may or may not have set, which sets the threshold of what should be categorized as materials and supplies or what should be categorized as equipment. Equipment is always categorized as the amount over this threshold, while materials and supplies represent the amounts below this threshold. If your university doesn\'t have a capitalization threshold, feel free to leave this blank for now. The default capitalization rate is normally $5,000.'

type Props = {
  generalInfo: MaterialsSuppliesGeneralInfoType;
  onSubmit: (data: MaterialsSuppliesGeneralInfoType) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const MaterialsSuppliesGeneralInfoForm = (props: Props) => {
  const {
    trackError,
    trackExit, generalInfo, onSubmit
  } = props;
  const [data, onChangeData] = useState<MaterialsSuppliesGeneralInfoType>({
    // @ts-ignore TODO
    hasCapitalizationLevel: generalInfo?.hasCapitalizationLevel ?? '',
    defaultCapitalizationLevel: generalInfo?.defaultCapitalizationLevel ?? '',
    universityCapitalizationLevel: generalInfo?.universityCapitalizationLevel ?? '',
  });
  const {yesNoOptions} = useEnums()

  const handleChangeHasCapitalizationLevel = (value: boolean) => {
    if (!value) {
      onChangeData((prev) => ({
        ...prev,
        hasCapitalizationLevel: value,
        defaultCapitalizationLevel: 5000,
        universityCapitalizationLevel: 0
      }));
    } else {
      onChangeData(prev => ({
        ...prev,
        hasCapitalizationLevel: value,
        defaultCapitalizationLevel: 0,
        universityCapitalizationLevel: ''
      }))
    }
  }

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const handleSubmit = () => {
    onSubmit(data);
  };

  return (
    <Form prompt onSubmit={handleSubmit} trackExit={trackExit} trackError={trackError}>
      <Field>
        <Select
          className={styles.fullInput}
          options={yesNoOptions}
          value={data.hasCapitalizationLevel}
          name="hasCapitalizationLevel"
          label="Does your university have a capitalization level?"
          placeholder="Select the item"
          bool
          help={hasCapitalizationLevelHint}
          required
          onChange={(value: boolean) => handleChangeHasCapitalizationLevel(value)}
        />
      </Field>

      <Field last>
        <Input
          disabled={true}
          name="defaultCapitalizationLevel"
          money
          label="Default capitalization level"
          placeholder="Enter default capitalization level"
          className={styles.input}
          value={data.defaultCapitalizationLevel}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({defaultCapitalizationLevel: event.target.value})
          }
        />
        <Input
          disabled={!data.hasCapitalizationLevel}
          name="universityCapitalizationLevel"
          money
          label="University capitalization level"
          placeholder="Enter university capitalization level"
          className={styles.input}
          value={data.universityCapitalizationLevel}
          type="number"
          required={data.hasCapitalizationLevel}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChangeData({universityCapitalizationLevel: event.target.value})
          }
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" name="submit-ms-general-info">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MaterialsSuppliesGeneralInfoForm;
