import { ReactElement, FC } from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import Spinner from 'components/spinner';
import styles from './button.module.css';

type Props = {
  type?: 'primary' | 'danger' | 'ghost' | 'bordered' | 'link' | 'mint';
  htmlType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  name: string;
  className?: string;
  onClick?: () => void;
  preIcon?: string;
  postIcon?: string;
  loading?: boolean;
  full?: boolean;
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'xs';
  children?: string | string[] | ReactElement | ReactElement[];
}

const Button:FC<Props> = (props: Props) => {
  const {
    type = 'primary',
    htmlType = 'button',
    disabled, name,
    className, onClick,
    loading, size = 'md',
    children, preIcon,
    postIcon, full
  } = props;

  return (
    <button
      type={htmlType} disabled={disabled || loading}
      name={name} onClick={onClick} cypress-id={`${name}-btn`}
      className={cx(styles.btn, className, styles[type], styles[size], { [styles.loading]: loading, [styles.full]: full, [styles.empty]: !children })}
    >
      {preIcon && !loading ? <Icon size={size === 'xxl' ? 20 : 16} className={cx(styles.icon, 'btn-icon', styles.preicon)} icon={preIcon}/> : null}
      {loading ? <Spinner small white={['primary', 'danger'].includes(type)}/> : children}
      {postIcon && !loading ? <Icon size={size === 'xxl' ? 20 : 16} className={cx(styles.icon, 'btn-icon', styles.posticon)} icon={postIcon}/> : null}
    </button>
  )
}

export default Button;
