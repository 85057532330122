import { FC, useEffect, useMemo } from 'react';
import Typo from 'components/typo';
import useGrants from 'hooks/useGrants';
import Table, { Cell } from 'components/table';
import useEquipment, { Total } from 'hooks/useEquipment';
import styles from './total.module.css';
import { ListItem } from 'helpers/utils';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {numberToMoney} from 'helpers/numbers';


const prepareHeaders = (items: Total[]) => {
  const [first] = items;
  const years = first?.years.map((item: ListItem) => item.label);
  return ['Unit Name', ...(years ?? []), 'Total'];
}

const prepareData = (items: Total[]):Array<Cell[]> => {
  return items.map((item: Total) => {
    const yearsValues = item?.years.map((yearItem: ListItem) => <span className={styles.gray}>{numberToMoney(yearItem.value)}</span>);
    const total = item.total || item.totals;
    return [
      item.fullName,
      ...(yearsValues ?? []),
      total ? numberToMoney(total) : '-'
    ]
  });
}


type TotalsWithTitle = {
  title: string;
  headers: string[];
  data: Array<Cell[]>;
}

const TotalsPage:FC = () => {
  const { grant } = useGrants();
  const { sidebarWidth } = useUI();
  const { getTotals, totals, loading } = useEquipment();

  useEffect(() => {
    if (grant.id) {
      getTotals(grant.id)
    }
  }, [grant.id]);

  const list:TotalsWithTitle[] = useMemo(() => {
    return Object.keys(totals).map((title: string) => {
      const data = totals[title] as Total[];
      return {
        title,
        headers: prepareHeaders(data),
        data: prepareData(data),
      }
    })
  }, [totals]);

  return (
    <>
      <div className="container">
        <div className={styles.wrapper}>
          <Typo type="h3" bold className={styles.title}>Equipment</Typo>
          <div className={styles.subtitleWrapper}>
            <Typo type="h4" className={styles.subtitle}>
              Totals
            </Typo>
          </div>
          <div className={styles.tables}>
            {list.map((item: TotalsWithTitle, idx: number) => (
              <Table title={item.title} key={idx} className={styles.table} data={item.data} headers={item.headers} />
            ))}
          </div>
        </div>
      </div>
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  )
}

export default TotalsPage;
