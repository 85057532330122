import {FC} from 'react';
// hooks
import usePersonnel from 'hooks/usePersonnel';
// types
import {personalTypes} from 'const';
// components
import CreateBtn from 'pages/grant/paper/card/create';
import {FullGrant} from 'store/grants/types';

type PersonalTypeKeys = keyof typeof personalTypes;
type PersonalTypesValues = typeof personalTypes[PersonalTypeKeys];

type Props = {
  personnelType: PersonalTypesValues;
  btnText: string;
  side: FullGrant;
  isDisabled: boolean;
  showMissedGeneralInfoTip: boolean;
}

const PersonnelCreationButton: FC<Props> = ({
  personnelType,
  btnText,
  side,
  isDisabled,
  showMissedGeneralInfoTip,
}) => {
  const {
    createSeniorPerson,
    createAcademicResearchPerson,
    createPostDoctoralPerson,
    createGraduatedStudent,
    createUndergraduateStudent,
    createOtherPerson
  } = usePersonnel();

  let creationHandler: (grantId: string, personnelType: string) => void;

  switch (personnelType) {
    case personalTypes.seniorPersonal:
      creationHandler = createSeniorPerson;
      break;
    case personalTypes.academicResearchAssociate:
      creationHandler = createAcademicResearchPerson;
      break;
    case personalTypes.postDocPersonnel:
      creationHandler = createPostDoctoralPerson;
      break;
    case personalTypes.graduateStudents:
      creationHandler = createGraduatedStudent;
      break;
    case personalTypes.undergraduateStudents:
      creationHandler = createUndergraduateStudent;
      break;
    default:
      creationHandler = createOtherPerson;
  }

  return (
    <CreateBtn tip="To add personnel, you must add grant general information"
               disabled={isDisabled} when={showMissedGeneralInfoTip}
               name={`add_${personnelType}`} side={side}
               onClick={() => creationHandler(side.id, personnelType)}
    >
      {btnText}
    </CreateBtn>
  )
}

export default PersonnelCreationButton;
