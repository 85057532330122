import {FC, useCallback, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
// components
import {FullGrant, Travel} from 'store/grants/types';
import TransportationMethodsList from 'pages/grant/paper/card/folders/travel/components/transportationMethodsList';
import CostList from 'pages/grant/paper/card/folders/travel/components/costList';
// styles
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import styles from './travel.module.css';

type Props = {
  trip: Travel;
  side: FullGrant;
  onRemoveMethod: (grantId: string, travelId: string, methodId: string, redirect: boolean) => void;
  onRemoveCost: (grantId: string, travelId: string, costId: string, redirect: boolean) => void;
}

const Trip:FC<Props> = ({ trip, onRemoveCost, onRemoveMethod, side }) => {
  const url = useUrl();

  const activeEdit = useMemo(() => {
    return url.categoryId === trip.id && url.section === 'edit';
  }, [url, trip]);

  const activeStatus = useMemo(() => {
    return url.categoryId === trip.id && url.section === 'status';
  }, [url, trip]);

  const handleRemoveTransportationMethod = useCallback((id: string) => {
    onRemoveMethod(side.id, trip.id, id, url.section === id);
  }, [onRemoveMethod, side, url, trip]);

  const handleRemoveCost = useCallback((id: string) => {
    onRemoveCost(side.id, trip.id, id, url.categoryId === id);
  }, [onRemoveCost, side, url, trip]);

  const activeTotals = useMemo(() => {
    return url.categoryId === trip.id && url.section === 'totals'
  }, [trip, url]);

  return (
    <div className={styles.tripWrapper}>
      <Anchor thin to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/edit`} active={activeEdit}>
        Trip info
      </Anchor>

      <Anchor thin to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/status`} active={activeStatus}>
        Trip status
      </Anchor>

      <TransportationMethodsList
        side={side}
        trip={trip}
        transportationMethods={trip.transportationMethods}
        handleRemoveTransportationMethod={handleRemoveTransportationMethod}
      />

      <CostList
        side={side}
        trip={trip}
        costs={trip.costs}
        handleRemoveCost={handleRemoveCost}
      />

      <TotalAnchor total={trip.totalAmount} to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/totals`} active={activeTotals}>
        Trip totals
      </TotalAnchor>
    </div>
  );
}

export default Trip;
