import {FC, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
import useUrl from 'hooks/useUrl';
import {Contract as ContractType, ContractYear} from 'store/grants/otherDirectCosts/Contracts/types';
import useContracts from 'hooks/useContracts';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import CreateBtn from 'pages/grant/paper/card/create';
import Typo from 'components/typo';
import styles from '../cs/cs.module.css';
import {FullGrant} from 'store/grants/types';

type Props = {
  side: FullGrant;
}

const OtherCostContracts:FC<Props> = ({ side }) => {
  const url = useUrl();
  const { loading, deleteContract, createContract } = useContracts();

  const handleDeleteContract = useCallback((id?: string) => {
    if (id) deleteContract(side.id, id, url.categoryId === id);
  }, [deleteContract, url, side]);

  const list = useMemo(() => {
    return side.otherDirectCosts.contracts.map((item: ContractType) => {
      const links = [
        {
          label: 'Contract info',
          to: `/grants/${side.id}/otherDC/contracts/${item.id}/edit`,
          active: url.categoryId === item.id && url.section === 'edit'
        },
        ...item.years.map((yearItem: ContractYear, idx: number) => ({
          label: `${dayjs(yearItem.year[0]).format('MM/DD/YYYY')} - ${dayjs(yearItem.year[1]).format('MM/DD/YYYY')}`,
          to: `/grants/${side.id}/otherDC/contracts/${item.id}/year/${idx}`,
          active: url.categoryId === item.id && url.section === 'year' && url.sectionId === `${idx}`,
          isDisabled: !item.name || typeof item.isInflationAppliedEachYear !== 'boolean',
        }))
      ];

      return (
        <Item key={item.id}
              onDelete={() => handleDeleteContract(item.id)}
              title={item.name || 'Untitled contract'}
              links={links}
              active={url.categoryId === item.id}
        />
      )
    })
  }, [side, handleDeleteContract, url]);

  return (
    <>
      <Anchor thin to={`/grants/${side.id}/otherDC/contracts/general-info`} active={url.category === 'otherDC' && url.subCategory === 'contracts' && url.categoryId === 'general-info'}>
        General info
      </Anchor>
      {list.length > 0
        ? list
        : <Typo className={styles.placeholder} size={14} type="div">No contracts have been created yet</Typo>
      }
      {list.length > 0
        ?
          <TotalAnchor total={side.otherDirectCosts.contractsTotalAmount} to={`/grants/${side.id}/otherDC/contracts/totals`} active={url.categoryId === 'totals' && url.subCategory === 'contracts'}>
            Totals
          </TotalAnchor>
        : null
      }
      <CreateBtn loading={loading} onClick={() => createContract(side.id)}
                 name="add-grant-contract" side={side}
                 tip="To add contract, you must add grant general information"
      >
        Add contract
      </CreateBtn>
    </>
  );
}

export default OtherCostContracts;
