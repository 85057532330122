import {ChangeEvent, FC, useCallback, useState} from 'react';
import useGrants from 'hooks/useGrants';
import useTravel from 'hooks/useTravel';
import Field from 'components/field';
import { Input, Form, Textarea, Button } from 'components/form';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import TransportationYears from 'pages/grant/one/travel/common/years';
import { Travel, TravelCostData } from 'store/grants/types';
import styles from './totals.module.css';

type Props = {
  travel: Travel;
  cost: TravelCostData;
}

const OtherTotal:FC<Props> = ({travel, cost}) => {
  const { grant } = useGrants();
  const { previewTravelCost, updateTravelCost } = useTravel();

  const [data, onChangeData] = useState(() => {
    return {
      years: cost.years ?? [],
      totalAmount: cost.totalAmount ?? '',
      totalsNote: cost.totalsNote,
      totalAmountForTrip: cost.totalAmountForTrip
    }
  });

  const onBlur = useCallback((event?: any, dataToPreview?: any) => {
    const submitData = dataToPreview ?? data;
    const submitYears = submitData?.years;
    previewTravelCost(grant.id, travel.id, cost.id, {years: submitYears}, (response: any) => {
      const years = (response.years ?? data.years).map((item: any, idx: number) => ({
        ...item,
        numberOfOccurrence: item.numberOfOccurrence ?? submitYears[idx]?.numberOfOccurrence
      }));
      onChangeData({
        ...submitData,
        totalAmount: response.totalAmount,
        totalAmountForTrip: response.totalAmountForTrip,
        years
      });
    });
  }, [data, previewTravelCost, grant, cost, travel]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    onChangeData({
      ...data,
      ...field
    });
    if (preview) onBlur(undefined,{
      ...data,
      ...field
    });
  }, [data, onBlur]);

  const handleSubmit = useCallback(() => {
    updateTravelCost(grant.id, travel.id, cost.id, {
      years: data.years,
      kind: cost.kind,
      totalsNote: data.totalsNote,
      totalAmountForTrip: data.totalAmountForTrip
    })
  }, [cost, data, grant, travel, updateTravelCost]);

  return (
    <GrantFormWrapper title="Travel" subtitle="Travel Costs. Other. Totals">
      <Form prompt onSubmit={handleSubmit}>
        <Field>
          <Input name="totalAmount" value={data.totalAmount} placeholder="Total amount for the expense in a year" label="Total amount for the expense in a year" disabled readOnly />
        </Field>
        <TransportationYears onBlur={onBlur} label="Select the years that the expense will take place in" totalAmountForTrip={data.totalAmountForTrip}
                             //@ts-ignore
                             data={data.years} onChange={handleChangeData} />
        <Field last full>
          <Textarea name="note" label="Leave yourself a note amount this expense" value={data.totalsNote}
                    placeholder="Enter yourself a note..."
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({totalsNote: event.target.value})}
          />
        </Field>
        <div className={styles.footer}>
          <Button name="save-other-totals" htmlType="submit">Save</Button>
        </div>
      </Form>
    </GrantFormWrapper>
  );
}

export default OtherTotal;
