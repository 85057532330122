import {useCallback, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Errors } from 'helpers/errors';
import {AxiosError, AxiosResponse} from 'axios';
import * as T from './requestTypes'
import useApi from './useApi';
import useUI from './useUI';
import {addRate, deleteRate, setRates, updateRate} from 'store/auth/actions';
import {AppliedType, Rate, Rates} from 'store/auth/types';
import {authRatesSelector} from 'store/auth/selector';
import { setGrantsRates, setGrantsRatesPreview } from 'store/grants/actions';
import { ratesSelector, ratesPreviewSelector } from 'store/grants/selector';
import {confirm} from 'components/confirmation';
import toast from 'components/toast';
import useGrants from './useGrants';
import {RatePreview} from 'store/grants/types';
import {FieldErrors} from "react-hook-form";
import {useMixPanel} from "./useMixPanel";

type iUseRates  = {
  errors: Errors;
  rates: Rates;
  userRatesForGrant: Rates;
  getGrantRatesFromStore: (grantId: string) => Rate[];
  loading: boolean;
  spinner: boolean;
  getUserRates: () => void;
  getUserRatesForGrant: (grantId: string, cb?: () => void) => void;
  getGrantRates: (grantId: string) => void;
  getGrantRatesPreviewFromStore: (grantId?: string) => RatePreview;
  createUserRate: (data: T.RateData, cb?: (data: T.RateData) => void) => void;
  addUserRatesToGrant: (grantId: string, data: T.UserRateGrants, cb: () => void) => void;
  createGrantRate: (grantId: string, data: T.RateData, cb: () => void) => void;
  previewGrantRate: (grantId: string, data: T.RatePreviewData, cb?: (data: T.RatePreviewResponse) => void) => void;
  updateUserRate: (id: string, data: T.RateData, cb: () => void) => void;
  deleteGrantRate: (grantId: string, id: string, cb?: () => void) => void;
  updateGrantRate: (grantId: string, id: string, data: T.RateData, cb: () => void) => void;
  deleteUserRate: (id: string, isApplied: boolean) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const RatePreviewAdapter = (data: T.RatePreviewResponse):T.RatePreviewResponse => {
  return {
    overlay: {
      startDate: data?.overlay?.startDate ?? [],
      endDate: data?.overlay?.endDate ?? [],
    },
    uncoveredPeriods: data?.uncoveredPeriods ?? []
  }
}


export const RateAdapter = (data: Rate) => {
  return {
    id: data.id ?? '',
    rate: data.rate ? (Number(data.rate) * 100).toFixed(2) : '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    organizationName: data.organizationName ?? '',
    location: data.location ?? '',
    comment: data.comment ?? '',
    isOverlays: Boolean(data.isOverlays),
    isGrantExclusive: Boolean(data.isGrantExclusive),
    createdAt: data.createdAt ?? '',
    appliedToGrants: data.appliedToGrants?.map((item: AppliedType) => ({
      id: item.id ?? '',
      title: item.title ?? '',
      isAccepted: Boolean(item.isAccepted),
    })) || []
  }
}

type Props = {
  form_page_type?: string
}

const useRates = (props: Props = {}):iUseRates => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'F&A Rates'
  const form_subcategory = null;
  const {formStarted, formSaveFailed, formExited} = useMixPanel();
  const {grant} = useGrants();

  const dispatch = useDispatch();
  const { loader } = useUI();
  const rates = useSelector(authRatesSelector);
  const api = useApi();
  const { getOneWithSide } = useGrants();
  const grantRates = useSelector(ratesSelector);
  const ratesPreview = useSelector(ratesPreviewSelector);
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [userRatesForGrant, onChangeUserRatesForGrant]= useState<Rates>({
    data: []
  });
  const [errors, onChangeErrors] = useState<Errors>({});
  const [spinner, onChangeSpinner] = useState<boolean>(false);


  const getGrantRatesFromStore = useCallback((grantId: string) => {
    const list:Rate[] = grantRates[grantId] ?? [];
    return list;
  }, [grantRates]);

  const getGrantRatesPreviewFromStore = useCallback((grantId?: string) => {
    const defaultData = {
      overlay: {
        startDate: [],
        endDate: [],
      },
      uncoveredPeriods: []
    };
    if (grantId) {
      return ratesPreview[grantId] ?? defaultData;
    }
    return defaultData;
  }, [ratesPreview]);

  const createUserRate = useCallback((data: T.RateData, cb?: (data: T.RateData) => void) => {
    onChangeLoading(true);
    api.createUserRate(data)
      .then((response: AxiosResponse) => {
        dispatch(addRate(response.data));
        onChangeLoading(false);
        if (cb) cb(response.data);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [dispatch, api]);

  const createGrantRate = useCallback((grantId: string, data: T.RateData, cb: () => void) => {
    onChangeLoading(true);
    api.createGrantRate(grantId, data)
      .then((response: AxiosResponse) => {
        const currentList = getGrantRatesFromStore(grantId);
        const newList = [
          response.data,
          ...currentList
            .map((item: Rate) => ({...item, rate: Number(item.rate) / 100}))
        ];
        dispatch(setGrantsRates({id: grantId, rates: newList}));
        getOneWithSide(grantId);
        onChangeLoading(false);
        cb();
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [grantRates, getOneWithSide, api]);

  const updateUserRate = useCallback((id: string, data: T.RateData, cb: () => void) => {
    onChangeLoading(true);
    api.updateUserRate(id, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        dispatch(updateRate(response.data))
        cb();
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [dispatch, api]);

  const updateGrantRate = useCallback((grantId: string, id: string, data: T.RateData, cb: () => void) => {
    onChangeLoading(true);
    api.updateGrantRate(grantId, id, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        const currentList = getGrantRatesFromStore(grantId);
        const newRates = currentList.map((rate: Rate) => {
          if  (rate.id === id) return {...rate, ...response.data}
          return {
            ...rate,
            rate: Number(rate.rate) / 100
          }
        });
        getOneWithSide(grantId);
        dispatch(setGrantsRates({id: grantId, rates: newRates}));
        cb();
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [getGrantRatesFromStore, dispatch, getOneWithSide, api]);

  const addUserRatesToGrant = useCallback((grantId: string, data: T.UserRateGrants, cb: () => void) => {
    onChangeLoading(true);
    api.addUserRatesToGrant(grantId, data)
      .then((response: AxiosResponse) => {
        onChangeLoading(false);
        const currentList = getGrantRatesFromStore(grantId);
        const newList = [
          ...response.data.rates,
          ...currentList
            .map((item: Rate) => ({...item, rate: Number(item.rate) / 100}))
        ];
        dispatch(setGrantsRates({id: grantId, rates: newList}));
        cb();
        getOneWithSide(grantId);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  }, [getGrantRatesFromStore, dispatch, getOneWithSide, api]);

  const deleteUserRate = useCallback((id: string, isApplied: boolean) => {
    const text = isApplied
      ? 'The rate value will be deleted only from the repository. To delete the rate value in each grant, please use the F&A Rates section for each grant'
      : 'Are you sure you want to delete this data? This action will not be reversible.';
    confirm({
      title: 'Delete rate info',
      text,
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteUserRate(id)
          .then(() => {
            dispatch(deleteRate(id))
          })
          .catch((error: AxiosError) => {
            //@ts-ignore
            if (error?.response?.data?.message) {
              toast.error({
                title: 'Error', //@ts-ignore
                message: error?.response?.data?.message
              });
            }
          });
      }
    })
  }, [dispatch, api]);

  const deleteGrantRate = useCallback((grantId: string, id: string, cb?: () => void) => {
    api.deleteGrantRate(grantId, id)
      .then(() => {
        const currentList = getGrantRatesFromStore(grantId);
        const newList = currentList
          .filter((item: Rate) => item.id !== id)
          .map((item: Rate) => ({...item, rate: Number(item.rate) / 100}))
        dispatch(setGrantsRates({id: grantId, rates: newList}));
        getOneWithSide(grantId);
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data?.message) {
          toast.error({
            title: 'Error', //@ts-ignore
            message: error?.response?.data?.message
          });
        }
      });
  }, [getGrantRatesFromStore, dispatch, getOneWithSide, api]);

  const getUserRates = useCallback(() => {
    loader.start();
    onChangeSpinner(true);
    api.getUserRates()
      .then((response: AxiosResponse) => {
        loader.stop();
        onChangeSpinner(false);
        dispatch(setRates(response.data));
      })
      .catch(() => {
        loader.stop();
        onChangeSpinner(false);
      })
  }, [api, dispatch, loader]);

  const getUserRatesForGrant = useCallback((grantId: string, cb?: () => void) => {
    loader.start();
    api.getUserRates(`?grantId=${grantId}`)
      .then((response: AxiosResponse) => {
        loader.stop();
        onChangeUserRatesForGrant({
          data: response.data?.data?.map(RateAdapter) ?? []
        });
        if (cb) cb();
      })
      .catch(() => {
        loader.stop();
      })
  }, [api, loader]);

  const getGrantRates = useCallback((grantId: string) => {
    loader.start();
    onChangeSpinner(true);
    api.getGrantRates(grantId)
      .then((response: AxiosResponse) => {
        loader.stop();
        onChangeSpinner(false);
        dispatch(setGrantsRates({id: grantId, rates: response.data?.data ?? []}));
      })
      .catch(() => {
        onChangeSpinner(false);
        loader.stop();
      })
  }, [api, dispatch, loader]);

  const previewGrantRate = useCallback((grantId: string, data: T.RatePreviewData, cb?: (data: T.RatePreviewResponse) => void) => {
    const q = new URLSearchParams(data).toString();
    api.previewGrantRate(grantId, `?${q}`)
      .then((response: AxiosResponse) => {
        const responseData = RatePreviewAdapter(response.data);
        if (cb) {
          cb(responseData);
          return;
        }
        dispatch(setGrantsRatesPreview({id: grantId, data: response.data}));
      });
  }, [dispatch, api]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    errors,
    createGrantRate,
    updateGrantRate,
    getUserRates,
    getGrantRates,
    rates,
    previewGrantRate,
    deleteGrantRate,
    getUserRatesForGrant,
    getGrantRatesPreviewFromStore,
    loading,
    createUserRate,
    userRatesForGrant,
    spinner,
    deleteUserRate,
    addUserRatesToGrant,
    updateUserRate,
    getGrantRatesFromStore,
    trackError,
    trackExit,
    trackFormStarted
  };
}

export default useRates;
