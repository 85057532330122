import {FC} from 'react';
// types
import {TravelCostYear} from 'store/grants/types';
// components
import Typo from 'components/typo';
import {Checkbox, Input} from 'components/form';
// styles
import styles from './attendee.module.css';
import cx from 'classnames';

type Props = {
  years: Array<TravelCostYear>;
  totalAmountForTrip: string | number;
  onChangeYear: (field: Record<string, string | boolean>, serialNumber: number | string, blur?: boolean) => void;
  onBlur?: (event: any, data?: any) => void;
}

const ConsultantAttendeeYearsForm: FC<Props> = ({years, onChangeYear, onBlur, totalAmountForTrip}) => {
  return (
    <>
      <Typo className={styles.mb16}>
        Please check the year (years) of this grant proposal that this trip will take place in. Also, please
        specify the maximum number of consultants you would like to put on travel status. These numbers are
        used as a baseline multiplier later on in the trip budget and can be overridden when you reach that
        section.
      </Typo>

      <div className={styles.yearsFormWrapper}>
        {years.map((year, idx) => {
          return (
            <div className={styles.yearField} key={idx}>
              <Checkbox
                className={styles.yearCheckbox}
                onChange={(event) =>
                  onChangeYear({ isEnabled: event.target.checked }, year.serialNumber || idx, true)
                }
                value={year.isEnabled}
                name={`years[${year.serialNumber}].isEnabled`}
                label={`Year ${year.serialNumber}`}
              />
              <Input
                className={styles.yearInput}
                name={`years[${idx}].numberOfConsultants`}
                label={'Number of consultants in this trip'}
                type="number"
                value={year.numberOfConsultants}
                disabled={!year.isEnabled}
                onBlur={onBlur}
                onChange={(event) =>
                  onChangeYear({ numberOfConsultants: event.target.value }, year.serialNumber || idx)
                }
                placeholder="Number of consultants"
              />
              <Input
                className={styles.yearInput}
                name={`years[${idx}].numberOfOccurrence`}
                label={'Number of times expenses occur'}
                type="number"
                value={year.numberOfOccurrence}
                disabled={!year.isEnabled}
                onBlur={onBlur}
                onChange={(event) =>
                  onChangeYear({ numberOfOccurrence: event.target.value }, year.serialNumber || idx)
                }
                placeholder="Number of times"
              />
              <Input
                className={styles.yearInput}
                name={`years[${idx}].numberOfConsultants`}
                label={`Amount for year ${year.serialNumber} with inflation applied`}
                type="number"
                value={year.compensationAfterInflation}
                money
                disabled
                readOnly
                onChange={(event) =>
                  onChangeYear({ compensationAfterInflation: event.target.value }, year.serialNumber || idx)
                }
                placeholder="Amount of compensation after inflation"
              />
            </div>
          );
        })}

        <div className={styles.yearField}>
          <div className={styles.yearCheckbox}/>
          <div className={styles.yearInput}/>
          <div className={styles.yearInput}/>
          <div className={styles.yearInput}/>
          <Input
            className={cx(styles.yearInput, styles.yearLongInput)}
            name="totalAmountForTrip"
            label={'Total Amount for trip'}
            value={totalAmountForTrip}
            type="number"
            placeholder="Total amount"
            money
            disabled
            readOnly
          />
        </div>
      </div>
    </>
  )
}

export default ConsultantAttendeeYearsForm;
