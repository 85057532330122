import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useApi from './useApi';

import { dashboardSelector } from 'store/grants/selector';
import { setGrantsDashboard } from 'store/grants/actions';
import { Dashboard } from 'store/grants/types';
import {AxiosResponse} from 'axios';
import useUI from './useUI';

type iUseDashboard = {
  get: (silent?: boolean) => void;
  loading: boolean;
  dashboard: Dashboard;
}

const useDashboard = ():iUseDashboard => {
  const api = useApi();
  const dispatch = useDispatch();
  const { loader } = useUI();
  const dashboard = useSelector(dashboardSelector);

  const [ loading, onChangeLoading ] = useState<boolean>(false);

  const get = useCallback((silent?: boolean) => {
    if (!silent) {
      onChangeLoading(true);
      loader.start();
    }
    api.getDashboard()
      .then((response: AxiosResponse) => {
        dispatch(setGrantsDashboard(response.data));
        loader.stop();
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
        loader.stop();
      });
  }, [api, loader, dispatch]);

  return {
    dashboard,
    loading,
    get
  }
}

export default useDashboard;
