import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
// hooks
import useOtherExpenses from 'hooks/useOtherExpenses';
import useGrants from 'hooks/useGrants';
import useEnums from 'hooks/useEnums';
import {useParams} from 'react-router-dom';
// helpers
import {getOtherExpenseFromGrant} from 'helpers/getFromGrant';
// types
import {OtherExpenseType} from 'store/grants/otherDirectCosts/OtherExpenses/types';
// components
import {Button, Form, Input, Select} from 'components/form';
import Field from 'components/field';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// styles
import styles from '../../form.module.css';

type Props = {
  expense: OtherExpenseType;
  grantId: string;
}

const help = 'Inflation is normally applied to the majority of expenses. Some of the main times where inflation should not be applied is when the cost relates to some type of "incentive payment" or other type of compensation that might feel unfair if it is distributed at a larger amount in future years.'

const OtherExpenseForm: FC<Props> = ({expense, grantId}) => {
  const [data, setData] = useState<OtherExpenseType>(expense);
  const {
    updateExpense,
    trackError,
    trackExit,
    trackFormStarted
  } = useOtherExpenses({
    form_page_type: 'Expense info'
  })

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {yesNoOptions} = useEnums();

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      setData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const handleSubmit = () => {
    updateExpense(grantId, expense.id, {
      ...data,
      name: data.name,
      isInflationApplied: data.isInflationApplied,
    })
  }

  return (
    <>
      <Form prompt onSubmit={handleSubmit} trackError={trackError}
            trackExit={trackExit} trackFormStarted={trackFormStarted}>
        <Field last>
          <Input
            name="name"
            label="Expense name"
            placeholder="Enter Expense name"
            className={styles.input}
            value={data.name}
            required
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
            type="text"
          />
          <Select
            className={styles.fullInput}
            options={yesNoOptions}
            value={data.isInflationApplied}
            name="isInflationApplied"
            label="Is inflation applied to this expense each year?"
            placeholder="Select the item"
            bool
            help={help}
            required
            onChange={(value) => handleChangeData({isInflationApplied: value})}
          />
        </Field>
        <div className={styles.footer}>
          <Button
            htmlType="submit"
            name="submit-ms-general-info"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  )
}

const OtherExpensePage = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getOtherExpenseFromGrant(grant, params.expenseId);

  return <GrantFormWrapper title="Other Direct Costs" subtitle={`All other costs. Expense ${index > 0 ? index : ''}`}>
    {item ? (
      <OtherExpenseForm
        grantId={grant.id}
        key={`${grant.id}-publications-${params.unitId}`}
        expense={item}
      />
    ) : (
      <span/>
    )}
  </GrantFormWrapper>
}

export default OtherExpensePage;
