import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useGrants from 'hooks/useGrants';
import useCostSharing from 'hooks/useCostSharing';
import useEnums from 'hooks/useEnums';
// types
import {CostSharingExpenseItemType} from 'store/grants/costSharing/types';
// helpers
import {getCostSharingExpenseFromGrant} from 'helpers/getFromGrant';
// components
import Field from 'components/field';
import {Button, Form, Input, Select} from 'components/form';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// styles
import styles from '../costSharing.module.css';

type FormProps = {
  item: CostSharingExpenseItemType;
  grantId: string;
};

const inflationFieldHelpText = 'This should be specified or able to be deduced from your program solicitation. The next update to this program should have more information on specific details about specific program solicitations to further help you in unique cases such as this.';
const typeOfExpenseHelpText = 'All "Uncommitted Cost Sharing" does not have to be reported. Uncommitted cost sharing is your own personal contribution. They aren\'t required by contract; hence, they are uncommitted, which the NSF says not to report under this section.';

const CostSharingExpenseTypeOptions = [
  {value: 'voluntary', label: 'Voluntary'},
  {value: 'mandatory', label: 'Mandatory'},
]

const CostSharingExpenseForm: FC<FormProps> = ({item, grantId}) => {
  const [data, setData] = useState<CostSharingExpenseItemType>(item);
  const {
    isLoading, updateExpense, trackError,
    trackExit,
    trackFormStarted
  } = useCostSharing({
    form_page_type: 'Expense info'
  });

  const {yesNoOptions} = useEnums();

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      setData({
        ...data,
        ...field,
      });
    },
    [data],
  );

  const onSubmit = useCallback(() => {
    updateExpense(grantId, item.id, {
      ...item,
      ...data,
    })
  }, [data, grantId, item, updateExpense]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field last>
        <Input
          name="name"
          label="Name of expense"
          placeholder="Enter name"
          value={data.name}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
          type="text"
        />
        <Select
          options={CostSharingExpenseTypeOptions}
          value={data.type}
          name="type"
          label="Type of expense"
          placeholder="Select the item"
          bool
          help={typeOfExpenseHelpText}
          required
          onChange={(value) => handleChangeData({type: value})}
        />
      </Field>

      <Field last>
        <Select
          options={yesNoOptions}
          value={data.isInflationApplied}
          name="isInflationApplied"
          label="Is inflation applied to the expense?"
          placeholder="Select the item"
          bool
          help={inflationFieldHelpText}
          required
          onChange={(value) => handleChangeData({isInflationApplied: value})}
        />
      </Field>

      <div className={styles.footer}>
        <Button
          htmlType="submit"
          name="submit-costSharing-item"
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export const CostSharingItemPage = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getCostSharingExpenseFromGrant(grant, params.expenseId);

  return (
    <GrantFormWrapper title="Cost sharing" subtitle={item?.name || `Expense ${index > 0 ? index : ''}`}>
      {item ? (
        <CostSharingExpenseForm
          grantId={grant.id}
          key={`${grant.id}-costSharing-${params.expenseId}`}
          item={item}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
}

export default CostSharingItemPage;
