import {ChangeEvent, FC, useCallback, useState} from 'react';
// types
import {
  MaterialsSuppliesUnitType,
  MaterialsSuppliesUnitYearType
} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// hooks
import useMaterialsSupplies from 'hooks/useMaterialsSupplies';
// components
import {Input, Form, Button} from 'components/form';
import Field from 'components/field';
// styles
import styles from 'pages/grant/one/otherDirectCosts/form.module.css';
import useGrants from 'hooks/useGrants';
import {FieldErrors} from "react-hook-form";

type Props = {
  currentUnitYear: MaterialsSuppliesUnitYearType;
  currentUnit: MaterialsSuppliesUnitType
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const MaterialsSuppliesUnitYearForm: FC<Props> = ({ trackError,
                                                    trackExit,currentUnitYear, currentUnit}) => {
  const {grant} = useGrants();
  const {previewUnitYear, updateUnit, isLoading} = useMaterialsSupplies();
  const [data, onChangeData] = useState<MaterialsSuppliesUnitYearType>({
    compensationAfterInflation: currentUnitYear?.compensationAfterInflation ?? '',
    compensationBeforeInflation: currentUnitYear?.compensationBeforeInflation ?? '',
    numberOfUnits: currentUnitYear?.numberOfUnits ?? '',
  } as unknown as MaterialsSuppliesUnitYearType);

  const onBlur = useCallback(() => {
    if (currentUnit.id && data.numberOfUnits !== '') {
      previewUnitYear(grant.id, currentUnit.id, currentUnitYear.serialNumber, {
        numberOfUnits: data.numberOfUnits,
      }, (data: any) => {
        onChangeData({
          ...data,
          compensationAfterInflation: data.compensationAfterInflation,
          compensationBeforeInflation: data.compensationBeforeInflation,
        });
      })
    }
  }, [previewUnitYear, currentUnit.id, grant, data]);

  const handleChangeNumberOfUnits = (value: string) => {
    onChangeData((prev) => ({
      ...prev,
      numberOfUnits: value,
    }))
  }

  const handleSubmit = () => {
    const newYears = currentUnit.years.map((year: MaterialsSuppliesUnitYearType) => {
      if (year.serialNumber === currentUnitYear.serialNumber) {
        return {
          ...year,
          compensationBeforeInflation: data.compensationBeforeInflation,
          compensationAfterInflation: data.compensationAfterInflation,
          numberOfUnits: data.numberOfUnits
        }
      }
      return year
    })

    updateUnit({
        ...currentUnit,
        years: newYears
      },
      currentUnit.id,
      grant.id,)
  };

  return (
    <Form prompt onSubmit={handleSubmit} trackExit={trackExit} trackError={trackError}>
      <Field>
        <Input
          name="numberOfUnits"
          label="Number of units per year"
          placeholder="Enter number of units per year"
          className={styles.input}
          onBlur={onBlur}
          min={0}
          value={data.numberOfUnits}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeNumberOfUnits(event.target.value)}
          type="number"
        />
      </Field>
      <Field last>
        <Input
          name="compensationBeforeInflation"
          money
          label={`Total cost before inflation for Year ${currentUnitYear.serialNumber}`}
          placeholder="Total cost before inflation"
          className={styles.input}
          value={data.compensationBeforeInflation}
          readOnly
          type="number"
          disabled
        />
        <Input
          name="compensationAfterInflation"
          money
          label={`Total cost after inflation for Year ${currentUnitYear.serialNumber}`}
          help={currentUnitYear.serialNumber === 1 ? `Inflation is not applied in Year ${currentUnitYear.serialNumber} for any costs, but will be applied in subsequent years.` : undefined}
          placeholder="Total cost after inflation"
          className={styles.input}
          value={data.compensationAfterInflation}
          type="number"
          readOnly
          disabled
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={isLoading} name="submit-ms-general-info">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MaterialsSuppliesUnitYearForm;
