import {FC, createElement, useState, useMemo, useCallback, useEffect} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {capitalize} from 'lodash';
import useQuery, {queryToString} from 'helpers/query';
import useGrants from 'hooks/useGrants';
import {Select, Form, Button} from 'components/form';
import Field from 'components/field';
import {useParams, useLocation, useHistory} from 'react-router-dom';
import {getTravelFromGrant, getTransportationMethodFromTravel} from 'helpers/getFromGrant';
import forms from './forms';
import styles from './transportation.module.css';
import useEnums from 'hooks/useEnums';
import useTravel from 'hooks/useTravel';
import * as T from 'hooks/requestTypes';
import {TravelYear} from 'store/grants/types';

type Method = 'Car' | 'Taxi' | 'Plane' | 'Rideshare' | 'Subway' | 'Other' | 'Boat' | 'Charter' | 'Train';
const Transportation: FC = () => {
  const {grant} = useGrants();
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const params: Record<string, string> = useParams();
  const {
    addTransportationMethod, clearErrors, previewMethod, updateTransportationMethod, loading, errors, trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: 'Transportation methods'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {transportationMethod} = useEnums();
  const {index, trip} = getTravelFromGrant(grant, params.travelId);
  const transportation = trip ? getTransportationMethodFromTravel(trip, params.methodId) : undefined;

  const defaultMethodData = useMemo(() => {
    const defaultGrantYears = grant.years.map((year: string[], idx: number) => ({
      year: year,
      isEnabled: false,
      serialNumber: idx + 1,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }));

    const defaultTravelStatusYears = trip?.status.years.map((year: TravelYear) => ({
      year: year.year,
      isEnabled: year.isEnabled,
      serialNumber: year.serialNumber,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }));

    const defaultYears = trip && trip.status.years.length > 0 ? defaultTravelStatusYears : defaultGrantYears;

    return {
      nameExpense: transportation?.method?.nameExpense ?? '',
      typeOfExpense: transportation?.method?.typeOfExpense ?? (trip?.tripDefaultLocation || ''),
      startLocation: transportation?.method?.startLocation ?? '',
      totalAmountForTrip: transportation?.method?.totalAmountForTrip ?? '',
      endLocation: transportation?.method?.endLocation ?? '',
      compensationBeforeInflation: transportation?.method?.compensationBeforeInflation ?? '',
      years: transportation?.method?.years ?? defaultYears
    }
  }, [transportation, trip, grant])

  const [method, onChangeMethod] = useState<string | undefined>(transportation?.method?.method ? capitalize(transportation?.method?.method) : undefined);
  const [data, onChangeData] = useState(defaultMethodData);
  const defaultDetailsData = useMemo(() => ({
    airCarrier: transportation?.method?.details?.airCarrier ?? '',
    price: transportation?.method?.details?.price ?? '',
    isAirCarrierVerified: transportation?.method?.details?.isAirCarrierVerified,
    gasPrice: transportation?.method?.details?.gasPrice ?? '',
    compensationBeforeInflation: transportation?.method?.details?.compensationBeforeInflation ?? '',
    totalAmountForTickets: transportation?.method?.details?.totalAmountForTickets ?? '',
    miles: transportation?.method?.details?.miles ?? '',
    milesPerGallon: transportation?.method?.details?.milesPerGallon ?? '',
    numberOfPeopleWithTicket: transportation?.method?.details?.numberOfPeopleWithTicket ?? '',
    numberOfTrips: transportation?.method?.details?.numberOfTrips ?? '',
    ticketPrice: transportation?.method?.details?.ticketPrice ?? '',
    tickets: transportation?.method?.details?.tickets ?? '',
    companyName: transportation?.method?.details?.companyName ?? '',
    numberOfRides: transportation?.method?.details?.numberOfRides ?? '',
    cabCost: transportation?.method?.details?.cabCost ?? '',
    city: transportation?.method?.details?.city ?? '',
    priceExplanation: transportation?.method?.details?.priceExplanation ?? '',
    isRoundTrip: Boolean(transportation?.method?.details?.isRoundTrip),
    gallons: transportation?.method?.details?.gallons ?? '',
    amountOfCompensationBeforeInflation: transportation?.method?.details?.amountOfCompensationBeforeInflation ?? '',
    eligibility: transportation?.method?.details?.eligibility ?? [],
    travellers: transportation?.method?.details?.travellers ?? '',
    totalCost: transportation?.method?.details?.totalCost ?? '',
    totalPrice: transportation?.method?.details?.totalPrice ?? '',
    kind: transportation?.method?.details?.kind ?? null,
    numberOfUnits: transportation?.method?.details?.numberOfUnits ?? '',
    unit: transportation?.method?.details?.unit ?? '',
    estimatedNumberOfCharters: transportation?.method?.details?.estimatedNumberOfCharters ?? '',
    pricePerUnit: transportation?.method?.details?.pricePerUnit ?? '',
    charterCostByUnit: transportation?.method?.details?.charterCostByUnit ?? '',
    charterCost: transportation?.method?.details?.charterCost ?? '',
  }), [transportation]);

  const [details, onChangeDetails] = useState(defaultDetailsData);

  const onBlur = useCallback((event?: any, newData?: T.TransportationMethodData, newDetails?: T.TransportationMethodPlainDetails) => {
    if (trip?.id) {
      const correctData = newData ? newData : data;
      const submitData = {
        ...correctData,
        method: method?.toLowerCase(),
        years: correctData?.years?.map((item: T.TransportationMethodYear, index: number) => ({
          ...item,
          serialNumber: index + 1,
        })),
        details: newDetails || details
      };//@ts-ignore
      previewMethod(grant.id, trip.id, submitData, (response: T.TransportationMethodData) => {
        onChangeData({
          ...correctData,
          totalAmountForTrip: response.totalAmountForTrip,
          compensationBeforeInflation: response.compensationBeforeInflation,
          years: response.years,
        });
        onChangeDetails({
          ...(newDetails || details),
          compensationBeforeInflation: response.details.compensationBeforeInflation,
          gallons: response.details.gallons,
          amountOfCompensationBeforeInflation: response.details.amountOfCompensationBeforeInflation,
          charterCost: response.details.charterCost,
          totalPrice: response.details.totalPrice,
          totalAmountForTickets: response.details.totalAmountForTickets,
        })
      });
    }
  }, [details, data, method, trip, grant, previewMethod]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newData = {
      ...data,
      ...field
    };
    onChangeData(newData);
    if (preview) {
      onBlur(undefined, {
        ...newData,
        method: method?.toLowerCase(),//@ts-ignore
        years: newData?.years?.map((item: T.TransportationMethodYear, index: number) => ({
          ...item,
          serialNumber: index + 1,
        })),
        details
      });
    }
  }, [data, details, onBlur, method]);

  const handleChangeDetails = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newDetails = {
      ...details,
      ...field
    };
    onChangeDetails(newDetails);
    if (preview) {
      onBlur(undefined, undefined, newDetails);
    }
  }, [details, onBlur]);

  const eraseForm = useCallback(() => {
    onChangeDetails(defaultDetailsData);
    onChangeData(defaultMethodData);
    clearErrors();
    const newQuery = {
      ...query,
      method: ''
    }
    const path = location.pathname + '?' + queryToString(newQuery);
    history.replace(path);
    onChangeMethod(undefined);
  }, [clearErrors, history, location, query, defaultMethodData, defaultDetailsData]);

  const subtitle = useMemo(() => {
    if (method) {
      return <div className={styles.subtitle}>
        {`Transportation method. ${method === 'Charter' ? 'Rental charter' : method}`}
        {transportation?.method
          ? null
          : <Button size="xs" type="bordered" onClick={eraseForm} name="rollback">Change</Button>}
      </div>
    }
    return `Trip ${index}`;
  }, [method, eraseForm, transportation, index]);

  const onSubmit = useCallback(() => {
    if (trip?.id) {
      if (transportation?.method?.id) {
        updateTransportationMethod(grant.id, trip.id, transportation.method.id, {
          ...data,
          method: transportation.method.method, //@ts-ignore
          years: data.years.map((item: T.TransportationMethodYear, index: number) => ({
            ...item,
            serialNumber: index + 1,
          })),
          details
        })
        return;
      }
      addTransportationMethod(grant.id, trip.id, params.type, method?.toLowerCase() as string, {
        ...data,
        method: method?.toLowerCase(), //@ts-ignore
        years: data.years.map((item: T.TransportationMethodYear, index: number) => ({
          ...item,
          serialNumber: index + 1,
        })),
        details
      });
    }
  }, [
    addTransportationMethod, params, updateTransportationMethod,
    method, transportation, data,
    details, grant, trip
  ]);

  useEffect(() => {
    if (transportation?.method) {
      onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeMethod = useCallback((item: string) => {
    onChangeMethod(capitalize(item));
    const newQuery = {
      ...query,
      method: item
    }
    const path = location.pathname + '?' + queryToString(newQuery);
    history.replace(path);
  }, [location, history, query])

  const props = {
    data,
    key: transportation?.method?.id,
    onChange: handleChangeData,
    details,
    onBlur,
    onChangeDetails: handleChangeDetails,
    onSubmit,
    loading,
    isUpdate: Boolean(transportation?.method),
    errors
  }

  const form = forms[method as Method];

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      { //@ts-ignore
        method && form as FC ? createElement(form, props)
          :
          <Form trackExit={trackExit} trackError={trackError}>
            <Field last>
              <Select name="method" options={transportationMethod} label="Choose a transportation method for this trip"
                      value={method} onChange={handleChangeMethod}
              />
            </Field>
          </Form>
      }
    </GrantFormWrapper>
  )
}

const TransportationPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {trip} = getTravelFromGrant(grant, params.travelId);
  const transportation = trip ? getTransportationMethodFromTravel(trip, params.methodId) : undefined;
  return grant.id ?
    <Transportation key={`${grant.id}-transportation-${params.travelId}-${transportation?.method?.id}`}/> : null;
}

export default TransportationPage;
