import {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {User} from 'store/auth/types';
import Prompt from 'containers/prompt';
import ProfileForm from 'containers/forms/profile';
import {isEqual} from 'lodash';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import Typo from 'components/typo';
import FormField from 'components/field';
import {Button, Checkbox, Input} from 'components/form';
import styles from '../account.module.css';
import Head from 'containers/head';
import {useLocation} from 'react-router';
import Help from '../../../../components/help';
import {useMixPanel} from "../../../../hooks/useMixPanel";

export type FormData = {
  firstName: string;
  lastName: string;
  race: string | null;
  yearSalary?: number | string | null;
  yearlyMonthsSalaryCompensation?: number | string | null;
  oneMonthCompensationAmount?: number | string | null;
  gender: string | null;
  birthdate: null | Date;
  calculateAverageBudget: boolean;
  positionTitle: string;
  profile?: {
    doShowHints: boolean;
  }
}

type AvatarData = {
  url: string | null;
  key: string | null;
}

export type Field = Record<string, string | boolean | number | null | Date>;

const formDataAdapter = (user: User): FormData => {

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    race: user.race,
    calculateAverageBudget: user.calculateAverageBudget,
    yearSalary: user.yearSalary ? user.yearSalary : null,
    yearlyMonthsSalaryCompensation: user.yearlyMonthsSalaryCompensation ? user.yearlyMonthsSalaryCompensation : null,
    oneMonthCompensationAmount: user.oneMonthCompensationAmount ? user.oneMonthCompensationAmount : null,
    gender: user.gender,
    birthdate: user.birthdate ? new Date(user.birthdate) : null,
    positionTitle: user.positionTitle,
    profile: {
      doShowHints: user.profile.doShowHints
    }
  }
}

const Personal:FC = () => {
  const { user, errors, updateMe, loading } = useAuth();
  const {
    personalInformationEdited
  } = useMixPanel()
  const { prevUrl } = useUI();
  const location = useLocation();

  const defaultAvatarData = {
    url: user.profileImage.url,
    key: ''
  };
  const [avatar, onChangeAvatar] = useState<AvatarData>(defaultAvatarData);
  const hint = useRef<HTMLDivElement>();

  useEffect(() => {
    if (location.hash === '#hint' && hint.current) {
      hint.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [location, hint]);

  const defaultData = useMemo(() => (formDataAdapter(user)), [user]);

  const [data, onChangeData] = useState<FormData>(defaultData);

  const handleChangeData = useCallback((field: Field) => {
    personalInformationEdited()
    onChangeData({
      ...data,
      ...field
    });
  }, [data, personalInformationEdited]);

  const handleChangeProfileData = useCallback((field: Field) => {
    onChangeData({
      ...data, //@ts-ignore
      profile: {
        ...data.profile,
        ...field
      }
    });
  }, [data, onChangeData]);

  const updateFormData = useCallback((updData: User) => {
    onChangeData(formDataAdapter(updData));
  }, [onChangeData])

  const onSubmit = useCallback((data: any) => {
    updateMe(data, updateFormData);
  }, [updateMe, updateFormData]);

  const canIQuit = useMemo(() => {
    return !isEqual({
      ...data,
      yearlyMonthsSalaryCompensation: data.yearlyMonthsSalaryCompensation
        ? Number(data.yearlyMonthsSalaryCompensation)
        : null,
      oneMonthCompensationAmount: data.oneMonthCompensationAmount
        ? Number(data.oneMonthCompensationAmount)
        : null,
      yearSalary: data.yearSalary
        ? Number(data.yearSalary)
        : null
    }, defaultData);
  }, [data, defaultData]);

  return (
    <div className={styles.wrapper}>
      <Head title="Personal info"/>
      <div className={styles.header}>
        <Typo type="h5" className={styles.tabTitle} semi>Personal information</Typo>
      </div>
      <ProfileForm
        onSubmit={onSubmit}
        data={data}
        onChange={handleChangeData}
        avatar={avatar}
        onChangeAvatar={onChangeAvatar}
        user={user}
        last={false}
        errors={errors}
      >
        <>
          <FormField refProp={hint} id="hint" last full>
            <Checkbox name="navigation_hint" label="Show navigation hint"
                      hint="Select this option to receive a navigation hint after each form save"
                      value={data.profile?.doShowHints}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeProfileData({doShowHints: event.target.checked})}
            />
          </FormField>
          <div className={styles.header}>
            <Typo type="div" className={styles.tabTitle} semi>
              <>
                Budget Inflation Rate
                <Help>
                  <div className={styles.smallHelp}>
                    For most costs, NSF requires that inflation in the amount of 4% per year be applied after Year 1.
                    This will be automatically calculated for you when necessary.
                  </div>
                </Help>
              </>
            </Typo>
          </div>
          <Input
            name="inflationRate"
            value={4}
            className={styles.fullInput}
            disabled
            readOnly
            label="Inflation Rate (%)"
            placeholder="Inflation rate, %"
          />
          <div className={styles.goBackFooter}>
            <Link to={prevUrl}>
              <Button type="bordered" size="lg" name="back" preIcon="arrow-left">Go back</Button>
            </Link>
            <Button htmlType="submit" size="lg" loading={loading} name="back">Update</Button>
          </div>
        </>
      </ProfileForm>
      <Prompt when={canIQuit}/>
    </div>
  );
}

export default Personal;
