import {FullGrant, GrantsState} from './types';

export const updateSideWithOne = (state: GrantsState) => {
  return state
    .updateIn(['sides'], (sides: FullGrant[]) => {
      const onePresentInSides = sides.find((item: FullGrant) => item.id === state.one.id);
      if (onePresentInSides) {
        return sides.map((item: FullGrant) => {
          if (item.id === state.one.id) return state.one;
          return item;
        });
      }
      return [...sides, state.one];
  })
}
