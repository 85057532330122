import {FC, createElement, useMemo} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import useGrants from 'hooks/useGrants';
import TravelCostAlaskaAttendee from './alaska';
import TravelCost48Attendee from './48';
import { Kind } from 'const';
import {useParams} from 'react-router-dom';
import {FullGrant, Travel, TravelCostData} from 'store/grants/types';

export type Props = {
  grant: FullGrant;
  inter?: boolean;
  trip: Travel;
  cost: TravelCostData;
}

const TravelCostAttendeePage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const { index, trip } = getTravelFromGrant(grant, params.travelId);
  const { cost } = getCostFromTravel(trip as Travel, params.costId);
  const forms:Record<Kind, FC<Props>> = {
    other: TravelCost48Attendee,
    fourty8states: TravelCost48Attendee,
    alaskaHawaii: TravelCostAlaskaAttendee,
    international: TravelCostAlaskaAttendee,
  };

  const subtitle = useMemo(() => {
    if (cost?.kind === 'fourty8states') return `Trip ${index}. Travel Costs. 48 states. Trip attendees`;
    if (cost?.kind === 'alaskaHawaii') return `Trip ${index}. Travel Costs. Alaska, Hawaii. Trip attendees`;
    if (cost?.kind === 'other' && trip?.type === 'consultant') return `Trip ${index}. Travel Costs. Other. Totals`;
    if (cost?.kind === 'other') return `Trip ${index}. Travel Costs. Other. Trip attendees`;
    return `Trip ${index}. Travel Costs. International. Trip attendees`;
  }, [index, trip, cost]);

  return (
    <GrantFormWrapper title="Travel" key={`${grant.id}-${trip?.id}-${params.costId}`} subtitle={subtitle}>
      {trip && cost && forms[cost.kind as Kind] ? createElement(forms[cost.kind as Kind], {
        trip,
        inter: cost.kind === 'international',
        grant,
        cost
      }) : <span/>}
    </GrantFormWrapper>
  )
}


export default TravelCostAttendeePage;
