import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {isEmpty} from 'lodash';
import Tabs, {Tab} from 'components/tabs';
import styles from './compensation.module.css';
import {Input, Form, Button} from 'components/form';
import useGrants from 'hooks/useGrants';
import {UnGraduateStudentNSFCompensation} from 'store/grants/types';
import usePersonnel from 'hooks/usePersonnel';
import {Errors} from 'helpers/errors';
import * as T from 'hooks/requestTypes';
import cx from 'classnames';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {FieldErrors} from "react-hook-form";

type FormProps = {
  year: number;
  loading: boolean;
  owner: boolean;
  onSubmit: (type: 'academTerm' | 'summer') => void;
  errors: Errors;
  data: UnGraduateStudentNSFCompensation;
  onChange: (field: Record<string, any>) => void;
  onBlur: () => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const AcademicForm: FC<FormProps> = ({
                                       trackError,
                                       trackExit,
                                       trackFormStarted,
                                       year,
                                       owner,
                                       onSubmit,
                                       errors,
                                       loading,
                                       onBlur,
                                       data,
                                       onChange
                                     }) => {
  const handleSubmit = useCallback(() => {
    onSubmit('academTerm');
  }, [onSubmit]);

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={cx(styles.field, styles.firstField)}>
        <Input name="numberOfStudents"
               value={data.academicTerm.numberOfStudents} onBlur={onBlur}
               errors={errors['academicTerm.numberOfStudents']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfStudents: event.target.value})}
               className={styles.fullInput}
               help="If an undergraduate student will only be working for a semester, they would count for half an undergraduate student."
               type="number"
               label={`Number of Undergraduate Students needed for full Academic year`} required
               placeholder="Enter number of undergraduate students needed for academic year"/>
      </div>
      <div className={styles.field}>
        <Input name="numberOfHours"
               value={data.academicTerm.numberOfHours} onBlur={onBlur} errors={errors['academicTerm.numberOfHours']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfHours: event.target.value})}
               className={styles.fullInput} type="number"
               label={`Number of Average Hours/Week per Undergraduate Student`} required
               placeholder="Enter number of average hours/week"/>
      </div>
      <div className={styles.field}>
        <Input name="numberOfWeeks"
               value={data.academicTerm.numberOfWeeks} onBlur={onBlur} errors={errors['academicTerm.numberOfWeeks']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfWeeks: event.target.value})}
               className={styles.fullInput} type="number"
               label={`Average Number of Weeks Worked per Undergraduate Student per Term`} required
               placeholder="Enter average number of weeks"/>
      </div>

      <div className={styles.field}>
        <Input name="totalAmountBeforeInflation" disabled readOnly
               value={data.academicTerm.totalAmountBeforeInflation}
               className={styles.fullInput} money
               label={`Total Amount Requested for All Undergraduate Students for Year ${year} Before Inflation`}
               placeholder="Total amount requested before inflation"/>
      </div>

      <div className={styles.lastField}>
        <Input name="totalAmountAfterInflation" disabled readOnly
               value={data.academicTerm.totalAmountAfterInflation}
               className={styles.fullInput} money
               label={`Total Amount Requested for All Undergraduate Students for Year ${year} After Inflation`}
               help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
               placeholder="Total amount requested after inflation"/>
      </div>


      <div className={cx(styles.field, styles.firstField)}>
        <Input name="percentForAccountOwner" type="number"
               value={data.academicTerm.percentForAccountOwner} onBlur={onBlur}
               errors={errors['academicTerm.percentForAccountOwner']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({percentForAccountOwner: event.target.value})}
               className={styles.fullInput} disabled={!owner}
               help={`The field is available if the "Account owner is an undergraduate student" switch is enabled. Please indicate the percentage of compensation that should be allocated to the account owner. Based on this and the amount of compensation after inflation, we will be able to calculate the account owner's salary budget and display it on the dashboard.`}
               label={`What percentage of compensation should be allocated to account owner?`}
               placeholder="Enter a percentage of compensation should be allocated to account owner"/>
      </div>


      <div className={styles.lastField}>
        <Input
          name="totalAmountForAccountOwnerAfterInflation"
          disabled
          readOnly
          money
          className={styles.fullInput}
          value={owner ? data.academicTerm.totalAmountForAccountOwnerAfterInflation : ''}
          label={`Total Amount Requested for account owner for Year ${year} After Inflation`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          placeholder="Total Amount Requested for account owner "
        />
      </div>

      <div className={styles.formFooter}>
        <Button htmlType="submit" name="submit" loading={loading} size="xl">Save</Button>
      </div>
    </Form>
  );
};


const SummerForm: FC<FormProps> = ({
                                     trackError,
                                     trackExit,
                                     trackFormStarted,
                                     year,
                                     owner,
                                     onBlur,
                                     onChange,
                                     loading,
                                     data,
                                     errors,
                                     onSubmit
                                   }) => {
  const handleSubmit = useCallback(() => {
    onSubmit('summer');
  }, [onSubmit])

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={cx(styles.field, styles.firstField)}>
        <Input name="numberOfStudents" onBlur={onBlur}
               value={data.summer.numberOfStudents} errors={errors['summer.numberOfStudents']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfStudents: event.target.value})}
               className={styles.fullInput} type="number"
               help="If an undergraduate student will only be working for half a Summer, they would count for half an undergraduate student."
               label={`Number of Undergraduate Students Employed for the Summer`} required
               placeholder="Enter a number of undergraduate students employed for the summer"/>
      </div>
      <div className={styles.field}>
        <Input name="numberOfHours" onBlur={onBlur}
               value={data.summer.numberOfHours} errors={errors['summer.numberOfHours']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfHours: event.target.value})}
               className={styles.fullInput} type="number"
               label={`Number of Average Hours/Week per Undergraduate Student`} required
               placeholder="Enter number of average hours/week"/>
      </div>
      <div className={styles.field}>
        <Input name="numberOfWeeks" onBlur={onBlur}
               value={data.summer.numberOfWeeks} errors={errors['summer.numberOfWeeks']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfWeeks: event.target.value})}
               className={styles.fullInput} type="number"
               label={`Average Number of Weeks Worked per Undergraduate Student per Summer`} required
               placeholder="Enter average number of weeks per summer"/>
      </div>

      <div className={styles.field}>
        <Input name="totalAmountBeforeInflation" disabled readOnly money
               value={data.summer.totalAmountBeforeInflation} errors={errors['summer.totalAmountBeforeInflation']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({totalAmountBeforeInflation: event.target.value})}
               className={styles.fullInput}
               label={`Total Amount Requested for All Undergraduate Students for the Summer in Year ${year} Before Inflation`}
               placeholder="Total amount requested for the summer before inflation"/>
      </div>


      <div className={styles.lastField}>
        <Input name="totalAmountAfterInflation" disabled readOnly money
               value={data.summer.totalAmountAfterInflation} errors={errors['summer.totalAmountAfterInflation']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({totalAmountAfterInflation: event.target.value})}
               className={styles.fullInput} type="number"
               label={`Total Amount Requested for All Undergraduate Students for the Summer in Year ${year} After Inflation`}
               help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
               placeholder="Total amount requested for the summer  after inflation"/>
      </div>


      <div className={cx(styles.field, styles.firstField)}>
        <Input name="percentForAccountOwner" onBlur={onBlur}
               value={data.summer.percentForAccountOwner} errors={errors['summer.percentForAccountOwner']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({percentForAccountOwner: event.target.value})}
               className={styles.fullInput} disabled={!owner} type="number"
               help={`The field is available if the "Account owner is an undergraduate student" switch is enabled. Please indicate the percentage of compensation that should be allocated to the account owner. Based on this and the amount of compensation after inflation, we will be able to calculate the account owner's salary budget and display it on the dashboard.`}
               label={`What percentage of compensation should be allocated to account owner?`}
               placeholder="Enter a percentage of compensation should be allocated to account owner"/>
      </div>

      <div className={styles.lastField}>
        <Input
          name="totalAmountForAccountOwnerAfterInflation"
          disabled
          readOnly
          className={styles.fullInput}
          money
          value={owner ? data.summer.totalAmountForAccountOwnerAfterInflation : ''}
          label={`Total Amount Requested for account owner for Year ${year} After Inflation`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          placeholder="Total Amount Requested for account owner "
        />
      </div>

      <div className={styles.formFooter}>
        <Button htmlType="submit" loading={loading} name="submit" size="xl">Save</Button>
      </div>
    </Form>
  );
}

const UnGraduatedStudentsCompensation: FC = () => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants();
  const {
    loading,
    updateUnderGraduateNsfCompensation,
    onChangeErrors,
    errors,
    calculateUnderGraduatedStudentSummer,
    calculateUnderGraduatedStudentByAnnual,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({
    form_page_type: 'Position Info'
  });
  const compensation: UnGraduateStudentNSFCompensation = grant.undergraduateStudents.nsfCompensation[Number(params.year)] ?? {
    summer: {},
    academicTerm: {}
  };
  const [data, onChangeData] = useState<UnGraduateStudentNSFCompensation>({
    ...compensation,
    serialNumber: compensation.serialNumber || Number(params.year) + 1,
    summer: {
      ...compensation.summer,
      percentForAccountOwner: compensation.summer.percentForAccountOwner ? percentToFixed(compensation.summer.percentForAccountOwner) : undefined,
    },
    academicTerm: {
      ...compensation.academicTerm,
      percentForAccountOwner: compensation.academicTerm.percentForAccountOwner ? percentToFixed(compensation.academicTerm.percentForAccountOwner) : undefined,
    }
  });

  const handleChangeSummer = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      summer: {
        ...data.summer,
        ...field
      }
    });
  }, [data]);

  const handleChangeAcademicTerm = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      academicTerm: {
        ...data.academicTerm,
        ...field
      }
    });
  }, [data]);

  const validate = useCallback((type: 'academTerm' | 'summer'): Errors => {
    let errors: Errors = {};
    if (type === 'academTerm') {
      if (grant.undergraduateStudents.loadLimitDuringAcademicTerm && Number(data.academicTerm.numberOfHours) > Number(grant.undergraduateStudents.loadLimitDuringAcademicTerm)) {
        errors = {
          ...errors,
          'academicTerm.numberOfHours': ['The value entered exceeds the maximum number of hours that students can work during academic terms in the Undergraduate Students tab']
        }
      }
      if (Number(grant.undergraduateStudents.numberOfWeekAcademicTerms) < Number(data.academicTerm.numberOfWeeks)) {
        errors = {
          ...errors,
          'academicTerm.numberOfWeeks': ['The value entered exceeds the number of weeks/academic terms in the Undergraduate Students tab']
        }
      }
    } else {
      if (grant.undergraduateStudents.loadLimitDuringSummer && Number(data.summer.numberOfHours) > Number(grant.undergraduateStudents.loadLimitDuringSummer)) {
        errors = {
          ...errors,
          'summer.numberOfHours': ['The value entered exceeds the maximum number of hours that students can work during summer in the Undergraduate Students tab']
        }
      }
      if (Number(data.summer.numberOfWeeks) > 12) {
        errors = {
          ...errors,
          'summer.numberOfWeeks': ['There are 12 weeks in a three-month summer. The value entered exceeds 12']
        }
      }
    }
    return errors;
  }, [data, grant])

  const onBlurAcademicTerm = useCallback(() => {
    const errors = validate('academTerm');
    onChangeErrors(errors);
    if (!isEmpty(errors)) return;
    if (Number(data.academicTerm.numberOfStudents) < 1) return;
    calculateUnderGraduatedStudentByAnnual(grant.id, {
      year: compensation.year,
      serialNumber: compensation.serialNumber || Number(params.year) + 1,
      numberOfStudents: data.academicTerm.numberOfStudents,
      numberOfHours: data.academicTerm.numberOfHours,
      numberOfWeeks: data.academicTerm.numberOfWeeks,
      percentForAccountOwner: data.academicTerm.percentForAccountOwner ? Number(data.academicTerm.percentForAccountOwner) / 100 : undefined,
    }, (data: T.UnderGraduatedStudentDataForCalcAcademTermResponse) => {
      handleChangeAcademicTerm({
        numberOfHours: data.numberOfHours,
        numberOfStudents: data.numberOfStudents,
        numberOfWeeks: data.numberOfWeeks,
        totalAmountBeforeInflation: data.totalAmountBeforeInflation,
        totalAmountAfterInflation: data.totalAmountAfterInflation,
        totalAmountForAccountOwnerAfterInflation: data.totalAmountForAccountOwnerAfterInflation,
      })
    })
  }, [handleChangeAcademicTerm, params, onChangeErrors, validate, calculateUnderGraduatedStudentByAnnual, compensation, data, grant]);


  const onBlurSummer = useCallback(() => {
    let errors = validate('summer');
    onChangeErrors(errors);
    if (!isEmpty(errors)) return;
    if (Number(data.summer.numberOfStudents) < 1) return;
    calculateUnderGraduatedStudentSummer(grant.id, {
      year: compensation.year,
      numberOfStudents: data.summer.numberOfStudents,
      serialNumber: compensation.serialNumber || Number(params.year) + 1,
      numberOfHours: data.summer.numberOfHours,
      numberOfWeeks: data.summer.numberOfWeeks,
      percentForAccountOwner: data.summer.percentForAccountOwner ? Number(data.summer.percentForAccountOwner) / 100 : undefined,
    }, (data: T.UnderGraduatedStudentDataForCalcSummerResponse) => {
      handleChangeSummer({
        numberOfHours: data.numberOfHours,
        numberOfStudents: data.numberOfStudents,
        numberOfWeeks: data.numberOfWeeks,
        percentForAccountOwner: data.percentForAccountOwner ? percentToFixed(data.percentForAccountOwner) : undefined,
        totalAmountBeforeInflation: data.totalAmountBeforeInflation,
        totalAmountAfterInflation: data.totalAmountAfterInflation,
        totalAmountForAccountOwnerAfterInflation: data.totalAmountForAccountOwnerAfterInflation,
      })
    })
  }, [handleChangeSummer, validate, onChangeErrors, calculateUnderGraduatedStudentSummer, params, compensation, data, grant]);

  const onSubmit = useCallback((type: 'academTerm' | 'summer') => {
    const errors = validate(type);
    onChangeErrors(errors);
    if (!isEmpty(errors)) return;
    updateUnderGraduateNsfCompensation(grant.id, {
      ...data,
      serialNumber: compensation.serialNumber || Number(params.year) + 1,
      summer: {
        ...data.summer,
        percentForAccountOwner: data.summer.percentForAccountOwner ? Number(data.summer.percentForAccountOwner) / 100 : undefined
      },
      academicTerm: {
        ...data.academicTerm,
        percentForAccountOwner: data.academicTerm.percentForAccountOwner ? Number(data.academicTerm.percentForAccountOwner) / 100 : undefined
      }
    }, grant.undergraduateStudents.isAccountOwner, type);

  }, [ data, compensation, params, grant, onChangeErrors, validate, updateUnderGraduateNsfCompensation]);

  const tabs: Tab[] = [
    {
      label: 'Academic year',
      id: 'academic',
      element: <AcademicForm owner={grant.undergraduateStudents.isAccountOwner} onSubmit={onSubmit} loading={loading}
                             errors={errors} onBlur={onBlurAcademicTerm} data={data} onChange={handleChangeAcademicTerm}
                             year={Number(params.year) + 1}
                             trackError={trackError}
                             trackExit={trackExit}
                             trackFormStarted={trackFormStarted}
      />
    },
    {
      label: 'Summer',
      id: 'summer',
      element: <SummerForm owner={grant.undergraduateStudents.isAccountOwner} onSubmit={onSubmit} loading={loading}
                           errors={errors} onBlur={onBlurSummer} data={data} onChange={handleChangeSummer}
                           year={Number(params.year) + 1}
                           trackError={trackError}
                           trackExit={trackExit}
                           trackFormStarted={trackFormStarted}
      />
    },
  ];

  useEffect(() => {
    if (grant.id) onBlurAcademicTerm();
  }, []);

  const onEnterTab = useCallback((id: string | number) => {
    if (id === 'summer') onBlurSummer();
    if (id === 'academic') onBlurAcademicTerm();
  }, [onBlurAcademicTerm, onBlurSummer])

  return (
    <GrantFormWrapper
      title={'Undergraduate Students'}
      subtitle={`Year ${Number(params.year) + 1}`}
    >
      <Tabs fixed onEnter={onEnterTab} smallPadding name="compensationType" list={tabs}/>
    </GrantFormWrapper>
  )
}

export default UnGraduatedStudentsCompensation;
