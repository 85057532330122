import React, {FC, ReactElement, useCallback} from 'react';
import cx from 'classnames';
import { isArray } from 'lodash';
import ReactModal from 'react-modal';
import Icon from 'components/icon';
import close from './close.svg';
import closeWhite from './close_white.svg';
import styles from './modal.module.css';

export interface Props {
  visible: boolean;
  children: ReactElement;
  titleCenter?: boolean;
  confirmation?: boolean;
  wide?: boolean;
  fb?: boolean;
  icon?: string;
  iconType?: 'success' | 'error' | 'warning' | 'info';
  onCancel?: () => void;
  subtitle?: string | string[];
  title?: string;
  iconClassName?: string;
  small?: boolean;
  className?: string;
}

ReactModal.setAppElement('#bm-modal');

const Modal:FC<Props> = ({ visible, iconClassName, fb, wide, small, subtitle, confirmation, titleCenter, iconType = 'info', icon, children, onCancel, className, title}) => {

  const handleCloseModal = useCallback(() => {
    if (onCancel) {
      onCancel();
      document.body.classList.remove('ReactModal__Body--open');
    }
  }, [onCancel]);

  return (
    <ReactModal
      isOpen={visible} shouldCloseOnEsc onRequestClose={handleCloseModal} //@ts-ignore
      shouldCloseOnOverlayClick overlayClassName={cx(styles.overlay, 'bm-modal-overlay')}
      className={cx(styles.content, {[styles.confirmation]: confirmation, [styles.fb]: fb, [styles.wide]: wide, [styles.small]: small}, className)} closeTimeoutMS={200}
    >
      {onCancel
        ? <button type="button" className={cx(styles.cancelIcon, {[styles.withoutTitle]: !title})} onClick={handleCloseModal}>
          <img src={close} alt="close" draggable={false}/>
        </button>
        : null
      }
      {icon
        ?
        <div className={cx(styles.iconWrapper, styles[iconType])}>
          <Icon icon={icon} className={cx(styles.icon, iconClassName)} size={20} />
        </div>
        : null
      }
      {title ? <h1 className={cx(styles.title, {[styles.center]: titleCenter, [styles.onlyTitle]: !subtitle})}>{title}</h1> : null}
      {subtitle
        ?
          <p className={cx(styles.subtitle, {[styles.center]: titleCenter})}>
            {isArray(subtitle)
              ? subtitle.map((text: string, idx: number) => <div key={idx} className={styles.subtitleField}>{text}</div>)
              : subtitle
            }
          </p>
        : null}
      <div className={styles.contentWrapper}>
        {children}
      </div>
    </ReactModal>
  );
}


export interface VideoModalProps {
  visible: boolean;
  onCancel?: () => void;
  video: string;
}

export const VideoModal:FC<VideoModalProps> = ({ visible, video, onCancel }) => {
  return (
    <ReactModal
      isOpen={visible} shouldCloseOnEsc onRequestClose={onCancel}
      shouldCloseOnOverlayClick overlayClassName={styles.overlay}
      className={cx(styles.content, styles.videoContent)} closeTimeoutMS={200}
    >
      {onCancel
        ? <button type="button" className={cx(styles.cancelIcon, styles.cancelVideoBtn)} onClick={onCancel}>
          <img src={closeWhite} alt="close" draggable={false}/>
        </button>
        : null
      }
      <video src={video} controls muted={false} preload="auto" autoPlay={true} color="#30D5C8" className={styles.video} />
    </ReactModal>
  );
}

export default Modal;
