import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import Tabs, {Tab} from 'components/tabs';
import {isNumber, omit, isEqual} from 'lodash';
import Typo from 'components/typo';
import styles from './breakdown.module.css';
import {MaIEBreakdown, MaIEBreakdownMonth, TravelCostData} from 'store/grants/types';
import DayForm from './day';
import FullDays from './full';
import useTravel from 'hooks/useTravel';
import TotalsForm from './totals';


type Props = {
  cost: TravelCostData;
  travelId: string;
  grantId: string;
}

const MiEBreakdown: FC<Props> = ({cost, grantId, travelId}) => {
  const {
    updateTravelCostMaIE, previewTravelCost, errors, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = useTravel({
    form_page_type: "M&IE Breakdown"
  });

  const defaultMonths = [{
    total: '',
    index: 0,
    month: '',
    dailyRate: '',
    numberOfDays: '',
  }];

  const defaultData = {
    ...cost.mAndIeBreakdown,
    fullDays: {
      ...cost.mAndIeBreakdown.firstDay,
      months: cost.mAndIeBreakdown.fullDays.months.length > 0
        ? cost.mAndIeBreakdown.fullDays.months
        : defaultMonths
    }
  };

  const [data, onChangeData] = useState<MaIEBreakdown>(defaultData);

  const onAddMonth = useCallback(() => {
    const [tail] = [...data.fullDays.months].reverse();
    onChangeData({
      ...data,
      fullDays: {
        ...data.fullDays,
        months: [...data.fullDays.months, {
          index: tail.index as number + 1,
          total: '',
          month: '',
          dailyRate: '',
          numberOfDays: '',
        }]
      }
    })
  }, [data])

  const onDeleteMonth = useCallback((idx: number) => {
    onChangeData({
      ...data,
      fullDays: {
        ...data.fullDays,
        months: data.fullDays.months.filter((item: MaIEBreakdownMonth) => item.index !== idx)
      }
    })
  }, [data]);

  const canBlur = useCallback((data: MaIEBreakdown, tab?: 'firstDay' | 'fullDays' | 'lastDay' | 'totals') => {
    if (tab === 'firstDay' || tab === 'lastDay') {
      return data[tab].dailyRate > 0;
    }
    if (tab === 'fullDays') {
      const validMonthCount = data.fullDays.months.reduce((result: number, item: MaIEBreakdownMonth) => {
        const isValid = isNumber(item.month) && item.numberOfDays > 0 && item.dailyRate > 0;
        const value = isValid ? 1 : 0;
        return result + value;
      }, 0);
      return data.fullDays.months.length <= validMonthCount;
    }
    const canForgetAboutMonth = isEqual(defaultMonths, data.fullDays.months);
    return canForgetAboutMonth || (data.doBudgetMaxAmount ? data.maxTotalAdjust > 0 : true);
  }, [defaultMonths])

  const onBlur = useCallback((tab?: 'firstDay' | 'fullDays' | 'lastDay') => (event?: ChangeEvent<HTMLInputElement>, value?: MaIEBreakdown) => {
    const submitData = value ?? data;
    const canMakeRequest = canBlur(submitData, tab);
    if (!canMakeRequest) return;
    if (grantId && cost) {
      let value;
      if (tab) {//@ts-ignore
        value = {
          [tab]: submitData[tab]
        };
      } else {
        value = isEqual(defaultMonths, data.fullDays.months) ? omit(submitData, 'fullDays') : submitData;
        value = {
          ...value,
          maxTotalAdjust: value.maxTotalAdjust === '' ? undefined : value.maxTotalAdjust
        }
      }
      previewTravelCost(grantId, travelId, cost.id, {
        mAndIeBreakdown: value
      }, (response: any) => {
        onChangeData({
          ...submitData,
          ...(tab ? {[tab]: response.mAndIeBreakdown[tab]} : {
            doBudgetMaxAmount: response.mAndIeBreakdown?.doBudgetMaxAmount,
            total: response.mAndIeBreakdown?.total,
            maxTotalAdjust: response.mAndIeBreakdown?.maxTotalAdjust,
          })
        });
      });
    }
  }, [defaultMonths, previewTravelCost, canBlur, data, cost, grantId, travelId]);

  const onEnter = useCallback((tab: 'firstDay' | 'fullDays' | 'lastDay' | 'totals') => {
    const canMakeRequest = canBlur(defaultData, tab);
    onChangeData(defaultData);
    if (!canMakeRequest) return;
    if (grantId && cost) {
      let value;
      if (tab !== 'totals') {//@ts-ignore
        value = {
          [tab]: defaultData[tab]
        };
      } else {
        value = isEqual(defaultMonths, defaultData.fullDays.months) ? omit(defaultData, 'fullDays') : defaultData;
        value = {
          ...value,
          maxTotalAdjust: value.maxTotalAdjust === '' ? undefined : value.maxTotalAdjust
        }
      }
      previewTravelCost(grantId, travelId, cost.id, {
        mAndIeBreakdown: value
      }, (response: any) => {
        onChangeData({
          ...defaultData,
          ...(tab !== 'totals' ? {[tab]: response.mAndIeBreakdown[tab]} : {
            doBudgetMaxAmount: response.mAndIeBreakdown?.doBudgetMaxAmount,
            total: response.mAndIeBreakdown?.total,
            maxTotalAdjust: response.mAndIeBreakdown?.maxTotalAdjust,
          })
        });
      });
    }
  }, [previewTravelCost, defaultData, defaultMonths, canBlur, cost, grantId, travelId]);

  const handleChangeDay = useCallback((day: 'firstDay' | 'fullDays' | 'lastDay') => {
    return (field: Record<string, any>, preview?: boolean) => {
      onChangeData({
        ...data,
        [day]: {
          ...data[day],
          ...field
        }
      });
      if (preview) {
        const blur = onBlur(day);
        blur(undefined, {
          ...data,
          [day]: {
            ...data[day],
            ...field
          }
        })
      }
    }
  }, [onBlur, data]);

  const handleChange = useCallback((field: Record<string, any>, preview?: boolean) => {
    onChangeData({
      ...data,
      ...field
    });
    if (preview) {
      const blur = onBlur();
      blur(undefined, {
        ...data,
        ...field
      })
    }
  }, [onBlur, data])

  const onSubmit = useCallback((tab: 'firstDay' | 'fullDays' | 'lastDay' | 'totals') => {
    let value = {
      doBudgetMaxAmount: Boolean(data.doBudgetMaxAmount),
      total: data.total,
      maxTotalAdjust: data.maxTotalAdjust,
    }
    if (tab !== 'totals') {//@ts-ignore
      value = {
        [tab]: data[tab]
      };
    }
    updateTravelCostMaIE(grantId, travelId, cost.id, {
      mAndIeBreakdown: value
    }, tab);
  }, [cost, travelId, updateTravelCostMaIE, grantId, data]);

  const disabledTotalTab = useMemo(() => {
    return Boolean(defaultData.firstDay.total <= 0 && defaultData.lastDay.total <= 0);
  }, [defaultData]);

  const tabs: Tab[] = [
    {
      label: 'First day*',
      element: <DayForm field="firstDay" errors={errors} loading={loading} onBlur={onBlur('firstDay')}
                        onSubmit={() => onSubmit('firstDay')} data={data.firstDay}
                        onChange={handleChangeDay('firstDay')}
                        trackError={trackError}
                        trackExit={trackExit}
                        trackFormStarted={trackFormStarted}
      />,
      id: 'firstDay'
    },
    {
      label: 'Full day(s)',
      element: <FullDays onDelete={onDeleteMonth} add={onAddMonth} errors={errors} loading={loading}
                         onBlur={onBlur('fullDays')} onSubmit={() => onSubmit('fullDays')} data={data.fullDays}
                         onChange={handleChangeDay('fullDays')}
                         trackError={trackError}
                         trackExit={trackExit}
                         trackFormStarted={trackFormStarted}
      />,
      id: 'fullDays'
    },
    {
      label: 'Last day*',
      element: <DayForm field="lastDay" errors={errors} loading={loading} onBlur={onBlur('lastDay')}
                        onSubmit={() => onSubmit('lastDay')} data={data.lastDay}
                        onChange={handleChangeDay('lastDay')}
                        trackError={trackError}
                        trackExit={trackExit}
                        trackFormStarted={trackFormStarted}
      />,
      id: 'lastDay'
    },
    {
      label: 'Totals',
      element: <TotalsForm errors={errors} loading={loading} onBlur={onBlur()} onSubmit={() => onSubmit('totals')}
                           data={data} onChange={handleChange}
                           trackError={trackError}
                           trackExit={trackExit}
                           trackFormStarted={trackFormStarted}
      />,
      id: 'totals',
      disabled: disabledTotalTab
    },
  ];

  return (
    <div>
      <Typo className={styles.text}>If you choose to add a breakdown of M&IE per person, keep in mind that the "First
        Day" and "Last Day" tabs
        must be completed.You can skip the "Full Day" tab if your trip does not include one.</Typo>
      <Tabs onEnter={(id: any) => onEnter(id)} smallPadding fixed full list={tabs} name="mie"/>
    </div>
  );
}

export default MiEBreakdown;
