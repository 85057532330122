import React, {ChangeEvent, FC, useCallback} from 'react';
import Field from 'components/field';
import { Button, Form, Checkbox, DatePicker, Input } from 'components/form';
import Typo from 'components/typo';
import styles from './forms.module.css';
import {Location} from './common';
import { Props } from './index';

const InternationalForm:FC<Props> = ({ data, travel, onSubmit, errors, isUpdate, loading, onChange }) => {
  const handleChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    let newData = {
      startDate: null,
      endDate: null,
      numberOfSeasons: '',
    }
    if (event.target.checked) {
      newData = {//@ts-ignore
        startDate: travel.estimatedStartDate ? new Date(travel.estimatedStartDate) : null,//@ts-ignore
        endDate: travel.estimatedEndDate ? new Date(travel.estimatedEndDate) : null,//@ts-ignore
        numberOfSeasons: data.numberOfSeasons ? data.numberOfSeasons : ''
      }
    }
    onChange({
      isStartEndDateKnown: event.target.checked,
      ...newData
    });
  }, [travel, data, onChange]);


  return (
    <Form prompt onSubmit={onSubmit}>
      <Typo className={styles.text} type="div" >
        Please go to the website below to find a per diem rates or amounts that would apply to this trip. Please note,
        if this trip involves a major switch in location, please use another separate per diem rate to account for the
        location change. This is important because the change in location might alter the location rates that are
        allowed to be used.
      </Typo>
      <Typo className={styles.text} type="div" >
        <>Rates can be found here: <a href="https://aoprals.state.gov/web920/per_diem.asp" target="_blank"
                                      rel="noreferrer">https://aoprals.state.gov/web920/per_diem.asp</a></>
      </Typo>
      <Typo className={styles.text} type="div" >On the U.S. Department of State website, you should see an area
        titled "Office of Allowances". There are two ways to search for the location that you will be traveling to. The
        first method is to search by country. The second method is to enter the city you are planning to travel to into
        the search box titled "Post". Please note that if you decide to use the search box titled post, you will have to
        provide the exact spelling of the city you are looking for. Searching by country will will show a list of all
        cities in the country you will be traveling to and the rates associated with each of them. It is often times
        easier than using the post box because searching by country will show an "other" rate for that country should
        your exact location of your travel not have an available rate.</Typo>
      <Typo className={styles.text} type="div" >If you decided to search by country, once you have pressed go,
        a table of all of the travel rates for all available cities in the respective country should be shown. Rates
        will be shown in the same format if you decided to search by Post however you may not be shown an "Other" rate
        for the country which is needed should your exact city of travel not have a specific rate. You should see a list
        of all of the cities in the country that you searched for under the column titled "Post Name" along with the
        Maximum Per Diem Rates associated with the city you are searching for.</Typo>
      <Typo className={styles.text} type="div" >Please locate the row of your city. If you have two rows titled
        with the exact same city, please look at the two columns directly to the right of the name of your city. You
        should see a date range. This date range should correspond with columns titled Season Beginning and Season End.
        In certain cases, rates in locations will differ based on the time of year that you plan to travel to your
        location. Please use the row of your City that best reflects what time of year you plan to travel to your
        location. Dont worry, the year of your travel will end up taking place will be accounted for once all rates have
        been entered at the end of this section.</Typo>

      <Field full>
        <Input name="nameOfTrip" errors={errors.nameOfTrip} value={data.nameOfTrip}
               label="Name of travel" required placeholder="Enter a name of travel"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({nameOfTrip: event.target.value})}
        />
      </Field>

      <Location hideText inter optional data={data} errors={errors} onChange={onChange} />

      <Field last>
        <Checkbox name="isStartEndDateKnown" label="I know the trip start and end dates"
                  value={data.isStartEndDateKnown} onChange={handleChangeCheckbox}
        />
      </Field>
      <Field withHelp>
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} name="startDate"
                    errors={errors.startDate} label="Start date" required={data.isStartEndDateKnown}
                    placeholder="Select a start date" value={data.startDate}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab"
                    onChange={(date: Date | null) => onChange({startDate: date})}
        />
        <DatePicker className={styles.input} disabled={!data.isStartEndDateKnown} minDate={data.startDate || undefined} name="endDate"
                    errors={errors.endDate} label="End date" required={data.isStartEndDateKnown}
                    placeholder="Select an end date" value={data.endDate}
                    help="The start and end dates were automatically populated based on the start and end dates of this trip. You can still change them, and it won't affect what you have selected in the Trip tab"
                    onChange={(date: Date | null) => onChange({endDate: date})}
        />
        <Input type="number" name="numberOfSeasons" label="Number of seasons" required={data.isStartEndDateKnown}
               placeholder="Enter number of seasons" className={styles.input}
               help={`The word "Seasons" here refers to when you look up a travel rate at the link given. They may provide two rates to represent what time of the year you will be visiting an area. If your travel dates extend from one season to another, by entering the second rate amounts separately by the reimbursement that they qualify for and sum both together to get a total amount that includes both rates for this trip.`}
               value={data.numberOfSeasons} errors={errors.numberOfSeasons} disabled={!data.isStartEndDateKnown}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfSeasons: event.target.value})}
        />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add'} travel costs`}
        </Button>
      </div>
    </Form>
  );
}

export default InternationalForm;

