import {FC, useEffect, useMemo} from 'react';
import styles from './totals.module.css';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useTravel, {Total} from 'hooks/useTravel';
import useGrants from 'hooks/useGrants';
import {sortBy} from 'lodash';
import {ListItem} from 'helpers/utils';
import Table, {Cell} from 'components/table';
import {numberToMoney} from 'helpers/numbers';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {Travel} from 'store/grants/types';
import OtherTotal from './other';

const prepareHeaders = (items: Total[] = []) => {
  const [yearsItem] = sortBy(items, (item: Total) => item.years.length).reverse();
  const years = yearsItem?.years.map((item: ListItem) => item.label);
  return [
    'Season',
    ...(years ? years.map((_item: string, idx: number) => `Season ${idx + 1}` ) : []),
    'Total'
  ];
}

const prepareData = (items: Total[] = []):Array<Cell[]> => {
  return items.map((item: Total) => {
    const [yearsItem] = sortBy(items, (item: Total) => item.years.length).reverse();
    const keys = yearsItem.years.map((yearItem: ListItem) => yearItem.label)
    const yearsValues = keys.map((key: string) => {
      const currentYear:ListItem | undefined = item.years.find((item: ListItem) => item.label === key);
      return <span className={styles.gray}>{numberToMoney(currentYear?.value)}</span>
    })
    return [
      item.fullName,
      ...(yearsValues ?? []),
      numberToMoney(item.total || item.totals)
    ]
  });
}


type TotalsWithTitle = {
  title: string;
  headers: string[];
  data: Array<Cell[]>;
}

type Props = {
  travel: Travel;
}

const CostTotals:FC<Props> = ({ travel }) => {
  const { getTravelCost, totals, loading } = useTravel();
  const { sidebarWidth } = useUI();
  const params:Record<string, string> = useParams();
  const { cost } = getCostFromTravel(travel, params.costId);

  useEffect(() => {
    getTravelCost(params.id, params.travelId, params.costId);
  }, [params.costId]);

  const lodgingBreakdown:TotalsWithTitle = useMemo(() => {
    return {
      title: 'Lodging breakdown per person',
      headers: prepareHeaders(totals.lodgingBreakdown),
      data: prepareData(totals.lodgingBreakdown),
    }
  }, [totals]);

  const mealsExpenses:TotalsWithTitle = useMemo(() => {
    return {
      title: 'Meals Expenses Breakdown per person',
      headers: prepareHeaders(totals.mealsExpenses),
      data: prepareData(totals.mealsExpenses),
    }
  }, [totals]);

  const ieBreakdown:TotalsWithTitle = useMemo(() => {
    return {
      title: 'Incidental Expenses (IE) Breakdown per person',
      headers: prepareHeaders(totals.ieBreakdown),
      data: prepareData(totals.ieBreakdown),
    }
  }, [totals]);

  const mAndIeBreakdown:TotalsWithTitle = useMemo(() => {
    return {
      title: 'M&IE breakdown per person',
      headers: prepareHeaders(totals.mAndIeBreakdown),
      data: prepareData(totals.mAndIeBreakdown),
    }
  }, [totals]);

  const subtitle = useMemo(() => {
    if (cost && cost.kind === 'alaskaHawaii') {
      return `Alaska, Hawaii. Totals per seasons`;
    }
    return `International. Totals`;
  }, [cost]);

  const list = useMemo(() => {
    if (cost?.kind === 'international') {
      return [
        lodgingBreakdown,
        mAndIeBreakdown
      ];
    }
    return [
      lodgingBreakdown,
      mealsExpenses,
      ieBreakdown
    ];
  }, [cost, mAndIeBreakdown, lodgingBreakdown, ieBreakdown, mealsExpenses]);

  return (
    <>
      <GrantFormWrapper title="Travel costs" subtitle={subtitle}>
        <div className={styles.tables}>
          {list.map((table: TotalsWithTitle, idx: number) => (
            <Table title={table.title} key={idx} className={styles.table} data={table.data} headers={table.headers} />
          ))}
        </div>
      </GrantFormWrapper>
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  );
}

const CostTotalsPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const { trip } = getTravelFromGrant(grant, params.travelId);
  const costFromTravel = trip ? getCostFromTravel(trip, params.costId) : undefined;
  const key = `${grant.id}-${params.travelId}-${params.costId ?? 'cost'}`;
  return (
    trip ? (
      costFromTravel?.cost?.kind === 'other'
        ? <OtherTotal cost={costFromTravel.cost} travel={trip} key={key} />
        : <CostTotals travel={trip} key={key} />
    ) : null
  )
}

export default CostTotalsPage;
