import { FC, useMemo } from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import Typo from 'components/typo';
import checkPassword from 'helpers/password';
import styles from './passwordCheck.module.css';

type Props = {
  password: string;
  mobile?: boolean;
}

type Check = {
  label: string;
  pass: boolean;
}

const PasswordCheck:FC<Props> = ({ mobile, password }) => {
  const checks:Check[] = useMemo(() => {
    const passes = checkPassword(password)
    return [
      {
        label: '8-28 characters',
        pass: passes.length
      },
      {
        label: '1 or more capital letters',
        pass: passes.capital
      },
      {
        label: '1 special character',
        pass: passes.special
      },
      {
        label: '1 digit',
        pass: passes.digit
      },
    ];
  }, [password]);

  return (
    <div className={cx(styles.wrapper, {[styles.mobile]: mobile})}>
      <Typo className={styles.title}>Password must contain the following:</Typo>
      <div className={styles.checks}>
        {checks.map((item: Check, idx: number) => (
          <div key={idx} className={cx(styles.check, {[styles.pass]: item.pass})}>
            {item.pass ? <Icon className={styles.icon} icon="check" size={12}/> : null}
            <Typo className={styles.checkText}>{item.label}</Typo>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PasswordCheck;
