import { FC, useState, useCallback, ChangeEvent } from 'react';
import Field from 'components/field';
import useEnums from 'hooks/useEnums';
import { Textarea, Input, Form, Button, RadioGroup } from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import {
  GrantEventParticipantExpense, GrantEventParticipantExpenseYear
} from 'store/grants/types';
import {Errors} from 'helpers/errors';
import styles from './travel.module.css';
import {FieldErrors} from "react-hook-form";

type Props = {
  item: GrantEventParticipantExpense;
  errors: Errors;
  loading: boolean;
  onBlur: (data: GrantEventParticipantExpense, id: string, cb: (cbData: GrantEventParticipantExpense) => void) => void;
  onSubmit: (data: GrantEventParticipantExpense) => void;
  trackError?: (errors: FieldErrors) => void;
  trackExit?: () => void
}

const GrantEventParticipantTravelForm:FC<Props> = ({ trackError, trackExit,item, onBlur, loading, errors, onSubmit }) => {

  const [ data, onChangeData ] = useState<GrantEventParticipantExpense>(item);
  const { yesNoOptionsRevert } = useEnums();
  const handleSubmit = useCallback(() => {
    onSubmit(data);
  }, [onSubmit, data]);

  const handleBlur = useCallback((event?: any, newData?: GrantEventParticipantExpense) => {
    onBlur(newData ?? data, item.id, (cbData: GrantEventParticipantExpense) => {
      const newYears = (newData ?? data).years.map((year: GrantEventParticipantExpenseYear) => {
        const yearFromCb = cbData.years.find((cbYear: GrantEventParticipantExpenseYear) => cbYear.serialNumber === year.serialNumber);
        if (yearFromCb) {
          return {
            ...year,
            compensationBeforeInflation: yearFromCb.compensationBeforeInflation,
            compensationAfterInflation: yearFromCb.compensationAfterInflation,
          }
        }
        return year;
      });
      onChangeData({
        ...(newData ?? data),
        amount: cbData.amount,
        totalAmountForTrip: cbData.totalAmountForTrip,
        years: newYears
      });
    });
  }, [onBlur, item, data]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newData:GrantEventParticipantExpense = {
      ...data,
      ...field
    };
    onChangeData(newData);
    if (preview) handleBlur(undefined, newData);
  }, [data, handleBlur]);

  return (
    <Form prompt onSubmit={handleSubmit} trackExit={trackExit} trackError={trackError}>
      <Field withHelp>
        <Input name="name" errors={errors.name} label="Name of other participant expense" required
               value={data.name} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({name: event.target.value})}
               placeholder="Enter name" help="some help" onBlur={handleBlur} />
        <Input name="price" errors={errors.price} money type="number" label="Price for the expense" required
               value={data.price} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({price: event.target.value})}
               placeholder="Enter price for the expense" onBlur={handleBlur} />
      </Field>
      <Field full>
        <RadioGroup name="isSubsistence" inline label="Would you like this expense to count as a Subsistence expense?" required bool options={yesNoOptionsRevert}
                    value={data.isSubsistence} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({ isSubsistence: event.target.value })}
                    help="Choose 'Subsistence' as the cost category for basic needs like lodging and daily allowances during the project. This covers hotel costs, meals, and support for active participation. Exclude travel expenses, conference fees, and incidental costs. It's for short-term support aligning with the project's duration, and local participants may not need it unless they require overnight stays"
        />
      </Field>
      <TransportationYears label="Please check the year(s) of this grant proposal in which these expenses will occur" expense errors={errors} onBlur={handleBlur} totalAmountForTrip={data.totalAmountForTrip} onChange={handleChangeData} participant data={data.years} />
      <Field full last>
        <Textarea name="justification" label="Justification for this cost"
                  placeholder="Enter a description..."
                  value={data.justification}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({justification: event.target.value})} />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit">Save</Button>
      </div>
    </Form>
  );
}

export default GrantEventParticipantTravelForm;
