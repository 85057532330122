type CheckPassword = (password: string) => {
  digit: boolean;
  length: boolean;
  special: boolean;
  capital: boolean;
  all: boolean;
}
const checkPassword:CheckPassword = (password: string) => {
  const digit = new RegExp(/[0-9]+/g).test(password);
  const length = password.length > 7 && password.length < 29;
  const special = new RegExp(/[!@#$%^^&*()±§\-\+_=~`<>\{\}\[\]\\,.?~\/"№;:]/g).test(password);
  const capital = new RegExp(/([A-Z]+)/g).test(password);
  return {
    digit,
    length,
    special,
    capital,
    all: digit && length && special && capital
  }
}
export default checkPassword;
