import {FC, ReactElement, useEffect} from 'react';
import { isString } from 'lodash';
import cx from 'classnames';
import {useLocation} from 'react-router';
import useGrants from 'hooks/useGrants';
import Typo from 'components/typo';
import Head from 'containers/head';
import Message from 'pages/grant/common/message';
import useUI from 'hooks/useUI';
import styles from './wrapper.module.css';

type Props = {
  title: string | ReactElement;
  subtitle?: any;
  helmet?: string;
  className?: string;
  children?: ReactElement;
}

const GrantFormWrapper:FC<Props> = ({ title, className, helmet, children, subtitle }) => {
  const { grant } = useGrants();
  const location = useLocation();
  const { onHideNavigationMessage } = useUI();

  useEffect(() => {
    onHideNavigationMessage();
  }, [location])

  const head = helmet ?? `${grant.title}: ${title}${subtitle ? '. ' + subtitle : ''}`;

  return (
    <>
      <Head title={head} />
      <div className={cx('container page-wrapper', className, styles.container)}>
        <div className={styles.wrapper}>
          {isString(title) ? <Typo type="h3" bold className={styles.title}>{title}</Typo> : title}
          {subtitle ?
            <div className={styles.subtitleWrapper}>
              <Typo type="h4" className={styles.subtitle} bold>{subtitle}</Typo>
            </div>
            : null
          }
        </div>
        {children}
        <Message/>
      </div>
    </>
  );
}

export default GrantFormWrapper;
