import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateEditEvent from 'pages/grant/one/participant/createOrEditEvent';
import CreateEventParticipant from 'pages/grant/one/participant/participant';
import GrantEventParticipantTravels from 'pages/grant/one/participant/participant/travel';
import GrantEventParticipantExpensesPage from 'pages/grant/one/participant/participant/expenses';
import GrantEventParticipantStipends from 'pages/grant/one/participant/participant/stipends';
import GrantEventTotal from 'pages/grant/one/participant/totals';
import GrantEventsTotal from 'pages/grant/one/participant/totals/all';


const ParticipantIndex:FC = () => {
  return (
    <>
      <Switch>
        <Route path="/grants/:id/events/create" exact component={CreateEditEvent} />
        <Route path="/grants/:id/events/:eventId/edit" exact component={CreateEditEvent} />
        <Route path="/grants/:id/events/totals" exact component={GrantEventsTotal} />
        <Route path="/grants/:id/events/:eventId/totals" exact component={GrantEventTotal} />
        <Route path="/grants/:id/events/:eventId/participants/:participantId/edit" exact component={CreateEventParticipant} />
        <Route path="/grants/:id/events/:eventId/participants/:participantId/travels" exact component={GrantEventParticipantTravels} />
        <Route path="/grants/:id/events/:eventId/participants/:participantId/expenses" exact component={GrantEventParticipantExpensesPage} />
        <Route path="/grants/:id/events/:eventId/participants/:participantId/stipends" exact component={GrantEventParticipantStipends} />
      </Switch>
    </>
  );
}

export default ParticipantIndex;
