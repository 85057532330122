import {ChangeEvent, FC, useCallback, useState} from 'react';
import cx from 'classnames';
import Modal from 'components/modal';
import Typo from 'components/typo';
import { Input, Form, DatePicker, Checkbox, Button } from 'components/form';
import { FullGrant } from 'store/grants/types';
import * as T from 'hooks/requestTypes';
import styles from './acceptForm.module.css';
import {Errors} from 'helpers/errors';
import {stringOrUndefined} from 'helpers/date';
import {useMixPanel} from "../../../hooks/useMixPanel";

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: T.AcceptForm) => void;
  grant: FullGrant;
  errors: Errors;
  loading: boolean;
}

type Field = Record<string, any>;

const AcceptForm:FC<Props> = ({ visible, loading, errors, onSubmit, grant, onClose}) => {
  const {grantAcceptedFormSubmitted} = useMixPanel()
  const [ data, onChangeData ] = useState<T.AcceptForm>({
    endDate: grant.endDate ? new Date(grant.endDate) : null,
    startDate: grant.startDate ? new Date(grant.startDate) : null,
    subGroup: grant.organization?.subGroup,
    department: grant.organization?.department,
    awardNumber: grant.acceptedForm?.awardNumber ?? '',
    uniqueEntityIdentifier: grant.acceptedForm?.uniqueEntityIdentifier ?? '',
    totalDirectCosts: grant.acceptedForm?.totalDirectCosts ?? '',
    checked: Boolean(grant.acceptedForm?.awardNumber),
    totalIndirectCosts: grant.acceptedForm?.totalDirectCosts ?? '',
    awardDate: grant.acceptedForm?.awardDate ? new Date(grant.acceptedForm.awardDate) : null,
  });

  const readOnly = Boolean(grant.acceptedForm?.awardNumber);

  const handleChangeData = useCallback((field: Field) => {
    if (readOnly) return;
    onChangeData({
      ...data,
      ...field
    });
  }, [data, readOnly]);

  const handleSubmit = useCallback(() => {
    if (readOnly) return;
    onSubmit({
      checked: data.checked,
      awardNumber: data.awardNumber,
      uniqueEntityIdentifier: data.uniqueEntityIdentifier,
      totalDirectCosts: data.totalDirectCosts,
      totalIndirectCosts: data.totalIndirectCosts,
      awardDate: stringOrUndefined(data.awardDate),
    });
    grantAcceptedFormSubmitted(grant.id)
  }, [onSubmit, readOnly, data]);


  return (
    <Modal visible={visible} title="Grant accepted form" onCancel={onClose}>
      <Form onSubmit={handleSubmit} className={styles.wrapper}>
        <Typo size={16} className={styles.subtitle} type="div">
          <div>Congrats! We're thrilled you have successfully gotten your research funded!</div>
          <div>To confirm your successful research funding and to help us keep track of the final values, we may need to
            make adjustments to your final values in our software.
          </div>
          <div>All the information needed for this form can be found on your NSF Award Notice. If you are unsure of
            where this notice is, it is possible that it has been emailed to you or that your university has a copy.
          </div>
        </Typo>
        <div className={cx(styles.field, styles.twoInputsField)}>
          <Input name="awardNumber" className={styles.input} label="Award Number (FAIN)" required
                 disabled={readOnly} readOnly={readOnly}
                 placeholder="Enter award number" value={data.awardNumber} errors={errors.awardNumber}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({awardNumber: event.target.value})}
          />
          <Input name="uniqueEntityIdentifier" className={styles.input} label="Unique Entity Identifier (DUNS ID)" required
                 disabled={readOnly} readOnly={readOnly}
                 placeholder="Enter unique entity identifier" value={data.uniqueEntityIdentifier} errors={errors.uniqueEntityIdentifier}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({uniqueEntityIdentifier: event.target.value})}
          />
        </div>
        <div className={cx(styles.field, styles.twoInputsField)}>
          <Input name="subGroup" className={styles.input} label="College (if a subgroup of your university)"
                 placeholder="Enter college" value={data.subGroup} errors={errors.subGroup} readOnly disabled
          />
          <Input name="department" className={styles.input} label="Department"
                 placeholder="Enter department" value={data.department} errors={errors.department} readOnly disabled
          />
        </div>
        <div className={cx(styles.field, styles.twoInputsField)}>
          <Input name="totalDirectCosts" money className={styles.input} label="Total Direct Costs"
                 disabled={readOnly} readOnly={readOnly}
                 placeholder="Enter total direct costs" value={data.totalDirectCosts} errors={errors.totalDirectCosts}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({totalDirectCosts: event.target.value})}
          />
          <Input name="totalIndirectCosts" money className={styles.input} label="Total Indirect Costs" required
                 disabled={readOnly} readOnly={readOnly}
                 placeholder="Enter total indirect costs" value={data.totalIndirectCosts} errors={errors.totalIndirectCosts}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({totalIndirectCosts: event.target.value})}
          />
        </div>
        <div className={styles.field}>
          <DatePicker name="awardDate" className={styles.fullInput} label="Award Date" required
                      disabled={readOnly} readOnly={readOnly}
                      placeholder="Choose date" value={data.awardDate} errors={errors.awardDate}
                      onChange={(value: Date | null) => handleChangeData({awardDate: value})}
          />
        </div>
        <div className={cx(styles.field, styles.twoInputsField, styles.last)}>
          <DatePicker name="startDate" className={styles.input} label="Grant Start Date"
                      disabled readOnly
                      placeholder="Choose date" value={data.startDate} errors={errors.startDate}
          />
          <DatePicker name="endDate" className={styles.input} label="Grant End Date"
                      disabled readOnly
                      placeholder="Choose date" value={data.endDate} errors={errors.endDate}
          />
        </div>
        <Typo className={styles.text}>
          This is language in the NSF acceptance letter that we want to draw your attention to as you go on to publish
          and distribute your work in the future. We do not count ourselves as a “contributor” so there’s no need to
          include us if you don’t want to.
        </Typo>
        <Checkbox name="checked" disabled={readOnly} value={data.checked} errors={errors.checked}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({checked: event.target.checked})}
                  label="I understand"
        />
        <div className={styles.footer}>
          <Button size="lg" disabled={readOnly} name="submit" loading={loading} htmlType="submit" postIcon="arrow-narrow-right">Submit form</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default AcceptForm;
