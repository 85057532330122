import {FC, useCallback, useMemo} from 'react';
import useUrl from 'hooks/useUrl';
import {ETravelType, FullGrant, SidebarTotals, Travel} from 'store/grants/types';
import Trip from './trip';
import useTravel from 'hooks/useTravel';
import TripsList from './components/tripsList';
import {TotalAnchor} from 'pages/grant/paper/card/anchor';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}
const TravelFolders:FC<Props> = ({ side, totals }) => {
  const {create, destroy, removeTransportationMethod, duplicateTravel, removeTravelCost} = useTravel();
  const url = useUrl();

  const handleCreateTravel = useCallback((data: any) => {
    create(side.id, data)
  }, [create, side])

  const handleRemoveTravel = useCallback((id: string) => {
    destroy(side.id, id, url.subCategory === id);
  }, [destroy, side, url]);

  const handleDuplicateTravel = useCallback((id: string) => {
    duplicateTravel(side.id, id);
  }, [duplicateTravel, side]);


  const grantPersonnelTrips = useMemo(() => {
    const list = side.travel.filter(travel => travel.type === ETravelType.GrantPersonnel);

    return list.map((item: Travel) => {
      return {
        item: {
          label: item.tripName || 'Untitled trip',
          type: item.id,
          id: item.id,
          active: url.categoryId === item.id,
          tripType: item.type,
        },
        children: (
          <Trip
            onRemoveMethod={removeTransportationMethod}
            onRemoveCost={removeTravelCost}
            side={side}
            trip={item}
          />
        ),
      }
    });
  }, [side, removeTransportationMethod, removeTravelCost, url]);

  const consultantTrips = useMemo(() => {
    let unnamedTripsCount = 0;
    const list = side.travel.filter(travel => travel.type === ETravelType.Consultant);

    return list.map((item: Travel) => {
      if (!item.tripName) unnamedTripsCount++;

      return {
        item: {
          label: `${item.tripName || `Untitled trip ${unnamedTripsCount}`}`,
          type: item.id,
          id: item.id,
          active: url.categoryId === item.id,
          tripType: item.type,
        },
        children: (
          <Trip
            onRemoveMethod={removeTransportationMethod}
            onRemoveCost={removeTravelCost}
            side={side}
            trip={item}
          />
        ),
      }
    });
  }, [side, removeTransportationMethod, removeTravelCost, url]);
  return (
    <>
      <TripsList
        grant={side}
        trips={grantPersonnelTrips}
        tripsType={ETravelType.GrantPersonnel}
        listTitle="Grant personnel trips"
        listId="1"
        activeTripId={url.categoryId ?? ''}
        handleCreateTrip={handleCreateTravel}
        handleDeleteTrip={handleRemoveTravel}
        handleDuplicateTrip={handleDuplicateTravel}
      />
      <TripsList
        grant={side}
        trips={consultantTrips}
        tripsType={ETravelType.Consultant}
        listTitle="Consultant trips"
        listId="2"
        activeTripId={url.categoryId ?? ''}
        handleCreateTrip={handleCreateTravel}
        handleDeleteTrip={handleRemoveTravel}
        handleDuplicateTrip={handleDuplicateTravel}
      />
      {
        side.travel.length > 0
          ?
          <TotalAnchor total={totals.travelsTotalAmount} to={`/grants/${side.id}/travel/totals`} active={url.grantId === side.id && url.category === 'travel' && url.subCategory === 'totals'}>
            Total. All trips
          </TotalAnchor>
          : null
      }
    </>
  )
}

export default TravelFolders;
