import {FC, useCallback, useMemo, useState} from 'react';
import useGrants from 'hooks/useGrants';
import usePersonnel from 'hooks/usePersonnel';
import {useParams} from 'react-router-dom';
import {personalTypes} from 'const';
import {getAcademicResearchPersonFromGrant, getSeniorPersonFromGrant} from 'helpers/getFromGrant';
import {PersonalNSFCompensation} from 'store/grants/types';
import CompensationPercentForm, {PercentCompensationData} from './percent';
import * as T from 'hooks/requestTypes';
import CompensationMonthForm from './month';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {isNumber} from 'lodash';

const SeniorOrAcademicPersonCompensation: FC = () => {
  const {grant} = useGrants();
  const {
    calculatePercentCompensation, editAcademicResearchPersonNSFCompensation,
    editSeniorPersonNSFCompensation, calculateMonthCompensation,
    errors, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({
    form_page_type: 'General Info'
  });
  const params: Record<string, string> = useParams();
  const {person, index} = params.personnelType === personalTypes.seniorPersonal
    ? getSeniorPersonFromGrant(grant, params.personId)
    : getAcademicResearchPersonFromGrant(grant, params.personId);

  const compensation: PersonalNSFCompensation | undefined = useMemo(() => {
    return person?.nsfCompensation[Number(params.year)];
  }, [person, params]);

  const [percentData, onChangePercentData] = useState<PercentCompensationData>(() => {
    const data = person?.nsfCompensation[Number(params.year)];
    return {
      compensationAfterInflation: data?.compensationAfterInflation ?? '',
      compensationBeforeInflation: data?.compensationBeforeInflation ?? '',
      equivalentNumberOfMonths: data?.equivalentNumberOfMonths ?? '',
      percent: data?.percent ? percentToFixed(data.percent) : 0,
    }
  });

  const [monthData, onChangeMonthData] = useState(() => {
    const data = person?.nsfCompensation[Number(params.year)];
    return {
      months: data?.months ?? '',
      monthsFromCurrentGrant: data?.monthsFromCurrentGrant ?? '',
      monthsInYear: data?.monthsInYear ?? '',
      availableMonths: data?.availableMonths ?? '',
      monthlyCompensationBasedOnSalary: data?.monthlyCompensationBasedOnSalary ?? '',
      monthsAvailableAfterRequest: data?.monthsAvailableAfterRequest ?? '',
      nsfCompensatedMonths: data?.nsfCompensatedMonths ?? '',
      compensationAfterInflation: data?.compensationAfterInflation ?? '',
      compensationBeforeInflation: data?.compensationBeforeInflation ?? '',
      uncappedMonths: data?.uncappedMonths ?? '',
      monthCompensatedByOrganization: person?.salary?.monthCompensatedByOrganization ?? '',
    }
  });

  const title = useMemo(() => {
    if (compensation?.kind === 'percent') return 'NSF compensation by percentage';
    return 'NSF compensation by months';
  }, [compensation]);

  const subtitle = useMemo(() => {
    return `Person ${index}. Year ${Number(params.year) + 1}`
  }, [index, params]);

  const type: string = useMemo(() => {
    return params.personnelType === personalTypes.seniorPersonal ? personalTypes.seniorPersonal : personalTypes.academicResearchAssociate;
  }, [params]);

  const onSubmitPercentCompensation = useCallback(() => {
    if (!person || !compensation) return;
    //@ts-ignore
    const nsfCompensation: NSFCompensationForUpdate[] = person.nsfCompensation.map((c: PersonalNSFCompensation, idx: number) => {
      if (idx === Number(params.year)) {
        return {
          ...c,
          percent: percentData.percent / 100
        }
      }
      return c;
    });
    if (type === personalTypes.academicResearchAssociate) {
      editAcademicResearchPersonNSFCompensation(grant.id, person.id, {
        nsfCompensation
      });
      return;
    }
    if (type === personalTypes.seniorPersonal) {
      editSeniorPersonNSFCompensation(grant.id, person.id, {
        nsfCompensation
      });
      return;
    }
  }, [type, editAcademicResearchPersonNSFCompensation, params, compensation, percentData, person, grant, editSeniorPersonNSFCompensation])

  const onSubmitMonthCompensation = useCallback(() => {
    if (isNumber(monthData.availableMonths) && monthData.monthsFromCurrentGrant && monthData.monthsFromCurrentGrant > monthData.availableMonths) return;
    if (!person || !compensation) return;
    //@ts-ignore
    const nsfCompensation: NSFCompensationForUpdate[] = person.nsfCompensation.map((c: PersonalNSFCompensation, idx: number) => {
      if (idx === Number(params.year)) {
        return {
          ...c,
          ...monthData
        }
      }
      return c;
    });
    if (type === 'academic-research-personal') {
      editAcademicResearchPersonNSFCompensation(grant.id, person.id, {
        nsfCompensation
      });
      return;
    }
    if (type === 'senior-personal') {
      editSeniorPersonNSFCompensation(grant.id, person.id, {
        nsfCompensation
      });
      return;
    }
  }, [type, editAcademicResearchPersonNSFCompensation, params, compensation, monthData, person, grant, editSeniorPersonNSFCompensation])


  const handleCalculatePercent = useCallback(() => {
    if (person && compensation) {
      calculatePercentCompensation(grant.id, type, {
        percent: percentData.percent / 100,
        year: compensation.year,
        serialNumber: Number(compensation.serialNumber),
        personId: person.id
      }, (data: T.PercentCompensationResponse) => {
        onChangePercentData({
          ...percentData,
          compensationAfterInflation: data.compensationAfterInflation,
          compensationBeforeInflation: data.compensationBeforeInflation,
          equivalentNumberOfMonths: data.equivalentNumberOfMonths,
        })
      });
    }
  }, [percentData, compensation, grant, type, person, calculatePercentCompensation]);

  const handleCalculateMonths = useCallback(() => {
    if (monthData.months === '' || Number(monthData.monthsInYear) < 1) return;
    if (person && compensation) {
      calculateMonthCompensation(grant.id, type, {
        months: Number(monthData.months),
        monthsInYear: Number(monthData.monthsInYear),
        serialNumber: Number(compensation.serialNumber),
        nsfCompensatedMonths: Number(monthData.nsfCompensatedMonths),
        availableMonths: Number(monthData.availableMonths),
        monthsFromCurrentGrant: monthData.monthsFromCurrentGrant ? Number(monthData.monthsFromCurrentGrant) : undefined,
        year: compensation.year,
        personId: person.id
      }, (data: T.MonthCompensationResponse) => {
        onChangeMonthData({
          ...monthData,
          ...data
        })
      });
    }
  }, [monthData, compensation, grant, type, person, calculateMonthCompensation]);

  return (
    <GrantFormWrapper title={title} subtitle={subtitle}>
      {
        person && compensation?.kind === 'percent'
          ? <CompensationPercentForm errors={errors} loading={loading} year={Number(params.year) + 1}
                                     onSubmit={onSubmitPercentCompensation} compensation={percentData}
                                     onChange={onChangePercentData} onBlur={handleCalculatePercent}
                                     trackError={trackError}
                                     trackExit={trackExit}
                                     trackFormStarted={trackFormStarted}
          />
          : <CompensationMonthForm errors={errors} loading={loading} year={Number(params.year) + 1}
                                   onSubmit={onSubmitMonthCompensation} compensation={monthData}
                                   onChange={onChangeMonthData} onBlur={handleCalculateMonths}
                                   trackError={trackError}
                                   trackExit={trackExit}
                                   trackFormStarted={trackFormStarted}
          />
      }
    </GrantFormWrapper>
  )
}

export default SeniorOrAcademicPersonCompensation;
