import { FC } from 'react';
import styles from './social.module.css';
import {Button} from 'components/form';
import google from './google.svg';
import microsoft from './microsoft.png';
import {useGoogleLogin} from '@react-oauth/google';

type Props = {
  msLoading: boolean;
  googleLoading: boolean;
  msLogin: () => void;
  text?: string;
}

const Social:FC<Props> = ({ msLogin, msLoading, text = 'Sign in', googleLoading }) => {

  const googleLogin = useGoogleLogin({
    redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    ux_mode: 'redirect',
    flow: 'auth-code'
  });
  return (
    <div>
      <Button htmlType="button" className={styles.social}
              name="google" type="bordered"
              onClick={googleLogin} loading={googleLoading}
      >
        <><img className={styles.socialIcon} src={google} alt="google"/>{text} with Google</>
      </Button>
      <Button htmlType="button" className={styles.social}
              name="microsoft" type="bordered"
              onClick={msLogin} loading={msLoading}
      >
        <><img className={styles.socialIcon} src={microsoft} alt="microsoft"/>{text} with Microsoft</>
      </Button>
    </div>
  )
}

export default Social;
