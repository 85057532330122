import { Action } from 'store/types';
import { EnumsState } from './types';
import { enumsStateAdapter } from './adapters';
import {
  setEnumsAction,
  setAirportsAction,
  setAirlinesAction,
} from './actions';


const initialStore = enumsStateAdapter();

const reducer = (state:EnumsState = initialStore, action: Action) => {
  switch (action.type) {
    case setEnumsAction.type: {
      return enumsStateAdapter(action.payload);
    }
    case setAirportsAction.type: {
      localStorage.setItem('bm_enum_airports', JSON.stringify(action.payload));
      return state.set('airports', action.payload);
    }
    case setAirlinesAction.type: {
      localStorage.setItem('bm_enum_airlines', JSON.stringify(action.payload));
      return state.set('airlines', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
