import {FC, useMemo, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {getConsultantServiceFromGrant} from 'helpers/getFromGrant';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Tabs from 'components/tabs';
import ProviderForm from './form';
import useConsultantServices from 'hooks/useConsultantServices';
import {
  ConsultantServiceProvider,
  ConsultantServiceType,
  ConsultantServiceYear
} from 'store/grants/otherDirectCosts/ConsultantServices/types';
import useEnums from 'hooks/useEnums';
import {FieldErrors} from "react-hook-form";


export type FormProps = {
  consultant: ConsultantServiceType;
  year: ConsultantServiceYear;
  provider: ConsultantServiceProvider;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type Props = {
  consultant: ConsultantServiceType;
  grantId: string;
  year: ConsultantServiceYear;
}
const YearForm: FC<Props> = ({consultant, year, grantId}) => {
  const {consultantServiceProviderTypes} = useEnums();
  const {
    addProvider, deleteProvider,
    trackError,
    trackExit,
    trackFormStarted
  } = useConsultantServices({
    form_page_type: "Date"
  });

  const history = useHistory();

  const list = useMemo(() => {
    const type = consultantServiceProviderTypes.find((option) => option.value === consultant.providersType);
    return year.providers.map((item: ConsultantServiceProvider, idx: number) => ({
      label: item.name || `${type?.label} ${idx + 1}`,
      id: item.id,
      onDelete: () => deleteProvider(grantId, consultant.id, year.serialNumber, item.id),
      element: <ProviderForm provider={item} key={item.id} year={year}
                             consultant={consultant}
                             trackError={trackError}
                             trackExit={trackExit}
                             trackFormStarted={trackFormStarted}
      />
    }));
  }, [consultantServiceProviderTypes, year, consultant, trackError, trackExit, trackFormStarted, deleteProvider, grantId]);

  const onAdd = useCallback(() => {
    addProvider(grantId, consultant.id, year.serialNumber, (data) => {
      //@ts-ignore
      const [newProvider] = [...(data.years[year.serialNumber - 1]?.providers ?? [])].reverse();
      if (newProvider) {
        history.push(`/grants/${grantId}/otherDC/consultantServices/${consultant.id}/years/${year.serialNumber}?provider=${newProvider.id}`)
      }
    });
  }, [addProvider, history, consultant, grantId, year]);

  return (
    <Tabs smallPadding list={list} name="provider" action={onAdd} actionTitle="Add service provider"/>
  );
}

const ConsultantYear: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getConsultantServiceFromGrant(grant, params.serviceId);
  const year = item?.years[Number(params.year) - 1];

  return (
    <GrantFormWrapper title="Other Direct Costs"
                      subtitle={`Consultant service ${index > 0 ? index : ''}. Year ${params.year}`}>
      {year ? <YearForm grantId={grant.id} year={year} consultant={item}
                        key={`${grant.id}-consultant-service-${params.serviceId}-year-${params.year}`}/> : <></>}
    </GrantFormWrapper>
  )
}

export default ConsultantYear;
