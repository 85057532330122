import {ChangeEvent, FC, useCallback} from 'react';
import Month from 'pages/grant/one/travel/cost/common/month';
import styles from './breakdown.module.css';
import {ListItem} from 'helpers/utils';
import Help from 'components/help';
import {Button, Form} from 'components/form';
import {MaIEBreakdownFullDays, MaIEBreakdownMonth} from 'store/grants/types';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

const allMonths: ListItem[] = [
  "January", "February", "March", "April",
  "May", "June", "July", "August",
  "September", "October", "November", "December"
].map((item: string, idx: number) => ({value: idx + 1, label: item}));

type Props = {
  data: MaIEBreakdownFullDays;
  onSubmit: () => void;
  onBlur: () => void;
  onDelete: (idx: number) => void;
  add: () => void;
  errors: Errors;
  loading: boolean;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const FullDays: FC<Props> = ({
                               trackError,
                               trackExit, data, onBlur, onDelete, add, onSubmit, errors, onChange
                             }) => {


  const onChangeMonth = useCallback((idx: number) => (field: Record<string, any>, preview?: boolean) => {
    const nextMonths = data.months.map((item: MaIEBreakdownMonth) => {
      if (item.index === idx) return {
        ...item,
        ...field
      }
      return item;
    });
    onChange({
      ...data,
      months: nextMonths
    }, preview);
  }, [data, onChange]);

  return (
    <Form prompt onSubmit={onSubmit} trackExit={trackExit} trackError={trackError}>
      {
        data.months.map((item: MaIEBreakdownMonth) => (
          <Month withoutHelp onBlur={onBlur} key={item.index as number} field="mAndIeBreakdown.fullDays"
                 months={allMonths}
                 onDelete={data.months.length === 1 ? undefined : () => onDelete(item.index as number)} maie
                 onChange={onChangeMonth(item.index as number)} errors={errors} index={item.index as number}
                 data={item}/>
        ))
      }
      <div className={styles.addWrapper}>
        <Button name="add-month" preIcon="plus" onClick={add} htmlType="button">Add month</Button>
        <Help>
          Add more days, months if the daily rate of you M&IE changes during your trip due to month change or due to
          rate change in the same month.
        </Help>
      </div>
      <div className={styles.footer}>
        <Button name="submit-first-day" htmlType="submit">Save</Button>
      </div>
    </Form>
  )
}

export default FullDays;
