import { FC, useState, useCallback } from 'react';
import {Link} from 'react-router-dom';
import { Button } from 'components/form';
import useAuth from 'hooks/useAuth';
import useUI from 'hooks/useUI';
import Typo from 'components/typo';
import OrganizationForm from 'containers/forms/organization';
import { OrganizationPureUserData } from 'hooks/requestTypes';
import styles from '../account.module.css';
import Head from 'containers/head';
import { trackUserAction } from 'helpers/trackUserActions';
import {useMixPanel} from "../../../../hooks/useMixPanel";

export type Field = Record<string, any | Record<any, any>>;
const Organization:FC = () => {
  const {
    organizationInformationEdited
  } = useMixPanel()
  const { user, updateOrganization, errors, loading } = useAuth();
  const { prevUrl } = useUI();
  const [ data, onChangeData ] = useState<OrganizationPureUserData>({
    id: user.organization.id || undefined,
    name: user.organization.name,
    subGroup: user.organization.subGroup,
    department: user.organization.department,
    address: {
      address1: user.organization.address.address1,
      address2: user.organization.address.address2,
      city: user.organization.address.city,
      country: user.organization.address.country,
      state: user.organization.address.state,
      zip: user.organization.address.zip,
    },
    academicYear: {
      startDate: user.organization.academicYear.startDate ? new Date(user.organization.academicYear.startDate) : null,
      endDate: user.organization.academicYear.endDate ? new Date(user.organization.academicYear.endDate) : null,
    },
    fiscalYear: {
      startDate: user.organization.fiscalYear.startDate ? new Date(user.organization.fiscalYear.startDate) : null,
      endDate: user.organization.fiscalYear.endDate ? new Date(user.organization.fiscalYear.endDate) : null,
      definition: user.organization.fiscalYear.definition,
    },
    annualSchedule: user.organization.annualSchedule,
});

  const handleChangeData = useCallback((field: Field) => {
    organizationInformationEdited()
    onChangeData({
      ...data,
      ...field
    });
  }, [data, onChangeData]);

  const onSubmit = useCallback((value: OrganizationPureUserData) => {
    updateOrganization(value);
    trackUserAction("Successfully Started a new grant")
  }, [updateOrganization]);

  return (
    <div className={styles.wrapper}>
      <Head title="Organization information"/>
      <div className={styles.header}>
        <Typo type="h5" className={styles.tabTitle} semi>Organization information</Typo>
      </div>
      <OrganizationForm onSubmit={onSubmit} data={data} errors={errors} onChange={handleChangeData}>
        <div className={styles.footer}>
          <div className={styles.goBackFooter}>
            <Link to={prevUrl}>
              <Button type="bordered" size="lg" name="back" preIcon="arrow-left">Go back</Button>
            </Link>
            <Button htmlType="submit" size="lg" loading={loading} name="back">Update</Button>
          </div>
        </div>
      </OrganizationForm>
    </div>
  )
}

export default Organization;
