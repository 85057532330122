import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import {FormProps} from './index';
import cx from 'classnames';
import Field from 'components/field';
import {Input, Button, RadioGroup, Select, Form, Radio, Textarea, Checkbox, Tags} from 'components/form';
import {
  ConsultantServiceProvider,
  ForeignConsultantInfoType
} from 'store/grants/otherDirectCosts/ConsultantServices/types';
import useEnums from 'hooks/useEnums';
import useConsultantServices from 'hooks/useConsultantServices';
import styles from './year.module.css';
import useGrants from 'hooks/useGrants';
import {Travel} from 'store/grants/types';
import Modal from 'components/modal';
import Typo from 'components/typo';

const IndividualForm:FC<FormProps> = ({trackError,
                                        trackExit, provider, consultant, year }) => {
  const { yesNoOptions, consultantServiceProviderTravelCostsTypes } = useEnums();
  const { grant } = useGrants();
  const { previewServiceData, isLoading, updateProvider } = useConsultantServices();
  const [ data, onChangeData ] = useState<ConsultantServiceProvider>({
    ...provider,
    trips: provider.trips.map((item) => item?.id)
  });
  const defaultInfo = useMemo(() => {
   return {
     needSupportExplanation: data.foreignConsultantInfo?.needSupportExplanation ?? '',
     receivedSupportExplanation: data.foreignConsultantInfo?.receivedSupportExplanation ?? '',
     isContributes: Boolean(data.foreignConsultantInfo?.isContributes),
     isOffers: Boolean(data.foreignConsultantInfo?.isOffers),
   }
  }, [data]);

  const [ info, onChangeInfo ] = useState<ForeignConsultantInfoType>(defaultInfo);
  const [ showModal, onChangeShowModal ] = useState<boolean>(false);
  const travels = grant.travel
    .filter((item: Travel) => item.type === 'consultant')
    .map((item: Travel) => ({label: item.tripName, value: item.id}));

  const handleChangeInfo = useCallback((field: Record<string, any>) => {
    onChangeInfo({
      ...info,
      ...field
    });
  }, [info]);

  const onSubmitModal = useCallback(() => {
    onChangeData({
      ...data,
      foreignConsultantInfo: {
        ...data.foreignConsultantInfo,
        ...info
      }
    });
    onChangeShowModal(false);
  }, [info, data]);

  const onCloseModal = useCallback(() => {
    onChangeInfo(defaultInfo);
    onChangeShowModal(false);
  }, [defaultInfo]);

  const onSubmit = useCallback(() => {
    const trips = travels
      .filter((item) => data.trips.includes(item.value))
      .map((item) => ({tripName: item.label, id: item.value}));
    const submitData = {
      ...data,
      trips: data.travelCostsType === 'travelCosts' ? trips : [],
    }
    updateProvider(grant.id, consultant.id, year.serialNumber, provider.id, submitData);
  }, [updateProvider, grant, data, consultant, year, travels, provider]);

  const onBlur = useCallback((event?: any, blurData?: ConsultantServiceProvider) => {
    const value = blurData ?? data;
    const trips = travels
      .filter((item) => value.trips.includes(item.value))
      .map((item) => ({tripName: item.label, id: item.value}));
    const previewData = {
      ...(value.dailyCompensationRate !== '' ? {dailyCompensationRate: value.dailyCompensationRate} : {}),
      ...(value.numberOfDays !== '' ? {numberOfDays: value.numberOfDays} : {}),
      ...(value.yearlyLumpSum !== '' ? {yearlyLumpSum: value.yearlyLumpSum} : {}),
      ...(value.additionalCompensation !== '' ? {additionalCompensation: value.additionalCompensation} : {}),
      ...(value.travelCostsType !== '' ? {travelCostsType: value.travelCostsType} : {}),
      ...(value.estimatedTravelCosts !== '' ? {estimatedTravelCosts: value.estimatedTravelCosts} : {}),
      ...(value.numberOfConsultantsInOrganization !== '' ? {numberOfConsultantsInOrganization: value.numberOfConsultantsInOrganization} : {}),
      ...(value.isDailyCompensation ? {isDailyCompensation: 1} : {}),
      trips: value.travelCostsType === 'travelCosts' ? trips : [],
      yearSerialNumber: year.serialNumber,
      isRegularCostInflation: consultant.isRegularCostInflation,
      isAdditionalCostInflation: consultant.isAdditionalCostInflation,
      isTravelCostInflation: consultant.isTravelCostInflation,
      type: consultant.providersType
    };
    previewServiceData(grant.id, previewData, (response) => {
      onChangeData({
        ...value,
        regularCompensationBeforeInflation: response?.regularCompensationBeforeInflation ?? undefined,
        regularCompensationAfterInflation: response?.regularCompensationAfterInflation ?? undefined,
        additionalCompensationBeforeInflation: response?.additionalCompensationBeforeInflation ?? undefined,
        additionalCompensationAfterInflation: response?.additionalCompensationAfterInflation ?? undefined,
        travelCostsCompensationBeforeInflation: response?.travelCostsCompensationBeforeInflation ?? undefined,
        travelCostsCompensationAfterInflation: response?.travelCostsCompensationAfterInflation ?? undefined,
        compensationBeforeInflation: response?.compensationBeforeInflation ?? undefined,
        compensationAfterInflation: response?.compensationAfterInflation ?? undefined,
      });
    });
  }, [year, consultant, travels, data, grant, previewServiceData]);

  const handleChange = useCallback((field: Record<string, any>, blur?: boolean) => {
    const newData = {
      ...data,
      ...field
    };
    onChangeData(newData);
    if (blur) {
      onBlur(undefined, newData);
    }
  }, [onBlur, data]);

  const handleChangeRadio = useCallback((isDailyCompensation: boolean) => {
    if (isDailyCompensation) {
      onChangeData({
        ...data,
        yearlyLumpSum: '',
        regularCompensationBeforeInflation: undefined,
        regularCompensationAfterInflation: undefined,
        isDailyCompensation
      });
    } else {
      onChangeData({
        ...data,
        numberOfConsultantsInOrganization: '',
        dailyCompensationRate: '',
        numberOfDays: '',
        isDailyCompensation
      });
    }
  }, [data]);

  const handleChangeTravelRadio = useCallback((travelCostsType: string) => {
    const newData = {
      ...data,
      estimatedTravelCosts: travelCostsType === 'no' ? 0 : data.estimatedTravelCosts,
      travelCostsType,
      trips: travelCostsType === 'travelCosts' ? data.trips : [],
    };
    if (travelCostsType === 'no') {
      onBlur(undefined, newData);
    }
    onChangeData(newData);
  }, [onBlur, data]);

  const isOrganization = consultant.providersType === 'organization';

  const compensationHelp = year.serialNumber < 2 ? `Inflation is not applied in Year ${year.serialNumber} for any costs, but will be applied in subsequent years` : undefined;
  const nameLabel = isOrganization ? 'Organization name' : 'Individual name';

  const foreignBtnText = useMemo(() => {
    if (data.foreignConsultantInfo?.isContributes || data.foreignConsultantInfo?.isOffers) {
      return `You are eligible to use a foreign consultant`;
    }
    if (data.foreignConsultantInfo) {
      return 'You are not eligible to use a foreign consultant'
    }
    return 'Please select if the consultant is foreign and check to see if you can add a foreign consultant to the grant';
  }, [data]);

  const foreignBtnClassName = useMemo(() => {
    return cx({
      [styles.red]: data.foreignConsultantInfo,
      [styles.green]: data.foreignConsultantInfo?.isContributes || data.foreignConsultantInfo?.isOffers,
    }, styles.foreignBtn)
  }, [data]);

  return (
    <>
      <Form onSubmit={onSubmit} trackExit={trackExit} trackError={trackError}>
        <Field full withHelp>
          <Input name="name" required
                 value={data.name} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({name: event.target.value})}
                 label={nameLabel}
                 help={`If you do not wish to enter the name of the consultant, you may enter a generic name such as “Consultant 1”`}
                 placeholder="Enter a name of individual"
          />
        </Field>

        <Field>
          <Select name="isForeignConsultant" bool options={yesNoOptions}
                  value={data.isForeignConsultant} onChange={(isForeignConsultant) => handleChange({isForeignConsultant})}
                  label="Is this consultant a foreign consultant?" required

          />
          {
            data.isForeignConsultant
            ?
              <Button htmlType="button" type="link"
                      name="isForeignConsultantButton" className={foreignBtnClassName}
                      onClick={() => onChangeShowModal(true)}
              >
                {foreignBtnText}
              </Button>
              : undefined
          }
        </Field>

        <Field last>
          <Radio checked={data.isDailyCompensation} name="isDailyCompensation"
                 label="Enter compensation as a daily amount"
                 onChange={() => handleChangeRadio(true)}
          />
        </Field>
        {
          isOrganization
            ?
            <Field last full>
              <Input name="numberOfConsultantsInOrganization" required={data.isDailyCompensation} type="number"
                     value={data.numberOfConsultantsInOrganization} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({numberOfConsultantsInOrganization: event.target.value})}
                     label="Number of consultants in organization"
                     disabled={!data.isDailyCompensation}
                     placeholder="Enter number of consultants" onBlur={onBlur}
              />
            </Field>
            : null
        }
        <Field>
          <Input name="dailyCompensationRate" required={data.isDailyCompensation}
                 value={data.dailyCompensationRate} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({dailyCompensationRate: event.target.value})}
                 label="Daily compensation rate" money onBlur={onBlur}
                 disabled={!data.isDailyCompensation}
                 placeholder="Enter daily rate"
          />
          <Input name="numberOfDays" required={data.isDailyCompensation}
                 value={data.numberOfDays} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({numberOfDays: event.target.value})}
                 label="Expected number of days of service" onBlur={onBlur}
                 disabled={!data.isDailyCompensation} type="number"
                 placeholder="Enter number of days of service"
          />
        </Field>

        <Field last>
          <Radio checked={data.isDailyCompensation === false} name="isDailyCompensation"
                 label="Enter compensation as a yearly lump sum" bool
                 onChange={() => handleChangeRadio(false)}
          />
        </Field>
        <Field>
          <Input name="yearlyLumpSum" required={!data.isDailyCompensation}
                 value={data.yearlyLumpSum} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({yearlyLumpSum: event.target.value})}
                 label="Yearly lump sum" money onBlur={onBlur}
                 disabled={data.isDailyCompensation}
                 placeholder="Enter yearly lump sum"
          />
        </Field>

        <Field full last>
          <Input name="regularCompensationBeforeInflation" disabled
                 value={data.regularCompensationBeforeInflation} money
                 label={`Total regular compensation before inflation for Year ${year.serialNumber}`}
                 placeholder="Total regular compensation before inflation"
          />
        </Field>
        <Field full withHelp={Boolean(compensationHelp)}>
          <Input name="regularCompensationAfterInflation" disabled help={compensationHelp}
                 value={data.regularCompensationAfterInflation} money
                 label={`Total regular compensation after inflation for Year ${year.serialNumber}`}
                 placeholder="Total regular compensation after inflation"
          />
        </Field>

        <Field full withHelp>
          <Input name="additionalCompensation" help="Additional compensation is compensation for the delivery of a work product. E.g., a report."
                 label="Additional Compensation" money required
                 value={data.additionalCompensation} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({additionalCompensation: event.target.value})}
                 placeholder="Enter additional compensation" onBlur={onBlur}
          />
        </Field>
        <Field full last>
          <Input name="additionalCompensationBeforeInflation" disabled
                 value={data.additionalCompensationBeforeInflation} money
                 label={`Total additional compensation before inflation for Year ${year.serialNumber}`}
                 placeholder="Total additional compensation before inflation"
          />
        </Field>
        <Field full withHelp={Boolean(compensationHelp)}>
          <Input name="additionalCompensationAfterInflation" disabled help={compensationHelp}
                 value={data.additionalCompensationAfterInflation} money
                 label={`Total additional compensation after inflation for Year ${year.serialNumber}`}
                 placeholder="Total additional compensation after inflation"
          />
        </Field>

        <Field full last>
          <RadioGroup name="travelCostsType" options={consultantServiceProviderTravelCostsTypes}
                      label="Travel expenses (if the trips are planned for consultant service)"
                      value={data.travelCostsType}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeTravelRadio(event.target.value)}
          />
        </Field>
        {
          data.travelCostsType === 'travelCosts'
            ?
              <Field>
                <Tags label="Estimated travel costs" options={travels}
                      name="trips" required
                      value={data.trips}
                      onChange={(trips: any[]) => handleChange({trips}, true)}
                />
              </Field>
            :
              <Field full last>
                <Input name="estimatedTravelCosts" required={data.travelCostsType !== 'no'}
                       label="Estimated travel costs" money disabled={data.travelCostsType === 'no'}
                       value={data.travelCostsType === 'no' ? 0 : data.estimatedTravelCosts} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange({estimatedTravelCosts: event.target.value})}
                       placeholder="Enter estimated travel costs" onBlur={onBlur}
                />
              </Field>
        }
        <Field full last>
          <Input name="travelCostsCompensationBeforeInflation" disabled
                 value={data.travelCostsCompensationBeforeInflation} money
                 label={`Total amount for travel before inflation for Year ${year.serialNumber}`}
                 placeholder="Total compensation before inflation"
          />
        </Field>
        <Field full withHelp={Boolean(compensationHelp)}>
          <Input name="travelCostsCompensationAfterInflation" disabled help={compensationHelp}
                 value={data.travelCostsCompensationAfterInflation} money
                 label={`Total amount for travel after inflation for Year ${year.serialNumber}`}
                 placeholder="Total compensation after inflation"
          />
        </Field>

        <Field full last>
          <Input name="compensationBeforeInflation" disabled
                 value={data.compensationBeforeInflation} money
                 label={`Total compensation with travel costs before inflation for Year ${year.serialNumber}`}
                 placeholder="Total compensation (regular and additional) with travel costs before inflation"
          />
        </Field>
        <Field full withHelp={Boolean(compensationHelp)}>
          <Input name="compensationAfterInflation" disabled help={compensationHelp}
                 value={data.compensationAfterInflation} money
                 label={`Total compensation with travel costs after inflation for Year ${year.serialNumber}`}
                 placeholder="Total compensation (regular and additional) with travel costs after inflation"
          />
        </Field>

        <div className={styles.footer}>
          <Button htmlType="submit" loading={isLoading} name={`submit-provider-${provider.id}`}>Save</Button>
        </div>
      </Form>
      <Modal visible={showModal} title="Please add information in this modal window if this consultant is a foreign"
             onCancel={onCloseModal}
      >
        <div>
          <Typo size={16} className={styles.modalText}>The NSF rarely provides funding to a foreign organization. The NSF will consider proposals for cooperative projects involving U.S. and foreign organizations, provided support is requested only for the U.S. portion of the collaborative effort. If you however consider a foreign consultant as a requirement to complete your proposed research, please proceed with the prompts below to outline the necessary information that has to be provided to the NSF</Typo>
          <Form onSubmit={onSubmitModal}>
            <Field full last>
              <Textarea label="Please explain why the support you need doesn’t have an equivalent domestic counterpart"
                        name="needSupportExplanation"
                        value={info.needSupportExplanation}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeInfo({needSupportExplanation: event.target.value})}
              />
            </Field>
            <Field full>
              <Textarea label="Please explain why the support you will receive from the foreign individual or organization can carry out the activity more effectively than a U.S. organization or U.S. individual"
                        name="receivedSupportExplanation"
                        value={info.receivedSupportExplanation}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeInfo({receivedSupportExplanation: event.target.value})}
              />
            </Field>
            <Typo size={16} className={styles.modalText}>
              Please check the boxes next to one or more of the statements below to ensure your eligibility to use a foreign consultant.
              Information regarding the use of foreign consultant services must be included in the project description section of the proposal. The box for "Funding of a Foreign Organization or Foreign Individual" must be checked on the Cover Sheet if the proposal includes funding for a foreign organization or foreign individual.
              One of the check-boxes must be checked below for foreign consultant to be added to the grant.
            </Typo>
            <Field full last>
              <Checkbox name="isContributes"
                        value={info.isContributes}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeInfo({isContributes: event.target.checked})}
                        label="The foreign organization or foreign individual contributes unique expertise, organizational capability, facilities, data resources, and/or acce. The foreign organization or foreign individual contributes unique expertise, organizational capability, facilities, data resources, and/or access to a geographic location not generally available to U.S. investigators (or which would require significant effort or time to duplicate)"
              />
            </Field>
            <Field full last>
              <Checkbox name="isOffers"
                        value={info.isOffers}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeInfo({isOffers: event.target.checked})}
                        label="The foreign organization or foreign individual offers significant science and engineering education, training, or research opportunities to the U.S./or acce. The foreign organization or foreign individual contributes unique expertise, organizational capability, facilities, data resources, and/or access to a geographic location not generally available to U.S. investigators (or which would require significant effort or time to duplicate)"
              />
            </Field>
            <div className={styles.modalFooter}>
              <Button htmlType="button" type="bordered" onClick={onCloseModal} name="cancel-modal">Cancel</Button>
              <Button htmlType="submit" name="submit-modal">Submit form</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default IndividualForm;
