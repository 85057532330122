import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Field from 'components/field';
import {Input, Form, Button, Textarea} from 'components/form'
import styles from './cs.module.css';
import {Payment, PaymentYear} from 'store/grants/otherDirectCosts/Payments/types';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import {getPaymentFromODC} from 'helpers/getFromGrant';
import useIncentivePayments from 'hooks/useIncentivePayments';

type Props = {
  item: PaymentYear;
  payment: Payment;
  grantId: string;
}

const totalCostAfterInflationHelpText = 'Inflation does not apply. You can change it in the Information tab';
const totalCostAfterInflationFirstYearHelpText = 'Inflation is not applied in Year 1 for any costs, but will be' +
  ' applied in subsequent years.'

const PaymentYearForm: FC<Props> = ({item, payment, grantId}) => {
  const [data, onChangeData] = useState<PaymentYear>(item);
  const {
    loading, updatePayment, previewPaymentYear,
    trackError,
    trackExit,
    trackFormStarted
  } = useIncentivePayments({form_page_type: 'Date'});

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    if (data.numberOfPayments !== '' && Number(data.numberOfPayments) > -1) {
      previewPaymentYear(grantId, payment.id, {
        serialNumber: item.serialNumber,
        numberOfPayments: data.numberOfPayments
      }, (response: any) => {
        onChangeData({
          ...data,
          compensationBeforeInflation: response.compensationBeforeInflation,
          totalAmount: response.totalAmount,
          compensationAfterInflation: response.compensationAfterInflation,
        })
      });
    } else {
      onChangeData({
        ...data,
        compensationBeforeInflation: '',
        totalAmount: '',
        compensationAfterInflation: '',
      })
    }
  }, [item, previewPaymentYear, payment.id, grantId, data]);

  const onSubmit = useCallback(() => {
    const newYears = payment.years.map((year: PaymentYear) => {
      if (year.serialNumber === item.serialNumber) {
        return {
          ...year,
          justification: data.justification,
          totalAmount: data.totalAmount,
          numberOfPayments: data.numberOfPayments,
          compensationBeforeInflation: data.compensationBeforeInflation,
          compensationAfterInflation: data.compensationAfterInflation,
        }
      }
      return year;
    });
    updatePayment(grantId, payment.id, {
      years: newYears
    })
  }, [data, item, payment, grantId, updatePayment]);

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field>
        <Input name="totalAmount"
               placeholder="Enter amount" type="number"
               value={data.numberOfPayments} onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeData({
                 ...data,
                 numberOfPayments: event.target.value
               })}
               label={`Number of payments in Year ${item.serialNumber}`} required/>

        <Input name="total"
               placeholder="Total amount of payments"
               value={data.totalAmount} money
               label="Total amount of incentive payments needed per year" disabled readOnly/>

      </Field>
      <Field>
        <Input name="compensationBeforeInflation"
               placeholder="Total cost before inflation"
               value={data.compensationBeforeInflation}
               type="number" money auto disabled readOnly
               label={`Total cost before inflation for Year ${item.serialNumber}`}/>
        <Input name="totalCostAfterInflation"
               placeholder="Total cost after inflation"
               value={data.compensationAfterInflation} auto
               help={
                 !payment.isInflationAppliedEachYear
                   ? totalCostAfterInflationHelpText
                   : item.serialNumber === 1
                     ? totalCostAfterInflationFirstYearHelpText
                     : ""
               }
               disabled readOnly type="number" money
               label={`Total cost after inflation for Year ${item.serialNumber}`}
        />

      </Field>
      <Field last full>
        <Textarea name="justification"
                  label="Justification for this cost"
                  placeholder="Enter a description..."
                  value={data.justification}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeData({
                    ...data,
                    justification: event.target.value
                  })}
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" loading={loading} name="submit">Save</Button>
      </div>
    </Form>
  );
}

const PaymentYearPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {item, index} = getPaymentFromODC(grant, params.paymentId);

  const year = item?.years[Number(params.year)];

  const subtitle = useMemo(() => {
    if (index < 1) return `Incentive Payments.`
    return `Incentive Payments. Payment ${index}. Year ${Number(params.year) + 1}`
  }, [index, params]);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={subtitle}>
      {
        year
          ? <PaymentYearForm grantId={grant.id} payment={item}
                             key={`${grant.id}-odc-payments-${params.serviceId}-${params.year}`} item={year}/>
          : <span/>
      }
    </GrantFormWrapper>
  )
}

export default PaymentYearPage;
