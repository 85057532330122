import { FC, useEffect, useMemo } from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import { useParams } from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import Table, { Cell } from 'components/table';
import usePersonnel, { Totals as TotalsType, Total } from 'hooks/usePersonnel';
import styles from './total.module.css';
import { ListItem } from 'helpers/utils';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {personalTypes} from 'const';
import {FullGrant} from 'store/grants/types';
import {numberToMoney} from 'helpers/numbers';

type Props = {
  totals: TotalsType;
}

const prepareHeaders = (items: Total[]) => {
  const [first] = items;
  const years = first?.years.map((item: ListItem) => item.label);
  return ['Name', ...(years ?? []), 'Total'];
}

const prepareData = (items: Total[]):Array<Cell[]> => {
  return items.map((item: Total) => {
    const yearsValues = item?.years.map((yearItem: ListItem) => <span className={styles.gray}>{numberToMoney(yearItem.value)}</span>);
    const total = item.total || item.totals
    return [
      item.fullName,
      ...(yearsValues ?? []),
      total ? numberToMoney(total) : '-'
    ]
  });
}

const SeniorAcademicTotals:FC<Props> = ({ totals }) => {
  const params:Record<string, string> = useParams();

  const title = useMemo(() => {
    if (params.personnelType === personalTypes.seniorPersonal) return 'Senior personnel';
    if (params.personnelType === personalTypes.postDocPersonnel) return 'Postdoctoral Research Associates';
    return 'Academic Research Associates';
  }, [params]);

  const salaries = useMemo(() => ({
    headers: prepareHeaders(totals.salaries),
    data: prepareData(totals.salaries),
  }), [totals]);

  const fringeBenefits = useMemo(() => ({
    headers: prepareHeaders(totals.fringeBenefits),
    data: prepareData(totals.fringeBenefits),
  }), [totals]);

  const salariesAndFringeBenefits = useMemo(() => ({
    headers: prepareHeaders(totals.salariesAndFringeBenefits),
    data: prepareData(totals.salariesAndFringeBenefits),
  }), [totals]);

  return (
    <GrantFormWrapper title={title} subtitle={'Totals'}>
      <div className={styles.tables}>
        <Table title="Salaries" className={styles.table} data={salaries.data} headers={salaries.headers} />
        <Table title="Fringe Benefits" className={styles.table} data={fringeBenefits.data} headers={fringeBenefits.headers} />
        <Table title="Salaries and Fringe Benefits" className={styles.table} data={salariesAndFringeBenefits.data} headers={salariesAndFringeBenefits.headers} />
      </div>
    </GrantFormWrapper>
  )
}

const OtherPersonnelTotals:FC<Props> = ({ totals }) => {
  const params:Record<string, string> = useParams();

  const title = useMemo(() => {
    if (params.personnelType === personalTypes.union) return 'Union';
    if (params.personnelType === personalTypes.temp) return 'Temp and Casual';
    if (params.personnelType === personalTypes.other) return 'Other Personnel';
    return 'Civil Service';
  }, [params]);

  const salaries = useMemo(() => ({
    headers: prepareHeaders(totals.salaries),
    data: prepareData(totals.salaries),
  }), [totals]);

  const fringeBenefits = useMemo(() => ({
    headers: prepareHeaders(totals.fringeBenefits),
    data: prepareData(totals.fringeBenefits),
  }), [totals]);

  const salariesAndFringeBenefits = useMemo(() => ({
    headers: prepareHeaders(totals.salariesAndFringeBenefits),
    data: prepareData(totals.salariesAndFringeBenefits),
  }), [totals]);

  return (
    <GrantFormWrapper title={title} subtitle={'Totals'}>
      <div className={styles.tables}>
        <Table title="Salaries" className={styles.table} data={salaries.data} headers={salaries.headers} />
        <Table title="Fringe Benefits" className={styles.table} data={fringeBenefits.data} headers={fringeBenefits.headers} />
        <Table title="Salaries and Fringe Benefits" className={styles.table} data={salariesAndFringeBenefits.data} headers={salariesAndFringeBenefits.headers} />
      </div>
    </GrantFormWrapper>
  )
}


type StudentProps = {
  totals: TotalsType;
  grant: FullGrant;
  undergraduated: boolean;
}

const StudentsTotals:FC<StudentProps> = ({ totals, undergraduated, grant }) => {
  const params:Record<string, string> = useParams();

  const title = useMemo(() => {
    if (params.personnelType === personalTypes.graduateStudents) return 'Graduate Students';
    return 'Undergraduate Students';
  }, [params]);

  const stipends = useMemo(() => ({
    headers: prepareHeaders(totals.stipends),
    data: prepareData(totals.stipends),
  }), [totals]);

  const fringeBenefitsOnStipend = useMemo(() => ({
    headers: prepareHeaders(totals.fringeBenefitsOnStipend),
    data: prepareData(totals.fringeBenefitsOnStipend),
  }), [totals]);

  const tuition = useMemo(() => ({
    headers: prepareHeaders(totals.tuition),
    data: prepareData(totals.tuition),
  }), [totals]);

  const fringeBenefitsOnTuition = useMemo(() => ({
    headers: prepareHeaders(totals.fringeBenefitsOnTuition),
    data: prepareData(totals.fringeBenefitsOnTuition),
  }), [totals]);

  const totalAmount = useMemo(() => ({
    headers: prepareHeaders(totals.totalAmount),
    data: prepareData(totals.totalAmount),
  }), [totals]);

  const stipendsDuringAcademicTerms = useMemo(() => ({
    headers: prepareHeaders(totals.stipendsDuringAcademicTerms),
    data: prepareData(totals.stipendsDuringAcademicTerms),
  }), [totals]);

  const stipendsDuringSummer = useMemo(() => ({
    headers: prepareHeaders(totals.stipendsDuringSummer),
    data: prepareData(totals.stipendsDuringSummer),
  }), [totals]);


  const academicYear = useMemo(() => ({
    headers: prepareHeaders(totals.academicYear),
    data: prepareData(totals.academicYear),
  }), [totals]);


  const academicYearAndSummer = useMemo(() => ({
    headers: prepareHeaders(totals.academicYearAndSummer),
    data: prepareData(totals.academicYearAndSummer),
  }), [totals]);


  const summer = useMemo(() => ({
    headers: prepareHeaders(totals.summer),
    data: prepareData(totals.summer),
  }), [totals]);

  const graduated = (<>
    {
      grant.graduateStudents.stipendAwardPeriod === 'academicTerm'
        ?
        <>
          <Table title="Stipends during academic terms" className={styles.table} data={stipendsDuringAcademicTerms.data} headers={stipendsDuringAcademicTerms.headers} />
          <Table title="Stipends during the summer" className={styles.table} data={stipendsDuringSummer.data} headers={stipendsDuringSummer.headers} />
        </>
        :
        <>
          <Table title="Stipends" className={styles.table} data={stipends.data} headers={stipends.headers} />
        </>
    }
    <Table title="Fringe Benefits Based on Total Stipend" className={styles.table} data={fringeBenefitsOnStipend.data} headers={fringeBenefitsOnStipend.headers} />
    <Table title="Tuition" className={styles.table} data={tuition.data} headers={tuition.headers} />
    <Table title="Fringe Benefits based on Tuition" className={styles.table} data={fringeBenefitsOnTuition.data} headers={fringeBenefitsOnTuition.headers} />
    <Table title="Total Amount (Stipends + Tuition + Stipend Based Fringe Benefits)" className={styles.table} data={totalAmount.data} headers={totalAmount.headers} />
  </>);

  const underGraduatedTotals = (<>
    <Table title="Academic year" className={styles.table} data={academicYear.data} headers={academicYear.headers} />
    <Table title="Summer" className={styles.table} data={summer.data} headers={summer.headers} />
    <Table title="Academic year + Summer" className={styles.table} data={academicYearAndSummer.data} headers={academicYearAndSummer.headers} />
  </>);

  return (
    <GrantFormWrapper title={title} subtitle={'Totals'}>
      <div className={styles.tables}>
        {undergraduated ? underGraduatedTotals : graduated}
      </div>
    </GrantFormWrapper>
  )
}

const TotalsPage:FC = () => {
  const { grant } = useGrants();
  const { sidebarWidth } = useUI();
  const { getTotals, loading, totals } = usePersonnel();
  const params:Record<string, string> = useParams();

  useEffect(() => {
    if (grant.id) {
      getTotals(grant.id, params.personnelType)
    }
  }, [grant.id, params.personnelType]);

  const elem = useMemo(() => {
    const key = `${grant.id}-${params.personnelType}-totals`;
    if ([personalTypes.seniorPersonal, personalTypes.postDocPersonnel, personalTypes.academicResearchAssociate].includes(params.personnelType)) return <SeniorAcademicTotals key={key} totals={totals}/>;
    if ([personalTypes.graduateStudents, personalTypes.undergraduateStudents].includes(params.personnelType)) return <StudentsTotals undergraduated={params.personnelType === personalTypes.undergraduateStudents} grant={grant} key={key} totals={totals}/>;
    return <OtherPersonnelTotals key={key} totals={totals}/>
  }, [totals, grant, params])

  return (
    <>
      {elem}
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  )
}

export default TotalsPage;
