import {FC, useState, useCallback} from 'react';
import {times} from 'lodash';
import styles from './alaska.module.css';
import {IEBreakdownSeason, TravelCostData, TravelCostMealExpensesSeason} from 'store/grants/types';
import Typo from 'components/typo';
import {Button} from 'components/form';
import Season from './season';
import useTravel from 'hooks/useTravel';
import {stringOrUndefined} from 'helpers/date';


type Props = {
  cost: TravelCostData;
  travelId: string;
  grantId: string;
}

const IEAlaskaHawaiiBreakdown:FC<Props> = ({ cost, travelId, grantId }) => {
  const { updateTravelCost, previewTravelCost, errors } = useTravel();
  const defaultSeason:IEBreakdownSeason = {
    days: '',
    total: '',
    maxTotal: '',
    isDeparture: false,
    isReturn: false,
    startDate:  undefined,
    serialNumber: 1,
    endDate:  undefined,
    rate: '',
    doBudgetMaxAmount: false,
    maxTotalAdjust: '',
    discount: {
      amount: '',
      threeQuarters: '',
      isStartDateFollowsEndDate: false,
      dailyAmountOfExpenses: '',
      maxAmount: '',
    }
  };

  const [seasons, onChangeSeasons] = useState<IEBreakdownSeason[]>(() => {
    return times(Number(cost.numberOfSeasons || 1), (i: number) => {
      let seasonInCost =  (cost.ieBreakdown.seasons ?? []).find((item: IEBreakdownSeason) => item.serialNumber === i + 1);
      if (seasonInCost) {
        seasonInCost = {
          ...seasonInCost,
          startDate: seasonInCost.startDate ? new Date(seasonInCost.startDate) : null,
          endDate: seasonInCost.endDate ? new Date(seasonInCost.endDate) : null,
        }
      }
      return seasonInCost ?? {
        ...defaultSeason,
        serialNumber: i + 1
      };
    })
  });

  const zero = Number(cost.numberOfSeasons) < 1;

  const onSubmitOne = useCallback((season: IEBreakdownSeason, index: number | string) => {
    if (cost) {
      let submitData = (cost.ieBreakdown.seasons ?? []).map((item: IEBreakdownSeason, idx: number) => {
        if (item.serialNumber === index) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: IEBreakdownSeason) => item.serialNumber === index)
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: IEBreakdownSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }));
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        ieBreakdown: {
          seasons: submitData
        }
      })
    }
  }, [cost, zero, grantId, travelId, updateTravelCost]);

  const onClear = useCallback((idx: number | string) => {
    onSubmitOne(defaultSeason, idx);
    const newSeasons = seasons.map((item: IEBreakdownSeason) => {
      if (item.serialNumber === idx) {
        return {
          ...defaultSeason,
          serialNumber: idx
        }
      }
      return item;
    });
    onChangeSeasons(newSeasons);
  }, [defaultSeason, seasons, onSubmitOne])

  const onBlur = useCallback((event: any, season: IEBreakdownSeason, index: number | string) => {
    if (cost) {
      let canMakeRequest = season.startDate && season.endDate;
      if (zero) canMakeRequest = Boolean(season.rate && season.days);
      if (season.doBudgetMaxAmount) canMakeRequest = canMakeRequest && !!season.maxTotalAdjust;
      if (!canMakeRequest) return;
      let submitData = (cost.ieBreakdown.seasons ?? [])?.map((item: IEBreakdownSeason, idx: number) => {
        if (index === item.serialNumber) return {...item, ...season};
        return item;
      });
      const seasonInSubmitData = submitData.find((item: IEBreakdownSeason) => item.serialNumber === index)
      if (!seasonInSubmitData) {
        submitData = [...submitData, season];
      }
      submitData = submitData.map((item: IEBreakdownSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }));
      previewTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        ieBreakdown: {
          seasons: submitData
        }
      }, (response: any) => {
        const responseSeasons:IEBreakdownSeason[] = response?.ieBreakdown.seasons ?? [];
        const newSeasons = seasons.map((item: IEBreakdownSeason, idx: number) => {
          const seasonFromResponse = responseSeasons.find((item: IEBreakdownSeason) => item.serialNumber === index);
          if (index === item.serialNumber) {
            return {
              ...item,
              ...(seasonFromResponse ?? {}),
              startDate: seasonFromResponse?.startDate ? new Date(seasonFromResponse.startDate) : item.startDate,
              endDate: seasonFromResponse?.endDate ? new Date(seasonFromResponse.endDate) : item.endDate,
            }
          }
          return item;
        });
        onChangeSeasons(newSeasons);
      })
    }
  }, [cost, grantId, zero, seasons, onChangeSeasons, travelId, previewTravelCost]);

  const handleChangeSeason = useCallback((idx: number | string) => (field: Record<string, any>, blur?: boolean) => {
    const newSeasons = seasons.map((item: IEBreakdownSeason) => {
      if (item.serialNumber === idx) return {...item, ...field};
      return item;
    });
    onChangeSeasons(newSeasons);
    const season = newSeasons.find((item: IEBreakdownSeason) => item.serialNumber === idx);
    if (blur && season) {
      onBlur(undefined, season, Number(idx));
    }
  }, [seasons, onBlur]);

  const saveAll = useCallback(() => {
    if (cost) {
      const submitData = seasons.map((item: IEBreakdownSeason) => ({
        ...item,
        days: zero ? item.days : undefined,
        maxTotalAdjust: item.maxTotalAdjust === '' ? undefined : item.maxTotalAdjust,
        startDate: stringOrUndefined(item.startDate),
        endDate: stringOrUndefined(item.endDate),
      }))
      updateTravelCost(grantId, travelId, cost.id, {
        kind: cost.kind,
        numberOfSeasons: cost.numberOfSeasons,
        ieBreakdown: {
          seasons: submitData
        }
      })
    }
  }, [cost, grantId, zero, seasons, travelId, updateTravelCost]);

  return (
    <div>
      {
        zero
          ? null
          :
            <>
              <Typo className={styles.text}>If your trip extends to more than one season, please split your travel dates by
                season.</Typo>
              <Typo className={styles.text}>For example, if the season changes in the middle of your trip, please provide us
                with two date ranges. First one: from the starting date of your trip to the end date of the first season. Second
                one: from the first day of the second season until the last day of your trip. If your trip extends across more
                than 2 seasons, split your travel dates analogically into 3, 4, etc. date ranges.</Typo>
              <Typo className={styles.text}>If you will be staying on a military base, please check to see if the "Onbase
                incidental rate" of $3.50 per day applies to you.</Typo>
            </>
      }
      <div className={styles.seasons}>
        {seasons.map((item: IEBreakdownSeason, idx: number) => (
          <Season zero={zero} item={item} onChange={handleChangeSeason(item.serialNumber)} key={idx} idx={idx}
                  onSubmit={onSubmitOne} onBlur={onBlur} errors={errors}
                  last={idx === seasons.length - 1}
                  first={idx === 0}
                  onClear={onClear}
          />
        ))}
      </div>
      {zero || seasons.length < 2
        ? null
        :
          <div className={styles.footer}>
            <Button name="submit-all-seasons" onClick={saveAll}>Save all</Button>
          </div>
      }
    </div>
  );
}

export default IEAlaskaHawaiiBreakdown;
