import { createAction } from 'helpers/actions';

export const setListGrants = createAction('grants/set_list');
export const clearAll = createAction('grants/clear');
export const clearGrantsAction = createAction('grants/clear_grants');
export const addNewGrantToList = createAction('grants/add_one_to_list');
export const addNewGrantOnDuplicateToList = createAction('grants/add_one_on_duplicate_to_list');
export const setGrantsStats = createAction('grants/set_grants_stats');
export const setOneGrant = createAction('grants/set_one_grant');
export const removeGrantAction = createAction('grants/remove_grant');
export const updateGrantInListAction = createAction('grants/update_in_list');
export const clearOneGrant = createAction('grants/clear_one_grant');
export const addSideGrant = createAction('grants/add_side_grant');
export const setEditableGrant = createAction('grants/set_editable_grant');
export const clearEditableGrant = createAction('grants/clear_editable_grant');
export const setGrantActiveId = createAction('grants/set_grant_active_id');
export const clearGrantActiveId = createAction('grants/clear_grant_active_id');
export const setGrantsDashboard = createAction('grants/set_dashboard');
export const setPrimaryInvestigatorToStatGrant = createAction('grants/set_primary_investigator_to_stat_grant');
export const addEquipToGrantAction = createAction('grants/add_equip');
export const addTravelToGrant = createAction('grants/add_travel_to_grant');
export const addTransportationMethodAction = createAction('grants/add_transportation_method_to_travel');
export const addTravelCost = createAction('grants/add_travel_cost');
export const addGrantEvent = createAction('grants/add_grant_event');
export const updateGrantEvent = createAction('grants/update_grant_event');
export const deleteGrantEvent = createAction('grants/delete_grant_event');
export const addGrantEventParticipant = createAction('grants/add_grant_event_participant');
export const updateGrantEventParticipant = createAction('grants/update_grant_event_participant');
export const deleteGrantEventParticipant = createAction('grants/delete_grant_event_participant');
export const removeTravelCost = createAction('grants/remove_travel_cost');
export const updateTravelCost = createAction('grants/update_travel_cost');
export const updateTravelStatusAction = createAction('grants/updateTravelStatus');
export const setSubAwardScheme = createAction('grants/set_subaward_scheme');
export const setGrantsRates = createAction('grants/set_grant_rates');
export const setGrantsRatesPreview = createAction('grants/set_grant_rates_preview');
export const addGrantSidebarTotals = createAction('grants/add_grant_sidebar_totals');

