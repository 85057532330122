import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {personalTypes} from 'const';
import GraduatedStudentsCompensation from './graduatedStudents';
import PostDocCompensation from './postDoc';
import SeniorOrAcademicPersonCompensation from './seniorOrAcademic';
import UnGraduatedStudentsCompensation from './unGraduatedStudents';
import OtherCompensation from './other';


const CompensationPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const key = `${grant.id}-${params.personnelType}-${params.personId || ''}-${params.year}`
  if (params.personnelType === personalTypes.postDocPersonnel) return <PostDocCompensation key={key}/>
  if (params.personnelType === personalTypes.graduateStudents) return <GraduatedStudentsCompensation key={key}/>
  if (params.personnelType === personalTypes.undergraduateStudents) return <UnGraduatedStudentsCompensation key={key}/>
  if ([personalTypes.seniorPersonal, personalTypes.academicResearchAssociate].includes(params.personnelType)) return <SeniorOrAcademicPersonCompensation key={key}/>;
  return <OtherCompensation key={key}/>
}

export default CompensationPage;
