import {FC, useCallback, useMemo} from 'react';
import Typo from 'components/typo';
import styles from './cs.module.css';
import useUrl from 'hooks/useUrl';
import {ComputerService, ComputerServiceYear} from 'store/grants/otherDirectCosts/ComputerService/types';
import useCS from 'hooks/useCS';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
import dayjs from 'dayjs';
import CreateBtn from 'pages/grant/paper/card/create';
import {FullGrant} from 'store/grants/types';

type Props = {
  side: FullGrant;
}


const OtherCostCS:FC<Props> = ({ side }) => {
  const url = useUrl();
  const { loading, deleteService, createService } = useCS();

  const handleDeleteService = useCallback((id?: string) => {
    if (id) deleteService(side.id, id, url.categoryId === id)
  }, [deleteService, url, side]);

  const list = useMemo(() => {
    return side.otherDirectCosts.computerServices.services.map((item: ComputerService) => {
      const links = [
        {
          label: 'Service info',
          to: `/grants/${side.id}/otherDC/computer-services/${item.id}/edit`,
          active: url.categoryId === item.id && url.section === 'edit'
        },
        ...item.years.map((yearItem: ComputerServiceYear, idx: number) => ({
          label: `${dayjs(yearItem.year[0]).format('MM/DD/YYYY')} - ${dayjs(yearItem.year[1]).format('MM/DD/YYYY')}`,
          to: `/grants/${side.id}/otherDC/computer-services/${item.id}/year/${idx}`,
          active: url.categoryId === item.id && url.section === 'year' && url.sectionId === `${idx}`,
          isDisabled: !item.name
        }))
      ];

      return (
        <Item
          title={item.name || 'Untitled service'}
          onDelete={() => handleDeleteService(item.id)}
          links={links}
          key={item.id}
          active={url.categoryId === item.id}
        />
      )
    })
  }, [side, handleDeleteService, url]);

  return (
    <>
      <Anchor thin active={url.category === 'otherDC' && url.subCategory === 'computer-services' && url.categoryId === 'general-info'}
              to={`/grants/${side.id}/otherDC/computer-services/general-info`}
      >
        General info
      </Anchor>
      {list.length > 0
        ? list
        : <Typo className={styles.placeholder} size={14} type="div">No computer services have been created yet</Typo>
      }
      {list.length > 0 ?
        <TotalAnchor total={side.otherDirectCosts.computerServicesTotalAmount} active={url.categoryId === 'totals' && url.subCategory === 'computer-services'} to={`/grants/${side.id}/otherDC/computer-services/totals`}>
          Totals
        </TotalAnchor>
        : null
      }
      <CreateBtn loading={loading} onClick={() => createService(side.id)}
                 when={side.otherDirectCosts.computerServices.isChargedAsDirectCost === undefined}
                 name="add-grant-computer-service" side={side}
                 tip="To add a service, you must save general info first"
      >
        Add service
      </CreateBtn>
    </>
  );
}

export default OtherCostCS;
