import axios, { AxiosInstance } from 'axios';
import { tokenKey } from 'const';

const token = localStorage.getItem(tokenKey);

const instance:AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v1',
  timeout: 120000,
  headers: {
    'Authorization': token ? `Bearer ${token}` : null
  }
});

export const downloadClient:AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v1',
  timeout: 360000,
  headers: {
    'Authorization': token ? `Bearer ${token}` : null
  }
});

export const clientV2:AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v2',
  timeout: 60000,
  headers: {
    'Authorization': token ? `Bearer ${token}` : null
  }
});

export default instance;
