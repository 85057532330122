import {FC, useCallback, useEffect, useState} from 'react';
import useGrants from 'hooks/useGrants';
import {useHistory, useParams} from 'react-router-dom';
import usePersonnel from 'hooks/usePersonnel';
import PostDoctorPersonForm, {PostDoctoralData} from 'containers/forms/personForm/postdoctor';
import {personalTypes} from 'const';
import {confirm} from 'components/confirmation';
import styles from './editPerson.module.css';
import Typo from 'components/typo';
import {Toggler, Form} from 'components/form';
import Help from 'components/help';
import {getPostDocPersonFromGrant} from 'helpers/getFromGrant';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';

const CreateEditPostDoctoral: FC = () => {
  const {grant} = useGrants();
  const history = useHistory();
  const params: Record<string, string> = useParams();

  const {
    updatePostDoctoralPersonnel,
    errors,
    loading,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({
    form_page_type: 'Position Info'
  });
  const {person, index} = getPostDocPersonFromGrant(grant, params.personId);

  const [data, onChangeData] = useState<PostDoctoralData>({
    positionTitle: person?.positionTitle ?? '',
    personsFringeBenefit: person?.personsFringeBenefit ? percentToFixed(person.personsFringeBenefit) : '',
    isAccountOwner: Boolean(person?.isAccountOwner),
    responsibilities: person?.responsibilities ?? '',
    currentBaseSalary: person?.currentBaseSalary ?? '',
  });

  const onDuplicateFromProfile = useCallback(() => {
    const {author} = grant;
    const ownerIsStudent = [
      'graduateStudents',
      'undergraduateStudents'
    ].includes(author.personalReference?.kind as string);
    const ownerIsPerson = (author.personalReference && author.personalReference.id !== params.personId);
    if (author.personalReference !== undefined && (ownerIsStudent || ownerIsPerson)) {
      const personnelType = personalTypes[author.personalReference.kind];
      let link = `/grants/${grant.id}/personnel/${personnelType}/${author.personalReference?.id}/edit`;
      if (ownerIsStudent) {
        link = `/grants/${grant.id}/personnel/${personnelType}/edit`
      }
      confirm({
        text: 'Another person/personnel type is marked as Account owner. Do you want to remove the mark?',
        plain: true,
        onConfirm: () => {
          history.push(link)
        },
        onCancel: () => onChangeData({
          ...data,
          isAccountOwner: false
        })
      })
      return;
    }
    onChangeData({
      ...data,
      currentBaseSalary: grant.author.yearSalary,
      positionTitle: grant.author.positionTitle,
      isAccountOwner: true
    });

  }, [params, data, history, grant]);

  const onRemoveDuplicateFromProfile = useCallback(() => {
    onChangeData({
      ...data,
      positionTitle: '',
      currentBaseSalary: '',
      isAccountOwner: false
    });
  }, [data]);

  const onChange = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    const submitData = {
      ...data,
      personsFringeBenefit: Number(data.personsFringeBenefit) / 100
    }
    updatePostDoctoralPersonnel(grant.id, params.personId, submitData);
  }, [data, grant, params, updatePostDoctoralPersonnel]);

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GrantFormWrapper title="Postdoctoral Research Associate" subtitle={(
      <div className={styles.subtitleWrapper}>
        <Typo type="h3" bold className={styles.title}>
          {`Position ${index < 1 ? grant.postDocPersonnel.length + 1 : index}`}
        </Typo>
        <div className={styles.btnWrapper}>
          <Form>
            <Toggler
              label="Account owner is a postdoc"
              name="isAccountOwner"
              green
              className={styles.toggler}
              checked={data.isAccountOwner}
              onChange={data.isAccountOwner ? onRemoveDuplicateFromProfile : onDuplicateFromProfile}
            />
          </Form>
          <Help contentClassName={styles.help}>
            Please use this button if you are the account owner. This is necessary
            to display the “Account owner salary budget” on the Dashboard. This button can only be used once for all
            types of personnel.
          </Help>
        </div>
      </div>
    )}>
      <PostDoctorPersonForm
        edit={Boolean(params.personId)}
        errors={errors}
        loading={loading}
        data={data}
        onSubmit={onSubmit}
        onChange={onChange}
        trackError={trackError}
        trackExit={trackExit}
      />
    </GrantFormWrapper>
  )
}

export default CreateEditPostDoctoral;
