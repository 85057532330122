import {FC, useCallback, useMemo, useState} from 'react';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import usePersonnel from 'hooks/usePersonnel';
import * as T from 'hooks/requestTypes';
import OtherPersonForm from 'containers/forms/personForm/civil';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {
  getCivilServiceGrant,
  OtherPersonFieldForSearch
} from 'helpers/getFromGrant';
import {personalTypes, personnelFieldInGrant} from 'const';
import {percentToFixed} from 'helpers/numbers';


const CreateEditOtherPerson: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {
    addOrEditOtherPersonnel,
    errors, loading,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({
    form_page_type: 'Position Info',
  });

  const {
    person,
    count,
    index
  } = getCivilServiceGrant(grant, personnelFieldInGrant[params.personnelType] as OtherPersonFieldForSearch, params.personId);

  const [data, onChangeData] = useState<T.OtherPerson>({
    positionTitle: person?.positionTitle ?? '',
    fringeBenefit: person?.fringeBenefit ? percentToFixed(person.fringeBenefit) : undefined,
    city: person?.city ?? '',
    state: person?.state ?? '',
    jobCategory: person?.jobCategory ?? '',
    country: person?.country ?? 'US',
    zipCode: person?.zipCode ?? '',
  });

  const isEdit = useMemo(() => Boolean(params.personId), [params]);

  const onChange = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    const requestData = {
      fringeBenefit: data.fringeBenefit ? Number(data.fringeBenefit) / 100 : undefined,
      positionTitle: data.positionTitle,
      jobCategory: data.jobCategory,
      country: data.country,
      city: data.city,
      state: data.country === 'US' ? data.state : '',
      zipCode: data.country === 'US' ? data.zipCode : ''
    }
    addOrEditOtherPersonnel(grant.id, params.personnelType, requestData, params.personId);
  }, [data, addOrEditOtherPersonnel, grant, params]);

  const title = useMemo(() => {
    if (params.personnelType === personalTypes.union) return 'Union';
    if (params.personnelType === personalTypes.temp) return 'Temp and Casual';
    if (params.personnelType === personalTypes.other) return 'Other Personnel';
    return 'Civil Service';
  }, [params]);

  return (
    <GrantFormWrapper title={title} subtitle={`Position ${index > 0 ? index : count + 1}`}>
      <OtherPersonForm
        type={params.personnelType}
        edit={isEdit}
        errors={errors}
        loading={loading}
        data={data}
        onSubmit={onSubmit}
        onChange={onChange}
        trackError={trackError}
        trackExit={trackExit}
        trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  )
}

export default CreateEditOtherPerson;
