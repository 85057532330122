import {FC, useEffect, useMemo} from 'react';
import styles from './totals.module.css';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import {ListItem} from 'helpers/utils';
import Table, {Cell} from 'components/table';
import { getTravelFromGrant } from 'helpers/getFromGrant';
import {Travel, Total, TravelCostsTotals} from 'store/grants/types';
import Spinner from 'components/spinner';
import useUI from 'hooks/useUI';
import {numberToMoney} from 'helpers/numbers';
import useTravel from '../../../../../hooks/useTravel';

const prepareHeaders = (years: ListItem[] = []) => {
  const yearsLabels = years.map((item: ListItem) => item.label);
  return [
    'Travel costs',
    'Expense name',
    ...(yearsLabels),
    'Total'
  ];
}

const prepareData = (items: Total[] = []):Array<Cell[]> => {
  return items.map((item: Total) => {
    const yearsValues = item.years.map((year: ListItem) => {
      return <span className={styles.gray}>{numberToMoney(year.value)}</span>
    });
    const total = item.total || item.totals;
    return [
      item.kind,
      item.nameOfTrip,
      ...(yearsValues ?? []),
      total ? numberToMoney(total) : '-'
    ];
  });
}


type TotalsWithTitle = {
  title: string;
  headers: string[];
  data: Array<Cell[]>;
}

type Props = {
  totals: TravelCostsTotals;
}

const Totals:FC<Props> = ({ totals }) => {
  const list = useMemo(() => {
    return [
      {
        title: 'Domestic Travel costs',
        headers: prepareHeaders(totals.domestic[0]?.years ?? []),
        data: prepareData(totals.domestic)
      },
      {
        title: 'Foreign Travel costs',
        headers: prepareHeaders(totals.foreign[0]?.years ?? []),
        data: prepareData(totals.foreign)
      },
      {
        title: 'Domestic+Foreign. Travel costs',
        headers: prepareHeaders(totals.domesticAndForeign[0]?.years ?? []),
        data: prepareData(totals.domesticAndForeign)
      }
    ]
  }, [totals]);

  return (
    <div className={styles.tables}>
      <div className={styles.tables}>
        {list.map((table: TotalsWithTitle, idx: number) => (
          <Table title={table.title} key={idx} className={styles.table} data={table.data} headers={table.headers} />
        ))}
      </div>
    </div>
  );
}

const TotalsPage:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const { getTravelCostTotals, costTotals, loading } = useTravel();
  const key = `${grant.id}-${params.travelId}-totals`;
  const { sidebarWidth } = useUI();

  useEffect(() => {
    if (params.id && params.travelId) getTravelCostTotals(params.id, params.travelId);
  }, [params.travelId]);

  return (
    <>
      <GrantFormWrapper key={key} title="Travel costs" subtitle="Totals">
        <Totals totals={costTotals} />
      </GrantFormWrapper>
      {loading ? <Spinner className={styles.spinner} style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}} full /> : null}
    </>
  )
}

export default TotalsPage;
