import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import usePublications from 'hooks/usePublications';
import useGrants from 'hooks/useGrants';
import useUI from 'hooks/useUI';
// helpers
import {getPublicationUnitFromGrant} from 'helpers/getFromGrant';
// types
import {PublicationsUnit} from 'store/grants/otherDirectCosts/Publications/types';
// components
import {Button, Form, Input} from 'components/form';
import Field from 'components/field';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// styles
import styles from '../publications.module.css';

type FormProps = {
  item: PublicationsUnit;
  grantId: string;
};

const PublicationsUnitForm = ({item, grantId}: FormProps) => {
  const [data, onChangeData] = useState<PublicationsUnit>(item);
  const {
    isLoading, updateUnit,
    trackError,
    trackExit,
    trackFormStarted
  } = usePublications({
    form_page_type: 'Unit info'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {confirmWithHide} = useUI();

  const onSubmit = useCallback(() => {
    const yearsAfterInflationSum = item!.years.reduce((acc, cur) => {
      return acc + Number(cur.compensationAfterInflation)
    }, 0)
    const shouldRecalculate = yearsAfterInflationSum > 0;

    confirmWithHide({
      title: 'Compensation will be recalculated',
      text: 'The total cost before and after inflation for all years will be recalculated based on the new cost',
      type: 'warning',
      icon: 'alert-hexagon',
      when: shouldRecalculate && data.currentCost !== item?.currentCost,
      okText: 'Recalculate',
      id: `odc-materials-and-supplies-update-${grantId}`,
      onConfirm: () => updateUnit(grantId, item.id, {
        name: data.name,
        currentCost: Number(data.currentCost),
      } as unknown as PublicationsUnit)
    });
  }, [data, confirmWithHide, grantId, item, updateUnit]);

  return (
    <Form prompt onSubmit={onSubmit}
          trackError={trackError}
          trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field last>
        <Input
          name="name"
          placeholder="Enter unit name"
          value={data.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeData({...data, name: event.target.value})
          }
          label="Unit name"
          required
        />
        <Input
          name="cost"
          placeholder="Enter current cost of unit"
          value={data.currentCost}
          type="number"
          money
          min={0}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeData({...data, currentCost: event.target.value})
          }
          label="Current cost of unit"
          required
        />
      </Field>
      <div className={styles.footer}>
        <Button htmlType="submit" name="submit" loading={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

const PublicationsUnitPage = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {item, index} = getPublicationUnitFromGrant(grant, params.unitId);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={`Publications. Unit ${index > 0 ? index : ''}`}>
      {item ? (
        <PublicationsUnitForm
          grantId={grant.id}
          key={`${grant.id}-publications-${params.unitId}`}
          item={item}
        />
      ) : (
        <span/>
      )}
    </GrantFormWrapper>
  );
};

export default PublicationsUnitPage;
