import {FC} from 'react';
import {TransportationMethod as TransportationMethodType} from 'store/grants/types';
import Item from 'pages/grant/paper/card/item';
import {capitalize} from 'lodash';

type Props = {
  item: TransportationMethodType;
  linkTo: string;
  isActive: boolean;
  showMethod?: boolean;
  handleRemoveTransportationMethod: (id: string) => void;
}

const TransportationMethod: FC<Props> = ({
  item,
  linkTo,
  isActive,
  handleRemoveTransportationMethod
}) => {

  return (
    <Item to={linkTo}
          title={item.nameExpense}
          role={capitalize(item.method)}
          active={isActive}
          onDelete={() => handleRemoveTransportationMethod(item.id)}
    />
  );
}

export default TransportationMethod;
