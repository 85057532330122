import React, {ChangeEvent, FC, useCallback} from 'react';
import Field from 'components/field';
import {Input, Select} from 'components/form';
import useEnums from 'hooks/useEnums';
import {CommonProps} from './index';
import Typo from 'components/typo';
import styles from './forms.module.css';


export const Location:FC<CommonProps> = ({ inter, data, hideText, optional, errors, onChange }) => {
  const { fetchZIP, countries } = useEnums();

  const onBlurZip = useCallback(() => {
    fetchZIP(data.zip, ({ state, city }) => {
      onChange({
        state,
        city
      });
    })
  }, [onChange, fetchZIP, data])

  return (
    <>
      {hideText
        ? null
        :
          <Typo className={styles.text} >
            Please enter the information you used for your search so we can keep track of your rates by the name of the
            location and time frame that your rate will apply to.
          </Typo>
      }
      <Field>
        {inter
          ?
            <Select name="country" options={countries} value={data.country}
                    onChange={(country) => onChange({country})} label="Country"
                    errors={errors.country} placeholder="Select an option"
            />
          :
          [
            <Input name="zip" errors={errors.zip} onBlur={onBlurZip}
                   label={optional ? 'ZIP code' : 'ZIP code*'} placeholder="Enter ZIP code"
                   value={data.zip} key="zip-0"
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({zip: event.target.value})}
            />,
            <Input name="state" errors={errors.state} label={optional ? 'State' : 'State*'}
                   placeholder="Enter state"
                   value={data.state} key="state-1"
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({state: event.target.value})}
            />
          ]
        }
        <Input name="city" errors={errors.city} label={optional ? 'City' : 'City*'}
               placeholder="Enter city"
               value={data.city} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({city: event.target.value})}
        />
      </Field>
    </>
  );
}
