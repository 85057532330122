import I from 'seamless-immutable';
import { UIState } from './types';

const storedSidebarWidth = localStorage.getItem('bm-sidebar-width');
const storedSidebarMinimize = localStorage.getItem('bm-sidebar-minimized');
const storedHiddenModals = localStorage.getItem('bm-hidden-modals');
const storedSmallBudgetBar = localStorage.getItem('bm-small-bb');
const storedHidePrompt = localStorage.getItem('bm-hide-prompt');
const storedHiddenRatesInBar = localStorage.getItem('bm-stored-hr');
const defaultHiddenModals:string[] = storedHiddenModals ? JSON.parse(storedHiddenModals) : [];
const defaultSidebarWidth = storedSidebarWidth ? Number(storedSidebarWidth) : 448;
const defaultSmallBudgetBar = storedSmallBudgetBar ? Boolean(Number(storedSmallBudgetBar)) : true;
const defaultHidePrompt = storedHidePrompt ? Boolean(Number(storedHidePrompt)) : false;
const defaultHiddenRatesInBar = storedHiddenRatesInBar ? JSON.parse(storedHiddenRatesInBar) : [];

export const uiStateAdapter = ():UIState => I({
 minimizeSidebar: Boolean(storedSidebarMinimize),
 sidebarWidth: defaultSidebarWidth,
 infoSidebar: false,
 hidePrompt: defaultHidePrompt,
 loading: false,
 showPaywall: false,
 showSettingsModal: false,
 showNavigationMessage: false,
 smallBudgetBar: defaultSmallBudgetBar,
 openedSteps: [],
 hiddenRatesInBar: defaultHiddenRatesInBar,
 hiddenModals: defaultHiddenModals,
 prevUrl: '/',
 mtdcGrantId: '',
 finalGrantId: '',
 showDisplayMessage: true,
 icGrantId: '',
});
export const uiClearedState:UIState = I({
 minimizeSidebar: false,
 sidebarWidth: 448,
 infoSidebar: false,
 hidePrompt: false,
 showSettingsModal: false,
 showPaywall: false,
 showNavigationMessage: false,
 smallBudgetBar: true,
 loading: false,
 hiddenModals: [],
 hiddenRatesInBar: [],
 openedSteps: [],
 prevUrl: '/',
 mtdcGrantId: '',
 finalGrantId: '',
 showDisplayMessage: true,
 icGrantId: '',
});
