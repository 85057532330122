import { FC } from 'react';
import { Route } from 'react-router-dom';
import ComputerServicesInfo from './info';
import ComputerServiceInfoPage from './service';
import ComputerServiceYearPage from './service/year';
import ComputerServicesTotals from './totals';

const ComputerServicesIndex:FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/computer-services/general-info"
        exact
        component={ComputerServicesInfo}
      />
      <Route
        path="/grants/:id/otherDC/computer-services/:serviceId/edit"
        exact
        component={ComputerServiceInfoPage}
      />
      <Route
        path="/grants/:id/otherDC/computer-services/:serviceId/year/:year"
        exact
        component={ComputerServiceYearPage}
      />
      <Route
        path="/grants/:id/otherDC/computer-services/totals"
        exact
        component={ComputerServicesTotals}
      />
    </>
  )
}

export default ComputerServicesIndex;
