import { FC } from 'react';
import Carousel from 'react-multi-carousel';
import cx from 'classnames';
import 'react-multi-carousel/lib/styles.css';
import Icon from 'components/icon';
import styles from './slider.module.css';

type Props = {
  children: any[] | any;
  per: number;
  className?: string;
}

type CustomArrowsProps = {
  previous: () => void;
  next: () => void;
}

const CustomArrows:FC<CustomArrowsProps> = (props) => {
  const {previous, next} = props;
  return (
    <div className={styles.controls}>
      <button className={styles.control} onClick={previous}>
        <Icon icon="chevron-left" className={styles.controlIcon}/>
      </button>
      <button className={styles.control} onClick={next}>
        <Icon icon="chevron-right" className={styles.controlIcon}/>
      </button>
    </div>
  )
};

const Slider:FC<Props> = ({ per, className, children }) => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1100
      },
      items: per,
      partialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1,
      partialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: {
        max: 1099,
        min: 464
      },
      items: per - 1,
      partialVisibilityGutter: 0
    }
  };


  return (
    <div className={cx(styles.wrapper, className)}>
      <Carousel
        additionalTransfrom={0}
        autoPlay={false}
        arrows={false}
        centerMode={false}
        className={styles.slider}
        focusOnSelect={false}
        infinite//@ts-ignore
        customButtonGroup={<CustomArrows/>}
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        slidesToSlide={1}
        swipeable={false}
      >
        {children}
      </Carousel>
    </div>
  )
}

export default Slider;
