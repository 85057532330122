import { FC, ChangeEvent, ReactElement } from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import { findInputError, RHErrors } from 'helpers/errors';
import { useFormContext } from 'react-hook-form';


type Props = {
  name: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  placeholder?: string;
  errors?: string[];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helpInLabel?: boolean;
  maxLength?: number;
  cols?: number;
  rows?: number;
  hint?: string;
  help?: ReactElement | string;
  minLength?: number;
  inputClassName?: string;
  className?: string;
}

const Textarea:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    placeholder = 'Enter a description...', className, errors = [], required, readOnly,
    disabled, maxLength = 500, cols, rows,
    minLength, inputClassName, hint,
    help, helpInLabel
  } = props;
  const {
    register,
    formState,
  } = useFormContext();

  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;

  const validation = register(name,
    {
      required: {value: Boolean(required), message: 'Field is required'},
      onChange: disabled ? () => {} : onChange
    })
  return (
    <Field help={help} helpInLabel={helpInLabel} required={required} className={className} label={label} hint={hint} errors={errorsList} name={name}>
      <textarea
        className={cx(styles.input, styles.textarea, inputClassName, {[styles.hasError]: invalid})}
        value={value}
        required={required}
        readOnly={readOnly}
        cypress-id={`${name}-textarea`}
        disabled={disabled}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        cols={cols}
        rows={rows}
        {...validation}
      />
    </Field>
  )
}

export default Textarea;
