import {FC, useCallback, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
import useOtherExpenses from 'hooks/useOtherExpenses';
// types
import {OtherExpenseType, OtherExpenseYearType} from 'store/grants/otherDirectCosts/OtherExpenses/types';
// components
import Typo from 'components/typo';
import CreateBtn from 'pages/grant/paper/card/create';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';
// styles
import styles from '../otherDirectCosts.module.css';
import dayjs from 'dayjs';
import {FullGrant} from 'store/grants/types';

type Props = {
  side: FullGrant;
}


const Other:FC<Props> = ({ side }) => {
  const url = useUrl();
  const { isLoading, createExpense, deleteExpense } = useOtherExpenses();

  const isGeneralInfoFolderActive = url.subCategory === 'otherExpenses' && url.categoryId === 'general-info';
  const isTotalFolderActive = url.subCategory === 'otherExpenses' && url.categoryId === 'totals';

  const handleDeleteExpense = useCallback(
    (id?: string) => {
      if (id) deleteExpense(side.id, id, url.categoryId === id);
    },
    [deleteExpense, url, side],
  );

  const list = useMemo(() => {
    return side.otherDirectCosts.allOtherCosts.expenses.map((item: OtherExpenseType) => {
      const links = [
        {
          label: 'Expense info',
          to: `/grants/${side.id}/otherDC/otherExpenses/${item.id}/edit`,
          active: url.categoryId === item.id && url.section === 'edit'
        },
        ...item.years.map((yearItem: OtherExpenseYearType, idx: number) => ({
          label: `${dayjs(yearItem.year[0]).format('MM/DD/YYYY')} - ${dayjs(yearItem.year[1]).format('MM/DD/YYYY')}`,
          to: `/grants/${side.id}/otherDC/otherExpenses/${item.id}/year/${idx}`,
          active: url.categoryId === item.id && url.section === 'year' && url.sectionId === `${idx}`,
          isDisabled: !item.name || typeof item.isInflationApplied !== 'boolean',
        }))
      ];
      return (
        <Item
          title={item.name || 'Untitled expense'}
          onDelete={() => handleDeleteExpense(item.id)}
          links={links}
          key={item.id}
          active={url.categoryId === item.id}
        />);
    });
  }, [side, handleDeleteExpense, url]);

  return (
    <>
      <Anchor thin to={`/grants/${side.id}/otherDC/otherExpenses/general-info`} active={isGeneralInfoFolderActive}>
        General info
      </Anchor>

      {list.length > 0
        ? list
        : <Typo className={styles.placeholder} size={14} type="div">No expenses have been created yet</Typo>
      }

      {list.length > 0 ? (
        <TotalAnchor total={side.otherDirectCosts.allOtherCostsTotalAmount} to={`/grants/${side.id}/otherDC/otherExpenses/totals`}
                active={isTotalFolderActive}>
          Totals
        </TotalAnchor>
      ) : null}

      <CreateBtn
        loading={isLoading}
        onClick={() => createExpense(side.id)}
        name="add-grant-expense" side={side}
        tip="To add expense, you must save general info first"
      >
        Add expense
      </CreateBtn>
    </>
  )
}

export default Other;
