import {Action} from 'store/types';
import I, {ImmutableArray, ImmutableObject} from 'seamless-immutable';
import {
  FullGrant, GrantEvent, GrantEventParticipant,
  GrantsState,
  ListGrant, SidebarTotals,
  Stat,
  StatGrant,
  Travel,
} from './types';
import {
  grantListAdapter,
  grantsStateAdapter,
  statGrantAdapter,
  grantAdapter,
  statsAdapter,
  grantInListAdapter,
  dashboardAdapter,
  equipAdapter,
  grantEventAdapter,
  travelAdapter,
  transportationMethodAdapter,
  costAdapter,
  grantEventParticipantAdapter,
  RateAdapter, ratePreviewAdapter, sidebarTotalsAdapter,
} from './adapters';

import {
  setGrantsDashboard,
  addGrantEvent,
  setGrantsStats,
  clearAll,
  setListGrants,
  setEditableGrant,
  clearEditableGrant,
  clearOneGrant,
  updateGrantInListAction,
  setOneGrant,
  setGrantActiveId,
  clearGrantActiveId,
  removeGrantAction,
  addNewGrantToList,
  addNewGrantOnDuplicateToList,
  setPrimaryInvestigatorToStatGrant,
  addEquipToGrantAction,
  addTravelToGrant,
  addTransportationMethodAction,
  addTravelCost,
  removeTravelCost,
  updateTravelCost,
  addGrantEventParticipant,
  updateGrantEvent,
  deleteGrantEvent,
  updateGrantEventParticipant,
  deleteGrantEventParticipant,
  setSubAwardScheme,
  clearGrantsAction,
  addSideGrant,
  updateTravelStatusAction,
  setGrantsRates, setGrantsRatesPreview, addGrantSidebarTotals,
} from './actions';
// Materials and Supplies
import {
  updateMaterialsSuppliesGeneralInfoAction,
  createMaterialsSuppliesUnitAction,
} from "./otherDirectCosts/MaterialsSupplies/actions";
import {
  materialsSuppliesAdapter,
} from './otherDirectCosts/MaterialsSupplies/adapters';
// Publications
import {
  addPublicationsUnitAction,
} from './otherDirectCosts/Publications/actions'
import {publicationsUnitAdapter} from './otherDirectCosts/Publications/adapters';
import {PublicationsUnit} from './otherDirectCosts/Publications/types';
// Fees
import {
  addFeeItemAction,
  updateFeeItemAction,
  deleteFeeItemAction,
} from './fees/actions';
import {feesItemAdapter} from './fees/adapters';
import {FeeItemType} from './fees/types';
// Cost Sharing
import {
  addCostSharingExpenseItemAction,
  updateCostSharingExpenseItemAction,
  deleteCostSharingExpenseItemAction
} from './costSharing/actions';
import {costSharingItemAdapter} from './costSharing/adapters';
import {CostSharingExpenseItemType} from './costSharing/types';
// Other Expense
import {
  addOtherExpenseAction,
} from './otherDirectCosts/OtherExpenses/actions';
import {otherExpenseAdapter} from './otherDirectCosts/OtherExpenses/adapters';
import {OtherExpenseType} from './otherDirectCosts/OtherExpenses/types';
// Consultant Services
import {
  addConsultantServiceAction,
} from './otherDirectCosts/ConsultantServices/actions';
import {consultantServiceItemAdapter} from './otherDirectCosts/ConsultantServices/adapters';
import {ConsultantServiceType} from './otherDirectCosts/ConsultantServices/types';
// Computer Service
import {
  addServiceToOTDCS,
  setOTDCS,
} from './otherDirectCosts/ComputerService/actions';
import {computerServiceAdapter, computerServicesAdapter} from './otherDirectCosts/ComputerService/adapters';
import {ComputerService} from './otherDirectCosts/ComputerService/types';
import {addContract} from './otherDirectCosts/Contracts/actions';
import {Contract} from './otherDirectCosts/Contracts/types';
import {contractAdapter} from './otherDirectCosts/Contracts/adapters';
import {addPaymentToODC} from './otherDirectCosts/Payments/actions';
import {Payment} from './otherDirectCosts/Payments/types';
import {paymentAdapter} from './otherDirectCosts/Payments/adapters';
import {addSubawardAction} from './otherDirectCosts/Subawards/actions';
import {Subaward} from './otherDirectCosts/Subawards/types';
import {subawardAdapter, subawardeeFormAdapter} from './otherDirectCosts/Subawards/adapters';
import {updateSideWithOne} from './helpers';


const initialStore = grantsStateAdapter();

const reducer = (state: GrantsState = initialStore, action: Action) => {
  switch (action.type) {
    case setListGrants.type: {
      return state.set('list', grantListAdapter(action.payload));
    }

    case setGrantsDashboard.type: {
      return state.set('dashboard', dashboardAdapter(action.payload));
    }

    case setSubAwardScheme.type: {
      return state.set('subAwardScheme', subawardeeFormAdapter(action.payload));
    }

    case setPrimaryInvestigatorToStatGrant.type: {
      return state
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => {
          return item.update('grants', grants => grants.map((item: StatGrant) => {
            if (item.id === action.payload.grantId) {
              return {
                ...item,
                primaryInvestigator: {
                  firstName: action.payload.firstName,
                  lastName: action.payload.lastName,
                }
              }
            }
            return item;
          }))
        }))
        .updateIn(['list', 'data'], data => data.map((item: ListGrant) => {
          if (item.id === action.payload.grantId) {
            return {
              ...item,
              primaryInvestigator: {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
              }
            }
          }
          return item;
        }))
    }

    case addGrantEvent.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'events'], events => events.concat(grantEventAdapter(action.payload)))
      );
    }

    case deleteGrantEvent.type: {
      return state
        .update('one', one => {
          if (one.id === action.payload.grantId) {
            return {
              ...one,
              events: one.events.filter((ev: ImmutableObject<GrantEvent>) => ev.id !== action.payload.id)
            };
          }
          return one;
        })
        .updateIn(['sides'], (sides: FullGrant[]) => {
          return sides.map((item: FullGrant) => {
            if (item.id === action.payload.grantId) {
              return {
                ...item,
                events: item.events.filter((ev: GrantEvent) => ev.id !== action.payload.id)
              }
            }
            return item;
          });
        });
    }

    case updateGrantEvent.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'events'], events => events.map((item: ImmutableObject<GrantEvent>) => {
          if (item.id === action.payload.id) {
            return grantEventAdapter({
              ...item,
              ...action.payload
            })
          }
          return item;
        }))
      )
    }

    case addGrantEventParticipant.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'events'], events => events.map((item: ImmutableObject<GrantEvent>) => {
          if (item.id === action.payload.eventId) {
            return {
              ...item,
              participants: item.participants.concat([grantEventParticipantAdapter(action.payload.participant)])
            };
          }
          return item;
        }))
      )
    }

    case deleteGrantEventParticipant.type: {
      return state
        .update('one', one => {
          if (one.id === action.payload.grantId) {
            return {
              ...one,
              events: one.events.map((item: ImmutableObject<GrantEvent>) => {
                if (item.id === action.payload.eventId) {
                  return {
                    ...item,
                    participants: item.participants.filter((participant: ImmutableObject<GrantEventParticipant>) => participant.id !== action.payload.id)
                  };
                }
                return item;
              })
            };
          }
          return one;
        })
        .update('sides', sides => {
          return sides.map((side) => {
            if (side.id === action.payload.grantId) {
              return {
                ...side,
                events: side.events
                  .map((ev: ImmutableObject<GrantEvent>) => {
                    if (ev.id === action.payload.eventId) {
                      return {
                        ...ev,
                        participants: ev.participants
                          .filter((participant: ImmutableObject<GrantEventParticipant>) => participant.id !== action.payload.id)
                      };
                    }
                    return ev;
                  })
              };
            }
            return side;
          })
        })
    }

    case updateGrantEventParticipant.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'events'], events => events.map((item: ImmutableObject<GrantEvent>) => {
          if (item.id === action.payload.eventId) {
            return {
              ...item,
              participants: item.participants.map((participant: ImmutableObject<GrantEventParticipant>) => {
                if (participant.id === action.payload.participant.id) {
                  return grantEventParticipantAdapter({
                    ...participant,
                    ...action.payload.participant
                  });
                }
                return participant;
              })
            }
          }
          return item;
        }))
      );
    }

    case setOTDCS.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'computerServices'], computerServices => computerServicesAdapter({...computerServices, ...action.payload}))
      );
    }


    case addServiceToOTDCS.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'computerServices', 'services'], (services: ComputerService[]) => services.concat([computerServiceAdapter(action.payload)]))
      );
    }


    case addSubawardAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'subawards'], (subawards: Subaward[]) => subawards.concat([subawardAdapter(action.payload)]))
      );
    }

    case addContract.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'contracts'], (contracts: Contract[]) => contracts.concat([contractAdapter(action.payload)]))
      );
    }


    case addPaymentToODC.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'incentivePayments'], (payments: Payment[]) => payments.concat([paymentAdapter(action.payload)]))
      );
    }

    case addTravelToGrant.type: {
      return updateSideWithOne(state //@ts-ignore
        .updateIn(['one', 'travel'], travel => travel.concat([travelAdapter(action.payload)]))//@ts-ignore
      );
    }

    case addTransportationMethodAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'travel'], travel => travel.map((item: ImmutableObject<Travel>) => {
          if (item.id === action.payload.travelId) {
            return item.update('transportationMethods', method => method.concat([transportationMethodAdapter(action.payload.transportationMethod)]))
          }
          return item;
        }))
      );
    }

    case addTravelCost.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'travel'], travel => travel.map((item: ImmutableObject<Travel>) => {
          if (item.id === action.payload.travelId) { //@ts-ignore
            return item.update('costs', costs => costs.concat([costAdapter(action.payload.cost)]))
          }
          return item;
        }))
      );
    }

    case updateTravelCost.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'travel'], travel => travel.map((item: ImmutableObject<Travel>) => {
          if (item.id === action.payload.travelId) {
            return item.update('costs', costs => costs.map((cost) => {
              if (cost.id === action.payload.cost.id) return costAdapter(action.payload.cost);
              return cost;
            }))
          }
          return item;
        }))
      );
    }

    case updateTravelStatusAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'travel'], travel => travel.map((item: ImmutableObject<Travel>) => {
          if (item.id === action.payload.travelId) {
            return item.update('status', () => {
              return action.payload.data.status
            })
          }
          return item;
        }))
      );
    }

    case removeTravelCost.type: {
      return state
        .update('one', one => {
          if (one.id === action.payload.grantId) {
            return {
              ...one,
              travel: one.travel.map((item: ImmutableObject<Travel>) => {
                if (item.id === action.payload.travelId) {
                  return item.update('costs', costs => costs.filter((cost) => cost.id !== action.payload.id))
                }
                return item;
              })
            }
          }
          return one;
        })
        .update('sides', sides => sides.map(side => {
          if (side.id === action.payload.grantId) {
            return {
              ...side,
              travel: side.travel.map((item: ImmutableObject<Travel>) => {
                if (item.id === action.payload.travelId) {
                  return item.update('costs', costs => costs.filter((cost) => cost.id !== action.payload.id))
                }
                return item;
              })
            }
          }
          return side;
        }));
    }

    case clearOneGrant.type: {
      return state.set('one', grantAdapter({} as FullGrant));
    }

    case addSideGrant.type: {
      return state
        .update('sides', sides => {
          const grantExistInSides = sides.find((item: FullGrant) => item.id === action.payload.id);
          if (grantExistInSides) {
            return sides.map((item: ImmutableObject<FullGrant>) => {
              if (item.id === action.payload.id) return grantAdapter(action.payload);
              return item;
            });
          }
          //@ts-ignore
          return sides.concat([grantAdapter(action.payload)]);
        })
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => {
          return item.update('grants', grants => grants.map((item: StatGrant) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                primaryInvestigator: {
                  firstName: action.payload.primaryInvestigator?.firstName ?? '',
                  lastName: action.payload.primaryInvestigator?.lastName ?? '',
                }
              }
            }
            return item;
          }))
        }))
        .updateIn(['list', 'data'], data => data.map((item: ListGrant) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              primaryInvestigator: {
                firstName: action.payload.primaryInvestigator?.firstName ?? '',
                lastName: action.payload.primaryInvestigator?.lastName ?? '',
              }
            }
          }
          return item;
        }))
    }

    case clearEditableGrant.type: {
      return state.set('editable', grantAdapter({} as FullGrant));
    }

    case setEditableGrant.type: {
      return state.set('editable', grantAdapter(action.payload));
    }

    case setGrantsStats.type: {
      return state.set('stats', statsAdapter(action.payload));
    }

    case addEquipToGrantAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'equipment'], equipment => equipment.concat([equipAdapter(action.payload)]))
      );
    }

    case removeGrantAction.type: {
      return state
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => {
          const existInStats = item.grants.find((grant: StatGrant) => grant.id === action.payload);
          return {
            ...item,
            count: existInStats ? item.count - 1 : item.count,
            grants: item.grants.filter((grant: StatGrant) => grant.id !== action.payload)
          }
        }))
        .updateIn(['list', 'data'], data => data.filter((item: ListGrant) => item.id !== action.payload))
        .updateIn(['list', 'total'], total => total - 1)
    }

    case addNewGrantToList.type: {
      return state
        .update('stats', stats => stats.length < 1 ?
          statsAdapter([{
            count: 1,
            grants: [action.payload],
            step: action.payload.step
          }])
          :
          stats.map((item: ImmutableObject<Stat>) => {
            if (item.step === action.payload.step) {
              const existInList = item.grants.find((grant: StatGrant) => grant.id === action.payload.id);
              //@ts-ignore
              const newList = [statGrantAdapter(action.payload)].concat(item.grants);

              return {
                ...item,
                count: existInList ? item.count : item.count + 1,
                grants: existInList ? item.grants : newList
              }
            }
            return item;
          }))
        .update('list', list => {
          const existInList = list.data.find((grant: ListGrant) => grant.id === action.payload.id);
          //@ts-ignore
          const newList = [grantInListAdapter(action.payload)].concat(list.data);
          return {
            ...list,
            total: existInList ? list.total : list.total + 1,
            data: existInList ? list.data : newList
          }
        });
    }

    case addNewGrantOnDuplicateToList.type: {
      return state
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => {
          if (item.step === action.payload.step) {
            return {
              ...item,
              count: item.count + 1,//@ts-ignore
              grants: I([statGrantAdapter(action.payload)]).concat(item.grants)
            }
          }
          return item;
        }))
        .update('list', list => {
          return {
            ...list,
            total: list.total + 1,
            data: I([grantInListAdapter(action.payload)]).concat(list.data)
          }
        });
    }

    case updateGrantInListAction.type: {
      return state
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => ({
          ...item,
          grants: item.grants.map((grant: StatGrant) => {
            if (grant.id === action.payload.id) return statGrantAdapter(action.payload);
            return grant;
          })
        })))
        .updateIn(['list', 'data'], data => data.map((item: ListGrant) => {
          if (item.id === action.payload.id) return grantInListAdapter(action.payload)
          return item;
        }));
    }

    case setOneGrant.type: {
      return updateSideWithOne(state
        .set('one', grantAdapter(action.payload))
        .update('stats', stats => stats.map((item: ImmutableObject<Stat>) => {
          return item.update('grants', grants => grants.map((item: StatGrant) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                completion: action.payload.completion
              }
            }
            return item;
          }))
        }))
        .updateIn(['list', 'data'], data => data.map((item: ListGrant) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              completion: action.payload.completion
            }
          }
          return item;
        }))
      );
    }

    case setGrantActiveId.type: {
      return state.set('activeId', action.payload);
    }

    case clearGrantActiveId.type: {
      return state.set('activeId', '');
    }

    case updateMaterialsSuppliesGeneralInfoAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'materialsAndSupplies'], () => materialsSuppliesAdapter(action.payload))
      );
    }

    case createMaterialsSuppliesUnitAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'materialsAndSupplies', 'units'], units => units.concat(action.payload))
      );
    }

    case addPublicationsUnitAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'publications', 'units'], (units: PublicationsUnit[]) => units.concat(publicationsUnitAdapter(action.payload)))
      );
    }

    case addOtherExpenseAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'allOtherCosts', 'expenses'], (expenses: Array<OtherExpenseType>) => expenses.concat([otherExpenseAdapter(action.payload)]))
      );
    }

    case addConsultantServiceAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'otherDirectCosts', 'consultantServices'], (services: Array<ConsultantServiceType>) => services.concat([consultantServiceItemAdapter(action.payload)]))
      );
    }

    case addFeeItemAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'fees'], (fees: FeeItemType[]) => fees.concat([feesItemAdapter(action.payload)]))
      );
    }

    case updateFeeItemAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'fees'], (fees: FeeItemType[]) =>
          fees.map((item) => {
            if (item.id === action.payload.id) {
              return feesItemAdapter(action.payload);
            }
            return item;
          }),
        )
      );
    }

    case deleteFeeItemAction.type: {
      return state
        .update('one', one => {
          if (one.id === action.payload.grantId) {
            return {
              ...one,
              fees: one.fees.filter((item) => item.id !== action.payload.id)
            }
          }
          return one;
        })
        .update('sides', sides => sides.map((side) => {
          if (side.id === action.payload.grantId) {
            return {
              ...side,
              fees: side.fees.filter((item) => item.id !== action.payload.id)
            }
          }
          return side;
        }));
    }

    case addCostSharingExpenseItemAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'costSharingExpenses'], (expenses: CostSharingExpenseItemType[]) => expenses.concat([costSharingItemAdapter(action.payload)]))
      );
    }

    case updateCostSharingExpenseItemAction.type: {
      return updateSideWithOne(state
        .updateIn(['one', 'costSharingExpenses'], (expenses: CostSharingExpenseItemType[]) =>
          expenses.map((item) => {
            if (item.id === action.payload.id) {
              return costSharingItemAdapter(action.payload);
            }
            return item;
          }),
        )
      );
    }

    case deleteCostSharingExpenseItemAction.type: {
      return state
        .update('one', one => {
          if (one.id === action.payload.grantId) {
            return {
              ...one,
              costSharingExpenses: one.costSharingExpenses.filter((item) => item.id !== action.payload.id)
            }
          }
          return one;
        })
        .update('sides', sides => sides.map((side) => {
          if (side.id === action.payload.grantId) {
            return {
              ...side,
              costSharingExpenses: side.costSharingExpenses.filter((item) => item.id !== action.payload.id)
            }
          }
          return side;
        }))
    }

    case clearGrantsAction.type: {
      return state
        .set('one', grantAdapter({} as FullGrant))
        .set('side', grantAdapter({} as FullGrant))
        .set('sides', [])
        .set('editable', grantAdapter({} as FullGrant))
        .set('activeId', '');
    }

    case setGrantsRates.type: {
      return state
        .update('rates', (rates) => ({
          ...rates,
          [action.payload.id]: action.payload.rates.map(RateAdapter)
        }));
    }

    case setGrantsRatesPreview.type: {
      return state
        .update('ratesPreview', (rates) => ({
          ...rates,
          [action.payload.id]: ratePreviewAdapter(action.payload.data)
        }));
    }

    case addGrantSidebarTotals.type: {
      return state
        .update('sidebar', (totals: ImmutableArray<SidebarTotals>) => {
          // @ts-ignore
          const exist = totals.find((item: ImmutableObject<SidebarTotals>) => item.id === action.payload.id);
          if (exist) {
            return totals.map((item: ImmutableObject<SidebarTotals>) => {
              if (item.id === action.payload.id) return sidebarTotalsAdapter(action.payload);
              return item;
            });
          }
          return totals.concat(sidebarTotalsAdapter(action.payload))
        });
    }

    case clearAll.type: {
      return grantsStateAdapter();
    }

    default: {
      return state;
    }
  }
};

export default reducer;
