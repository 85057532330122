import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import useApi from './useApi';

type APIListItem = {
  objectId: string;
  name: string;
}

type Option = {
  label: string;
  value: string;
}

type iUseOrganizations = {
  fetchApi: (name: string) => void
  fetchOptions: Option[];
  isLoading: boolean;
}

const useOrganizations = ():iUseOrganizations => {
  const api = useApi();
  const [ fetchOptions, onChangeFetchOptions ] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchApi = useCallback((name: string) => {
    setIsLoading(true);
    const where = encodeURIComponent(JSON.stringify({
      "name": {
        "$regex": `${name}`
      }
    }));
    api.fetchOrganizations(where)
      .then((response: AxiosResponse) => {
        const options = response.data.results.map((item: APIListItem) => ({
          value: item.name,
          label: item.name
        }));
        onChangeFetchOptions(options);
      })
      .finally(() => setIsLoading(false));
  }, [api]);

  return {
    fetchApi,
    fetchOptions,
    isLoading,
  }
}

export default useOrganizations;
