import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from 'components/form';
import {debounce} from 'lodash';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import styles from './subawards.module.css';
import {useParams} from 'react-router-dom';
import Tip from 'components/tip';
import Typo from 'components/typo';
import useGrants from 'hooks/useGrants';
import {getSubawardFromODC} from 'helpers/getFromGrant';
import {Subaward, SubAwardeeForm} from 'store/grants/otherDirectCosts/Subawards/types';
import SubawardeeTable from 'containers/subawardee';
import useSubawards from 'hooks/useSubawards';
import dayjs from 'dayjs';
import {confirm} from 'components/confirmation';

type Props = {
  grantId: string;
  item: Subaward;
  tableData: SubAwardeeForm;
}

const SubawardeeForm: FC<Props> = ({item, tableData, grantId}) => {
  const [copied, onChangeCopied] = useState<boolean>(false);
  const [data, onChangeData] = useState(item.subAwardeeForm || tableData);

  const {
    getLink, update, loading,
    trackFormStarted
  } = useSubawards({
    form_page_type: 'Subawardee form'
  });

  useEffect(() => {
    trackFormStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedChangeCopied = useCallback(debounce(() => onChangeCopied(false),
    2000,
    {trailing: true}
  ), []);

  const onCopy = useCallback(() => {
    getLink(grantId, item.id, (link) => {
      navigator.clipboard.writeText(link)
        .then(() => {
          onChangeCopied(true);
          debouncedChangeCopied();
        });
    });
  }, [getLink, debouncedChangeCopied, grantId, item]);

  const onPreview = useCallback(() => {
    getLink(grantId, item.id, (link) => {
      window.open(link, '_blank')
    });
  }, [getLink, grantId, item]);

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onClear = useCallback(() => {
    confirm({
      title: 'Clear subawardees form',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => onChangeData(tableData)
    });
  }, [tableData]);

  const onInsert = useCallback(() => {
    if (item.subAwardeeRecipientForm?.subAwardeeForm) {
      confirm({
        title: 'Want to override data in a table?',
        text: 'Data in the table will be replaced with the data from subawardees form',
        type: 'success',
        okText: 'Confirm',
        onConfirm: () => {
          onChangeData({//@ts-ignore
            ...item.subAwardeeRecipientForm.subAwardeeForm,//@ts-ignore
            rates: item.subAwardeeRecipientForm.subAwardeeForm.rates.map((item) => ({
              ...item,
              startDate: item.startDate ? dayjs(item.startDate).format('YYYY-MM-DD') : item.startDate,
              endDate: item.endDate ? dayjs(item.endDate).format('YYYY-MM-DD') : item.endDate,
            }))
          });
        }
      });
    }
  }, [item]);

  const handleUpdate = useCallback(() => {
    update(grantId, item.id, {
      subAwardeeForm: {
        ...data,
        rates: data.rates.map((item) => ({
          ...item,
          rate: item.rate ? Number(item.rate) / 100 : item.rate
        }))
      }
    })
  }, [update, grantId, item, data]);

  return (
    <div>
      <Typo className={styles.text}>
        To make the process of completing this section easier, you can share a link to the form with a subrecipient.
        When a subrecipient completes the form, you will be notified by email. The data will automatically be added to
        the table below. You can always change the added data or enter the values manually.
      </Typo>
      <div className={styles.btnWrapper}>
        <span className={styles.linkText}>Subawardees form</span>
        <Tip className={styles.mrb} text={copied ? 'Copied' : 'Copy link'}>
          <Button onClick={onCopy} name="subawards-copy-link" htmlType="button" type="link" postIcon="link-03"/>
        </Tip>
        <Tip className={styles.mrb} text="Preview Subawardees form">
          <Button name="subawards-preview-form" onClick={onPreview} htmlType="button" type="link"
                  postIcon="link-external-01"/>
        </Tip>
      </div>
      <div className={styles.formWrapper}>
        <div className={styles.formBtnsWrapper}>
          <Button className={styles.clear} onClick={onClear} name="clear" htmlType="button" type="bordered">Clear
            data</Button>
          <Tip top when={!item.subAwardeeRecipientForm} text="Subawardees form not completed">
            <Button name="insert" htmlType="button" onClick={onInsert} disabled={!item.subAwardeeRecipientForm}>Insert
              data from the subrecipient</Button>
          </Tip>
        </div>
        <SubawardeeTable onChange={handleChangeData} data={data}/>
        <div className={styles.btnFormWrapper}>
          <Button loading={loading} onClick={handleUpdate} htmlType="submit" name="submit">Save</Button>
        </div>
      </div>
    </div>
  );
}

const SubawardeePage: FC = () => {
  const params: Record<string, string> = useParams();
  const {privateSubaward} = useSubawards();
  const {grant} = useGrants();
  const {item, index} = getSubawardFromODC(grant, params.subawardId);

  const subtitle = useMemo(() => {
    if (item) return `${item.name || `Untitled Subaward ${index > 0 ? index : ''}`}. Subawardee Form`
    return 'Untitled Subaward. Subawardee Form'
  }, [item, index]);

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle={subtitle}>
      {item && privateSubaward
        ? <SubawardeeForm tableData={privateSubaward} item={item} grantId={grant.id}
                          key={`${grant.id}-odc-subawards-${params.subawardId}`}/>
        : <span/>}
    </GrantFormWrapper>
  )
}


export default SubawardeePage;
