import { FC, createElement, ReactElement, CSSProperties } from 'react';
import cx from 'classnames';
import styles from './typo.module.css';

type Props = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' | 'div';
  bold?: boolean;
  semi?: boolean;
  medium?: boolean;
  title?: string;
  className?: string;
  style?: CSSProperties;
  size?: number | string;
  children: string | string[] | ReactElement | ReactElement[] | number | Element | Element[];
}

const Typo:FC<Props> = (props: Props) => {
  const {
    type = 'p', size, children,
    bold, className, semi,
    medium, style = {},
    title
  } = props;
  return createElement(type, {
    children: children,
    title,
    style: { fontSize: size, ...style },
    className: cx(styles.typo, styles[type], className, {
      [styles.bold]: bold,
      [styles.semi]: semi,
      [styles.medium]: medium
    })
  });
}

export default Typo;
