import {FC, FunctionComponent, createElement, useMemo} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import * as forms from './forms';
import {useParams} from 'react-router-dom';
import useGrants from 'hooks/useGrants';
import { Kind } from 'const';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {Travel} from 'store/grants/types';

const CostLodgingComponent:FC = () => {
  const params:Record<string, string> = useParams();
  const { grant } = useGrants();
  const travel = getTravelFromGrant(grant, params.travelId);
  const { cost } = getCostFromTravel(travel.trip as Travel, params.costId);
  const key = `${grant.id}-${params.travelId}-${params.costId ?? 'cost'}-${cost?.kind}`;
  const form:Record<Kind, FunctionComponent<forms.FormProps>> = {
    other: forms.FortyEightLodgingForm,
    fourty8states: forms.FortyEightLodgingForm,
    international: forms.AlaskaHawaiiLodgingForm,
    alaskaHawaii: forms.AlaskaHawaiiLodgingForm
  }

  const subtitle = useMemo(() => {
    if (cost && cost.kind === 'alaskaHawaii') {
      return `Travel Costs. Alaska, Hawaii. Lodging breakdown per person`
    }
    if (cost && cost.kind === 'fourty8states') {
      return `Travel Costs. 48 states. Lodging breakdown per person`
    }
    if (cost && cost.kind === 'international') {
      return `Travel Costs. International. Lodging breakdown per person`
    }
    return 'Travel Costs';
  }, [cost]);

  return (
    <GrantFormWrapper title="Travel" subtitle={subtitle}>
      {cost && cost.kind && form[cost.kind as Kind]
        ? createElement(form[cost.kind as Kind], {
            grantId: grant.id,
            travelId: params.travelId,
            key: key,
            inter: cost.kind === 'international',
            cost: cost,
          })
        : undefined}
    </GrantFormWrapper>
  );
}

export default CostLodgingComponent;
