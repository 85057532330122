import {FC, useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import {getEventFromGrant, getParticipantFromGrantEvent} from 'helpers/getFromGrant';
import useGrants from 'hooks/useGrants';
import useParticipants from 'hooks/useParticipants';
import Form from './form';
import Tabs, {Tab} from 'components/tabs';
import {
  FullGrant,
  GrantEventParticipant,
  GrantEventParticipantYear
} from 'store/grants/types';


type Props = {
  grant: FullGrant;
  participant: GrantEventParticipant;
  eventId: string;
}

const GrantEventParticipantStipends: FC<Props> = ({grant, participant, eventId}) => {
  const {
    errors, loading, clearErrors, updateEventParticipant,
    trackError,
    trackExit,
    trackFormStarted
  } = useParticipants({
    form_page_type: 'Participant`s stipends'
  });

  const onSubmit = useCallback((data: GrantEventParticipantYear) => {
    const newYears = participant.years.map((item: GrantEventParticipantYear) => {
      if (item.serialNumber === data.serialNumber) return data;
      return item;
    });
    updateEventParticipant(grant.id, eventId, participant.id, {
      category: participant.category,
      years: newYears
    });
  }, [grant, updateEventParticipant, eventId, participant]);

  const list: Tab[] = useMemo(() => {
    return participant.years.map((item: GrantEventParticipantYear, idx: number) => ({
      label: `Year ${idx + 1}`,
      id: `${item.serialNumber}` ?? 'new',
      element: participant
        ? <Form grantId={grant.id} year={item} participantId={participant.id}
                onSubmit={onSubmit} eventId={eventId} notFirst={item.serialNumber > 1}
                key={item.serialNumber} clearErrors={clearErrors}
                errors={errors} loading={loading}
                trackError={trackError}
                trackExit={trackExit}
                trackFormStarted={trackFormStarted}

        />
        : <span/>
    }));
  }, [participant, grant.id, onSubmit, eventId, clearErrors, errors, loading, trackError, trackExit, trackFormStarted]);

  return (
    <Tabs smallPadding onEnter={clearErrors} list={list} name="year"/>
  );
}

const GrantEventParticipantTravelsPage: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, any> = useParams();
  const {item, index} = getEventFromGrant(grant, params.eventId);
  const participant = item && getParticipantFromGrantEvent(item, params.participantId);
  const key = `${params.id}-event-${params.eventId}-participant-${participant?.item?.id}-travels`;

  const subtitle = useMemo(() => {
    return `Participant's Stipends. Event ${index}. Participant ${participant?.index ?? ''}`
  }, [index, participant]);

  return (
    <GrantFormWrapper title="Participants Support" subtitle={subtitle}>
      {participant?.item
        ?
        <GrantEventParticipantStipends grant={grant} participant={participant.item} eventId={params.eventId} key={key}/>
        : <span/>
      }
    </GrantFormWrapper>
  );
}

export default GrantEventParticipantTravelsPage;
