import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useTravel from 'hooks/useTravel';
// types
import {FullGrant, Travel, TravelCostData, TravelCostYear, TravelYear} from 'store/grants/types';
import { Button, Form, Input } from 'components/form';
import Field from 'components/field';
import ConsultantAttendeeYearsForm from 'pages/grant/one/travel/common/attendee/consultantAttendeeYears';
// styles
import styles from './attendee.module.css';

type TravelCostOtherConsultantAttendeeProps = {
  cost: TravelCostData;
  grant: FullGrant;
  trip: Travel;
};

const consultantOtherCostYearsAdapter = (year: any) => {
  return {
    year: year?.year,
    serialNumber: year?.serialNumber,
    isEnabled: Boolean(year?.isEnabled),
    numberOfOccurrence: year?.numberOfOccurrence ?? '',
    numberOfConsultants: year?.numberOfConsultants ?? '',
    amountWithInflation: year?.amountWithInflation,
    compensationAfterInflation: year?.compensationAfterInflation,
    isNotForPersons: true,
    isConsultantService: true,
    totalAmount: year?.totalAmount,
    compensationBeforeInflation: year?.compensationBeforeInflation,
    totalAmountOfPersons: year?.totalAmountOfPersons,
  }
}

const TravelCostOtherConsultantAttendee: FC<TravelCostOtherConsultantAttendeeProps> = ({ cost, trip, grant }) => {
  const { loading, previewTravelCost, updateTravelCost } = useTravel();
  const params: Record<string, string> = useParams();
  //@ts-ignore
  const defaultYears:TravelCostYear[] =
    trip.status.years.length > 0
      ? trip.status.years.map((year: TravelYear) => ({
        year: year.year,
        serialNumber: year.serialNumber,
        isEnabled: false,
        numberOfOccurrence: '',
        numberOfConsultants: '',
        amountWithInflation: '',
        isNotForPersons: true,
      }))
      : grant.years.map((year: string[], serialNumber: number) => ({
        year,
        serialNumber: serialNumber + 1,
        isEnabled: false,
        numberOfOccurrence: '',
        numberOfConsultants: '',
        amountWithInflation: '',
        isNotForPersons: true,
      }));
  const [data, setData] = useState({
    years: cost && cost.years && cost.years.length > 0 ? cost.years : defaultYears,
    totalAmount: (cost.totalAmount || cost.totalAmount === 0) ? cost.totalAmount : '',
    totalAmountForTrip: (cost.totalAmountForTrip || cost.totalAmountForTrip === 0) ? cost.totalAmountForTrip : '',
  });

  const onBlur = useCallback((event: any, blurData?: any) => {
    const value = blurData ?? data;
    if (grant.id && cost) {
      previewTravelCost(
        grant.id,
        trip.id,
        cost.id,
        {
          isNotForPersons: true,
          years: value.years.map((item: any) => ({
            ...item,
            isConsultantService: true
          }))
        },
        (response: any) => {
          setData({
            ...value,
            totalAmountForTrip: response?.totalAmountForTrip,
            totalAmount: response?.totalAmount,
            years: response?.years.map(consultantOtherCostYearsAdapter),
          });
        }, true,
      );
    }
  }, [previewTravelCost, data, cost, grant.id, trip]);

  const onChangeYears = useCallback((year: Record<string, any>, idx: number | string, blur?: boolean) => {
    const newYears = data.years.map((item: TravelCostYear) => {
      if (item.serialNumber === idx) {
        return {
          ...item,
          ...year,
        };
      }
      return item;
    });
    const newData = {
      ...data,
      years: newYears,
    }
    setData(newData);
    if (blur) onBlur(undefined, newData);
  },[data, onBlur, setData]);


  const handleSubmit = () => {
    if (trip.id && cost) {
      updateTravelCost(grant.id, trip.id, params.costId, {
        ...data,
        isNotForPersons: true,
        years: data.years.map((item: any) => ({
          ...item,
          isConsultantService: true
        }))
      });
    }
  };

  return (
    <Form prompt onSubmit={handleSubmit}>
      <Field>
        <Input
          name="totalAmount"
          money
          value={data.totalAmount}
          label="Lodging and meals for all people before inflation"
          placeholder="Amount of compensation before inflation"
          disabled
          readOnly
        />
      </Field>

      <ConsultantAttendeeYearsForm
        years={data.years}
        onBlur={onBlur}
        onChangeYear={onChangeYears}
        totalAmountForTrip={data.totalAmountForTrip}
      />

      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-equip">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default TravelCostOtherConsultantAttendee;
