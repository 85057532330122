import { createSelector } from 'reselect';
import { Store } from '../types';
import { GrantsState } from './types';

const grantsSelector = (store: Store) => store.grants;

export const grantsListSelector = createSelector(grantsSelector, (state: GrantsState) => state.list.asMutable({ deep: true }));
export const grantsStatsSelector = createSelector(grantsSelector, (state: GrantsState) => state.stats.asMutable({ deep: true }));
export const grantSelector = createSelector(grantsSelector, (state: GrantsState) => state.one.asMutable({ deep: true }));
export const sideGrantSelector = createSelector(grantsSelector, (state: GrantsState) => state.side.asMutable({ deep: true }));
export const sidesGrantSelector = createSelector(grantsSelector, (state: GrantsState) => state.sides.asMutable({ deep: true }));
export const editableGrantSelector = createSelector(grantsSelector, (state: GrantsState) => state.editable.asMutable({ deep: true }));
export const activeGrantIdSelector = createSelector(grantsSelector, (state: GrantsState) => state.activeId);
export const dashboardSelector = createSelector(grantsSelector, (state: GrantsState) => state.dashboard.asMutable({ deep: true }));
export const subAwardSchemeSelector = createSelector(grantsSelector, (state: GrantsState) => state.subAwardScheme.asMutable({ deep: true }));
export const ratesSelector = createSelector(grantsSelector, (state: GrantsState) => state.rates.asMutable({ deep: true }));
export const ratesPreviewSelector = createSelector(grantsSelector, (state: GrantsState) => state.ratesPreview.asMutable({ deep: true }));
export const grantSidebarSelector = createSelector(grantsSelector, (state: GrantsState) => state.sidebar.asMutable({ deep: true }));
