import I from 'seamless-immutable';
import { tokenKey } from 'const';
import {AuthState, Plan, User, Organization, Rate, Rates, AppliedType, Billing} from './types';

export const organizationAdapter = (data?: Organization):Organization => {
 if (data) {
  return I({
   id: data.id ?? '',
   name: data.name ?? '',
   subGroup: data.subGroup ?? '',
   department: data.department ?? '',
   address: {
    address1: data.address?.address1 ?? '',
    address2: data.address?.address2 ?? '',
    city: data.address?.city ?? '',
    country: data.address?.country ?? '',
    state: data.address?.state ?? '',
    zip: data.address?.zip ?? '',
   },
   academicYear: {
    startDate: data.academicYear?.startDate ?? '',
    endDate: data.academicYear?.endDate ?? '',
   },
   fiscalYear: {
    startDate: data.fiscalYear?.startDate ?? '',
    endDate: data.fiscalYear?.endDate ?? '',
    definition: data.fiscalYear?.definition ?? ''
   },
   annualSchedule: data.annualSchedule ?? '',
  });
 }
 return I({
  id: '',
  name: '',
  department: '',
  subGroup: '',
  address: {
   address1: '',
   address2: '',
   city: '',
   country: '',
   state: '',
   zip: '',
  },
  academicYear: {
   startDate: '',
   endDate: '',
  },
  fiscalYear: {
   startDate: '',
   endDate: '',
   definition: '',
  },
  annualSchedule: '',
 });
}

export const userAdapter = (data?: User):User => {
 if (data) {
  return I({
   createdAt: data.createdAt,
   paid: true,
   firstName: data.firstName,
   billing: billingAdapter(data?.billing),
   organization: organizationAdapter(data.organization),
   id: data.id,
   calculateAverageBudget: Boolean(data.calculateAverageBudget),
   lastName: data.lastName,
   fullName: `${data.firstName} ${data.lastName}`,
   profileImage: data.profileImage ?? I({ url: '' }),
   updatedAt: data.updatedAt,
   race: data.race ?? null,
   yearSalary: data.yearSalary ?? undefined,
   yearlyMonthsSalaryCompensation: data.yearlyMonthsSalaryCompensation ?? undefined,
   oneMonthCompensationAmount: data.oneMonthCompensationAmount ?? undefined,
   gender: data.gender ?? null,
   birthdate: data.birthdate ?? '',
   email: data.email ?? '',
   positionTitle: data.positionTitle ?? '',
   profile: {
    doShowHints: Boolean(data.profile?.doShowHints)
   }
  });
 }
 return I({
  createdAt: '',
  firstName: '',
  paid: true,
  id: '',
  calculateAverageBudget: true,
  fullName: '',
  organization: organizationAdapter(),
  profileImage: I({ url: '' }),
  yearSalary: undefined,
  yearlyMonthsSalaryCompensation: undefined,
  oneMonthCompensationAmount: undefined,
  lastName: '',
  billing: undefined,
  updatedAt: '',
  race: null,
  gender: null,
  birthdate: '',
  email: '',
  positionTitle: '',
  profile: {
   doShowHints: true
  }
 });
}

const billingAdapter = (data?: Billing) => {
 if (!data) return undefined;
 return {
  plan: {
   createdAt: data.plan?.createdAt ?? '',
   active: Boolean(data.plan?.active),
   interval: data.plan?.interval ?? '',
   price: data.plan?.price ?? 0,
   product: {
    id: data.plan?.product?.id ?? '',
    name: data.plan?.product?.name ?? '',
    isUpgradable: Boolean(data.plan?.product?.isUpgradable),
   },
  },
  stripe: {
   isWaitingPaymentSuccess: Boolean(data.stripe?.isWaitingPaymentSuccess),
   subscriptionNoticeConfirmedAt: data.stripe?.subscriptionNoticeConfirmedAt,
   paymentProcessStatus: data.stripe?.subscriptionNoticeConfirmedAt,
  },
  subscription: {
   expiresOn: data.subscription?.expiresOn ?? '',
   isActive: Boolean(data.subscription?.isActive),
   id: data.subscription?.id ?? '',
  }
 }
}

export const RateAdapter = (data: Rate) => {
 return {
  id: data.id ?? '',
  rate: data.rate ? (Number(data.rate) * 100).toFixed(2) : '',
  startDate: data.startDate ?? '',
  endDate: data.endDate ?? '',
  organizationName: data.organizationName ?? '',
  location: data.location ?? '',
  comment: data.comment ?? '',
  isGrantExclusive: Boolean(data.isGrantExclusive),
  createdAt: data.createdAt ?? '',
  appliedToGrants: data.appliedToGrants?.map((item: AppliedType) => ({
   id: item.id ?? '',
   title: item.title ?? '',
   isAccepted: Boolean(item.isAccepted),
  })) || []
 }
}
export const RatesAdapter = (data: Rates) => {
 return I({
  data: data.data?.map(RateAdapter) ?? [],
  total: data.total,
  page: data.page,
  perPage: data.perPage,
  next: Boolean(data.next),
 })
}

export const authStateAdapter = ():AuthState => I({
 token: localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey) || '',
 user: userAdapter(),
 rates: {
  total: 0,
  page: 1,
  perPage: 10,
  next: false,
  data: [],
 },
 plans: []
});
