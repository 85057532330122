import { useLocation } from 'react-router';

type iUseUrl = {
  grantId?: string;
  category?: string;
  subCategory?: string;
  categoryId?: string;
  section?: string;
  sectionId?: string;
  subSectionId?: string;
  subSection?: string;
}

const useUrl = ():iUseUrl => {
  const { pathname } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_trash, _base, grantId, category, subCategory, categoryId, section, sectionId, subSection, subSectionId ] = pathname.split('/');
  //           / grants GRANTID personnel PERSONNELTYPE PERSONID compensation SECTIONID year yearNumber

  return {
    grantId,
    category,
    subCategory,
    subSection,
    subSectionId,
    categoryId,
    section,
    sectionId
  }
}

export default useUrl;
