import { FC, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import Tip from 'components/tip';
import Icon from 'components/icon';
import Typo from 'components/typo';
import Card from 'pages/grant/paper/card';
import { StepType } from 'pages/grant/paper';
import { StatGrant } from 'store/grants/types';
import { groupBy } from 'lodash';
import useEnums from 'hooks/useEnums';
import { ListItem } from 'helpers/utils';
import styles from '../paper.module.css';
import useUrl from '../../../../hooks/useUrl';

type Props = {
  opened: boolean;
  onOpen?: () => void;
  item: StepType;
  search?: string;
  sidebarWidth: number;
}

type GroupedItems = {
  key: string;
  label: string;
  list: StatGrant[];
}

const Step:FC<Props> = (props: Props) => {
  const { item, search, onOpen, sidebarWidth, opened } = props;
  const { grantStatus } = useEnums();
  const url = useUrl();
  const [ showSubs, onChangeShowSubs ] = useState<string[]>(grantStatus.map((item: ListItem) => item.value));

  const groupedList:GroupedItems[] = useMemo(() => {
    const groupedItems = groupBy(item.list, 'status');
    return grantStatus.map((status: ListItem) => {
      return {
        key: status.value,
        label: status.label,
        list: groupedItems[status.value] ?? []
      }
    });
  }, [grantStatus, item]);

  const handleToggleSub = useCallback((sub: string) => {
    const newSubs = showSubs.includes(sub) ? showSubs.filter((item: string) => item !== sub) : [...showSubs, sub];
    onChangeShowSubs(newSubs);
  }, [showSubs]);

  const isActive = useMemo(() => {
    const ids = item.list.map((grant: StatGrant) => grant.id);
    return ids.includes(url.grantId || '');
  }, [url, item]);

  const show = (opened || item.open) && ((item.step === 'hidden' && groupedList.length > 0) || item.list.length > 0);

  return (
    <div className={styles.stepWrapper}>
      <Tip text={`${item.label} (${item.count})`} className={styles.tip} when={sidebarWidth < 100}>
        <div role="button" onClick={onOpen}
             className={cx(styles.stepHeader, {
               [styles.opened]: opened,
               [styles.active]: isActive,
             })}>
          {item.count > 0
            ? <Icon size={20} className={styles.chevron} icon="chevron-right"/>
            : <span className={styles.chevron}/>
          }
          <Icon size={20} className={styles.stepIcon} icon={item.icon}/>
          <Typo semi size={16} className={styles.stepLabel}>{item.label}</Typo>
          <span className={styles.grantCount}>{item.count}</span>
          {item.count > 0 ? <span className={styles.minimizedGrantCount}/> : null}
        </div>
      </Tip>
      {show ?
        <div className={styles.cards}>
          { item.step === 'hidden'
            ?
              (
                groupedList.map((group: GroupedItems) => group.list.length < 1 ? null : (
                  <div className={styles.grouped} key={group.key}>
                    <div role="button" onClick={() => handleToggleSub(group.key)} className={cx(styles.groupedHeader, {[styles.openedGroup]: showSubs.includes(group.key)})}>
                      {group.label} <Icon size={20} className={styles.chevron} icon="chevron-down"/>
                    </div>
                    {showSubs.includes(group.key)
                      ?
                        <div className={styles.groupedList}>
                          {group.list.map((card: StatGrant, index: number) => (
                            <Card search={search} last={index === group.list.length - 1} item={card} key={index}/>
                          ))}
                        </div>
                        : null
                    }
                  </div>
                ))
              )
            : item.list.map((card: StatGrant, cardIndex: number) => (
              <Card search={search} last={cardIndex === item.list.length - 1} item={card} key={cardIndex}/>
            ))}
        </div>
        : null}
    </div>
  );
}

export default Step;
