 import { FC } from 'react';
import Rates from 'containers/rates';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import styles from 'containers/rates/rates.module.css';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Typo from 'components/typo';

const Facilities:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const subtitle = (
    <>
      <Typo type="p" size={16} className={styles.subtitle}>
        Facilities and Administrations Rate (F&A Rate) is a rate negotiated between your institution and the government to roughly determine what amount of money your university is entitled to perform services to not only your work but also others' work as well.
      </Typo>
      <Typo type="p" size={16} className={styles.subtitle}>
        Think of this as either a tax that goes to your university to keep the lights on or money distributed to the general staff, general maintenance, and university-wide resources. Normally the F&A rates could be found on the same page that their university has posted the rates on their website.
      </Typo>
    </>
  )
  return (
    <GrantFormWrapper title="Facilities and Administrations Rates (F&A Rates)" subtitle={subtitle}>
      {grant?.id ? <Rates titless grant={grant} grantId={params.id} key={grant.id} /> : <span/>}
    </GrantFormWrapper>
  )
}

export default Facilities;
