import {FC} from 'react';
import {Route} from 'react-router-dom';
// components
import OtherInfo from './info';
import OtherExpensePage from './expense';
import OtherExpenseYearPage from './expense/year';
import OtherExpenseTotals from './totals';

const OtherIndex: FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/otherExpenses/general-info"
        exact
        component={OtherInfo}
      />
      <Route
        path="/grants/:id/otherDC/otherExpenses/:expenseId/edit"
        exact
        component={OtherExpensePage}
      />
      <Route
        path="/grants/:id/otherDC/otherExpenses/:expenseId/year/:yearId"
        exact
        component={OtherExpenseYearPage}
      />
      <Route
        path="/grants/:id/otherDC/otherExpenses/totals"
        exact
        component={OtherExpenseTotals}
      />
    </>
  )
}

export default OtherIndex;
