import {FC, ChangeEvent, useState} from 'react';
import cx from 'classnames';
import Field from 'components/form/field';
import styles from 'components/form/form.module.css';
import {useFormContext} from 'react-hook-form';
import {findInputError, RHErrors} from '../../../helpers/errors';

type Props = {
  name: string;
  value?: boolean | any;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  inputClassName?: string;
  invert?: boolean;
  green?: boolean;
  className?: string;
}

const Toggler:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    className, errors = [], required,
    disabled, inputClassName, hint,
    invert, checked
  } = props;
  const {
    register,
    formState,
  } = useFormContext();

  const inputErrors = findInputError(formState.errors as RHErrors, name);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;

  //todo check turn off

  const validation = register(name,
    {
      required: {value: Boolean(required), message: 'Field is required'},
      onChange,
    })

  return (
    <label className={cx(styles.togglerWrapper, className, styles.green, { [styles.invert]: invert })}>
      <input
        type="checkbox" checked={checked}
        value={value} className={cx(styles.hidden, styles.togglerCheckbox)}
        disabled={disabled} required={required} cypress-id={`${name}-toggler`}
        {...validation}
      />
      <div className={cx(styles.toggler, inputClassName)}>
        <span className={styles.point}/>
      </div>
      <Field element="span" className={styles.togglerLabel} required={required} label={label} hint={hint} errors={errorsList} name={`${name}-field`}/>
    </label>
  )
}

export default Toggler;
