import React, {FC, useContext, useEffect, useCallback, useState, useMemo} from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import useGrants from 'hooks/useGrants';
import * as T from 'hooks/requestTypes';
import useContextMenu from 'context/useContextMenu';
import useEnums from 'hooks/useEnums';
import AcceptForm from 'containers/forms/acceptForm';
import { stepNames } from 'const';
import { FullGrant } from 'store/grants/types';
import { grantAdapter } from 'store/grants/adapters';
import { ListItem } from 'helpers/utils';
import styles from './contextMenu.module.css';
import useUI from 'hooks/useUI';

const ContextMenu:FC = () => {
  const { show, grantId, contextGrant, onHideMenu, coords } = useContext(useContextMenu);
  const { grantStatus } = useEnums();
  const { onShowSettingsModal } = useUI();
  const { removeGrant, getEditable, duplicate, changeGrantStep, errors, loading, getOneSilent } = useGrants();
  const [ acceptStep, onChangeAcceptStep ] = useState<'accepted' | 'hidden'>('accepted');
  const [ grant, onChangeGrant ] = useState<FullGrant | undefined>(undefined);
  const [ showAcceptForm, onChangeShowAcceptForm ] = useState<boolean>(false);
  const uniqueClassName = 'bm-context-menu';

  const handleClickOutside = useCallback((event: any) => {
    const inElem = event.target?.closest(`.${uniqueClassName}`);
    const datePicker = event.target?.closest(`.react-datepicker__tab-loop`);
    const onToggler = event.target?.className.includes(`grant-dots-toggler-${grantId}`);
    const onSelect = event.target?.closest(`.bm-select`);
    const openedModalFromContext = event.target?.closest(`.bm-modal-overlay`);
    const toastClicked = event.target?.closest(`.Toastify`);
    if (show && !inElem && !datePicker && !onSelect && !showAcceptForm && !onToggler && !openedModalFromContext && !toastClicked) {
      onHideMenu();
    }
    return;
  }, [onHideMenu, grantId, showAcceptForm, show]);

  const handleCloseAcceptFormModal = useCallback(() => {
    onChangeShowAcceptForm(false);
    onHideMenu();
    onChangeGrant(undefined);
  }, [onHideMenu]);

  const handleSubmitAcceptedForm = useCallback((data: T.AcceptForm) => {
    if (contextGrant) {
      changeGrantStep(contextGrant, acceptStep, data, handleCloseAcceptFormModal, acceptStep === 'hidden' ? 'funded' : undefined);
    }
  }, [contextGrant, handleCloseAcceptFormModal, acceptStep, changeGrantStep]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [handleClickOutside]);

  const handleRemoveGrant = useCallback(() => {
    if (grantId) {
      removeGrant(grantId, onHideMenu);
    }
  }, [grantId, onHideMenu, removeGrant]);

  const handleDuplicateGrant = useCallback(() => {
    if (grantId) {
      duplicate(grantId, onHideMenu);
    }
  }, [grantId, onHideMenu, duplicate]);

  const steps = Object.keys(stepNames).reduce((result: ListItem[], key: string) => {
    if (key === 'hidden') return result;
    return [
      ...result,
      {
        value: key,
        label: stepNames[key]
      }
    ];
  }, []);

  const handleShowSettings = useCallback(() => {
    if (grantId) getEditable(grantId, () => {
      onShowSettingsModal();
      onHideMenu();
    });
  }, [getEditable, onHideMenu, onShowSettingsModal, grantId]);

  const onClickChangeStep = useCallback((step: string) => {
    if (contextGrant && contextGrant.step !== step) {
      if (step === 'accepted' && !contextGrant.acceptedForm) {
        getOneSilent(contextGrant.id, (grant: FullGrant) => {
          onChangeShowAcceptForm(true);
          onChangeAcceptStep('accepted');
          const adaptedGrant = grantAdapter(grant).asMutable({deep: true});
          //@ts-ignore
          onChangeGrant(adaptedGrant);
        });
        return;
      }
      onHideMenu();
      changeGrantStep(contextGrant, step);
    }
  }, [contextGrant, onHideMenu, getOneSilent, changeGrantStep]);

  const onClickChangeStepToHidden = useCallback((step: string, status: string) => {
    if (contextGrant && contextGrant.step !== step) {
      if (!contextGrant.acceptedForm && status === 'funded') {
        getOneSilent(contextGrant.id, (grant: FullGrant) => {
          onChangeShowAcceptForm(true);
          onChangeAcceptStep('hidden');
          const adaptedGrant = grantAdapter(grant).asMutable({deep: true});
          //@ts-ignore
          onChangeGrant(adaptedGrant);
        });
        return;
      }
      onHideMenu();
      changeGrantStep(contextGrant, step, undefined, undefined, status);
    }
  }, [contextGrant, getOneSilent, onHideMenu, changeGrantStep]);

  const style = useMemo(() => {
    const toTop = coords.y > window.innerHeight/2;
    return {
      left: `${coords.x}px`,
      top: `${coords.y}px`,
      transform: toTop ? 'translateY(-100%)' : undefined
    }
  }, [coords]);

  const onShowAcceptedFormViewOnly = useCallback(() => {
    if (contextGrant?.acceptedForm) {
      getOneSilent(contextGrant.id, (grant: FullGrant) => {
        onChangeShowAcceptForm(true);
        const adaptedGrant = grantAdapter(grant).asMutable({deep: true});
        //@ts-ignore
        onChangeGrant(adaptedGrant);
      });
    }
  }, [getOneSilent, contextGrant]);

  return (
    <div className={cx(styles.wrapper, uniqueClassName, {[styles.show]: show})} style={style}>
      <ul className={styles.list}>
        <li role="button" className={styles.withInnerList}>
          <Icon icon="move" className={styles.icon}/> Move grant to <Icon icon="chevron-right" className={styles.arrow} />
          <ul className={cx(styles.list, styles.innerList)}>
            {steps.map((item: ListItem, idx: number) => (
              <li role="button" key={idx} className={cx({[styles.disabled]: item.value === contextGrant?.step})} onClick={() => onClickChangeStep(item.value)}>{item.label}</li>
            ))}
            <li className={cx(styles.withInnerList, {[styles.disabled]: contextGrant?.step === 'hidden'})}
                role="button"
            >
              {stepNames.hidden} <Icon icon="chevron-right" className={styles.arrow} />
              <ul className={cx(styles.list, styles.innerList, styles.horizontal)}>
                {grantStatus.map((item: ListItem, idx: number) => (
                  <li key={idx} onClick={() => onClickChangeStepToHidden('hidden', item.value)}>{item.label}</li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
        <li role="button" onClick={handleDuplicateGrant}>
          <Icon icon="copy-03" className={styles.icon}/>Duplicate grant
        </li>
        <li role="button" onClick={handleShowSettings}>
          <Icon icon="settings-01" className={styles.icon}/>Grant settings
        </li>
        <li
          role="button"
          onClick={onShowAcceptedFormViewOnly}
          className={cx(styles.divider, {[styles.disabled]: !contextGrant?.acceptedForm})}
        >
          <Icon icon="layout-alt-03" className={cx(styles.icon, {[styles.icon_disabled]: !contextGrant?.acceptedForm})}/>
          Display Accepted form
        </li>
        <li role="button" onClick={handleRemoveGrant}>
          <Icon className={styles.icon} icon="trash-01"/>Delete grant
        </li>
      </ul>
      {grant
        ? <AcceptForm visible={showAcceptForm} errors={errors} loading={loading} onSubmit={handleSubmitAcceptedForm} grant={grant} onClose={handleCloseAcceptFormModal}/>
        : null}
    </div>
  );
}

export default ContextMenu;
