import {FC, ReactElement, useState, useEffect, useRef} from 'react';
import {FieldErrors, FormProvider, useForm} from 'react-hook-form';
import Prompt from 'containers/prompt';

type Props = {
  onSubmit?: (data: any) => void;
  className?: string;
  prompt?: boolean;
  children: ReactElement | ReactElement[] | any | any[];
  trackError?: (errors: FieldErrors) => void;
  trackExit?: () => void
  trackFormStarted?: () => void
}

const Form: FC<Props> = ({onSubmit, prompt, className, children, trackError, trackExit,trackFormStarted}) => {
  const methods = useForm();
  const changeFormRef = useRef(false)
  const [submitted, onChangeSubmitted] = useState<boolean>(false);
  const handleSubmit = methods.handleSubmit((data: any) => {
    if (onSubmit) onSubmit(data);
    if (prompt) {
      methods.reset();
      onChangeSubmitted(true);
    }
  });

  useEffect(() => {
    if (methods.formState.submitCount > 0 && Object.keys(methods.formState.errors)?.length > 0 && trackError) {
      trackError(methods.formState.errors)
    }
  }, [methods.formState.errors, methods.formState.submitCount, trackError]);

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) onChangeSubmitted(false);
  }, [methods.formState.submitCount, methods.formState.isSubmitSuccessful]);

  const promptCondition = methods.formState.isDirty && !submitted;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} className={className} noValidate onChange={() => {
        if(!changeFormRef.current && trackFormStarted){
          changeFormRef.current = true
          trackFormStarted()
        }
      }}>
        {children}
      </form>
      {prompt ? <Prompt when={promptCondition} trackExit={trackExit}/> : null}
    </FormProvider>
  );
}

export default Form;
