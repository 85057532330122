import { FC } from 'react';
import {Link} from 'react-router-dom';
import Icon from 'components/icon';
import Typo from 'components/typo';
import {Button} from 'components/form';
import styles from './rates.module.css';

type Props = {
  notFound?: boolean;
  redirect?: boolean;
  onAddRate: () => void;
}

const Placeholder:FC<Props> = ({ notFound, redirect, onAddRate }) => {
  if (notFound) {
    return (
      <div className={styles.placeholderWrapper}>
        <div className={styles.placeholder}>
          <Icon type="info" icon="search-sm"/>
          <Typo className={styles.placeholderTitle} bold size={20}>
            No rates found
          </Typo>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.placeholderWrapper}>
      <div className={styles.placeholder}>
        <Icon type="info" icon="edit-04"/>
        <Typo className={styles.placeholderTitle} bold size={20}>
          No rates have been added yet
        </Typo>
        <Typo className={styles.placeholderText} size={16}>
          No rates have been added in your account yet. Please
          proceed to adding a rate
        </Typo>
        {
          redirect
            ?
              <Link to="/grants/rates">
                <Button preIcon="plus" name="add-rate-placeholder">Add rate</Button>
              </Link>
            : <Button preIcon="plus" onClick={onAddRate} name="add-rate-placeholder">Add rate</Button>
        }
      </div>
    </div>
  );
}

export default Placeholder;
