import { FC } from 'react';
import { Route } from 'react-router-dom';
import ContractsInfo from './info';
import ContractPage from './contract';
import ContractYearPage from './contract/year';
import ContractsTotals from './totals';

const ComputerServicesIndex:FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/contracts/general-info"
        exact
        component={ContractsInfo}
      />
      <Route
        path="/grants/:id/otherDC/contracts/:contractId/edit"
        exact
        component={ContractPage}
      />
      <Route
        path="/grants/:id/otherDC/contracts/:contractId/year/:year"
        exact
        component={ContractYearPage}
      />
      <Route
        path="/grants/:id/otherDC/contracts/totals"
        exact
        component={ContractsTotals}
      />
    </>
  )
}

export default ComputerServicesIndex;
