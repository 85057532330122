import {
  CivilService,
  Equip,
  FullGrant,
  PostDoctoralPersonal,
  Travel,
  SeniorPersonal,
  TransportationMethod, TravelCostData, GrantEvent, GrantEventParticipant
} from 'store/grants/types';
import {get} from 'lodash';
import {ComputerService} from 'store/grants/otherDirectCosts/ComputerService/types';
import {PublicationsWithId, PublicationsUnit} from 'store/grants/otherDirectCosts/Publications/types';
import {Contract} from 'store/grants/otherDirectCosts/Contracts/types';
import {Payment} from 'store/grants/otherDirectCosts/Payments/types';
import {OtherExpenseType, OtherExpenseWithId} from 'store/grants/otherDirectCosts/OtherExpenses/types';
import {MaterialsSuppliesUnitType} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
import {ConsultantServiceType, ConsultantServiceWithId} from 'store/grants/otherDirectCosts/ConsultantServices/types';
import {FeeItemType, FeeWithId} from 'store/grants/fees/types';
import {CostSharingExpenseItemType, CostSharingExpenseWithId} from 'store/grants/costSharing/types';
import {Subaward} from 'store/grants/otherDirectCosts/Subawards/types';

type PersonWithId = {
  person: SeniorPersonal | undefined;
  index: number;
}
type PostDocWithId = {
  person: PostDoctoralPersonal | undefined;
  index: number;
}
type OtherPersonWithId = {
  person: CivilService | undefined;
  index: number;
  count: number
}
type EquipmentUnitWithId = {
  unit: Equip | undefined;
  index: number;
  count: number
}

type TravelWithId = {
  trip: Travel | undefined;
  index: number;
  count: number
}

type TransportationMethodWithId = {
  method: TransportationMethod | undefined;
  index: number;
  count: number
}

type TravelCostWithId = {
  cost: TravelCostData | undefined;
  index: number;
  count: number
}

type MaterialsSuppliesUnitWithId = {
  item: MaterialsSuppliesUnitType | undefined;
  index: number;
  count: number
}

type ComputerServiceWithId = {
  item: ComputerService | undefined;
  index: number;
  count: number
}

type ContractWithId = {
  item: Contract | undefined;
  index: number;
  count: number
}

type SubawardWithId = {
  item: Subaward | undefined;
  index: number;
  count: number
}

type PaymentWithId = {
  item: Payment | undefined;
  index: number;
  count: number
}

type GrantEventWithId = {
  item: GrantEvent | undefined;
  index: number;
  count: number
}

type GrantEventParticipantWithId = {
  item: GrantEventParticipant | undefined;
  index: number;
  count: number
}

export const getSeniorPersonFromGrant = (grant: FullGrant, id: string): PersonWithId => {
  const person = grant.seniorPersonal.find((item: SeniorPersonal) => item.id === id);
  const index = grant.seniorPersonal.findIndex((item: SeniorPersonal) => item.id === id);
  return {
    person,
    index: index + 1
  }
}
export const getAcademicResearchPersonFromGrant = (grant: FullGrant, id: string): PersonWithId => {
  const person = grant.academicResearchAssociate.find((item: SeniorPersonal) => item.id === id);
  const index = grant.academicResearchAssociate.findIndex((item: SeniorPersonal) => item.id === id);
  return {
    person,
    index: index + 1
  }
}

export const getPostDocPersonFromGrant = (grant: FullGrant, id: string): PostDocWithId => {
  const person = grant.postDocPersonnel.find((item: PostDoctoralPersonal) => item.id === id);
  const index = grant.postDocPersonnel.findIndex((item: PostDoctoralPersonal) => item.id === id);
  return {
    person,
    index: index + 1
  }
}

export type OtherPersonFieldForSearch = 'civilService' | 'unionPersonnel' | 'tempAndCasual' | 'otherPersonnel';
export const getCivilServiceGrant = (grant: FullGrant, field: OtherPersonFieldForSearch, id: string): OtherPersonWithId => {
  const list = get(grant, field, []);
  const person = list.find((item: CivilService) => item.id === id);
  const index = list.findIndex((item: CivilService) => item.id === id);
  return {
    person,
    index: index + 1,
    count: list.length
  }
}

export const getEquipmentFromGrant = (grant: FullGrant, id: string): EquipmentUnitWithId => {
  const unit = grant?.equipment.find((item: Equip) => item.id === id);
  const index = grant?.equipment.findIndex((item: Equip) => item.id === id);
  return {
    unit,
    index: index + 1,
    count: grant?.equipment.length ?? 0
  }
}

export const getEventFromGrant = (grant: FullGrant, id: string): GrantEventWithId => {
  const item = grant.events.find((item: GrantEvent) => item.id === id);
  const index = grant.events.findIndex((item: GrantEvent) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.equipment.length ?? 0
  }
}

export const getParticipantFromGrantEvent = (grantEvent: GrantEvent, id: string): GrantEventParticipantWithId => {
  const item = grantEvent.participants.find((item: GrantEventParticipant) => item.id === id);
  const index = grantEvent.participants.findIndex((item: GrantEventParticipant) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grantEvent.participants.length ?? 0
  }
}

export const getTravelFromGrant = (grant: FullGrant, id: string): TravelWithId => {
  const trip = grant?.travel.find((item: Travel) => item.id === id);
  const index = grant?.travel.findIndex((item: Travel) => item.id === id);
  return {
    trip,
    index: index + 1,
    count: grant?.travel.length ?? 0
  }
}

export const getTransportationMethodFromTravel = (travel: Travel, id: string): TransportationMethodWithId => {
  const method = travel?.transportationMethods.find((item: TransportationMethod) => item.id === id);
  const index = travel?.transportationMethods.findIndex((item: TransportationMethod) => item.id === id);
  return {
    method,
    index: index + 1,
    count: travel?.transportationMethods.length ?? 0
  }
}

export const getTransportationMethodFromGrant = (grant: FullGrant, travelId: string, transportationMethodId: string): TransportationMethodWithId => {
  const {trip} = getTravelFromGrant(grant, travelId);
  const noFoundTripTransportationMethodItem = {
    method: undefined,
    index: 0,
    count: 0
  }

  return trip ? getTransportationMethodFromTravel(trip, transportationMethodId) : noFoundTripTransportationMethodItem;
}

export const getCostFromTravel = (travel: Travel, id: string): TravelCostWithId => {
  const cost = travel?.costs.find((item: TravelCostData) => item.id === id);
  const index = travel?.costs.findIndex((item: TravelCostData) => item.id === id);
  return {
    cost,
    index: index + 1,
    count: travel?.costs.length ?? 0
  }
}

export const getTravelCostFromGrant = (grant: FullGrant, travelId: string, costId: string): TravelCostWithId => {
  const {trip} = getTravelFromGrant(grant, travelId);
  const noFoundTripCostItem = {
    cost: undefined,
    index: 0,
    count: 0
  }

  return trip ? getCostFromTravel(trip!, costId) : noFoundTripCostItem;
}

export const getMaterialsSuppliesUnitFromGrant = (grant: FullGrant, id: string): MaterialsSuppliesUnitWithId => {
  const item = grant.otherDirectCosts.materialsAndSupplies.units.find((item) => item.id === id);
  const index = grant.otherDirectCosts.materialsAndSupplies.units.findIndex((item) => item.id === id)

  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.materialsAndSupplies.units.length ?? 0
  }
}

export const getComputerServiceFromODC = (grant: FullGrant, id: string): ComputerServiceWithId => {
  const item = grant.otherDirectCosts.computerServices.services.find((item: ComputerService) => item.id === id);
  const index = grant.otherDirectCosts.computerServices.services.findIndex((item: ComputerService) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.computerServices.services.length ?? 0
  }
}

export const getPublicationUnitFromGrant = (grant: FullGrant, id: string): PublicationsWithId => {
  const item = grant.otherDirectCosts.publications.units.find((item: PublicationsUnit) => item.id === id);
  const index = grant.otherDirectCosts.publications.units.findIndex((item: PublicationsUnit) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.publications.units.length ?? 0
  }
}

export const getFeeItemFromGrant = (grant: FullGrant, id: string): FeeWithId => {
  const item = grant.fees.find((item: FeeItemType) => item.id === id);
  const index = grant.fees.findIndex((item: FeeItemType) => item.id === id);

  return {
    item,
    index: index + 1,
    count: grant.fees.length ?? 0
  }
}

export const getConsultantServiceFromGrant = (grant: FullGrant, id: string): ConsultantServiceWithId => {
  const item = grant.otherDirectCosts.consultantServices.find((item: ConsultantServiceType) => item.id === id);
  const index = grant.otherDirectCosts.consultantServices.findIndex((item: ConsultantServiceType) => item.id === id);

  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.consultantServices.length ?? 0
  }
}

export const getCostSharingExpenseFromGrant = (grant: FullGrant, id: string): CostSharingExpenseWithId => {
  const item = grant.costSharingExpenses.find((item: CostSharingExpenseItemType) => item.id === id);
  const index = grant.costSharingExpenses.findIndex((item: CostSharingExpenseItemType) => item.id === id);

  return {
    item,
    index: index + 1,
    count: grant.costSharingExpenses.length ?? 0
  }
}

export const getOtherExpenseFromGrant = (grant: FullGrant, id: string): OtherExpenseWithId => {
  const item = grant.otherDirectCosts.allOtherCosts.expenses.find((item: OtherExpenseType) => item.id === id);
  const index = grant.otherDirectCosts.allOtherCosts.expenses.findIndex((item: OtherExpenseType) => item.id === id);

  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.allOtherCosts.expenses.length ?? 0
  }
}

export const getContractFromODC = (grant: FullGrant, id: string): ContractWithId => {
  const item = grant.otherDirectCosts.contracts.find((item: Contract) => item.id === id);
  const index = grant.otherDirectCosts.contracts.findIndex((item: Contract) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.contracts.length ?? 0
  }
}

export const getSubawardFromODC = (grant: FullGrant, id: string): SubawardWithId => {
  const item = grant.otherDirectCosts.subawards.find((item: Subaward) => item.id === id);
  const index = grant.otherDirectCosts.subawards.findIndex((item: Subaward) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.subawards.length ?? 0
  }
}


export const getPaymentFromODC = (grant: FullGrant, id: string): PaymentWithId => {
  const item = grant.otherDirectCosts.incentivePayments.find((item: Payment) => item.id === id);
  const index = grant.otherDirectCosts.incentivePayments.findIndex((item: Payment) => item.id === id);
  return {
    item,
    index: index + 1,
    count: grant.otherDirectCosts.incentivePayments.length ?? 0
  }
}

