import {FC, useMemo} from 'react';
import { shuffle } from 'lodash';
import Carousel from 'react-multi-carousel';
import styles from './signin.module.css';
import cx from 'classnames';
import logo from 'images/logo_white.svg';
import b from '../images/b.svg';
import Typo from 'components/typo';
import dayjs from 'dayjs';
import img1 from './images/1.png';
import img2 from './images/2.png';
import img4 from './images/4.png';
import img6 from './images/6.png';
import img8 from './images/8.png';
import img9 from './images/9.png';
import img10 from './images/10.png';
import img12 from './images/12.png';

const list = [img1, img4, img2, img6, img8, img9, img10, img12];
const Slideshow:FC = () => {
  const images = useMemo(() => shuffle(list), []);

  const year = dayjs().get('year').toString();
  return (
    <div className={cx(styles.leftSide, styles.side)}>
      <div className={styles.carouselWrapper}>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={60000}
          autoPlay
          centerMode={false}
          className={styles.slideshow}
          containerClass={styles.sliderContainer}
          dotListClass=""
          draggable={false}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl={false}
          minimumTouchDrag={80}
          pauseOnHover={false}
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 1
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 1
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {images.map((img: string, idx: number) => (
            <img draggable={false} alt={`sign-in-img-${idx}`} src={img} key={idx} className={styles.slideImage} />
          ))}
        </Carousel>
      </div>
      <img src={logo} alt="logo" draggable={false} className={styles.logo}/>
      <img src={b} alt="b" draggable={false} className={styles.b}/>
      <Typo className={styles.year}>{year} © Budget Mentor</Typo>
    </div>
  )
}

export default Slideshow;
