import {ChangeEvent, FC, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useTravel from 'hooks/useTravel';
// types
import {ETravelType, TravelYear, TripAttendees} from 'store/grants/types';
import {Props} from './index';
// components
import Typo from 'components/typo';
import AttendeesForm from 'pages/grant/one/travel/common/attendee';
import TravelCost48ConsultantAttendee from './48Consultant';
import TravelCostOtherConsultantAttendee from './otherConsultant';
// styles
import styles from './attendee.module.css';

const TravelCost48Attendee:FC<Props> = ({trip, cost, grant}) => {
  const { loading, previewTravelCost, updateTravelCost, errors } = useTravel();
  const params:Record<string, string> = useParams();
  const defaultYears = trip.status.years.length > 0
    ? trip.status.years.map((year: TravelYear) => ({
      year: year.year,
      serialNumber: year.serialNumber,
      isEnabled: false,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }))
    :
    grant.years.map((year: string[], serialNumber: number) => ({
      year,
      serialNumber: serialNumber + 1,
      isEnabled: false,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }));

  const defaultData = {
    postDocs: trip.status.postDocs ?? 0,
    graduateStudents: trip.status.graduateStudents ?? 0,
    undergraduateStudents: trip.status.undergraduateStudents ?? 0,
    civilService: trip.status.civilService ?? 0,
    union: trip.status.union ?? 0,
    tempAndCasual: trip.status.tempAndCasual ?? 0,
    otherPersonnel: trip.status.otherPersonnel ?? 0,
    seniorPersonnel: trip.status.seniorPersonnel.map((item: any) => item?.id) ?? [],
    academicResearchAssociate: trip.status.academicResearchAssociate.map((item: any) => item?.id) ?? [],
  }

  const [data, onChangeData] = useState<TripAttendees>({
    postDocs: cost?.tripAttendees?.postDocs || 0,
    compensationBeforeInflation: cost?.tripAttendees?.compensationBeforeInflation || '',
    graduateStudents: cost?.tripAttendees?.graduateStudents || 0,
    undergraduateStudents: cost?.tripAttendees?.undergraduateStudents || 0,
    civilService: cost?.tripAttendees?.civilService || 0,
    totalAmountForTrip: cost?.tripAttendees?.totalAmountForTrip || '',
    union: cost?.tripAttendees?.union || 0,
    tempAndCasual: cost?.tripAttendees?.tempAndCasual || 0,
    otherPersonnel: cost?.tripAttendees?.otherPersonnel || 0,
    years: cost && cost.tripAttendees.years.length > 0 ? cost.tripAttendees.years : defaultYears,
    seniorPersonnel: cost?.tripAttendees?.seniorPersonnel?.length > 0 ? cost?.tripAttendees?.seniorPersonnel.map((item: any) => item?.id) : [],
    academicResearchAssociate: cost?.tripAttendees?.academicResearchAssociate?.length > 0 ? cost?.tripAttendees?.academicResearchAssociate.map((item: any) => item?.id) : [],
  });

  const onBlur = useCallback((event?: ChangeEvent<HTMLInputElement>, value?: TripAttendees) => {
    const submitData = value ?? data;
    if (grant.id && cost) {
      previewTravelCost(grant.id, trip.id, cost.id, {
        tripAttendees: {
          ...submitData,
          seniorPersonnel: submitData.seniorPersonnel.map((id: string) => ({id})),
          academicResearchAssociate: submitData.academicResearchAssociate.map((id: string) => ({id})),
        }
      }, (response: any) => {
        onChangeData({
          ...submitData,
          ...response.tripAttendees,
          seniorPersonnel: response.tripAttendees.seniorPersonnel.map((item: Record<'id', string>) => item.id),
          academicResearchAssociate: response.tripAttendees.academicResearchAssociate.map((item: Record<'id', string>) => item.id),
        });
      });
    }
  }, [previewTravelCost, data, cost, grant.id, trip]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    onChangeData({
      ...data,
      ...field
    });
    if (preview) {
      onBlur(undefined,{
        ...data,
        ...field
      })
    }
  }, [data, onBlur]);

  const onCopy = useCallback(() => {
    let newYears = data.years;
    if (trip.status.years.length > 0) {
      newYears = trip.status.years.map((year: TravelYear) => ({
        year: year.year,
        serialNumber: year.serialNumber,
        isEnabled: year.isEnabled,
        numberOfOccurrence: '',
        compensationAfterInflation: '',
        compensationBeforeInflation: '',
        amountWithInflation: '',
      }))
    }
    const newData = {
      ...data,
      ...defaultData,
      years: newYears
    };
    onChangeData(newData);
    onBlur(undefined, newData);
  }, [data, trip, onBlur, defaultData]);

  const onSubmit = useCallback(() => {
    if (trip.id && cost) {
      updateTravelCost(grant.id, trip.id, params.costId, {
        tripAttendees: {
          ...data,
          seniorPersonnel: data.seniorPersonnel.map((id: string) => ({id})),
          academicResearchAssociate: data.academicResearchAssociate.map((id: string) => ({id})),
        }
      })
    }
  }, [data, updateTravelCost, cost, params, grant.id, trip]);

  if (trip.type === ETravelType.Consultant && cost.kind === 'fourty8states') {
    return <TravelCost48ConsultantAttendee grant={grant} cost={cost} trip={trip}/>
  }
  if (trip.type === ETravelType.Consultant && cost.kind === 'other') {
    return <TravelCostOtherConsultantAttendee grant={grant} cost={cost} trip={trip}/>
  }

  return (
    <div>
      <Typo className={styles.mb16}>Please check all of the PI's, Co-PI's, and Academic Research Associates who you
        would like to list as "on travel status" for this trip. This is a formality and will have little result in the
        final budget however if at any point they will be on a leg of this trip you are budgeting for, you should be
        sure to check their name.</Typo>
      <Typo className={styles.mb20}>Only people who are "on travel status" can have costs on trips covered. All
        exceptions for this rule are taken into account in a later section under this trip.</Typo>
      <AttendeesForm onCopy={onCopy} onBlur={onBlur} loading={loading} errors={errors} onSubmit={onSubmit} data={data} onChange={handleChangeData}/>
    </div>
  );
}


export default TravelCost48Attendee;
