import I from 'seamless-immutable';
import {
  CivilService,
  CivilServiceNSF,
  Dashboard,
  Equip,
  EquipNsfCompensation,
  ETravelType,
  FullGrant,
  GraduateStudent,
  Grant,
  GrantEvent,
  GrantEventParticipant,
  GrantEventParticipantExpense,
  GrantEventParticipantExpenseYear,
  GrantEventParticipantsTotal,
  GrantEventParticipantTotal,
  GrantEventParticipantYear,
  GrantEventTotal,
  GrantEventTotalEvent,
  GrantEventTotalEventYear,
  GrantsState,
  IEBreakdown,
  IEBreakdownSeason,
  List,
  MaIEBreakdown,
  MaIEBreakdownMonth,
  PersonalNSFCompensation,
  PostDoctoralPersonal,
  SeniorPersonal,
  Stat,
  StatGrant,
  Total,
  TransportationMethod,
  TransportationMethodDetails,
  TransportationMethodYear,
  Travel,
  TravelCostData,
  TravelCostLodgingData,
  TravelCostLodgingMonth,
  TravelCostMealExpenses,
  TravelCostMealExpensesSeason,
  TravelCostSeason,
  TravelCostsTotals,
  TravelCostYear,
  TravelStatus,
  TravelYear,
  UnGraduateStudent,
  AppliedType, Rate,
  UnGraduateStudentNSFCompensation, RatePreview, SidebarTotals, TravelConsultantServiceProviders
} from './types';
// Materials and Supplies
import {materialsSuppliesAdapter} from "./otherDirectCosts/MaterialsSupplies/adapters";
import {computerServicesAdapter} from "./otherDirectCosts/ComputerService/adapters";
import {publicationsAdapter} from './otherDirectCosts/Publications/adapters';
import {feesAdapter} from './fees/adapters';
import {costSharingAdapter} from './costSharing/adapters';
import {otherExpensesAdapter} from './otherDirectCosts/OtherExpenses/adapters';
import {contractsAdapter} from "./otherDirectCosts/Contracts/adapters";
import {paymentsAdapter} from './otherDirectCosts/Payments/adapters';
import {subawardeeFormAdapter, subawardsAdapter} from './otherDirectCosts/Subawards/adapters';
import {SubAwardeeForm} from './otherDirectCosts/Subawards/types';
import {consultantServicesAdapter} from './otherDirectCosts/ConsultantServices/adapters';

export const grantInListAdapter = (data: any): Grant => {
  return data;
}

export const grantListAdapter = (data: List) => {
  return I({
    data: data.data.map(grantInListAdapter),
    next: Boolean(data.next),
    page: data.page,
    perPage: data.perPage,
    total: data.total,
  });
}

export const statGrantAdapter = (data: StatGrant) => {
  return I({
    id: data.id,
    title: data.title,
    type: data.type,
    startDate: data.startDate,
    step: data.step ?? '',
    completion: data.completion ?? 0,
    status: data.status ?? '',
    isOngoing: Boolean(data.isOngoing),
    acceptedForm: Boolean(data.acceptedForm),
    endDate: data.endDate,
    nsf: {
      solicitationName: data.nsf?.solicitationName ?? '',
      solicitationNumber: data.nsf?.solicitationNumber ?? '',
      directorate: data.nsf?.directorate ?? '',
      division: data.nsf?.division ?? '',
    },
    organization: {
      name: data.organization?.name ?? ''
    },
    primaryInvestigator: {
      firstName: data.primaryInvestigator?.firstName ?? '',
      lastName: data.primaryInvestigator?.lastName ?? ''
    }
  })
}

export const statAdapter = (data: Stat) => {
  return I({
    count: data.count,
    grants: data.grants.map(statGrantAdapter),
    step: data.step,
  });
}

export const RateAdapter = (data: Rate) => {
  return {
    id: data.id ?? '',
    rate: data.rate ? (Number(data.rate) * 100).toFixed(2) : '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    organizationName: data.organizationName ?? '',
    location: data.location ?? '',
    comment: data.comment ?? '',
    isOverlays: Boolean(data.isOverlays),
    isGrantExclusive: Boolean(data.isGrantExclusive),
    createdAt: data.createdAt ?? '',
    appliedToGrants: data.appliedToGrants?.map((item: AppliedType) => ({
      id: item.id ?? '',
      title: item.title ?? '',
      isAccepted: Boolean(item.isAccepted),
    })) || []
  }
}

export const statsAdapter = (data: Stat[]) => {
  return I(data.map(statAdapter));
}

export const nsfCompensationAdapter = (data: PersonalNSFCompensation) => {
  return {
    year: data.year ?? [],
    kind: data.kind ?? '',
    serialNumber: data.serialNumber ?? 1,
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    equivalentNumberOfMonths: data.equivalentNumberOfMonths ?? '',
    numberToReceiveStipends: data.numberToReceiveStipends ?? undefined,
    numberOfStudents: data.numberOfStudents ?? undefined,
    numberToReceiveTuitionCoverage: data.numberToReceiveTuitionCoverage ?? undefined,
    numberOfStudentsSummer: data.numberOfStudentsSummer ?? undefined,
    percent: data.percent ?? undefined,
    months: data.months ?? undefined,
    percentForAccountOwner: data.percentForAccountOwner ?? undefined,
    monthsFromCurrentGrant: data.monthsFromCurrentGrant ?? undefined,
    monthsInYear: data.monthsInYear ?? undefined,
    availableMonths: data.availableMonths ?? undefined,
    monthlyCompensationBasedOnSalary: data.monthlyCompensationBasedOnSalary ?? undefined,
    monthsAvailableAfterRequest: data.monthsAvailableAfterRequest ?? undefined,
    nsfCompensatedMonths: data.nsfCompensatedMonths ?? undefined,
    totalCompensationAfterInflation: data.totalCompensationAfterInflation ?? undefined,
    totalCompensationBeforeInflation: data.totalCompensationBeforeInflation ?? undefined,
    uncappedMonths: data.uncappedMonths ?? undefined,
  };
}

export const seniorPersonalAdapter = (data: SeniorPersonal) => {
  return {
    id: data.id ?? '',
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    positionTitle: data.positionTitle ?? '',
    subGroup: data.subGroup ?? '',
    jobCategory: data.jobCategory ?? '',
    department: data.department ?? '',
    fringeBenefitRate: data.fringeBenefitRate || undefined,
    isRequestingSupport: Boolean(data.isRequestingSupport),
    isAccountOwner: Boolean(data.isAccountOwner),
    salary: {
      amount: data.salary?.amount ?? undefined,
      startDate: data.salary?.startDate ?? undefined,
      endDate: data.salary?.endDate ?? undefined,
      monthCompensatedByOrganization: data.salary?.monthCompensatedByOrganization ?? undefined,
    },
    nsfCompensation: data.nsfCompensation ? data.nsfCompensation.map(nsfCompensationAdapter) : [],
    role: data.role ?? undefined,
  }
}

export const seniorPersonalsAdapter = (data: SeniorPersonal[] = []) => {
  return data.map(seniorPersonalAdapter);
}

export const postDoctoralAdapter = (data?: PostDoctoralPersonal) => {
  return {
    id: data?.id ?? '',
    positionTitle: data?.positionTitle ?? undefined,
    currentBaseSalary: data?.currentBaseSalary ?? undefined,
    personsFringeBenefit: data?.personsFringeBenefit ?? undefined,
    responsibilities: data?.responsibilities ?? '',
    isAccountOwner: Boolean(data?.isAccountOwner),
    nsfCompensation: data?.nsfCompensation ? data.nsfCompensation.map(nsfCompensationAdapter) : []
  }
}

export const graduateStudentsAdapter = (data?: GraduateStudent) => {
  return {
    isAccountOwner: Boolean(data?.isAccountOwner),
    academicTerm: data?.academicTerm ?? undefined,
    numberOfAcademicTerms: data?.numberOfAcademicTerms ?? undefined,
    fringeBenefit: data?.fringeBenefit ?? undefined,
    stipendAwardPeriod: data?.stipendAwardPeriod ?? undefined,
    totalAmount: data?.totalAmount ?? 0,
    stipendPerYear: data?.stipendPerYear ?? undefined,
    stipendForEachAcademicTerm: data?.stipendForEachAcademicTerm ?? undefined,
    stipendForSummer: data?.stipendForSummer ?? undefined,
    annualInfo: data?.annualInfo ? data.annualInfo.map(nsfCompensationAdapter) : [],
    tuitionForEachAcademicTerm: data?.tuitionForEachAcademicTerm ?? undefined,
  }
}

export const underGraduatedStudentNSFCompensation = (data: UnGraduateStudentNSFCompensation) => {
  return {
    year: data.year,
    serialNumber: data.serialNumber,
    academicTerm: {
      numberOfStudents: data.academicTerm?.numberOfStudents ?? undefined,
      numberOfHours: data.academicTerm?.numberOfHours ?? undefined,
      numberOfWeeks: data.academicTerm?.numberOfWeeks ?? undefined,
      percentForAccountOwner: data.academicTerm?.percentForAccountOwner ?? undefined,
      totalAmountBeforeInflation: data.academicTerm?.totalAmountBeforeInflation ?? undefined,
      totalAmountAfterInflation: data.academicTerm?.totalAmountAfterInflation ?? undefined,
      totalAmountForAccountOwnerAfterInflation: data.academicTerm?.totalAmountForAccountOwnerAfterInflation ?? undefined,
    },
    summer: {
      numberOfStudents: data.summer?.numberOfStudents ?? undefined,
      numberOfHours: data.summer?.numberOfHours ?? undefined,
      numberOfWeeks: data.summer?.numberOfWeeks ?? undefined,
      percentForAccountOwner: data.summer?.percentForAccountOwner ?? undefined,
      totalAmountBeforeInflation: data.summer?.totalAmountBeforeInflation ?? undefined,
      totalAmountAfterInflation: data.summer?.totalAmountAfterInflation ?? undefined,
      totalAmountForAccountOwnerAfterInflation: data.summer?.totalAmountForAccountOwnerAfterInflation ?? undefined,
    }
  };
}

export const unGraduateStudentsAdapter = (data?: UnGraduateStudent) => {
  return {
    isAccountOwner: Boolean(data?.isAccountOwner),
    academicTerm: data?.academicTerm ?? undefined,
    totalAmount: data?.totalAmount ?? 0,
    numberOfAcademicTerms: data?.numberOfAcademicTerms ?? undefined,
    wageInAcademicTerm: data?.wageInAcademicTerm ?? undefined,
    numberOfWeekAcademicTerms: data?.numberOfWeekAcademicTerms ?? undefined,
    wageInSummer: data?.wageInSummer ?? undefined,
    loadLimitDuringAcademicTerm: data?.loadLimitDuringAcademicTerm ?? undefined,
    loadLimitDuringSummer: data?.loadLimitDuringSummer ?? undefined,
    nsfCompensation: data?.nsfCompensation ? data.nsfCompensation.map(underGraduatedStudentNSFCompensation) : [],
  }
}

export const civilServiceNSFAdapter = (data: CivilServiceNSF) => {
  return I({
    year: data.year ?? '',
    amountOfPeople: data.amountOfPeople ?? undefined,
    paidBy: data.paidBy ?? '',
    totalBudgeted: data.totalBudgeted ?? undefined,
    totalCompensation: data.totalBudgeted ?? undefined,
    totalForPosition: data.totalForPosition ?? undefined,
    details: {
      isSpecificWage: data.details?.isSpecificWage === undefined ? undefined : Boolean(data.details?.isSpecificWage),
      hourlyWage: data.details?.hourlyWage ?? undefined,
      reasonableWage: data.details?.reasonableWage ?? undefined,
      hoursPerWeek: data.details?.hoursPerWeek ?? undefined,
      weeksPerYear: data.details?.weeksPerYear ?? undefined,
      annualSalary: data.details?.annualSalary ?? undefined,
      averagePercent: data.details?.averagePercent ?? undefined,
    }
  })
}

export const civilServiceAdapter = (data?: CivilService) => {
  return {
    id: data?.id ?? '',
    positionTitle: data?.positionTitle ?? '',
    jobCategory: data?.jobCategory ?? '',
    fringeBenefit: data?.fringeBenefit ?? undefined,
    state: data?.state ?? '',
    city: data?.city ?? '',
    country: data?.country ?? '',
    zipCode: data?.zipCode ?? '',
    nsfCompensation: data?.nsfCompensation ? data?.nsfCompensation.map(civilServiceNSFAdapter) : [],
  }
}

export const equipNsfCompensation = (data: EquipNsfCompensation) => {
  return {
    year: data.year,
    numberOfUnits: data.numberOfUnits ?? '',
    justification: data.justification ?? ''
  }
}

export const equipAdapter = (data: Equip) => {
  return {
    name: data.name ?? '',
    category: data.category ?? '',
    cost: data.cost ?? '',
    id: data.id ?? '',
    nsfCompensation: data.nsfCompensation ? data.nsfCompensation.map(equipNsfCompensation) : []
  }
}

export const equipmentAdapter = (data: Equip[] = []) => {
  return data.map(equipAdapter);
}

export const transportationMethodYear = (data: TransportationMethodYear) => {
  return {
    year: data.year ?? [],
    isEnabled: Boolean(data.isEnabled),
    numberOfOccurrence: data.numberOfOccurrence ?? '',
    numberOfParticipants: data.numberOfParticipants ?? '',
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    amountWithInflation: data.amountWithInflation ?? '',
    serialNumber: data.serialNumber ?? '',
  }
}

export const transportationMethodDetails = (data: TransportationMethodDetails) => {
  return {
    airCarrier: data?.airCarrier ?? '',
    isAirCarrierVerified: data?.isAirCarrierVerified,
    price: data?.price ?? '',
    compensationBeforeInflation: data?.compensationBeforeInflation ?? '',
    totalAmountForTickets: data?.totalAmountForTickets ?? '',
    eligibility: data?.eligibility ?? [],
    travellers: data?.travellers ?? '',
    gasPrice: data?.gasPrice ?? '',
    amountOfCompensationBeforeInflation: data?.amountOfCompensationBeforeInflation ?? '',
    gallons: data?.gallons ?? '',
    cabCost: data?.cabCost ?? '',
    city: data?.city ?? '',
    numberOfPeopleWithTicket: data?.numberOfPeopleWithTicket ?? '',
    numberOfTrips: data?.numberOfTrips ?? '',
    miles: data?.miles ?? '',
    ticketPrice: data?.ticketPrice ?? '',
    kind: data?.kind ?? '',
    numberOfUnits: data?.numberOfUnits ?? '',
    estimatedNumberOfCharters: data?.estimatedNumberOfCharters ?? '',
    unit: data?.unit ?? '',
    pricePerUnit: data?.pricePerUnit ?? '',
    charterCostByUnit: data?.charterCostByUnit ?? '',
    charterCost: data?.charterCost ?? '',
    tickets: data?.tickets ?? '',
    companyName: data?.companyName ?? '',
    numberOfRides: data?.numberOfRides ?? '',
    priceExplanation: data?.priceExplanation ?? '',
    milesPerGallon: data?.milesPerGallon ?? '',
    isRoundTrip: Boolean(data?.isRoundTrip),
    totalCost: data?.totalCost ?? '',
    totalPrice: data?.totalPrice ?? '',
  }
}

export const transportationMethodAdapter = (data: TransportationMethod) => {
  return {
    id: data.id ?? '',
    method: data.method ?? '',
    nameExpense: data.nameExpense ?? '',
    typeOfExpense: data.typeOfExpense ?? '',
    startLocation: data.startLocation ?? '',
    totalAmountForTrip: data.totalAmountForTrip ?? '',
    endLocation: data.endLocation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    years: data.years ? data.years.map(transportationMethodYear) : [],
    details: transportationMethodDetails(data.details),
  }
}

export const travelCostLodgingMonthAdapter = (data: TravelCostLodgingMonth, idx: number) => {
  return {
    month: data.month,
    index: idx,
    nights: data.nights,
    dailyRate: data.dailyRate,
    monthTotal: data.monthTotal,
    total: data.total,
  }
}


export const travelCostLodgingSeasonAdapter = (data: TravelCostSeason) => {
  return {
    serialNumber: data.serialNumber ?? '',
    startDateOfTravel: data.startDateOfTravel ?? '',
    endDateOfTravel: data.endDateOfTravel ?? '',
    lodgingRate: data.lodgingRate ?? '',
    nights: data.nights ?? '',
    doBudgetMaxAmount: Boolean(data.doBudgetMaxAmount),
    maxTotalAdjust: data.maxTotalAdjust ?? '',
    total: data.total ?? '',
    maxTotal: data.maxTotal ?? '',
  }
}

export const travelCostLodgingAdapter = (data: TravelCostLodgingData) => {
  return {
    doBudgetMaxAmount: data?.doBudgetMaxAmount ?? false,
    maxTotalAdjust: data?.maxTotalAdjust ?? '',
    maxTotal: data?.maxTotal ?? '',
    months: data?.months ? data.months.map(travelCostLodgingMonthAdapter) : [],
    seasons: data?.seasons ? data.seasons.map(travelCostLodgingSeasonAdapter) : []
  }
}

export const mAndIeBreakdownFullDaysAdapter = (data?: MaIEBreakdownMonth[]) => {
  if (data) {
    return data.map((item: MaIEBreakdownMonth, index: number) => ({
      total: item.total,
      index,
      month: item.month,
      dailyRate: item.dailyRate,
      numberOfDays: item.numberOfDays,
    }));
  }
  return [];
}
export const mAndIeBreakdownFullDayAdapter = (data?: MaIEBreakdownMonth) => {
  return {
    total: data?.total ?? '',
    month: data?.month ?? '',
    dailyRate: data?.dailyRate ?? '',
    numberOfDays: data?.numberOfDays ?? '',
  }
}
export const mAndIeBreakdownAdapter = (data: MaIEBreakdown) => {
  return {
    firstDay: {
      dailyRate: data?.firstDay?.dailyRate ?? '',
      isBeforehand: Boolean(data?.firstDay?.isBeforehand),
      total: data?.firstDay?.total ?? '',
    },
    fullDays: {
      months: mAndIeBreakdownFullDaysAdapter(data?.fullDays?.months ?? [])
    },
    lastDay: {
      dailyRate: data?.lastDay?.dailyRate ?? '',
      isBeforehand: Boolean(data?.lastDay?.isBeforehand),
      total: data?.lastDay?.total ?? '',
    },
    seasons: data?.seasons ? data?.seasons.map(travelCostMealExpensesSeasonAdapter) : [],
    doBudgetMaxAmount: Boolean(data?.doBudgetMaxAmount),
    total: data?.total ?? '',
    maxTotal: data?.maxTotal ?? '',
    maxTotalAdjust: data?.maxTotalAdjust ?? ''
  }
}

export const travelCostMealExpensesSeasonAdapter = (data: TravelCostMealExpensesSeason) => {
  return {
    days: data.days ?? '',
    total: data.total ?? '',
    maxTotal: data.maxTotal ?? '',
    startDate: data.startDate ?? undefined,
    endDate: data.endDate ?? undefined,
    serialNumber: data.serialNumber ?? 0,
    rate: data.rate ?? '',
    doBudgetMaxAmount: Boolean(data.doBudgetMaxAmount),
    maxTotalAdjust: data.maxTotalAdjust ?? '',
    discount: {
      amount: data.discount?.amount ?? '',
      threeQuarters: data.discount?.threeQuarters ?? '',
      isStartDateFollowsEndDate: Boolean(data.discount?.isStartDateFollowsEndDate),
      dailyAmountOfExpenses: data.discount?.dailyAmountOfExpenses ?? '',
      maxAmount: data.discount?.maxAmount ?? '',
    }
  }
}

export const travelCostMealExpensesAdapter = (data?: TravelCostMealExpenses) => {
  return {
    seasons: data?.seasons ? data?.seasons.map(travelCostMealExpensesSeasonAdapter) : []
  }
}

export const IEBreakdownAdapterSeasonAdapter = (data: IEBreakdownSeason) => {
  return {
    days: data.days ?? '',
    total: data.total ?? '',
    maxTotal: data.maxTotal ?? '',
    isDeparture: Boolean(data.isDeparture),
    isReturn: Boolean(data.isReturn),
    startDate: data.startDate ?? undefined,
    serialNumber: data.serialNumber ?? 1,
    endDate: data.endDate ?? undefined,
    rate: data.rate ?? '',
    doBudgetMaxAmount: Boolean(data.doBudgetMaxAmount),
    maxTotalAdjust: data.maxTotalAdjust ?? '',
    discount: {
      amount: data.discount?.amount ?? '',
      threeQuarters: data.discount?.threeQuarters ?? '',
      isStartDateFollowsEndDate: Boolean(data.discount?.isStartDateFollowsEndDate),
      dailyAmountOfExpenses: data.discount?.dailyAmountOfExpenses ?? '',
      maxAmount: data.discount?.maxAmount ?? '',
    }
  }
}

export const IEBreakdownAdapter = (data: IEBreakdown) => {
  return {
    seasons: data?.seasons ? data.seasons.map(IEBreakdownAdapterSeasonAdapter) : []
  }
}


export const costYearAdapter = (data: TravelCostYear, idx: number) => {
  return {
    totalAmountForTrip: data.totalAmountForTrip ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    seniorPersonnel: data.seniorPersonnel ?? [],
    academicResearchAssociate: data.academicResearchAssociate ?? [],
    postDocs: data.postDocs ?? '',
    numberOfOccurrence: data.numberOfOccurrence ?? '',
    isEnabled: Boolean(data.isEnabled),
    graduateStudents: data.graduateStudents ?? '',
    undergraduateStudents: data.undergraduateStudents ?? '',
    civilService: data.civilService ?? '',
    union: data.union ?? '',
    tempAndCasual: data.tempAndCasual ?? '',
    otherPersonnel: data.otherPersonnel ?? '',
    compensationBeforeInflationPreview: data.compensationBeforeInflationPreview ?? '',
    compensationAfterInflationPreview: data.compensationAfterInflationPreview ?? '',
    years: data.years ?? [],
    totalAmountOfPersons: data.totalAmountOfPersons ?? '',
    totalAmount: data.totalAmount ?? '',
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    typeOfExpenses: data.typeOfExpenses ?? '',
    year: data.year ?? [],
    serialNumber: idx + 1,
    justification: data.justification ?? '',
    numberOfConsultants: data.numberOfConsultants ?? '',
    isNotForPersons: Boolean(data.isNotForPersons),
  }
}

export const costAdapter = (data: TravelCostData) => {
  return {
    id: data.id ?? '',
    kind: data.kind ?? '',
    nameOfTrip: data.nameOfTrip ?? '',
    numberOfSeasons: data.numberOfSeasons ?? '',
    zip: data.zip ?? '',
    country: data.country ?? '',
    years: data.years ? data.years.map(costYearAdapter) : [],
    category: data.category ?? '',
    typeOfExpenses: data.typeOfExpenses ?? '',
    price: data.price ?? '',
    totalAmount: data.totalAmount ?? '',
    numberOfOccurrence: data.numberOfOccurrence ?? '',
    isPerPerson: Boolean(data.isPerPerson),
    state: data.state ?? '',
    mealsExpenses: travelCostMealExpensesAdapter(data?.mealsExpenses),
    tripAttendees: {
      seniorPersonnel: data.tripAttendees?.seniorPersonnel ?? [],
      academicResearchAssociate: data.tripAttendees?.academicResearchAssociate ?? [],
      postDocs: data.tripAttendees?.postDocs ?? 0,
      compensationBeforeInflation: data.tripAttendees?.compensationBeforeInflation ?? '',
      lodgingBreakdownAmount: data.tripAttendees?.lodgingBreakdownAmount ?? '',
      totalDiscountAmount: data.tripAttendees?.totalDiscountAmount ?? '',
      mAndIeBreakdownAmount: data.tripAttendees?.mAndIeBreakdownAmount ?? '',
      totalMAndIeBeforeInflation: data.tripAttendees?.totalMAndIeBeforeInflation ?? '',
      graduateStudents: data.tripAttendees?.graduateStudents ?? 0,
      undergraduateStudents: data.tripAttendees?.undergraduateStudents ?? 0,
      civilService: data.tripAttendees?.civilService ?? 0,
      union: data.tripAttendees?.union ?? 0,
      totalLodgingBeforeInflation: data.tripAttendees?.totalLodgingBeforeInflation ?? '',
      totalMealsBeforeInflation: data.tripAttendees?.totalMealsBeforeInflation ?? '',
      totalIeBeforeInflation: data.tripAttendees?.totalIeBeforeInflation ?? '',
      totalDiscount: data.tripAttendees?.totalDiscount ?? '',
      tempAndCasual: data.tripAttendees?.tempAndCasual ?? 0,
      otherPersonnel: data.tripAttendees?.otherPersonnel ?? 0,
      totalAmountForTrip: data.tripAttendees?.totalAmountForTrip ?? '',
      years: data.tripAttendees?.years ?? [],
    },
    city: data.city ?? '',
    fiscalYear: data.fiscalYear ?? '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    ieBreakdown: IEBreakdownAdapter(data.ieBreakdown),
    mAndIeBreakdown: mAndIeBreakdownAdapter(data.mAndIeBreakdown),
    lodgingBreakdown: travelCostLodgingAdapter(data.lodgingBreakdown),
    isStartEndDateKnown: Boolean(data.isStartEndDateKnown),
    totalsNote: data.totalsNote ?? '',
    totalAmountForTrip: data.totalAmountForTrip ?? '',
  }
}

export const costsAdapter = (data: TravelCostData[] = []) => {
  return data.map(costAdapter);
}

const travelYearAdapter = (year: TravelYear, idx: number) => {
  return {
    year: year.year,
    isEnabled: Boolean(year.isEnabled),
    serialNumber: year.serialNumber ?? idx + 1,
    numberOfConsultants: year.numberOfConsultants ?? ''
  }
}

export const travelStatusAdapter = (data: TravelStatus) => ({
  seniorPersonnel: data?.seniorPersonnel ?? [],
  academicResearchAssociate: data?.academicResearchAssociate ?? [],
  postDocs: data?.postDocs ?? 0,
  graduateStudents: data?.graduateStudents ?? 0,
  undergraduateStudents: data?.undergraduateStudents ?? 0,
  civilService: data?.civilService ?? 0,
  union: data?.union ?? 0,
  tempAndCasual: data?.tempAndCasual ?? 0,
  otherPersonnel: data?.otherPersonnel ?? 0,
  years: data?.years ? data.years.map(travelYearAdapter) : [],
});

export const travelTotalAdapter = (data?: Total) => {
  return {
    kind: data?.kind ?? '',
    fullName: data?.fullName ?? '',
    nameOfTrip: data?.nameOfTrip ?? '',
    totals: data?.totals ?? '',
    total: data?.total ?? '',
    years: data?.years ? data.years : []
  }
}
export const travelCostsTotalsAdapter = (data: TravelCostsTotals) => {
  return {
    domestic: data?.domestic ? data.domestic.map(travelTotalAdapter) : [],
    foreign: data?.foreign ? data.foreign.map(travelTotalAdapter) : [],
    domesticAndForeign: data?.domesticAndForeign ? data.domesticAndForeign.map(travelTotalAdapter) : []
  }
}

export const travelConsultantServiceProviderAdapter = (data: TravelConsultantServiceProviders) => ({
  id: data.id ?? '',
  name: data.name ?? '',
  consultantServiceId: data.consultantServiceId ?? '',
  yearSerialNumber: data.yearSerialNumber ?? '',
})

export const travelAdapter = (data: Travel) => {
  return I({
    tripName: data.tripName ?? '',
    id: data.id ?? '',
    type: data.type ?? ETravelType.GrantPersonnel,
    tripDescription: data.tripDescription ?? '',
    tripDefaultLocation: data.tripDefaultLocation ?? '',
    tripPurpose: data.tripPurpose ?? '',
    consultantServiceProviders: data.consultantServiceProviders && data.consultantServiceProviders?.length > 0 ? data.consultantServiceProviders.map(travelConsultantServiceProviderAdapter) : [],
    totalAmount: data.totalAmount ?? '',
    travelCostsTotalAmount: data.travelCostsTotalAmount ?? '',
    travelMethodsTotalAmount: data.travelMethodsTotalAmount ?? '',
    estimatedStartDate: data.estimatedStartDate ?? null,
    estimatedEndDate: data.estimatedEndDate ?? null,
    travelCostsAndMethodsTotals: travelCostsTotalsAdapter(data.travelCostsAndMethodsTotals),
    isConferenceTrip: Boolean(data.isConferenceTrip),
    costs: costsAdapter(data.costs),
    transportationMethods: data.transportationMethods ? data.transportationMethods.map(transportationMethodAdapter) : [],
    status: travelStatusAdapter(data.status),
    consultantServices: data?.consultantServices ?? []
  });
}

export const travelsAdapter = (data: Travel[]) => {
  return data.map(travelAdapter);
}

export const grantEventParticipantExpenseAdapter = (data: GrantEventParticipantExpense) => {
  return {
    amount: data.amount ?? '',
    totalAmountForTrip: data.totalAmountForTrip ?? '',
    id: data.id ?? '',
    name: data.name ?? '',
    isSubsistence: Boolean(data.isSubsistence),
    price: data.price ?? '',
    justification: data.justification ?? '',
    years: data.years?.map((item: GrantEventParticipantExpenseYear) => ({
      year: item.year ?? [],
      serialNumber: item.serialNumber ?? '',
      numberOfParticipants: item.numberOfParticipants ?? '',
      numberOfOccurrence: item.numberOfOccurrence ?? '',
      compensationBeforeInflation: item.compensationBeforeInflation ?? '',
      compensationAfterInflation: item.compensationAfterInflation ?? '',
      isEnabled: Boolean(item.isEnabled),
      amountWithInflation: item.amountWithInflation ?? '',
    }))
  }
}
export const grantEventParticipantExpensesAdapter = (data: GrantEventParticipantExpense[]) => {
  return data.map(grantEventParticipantExpenseAdapter)
}

export const grantEventParticipantAdapter = (data: GrantEventParticipant) => {
  return {
    id: data.id,
    category: data.category,
    travelMethods: data.travelMethods ? data.travelMethods.map(transportationMethodAdapter) : [],
    years: data.years ? data.years.map((item: GrantEventParticipantYear, idx: number) => ({
      serialNumber: item.serialNumber ?? idx + 1,
      year: item.year ?? [],
      kind: item.kind,
      doUse1stYearValue: Boolean(item.doUse1stYearValue),
      hourlyRate: item.hourlyRate ?? '',
      averageCompensationHours: item.averageCompensationHours ?? '',
      averageCompensationForPerson: item.averageCompensationForPerson ?? '',
      justification: item.justification ?? '',
      totalStipend: item.totalStipend ?? '',
      numberOfPeople: item.numberOfPeople ?? ''
    })) : [],
    expenses: grantEventParticipantExpensesAdapter(data.expenses ?? [])
  }
}

export const grantEventParticipantsAdapter = (data: GrantEventParticipant[] = []) => {
  return data.map(grantEventParticipantAdapter)
}

export const grantEventParticipantTotalItemAdapter = (data?: GrantEventParticipantTotal) => {
  if (data) {
    return {
      totals: data.totals ?? '',
      years: data.years ?? [],
      fullName: data.fullName
    }
  }
  return {
    totals: 0,
    years: [],
    fullName: ''
  }
}

export const grantEventParticipantTotalAdapter = (data: GrantEventParticipantsTotal) => {
  return {
    stipends: grantEventParticipantTotalItemAdapter(data.stipends),
    travel: grantEventParticipantTotalItemAdapter(data.travel),
    expenses: grantEventParticipantTotalItemAdapter(data.expenses),
    totals: grantEventParticipantTotalItemAdapter(data.totals),
    category: data.category
  }
}
export const grantEventParticipantsTotalAdapter = (data?: GrantEventParticipantsTotal[]) => {
  if (data) {
    return data.map(grantEventParticipantTotalAdapter)
  }
  return [];
}

export const grantEventAdapter = (data: GrantEvent) => {
  return {
    name: data.name ?? '',
    id: data.id,
    totalAmount: data.totalAmount ?? '',
    category: data.category,
    participants: grantEventParticipantsAdapter(data.participants),
  };
}

export const grantEventsAdapter = (data: GrantEvent[] = []) => {
  return data.map(grantEventAdapter)
}

export const grantEventTotalEventAdapter = (data: GrantEventTotalEvent) => {
  return {
    fullName: data.fullName,
    totals: data.totals,
    years: data.years.map((item: GrantEventTotalEventYear) => ({
      label: item.label ?? '',
      value: item.value ?? ''
    }))
  }
}

export const grantEventTotalAdapter = (data: GrantEventTotal) => {
  return {
    category: data.category,
    events: data.events.map(grantEventTotalEventAdapter)
  }
}

export const grantAdapter = (data: FullGrant) => {
  return I({
    id: data.id ?? '',
    createdAt: data.createdAt ?? '',
    updatedAt: data.updatedAt ?? '',
    budgetCap: data.budgetCap ?? '',
    budgetBar: {
      directCost: data.budgetBar?.directCost ?? '',
      directCostOverbudget: data.budgetBar?.directCostOverbudget ?? '',
      indirectCost: data.budgetBar?.indirectCost ?? '',
      indirectCostOverbudget: data.budgetBar?.indirectCostOverbudget ?? '',
    },
    doUseDurationLeftover: Boolean(data.doUseDurationLeftover),
    daysInGrant: data.daysInGrant ?? '',
    travelsTotalAmount: data.travelsTotalAmount ?? '',
    participantSupportTotalAmount: data.participantSupportTotalAmount ?? '',
    yearsInGrant: data.yearsInGrant ?? '',
    equipmentTotalAmount: data.equipmentTotalAmount ?? '',
    monthsInGrant: data.monthsInGrant ?? '',
    title: data.title ?? '',
    rolesCount: {
      coPi: 4 - (data.rolesCount?.coPi === undefined ? 0 : data.rolesCount.coPi),
      pi: 1 - (data.rolesCount?.pi === undefined ? 0 : data.rolesCount.pi),
    },
    step: data.step ?? '',
    completion: data.completion ?? 0,
    status: data.status ?? '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    type: data.type ?? '',
    events: grantEventsAdapter(data.events),
    primaryInvestigator: {
      firstName: data.primaryInvestigator?.firstName ?? '',
      lastName: data.primaryInvestigator?.lastName ?? '',
      id: data.primaryInvestigator?.id ?? '',
      fullName: data.primaryInvestigator?.fullName ?? '',
    },
    isBudgetSubwardOnAnother: data.isBudgetSubwardOnAnother,
    notes: data.notes ?? '',
    contactEmail: data.contactEmail ?? '',
    years: data.years ?? [],
    postdoctoralPersonal: postDoctoralAdapter(data.postdoctoralPersonal),
    postDocPersonnel: data.postDocPersonnel ? data.postDocPersonnel.map(postDoctoralAdapter) : [],
    nsf: {
      solicitationName: data.nsf?.solicitationName ?? '',
      solicitationNumber: data.nsf?.solicitationNumber ?? '',
      directorate: data.nsf?.directorate ?? null,
      division: data.nsf?.division ?? null,
      directorateOther: data.nsf?.directorateOther ?? '',
      divisionOther: data.nsf?.divisionOther ?? '',
    },
    organization: {
      name: data.organization?.name || '',
      id: data.organization?.id || '',
      subGroup: data.organization?.subGroup || '',
      department: data.organization?.department || '',
      address: {
        address1: data.organization?.address?.address1 || '',
        address2: data.organization?.address?.address2 || '',
        city: data.organization?.address?.city || '',
        country: data.organization?.address?.country || '',
        state: data.organization?.address?.state || '',
        zip: data.organization?.address?.zip || '',
      },
      academicYear: {
        startDate: data.organization?.academicYear?.startDate || null,
        endDate: data.organization?.academicYear?.endDate || null,
      },
      fiscalYear: {
        startDate: data.organization?.fiscalYear?.startDate || null,
        endDate: data.organization?.fiscalYear?.endDate || null,
        definition: data.organization?.fiscalYear?.definition || '',
      },
      annualSchedule: data.organization?.annualSchedule || null,
    },
    author: {
      firstName: data.author?.firstName || '',
      race: data.author?.race,
      personalReference: data.author?.personalReference ?? undefined,
      gender: data.author?.gender,
      birthdate: data.author?.birthdate || '',
      calculateAverageBudget: Boolean(data.author?.calculateAverageBudget),
      email: data.author?.email || '',
      positionTitle: data.author?.positionTitle || '',
      yearSalary: data.author?.yearSalary || undefined,
      yearlyMonthsSalaryCompensation: data.author?.yearlyMonthsSalaryCompensation || undefined,
      oneMonthCompensationAmount: data.author?.oneMonthCompensationAmount || undefined,
      lastName: data.author?.lastName || '',
    },
    acceptedForm: {
      awardDate: data.acceptedForm?.awardDate ?? undefined,
      awardNumber: data.acceptedForm?.awardNumber ?? '',
      totalDirectCosts: data.acceptedForm?.totalDirectCosts ?? '',
      totalIndirectCosts: data.acceptedForm?.totalIndirectCosts ?? '',
      uniqueEntityIdentifier: data.acceptedForm?.uniqueEntityIdentifier ?? '',
    },
    seniorPersonal: seniorPersonalsAdapter(data.seniorPersonal),
    equipment: equipmentAdapter(data.equipment),
    otherDirectCosts: {
      materialsAndSupplies: materialsSuppliesAdapter(data.otherDirectCosts?.materialsAndSupplies),
      computerServices: computerServicesAdapter(data.otherDirectCosts?.computerServices),
      publications: publicationsAdapter(data.otherDirectCosts?.publications),
      allOtherCosts: otherExpensesAdapter(data.otherDirectCosts?.allOtherCosts),
      contracts: contractsAdapter(data.otherDirectCosts?.contracts),
      incentivePayments: paymentsAdapter(data.otherDirectCosts?.incentivePayments),
      subawards: subawardsAdapter(data.otherDirectCosts?.subawards),
      consultantServices: consultantServicesAdapter(data.otherDirectCosts?.consultantServices),
      allOtherCostsTotalAmount: data.otherDirectCosts?.allOtherCostsTotalAmount ?? '',
      consultantServicesTotalAmount: data.otherDirectCosts?.consultantServicesTotalAmount ?? '',
      contractsTotalAmount: data.otherDirectCosts?.contractsTotalAmount ?? '',
      computerServicesTotalAmount: data.otherDirectCosts?.computerServicesTotalAmount ?? '',
      incentivePaymentsTotalAmount: data.otherDirectCosts?.incentivePaymentsTotalAmount ?? '',
      materialsAndSuppliesTotalAmount: data.otherDirectCosts?.materialsAndSuppliesTotalAmount ?? '',
      publicationsTotalAmount: data.otherDirectCosts?.publicationsTotalAmount ?? '',
      subawardsTotalAmount: data.otherDirectCosts?.subawardsTotalAmount ?? '',
    },
    fees: feesAdapter(data?.fees),
    costSharingExpenses: costSharingAdapter(data?.costSharingExpenses),
    academicResearchAssociate: seniorPersonalsAdapter(data.academicResearchAssociate),
    graduateStudents: graduateStudentsAdapter(data.graduateStudents),
    undergraduateStudents: unGraduateStudentsAdapter(data.undergraduateStudents),
    civilService: data.civilService ? data.civilService.map(civilServiceAdapter) : [],
    unionPersonnel: data.unionPersonnel ? data.unionPersonnel.map(civilServiceAdapter) : [],
    tempAndCasual: data.tempAndCasual ? data.tempAndCasual.map(civilServiceAdapter) : [],
    otherPersonnel: data.otherPersonnel ? data.otherPersonnel.map(civilServiceAdapter) : [],
    travel: data.travel ? travelsAdapter(data.travel) : [],
    civilServiceTotalAmount: data.civilServiceTotalAmount ?? '',
    academicResearchAssociateTotalAmount: data.academicResearchAssociateTotalAmount ?? '',
    costSharingTotalAmount: data.costSharingTotalAmount ?? '',
    feesTotalAmount: data.feesTotalAmount ?? '',
    otherPersonnelTotalAmount: data.otherPersonnelTotalAmount ?? '',
    postDocPersonnelTotalAmount: data.postDocPersonnelTotalAmount ?? '',
    seniorPersonnelTotalAmount: data.seniorPersonnelTotalAmount ?? '',
    tempAndCasualTotalAmount: data.tempAndCasualTotalAmount ?? '',
    unionPersonnelTotalAmount: data.unionPersonnelTotalAmount ?? '',
    participantSupportTotals: {
      events: data.participantSupportTotals?.events?.map(grantEventTotalAdapter) ?? []
    }
  });
}

export const dashboardAdapter = (data: Dashboard) => {
  return I({
    countAccepted: data.countAccepted ?? 0,
    personalBudget: data.personalBudget ?? 0,
    totalFunding: data.totalFunding ?? 0,
    countTotal: data.countTotal ?? 0,
    grantsStats: statsAdapter(data.grantsStats)
  });
}

export const ratePreviewAdapter = (data: RatePreview) => {
  return {
    overlay: {
      startDate: data?.overlay?.startDate ?? [],
      endDate: data?.overlay?.endDate ?? [],
    },
    uncoveredPeriods: data.uncoveredPeriods ?? []
  }
}

export const sidebarTotalsAdapter = (data: SidebarTotals) => {
  return {
    id: data?.id ?? '',
    createdAt: data?.createdAt ?? '',
    isAccepted: Boolean(data?.isAccepted),
    isOngoing: Boolean(data?.isOngoing),
    rolesCount: data.rolesCount ?? {},
    primaryInvestigator: data?.primaryInvestigator ?? {},
    years: data?.years ?? [],
    isPrimaryGeneralInfoComplete: Boolean(data?.isPrimaryGeneralInfoComplete),
    yearsInGrant: data?.yearsInGrant ?? 0,
    monthsInGrant: data?.monthsInGrant ?? 0,
    daysInGrant: data?.daysInGrant ?? 0,
    seniorPersonnelTotalAmount: data?.seniorPersonnelTotalAmount ?? 0,
    academicResearchAssociateTotalAmount: data?.academicResearchAssociateTotalAmount ?? 0,
    postDocPersonnelTotalAmount: data?.postDocPersonnelTotalAmount ?? 0,
    civilServiceTotalAmount: data?.civilServiceTotalAmount ?? 0,
    otherPersonnelTotalAmount: data?.otherPersonnelTotalAmount ?? 0,
    tempAndCasualTotalAmount: data?.tempAndCasualTotalAmount ?? 0,
    unionPersonnelTotalAmount: data?.unionPersonnelTotalAmount ?? 0,
    travelsTotalAmount: data?.travelsTotalAmount ?? 0,
    equipmentTotalAmount: data?.equipmentTotalAmount ?? 0,
    participantSupportTotalAmount: data?.participantSupportTotalAmount ?? 0,
    feesTotalAmount: data?.feesTotalAmount ?? 0,
    costSharingTotalAmount: data?.costSharingTotalAmount ?? 0,
    hasDurationLeftover: Boolean(data?.hasDurationLeftover),
    completion: data?.completion ?? 0,
  }
}


//@ts-ignore
export const grantsStateAdapter = (): GrantsState => I({
  list: {
    data: [],
    next: false,
    page: 1,
    perPage: 10,
    total: 0
  },
  dashboard: {
    countAccepted: 0,
    personalBudget: 0,
    countTotal: 0,
    grantsStats: []
  },
  subAwardScheme: subawardeeFormAdapter({} as SubAwardeeForm),
  one: grantAdapter({} as FullGrant),
  sides: [],
  sidebar: [],
  rates: {},
  ratesPreview: {},
  side: grantAdapter({} as FullGrant),
  editable: grantAdapter({} as FullGrant),
  stats: [],
  activeId: ''
});
