import {FC, useMemo} from 'react';
// hooks
import useUrl from 'hooks/useUrl';
// helpers
import {getTravelCostFromGrant} from 'helpers/getFromGrant';
// types
import {FullGrant, Travel, TravelCostData} from 'store/grants/types';
// components
import Cost from 'pages/grant/paper/card/folders/travel/components/cost';
import Folder from 'pages/grant/paper/card/folder';

type Props = {
  side: FullGrant;
  trip: Travel;
  list: {
    items: Array<TravelCostData>;
    listId: string;
    value: string;
    label: string;
  };
  handleRemoveCost: (id: string) => void;
};

const GroupedByCostKindList:FC<Props> = ({side, trip, list, handleRemoveCost}) => {
  const url = useUrl();
  const {cost} = getTravelCostFromGrant(side, trip.id, url.sectionId as string);

  const folderData = useMemo(() => ({
    label: list.label,
    type: list.value,
    id: list.listId,
    active: cost?.kind === list.value,
    open: cost?.kind === list.value
  }), [cost, list])

  return (
    <>
      {list.items.length === 1 && (
        <Cost
          sideId={side.id}
          trip={trip}
          cost={list.items[0]}
          showCostKind
          handleRemoveCost={handleRemoveCost}
        />
      )}

      {list.items.length > 1 && (
        <Folder type="section" grantId={side.id} icon item={folderData}>
          {list.items.map((cost: TravelCostData) => {

            return (
              <Cost
                key={cost.id}
                sideId={side.id}
                trip={trip}
                cost={cost}
                handleRemoveCost={handleRemoveCost}
              />
            )
          })}
        </Folder>
      )}
    </>
  );
}

export default GroupedByCostKindList;
