import { Route } from 'react-router-dom';
//
import CreateEditMSGeneralInfo from './createOrEditGeneralInfo';
import CreateEditMSUnit from './createOrEditUnit';
import CreateEditMSUnitYear from './createOrEditYear';
import MaterialsSuppliesTotals from './totals';

const MaterialsSuppliesIndex = () => {
    return (
            <>
                <Route
                    path="/grants/:id/otherDC/materials-and-supplies/general-info"
                    exact
                    component={CreateEditMSGeneralInfo}
                />
                <Route path="/grants/:id/otherDC/materials-and-supplies/create" exact component={CreateEditMSUnit} />
                <Route
                    path="/grants/:id/otherDC/materials-and-supplies/:unitId/edit"
                    exact
                    component={CreateEditMSUnit}
                />
                <Route
                    path="/grants/:id/otherDC/materials-and-supplies/:unitId/year/:yearId"
                    exact
                    component={CreateEditMSUnitYear}
                />
                <Route
                    path="/grants/:id/otherDC/materials-and-supplies/totals"
                    exact
                    component={MaterialsSuppliesTotals}
                />
            </>
    );
};

export default MaterialsSuppliesIndex;
