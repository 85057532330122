import {useCallback, useMemo, useState} from 'react';
import useApi from 'hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import toast from 'components/toast';
import { setEnumsAction, setAirlinesAction, setAirportsAction } from 'store/enums/actions';
import { ListItem, HardListItem } from 'helpers/utils';
import countriesList, { Country } from 'helpers/countries';
import {
  enumsSelector
} from 'store/enums/selector';
import useUI from './useUI';
import {grantSelector} from 'store/grants/selector';
import {SeniorPersonal} from 'store/grants/types';

type ZipData = {
  city: string;
  state: string;
}

type Airport = {
  country_code: string;
  iata_code: string;
  icao_code: string;
  name: string;
}

type Airline = {
  iata_code: string;
  icao_code: string;
  name: string;
}

type iUseEnums = {
  getEnums: () => void;
  fetchZIP: (zip: string, cb: (zipData: ZipData) => void) => void;
  genders: ListItem[];
  grantTypes: ListItem[];
  yesNoOptions: ListItem[];
  yesNoOptionsRevert: ListItem[];
  yesNoOptionsNumber: ListItem[];
  countries: ListItem[];
  airports: ListItem[];
  consultantServiceProviderTypes: ListItem[];
  isAirportsLoading: boolean;
  airlines: ListItem[];
  isAirlinesLoading: boolean;
  seniorPersonnel: ListItem[];
  academicPersonnel: ListItem[];
  races: ListItem[];
  travelCostCategories: ListItem[];
  isTravelCostCategoriesLoading: boolean;
  getSubs: (list: HardListItem[], value: any) => ListItem[];
  getEquipmentCategories: (name: string) => void;
  getTravelCostCategories: (name: string) => void;
  getGrantEventCategories: (name: string) => void;
  getGrantEventParticipantCategories: (name: string) => void;
  getAirports: () => void;
  getAirlines: () => void;
  derectorates: HardListItem[];
  grantSteps: HardListItem[];
  annualSchedules: ListItem[];
  studentTypesRoles: ListItem[];
  grantStatus: ListItem[];
  eventCategories: ListItem[];
  isEventCategoriesLoading: boolean;
  eventParticipantCategories: ListItem[];
  isEventParticipantCategoriesLoading: boolean;
  personRoles: ListItem[];
  travelExpensesTypes: ListItem[];
  travelTypes: ListItem[];
  consultantServiceProviderTravelCostsTypes: ListItem[];
  compensationType: ListItem[];
  eligibility: ListItem[];
  distanceUnits: ListItem[];
  timeUnits: ListItem[];
  jobCategory: {
    academicResearchAssociate: ListItem[]
    civilService: ListItem[]
  };
  travelCostsTypes: ListItem[];
  FARateStatuses: ListItem[];
  costSharingExpenseTypes: ListItem[];
  stipendAwardPeriod: ListItem[];
  civilServiceCompensation: ListItem[];
  transportationMethod: ListItem[];
  equipmentCategories: ListItem[];
  FARateLocations: ListItem[];
  isEquipmentCategoriesLoading: boolean;
}

const useEnums = ():iUseEnums => {
  const api = useApi();
  const { loader } = useUI();
  const dispatch = useDispatch();
  const enums = useSelector(enumsSelector);
  const grant = useSelector(grantSelector);
  const [ equipmentCategories, onChangeEquipmentCategories ] = useState<ListItem[]>([]);
  const [ isEquipmentCategoriesLoading, setIsEquipmentCategoriesLoading] = useState<boolean>(false);
  const [ travelCostCategories, onChangeTravelCostCategories ] = useState<ListItem[]>([]);
  const [ isTravelCostCategoriesLoading, setIsTravelCostCategoriesLoading] = useState<boolean>(false);
  const [ eventCategories, onChangeEventCategories ] = useState<ListItem[]>([]);
  const [ isEventCategoriesLoading, setIsEventCategoriesLoading] = useState<boolean>(false);
  const [ eventParticipantCategories, onChangeEventParticipantCategories ] = useState<ListItem[]>([]);
  const [ isEventParticipantCategoriesLoading, setIsEventParticipantCategoriesLoading] = useState<boolean>(false);
  const airlines = enums.airlines;
  const [isAirlinesLoading, setIsAirlinesLoading] = useState<boolean>(false);
  const airports = enums.airports;
  const [isAirportsLoading, setIsAirportsLoading] = useState<boolean>(false);

  const fetchZIP = useCallback((zip: string, cb: (zipData: ZipData) => void) => {
    if (zip === '') return;
    loader.start();
    api.fetchZIP(zip)
      .then((response: AxiosResponse) => {
        cb({
          city: response.data.city,
          state: response.data.state,
        });
        loader.stop();
      })
      .catch(() => {
        loader.stop();
        toast.info({
          title: 'Info',
          message: 'State and City not found with entered ZIP code'
        });
      })
  }, [api, loader]);

  const getEnums = useCallback(() => {
    api.getEnums()
      .then((response: AxiosResponse) => {
        dispatch(setEnumsAction(response.data));
      });
  }, [dispatch, api]);

  const getSubs = useCallback((list: HardListItem[], value: any):ListItem[] => {
    const currentSubsItem = list.find((item: HardListItem) => item.value === value);
    if (currentSubsItem) return currentSubsItem.sub;
    return [];
  }, []);

  const studentTypesRoles = [
    {value: 'PI', label: 'Primary investigator'},
    {value: 'other', label: 'Other role'},
  ];

  const countries = countriesList.map((item: Country) => ({
    label: item.name,
    value: item.code
  }));

  const yesNoOptions = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ]

  const yesNoOptionsRevert = [
    {value: false, label: 'No'},
    {value: true, label: 'Yes'},
  ]

  const yesNoOptionsNumber = [
    {value: 1, label: 'Yes'},
    {value: 0, label: 'No'}
  ];

  const getEquipmentCategories = useCallback((name: string) => {
    setIsEquipmentCategoriesLoading(true);
    api.getEquipmentCategories(name)
      .then((response: AxiosResponse) => {
        const list = (response.data || []).map((item: any) => ({
          label: item.name,
          value: item.name,
        }));
        onChangeEquipmentCategories(list)
      })
      .finally(() => setIsEquipmentCategoriesLoading(false));
  }, [api]);

  const getTravelCostCategories = useCallback((name: string) => {
    setIsTravelCostCategoriesLoading(true);
    api.getTravelCostCategories(name)
      .then((response: AxiosResponse) => {
        const list = (response.data || []).map((item: any) => ({
          label: item.name,
          value: item.name,
        }));
        onChangeTravelCostCategories(list)
      })
      .finally(() => setIsTravelCostCategoriesLoading(false));
  }, [api]);

  const getGrantEventCategories = useCallback((name: string) => {
    setIsEventCategoriesLoading(true);
    api.getGrantEventCategories(name)
      .then((response: AxiosResponse) => {
        const list = (response.data || []).map((item: any) => ({
          label: item.name,
          value: item.name,
        }));
        onChangeEventCategories(list);
      })
      .finally(() => setIsEventCategoriesLoading(false));
  }, [api]);

  const getGrantEventParticipantCategories = useCallback((name: string) => {
    setIsEventParticipantCategoriesLoading(true);
    api.getGrantEventParticipantCategories(name)
      .then((response: AxiosResponse) => {
        const list = (response.data || []).map((item: any) => ({
          label: item.name,
          value: item.name,
        }));
        onChangeEventParticipantCategories(list);
      })
      .finally(() => setIsEventParticipantCategoriesLoading(false));
  }, [api]);

  const seniorPersonnel = useMemo(() => {
    return grant.seniorPersonal.map((item: SeniorPersonal) => {
      const label = item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : 'Unnamed person';
      return ({
        value: item.id,
        label
      })
    })
  }, [grant]);

  const academicPersonnel = useMemo(() => {
    return grant.academicResearchAssociate.map((item: SeniorPersonal) => {
      const label = item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : 'Unnamed person';
      return ({
        value: item.id,
        label
      })
    })
  }, [grant]);

  const getAirports = useCallback(() => {
    if (airports.length > 0) return;
    setIsAirportsLoading(true);
    api.getAirports()
      .then((response: AxiosResponse) => {
        const list = response.data.response.map((item: Airport) => ({
          value: item.icao_code,
          label: item.name
        }));
        dispatch(setAirportsAction(list));
      })
      .finally(() => setIsAirportsLoading(false));
  }, [api, dispatch, airports]);

  const getAirlines = useCallback(() => {
    if (airlines.length > 0) return;
    setIsAirlinesLoading(true);
    api.getAirlines()
      .then((response: AxiosResponse) => {
        const list = response.data.response
          .filter((item: Airline) => item.icao_code)
          .map((item: Airline) => ({
            value: item.icao_code,
            label: item.name
          }));
        dispatch(setAirlinesAction(list));
      })
      .finally(() => setIsAirlinesLoading(false));
  }, [api, dispatch, airlines]);

  const distanceUnits = ['Kilometer', 'Mile'].map((value: string) => ({value, label: value}));
  const timeUnits = ['Months', 'Weeks', 'Days'].map((value: string) => ({value, label: value}));

  return {
    getEnums,
    distanceUnits,
    timeUnits,
    getAirports,
    getAirlines,
    airlines,
    isAirlinesLoading,
    yesNoOptionsNumber,
    airports,
    isAirportsLoading,
    seniorPersonnel,
    academicPersonnel,
    personRoles: enums.roleInGrant,
    studentTypesRoles,
    travelCostCategories,
    isTravelCostCategoriesLoading,
    equipmentCategories,
    isEquipmentCategoriesLoading,
    yesNoOptions,
    yesNoOptionsRevert,
    getTravelCostCategories,
    getEquipmentCategories,
    genders: enums.gender,
    FARateLocations: enums.FARateLocations,
    travelExpensesTypes: enums.travelExpensesTypes,
    travelTypes: enums.travelTypes,
    races: enums.race,
    eventParticipantCategories,
    isEventParticipantCategoriesLoading,
    getGrantEventCategories,
    eventCategories,
    jobCategory: enums.jobCategory,
    travelCostsTypes: enums.travelCostsTypes,
    FARateStatuses: enums.FARateStatuses,
    costSharingExpenseTypes: enums.costSharingExpenseTypes,
    stipendAwardPeriod: enums.stipendAwardPeriod,
    civilServiceCompensation: enums.civilServiceCompensation,
    isEventCategoriesLoading,
    transportationMethod: enums.transportationMethod,
    annualSchedules: enums.annualSchedule,
    getGrantEventParticipantCategories,
    eligibility: enums.eligibility,
    consultantServiceProviderTypes: enums.consultantServiceProviderTypes,
    consultantServiceProviderTravelCostsTypes: enums.consultantServiceProviderTravelCostsTypes,
    derectorates: enums.nsfDerectorate,
    grantTypes: enums.grantType,
    grantSteps: enums.grantStep,
    grantStatus: enums.grantStatus,
    compensationType: enums.nsfCompensation,
    countries,
    fetchZIP,
    getSubs,
  }
}

export default useEnums;
