import { createSelector } from 'reselect';
import { Store } from '../types';
import { UIState } from './types';

const uiSelector = (store: Store) => store.ui;

export const minimizedSidebarSelector = createSelector(uiSelector, (state: UIState) => state.minimizeSidebar);
export const sidebarWidthSelector = createSelector(uiSelector, (state: UIState) => state.sidebarWidth);
export const loadingSelector = createSelector(uiSelector, (state: UIState) => state.loading);
export const openedStepsSelector = createSelector(uiSelector, (state: UIState) => state.openedSteps.asMutable());
export const infoSidebarSelector = createSelector(uiSelector, (state: UIState) => state.infoSidebar);
export const hiddenModalsSelector = createSelector(uiSelector, (state: UIState) => state.hiddenModals);
export const prevUrlSelector = createSelector(uiSelector, (state: UIState) => state.prevUrl);
export const mtdcGrantIdSelector = createSelector(uiSelector, (state: UIState) => state.mtdcGrantId);
export const finalGrantIdSelector = createSelector(uiSelector, (state: UIState) => state.finalGrantId);
export const icGrantIdSelector = createSelector(uiSelector, (state: UIState) => state.icGrantId);
export const showNavigationMessageSelector = createSelector(uiSelector, (state: UIState) => state.showNavigationMessage);
export const budgetBarSelector = createSelector(uiSelector, (state: UIState) => state.smallBudgetBar);
export const hiddenRatesInBarSelector = createSelector(uiSelector, (state: UIState) => state.hiddenRatesInBar.asMutable());
export const hiddenPromptSelector = createSelector(uiSelector, (state: UIState) => state.hidePrompt);
export const paywallSelector = createSelector(uiSelector, (state: UIState) => state.showPaywall);
export const showSettingsModalSelector = createSelector(uiSelector, (state: UIState) => state.showSettingsModal);
export const showDisplayMessageSelector = createSelector(uiSelector, (state: UIState) => state.showDisplayMessage);
