import { useState, useCallback } from 'react';
import {AxiosError, AxiosResponse} from 'axios';
import { useDispatch } from 'react-redux';
import {
  addGrantEvent,
  addGrantEventParticipant,
} from 'store/grants/actions';
import {GrantEventParticipantsTotal, GrantEventTotal} from 'store/grants/types';
import {
  grantEventParticipantExpenseAdapter,
  grantEventParticipantsTotalAdapter,
  grantEventTotalAdapter,
  transportationMethodAdapter
} from 'store/grants/adapters';
import useApi from './useApi';
import useUI from './useUI';
import {useHistory} from 'react-router-dom';
import * as T from './requestTypes';
import parseErrors, {Errors} from 'helpers/errors';
import toast from 'components/toast';
import {confirm} from 'components/confirmation';
import useGrants from './useGrants';
import {useMixPanel} from "./useMixPanel";
import {FieldErrors} from "react-hook-form";

type iUseParticipants = {
  loading: boolean;
  errors: Errors;
  totals: GrantEventTotal[];
  addEvent: (grantId: string) => void;
  updateEvent: (grantId: string, id: string, data: T.GrantEvent) => void;
  deleteEvent: (grantId: string, id: string, redirect: boolean) => void;
  updateEventParticipant: (grantId: string, eventId: string, id: string, data: T.UpdateGrantEventParticipant) => void;
  addGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, id: string, cb: (data: any) => void) => void;
  addGrantEventParticipantExpense: (grantId: string, eventId: string, id: string, cb: (data: any) => void) => void;
  deleteGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, id: string, cb: () => void) => void;
  deleteGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, id: string, cb: () => void) => void;
  updateGrantEventParticipantTransportationMethod: (grantId: string, eventId: string, participantId: string, id: string, data: any, cb: (data: any) => void) => void;
  updateGrantEventParticipantExpense: (grantId: string, eventId: string, participantId: string, id: string, data: any, cb: (data: any) => void) => void;
  addParticipant: (grantId: string, eventId: string) => void;
  previewParticipant: (grantId: string, eventId: string, data: any, cb: (data: any) => void) => void;
  deleteParticipant: (grantId: string, id: string, participantId: string, redirect: boolean) => void;
  clearErrors: () => void;
  getTotals: (grantId: string) => void;
  eventTotals: GrantEventParticipantsTotal[];
  getEventTotals: (grantId: string, eventId: string) => void;
  previewParticipantExpense: (grantId: string, eventId: string, participantId: string, data: any, cb: (data: any) => void) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

type Props = {
  form_page_type?: string
}

const useParticipants = (props: Props = {}):iUseParticipants => {
  const form_page_type = props.form_page_type || '';
  const form_category = 'participant';
  const form_subcategory = '';
  const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const {grant} = useGrants();
  const api = useApi();
  const dispatch = useDispatch();
  const { loader, onShowNavigationMessage } = useUI();
  const history = useHistory();
  const { getOneWithSide } = useGrants();
  const [ errors, onChangeErrors ] = useState<Errors>({});
  const [ totals, onChangeTotals ] = useState<GrantEventTotal[]>([]);
  const [ eventTotals, onChangeEventTotals ] = useState<GrantEventParticipantsTotal[]>([]);
  const [ loading, onChangeLoading ] = useState<boolean>(false);

  const formSuccessSaveCallBack = useCallback(() => {
    formSaved(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaved, form_page_type, grant.id])

  const trackFormSaveAttempted = useCallback(() => {
    formSaveAttempted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formSaveAttempted, form_page_type, grant.id])

  const addEvent = useCallback((grantId: string) => {
    loader.start();
    onChangeLoading(true);
    api.addGrantEvent(grantId)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        loader.stop();
        onChangeLoading(false);
        history.push(`/grants/${grantId}/events/${response.data.id}/edit`);
        setTimeout(() => {
          dispatch(addGrantEvent(response.data));
        }, 300);
      })
      .catch(() => {
        loader.stop();
        onChangeLoading(false);
      });
    trackFormSaveAttempted()
  }, [loader, api, trackFormSaveAttempted, formSuccessSaveCallBack, history, dispatch]);

  const updateEvent = useCallback((grantId: string, id: string, data: T.GrantEvent) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateGrantEvent(grantId, id, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onChangeErrors({});
        getOneWithSide(grantId);
        toast.success({
          title: 'The event details have been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const updateEventParticipant = useCallback((grantId: string, eventId: string, id: string, data: T.UpdateGrantEventParticipant) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateGrantEventParticipant(grantId, eventId, id, data)
      .then((response: AxiosResponse) => {
        formSuccessSaveCallBack()
        onChangeLoading(false);
        onChangeErrors({});
        getOneWithSide(grantId);
        onShowNavigationMessage();
        toast.success({
          title: 'The participant details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch((error: AxiosError) => {
        onChangeLoading(false);
        //@ts-ignore
        if (error?.response?.data?.errors) { // @ts-ignore
          onChangeErrors(parseErrors(error.response.data?.errors));
        }
      })
    trackFormSaveAttempted()
  }, [api, trackFormSaveAttempted, formSuccessSaveCallBack, getOneWithSide, onShowNavigationMessage]);

  const deleteEvent = useCallback((grantId: string, id: string, redirect: boolean) => {
    confirm({
      title: 'Delete event info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteGrantEvent(grantId, id)
          .then(() => {
            getOneWithSide(grantId);
            toast.success({
              title: 'The event has been deleted',
              message: 'Changes have been successfully saved'
            });
            if (redirect) history.push(`/grants/${grantId}/edit`);
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const deleteParticipant = useCallback((grantId: string, id: string, participantId: string, redirect: boolean) => {
    confirm({
      title: 'Delete participant info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteGrantEventParticipant(grantId, id, participantId)
          .then(() => {
            getOneWithSide(grantId);
            toast.success({
              title: 'The participant has been deleted',
              message: 'Changes have been successfully saved'
            });
            if (redirect) history.push(`/grants/${grantId}/edit`);
          });
      }
    })
  }, [api, history, getOneWithSide]);

  const addParticipant = useCallback((grantId: string, eventId: string) => {
    loader.start();
    api.addGrantEventParticipant(grantId, eventId, {
      category: ''
    })
      .then((response: AxiosResponse) => {
        loader.stop();
        history.push(`/grants/${grantId}/events/${eventId}/participants/${response.data.id}/edit`);
        setTimeout(() => {
          dispatch(addGrantEventParticipant({
            eventId,
            participant: response.data})
          );
        }, 300);
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, history, loader, dispatch]);

  const previewParticipant = useCallback((grantId: string, eventId: string, data: any, cb: (data: any) => void) => {
    const q = new URLSearchParams(data).toString();
    if (q) loader.start();
    const query = q ? `?${q}` : '';
    api.previewParticipant(grantId, eventId, query)
      .then((response: AxiosResponse) => {
        cb(response.data);
        loader.stop();
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const previewParticipantExpense = useCallback((grantId: string, eventId: string, participantId: string, data: any, cb: (data: any) => void) => {
    loader.start();
    api.previewGrantEventParticipantExpense(grantId, eventId, participantId, data)
      .then((response: AxiosResponse) => {
        cb(response.data);
        loader.stop();
      })
      .catch(() => {
        loader.stop();
      });
  }, [api, loader]);

  const addGrantEventParticipantTransportationMethod = useCallback((grantId: string, eventId: string, id: string, cb: (data: any) => void) => {
    api.addGrantEventParticipantTransportationMethod(grantId, eventId, id)
      .then((response: AxiosResponse) => {
        getOneWithSide(grantId);
        cb(transportationMethodAdapter(response.data));
      });
  }, [api, getOneWithSide]);


  const addGrantEventParticipantExpense = useCallback((grantId: string, eventId: string, id: string, cb: (data: any) => void) => {
    api.addGrantEventParticipantExpense(grantId, eventId, id)
      .then((response: AxiosResponse) => {
        getOneWithSide(grantId);
        cb(grantEventParticipantExpenseAdapter(response.data));
      });
  }, [api, getOneWithSide]);

  const deleteGrantEventParticipantTransportationMethod = useCallback((grantId: string, eventId: string, participantId: string, id: string, cb: () => void) => {
    confirm({
      title: 'Delete participant travel method info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteGrantEventParticipantTransportationMethod(grantId, eventId, participantId, id)
          .then(() => {
            getOneWithSide(grantId);
            toast.success({
              title: 'Transportation method has been deleted',
              message: 'Changes have been successfully saved'
            });
            cb();
          });
      }
    })
  }, [api, getOneWithSide]);

  const deleteGrantEventParticipantExpense = useCallback((grantId: string, eventId: string, participantId: string, id: string, cb: () => void) => {
    confirm({
      title: 'Delete participant expense info',
      text: 'Are you sure you want to delete this data? This action will not be reversible.',
      type: 'error',
      icon: 'trash-01',
      okText: 'Delete',
      onConfirm: () => {
        api.deleteGrantEventParticipantExpense(grantId, eventId, participantId, id)
          .then(() => {
            getOneWithSide(grantId);
            toast.success({
              title: 'The expense has been deleted',
              message: 'Changes have been successfully saved'
            });
            cb();
          });
      }
    })
  }, [api, getOneWithSide]);

  const updateGrantEventParticipantTransportationMethod = useCallback((grantId: string, eventId: string, participantId: string, id: string, data: any, cb: (data: any) => void) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateGrantEventParticipantTransportationMethod(grantId, eventId, participantId, id, data)
      .then((response: AxiosResponse) => {
        getOneWithSide(grantId);
        cb(transportationMethodAdapter(response.data));
        onShowNavigationMessage();
        onChangeLoading(false);
        toast.success({
          title: 'The participant transportation method details have been updated',
          message: 'Changes have been successfully saved'
        });
      })
      .catch(() => {
        onChangeLoading(false);
      })
  }, [api, onShowNavigationMessage, getOneWithSide]);

  const updateGrantEventParticipantExpense = useCallback((grantId: string, eventId: string, participantId: string, id: string, data: any, cb: (data: any) => void) => {
    onChangeErrors({});
    onChangeLoading(true);
    api.updateGrantEventParticipantExpense(grantId, eventId, participantId, id, data)
      .then((response: AxiosResponse) => {
        getOneWithSide(grantId);
        toast.success({
          title: 'The participant expense details have been updated',
          message: 'Changes have been successfully saved'
        });
        onShowNavigationMessage();
        cb(grantEventParticipantExpenseAdapter(response.data));
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      })
  }, [api, onShowNavigationMessage, getOneWithSide]);

  const clearErrors = useCallback(() => {
    onChangeErrors({});
  }, []);

  const getTotals = useCallback((grantId: string) => {
    onChangeLoading(true);
    api.getGrantEventTotals(grantId)
      .then((response: AxiosResponse) => {
        const data = (response.data.events ?? []).map(grantEventTotalAdapter)
        onChangeTotals(data);
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      })
  }, [api]);

  const getEventTotals = useCallback((grantId: string, eventId: string) => {
    onChangeLoading(true);
    api.getEventTotals(grantId, eventId)
      .then((response: AxiosResponse) => {
        onChangeEventTotals(grantEventParticipantsTotalAdapter(response.data?.participants ?? []));
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      })
  }, [api]);

  const trackError = useCallback((errors: FieldErrors = {}) => {
    const error_message = Object.keys(errors).map(key => `${key}: ${errors && errors[key]?.message}`).join(', ');
    formSaveFailed(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1,
      error_message
    )
  }, [formSaveFailed, form_page_type, grant.id])

  const trackExit = useCallback(() => {
    formExited(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formExited, form_page_type, grant.id])

  const trackFormStarted = useCallback(() => {
    formStarted(
      grant.id,
      form_category,
      form_subcategory,
      form_page_type,
      1
    )
  }, [formStarted, form_page_type, grant.id])

  return {
    loading,
    getEventTotals,
    getTotals,
    previewParticipant,
    clearErrors,
    addGrantEventParticipantExpense,
    totals,
    eventTotals,
    deleteGrantEventParticipantExpense,
    deleteEvent,
    updateGrantEventParticipantExpense,
    deleteGrantEventParticipantTransportationMethod,
    previewParticipantExpense,
    addGrantEventParticipantTransportationMethod,
    errors,
    updateGrantEventParticipantTransportationMethod,
    deleteParticipant,
    updateEventParticipant,
    updateEvent,
    addParticipant,
    addEvent,
    trackError,
    trackExit,
    trackFormStarted
  }
}

export default useParticipants;
