import { FC, ReactElement } from 'react';
import Header from 'containers/header';
import styles from './wrapper.module.css';

type Props = {
  children?: ReactElement;
}
const Wrapper:FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        {children}
      </div>
    </>
  );
}

export default Wrapper;
