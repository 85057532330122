import {ChangeEvent, FC} from 'react';
import {Props} from './index';
import useEnums from 'hooks/useEnums';
import Field from 'components/field';
import {Input, Select, Form, Button} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import helps from './common/helps';

const Boat:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {

  const { travelExpensesTypes } = useEnums();

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name of boat expense" required placeholder="Enter name of expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field withHelp top={Boolean(errors.startLocation || errors.endLocation)}>
        <Input placeholder="Starting Location/Port or Dock" label="Starting location"
               name="startLocation"
               value={data.startLocation} errors={errors.startLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ startLocation: event.target.value })}
        />
        <Input placeholder="Enter ending location" label="Ending location"
               name="endLocation"
               value={data.endLocation} errors={errors.endLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ endLocation: event.target.value })}
        />
      </Field>
      <Field withHelp top={Boolean(errors['details.ticketPrice'] || errors['details.tickets'])}>
        <Input placeholder="Enter a price of ticket" label="Current price of the boat ticket for one person" required
               name="ticketPrice" type="number" onBlur={onBlur}
               value={details.ticketPrice} errors={errors['details.ticketPrice']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ ticketPrice: event.target.value })}
               help={helps.boat} money
        />
        <Input placeholder="Enter the number of tickets" label="Estimated number of boat tickets needed for trip" required
               name="tickets" type="number" onBlur={onBlur}
               value={details.tickets} errors={errors['details.tickets']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ tickets: event.target.value })}
        />
      </Field>

      <Field top={!!errors['details.totalCost']}>
        <Input placeholder="Enter total cost of misc expenses" label="Miscellaneous expenses for boat travel for all trip attendees"
               name="totalCost" type="number" onBlur={onBlur} money
               value={details.totalCost} errors={errors['details.totalCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
        />
        <Input placeholder="Amount of compensation before inflation" label="Amount budgeted for train travel before inflation"
               name="amountOfCompensationBeforeInflation" money
               value={data.compensationBeforeInflation}
               disabled readOnly
        />
      </Field>
      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors} data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Boat;
