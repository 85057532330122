import {FC, useEffect, useCallback, useState, useRef} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { debounce } from 'lodash';
import cx from 'classnames';
import Typo from 'components/typo';
import Icon from 'components/icon';
import useUI from 'hooks/useUI';
import styles from './message.module.css';


const Message:FC = () => {
  const { showNavigationMessage, onHideNavigationMessage, savePreviousPage } = useUI();
  const location = useLocation();
  const [ closing, onChangeClosing ] = useState(false);
  const message = useRef<HTMLDivElement | null>(null);

  const debouncedHide = useCallback(debounce(() => {
    onHideNavigationMessage();
    onChangeClosing(false);
  }, 300, {trailing: true}), []);

  const debouncedClose = useCallback(debounce(() => {
    onChangeClosing(true);
    debouncedHide();
  }, 7000, {trailing: true}), []);

  useEffect(() => {
    if (showNavigationMessage && message.current) {
      message.current.scrollIntoView({behavior: 'smooth'});
      debouncedClose();
    }
  }, [showNavigationMessage]);

  const close = () => {
    if (closing) return;
    onChangeClosing(true);
    debouncedHide();
  }

  return (
    showNavigationMessage
      ?
      <div ref={message} className={cx(styles.messageWrapper, {[styles.closing]: closing})}>
        <div className={styles.message}>
          <Typo className={styles.messageText}>Continue your journey by using the left sidebar navigation to move to the next section</Typo>
          <Link to="/settings/account/personal#hint" onClick={() => savePreviousPage(location.pathname, false)}>
            To turn off this message, please go to the Account Settings
          </Link>
          <Icon icon="x-close" onClick={close} size={20} className={styles.closeMessage} />
        </div>
      </div>
      : null
  );
}

export default Message;
