import {FC} from 'react';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Typo from 'components/typo';
// styles
import styles from 'pages/grant/one/otherDirectCosts/otherDC.module.css'

const OtherInfo: FC = () => {

  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="All other cost">
      <>
        <div className={styles.green}>
          <Typo className={styles.greenText}>
            We strongly recommend that you include in your budget (in this section for each year) a $1,440/year expense to cover any future revisions we make to budget mentor.
          </Typo>
          <Typo className={styles.greenText}>
            These may include additional features helping you better manage your grant.
          </Typo>
          <Typo className={styles.greenText}>
            These features will not be available on the discounted subscription price. These features will only be available at the regular $1,440/year subscription price.
          </Typo>
        </div>
        <Typo className={styles.mb16}>
          Your university or organization likely has specific guidelines on what is or isn't allowable as any other
          expenses. It is suggested you check in with any available resources if they are available. Any other
          guidelines as specified by the NSF can be found to the right.
        </Typo>
        <Typo className={styles.mb20}>
          This section is for any other items that we have left out. You have the option to decide if you want to budget
          by unit or as one whole amount per year. If your proposal doesn’t require any other expenses, no need to worry
          about this section, you can move to the next section.
        </Typo>
      </>
    </GrantFormWrapper>
  )
}

export default OtherInfo;
