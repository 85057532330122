import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import cx from 'classnames';
import {useParams} from 'react-router-dom';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import useGrants from 'hooks/useGrants';
import {Input, Form, Button, Textarea} from 'components/form';
import * as T from 'hooks/requestTypes';
import Typo from 'components/typo';
import {getEquipmentFromGrant} from 'helpers/getFromGrant';
import useEquipment from 'hooks/useEquipment';
import styles from './compensation.module.css';

const EquipmentCompensationForm: FC = () => {
  const {calculate, updateNSFCompensation, loading, errors, trackError, trackFormStarted, trackExit} = useEquipment({
    form_page_type: 'Date',
  })
  const params: Record<string, string> = useParams();
  const {grant} = useGrants()
  const {unit} = getEquipmentFromGrant(grant, params.unitId);
  const compensation = unit?.nsfCompensation[Number(params.year)];
  const year = Number(params.year) + 1;

  const [data, onChangeData] = useState({
    numberOfUnits: compensation?.numberOfUnits ?? '',
    totalCostAfterInflation: '',
    justification: compensation?.justification ?? '',
    totalCostBudget: '',
    totalCostBeforeInflation: '',
  });

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    })
  }, [data]);

  const onBlur = useCallback(() => {
    if (data.numberOfUnits !== '' && compensation && unit) {
      calculate(grant.id, unit.id, {
        numberOfUnits: data.numberOfUnits,
        year: compensation.year,
        serialNumber: Number(params.year) + 1
      }, (response: T.EquipNsfCompensation) => {
        handleChangeData({
          totalCostAfterInflation: response.totalCostAfterInflation,
          totalCostBudget: response.totalCostBudget,
          totalCostBeforeInflation: response.totalCostBeforeInflation
        })
      })
    }
  }, [data, handleChangeData, calculate, unit, compensation, grant, params.year]);

  useEffect(() => {
    onBlur()
  }, [])

  const onSubmit = useCallback(() => {
    if (compensation && unit) {
      updateNSFCompensation(grant.id, unit.id, {
        ...data,
        year: compensation.year,
        serialNumber: Number(params.year) + 1
      });
    }
  }, [data, updateNSFCompensation, compensation, unit, grant, params.year]);

  const isLast = year === unit?.nsfCompensation.length;

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError} trackFormStarted={trackFormStarted} trackExit={trackExit}>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input name="numberOfUnits" label="Number of Units" required
               value={data.numberOfUnits} errors={errors.numberOfUnits}
               className={styles.input}
               type="number" min={0}
               onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeData({numberOfUnits: event.target.value})}
               placeholder="Enter number of units" onBlur={onBlur}
        />
      </div>
      <div className={cx(styles.field, styles.last, styles.twoInputsField)}>
        <Input name="totalCostBeforeInflation" money label={`Total Cost Before Inflation for Year ${year}`}
               value={data.totalCostBeforeInflation}
               className={styles.input}
               placeholder="Should be calculated"
               disabled readOnly
        />
        <Input
          name="totalCostAfterInflation"
          money
          label={`Total Cost After Inflation for Year ${year}`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          value={data.totalCostAfterInflation}
          className={styles.input}
          placeholder="Should be calculated"
          disabled
          readOnly
        />
      </div>

      {isLast
        ?
        <div className={styles.lastForm}>
          <Typo type="h5" className={styles.title} bold>Totals</Typo>
          <div className={cx(styles.field, styles.twoInputsField)}>
            <Input name="totalCostBudget" money label="Total cost budgeted for all years"
                   value={data.totalCostBudget}
                   className={styles.input}
                   placeholder="Should be calculated"
                   disabled readOnly
            />
          </div>
          <div className={cx(styles.field, styles.last)}>
            <Textarea name="justification" label="Justification for this Cost"
                      value={data.justification}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({justification: event.target.value})}
                      placeholder="Enter a description ..."
            />
          </div>
        </div>
        :
        <div className={styles.placeholder}>
          <Typo size={16}>
            <>
              <div>
                When adding calculations on the last year tab, feel free to include a <strong>Total Cost
                Justification.</strong>.
              </div>
              <div>
                This can be helpful during grant approval.
              </div>
            </>
          </Typo>
        </div>
      }


      <div className={styles.formFooter}>
        <Button loading={loading} name="submit-calculations" htmlType="submit">Save</Button>
      </div>
    </Form>
  );
}

const EquipmentCompensationPage: FC = () => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants()
  const {unit, index} = getEquipmentFromGrant(grant, params.unitId);

  return (
    <GrantFormWrapper title="Equipment" subtitle={`Unit ${index}. Year ${Number(params.year) + 1}`}>
      {unit ? <EquipmentCompensationForm key={`${params.grantId}-${params.unitId}-${params.year}`}/> : <></>}
    </GrantFormWrapper>
  )
}


export default EquipmentCompensationPage;
