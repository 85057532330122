import {FC, useCallback, useEffect} from 'react';
import { Button } from 'components/form';
import { ParsedQuery } from 'query-string';
import Typo from 'components/typo';
import Wrapper from 'pages/auth/components/wrapper';
import useAuth from 'hooks/useAuth';
import useQuery from 'helpers/query';
import styles from './forgot.module.css';
import {useMixPanel} from "../../../hooks/useMixPanel";

const Forgot:FC = () => {
  const {pageViewed} = useMixPanel();
  const { forgot } = useAuth();
  const query:ParsedQuery = useQuery();

  const onSubmit = useCallback(() => {
    if (query.email) forgot({email: query.email as string}, true);
  }, [forgot, query]);

  const openMailer = useCallback(() => {
    window.open('mailto:');
  }, []);

  useEffect(() => {
    //track mix panel Page Viewed event
    pageViewed("Forgot Password Success Page")
  }, []);

  return (
    <Wrapper
      title="Check your email"
      subtitle={`We sent a password reset link to ${query.email}`}
      icon="mail-01"
      backToLogin
    >
      <div className={styles.wrapper}>
        <Button onClick={openMailer} full name="open-mailer">Open email app</Button>
        <Typo className={styles.resendText}>
          <>Didn’t receive the email? <Button type="link" name="resend" onClick={onSubmit}>Click to resend</Button></>
        </Typo>
      </div>
    </Wrapper>
  )
}

export default Forgot;
