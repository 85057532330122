import { FC } from 'react';
import Paper from 'pages/grant/paper';
import { Switch, Route } from 'react-router-dom';
import Grant from 'pages/grant/one';
import Home from './home';
import Rates from './rates';

const GrantIndex:FC = () => (
  <Paper>
    <Switch>
      <Route path="/grants/" exact component={Home} />
      <Route path="/grants/rates" exact component={Rates} />
      <Route path="/grants/:id" component={Grant} />
    </Switch>
  </Paper>
);

export default GrantIndex;
