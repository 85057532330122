import {createElement, FC, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {Link, LinkProps} from 'react-router-dom';
import Typo from 'components/typo';
import Icon from 'components/icon';
import styles from './item.module.css';
import Tip from 'components/tip';
import {isArray, isString} from 'lodash';

type LinkType = {
  to: string;
  label: string;
  active?: boolean;
  isDisabled?: boolean;
}

type Props = {
  title: string | ReactElement;
  role?: string | string[];
  icon?: string;
  iconText?: string;
  onDelete?: () => void;
  to?: string;
  links?: LinkType[];
  active?: boolean;
}

type HeaderProps = {
  className: string;
  to?: string;
  children: ReactElement | ReactElement[];
}

const Header:FC<HeaderProps> = ({to, ...rest}) => {
  return createElement(to ? Link : 'div', { ...rest, to } as LinkProps);
}

const Item:FC<Props> = (props: Props) => {
  const {
    title, role, active, icon,
    iconText, to, links, onDelete
  } = props;
  const [ open, onChangeOpen ] = useState<boolean | undefined>(active);

  useEffect(() => {
    if (active) onChangeOpen(active);
  }, [active]);

  const handleDelete = useCallback((event: any) => {
    event.preventDefault();
    if (onDelete) onDelete();
  }, [onDelete]);

  const customIcon = useMemo(() => {
    if (iconText && icon) {
      return (
        <Tip top text={iconText}>
          <Icon icon={icon} className={cx(styles.icon, styles.custom)} />
        </Tip>
      );
    }
    if (icon) {
      return (
        <Icon icon={icon} className={cx(styles.icon, styles.custom)} />
      );
    }
    return null;
  }, [icon, iconText]);

  const uncollapsed = open && links;

  const roles = useMemo(() => {
    if (isArray(role)) {
      return (
        <>
          {role.map((item: string, idx: number) => (
            <span title={item} key={idx} className={styles.role}>{item}</span>
          ))}
        </>
      );
    }
    if (isString(role)) return (<span title={role} className={styles.role}>{role}</span>);
    return null;
  }, [role]);

  return (
    <div className={cx(styles.wrapper, { [styles.active]: active, [styles.open]: open, [styles.asLink]: Boolean(to), [styles.closedActive]: active && !open })}>
      <Header to={to} className={styles.header}>
        <>
          {to
            ? <span className={styles.chevron}/>
            : <Icon icon="chevron-right" className={styles.chevron} onClick={() => onChangeOpen(!open)} />
          }
          <div className={styles.titles} onClick={() => onChangeOpen(!open)}>
            <div className={styles.titleWrapper}>
              {customIcon}
              <Typo title={title as string} className={styles.title}>
                {title}
              </Typo>
            </div>
            {roles}
          </div>
          {onDelete ? <Icon className={cx(styles.icon, styles.delete)} icon="trash-01" onClick={handleDelete}/> : null}
        </>
      </Header>
      {
        uncollapsed ?
          <div className={styles.body}>
            {
              links.map((item: LinkType, idx: number) => (
                  <Tip key={idx} className={styles.linkWrapper} text='To add calculations, complete the Info tab' when={Boolean(item.isDisabled)} top>
                    <Link className={cx(styles.link, {[styles.active]: item.active}, {[styles.disabled]: item.isDisabled})} to={item.to}>
                      {item.label}
                    </Link>
                  </Tip>
                ))
            }
          </div>
          : null
      }
    </div>
  );
}

export default Item;
