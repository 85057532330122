import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateEditEquipment from './createOrEdit';
import EquipmentCompensation from './compensation';
import TotalsPage from './total';


const EquipmentIndex:FC = () => {
  return (
    <Switch>
      <Route path="/grants/:id/equipment/create" exact component={CreateEditEquipment} />
      <Route path="/grants/:id/equipment/:unitId/edit" exact component={CreateEditEquipment} />
      <Route path="/grants/:id/equipment/:unitId/compensation/:year" exact component={EquipmentCompensation} />
      <Route path="/grants/:id/equipment/totals" exact component={TotalsPage} />
    </Switch>
  )
}

export default EquipmentIndex;
