import { FC, ReactElement, useCallback } from 'react';
import cx from 'classnames';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import Field from 'components/form/field';
import Select from 'components/form/select/datepickerSelect';
import styles from 'components/form/form.module.css';
import Icon from 'components/icon';
import dayjs from 'dayjs';
import { useFormContext, Controller } from 'react-hook-form';
import {findInputError, RHErrors} from 'helpers/errors';

export type Props = {
  name: string;
  value?: any;
  onChange?: (date: Date | null) => void;
  label?: string;
  placeholder?: string;
  errors?: string[];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  minDate?: Date;
  onBlur?: (event?: any) => void;
  help?: ReactElement | string;
  hint?: string;
  maxDate?: Date;
  inputClassName?: string;
  className?: string;
}

const CustomDatePickerHeader:FC<ReactDatePickerCustomHeaderProps> = (props: ReactDatePickerCustomHeaderProps) => {
  const { date, changeYear, changeMonth,
    decreaseMonth, increaseMonth,
    prevMonthButtonDisabled, nextMonthButtonDisabled,
  } = props;
  const getYear = (date: Date) => dayjs(date).get('year');
  const getMonth = (date: Date) => dayjs(date).get('month');

  const years = Array.from({length: 150}, (_item: any, id: number) => ({value: 1900 + id, label: 1900 + id}));

  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ].map((item: string, idx: number) => ({ value: idx, label: item }));

  const currentYear = dayjs().year();

  return (
    <div className={styles.datepickerHeader}>
      <Icon disabled={prevMonthButtonDisabled} size={28} className={cx(styles.datepickerArrow, styles.datepickerPrev)} icon="chevron-left"
            onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
      />
      <div className={styles.datepickerHeaderSelects}>
        <Select
          name="datepicker-header-month"
          options={months}
          pure
          className={styles.datepickerHeaderSelectWrapper}
          inputClassName={styles.datepickerHeaderSelect}
          value={months[getMonth(date)].value}
          onChange={(value: string | undefined | number) => changeMonth(Number(value))}
        />
        <Select
          name="datepicker-header-year"
          value={getYear(date)}
          pure
          scroll
          defaultValueToScroll={`${currentYear}`}
          className={styles.datepickerHeaderSelectWrapper}
          inputClassName={styles.datepickerHeaderSelect}
          options={years}
          onChange={(value: string | number | undefined) => changeYear(Number(value))}
        />
      </div>
      <Icon disabled={nextMonthButtonDisabled} size={28} className={cx(styles.datepickerArrow, styles.datepickerNext)} icon="chevron-right"
            onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
      />
    </div>
  );
}

const DatePicker:FC<Props> = (props: Props) => {
  const {
    name, value, onChange, label,
    placeholder = 'Choose date', className, errors = [], required, readOnly,
    disabled, minDate =  new Date('01-01-1900'),
    maxDate, inputClassName, hint,
    help, onBlur
  } = props;

  const handleBlur = useCallback(() => {
    if (onBlur) onBlur();
  }, [onBlur]);

  const {
    formState,
    setValue,
    clearErrors,
    control
  } = useFormContext();
  const inputErrors = findInputError(formState.errors as RHErrors, `${name}-datepicker`);

  const handleChange = useCallback((date: any) => {
    if (onChange) onChange(date);
    if (date && required) clearErrors(`${name}-datepicker`);
    setValue(`${name}-datepicker`, date);
  }, [onChange, clearErrors, required, name, setValue]);

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const rules = {
    required: {value: Boolean(required), message: 'Field is required'},
    value
  }
  const invalid = errorsList.length > 0;

  return (
    <Field help={help} className={className} required={required} label={label} hint={hint} errors={errorsList} name={name}>
      <Controller
        control={control} name={`${name}-datepicker`}
        rules={rules}
        defaultValue={value}
        render={({field}) => (
          <div className={cx(styles.inputWrapper, styles.withPreIcon)}>
            <ReactDatePicker
              renderCustomHeader={CustomDatePickerHeader} cypress-id={`${name}-datepicker`}
              readOnly={readOnly} selected={value} minDate={minDate} onCalendarClose={handleBlur} maxDate={maxDate} disabled={disabled}
              dateFormat={['MM-dd-yyyy', 'MM/dd/yy', 'MM/dd/yyyy', 'MM.dd.yyyy']} locale="en-US" placeholderText={placeholder || 'Select date'}
              className={cx(styles.datepicker, {[styles.hasError]: invalid}, inputClassName, styles.input)}
              onSelect={handleChange}
              name={field.name}
              onChange={(date: any) => {
                handleChange(date);
                field.onChange(date);
              }}
            />
            <Icon className={cx(styles.calendarIcon, styles.preicon)} icon="calendar-pure"/>
          </div>
        )}
      />
    </Field>
  )
}

export default DatePicker;
