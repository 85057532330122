import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
// types
import {MaterialsSuppliesUnitType} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// hooks
import useMaterialsSupplies from 'hooks/useMaterialsSupplies';
import useGrants from 'hooks/useGrants';
// helpers
import {getMaterialsSuppliesUnitFromGrant} from 'helpers/getFromGrant';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import MaterialsSuppliesUnitForm from './form';

const CreateEditMSUnit = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const {updateUnit,
    trackError,
    trackExit,
    trackFormStarted} = useMaterialsSupplies({form_page_type: 'Unit info'});
  const generalInfo = {
    hasCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.hasCapitalizationLevel!,
    universityCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.universityCapitalizationLevel!,
    defaultCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.defaultCapitalizationLevel!,
  }
  const {item: currentUnit, index: currentUnitIdx} = getMaterialsSuppliesUnitFromGrant(grant, params.unitId);
  const pageSubtitle = `Materials and Supplies. Unit ${currentUnitIdx > 0 ? currentUnitIdx : ''}`;

  const onSubmit = useCallback((data: MaterialsSuppliesUnitType) => {
    if (currentUnit?.id) {
      updateUnit(data, currentUnit.id, grant.id);
      return;
    }
  }, [grant, currentUnit, updateUnit]);

  return (
    <GrantFormWrapper
      subtitle={pageSubtitle}
      title={'Other direct costs'}
      key={`${params.id}-materials-n-supplies-unit-${currentUnit?.id}`}
    >
      <MaterialsSuppliesUnitForm
        unit={currentUnit}
        onSubmit={onSubmit}
        grantId={grant.id}
        generalInfo={generalInfo}
        trackError={trackError}
        trackExit={trackExit}
        trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  );
};

export default CreateEditMSUnit;
