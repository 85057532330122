import { FC, ReactElement } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Typo from 'components/typo';
import Head from 'containers/head';
import Icon from 'components/icon';
import { Button } from 'components/form';
import styles from './wrapper.module.css';

type Props = {
  children: ReactElement;
  title: string;
  subtitle: string;
  icon: string;
  red?: boolean;
  link?: string;
  linkText?: string;
  greenIcon?: boolean;
  backToLogin?: boolean;
}

const Wrapper:FC<Props> = (props: Props) => {
  const {
    children, red, title, subtitle,
    icon, greenIcon, backToLogin,
    linkText, link
  } = props;
  return (
    <div className={styles.wrapper}>
      <Head title={title}/>
      <div className={cx(styles.iconWrapper, {[styles.green]: greenIcon})}>
        <Icon size={22} className={styles.icon} icon={icon}/>
      </div>
      <Typo type="h3" className={styles.title}>{title}</Typo>
      <Typo type="p" size={16} className={cx(styles.subtitle, {[styles.red]: red})}>{subtitle}</Typo>
      <div className={styles.childWrapper}>
        {children}
      </div>
      {backToLogin
        ?
        <Link to={link || '/login'} className={styles.back}><
          Button name="back-to-login" type="ghost" preIcon="arrow-left">{linkText || 'Back to Sign in'}</Button>
        </Link>
        : null}
    </div>
  )
}

export default Wrapper;
