import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import Wrapper from 'containers/wrapper';
import Account from './account';

const Settings:FC = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path="/settings/account" render={() => <Account/>}/>
      </Switch>
    </Wrapper>
  )
}

export default Settings;
