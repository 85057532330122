import {ChangeEvent, FC, useMemo} from 'react';
import cx from 'classnames';
import {Input, Button, Form, Radio, Select} from 'components/form';
import useEnums from 'hooks/useEnums';
import styles from './compensation.module.css';
import {Errors} from 'helpers/errors';
import {personalTypes} from 'const';
import {FieldErrors} from "react-hook-form";

type Props = {
  year: number | string;
  data: FormData;
  type: string;
  errors: Errors;
  loading: boolean;
  onChange: (field: Record<string, any>) => void;
  onBlur: () => void;
  onSubmit: () => void;
  position: number | string;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

export type FormData = {
  amountOfPeople?: number | string;
  paidBy?: string;
  isSpecificWage?: number;
  hourlyWage?: number | string;
  reasonableWage?: number | string;
  hoursPerWeek?: number | string;
  weeksPerYear?: number | string;
  annualSalary?: number | string;
  averagePercent?: number | string;
  totalBudget?: number | string;
  totalBudgeted?: number | string;
  totalCompensation?: number | string;
  totalForPosition?: number | string;
}

const helpText = "Only include people who will be paid the same salary in the same way (annually or hourly). If the pay or the compensation scheme (annually or hourly) is different, then create a new position for each worker";

const OtherCompensationForm: FC<Props> = ({
                                            trackError,
                                            trackExit,
                                            trackFormStarted,
                                            position,
                                            type,
                                            errors,
                                            loading,
                                            onSubmit,
                                            onBlur,
                                            onChange,
                                            data,
                                            year
                                          }) => {
  const {yesNoOptionsNumber} = useEnums();

  const positionTitle = useMemo(() => {
    if (type === personalTypes.union) return 'Union';
    if (type === personalTypes.temp) return 'Temp and Casual';
    if (type === personalTypes.other) return 'Other Personnel';
    return 'Civil Service';
  }, [type]);

  const hourlyDisabled = !data.paidBy || Boolean(data.paidBy && data.paidBy !== 'hourly');
  const annualDisabled = !data.paidBy || Boolean(data.paidBy && data.paidBy !== 'annual');

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={styles.field}>
        <Input name="amountOfPeople" errors={errors.amountOfPeople} value={data.amountOfPeople} type="number"
               className={styles.fullInput}
               label={`How many people in ${positionTitle} Position ${position} will be supported in Year ${year}?`}
               required
               placeholder="Enter how many people will be supported" onBlur={onBlur}
               help={helpText}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({amountOfPeople: event.target.value})}
        />
      </div>
      <div className={styles.field}>
        <Radio className={styles.input} required value="hourly"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({paidBy: event.target.value})}
               checked={data.paidBy === 'hourly'} name="paidBy" errors={errors.paidBy}
               label="This position paid hourly by the university"/>
        <div className={styles.plRadio}>
          <Select options={yesNoOptionsNumber} onChange={(isSpecificWage: number) => onChange({isSpecificWage})}
                  value={data.isSpecificWage} className={styles.fullInput} name="isSpecificWage"
                  errors={errors.isSpecificWage || errors['details.isSpecificWage']}
                  required={data.paidBy !== undefined && !hourlyDisabled}
                  label={`Does University/Department/College have a specific set wage for ${positionTitle} Workers in this position that differs from the minimum wage?`}
                  placeholder="Does Organization has a specific set wage?" disabled={hourlyDisabled}
          />
          <div className={cx(styles.twoInputsField, styles.field, styles.last)}>
            <Input name="hourlyWage" money errors={errors.hourlyWage || errors['details.hourlyWage']}
                   value={data.hourlyWage} className={styles.input}
                   placeholder="Enter hourly wage" type="number" onBlur={onBlur}
                   disabled={hourlyDisabled || data.isSpecificWage !== 1}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({hourlyWage: event.target.value})}
                   label={`What is your university's hourly wage set for ${positionTitle} Workers in position ${position}?`}
                   required={data.paidBy !== undefined && data.isSpecificWage !== undefined && data.isSpecificWage !== 0 && !hourlyDisabled}
            />
            <Input name="reasonableWage" money errors={errors.reasonableWage || errors['details.reasonableWage']}
                   className={styles.input} value={data.reasonableWage}
                   placeholder="Enter a reasonable wage" type="number" onBlur={onBlur}
                   disabled={hourlyDisabled || data.isSpecificWage !== 0}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({reasonableWage: event.target.value})}
                   label={`What is a reasonable wage for this position in the location where the work is expected to take place?`}
                   required={data.paidBy !== undefined && data.isSpecificWage !== undefined && data.isSpecificWage !== 1 && !hourlyDisabled}
            />
          </div>
          <div className={cx(styles.twoInputsField, styles.field, styles.wb)}>
            <Input name="hoursPerWeek" errors={errors.hoursPerWeek || errors['details.hoursPerWeek']}
                   value={data.hoursPerWeek} className={styles.input}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({hoursPerWeek: event.target.value})}
                   placeholder="Enter expected average hours per week" type="number" onBlur={onBlur}
                   label={`Expected Average Hours per Week for Each ${positionTitle} Worker`} disabled={hourlyDisabled}
                   required={data.paidBy !== undefined && !hourlyDisabled}
            />
            <Input name="weeksPerYear" errors={errors.weeksPerYear || errors['details.weeksPerYear']}
                   value={data.weeksPerYear} className={styles.input}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({weeksPerYear: event.target.value})}
                   placeholder="Enter expected average weeks per year" type="number" onBlur={onBlur}
                   label={`Expected Average Weeks per Year for Each ${positionTitle} Worker`} disabled={hourlyDisabled}
                   required={data.paidBy !== undefined && !hourlyDisabled}
            />
          </div>
          <Input name="totalCompensation" money className={styles.fullInput} placeholder="Total compensation" disabled
                 readOnly
                 value={hourlyDisabled ? '' : data.totalCompensation}
                 label={`Total compensation for Year ${year} per person based on hourly wage`}
          />
        </div>
      </div>

      <div className={styles.field}>
        <Radio className={styles.input} required errors={errors.paidBy} value="annual" name="paidBy"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({paidBy: event.target.value})}
               checked={data.paidBy === 'annual'} label="This position paid annually by the university"/>
        <div className={styles.plRadio}>
          <Input type="number" className={styles.fullInput} name="annualSalary"
                 errors={errors.annualSalary || errors['details.annualSalary']} onBlur={onBlur}
                 label="Current annual salary" money value={data.annualSalary}
                 required={data.paidBy !== undefined && !annualDisabled}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({annualSalary: event.target.value})}
                 placeholder="Enter annual salary" disabled={annualDisabled}
          />
          <div className={cx(styles.twoInputsField, styles.field, styles.last)}>
            <Input name="averagePercent" errors={errors.averagePercent || errors['details.averagePercent']}
                   className={styles.input} value={data.averagePercent}
                   placeholder="Enter average percent" type="number" onBlur={onBlur} disabled={annualDisabled}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({averagePercent: event.target.value})}
                   label={`What is the average percent of the year people in this position will be expected to work for your project, assuming a 40 hour work week?`}
                   required={data.paidBy !== undefined && !annualDisabled}
            />
            <Input name="totalCompensation" money className={styles.input} disabled readOnly
                   placeholder="Total average compensation" type="number"
                   value={annualDisabled ? '' : data.totalCompensation}
                   label={`Total average compensation before inflation for Year ${year} per person based on annual salary`}
            />
          </div>
        </div>
      </div>
      <div className={styles.lastField}>
        <Input type="number" className={cx(styles.fullInput, styles.mb20)} name="totalBudget"
               value={data.totalBudgeted} money
               label={`Total amount budgeted for this ${positionTitle} Position for Year ${year} before inflation`}
               disabled readOnly
               placeholder="Total amount budgeted"
        />
        <Input
          type="number"
          money
          className={styles.fullInput}
          name="totalForPosition"
          label={`Total for ${positionTitle} Position ${position} for Year ${year} after inflation`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          disabled
          readOnly
          value={data.totalForPosition}
          placeholder={`Total for ${positionTitle} Position ${year}`}
        />
      </div>
      <div className={styles.formFooter}>
        <Button htmlType="submit" loading={loading} name="submit" size="xl">Save</Button>
      </div>
    </Form>
  );
}

export const TempAndCasualCompensationForm: FC<Props> = ({
                                                           position,
                                                           errors,
                                                           loading,
                                                           onSubmit,
                                                           onBlur,
                                                           onChange,
                                                           data,
                                                           year,
                                                           trackError,
                                                           trackExit,
                                                           trackFormStarted
                                                         }) => {
  const {yesNoOptionsNumber} = useEnums();

  const positionTitle = 'Temp and Casual';

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={styles.field}>
        <Input name="amountOfPeople" errors={errors.amountOfPeople} value={data.amountOfPeople} type="number"
               className={styles.fullInput}
               label={`How many people in ${positionTitle} Position ${position} will be supported in Year ${year}?`}
               required
               placeholder="Enter how many people will be supported" onBlur={onBlur}
               help={helpText}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({amountOfPeople: event.target.value})}
        />
      </div>
      <div className={styles.field}>
        <Select options={yesNoOptionsNumber} onChange={(isSpecificWage: number) => onChange({isSpecificWage})}
                value={data.isSpecificWage} className={styles.fullInput} name="isSpecificWage"
                errors={errors.isSpecificWage || errors['details.isSpecificWage']}
                label={`Does University/Department/College have a specific set wage for ${positionTitle} Workers in this position that differs from the minimum wage?`}
                required
                placeholder="Does Organization has a specific set wage?"
        />
        <div className={cx(styles.twoInputsField, styles.field, styles.last)}>
          <Input name="hourlyWage" money errors={errors.hourlyWage || errors['details.hourlyWage']}
                 value={data.hourlyWage} className={styles.input}
                 placeholder="Enter hourly wage" type="number" onBlur={onBlur} disabled={data.isSpecificWage !== 1}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({hourlyWage: event.target.value})}
                 label={`What is your university's hourly wage set for ${positionTitle} Workers in position ${position}?`}
                 required={data.isSpecificWage !== undefined && data.isSpecificWage === 1}
          />
          <Input name="reasonableWage" money errors={errors.reasonableWage || errors['details.reasonableWage']}
                 className={styles.input} value={data.reasonableWage}
                 placeholder="Enter a reasonable wage" type="number" onBlur={onBlur}
                 disabled={data.isSpecificWage !== 0}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({reasonableWage: event.target.value})}
                 label={`What is a reasonable wage for this position in the location where the work is expected to take place?`}
                 required={data.isSpecificWage !== undefined && data.isSpecificWage === 0}
          />
        </div>
        <div className={cx(styles.twoInputsField, styles.field, styles.wb)}>
          <Input name="hoursPerWeek" errors={errors.hoursPerWeek || errors['details.hoursPerWeek']}
                 value={data.hoursPerWeek} className={styles.input}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({hoursPerWeek: event.target.value})}
                 placeholder="Enter expected average hours per week" type="number" onBlur={onBlur}
                 label={`Expected Average Hours per Week for Each ${positionTitle} Worker`} required
          />
          <Input name="weeksPerYear" errors={errors.weeksPerYear || errors['details.weeksPerYear']}
                 value={data.weeksPerYear} className={styles.input}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({weeksPerYear: event.target.value})}
                 placeholder="Enter expected average weeks per year" type="number" onBlur={onBlur}
                 label={`Expected Average Weeks per Year for Each ${positionTitle} Worker`} required
          />
        </div>
        <Input name="totalCompensation" money className={styles.fullInput} placeholder="Total compensation" disabled
               readOnly
               value={data.totalCompensation}
               label={`Total compensation for Year ${year} per person based on hourly wage`}
        />
      </div>
      <div className={styles.lastField}>
        <Input type="number" className={cx(styles.fullInput, styles.mb20)} name="totalBudgeted"
               value={data.totalBudgeted}
               label={`Total amount budgeted for this ${positionTitle} Position for Year ${year} before inflation`}
               disabled readOnly money
               placeholder="Total amount budgeted"
        />
        <Input
          type="number"
          money
          className={styles.fullInput}
          name="totalForPosition"
          label={`Total for ${positionTitle} Position ${position} for Year ${year} after inflation`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          disabled
          readOnly
          value={data.totalForPosition}
          placeholder={`Total for ${positionTitle} Position ${year}`}
        />
      </div>
      <div className={styles.formFooter}>
        <Button htmlType="submit" loading={loading} name="submit" size="xl">Save</Button>
      </div>
    </Form>
  );
}

export default OtherCompensationForm;
