import {OtherExpensesType, OtherExpenseType} from './types';

export const otherExpenseAdapter = (data: OtherExpenseType) => {
  return {
    id: data?.id ?? '',
    name: data?.name ?? '',
    isInflationApplied: data?.isInflationApplied ?? '',
    years: data?.years ?? []
  }
};

export const otherExpensesAdapter = (data: OtherExpensesType) => {
  return {
    expenses: data?.expenses ? data.expenses.map(otherExpenseAdapter) : []
  }
};
