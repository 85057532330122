import {ChangeEvent, useState, FC, useCallback, useMemo} from 'react';
import Typo from 'components/typo';
import Field from 'components/field';
import Modal from 'components/modal';
import {Button, Form, Checkbox, DatePicker, Input} from 'components/form';
import {TravelCostMealExpensesSeason} from 'store/grants/types';
import styles from './alaska.module.css';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

type Props = {
  item: TravelCostMealExpensesSeason;
  errors: Errors;
  zero?: boolean;
  last?: boolean;
  inter?: boolean;
  first?: boolean;
  idx: number;
  errorField?: 'mealsExpenses' | 'mAndIeBreakdown';
  onChange: (field: Record<string, any>, blur?: boolean) => void;
  onSubmit: (season: TravelCostMealExpensesSeason, idx: number | string) => void;
  onClear: (idx: number | string) => void;
  onBlur: (event: any, season: TravelCostMealExpensesSeason, idx: number | string) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const Season: FC<Props> = ({
                             trackError,
                             trackExit,
                             trackFormStarted,
                             item,
                             onClear,
                             inter,
                             first,
                             last,
                             errorField = 'mealsExpenses',
                             zero,
                             onBlur,
                             errors,
                             idx,
                             onSubmit,
                             onChange
                           }) => {
  const [show, onChangeShow] = useState<boolean>(false);
  const [localErrors] = useState<Errors>({});

  const handleSubmit = useCallback(() => {
    onSubmit(item, item.serialNumber);
  }, [onSubmit, item]);

  const clearSeason = useCallback(() => {
    onClear(item.serialNumber)
  }, [item, onClear]);

  const errorKey = `${errorField}.seasons[${idx}]`;

  const handleBlur = useCallback((event: any) => {
    onBlur(event, item, item.serialNumber);
  }, [onBlur, item]);

  const onCloseModal = useCallback(() => {
    onChangeShow(false);
  }, []);

  const disableDiscountButton = useMemo(() => {
    if (item.doBudgetMaxAmount) {
      return !item.maxTotalAdjust;
    }
    if (zero) {
      return !(item.days);
    }
    return Boolean(
      !item.startDate ||
      !item.endDate ||
      !item.rate
    );
  }, [zero, item]);

  const onChangeDiscount = useCallback((field: Record<string, any>, blur?: boolean) => {
    onChange({
      discount: {
        ...item.discount,
        ...field
      }
    }, blur);
  }, [item, onChange]);


  // const disabled = useMemo(() => {
  //   if (zero) {
  //     return (
  //       !item.days ||
  //       !item.rate ||
  //       !(item.doBudgetMaxAmount ? item.maxTotalAdjust && item.maxTotalAdjust <= item.maxTotal : true)
  //     );
  //   }
  //   return (
  //     !(item.startDate && item.endDate)
  //     ||
  //     !item.rate ||
  //     !(item.doBudgetMaxAmount ? item.maxTotalAdjust && item.maxTotalAdjust <= item.maxTotal : true)
  //   );
  // }, [item, zero]);


  const maxError = ['The entered value is over the maximum amount allowed according to the website. Please double-check to ensure the values for each daily rate and number of nights are correct.'];
  const disabledMax = useMemo(() => {
    return Boolean(item.doBudgetMaxAmount && item.maxTotalAdjust && (item.maxTotalAdjust > item.maxTotal))
  }, [item]);

  const discountModalTitle = useMemo(() => {
    if (first && !zero) return 'Amount that needs to be subtracted for departure day';
    return 'Amount that needs to be subtracted for the day you return home';
  }, [first, zero]);

  const discountBtnTitle = useMemo(() => {
    if (first && !zero) return 'Check discount for departure day';
    return 'Check discount for the day you return home';
  }, [first, zero]);

  const discountInputLabel = useMemo(() => {
    if (first) return 'Max amount to budget departure day';
    return 'Max amount to budget arrival day';
  }, [first]);

  const disableModal = !item.discount?.isStartDateFollowsEndDate;

  const meals = inter ? 'M&IE' : 'meals';
  const modalMeals = inter ? 'M&IE' : 'meals expenses';

  //todo check seasons submit with prompt
  return (
    <>
      <Form onSubmit={handleSubmit} className={styles.season} trackError={trackError} trackExit={trackExit}
            trackFormStarted={trackFormStarted}>
        {zero
          ?
          <Field full>
            <Input value={item.days}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({days: event.target.value})}
                   name="days" label="Number of days of travel" required
                   placeholder="Enter number of days"
                   errors={errors[`${errorKey}.days`] || localErrors.days}
                   type="number" onBlur={handleBlur}
            />
          </Field>
          :
          <>
            <Typo type="h5" className={styles.seasonTitle} bold>{`Season ${idx + 1}`}</Typo>
            <Field>
              <DatePicker errors={errors[`${errorKey}.startDate`] || localErrors.startDate} value={item.startDate}
                          onChange={(startDate: Date | null) => onChange({startDate}, true)} name={`startDate_${idx}`}
                          label="Start date of travel in the season" required placeholder="Select a start date"/>
              <DatePicker errors={errors[`${errorKey}.endDate`] || localErrors.endDate}
                          minDate={item.startDate || undefined} value={item.endDate}
                          onChange={(endDate: Date | null) => onChange({endDate}, true)} name={`endDate_${idx}`}
                          label="End date of travel in the season" required placeholder="Select a end date"/>
              <Input value={item.days} disabled readOnly
                     name={`days_${idx}`} label="Number of days of travel in the season"
                     placeholder="Should be calculated"
                     type="number"
              />
            </Field>
          </>
        }
        <Field>
          <Input name={`rate_${idx}`} onBlur={handleBlur} required money
                 errors={errors[`${errorKey}.rate`] || localErrors.rate} type="number"
                 label={inter ? 'M&IE rate' : 'Meal rate'} placeholder="Enter rate"
                 value={item.rate}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({rate: event.target.value})}
          />
        </Field>
        <Field>
          <Input name={`maxTotal_${idx}`} type="number" money value={item.maxTotal} disabled readOnly
                 label={`Total for ${meals} for one person`} placeholder={`Total for ${meals} for one person`}/>
          <Checkbox name={`doBudgetMaxAmount_${idx}`} field value={item.doBudgetMaxAmount}
                    label={`I want to budget the maximum amount possible for ${meals} expenses`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({
                      doBudgetMaxAmount: event.target.checked,
                      maxTotalAdjust: event.target.checked ? item.maxTotal : ''
                    })}
          />
        </Field>
        <Field last>
          <Input name={`maxTotalAdjust_${idx}`} money onBlur={handleBlur}
                 errors={disabledMax ? maxError : (errors[`${errorKey}.maxTotalAdjust`] || localErrors.maxTotalAdjust)}
                 disabled={!item.doBudgetMaxAmount}
                 type="number"
                 help={`Based on the numbers entered, we calculated the total for ${meals} for one person. However, if you wish to change this value, uncheck the box and enter the value in Adjusted total for ${meals} expenses filed. Please note that the value entered may be greater than Total for ${meals} for one Person`}
                 label={`Adjusted total for ${meals} expenses`}
                 placeholder={`Enter adjusted total for ${meals} expenses`}
                 value={item.maxTotalAdjust}
                 onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({maxTotalAdjust: event.target.value})}
          />
        </Field>
        <div className={styles.seasonFooter}>
          {
            (first || last || zero)
              ?
              <Button htmlType="button" disabled={disableDiscountButton} onClick={() => onChangeShow(true)}
                      name={`show-discount-modal-${idx}`} type={zero ? 'primary' : 'bordered'}>
                {discountBtnTitle}
              </Button>
              : null
          }
          <Button htmlType="submit" name={`submit-season-${idx}`}>Save</Button>
          <div className={styles.clear}>
            <Button htmlType="button" onClick={clearSeason} type="link" name={`clear-season-${idx}`}>Clear and
              save</Button>
          </div>
        </div>
      </Form>

      <Modal visible={show} title={discountModalTitle} onCancel={onCloseModal}>
        <Form onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
          <Checkbox name="isStartDateFollowsEndDate" value={item.discount.isStartDateFollowsEndDate}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDiscount({isStartDateFollowsEndDate: event.target.checked}, true)}
                    label="The start date of this trip does not immediately follow the end date of another part of this trip"/>
          <Field>
            <Input name="dailyAmountOfExpenses" money value={disableModal ? '' : item.discount.dailyAmountOfExpenses}
                   label={`Daily amount for ${modalMeals}`} placeholder={`Daily amount for ${modalMeals}`} disabled/>
            <Input name="threeQuarters" value={disableModal ? '' : item.discount.threeQuarters}
                   label={`75% of ${modalMeals}`} placeholder={`75% of ${modalMeals}`} disabled/>
          </Field>
          <Field>
            <Input name="maxAmount" value={disableModal ? '' : item.discount.maxAmount} label={discountInputLabel}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDiscount({maxAmount: event.target.value})}
                   onBlur={handleBlur} money disabled={disableModal}
                   placeholder="Enter amount to budget"
                   help="For the first day, you can only budget 75% of the expenses"
            />
          </Field>
          <Field last>
            <Input name="amount" money value={disableModal ? '' : item.discount.amount} label="Discount amount"
                   help={item.discount.amount === 0 ? `The discount can be only applied if the "Max amount to budget" is less or equal to 75% of expenses` : undefined}
                   placeholder="Discount amount" disabled/>
          </Field>
          <div className={styles.modalFooter}>
            <Button htmlType="button" name="close-discount-modal" type="bordered" onClick={onCloseModal}>Cancel</Button>
            <Button htmlType="submit" disabled={disableModal} name="save-discount-modal" onClick={onCloseModal}>Submit
              form</Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default Season;
