import { FC, useCallback} from 'react';
import Field from 'components/field';
import Typo from 'components/typo';
import {Input, Form, Checkbox, Button} from 'components/form';
import FormField from 'components/form/field';
import useGrants from 'hooks/useGrants';
import {TravelStatus, TravelYear} from 'store/grants/types';
import styles from './attendee.module.css';
import {Errors} from 'helpers/errors';
import {getYearLabel} from 'helpers/date';
import TransportationYears from 'pages/grant/one/travel/common/years';
import TravelPersonnel from 'pages/grant/one/travel/common/personnel';
import {FieldErrors} from "react-hook-form";

type Props = {
  data: TravelStatus;
  onSubmit: () => void;
  loading: boolean;
  status?: boolean;
  onBlur?: () => void;
  onCopy?: () => void;
  errors: Errors;
  onChange: (field: Record<string, any>, preview?: boolean) => void;
  trackError?: (errors: FieldErrors) => void;
  trackExit?: () => void;
  trackFormStarted?: () => void;
}

const AttendeesForm: FC<Props> = ({
                                    trackError,
                                    trackExit,
                                    trackFormStarted, data, onCopy, errors, onBlur, status, onSubmit, loading, onChange
                                  }) => {
  const {grant} = useGrants();

  const onChangeYears = useCallback((year: Record<string, any>, idx: number | string) => {
    const newYears = data.years.map((item: TravelYear) => {
      if (item.serialNumber === idx) return {
        ...item,
        ...year
      }
      return item;
    })
    onChange({years: newYears});
  }, [data, onChange]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const hintText = `Please list the maximum number of other personnel by type that you would like to put
        on travel status. This amount can be fluid. These numbers are simply used as a baseline multiplier later on in
        the trip budget and can be overridden when you reach that section.
        `;

  const personnelData = {
    seniorPersonnel: data.seniorPersonnel,
    academicResearchAssociate: data.academicResearchAssociate,
    postDocs: data.postDocs,
    graduateStudents: data.graduateStudents,
    undergraduateStudents: data.undergraduateStudents,
    civilService: data.civilService,
    union: data.union,
    tempAndCasual: data.tempAndCasual,
    otherPersonnel: data.otherPersonnel,
  };

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit}
          trackFormStarted={trackFormStarted}>
      <Typo type="div" bold className={styles.title}>
        <>
          <span>Trip attendees</span>
          {onCopy ?
            <Button htmlType="button" onClick={onCopy} name="duplicate-from-status" type="mint">Duplicate data from
              Travel status</Button> : null}
        </>
      </Typo>
      <TravelPersonnel data={personnelData} grant={grant} onChange={onChange} onBlur={onBlur} hint={hintText}/>
      {status
        ? null
        :
        <Field>
          <Input name="compensationBeforeInflation" money value={data.compensationBeforeInflation}
                 label="Lodging and meals for all people before inflation"
                 placeholder="Amount of compensation before inflation" disabled readOnly
          />
        </Field>
      }
      {status
        ?
        <FormField name="yearsLabel" helpInLabel
                   label="Please check the year(s) of this grant proposal that this trip will take place in"
                   help="This will automatically duplicate the costs you budget under this trip for future years. Don’t worry if not all costs relate to all future trips, but the trip in the future will still be similar enough to consider the same trip. You will have the option to unselect what years certain expenses will occur as you go along. However, they will default to the years you have selected here.">
          <div className={styles.checkboxes}>
            {data.years.map((item: TravelYear, idx: number) => (
              <Checkbox name={`year[${idx}]`} value={item.isEnabled}
                        onChange={() => onChangeYears({isEnabled: !item.isEnabled}, item.serialNumber || idx)} key={idx}
                        label={getYearLabel(item.year, idx)}/>
            ))}
          </div>
        </FormField>
        : <TransportationYears errorsPrefix="tripAttendees" onBlur={onBlur}
                               totalAmountForTrip={data.totalAmountForTrip} //@ts-ignore
                               data={data.years} onChange={onChange} errors={errors}/>
      }

      <Typo>
        For every section except for Travel Costs, please enter all of the expenses for the trip based on current values
        and prices regardless of what year you are planning the trip for. We will apply Inflation automatically based on
        the year you are in. It is okay if all amounts aren't exact. Estimates based on current prices available are
        expected.
      </Typo>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-equip">Save</Button>
      </div>
    </Form>
  );
}

export default AttendeesForm;
