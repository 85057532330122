import React, {ChangeEvent, FC} from 'react';
import Field from 'components/field';
import {Button, Form, Checkbox, Autocomplete, Input, Select} from 'components/form';
import Typo from 'components/typo';
import styles from './forms.module.css';
import useEnums from 'hooks/useEnums';
import { Props } from './index';

const OtherForm:FC<Props> = ({ data, travel, onBlur, onSubmit, errors, isUpdate, loading, onChange }) => {
  const { travelExpensesTypes, travelCostCategories, getTravelCostCategories, isTravelCostCategoriesLoading } = useEnums();

  const isConsultantTrip = travel.type === 'consultant';

  return (
    <Form prompt onSubmit={onSubmit}>
      <Typo className={styles.text} >This section will hopefully provide a base to work from when budgeting the
        more specific requirements of your grant. We aim to be able to provide precise templates for some common other
        requests as soon as possible. We appreciate your early support and are eager to offer you more specific support
        in the near future. Thank you for your understanding.</Typo>
      <Typo className={styles.text} >Some common expenses that may relate to your trip include estimated
        conference costs, additional support for dependants, specific data storage needs or polar program requirements.
        You will be able to assign the cost to people on your trip by the years that the expense will happen. We will
        handle the key compliance details behind the scenes. Key tip: If you know what type of expense you are looking
        to budget in, go to the pdf version of the NSF rule book (PAPPG guidelines document). If you use control + f on
        windows, (the find command on Macs) and search the name of the expense you are looking for, this is a great way
        to see all of the places your key term comes up in the rule book document.</Typo>

      <Field withHelp>
        <Input name="nameOfTrip" errors={errors.nameOfTrip} value={data.nameOfTrip}
               label="Name of travel" required placeholder="Enter a name of travel"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({nameOfTrip: event.target.value})}
        />
        <Autocomplete
          name="category"
          min={3}
          onLoad={getTravelCostCategories}
          options={travelCostCategories}
          placeholder="Select or input category"
          label="Category for other travel expense"
          value={data.category}
          required
          onChange={(category: any) => onChange({ category })}
          onEnter={(category: any) => onChange({ category })}
          clearable
          isLoading={isTravelCostCategoriesLoading}
          help="This is so you are easily able to search for this expense and budget it into a different trip on other grants or as generally needed."
        />
      </Field>
      <Field>
        <Select options={travelExpensesTypes} value={data.typeOfExpenses} errors={errors.typeOfExpenses}
                name="typeOfExpenses" label="Type of expenses" required placeholder="Select type of expenses"
                onChange={(typeOfExpenses: any) => onChange({ typeOfExpenses })}
        />
      </Field>
      <Field withHelp>
        <Input name="price" onBlur={onBlur} errors={errors.price} value={data.price} type="number" money
               label="Price for expense" required placeholder="Enter a price for expense"
               help={`Please use the current price of the expense. You will be able to select later on who that is traveling this expense will apply to. If this expense applies to the grant on the whole and not to a specific person, please enter the total value in here for one whole year and select the option that this is a general travel expense that doesn't happen to just one person.`}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({price: event.target.value})}
        />
        <Input name="numberOfOccurrence" onBlur={onBlur} errors={errors.numberOfOccurrence} value={data.numberOfOccurrence}
               label="Number of times expense will happen in year" required type="number" placeholder="Enter number of times expense"
               help={`Feel free to leave this unchanged if you have already summed up all of the occurrences. This is just a friendly input that is here if you find it conceptually easier to use a single price for one occurrence and then enter the number of times a year it will happen`}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({numberOfOccurrence: event.target.value})}
        />
      </Field>
      <Field>
        <Input name="totalAmount" type="number" money value={data.totalAmount}
               label="Total amount for the expense in a year" placeholder="Total amount for the expense" disabled readOnly
        />
      </Field>
      <Field>
        <Checkbox name="isPerPerson" value={data.isPerPerson} disabled={isConsultantTrip}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({isPerPerson: event.target.checked}, true)}
                  label="Budget these costs per person"
                  help={`If you check this option you will be able to select who this expense occurs to by year. If you leave this box blank you will be able to check which years of the grant this applies to and won't have to worry about selecting people each year.`}
        />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add'} travel costs`}
        </Button>
      </div>
    </Form>
  );
}

export default OtherForm;
