import {ChangeEvent, FC} from 'react';
import {Props} from './index';
import useEnums from 'hooks/useEnums';
import Field from 'components/field';
import {Input, Form, Select, Button} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import helps from './common/helps';

const Subway:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {

  const { travelExpensesTypes } = useEnums();

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name subway expense" required placeholder="Enter name subway expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field full>
        <Input placeholder="Enter the city" label="City in which the subway trip will take place"
               name="city"
               value={details.city} errors={errors['details.city']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ city: event.target.value })}
        />
      </Field>

      <Field top={Boolean(errors['details.numberOfPeopleWithTicket'] || errors['details.price'])}>
        <Input placeholder="Enter the number of people" label="Number of people who will use the ticket" required
               name="numberOfPeopleWithTicket" type="number" onBlur={onBlur}
               value={details.numberOfPeopleWithTicket} errors={errors['details.numberOfPeopleWithTicket']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfPeopleWithTicket: event.target.value })}
        />
        <Input placeholder="Enter a price" label="Price of subway ticket" required
               name="price" type="number" onBlur={onBlur} money
               value={details.price} errors={errors['details.price']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ price: event.target.value })}
        />
      </Field>

      <Field withHelp top={Boolean(errors['details.numberOfTrips'] || errors['details.totalCost'])}>
        <Input placeholder="Enter number of subway trips" label="Number of subway trips in the year for all trip attendees" required
               name="numberOfTrips" type="number" onBlur={onBlur} help={helps.subwayTrips}
               value={details.numberOfTrips} errors={errors['details.numberOfTrips']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfTrips: event.target.value })}
        />
        <Input placeholder="Enter total cost of misc expenses" label="Miscellaneous expenses for subway travel for all trip attendees"
               name="totalCost" type="number" onBlur={onBlur} money
               value={details.totalCost} errors={errors['details.totalCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
        />
      </Field>

      <Field>
        <Input placeholder="Amount of compensation before inflation" label="Amount budgeted for subway travel in year before inflation"
               name="amountOfCompensationBeforeInflation" money
               value={data.compensationBeforeInflation}
               disabled readOnly
        />
      </Field>
      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Subway;
