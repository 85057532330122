import {ChangeEvent, FC, useCallback} from 'react';
import Field from 'components/field';
import {Input, Form, Checkbox, Button} from 'components/form';
import styles from './breakdown.module.css';
import {MaIEBreakdown} from 'store/grants/types';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

type Props = {
  data: MaIEBreakdown;
  onSubmit: () => void;
  onBlur: () => void;
  errors: Errors;
  loading: boolean;
  onChange: (field: Record<string, any>) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const TotalsForm: FC<Props> = ({
                                 trackError,
                                 trackExit, trackFormStarted, data, errors, loading, onSubmit, onChange
                               }) => {

  const error = ["The entered value is over the maximum amount allowed according to the website. Please double check to make sure the values for each daily rate and number of nights are correct."];

  const disabled = data.maxTotalAdjust > data.maxTotal;

  const onChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange({
      doBudgetMaxAmount: event.target.checked,
      maxTotalAdjust: event.target.checked ? data.maxTotal : ''
    });
  }, [onChange, data]);

  const totalHelp = (
    <span>
      Please double check if the total lodging amount displayed here, is the same as the amount you received on the <a
      href="https://www.gsa.gov/" target="_blank" rel="noreferrer">Home</a> website.
      If not please go back and double check the number you input.
    </span>
  );

  return (
    <Form prompt onSubmit={onSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <Field withHelp>
        <Input name="maxTotal" disabled readOnly value={data.maxTotal} help={totalHelp} helpDebounce={3000}
               label="Max meals and incidental expenses breakdown" placeholder="Max M&IE"/>
        <Checkbox name="doBudgetMaxAmount" value={data.doBudgetMaxAmount} errors={errors.doBudgetMaxAmount}
                  onChange={onChangeCheckbox} field
                  label="I want to budget the maximum amount possible for Meals and incidental expenses"/>
      </Field>
      <Field last>
        <Input name="maxTotalAdjust" value={data.maxTotalAdjust} type="number"
               errors={disabled ? error : errors['mAndIeBreakdown.maxTotalAdjust']}
               label="Adjusted budget for meals and incidental expenses" placeholder="Enter adjusted budget for M&IE "
               disabled={!data.doBudgetMaxAmount} money required={!disabled}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({maxTotalAdjust: event.target.value})}
        />
      </Field>
      <div className={styles.footer}>
        <Button loading={loading} disabled={disabled} name="submit-totals" htmlType="submit">Save</Button>
      </div>
    </Form>
  );
}

export default TotalsForm;
