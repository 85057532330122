import {FC} from 'react';
import {Link} from 'react-router-dom';
import Icon from 'components/icon';
import cx from 'classnames';
import styles from './plan.module.css';
import {trackUserAction} from 'helpers/trackUserActions';
import {useMixPanel} from "../../hooks/useMixPanel";

type Props = {
  className?: string;
}

const PlanButton: FC<Props> = ({className}) => {
  const {planUpgradeClicked} = useMixPanel()
  return (
    <Link to={`/settings/account/billing`} className={cx(styles.link, className)} onClick={() => {
      trackUserAction('User Clicks on the screen showing the upgrade options')
      planUpgradeClicked()
    }}>
      <button className={styles.btn}>Upgrade plan <Icon size={20} className={styles.icon} icon="arrow-right"/></button>
    </Link>
  );
}

export default PlanButton;
