import {useParams} from 'react-router-dom';
// types
import {MaterialsSuppliesGeneralInfoType} from 'store/grants/otherDirectCosts/MaterialsSupplies/types';
// hooks
import useMaterialsSupplies from 'hooks/useMaterialsSupplies';
import useGrants from 'hooks/useGrants';
// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import MaterialsSuppliesGeneralInfoForm from './form';
// helpers
import {isNull} from 'lodash';

const CreateEditMSGeneralInfo = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();
  const generalInfo = {
    hasCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.hasCapitalizationLevel!,
    universityCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.universityCapitalizationLevel!,
    defaultCapitalizationLevel: grant.otherDirectCosts.materialsAndSupplies.defaultCapitalizationLevel!,
  }
  const {
    createGeneralInfo, updateGeneralInfo,
    trackError,
    trackExit,
    trackFormStarted
  } = useMaterialsSupplies({form_page_type: 'General info'});

  const pageKey = `${params.id}-materials-n-supplies-${generalInfo?.universityCapitalizationLevel}-general-info-${generalInfo?.defaultCapitalizationLevel}`;
  const isCreationForm = isNull(generalInfo.hasCapitalizationLevel);

  const onSubmit = (data: MaterialsSuppliesGeneralInfoType) => {
    isCreationForm ? createGeneralInfo(data, grant.id) : updateGeneralInfo(data, grant.id);
  };

  return (
    <GrantFormWrapper subtitle={'Materials and Supplies'} title={'Other direct costs'} key={pageKey}>
      <MaterialsSuppliesGeneralInfoForm generalInfo={generalInfo} onSubmit={onSubmit}
                                        trackError={trackError}
                                        trackExit={trackExit}
                                        trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  );
};

export default CreateEditMSGeneralInfo;
