import {FC, useCallback} from 'react';
import cx from 'classnames';
import {ceil} from 'lodash';
import {Button, Form, Input, Slider} from 'components/form';
import styles from './compensation.module.css';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

export type PercentCompensationData = {
  compensationAfterInflation: number | string;
  compensationBeforeInflation: number | string;
  equivalentNumberOfMonths: number | string;
  percent: number;
}

type Props = {
  onBlur: () => void;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  year: number;
  onChange: (value: PercentCompensationData) => void;
  compensation: PercentCompensationData;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
}

const CompensationPercentForm: FC<Props> = ({
                                              trackError,
                                              trackExit,
                                              trackFormStarted,
                                              errors,
                                              loading,
                                              compensation,
                                              year,
                                              onSubmit,
                                              onBlur,
                                              onChange
                                            }) => {

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const onChangePercent = useCallback((percent: number) => {
    onChange({
      ...compensation,
      percent
    });
  }, [onChange, compensation])

  return (
    <Form prompt onSubmit={handleSubmit} trackError={trackError} trackExit={trackExit} trackFormStarted={trackFormStarted}>
      <div className={styles.field}>
        <Slider name="percent" postfix="%" errors={errors[`${year - 1}.percent`] || errors.percent}
                value={compensation.percent} onBlur={onBlur} onChange={onChangePercent}
                label="Percentage of annual salary requested per year" required/>
      </div>
      <div className={styles.field}>
        <Input name="equivalentNumberOfMonths" value={ceil(Number(compensation.equivalentNumberOfMonths), 2)} readOnly
               disabled className={styles.fullInput} label="Equivalent number of months"
               placeholder="Equivalent number of months"/>
      </div>
      <div className={styles.field}>
        <Input name="compensationBeforeInflation" money
               value={ceil(Number(compensation.compensationBeforeInflation), 2)} readOnly disabled
               className={styles.fullInput}
               label={`Amount of compensation requested per year before inflation in Year ${year}`}
               placeholder="Amount of compensation requested per year before inflation"/>
      </div>
      <div className={cx(styles.field, styles.last)}>
        <Input
          name="compensationAfterInflation"
          money
          value={ceil(Number(compensation.compensationAfterInflation), 2)}
          readOnly
          disabled
          className={styles.fullInput}
          label={`Amount of compensation requested per year after inflation in Year ${year}`}
          help={year === 1 ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.` : undefined}
          placeholder="Amount of compensation requested per year after inflation"
        />
      </div>
      <div className={styles.formFooter}>
        <Button htmlType="submit" name="submit" size="xl" loading={loading}>Save</Button>
      </div>
    </Form>
  )
}

export default CompensationPercentForm;
