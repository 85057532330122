import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useTravel from 'hooks/useTravel';
// types
import { FullGrant, Travel, TravelCostData, TravelYear } from 'store/grants/types';
import { Button, Form, Input } from 'components/form';
import Field from 'components/field';
import ConsultantAttendeeYearsForm from 'pages/grant/one/travel/common/attendee/consultantAttendeeYears';
// styles
import styles from './attendee.module.css';

type TravelCost48ConsultantAttendeeProps = {
  cost: TravelCostData;
  grant: FullGrant;
  trip: Travel;
};

const TravelCost48ConsultantAttendee: FC<TravelCost48ConsultantAttendeeProps> = ({ cost, trip, grant }) => {
  const { loading, previewTravelCost, updateTravelCost } = useTravel();
  const params: Record<string, string> = useParams();
  const defaultYears =
    trip.status.years.length > 0
      ? trip.status.years.map((year: TravelYear) => ({
        year: year.year,
        serialNumber: year.serialNumber,
        isEnabled: false,
        numberOfOccurrence: '',
        amountWithInflation: '',
      }))
      : grant.years.map((year: string[], serialNumber: number) => ({
        year,
        serialNumber: serialNumber + 1,
        isEnabled: false,
        numberOfOccurrence: '',
        numberOfConsultants: '',
        amountWithInflation: '',
      }));

  const {tripAttendees: {compensationBeforeInflation, totalAmountForTrip}} = cost;

  const [data, setData] = useState({
    years: cost && cost.tripAttendees.years.length > 0 ? cost.tripAttendees.years : defaultYears,
    compensationBeforeInflation: (compensationBeforeInflation || compensationBeforeInflation === 0) ? compensationBeforeInflation : '',
    totalAmountForTrip: (totalAmountForTrip || totalAmountForTrip === 0) ? totalAmountForTrip : '',
  });

  const onBlur = useCallback((event: any, blurData?: any) => {
    const value = blurData ?? data;
    if (grant.id && cost) {
      previewTravelCost(
        grant.id,
        trip.id,
        cost.id,
        {
          tripAttendees: {
            isNotForPersons: true,
            years: value.years.map((item: any) => ({
              ...item,
              isConsultantService: true
            }))
          },
        },
        (response: any) => {
          setData({
            ...value,
            years: response?.tripAttendees.years,
            compensationBeforeInflation: response?.tripAttendees.compensationBeforeInflation,
            totalAmountForTrip: response?.tripAttendees.totalAmountForTrip
          });
        },
      );
    }
  }, [previewTravelCost, data, cost, grant.id, trip],);

  const onChangeYears = useCallback((year: Record<string, any>, idx: number | string, blur?: boolean) => {
    const newYears = data.years.map((item: TravelYear) => {
      if (item.serialNumber === idx) {
        return {
          ...item,
          ...year,
        };
      }
      return item;
    });
    const newData = {
      ...data,
      years: newYears,
    };
    setData(newData);
    if (blur) onBlur(undefined, newData);
  }, [data, setData, onBlur]);

  const handleSubmit = () => {
    if (trip.id && cost) {
      updateTravelCost(grant.id, trip.id, params.costId, {
        tripAttendees: {
          ...data,
          isNotForPersons: true,
          years: data.years.map((item: any) => ({
            ...item,
            isConsultantService: true
          })),
        },
      });
    }
  };

  return (
    <Form prompt onSubmit={handleSubmit}>
      <Field>
        <Input
          name="compensationBeforeInflation"
          money
          value={data.compensationBeforeInflation}
          label="Lodging and meals before inflation"
          placeholder="Amount of compensation before inflation"
          disabled
          readOnly
        />
      </Field>

      <ConsultantAttendeeYearsForm
        years={data.years}
        onBlur={onBlur}
        onChangeYear={onChangeYears}
        totalAmountForTrip={data.totalAmountForTrip}
      />

      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-equip">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default TravelCost48ConsultantAttendee;
