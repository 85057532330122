import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import {personalTypes, personnelFieldInGrant} from 'const';
import * as T from 'hooks/requestTypes';
import {useParams} from 'react-router-dom';
import {getCivilServiceGrant, OtherPersonFieldForSearch} from 'helpers/getFromGrant';
import OtherCompensationForm, {TempAndCasualCompensationForm} from './otherForm';
import useGrants from 'hooks/useGrants';
import usePersonnel from 'hooks/usePersonnel';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';

const OtherCompensationPage: FC = () => {
  const params: Record<string, string> = useParams();
  const {grant} = useGrants();
  const {
    calculateOtherPersonnel, updateOtherNSF, errors, loading, trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({
    form_page_type: 'Personal Info'
  });

  const title = useMemo(() => {
    if (params.personnelType === personalTypes.union) return 'Union';
    if (params.personnelType === personalTypes.temp) return 'Temp and Casual';
    if (params.personnelType === personalTypes.other) return 'Other Personnel';
    return 'Civil Service';
  }, [params]);

  const {
    person,
    index
  } = getCivilServiceGrant(grant, personnelFieldInGrant[params.personnelType] as OtherPersonFieldForSearch, params.personId);

  const [data, onChangeData] = useState(() => {
    const nsf = person?.nsfCompensation[Number(params.year)];
    const isTemp = params.personnelType === personalTypes.temp;
    const defaultPaidBy = isTemp ? 'hourly' : undefined;
    return {
      amountOfPeople: nsf?.amountOfPeople ?? undefined,
      paidBy: nsf?.paidBy ?? defaultPaidBy,
      isSpecificWage: nsf?.details.isSpecificWage === undefined ? undefined : (nsf?.details.isSpecificWage ? 1 : 0),
      totalBudgeted: nsf?.totalBudgeted ?? undefined,
      hourlyWage: nsf?.details.hourlyWage ?? undefined,
      reasonableWage: nsf?.details.reasonableWage ?? undefined,
      hoursPerWeek: nsf?.details.hoursPerWeek ?? undefined,
      weeksPerYear: nsf?.details.weeksPerYear ?? undefined,
      annualSalary: nsf?.details.annualSalary ?? undefined,
      averagePercent: nsf?.details.averagePercent ? percentToFixed(nsf.details.averagePercent) : undefined,
      totalBudget: undefined,
      totalCompensation: undefined,
      totalForPosition: undefined,
    };
  });

  const handleChangeData = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const serialNumber = useMemo(() => Number(params.year) + 1, [params]);

  const subtitle = useMemo(() => {
    return `Position ${index}. Year ${serialNumber}`;
  }, [serialNumber, index]);

  const onBlur = useCallback((event?: ChangeEvent<HTMLInputElement>) => {
    const year = person?.nsfCompensation[Number(params.year)]?.year;
    let field = {}; //todo think how to fix it in another way
    if (event) {
      field = {[event.target.name]: event.target.value}
    }
    if (year && data.amountOfPeople && data.paidBy) {
      calculateOtherPersonnel(grant.id, params.personnelType, {
        year,
        ...data,
        ...field,
        paidBy: data.paidBy,
        averagePercent: data.averagePercent ? Number(data.averagePercent) / 100 : undefined,
        serialNumber: serialNumber,
      }, (response: T.OtherPersonCalculationResponse) => {
        //@ts-ignore
        onChangeData({
          ...data,
          ...field,
          ...response,
          averagePercent: response.averagePercent ? percentToFixed(response.averagePercent) : data.averagePercent,
        });
      });
    }
  }, [data, calculateOtherPersonnel, grant, person, params, serialNumber]);

  useEffect(() => {
    const func = isTemp ? onBlurTemp : onBlur;
    func();
  }, [data.paidBy, data.isSpecificWage])

  const onBlurTemp = useCallback(() => {
    const year = person?.nsfCompensation[Number(params.year)]?.year;
    if (year && data.amountOfPeople) {
      calculateOtherPersonnel(grant.id, params.personnelType, {
        year,
        ...data,
        paidBy: 'hourly',
        averagePercent: data.averagePercent ? Number(data.averagePercent) / 100 : undefined,
        serialNumber: serialNumber,
      }, (response: T.OtherPersonCalculationResponse) => {
        //@ts-ignore
        onChangeData({
          ...data,
          ...response,
          averagePercent: response.averagePercent ? percentToFixed(response.averagePercent) : data.averagePercent,
        });
      });
    }
  }, [data, calculateOtherPersonnel, grant, person, params, serialNumber]);

  const onSubmit = useCallback(() => {
    const nsf = person?.nsfCompensation[Number(params.year)]
    if (person && nsf) {
      const submitData = {
        year: nsf.year,
        amountOfPeople: data.amountOfPeople,
        paidBy: params.personnelType === personalTypes.temp ? 'hourly' : data.paidBy,
        details: {
          isSpecificWage: Boolean(data.isSpecificWage),
          hourlyWage: data.hourlyWage,
          reasonableWage: data.reasonableWage,
          hoursPerWeek: data.hoursPerWeek,
          weeksPerYear: data.weeksPerYear,
          annualSalary: data.annualSalary,
          averagePercent: data.averagePercent ? Number(data.averagePercent) / 100 : undefined,
        },
        totalBudgeted: data.totalBudgeted,
        totalForPosition: data.totalForPosition,
        serialNumber: serialNumber
      };
      updateOtherNSF(grant.id, person.id, params.personnelType, submitData);
    }
  }, [data, updateOtherNSF, grant, params, person, serialNumber]);

  const isTemp = params.personnelType === personalTypes.temp;

  const FormComponent = isTemp ? TempAndCasualCompensationForm : OtherCompensationForm;

  return (
    <GrantFormWrapper title={title} subtitle={subtitle}>
      <FormComponent
        type={params.personnelType}
        errors={errors}
        loading={loading}
        onSubmit={onSubmit}
        data={data}
        onBlur={isTemp ? onBlurTemp : onBlur}
        onChange={handleChangeData}
        year={serialNumber}
        position={index}
        trackError={trackError}
        trackExit={trackExit}
        trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  )
}

export default OtherCompensationPage;
