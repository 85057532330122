import {FC, useCallback} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import EquipmentForm from './form';
import useEquipment from 'hooks/useEquipment';
import * as T from 'hooks/requestTypes';
import useGrants from 'hooks/useGrants';
import styles from './form.module.css';
import Typo from 'components/typo';
import {getEquipmentFromGrant} from 'helpers/getFromGrant';
import {useParams} from 'react-router-dom';

const CreateOrEditEquipment: FC = () => {
  const {grant} = useGrants();
  const params: Record<string, string> = useParams();

  const {unit, index, count} = getEquipmentFromGrant(grant, params.unitId);

  const {update, errors, loading, trackError, trackExit, trackFormStarted} = useEquipment({
    form_page_type: 'Equipment info',
  });

  const onSubmit = useCallback((data: T.EquipmentData) => {
    unit?.id && update(grant.id, unit.id, data);
  }, [grant, unit, update]);

  return (
    <GrantFormWrapper title="Equipment" key={`${grant.id}-equipment-${params.unitId}`}
                      subtitle={`Unit ${index || count + 1}`}>
      <>
        <Typo type="div" className={styles.hint}>
          If this equipment unit acquisition costs over $5,000 and has a more than one-year useful lifespan, please
          proceed with the form below.
          Other smaller items required for this grant but don’t fall under this category will have a chance to be
          budgeted in a later section.
        </Typo>
        <EquipmentForm unit={unit} errors={errors} loading={loading} onSubmit={onSubmit}
                       trackError={trackError}
                       trackExit={trackExit}
                       trackFormStarted={trackFormStarted}
        />
      </>
    </GrantFormWrapper>
  );
}

export default CreateOrEditEquipment;
