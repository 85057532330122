import {ChangeEvent, FC} from 'react';
import {Props} from './index';
import useEnums from 'hooks/useEnums';
import Field from 'components/field';
import {Input, Form, Select, Textarea, Button} from 'components/form';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import helps from './common/helps';

const Taxi:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {

  const { travelExpensesTypes } = useEnums();

  const cabCostHelp = (
    <div>
      You can use one of these links to calculate your taxi fare:
      <ul className="pureList">
        <li>1. <a href="https://www.taxi-calculator.com/taxi-fare-estimation" target="_blank" rel="noreferrer">Taxi-Calculator.com</a> - Your taxi fare calculation result</li>
      <li>2. <a href="https://www.taxifarefinder.com/" target="_blank" rel="noreferrer">TaxiFareFinder</a> - Taxi Fare Estimates</li>
      <li>3. <a href="https://www.worldtaximeter.com/washington-dc" target="_blank" rel="noreferrer">Taxi fare calculator in Washington D.C.</a> - DC, United States.</li>
      </ul>
      In case you use one of the proposed calculations, please copy paste the results into the explanation box below so
      that we know how you arrived to this final value
    </div>
  )


  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name taxi expense" required placeholder="Enter name taxi expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field withHelp>
        <Input placeholder="Enter the cost of one ride" label="The estimated cost of one taxi cab ride" required
               name="cabCost" type="number" onBlur={onBlur} money
               value={details.cabCost} errors={errors['details.cabCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ cabCost: event.target.value })}
               help={cabCostHelp} helpDebounce={3000}
        />
      </Field>
      <Field full>
        <Textarea placeholder="Enter a description..." label="Explain how you arrived at your taxi cab price"
                  name="priceExplanation"
                  value={details.priceExplanation}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeDetails({ priceExplanation: event.target.value })}
        />
      </Field>

      <Field withHelp top={Boolean(errors['details.numberOfRides'] || errors['details.totalCost'])}>
        <Input placeholder="Enter number of similar rides" label="Number of similar taxi rides needed for the trip" required
               name="numberOfRides" type="number" onBlur={onBlur}
               value={details.numberOfRides} errors={errors['details.numberOfRides']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ numberOfRides: event.target.value })}
               help={helps.taxiSimilar}
        />
        <Input placeholder="Enter total cost of misc expenses" label="Miscellaneous expenses for taxi travel for all trip attendees"
               name="totalCost" type="number" onBlur={onBlur} money
               value={details.totalCost} errors={errors['details.totalCost']}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
        />
      </Field>

      <Field>
        <Input placeholder="Amount of compensation before inflation" label="Amount budgeted for all rides before inflation"
               name="amountOfCompensationBeforeInflation" money
               value={data.compensationBeforeInflation}
               disabled readOnly
        />
      </Field>
      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Taxi;
