import {ChangeEvent, FC, useCallback} from 'react';
import {Props} from './index';
import useEnums from 'hooks/useEnums';
import TransportationYears from 'pages/grant/one/travel/common/years';
import styles from './forms.module.css';
import {Button, Form, Input, Select} from 'components/form';
import Field from 'components/field';
import helps from './common/helps';

const Train:FC<Props> = ({ data, participant, onBlur, isUpdate, loading, errors, details, onChangeDetails, onChange, onSubmit }) => {

  const { travelExpensesTypes } = useEnums();

  return (
    <Form prompt onSubmit={onSubmit}>
      <Field top={Boolean(errors.nameExpense || errors.typeOfExpense)}>
        <Input name="nameExpense" value={data.nameExpense} errors={errors.nameExpense}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ nameExpense: event.target.value })}
               label="Name train expense" required placeholder="Enter name plane expense"/>
        <Select name="typeOfExpense" options={travelExpensesTypes} value={data.typeOfExpense} errors={errors.typeOfExpense}
                onChange={(typeOfExpense: any) => onChange({ typeOfExpense })} help={helps.typeOfExpense}
                label="Type of expenses" required placeholder="Enter type of expenses"/>
      </Field>
      <Field withHelp top={Boolean(errors.startLocation || errors.endLocation)}>
        <Input placeholder="Enter starting location" label="Starting location"
               name="startLocation"
               value={data.startLocation} errors={errors.startLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ startLocation: event.target.value })}
        />
        <Input placeholder="Enter ending location" label="Ending location"
               name="endLocation"
               value={data.endLocation} errors={errors.endLocation}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ endLocation: event.target.value })}
        />
      </Field>


      <Field top={Boolean(errors['details.ticketPrice'] || errors['details.tickets'])}>
        <Input placeholder="Enter a price of ticket" label="Estimated price of train ticket (one way)" required
               name="ticketPrice" type="number" money
               value={details.ticketPrice} errors={errors['details.ticketPrice']} onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ ticketPrice: event.target.value })}
        />
        <Input placeholder="Enter the number of tickets" label="Estimated number of train tickets needed for trip" required
               name="tickets" type="number"
               value={details.tickets} errors={errors['details.tickets']} onBlur={onBlur}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ tickets: event.target.value })}
        />
      </Field>

      <Field>
        <Input name="totalCost" money value={details.totalCost} errors={errors['details.totalCost']} onBlur={onBlur} type="number"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeDetails({ totalCost: event.target.value })}
               label="Miscellaneous expenses for train travel for all trip attendees"
               placeholder="Enter total cost of misc expenses"/>
        <Input name="compensationBeforeInflation" value={data.compensationBeforeInflation}
               disabled readOnly money
               label="Amount budgeted for train travel before inflation"
               placeholder="Amount of compensation before inflation"/>
      </Field>
      <TransportationYears participant={participant} totalAmountForTrip={data.totalAmountForTrip} onBlur={onBlur} errors={errors}  data={data.years} onChange={onChange} />
      <div className={styles.footer}>
        <Button loading={loading} htmlType="submit" name="submit-transportation-method">
          {`${isUpdate ? 'Save' : 'Add a'} transportation method`}
        </Button>
      </div>
    </Form>
  );
}

export default Train;
