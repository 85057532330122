import styles from './form.module.css';
import { Checkbox, DatePicker, FileUploader, Input, Form, Select } from 'components/form';
import {ChangeEvent, FC, ReactElement, useCallback, useMemo, useState} from 'react';
import { trackUserAction } from 'helpers/trackUserActions';
import Avatar from 'components/avatar';
import cx from 'classnames';
import Typo from 'components/typo';
import Help from 'components/help';
import {Errors} from 'helpers/errors';
import useEnums from 'hooks/useEnums';
import * as T from 'hooks/requestTypes';
import {User} from 'store/auth/types';
import {ceil} from 'lodash';
import {stringOrUndefined} from 'helpers/date';

export type FormData = {
  firstName: string;
  lastName: string;
  race: string | null;
  yearSalary?: number | string | null;
  calculateAverageBudget: boolean;
  yearlyMonthsSalaryCompensation?: number | string | null;
  oneMonthCompensationAmount?: number | string | null;
  gender: string | null;
  birthdate: null | Date;
  positionTitle: string;
}

type AvatarData = {
  url: string | null;
  key: string | null;
}

export type Field = Record<string, any>;
type Props = {
  onSubmit: (data: FormData) => void;
  data: FormData | T.UpdateUserInfo;
  avatar?: AvatarData;
  user: User;
  onChangeAvatar?: (data: AvatarData) => void;
  children: ReactElement;
  errors?: Errors;
  embed?: boolean;
  short?: boolean;
  last?: boolean;
  onChange: (field: Field) => void;
}

type DivProps = {
  children: any[] | any;
  onSubmit: (data: FormData) => void;
}

const Div:FC<DivProps> = ({children}) => {
  return (
    <div>
      {children}
    </div>
  )
}

const ProfileForm:FC<Props> = (props: Props) => {
  const {
    onSubmit, onChangeAvatar, embed, children,
    data, onChange, user, avatar, errors = {},
    last = true,
    short
  } = props;
  const { genders, races } = useEnums();
  const [ localErrors, onChangeLocalErrors ] = useState<Errors>({});

  const handleBlurInput= useCallback(() => {
    trackUserAction(`User start filling the “Personnel Information” form`)
  }, [])

  const handleChangeNumberData = useCallback((field: Field) => {
    const [value] = Object.values(field);
    const [key] = Object.keys(field);
    if (value !== '' && Number(value) < 1) {
      onChangeLocalErrors({
        [key]: ['Must be greater or equal to 1']
      });
    } else onChangeLocalErrors({});
    onChange(field);
  }, [onChange, onChangeLocalErrors]);

  const handleChangeMonthData = useCallback((field: Field) => {
    const [value] = Object.values(field);
    const [key] = Object.keys(field);
    const localValue = Number(value);
    if (value !== '' && localValue < 1) {
      onChangeLocalErrors({
        [key]: ['Must be greater or equal to 1']
      });
    } else onChangeLocalErrors({});
    onChange({[key]: Number(value) > 12 ? 12 : value});
  }, [onChange]);

  const budgetHelp = useMemo(() => (
    <div className={styles.help}>
      <Typo semi className={styles.helpText}>We use this information to suggest to you the maximum amount of personal funding that you're eligible to receive for successfully funded research. Government guidelines limit the amount of compensation that you can request for on research proposals. We ensure your request adheres to government guidelines so that you don’t have to.</Typo>
      <Typo semi className={styles.helpText}>Don't worry, all of your information is and will always be kept private. It will only be disclosed to collaborators if you personally elect to share your budget with them.</Typo>
    </div>
  ), []);

  const calculations:number | string = useMemo(() => {
    if (data.yearSalary && data.yearlyMonthsSalaryCompensation) {
      return ceil(Number(data.yearSalary)/Number(data.yearlyMonthsSalaryCompensation), 2);
    }
    return '';
  }, [data]);

  const handleSubmit = useCallback(() => {
    let avatarData = avatar?.key ? {profileImage: {key: avatar.key}} : {};
    if (avatar?.key === null) {
      avatarData = {
        profileImage: {
          key: null!
        }
      }
    }
    onSubmit({
      ...data,
      ...avatarData,
      gender: data.gender ? data.gender : null,
      race: data.race ? data.race : null,
      calculateAverageBudget: data.calculateAverageBudget,
      yearSalary: data.yearSalary ? Number(data.yearSalary) : null,
      yearlyMonthsSalaryCompensation: data.yearlyMonthsSalaryCompensation ? Number(data.yearlyMonthsSalaryCompensation) : null,
      oneMonthCompensationAmount: (data.calculateAverageBudget ? calculations as number : Number(data.oneMonthCompensationAmount)) || null,
      //@ts-ignore
      birthdate: stringOrUndefined(data.birthdate)
    })
  }, [onSubmit, avatar, calculations, data]);

  const handleDeleteAvatar = useCallback(() => {
    if (onChangeAvatar) {
      onChangeAvatar({
        url: null,
        key: null
      });
    }
  }, [onChangeAvatar]);

  const Parent = embed ? Div : Form;

  const canDeleteAvatar = useMemo(() => {
    return avatar?.url
  }, [avatar])

  return (
    <Parent onSubmit={handleSubmit}>
      <div className={styles.field}>
        <Input name="firstName" errors={errors.firstName} className={styles.input} value={data.firstName}
               label="First Name" required placeholder="Enter your first name"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({firstName: event.target.value})}
               onBlur={handleBlurInput}
        />
        <Input name="lastName" errors={errors.lastName} className={styles.input} value={data.lastName}
               label="Last Name" required placeholder="Enter your last name"
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({lastName: event.target.value})}
        />
      </div>

      {short
        ?
          <div className={cx(styles.field, styles.last)}>
            <Input name="email" errors={errors.email} className={styles.fullInput} value={user.email}
                   label="Email" placeholder="Enter your email" readOnly disabled preIcon="mail-01"
            />
          </div>
        :
          <>
            <div className={styles.field}>
              <Select name="race" errors={errors.race} options={races} className={styles.input} value={data.race}
                      label="Race/Ethnicity" placeholder="Select race/ethnicity"
                      onChange={(race: any) => onChange({ race })}
              />
              <Select name="gender" errors={errors.gender} options={genders} className={styles.input} value={data.gender}
                      label="Gender" placeholder="Select gender"
                      onChange={(gender: any) => onChange({ gender })}
              />
            </div>
            <div className={styles.field}>
              <DatePicker //@ts-ignore
                name="birthdate" errors={errors.birthdate} value={data.birthdate} placeholder="Select date"  //@ts-ignore
                label="Birthdate" className={styles.input} preIcon="calendar-01"
                onChange={(birthdate: Date | null) => onChange({ birthdate })}
              />
              <Input name="email" errors={errors.email} className={styles.input} value={user.email}
                     label="Email" placeholder="Enter your email" readOnly disabled preIcon="mail-01"
              />
            </div>
          </>
      }



      {short
        ? null
        :
          <>
            {
              avatar ?
                <div className={styles.field}>
                  <div className={styles.imageField}>
                    <Avatar className={styles.avatar} onDelete={canDeleteAvatar ? handleDeleteAvatar : undefined} src={avatar.url || ''} alt={user.fullName} big />
                    <FileUploader onChange={onChangeAvatar} name="avatar" className={styles.file}
                                  type="uploadProfileImage" accept=".png, .jpg, .svg, .gif, .jpeg"
                                  placeholder="SVG, PNG, JPG or GIF"/>
                  </div>
                </div>
                : null
            }

            <div className={cx(styles.field, styles.last)}>
              <Input name="position" errors={errors.positionTitle} className={styles.fullInput} value={data.positionTitle}
                     label="Position Title" placeholder="Enter your position title"
                     onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({positionTitle: event.target.value})}
              />
            </div>
          </>
      }
      <div className={styles.header}>
        <Typo type="div" className={styles.tabTitle} semi>
          <>
            Personal Budget
            <Help>{budgetHelp}</Help>
          </>
        </Typo>
      </div>


      <div className={styles.field}>
        <Input name="yearSalary" className={styles.fullInput} type="number" value={data.yearSalary}
               label="Year Salary" placeholder="Enter your year salary" money
               onChange={(event: ChangeEvent<HTMLInputElement>) =>
                 handleChangeNumberData({yearSalary: event.target.value})}
               errors={localErrors.yearSalary || errors.yearSalary} min={1}
        />
      </div>


      <div className={styles.field}>
        <Input name="months" type="number" className={styles.fullInput}
               value={data.yearlyMonthsSalaryCompensation}
               errors={localErrors.yearlyMonthsSalaryCompensation || errors.yearlyMonthsSalaryCompensation}
               onChange={(event: ChangeEvent<HTMLInputElement>) =>
                 handleChangeMonthData({yearlyMonthsSalaryCompensation: event.target.value})}
               label="How many months of the year does your employment contract cover?"
               placeholder="Input number of months" min={1} step="0.1"
        />
      </div>
      <div className={cx(styles.field, cx({[styles.last]: last}))}>
        <div className={styles.columnWithCheckbox}>
          <Checkbox name="calculate" value={data.calculateAverageBudget} label="Calculate Average Monthly Salary"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({calculateAverageBudget: event.target.checked})}
          />
          <Input
            name="oneMonthCompensationAmount"
            disabled={data.calculateAverageBudget}
            className={styles.fullInput}
            money
            type="number"
            value={data.calculateAverageBudget ? calculations : data.oneMonthCompensationAmount}
            label="How much money on average do you make per month?"
            placeholder="Average amount of money per month"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                   handleChangeNumberData({oneMonthCompensationAmount: event.target.value})}
            errors={localErrors.oneMonthCompensationAmount || errors.oneMonthCompensationAmount}
          />
        </div>
      </div>
      {children}
    </Parent>
  )
}

export default ProfileForm;
