import {FC, useCallback, useMemo} from 'react';
import styles from './participant.module.css';
import Tip from 'components/tip';
import {FullGrant, GrantEvent, GrantEventParticipant} from 'store/grants/types';
import useUrl from 'hooks/useUrl';
import Typo from 'components/typo';
import {Button} from 'components/form';
import useParticipants from 'hooks/useParticipants';
import Item from 'pages/grant/paper/card/item';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';

type Props = {
  item: GrantEvent;
  grant: FullGrant;
  idx: number;
}

export const Event:FC<Props> = ({ item, grant, idx }) => {
  const url = useUrl();
  const { addParticipant, deleteParticipant, loading } = useParticipants();

  const handleDeleteParticipant = useCallback((id?: string) => {
    if (id) deleteParticipant(grant.id, item.id, id, url.section === id);
  }, [deleteParticipant, url, item, grant]);

  const list = useMemo(() => {
    return item.participants.map((participant: GrantEventParticipant) => {
      const links = [
        {
          label: 'Participant info',
          to: `/grants/${grant.id}/events/${item.id}/participants/${participant.id}/edit`,
          active: url.section === participant.id && url.sectionId === 'edit'
        },
        {
          label: "Participant's stipends",
          to: `/grants/${grant.id}/events/${item.id}/participants/${participant.id}/stipends`,
          active: url.section === participant.id && url.sectionId === 'stipends'
        },
        {
          label: "Participant's travel",
          to: `/grants/${grant.id}/events/${item.id}/participants/${participant.id}/travels`,
          active: url.section === participant.id && url.sectionId === 'travels'
        },
        {
          label: "Participant's expenses",
          to: `/grants/${grant.id}/events/${item.id}/participants/${participant.id}/expenses`,
          active: url.section === participant.id && url.sectionId === 'expenses'
        },
      ];
      return (
        <Item key={participant.id}
              title={participant.category || 'Uncategorized participant'}
              links={links}
              onDelete={() => handleDeleteParticipant(participant.id)}
              active={url.section === participant.id}
        />
      );
    });
  }, [item, url, handleDeleteParticipant, grant]);

  const allowAddParticipant = useMemo(() => {
    return Boolean(item.name && item.category);
  }, [item]);

  const handleAddParticipant = useCallback(() => {
    if (allowAddParticipant) {
      addParticipant(grant.id, item.id)
    }
  }, [item, allowAddParticipant, grant, addParticipant]);

  return (
    <div className={styles.tripWrapper}>
      <Anchor thin to={`/grants/${grant.id}/events/${item.id}/edit`} active={url.subCategory === item.id && url.categoryId === 'edit'}>
        Event info
      </Anchor>
      {list.length > 0
        ? <>
          {list}
          <TotalAnchor total={item.totalAmount} to={`/grants/${grant.id}/events/${item.id}/totals`} active={url.subCategory === item.id && url.categoryId === 'totals'}>
            Totals. Event {idx}
          </TotalAnchor>
        </>
        : <Typo className={styles.placeholder} size={14} type="div">No participants have been added yet</Typo>
      }
      {
        allowAddParticipant
          ?
            <Button loading={loading} size="xs" onClick={handleAddParticipant} full name={`add-event-${idx}-participant`} preIcon="plus">Add a participant</Button>
          :
          <Tip top text="To add a participant, you must save event info first">
            <Button loading={loading} disabled size="xs" onClick={handleAddParticipant} full name={`add-event-${idx}-participant`} preIcon="plus">Add a participant</Button>
          </Tip>
      }
    </div>
  );
}

export default Event;
