// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Typo from 'components/typo';
// styles
import styles from './fees.module.css'

const FeesInfo = () => {
  return (
    <GrantFormWrapper title="Fees">
      <>
        <Typo className={styles.mb16}>
          Cost's associated with fee's are only relevant if you are filling out an SBIR/STTR program solicitation.
        </Typo>
        <Typo className={styles.mb16}>
          The Fee's Costs are program specific for just the SBIR/STTR program solicitations. We encourage you to go back
          into your program solicitation and use your find keyboard shortcut to jump to the portions regarding budgeting
          for "fees". Although, we currently dont offer much information on the specifics of solicitations, we aim to in
          the very near future to better assist you in completing your budget with absolute accuracy in a timely manner.
          We apologize we cannot yet offer more support for this section, however we have included prompts to enter final
          fee values below so that they can automatically appear on your final budget. We have also included a text box
          section where you can store notes about how you arrived at your final fee values or any other information you
          would like to remind yourself of when it comes time to writing your budget justification.
        </Typo>
        <Typo className={styles.mb16}>
          If this grant isn’t budgeted for a SBIR/STTR program solicitation, no need to worry about this section, you can
          move to the next section.
        </Typo>
      </>
    </GrantFormWrapper>
  )
}

export default FeesInfo;
