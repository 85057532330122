import {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
// hooks
import useCostSharing from 'hooks/useCostSharing';
import useUI from 'hooks/useUI';
// components
import Spinner from 'components/spinner';
import Table from 'components/table';
import GrantFormWrapper from 'pages/grant/common/wrapper';
// helpers
import {numberToMoney} from 'helpers/numbers';
import {getTotalsTableData} from 'helpers/table';
// styles
import cx from 'classnames';
import styles from './costSharing.module.css'

enum ETableKeyType {
  Mandatory = 'mandatory',
  Voluntary = 'voluntary',
  Total = 'total'
}

const getCostSharingTableTitle = (tableKey: ETableKeyType) => {
  switch (tableKey) {
    case ETableKeyType.Voluntary: return 'Voluntary Committed and Uncommitted Cost Sharing';
    case ETableKeyType.Mandatory: return 'Mandatory Cost Sharing';
    case ETableKeyType.Total: return 'Voluntary and Mandatory Cost Sharing';
  }
}

export const CostSharingTotal = () => {
  const params: Record<string, string> = useParams();
  const {total, getTotals, isLoading} = useCostSharing();
  const {sidebarWidth} = useUI();

  const renderCell = (cell: string, idx: number, isGrayData: boolean) => {
    if (idx < 1) return cell;
    return <span className={cx({[styles.gray]: isGrayData})}>{numberToMoney(cell)}</span>;
  };

  const list = useMemo(() => {
    return Object.keys(total).map((key: string) => {
      return {
        title: getCostSharingTableTitle(key as ETableKeyType),
        ...getTotalsTableData(total[key], renderCell, ['Cost Sharing', 'Totals'])
      }
    })
  }, [total, renderCell]);

  useEffect(() => {
    getTotals(params.id);
  }, []);

  return (
    <GrantFormWrapper title={'Cost Sharing'} subtitle={'Totals'}>
      <div className={styles.tables}>
        {list.map(tableData => {
          return (
            <Table className={styles.table} {...tableData}/>
          )
        })}
        {isLoading ? (
          <Spinner
            className={styles.spinner}
            style={{width: `calc(100vw - ${sidebarWidth}px)`, left: sidebarWidth}}
            full
          />
        ) : null}
      </div>
    </GrantFormWrapper>
  );
}

export default CostSharingTotal;
