import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateEditPerson from 'pages/grant/one/personnel/createOrEdit';
import CompensationForPerson from 'pages/grant/one/personnel/compensation';
import Totals from 'pages/grant/one/personnel/total';
import PaidPersonnelRoute from './route';


const GrantIndex:FC = () => {
  return (
    <>
      <Switch>
        <Route path="/grants/:id/personnel/:personnelType/create" exact render={() => <PaidPersonnelRoute><CreateEditPerson/></PaidPersonnelRoute>} />
        <Route path="/grants/:id/personnel/:personnelType/edit" exact render={() => <PaidPersonnelRoute><CreateEditPerson/></PaidPersonnelRoute>} />
        <Route path="/grants/:id/personnel/:personnelType/:personId/edit" exact render={() => <PaidPersonnelRoute><CreateEditPerson/></PaidPersonnelRoute>} />
        <Route path="/grants/:id/personnel/:personnelType/:personId/compensation/:year" exact render={() => <PaidPersonnelRoute><CompensationForPerson/></PaidPersonnelRoute>} />
        <Route path="/grants/:id/personnel/:personnelType/compensation/:year" exact render={() => <PaidPersonnelRoute><CompensationForPerson/></PaidPersonnelRoute>} />
        <Route path="/grants/:id/personnel/:personnelType/totals" exact render={() => <PaidPersonnelRoute><Totals/></PaidPersonnelRoute>} />
      </Switch>
    </>
  );
}

export default GrantIndex;
