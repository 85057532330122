import { FC } from 'react';
import { Route } from 'react-router-dom';
import ConsultantInfo from './info';
import ConsultantYear from './year';
import ConsultantsTotals from './totals';

const ConsultantsIndex:FC = () => {
  return (
    <>
      <Route
        path="/grants/:id/otherDC/consultantServices/:serviceId/edit"
        exact
        component={ConsultantInfo}
      />
      <Route
        path="/grants/:id/otherDC/consultantServices/:serviceId/years/:year"
        exact
        component={ConsultantYear}
      />
      <Route
        path="/grants/:id/otherDC/consultantServices/totals"
        exact
        component={ConsultantsTotals}
      />
    </>
  );
}

export default ConsultantsIndex;
