import {FC, useCallback, useMemo} from 'react';
import cx from 'classnames';
import useUrl from 'hooks/useUrl';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Dropdown, {ListItem} from 'components/dropdown';
import Icon from 'components/icon';
import {Button} from 'components/form';
import Tip from 'components/tip';
import useAuth from 'hooks/useAuth';
import logo from 'images/logo.svg';
import useUI from 'hooks/useUI';
import Avatar from 'components/avatar';
import styles from './header.module.css';
import useGrants from 'hooks/useGrants';
import PlanButton from 'containers/planButton';
import {useMixPanel} from "../../hooks/useMixPanel";

type Props = {
  short?: boolean;
}

const Header: FC<Props> = ({short}) => {
  const {showGuideClicked, hideGuideClicked} = useMixPanel()
  const history = useHistory();
  const {user, paid, logout} = useAuth();
  const {create} = useGrants();
  const location = useLocation();
  const url = useUrl();
  const {
    mtdcGrantId,
    minimizedSidebar,
    hideInfoSidebar,
    showInfoSidebar,
    getInfoSectionLocation,
    infoSidebar,
    loader,
    sidebarWidth,
    minimizeSidebar,
    maximizeSidebar,
    finalGrantId,
    indirectCostGrantId,
    savePreviousPage
  } = useUI();

  const list: ListItem[] = useMemo(() => {
    return [
      {
        label: 'Dashboard',
        to: '/grants',
        icon: 'home'
      },
      {
        label: 'Account settings',
        to: '/settings/account/personal',
        icon: 'settings-01',
        onClick: () => savePreviousPage(location.pathname, url.grantId !== 'account'),
      },
      {
        label: 'F&A Rates',
        to: '/grants/rates',
        icon: 'percent-02',
        divider: true,
      },
      {
        label: 'Logout',
        icon: 'log-out-01',
        onClick: logout
      }
    ];
  }, [logout, savePreviousPage, location, url]);

  const style = useMemo(() => {
    if (short) return {left: sidebarWidth, width: `calc(100% - ${sidebarWidth}px)`};
  }, [short, sidebarWidth]);

  const onShowInfoSidebar = useCallback(() => {
    showGuideClicked()
    showInfoSidebar();
    minimizeSidebar();
    const newLocation = getInfoSectionLocation(location.pathname, url.category, url.subCategory);
    history.push(newLocation);
  }, [showGuideClicked, showInfoSidebar, minimizeSidebar, getInfoSectionLocation, location.pathname, url.category, url.subCategory, history]);

  const onHideInfoSidebar = useCallback(() => {
    hideGuideClicked()
    hideInfoSidebar();
    maximizeSidebar();
  }, [hideGuideClicked, maximizeSidebar, hideInfoSidebar]);

  const showBackBtn = useMemo(() => {
    return (mtdcGrantId && mtdcGrantId === url.grantId) ||
      (indirectCostGrantId && indirectCostGrantId === url.grantId) ||
      (finalGrantId && finalGrantId === url.grantId);
  }, [url, finalGrantId, mtdcGrantId, indirectCostGrantId]);

  const backBtnUrl = useMemo(() => {
    if (mtdcGrantId) return `/grants/${mtdcGrantId}/mtdc`;
    if (indirectCostGrantId) return `/grants/${indirectCostGrantId}/indirect`;
    if (finalGrantId) return `/grants/${url.grantId}/final`;
    return `/grants/${url.grantId}/edit`;
  }, [url, finalGrantId, mtdcGrantId, indirectCostGrantId]);

  const backBtnText = useMemo(() => {
    if (mtdcGrantId) return 'Back to MTDC';
    if (indirectCostGrantId) return 'Back to Indirect costs';
    if (finalGrantId) return 'Back to Final form';
    return 'Back to grant';
  }, [finalGrantId, mtdcGrantId, indirectCostGrantId]);

  const showPayBtn = useMemo(() => {
    return !paid;
  }, [paid]);

  const userName = useMemo(() => {
    if (user.firstName) return `${user.firstName} ${user.lastName}`;
    if (user.email) return user.email;
    return 'User';
  }, [user]);

  return (
    <div style={style}
         className={cx(styles.wrapper, {[styles.short]: short, [styles.minimizedSidebar]: minimizedSidebar})}>
      <div className={cx(styles.line, {[styles.animate]: loader.progress})}/>
      <header className={styles.header}>
        <div className={styles.content}>
          <Link className={styles.logoLink} to="/grants">
            <img src={logo} alt="logo" draggable={false}/>
          </Link>
          <Tip text={minimizedSidebar ? 'Maximize Grant Steps Nav' : 'Minimize Grant Steps Nav'}
               className={styles.btnTip}>
            <button onClick={minimizedSidebar ? onHideInfoSidebar : minimizeSidebar} type="button"
                    className={styles.sidebarToggler}>
              <Icon icon={minimizedSidebar ? 'chevron-right-double' : 'chevron-left-double'}/>
            </button>
          </Tip>
          {showBackBtn
            ?
            <Link to={backBtnUrl} className={styles.backToMTDC}>
              <Button size="xs" preIcon="arrow-left" type="link" name="back">{backBtnText}</Button>
            </Link>
            : null
          }
          {showPayBtn ? <PlanButton className={cx(styles.planBtn, {[styles.hide]: !short})}/> : null}
          <div className={styles.right}>
            <Button className={styles.info} onClick={create} size="md" preIcon="plus" name="create-grant">Create a New
              Budget</Button>
            <Button type="link" onClick={infoSidebar ? onHideInfoSidebar : onShowInfoSidebar} className={styles.info}
                    name="info">{infoSidebar ? 'Hide a guide' : 'Show a guide'}</Button>
            <Dropdown list={list} name="header-user" label={userName}>
              <div className={styles.dropdown}>
                <Avatar alt={userName} src={user.profileImage.url} className={styles.avatar}/>
                {userName}
              </div>
            </Dropdown>
          </div>
        </div>
      </header>
    </div>
  );
}

export const PublicHeader: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.line, {[styles.animate]: false})}/>
      <header className={styles.header}>
        <div className={styles.content}>
          <div className={styles.logoLink}>
            <img src={logo} alt="logo" draggable={false}/>
          </div>
          <div className={styles.right}>
            <a href="/sign-up" target="_blank">
              <Button name="signup">Try Budget Mentor for free</Button>
            </a>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header;
