import {FC} from 'react';
import {Route} from 'react-router-dom';
// components
import FeesInfo from './info';
import FeeItemPage from './fee';
import FeeItemYearPage from './fee/year';
import FeesTotals from './totals';

const FeesIndex: FC = () => {
  return <>
    <Route
      path="/grants/:id/fees/general-info"
      exact
      component={FeesInfo}
    />
    <Route
      path="/grants/:id/fees/:feeId/edit"
      exact
      component={FeeItemPage}
    />
    <Route
      path="/grants/:id/fees/:feeId/year/:yearId"
      exact
      component={FeeItemYearPage}
    />
    <Route
      path="/grants/:id/fees/totals"
      exact
      component={FeesTotals}
    />
  </>
}

export default FeesIndex;
