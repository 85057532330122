import { FC } from 'react';
import CreateEditTravel from './createOrEdit';
import TravelStatus from './status';
import { Switch, Route } from 'react-router-dom';
import Transportation from './transportation';
import TransportationTotalsPage from './transportation/total';
import CostPage from './cost/createOrEdit';
import CostLodging from './cost/lodging';
import CostBreakdownComponent from './cost/breakdown';
import TravelCostAttendeePage from './cost/attendee';
import CostIEBreakdownComponent from './cost/ie';
import CostTotals from './cost/totals';
import OtherCostYear from './cost/year';
import TotalsCostPage from './totals/cost';
import TotalsPage from './totals';
import TotalsTravelPage from './totals/all';


const TravelIndex:FC = () => {
  return (
    <>
      <Switch>
        <Route path="/grants/:id/travel/:type/create" exact component={CreateEditTravel} />
        <Route path="/grants/:id/travel/totals" exact component={TotalsTravelPage} />
        <Route path="/grants/:id/travel/:type/:travelId/totals" exact component={TotalsPage} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/totals" exact component={TotalsCostPage} />
        <Route path="/grants/:id/travel/:type/:travelId/edit" exact component={CreateEditTravel} />
        <Route path="/grants/:id/travel/:type/:travelId/status" exact component={TravelStatus} />
        <Route path="/grants/:id/travel/:type/:travelId/transportation/create" exact component={Transportation} />
        <Route path="/grants/:id/travel/:type/:travelId/transportation/:methodId/edit" exact component={Transportation} />
        <Route path="/grants/:id/travel/:type/:travelId/transportation/total" exact component={TransportationTotalsPage} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/create" exact component={CostPage} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/edit" exact component={CostPage} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/lodging" exact component={CostLodging} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/breakdown" exact component={CostBreakdownComponent} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/attendee" exact component={TravelCostAttendeePage} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/ie" exact component={CostIEBreakdownComponent} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/totals" exact component={CostTotals} />
        <Route path="/grants/:id/travel/:type/:travelId/cost/:costId/year/:year" exact component={OtherCostYear} />
      </Switch>
    </>
  );
}

export default TravelIndex;
