// components
import GrantFormWrapper from 'pages/grant/common/wrapper';
import Typo from 'components/typo';

const PublicationsInfo = () => {
  return (
    <GrantFormWrapper title="Other Direct Costs" subtitle="Publications">
      <Typo>
        The proposal budget may request funds for the costs of documenting, preparing, publishing or otherwise making
        available to others the findings and products of the work to be conducted under the grant. This generally
        includes the following types of activities: reports, reprints, page charges or other journal costs (except costs
        for prior or early publication); necessary illustrations; cleanup, documentation, storage and indexing of data
        and databases; development, documentation and debugging of software; and storage, preservation, documentation,
        indexing, etc., of physical specimens, collections, or fabricated items. Line G.2. of the proposal budget also
        may be used to request funding for data deposit and data curation costs. A data deposit cost is a one-time
        charge paid at the time a data set is deposited into a data repository. Data curation costs are expenses
        associated with preparing data into a form that others can use.
      </Typo>
    </GrantFormWrapper>
  )
}

export default PublicationsInfo;
