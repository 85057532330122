import { ContractYear, Contract } from './types';

export const contractYearAdapter = (data: ContractYear, idx: number) => {
  return {
    serialNumber: data.serialNumber ?? idx,
    year: data.year ?? [],
    compensationAfterInflation: data.compensationAfterInflation ?? '',
    compensationBeforeInflation: data.compensationBeforeInflation ?? '',
    value: data.value ?? '',
    justification: data.justification ?? '',
  }
}


export const contractsAdapter = (data: Contract[] = []) => {
  return data.map(contractAdapter);
}
export const contractAdapter = (data: Contract) => {
  return {
    name: data.name ?? '',
    isInflationAppliedEachYear: data.isInflationAppliedEachYear,
    id: data.id ?? '',
    years: data.years ? data.years.map(contractYearAdapter) : []
  }
}
