import { FC } from 'react';
import Anchor from 'pages/grant/paper/card/anchor';
import useUrl from 'hooks/useUrl';
import Tip from 'components/tip';
import {FullGrant, SidebarTotals} from 'store/grants/types';
import styles from './indirect.module.css';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}
const Indirect:FC<Props> = ({ side }) => {
  const url = useUrl();

  const isActive = url.category === 'indirect' && url.grantId === side.id;
  const disabled = side.years.length < 1;

  return (
    <Tip when={disabled} top text="To add Indirect Costs you must add grant general info">
      <Anchor className={styles.link} disabled={disabled} thin to={`/grants/${side.id}/indirect`} active={isActive}>
        General info
      </Anchor>
    </Tip>
  );
}

export default Indirect;
