import { FC } from 'react';
import cx from 'classnames';
import Typo from 'components/typo';
import Icon from 'components/icon';
import styles from './message.module.css';
import useUI from 'hooks/useUI';

type Props = {
  className?: string;
}

const AuthDisplayMessage:FC<Props> = ({ className }) => {
  const { showDisplayMessage, onHideDisplayMessage } = useUI();

  return showDisplayMessage ? (
    <div className={cx(styles.wrapper, className)}>
      <Typo bold size={16} className={styles.title}>The website isn't user-friendly on phones.</Typo>
      <Typo className={styles.text}>
        <span>Please visit </span><a href="https://www.budget-mentor.com/">www.budget-mentor.com</a><span> on your laptop to start drafting your budget.</span>
      </Typo>
      <Icon icon="x-close" onClick={onHideDisplayMessage} className={styles.close} />
    </div>
  ) : null
}

export default AuthDisplayMessage;
