import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import GrantFormWrapper from 'pages/grant/common/wrapper';
import TravelPersonnel from 'pages/grant/one/travel/common/personnel';
import { Input, Button, Form, Textarea, Select } from 'components/form';
import Field from 'components/field';
import Typo from 'components/typo';
import styles from './year.module.css';
import useGrants from 'hooks/useGrants';
import {useParams} from 'react-router-dom';
import useEnums from 'hooks/useEnums';
import useTravel from 'hooks/useTravel';
import {getCostFromTravel, getTravelFromGrant} from 'helpers/getFromGrant';
import {FullGrant, Travel, TravelCostYear} from 'store/grants/types';

type Props = {
  travel: Travel;
  grant: FullGrant;
}

const OtherCostYearForm:FC<Props> = ({ travel, grant }) => {
  const params:Record<string, string> = useParams();
  const { previewTravelCost, updateTravelCost } = useTravel();
  const { travelExpensesTypes } = useEnums();
  const { cost } = getCostFromTravel(travel, params.costId);
  const defaultPersonnel = {
    seniorPersonnel: travel.status.seniorPersonnel ? travel.status.seniorPersonnel.map((item: Record<string,string>) => item.id) : [],
    academicResearchAssociate: travel.status.academicResearchAssociate ? travel.status.academicResearchAssociate.map((item: Record<string,string>) => item.id) : [],
    postDocs: travel.status.postDocs ?? 0,
    graduateStudents: travel.status.graduateStudents ?? 0,
    undergraduateStudents: travel.status.undergraduateStudents ?? 0,
    civilService: travel.status.civilService ?? 0,
    union: travel.status.union ?? 0,
    tempAndCasual: travel.status.tempAndCasual ?? 0,
    otherPersonnel: travel.status.otherPersonnel ?? 0,
  };

  const [ data, onChangeData ] = useState(() => {
    const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
    return {
      totalAmountForTrip: year?.totalAmountForTrip ?? '',
      justification: year?.justification ?? '',
      compensationBeforeInflation: year?.compensationBeforeInflation ?? '',
      compensationBeforeInflationPreview: year?.compensationBeforeInflationPreview ?? '',
      compensationAfterInflationPreview: year?.compensationAfterInflationPreview ?? '',
      totalAmountOfPersons: year?.totalAmountOfPersons ?? '',
      totalAmount: year?.totalAmount ?? '',
      compensationAfterInflation: year?.compensationAfterInflation ?? '',
      typeOfExpenses: year?.typeOfExpenses ?? cost?.typeOfExpenses,
    }
  });


  const [ personnel, onChangePersonnel ] = useState(() => {
    const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
    if (year) {
      return {
        seniorPersonnel: year.seniorPersonnel ? year.seniorPersonnel.map((item: any) => item.id) : [],
        academicResearchAssociate: year.academicResearchAssociate ? year.academicResearchAssociate.map((item: any) => item.id) : [],
        postDocs: year.postDocs || 0,
        graduateStudents: year.graduateStudents || 0,
        undergraduateStudents: year.undergraduateStudents || 0,
        civilService: year.civilService || 0,
        union: year.union || 0,
        tempAndCasual: year.tempAndCasual || 0,
        otherPersonnel: year.otherPersonnel || 0,
      }
    }
    return {
      seniorPersonnel: [],
      academicResearchAssociate: [],
      postDocs: 0,
      graduateStudents: 0,
      undergraduateStudents: 0,
      civilService: 0,
      union: 0,
      tempAndCasual: 0,
      otherPersonnel: 0,
    };
  });

  const onBlur = useCallback((event: any, previewData?: any) => {
    if (cost) {
      const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
      const submitData = previewData || {
        years: [
          {
            ...(year || {}),
            ...data,
            ...personnel,
            numberOfOccurrence: undefined,
            serialNumber: Number(params.year),
            seniorPersonnel: personnel.seniorPersonnel.map((id: string) => ({id})),
            academicResearchAssociate: personnel.academicResearchAssociate.map((id: string) => ({id})),
          }
        ]
      };
      previewTravelCost(grant.id, travel.id, cost.id, submitData, (response: any) => {
        const year:TravelCostYear | undefined = response?.years ? response.years[0] : undefined;
        onChangeData({
          totalAmountForTrip: response?.totalAmountForTrip ?? data.totalAmountForTrip,
          compensationBeforeInflation: year?.compensationBeforeInflation ?? data.compensationBeforeInflation,
          justification: year?.justification ?? data.justification,
          totalAmountOfPersons: year?.totalAmountOfPersons ?? data.totalAmountOfPersons,
          totalAmount: year?.totalAmount ?? data.totalAmount,
          compensationBeforeInflationPreview: year?.compensationBeforeInflationPreview ?? data.compensationBeforeInflationPreview,
          compensationAfterInflationPreview: year?.compensationAfterInflationPreview ?? data.compensationAfterInflationPreview,
          compensationAfterInflation: year?.compensationAfterInflation ?? data.compensationAfterInflation,
          typeOfExpenses: year?.typeOfExpenses ?? data.typeOfExpenses,
        });
      });
    }
  }, [grant, travel, previewTravelCost, cost, params, data, personnel]);

  const handleChangePersonnel = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newPersonnel = {
      ...personnel,
      ...field
    };
    onChangePersonnel(newPersonnel);
    if (preview && cost) {
      const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
      const submitYear = {
        ...data,
        ...newPersonnel,
        numberOfOccurrence: undefined,
        serialNumber: year?.serialNumber ?? Number(params.year),
        seniorPersonnel: newPersonnel.seniorPersonnel.map((id: string) => ({id})),
        academicResearchAssociate: newPersonnel.academicResearchAssociate.map((id: string) => ({id})),
      }
      onBlur(undefined, {
        years: [submitYear]
      });
    }
  }, [personnel, onBlur, cost, data, params]);

  const handleSubmit = useCallback(() => {
    if (cost) {
      const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
      const submitYear = {
        ...data,
        ...personnel,
        numberOfOccurrence: undefined,
        serialNumber: year?.serialNumber ?? Number(params.year),
        seniorPersonnel: personnel.seniorPersonnel.map((id: string) => ({id})),
        academicResearchAssociate: personnel.academicResearchAssociate.map((id: string) => ({id})),
      }
      const years = cost.years && cost.years.length > 0
        ? cost.years.map((year: TravelCostYear) => {
          if (year.serialNumber === submitYear.serialNumber) {
            return {
              ...year,
              ...submitYear
            }
          }
          return {
            ...year,
            numberOfOccurrence: undefined,
          };
        })
        : [submitYear];
      updateTravelCost(grant.id, travel.id, cost.id, { years })
    }
  }, [data, grant, travel, updateTravelCost, params, cost, personnel]);

  const onCopy = useCallback(() => {
    const newData = {
      ...personnel,
      ...defaultPersonnel
    };
    onChangePersonnel(newData);
    if (cost) {
      const year:TravelCostYear | undefined = cost?.years ? cost.years[Number(params.year) - 1] as TravelCostYear : undefined;
      const submitYear = {
        ...data,
        ...newData,
        serialNumber: year?.serialNumber ?? Number(params.year),
        seniorPersonnel: newData.seniorPersonnel.map((id: string) => ({id})),
        academicResearchAssociate: newData.academicResearchAssociate.map((id: string) => ({id})),
      }
      onBlur(undefined, {years: [submitYear]});
    }
  }, [personnel, cost, data, params, defaultPersonnel, onBlur]);

  const isLast = useMemo(() => {
    const yearsLength = (cost?.years || []).filter((item: TravelCostYear) => item.isEnabled).length;
    return yearsLength === Number(params.year);
  }, [cost, params]);

  return (
    <Form prompt onSubmit={handleSubmit}>
      <Field full>
        <Input
          name="totalAmount"
          value={data.totalAmount}
          label="Total amount for the expense in a year"
          placeholder="Total amount for the expense in a year"
          money
          disabled
          readOnly
        />
      </Field>
      <Typo className={styles.hint}>Please check the PI, Co-PI, or Academic Research Associates you'd like to apply these rates to.</Typo>
      <Typo bold type="div" className={styles.title}>
        <>
          <span>Trip attendees</span>
          <Button htmlType="button" onClick={onCopy} name="duplicate-from-status" type="mint">Duplicate data from Travel status</Button>
        </>
      </Typo>
      <TravelPersonnel onBlur={onBlur} hint="Please list the maximum number of other personnel by type that you would like to put on travel status" data={personnel} onChange={handleChangePersonnel} grant={grant}/>
      <Field last>
        <Input
          name="totalAmountOfPersons"
          value={data.totalAmountOfPersons}
          label="Total number of people on this part of trip"
          placeholder="Total number of people"
          disabled
          readOnly
        />
        <Input
          name="compensationBeforeInflation"
          value={data.compensationBeforeInflationPreview}
          label="Amount for this method before inflation"
          placeholder="Amount for this method before inflation"
          money
          disabled
          readOnly
        />
      </Field>
      <Field last={!isLast} withHelp>
        <Input
          name="compensationAfterInflation"
          value={data.compensationAfterInflationPreview}
          label="Amount of this method after inflation"
          placeholder="Amount of this method after inflation"
          money
          disabled
          readOnly
        />
        <Select name="typeOfExpenses" value={data.typeOfExpenses}
                onChange={(typeOfExpenses: any) => onChangeData({...data, typeOfExpenses})}
                options={travelExpensesTypes} label="Type of expenses" required placeholder="Select type of expenses" help="some help" />
      </Field>
      { isLast
        ?
        <>
          <Field>
            <Input
              name="totalAmountForTrip"
              value={data.totalAmountForTrip}
              label="Total for all years by person after inflation"
              placeholder="Amount after inflation"
              money
              disabled
              readOnly
            />
          </Field>
          <Field full last>
            <Textarea name="justification" value={data.justification}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeData({...data, justification: event.target.value})}
                      label="Justification for this Cost" placeholder="Enter a description..."
            />
          </Field>
        </>
        : null
      }
      <div className={styles.footer}>
        <Button htmlType="submit" name="submit-year">Save</Button>
      </div>
    </Form>
  );
}

const OtherCostYear:FC = () => {
  const { grant } = useGrants();
  const params:Record<string, string> = useParams();
  const { trip } = getTravelFromGrant(grant, params.travelId);
  const key = `${grant.id}-${params.travelId}-${params.costId ?? 'cost'}-year-${params.year}`;

  const subtitle = useMemo(() => {
    return `Travel Cost. Other. Year ${params.year}`
  }, [params]);

  return (
    <GrantFormWrapper key={key} title="Travel" subtitle={subtitle}>
      {trip ? <OtherCostYearForm grant={grant} travel={trip} /> : <></>}
    </GrantFormWrapper>
  );
}

export default OtherCostYear;
