import { omit, omitBy } from 'lodash';
import {
  tableDataYearsAdapter,
  tableDataItemAdapter,
  tableDataSectionAdapter,
  tableDataSectionsAdapter,
  TableSection,
  TableSections, SectionData, TableDataItem, TableDataYear
} from './mtdcAdapter';


export type TableDataRequest = {
  sections: {
    equipment: TableSection;
    participantSupport: TableSection;
    otherDirectCosts: TableSection;
    fees: TableSection;
    costSharingExpenses: TableSection;
    personnel: {
      sections: {
        fringeBenefits: TableSection;
        salaries: TableSection;
      };
    };
    travel: {
      types: {
        domestic: SectionData;
        foreign: SectionData;
      };
      totalItems: TableDataItem[];
    };
    indirectCost: {
      totalItems: TableDataItem[];
    };
    directCosts: {
      totalItems: TableDataItem[];
    };
    totalDirectAndIndirectCosts: {
      totalItems: TableDataItem[];
    };
  };
  totalsItem: TableDataItem;
  years: string[][];
}

export type FinalTableData = {
  sections: {
    equipment: TableSection;
    participantSupport: TableSection;
    otherDirectCosts: TableSection;
    fees: TableSection;
    costSharingExpenses: TableSection;
    other: TableSection;
    personnel: {
      name: string;
      sections: TableSections;
    };
    otherPersonnel: {
      name: string;
      sections: TableSections;
    };
    fringeBenefits: {
      name: string;
      sections: TableSections;
    }
    travel: {
      name: string;
      sections: {
        domestic: SectionData;
        foreign: SectionData;
      };
      totalItems: TableDataItem[];
    };
    indirectCost: {
      name: string;
      section: string;
      totalItems: TableDataItem[];
    };
    directCosts: {
      name: string;
      totalItems: TableDataItem[];
    };
    totalDirectAndIndirectCosts: {
      name: string;
      totalItems: TableDataItem[];
    };
  };
  totalsItem: TableDataItem;
  years: string[][];
}

const tableDataAdapter = (data?: TableDataRequest):FinalTableData => {
  const otherPersonnel = omitBy({
    academicResearchAssociate: data?.sections?.personnel?.sections?.salaries?.sections?.academicResearchAssociate,
    postDocPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.postDocPersonnel,
    graduateStudentsStipend: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsStipend,
    graduateStudentsAcademic: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsAcademic,
    graduateStudentsSummer: data?.sections?.personnel?.sections?.salaries?.sections?.graduateStudentsSummer,
    undergraduateStudentsAcademic: data?.sections?.personnel?.sections?.salaries?.sections?.undergraduateStudentsAcademic,
    undergraduateStudentsSummer: data?.sections?.personnel?.sections?.salaries?.sections?.undergraduateStudentsSummer,
    civilService: data?.sections?.personnel?.sections?.salaries?.sections?.civilService,
    tempAndCasual: data?.sections?.personnel?.sections?.salaries?.sections?.tempAndCasual,
    unionPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.unionPersonnel,
    otherPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.otherPersonnel,
  }, item => item === undefined);

  const fringeBenefits = omitBy({
    seniorPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.seniorPersonnel,
    academicResearchAssociate: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.academicResearchAssociate,
    postDocPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.postDocPersonnel,
    graduateStudentsStipend: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.graduateStudentsStipend,
    graduateStudentsTuition: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.graduateStudentsTuition,
    civilService: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.civilService,
    tempAndCasual: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.tempAndCasual,
    unionPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.unionPersonnel,
    otherPersonnel: data?.sections?.personnel?.sections?.fringeBenefits?.sections?.otherPersonnel,
  }, item => item === undefined)

  return {
    totalsItem: tableDataItemAdapter(data?.totalsItem),
    years: data?.years ?? [],
    sections: {//@ts-ignore
      equipment: tableDataSectionAdapter(data?.sections?.equipment, 'Equipment', 'equipment'),//@ts-ignore
      participantSupport: tableDataSectionAdapter(data?.sections?.participantSupport, 'Participant Support', 'participantSupport'),//@ts-ignore
      otherDirectCosts: {
        name: 'Other Direct Costs',
        sections: {
          //@ts-ignore
          ...tableDataSectionsAdapter(omit(data?.sections?.otherDirectCosts?.sections || {}, 'other'), 'otherDirectCosts'),
          //@ts-ignore
          ...tableDataSectionsAdapter(data?.sections?.otherDirectCosts?.sections?.other?.sections || {}, 'otherDirectCosts.other')
        },
        totalsItem: tableDataItemAdapter(data?.sections?.otherDirectCosts?.totalsItem),
      },
      personnel: {
        name: 'Personnel',//@ts-ignore
        sections: tableDataSectionsAdapter({seniorPersonnel: data?.sections?.personnel?.sections?.salaries?.sections?.seniorPersonnel}, 'personnel.salaries'),//@ts-ignore
      },
      otherPersonnel: {
        name: 'Other Personnel',//@ts-ignore
        sections: tableDataSectionsAdapter(otherPersonnel, 'personnel.salaries'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.personnel?.sections?.salaries?.totalsItem),
      },
      fees: {
        name: 'Fees',//@ts-ignore
        sections: tableDataSectionsAdapter({ Fees: data?.sections?.fees }, 'fees'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.fees?.totalsItem),
      },
      costSharingExpenses: {
        name: 'Cost sharing',//@ts-ignore
        sections: tableDataSectionsAdapter({ Expenses: data?.sections?.costSharingExpenses }, 'costSharingExpenses'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.costSharingExpenses?.totalsItem),
      },
      fringeBenefits: {
        name: 'Fringe benefits',//@ts-ignore
        sections: tableDataSectionsAdapter(fringeBenefits, 'personnel.fringeBenefits'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.personnel?.sections?.fringeBenefits?.totalsItem),
      },
      travel: {
        name: 'Travel',//@ts-ignore
        sections: tableDataSectionsAdapter(data?.sections?.travel?.types, 'travel'),//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.travel?.totalsItem),
      },
      directCosts: {
        name: 'Direct costs',//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.directCosts?.totalsItem),
      },
      indirectCost: {
        name: 'Indirect costs',
        section: 'indirect',//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.indirectCost?.totalsItem),
      },
      totalDirectAndIndirectCosts: {
        name: 'Direct and Indirect costs',//@ts-ignore
        totalsItem: tableDataItemAdapter(data?.sections?.totalDirectAndIndirectCosts?.totalsItem),
      },
    }
  }
}

export default tableDataAdapter;
