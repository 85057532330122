import {FC, useState, useCallback, createElement, useMemo} from 'react';
import Field from 'components/field';
import {capitalize, omit} from 'lodash';
import { Select, Form } from 'components/form';
import forms from 'pages/grant/one/travel/transportation/forms';
import useEnums from 'hooks/useEnums';
import useTravel from 'hooks/useTravel';
import {
  GrantEventParticipantTransportationMethod,
  GrantEventParticipantYear
} from 'store/grants/types';
import * as T from 'hooks/requestTypes';
import {Errors} from 'helpers/errors';
import {FieldErrors} from "react-hook-form";

type Method = 'Car' | 'Taxi' | 'Plane' | 'Rideshare' | 'Subway' | 'Other' | 'Boat' | 'Charter' | 'Train';

type Props = {
  item: GrantEventParticipantTransportationMethod;
  years: GrantEventParticipantYear[];
  grantId: string;
  errors: Errors;
  loading: boolean;
  clearErrors: () => void;
  onSubmit: (data: GrantEventParticipantTransportationMethod) => void;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void; trackFormStarted: () => void;
}

const GrantEventParticipantTravelForm:FC<Props> = ({ trackError,trackExit,item, clearErrors, loading, errors, grantId, years, onSubmit }) => {
  const { transportationMethod } = useEnums();
  const { previewMethod } = useTravel();
  const [method, onChangeMethod] = useState<Method>(item.method as Method);
  const defaultDetailsData = useMemo(() => ({
    airCarrier: item.details.airCarrier ?? '',
    price: item.details.price ?? '',
    gasPrice: item.details.gasPrice ?? '',
    isAirCarrierVerified: item.details?.isAirCarrierVerified,
    compensationBeforeInflation: item.details?.compensationBeforeInflation ?? '',
    totalAmountForTickets: item.details?.totalAmountForTickets ?? '',
    miles: item.details.miles ?? '',
    milesPerGallon: item.details.milesPerGallon ?? '',
    numberOfPeopleWithTicket: item.details.numberOfPeopleWithTicket ?? '',
    numberOfTrips: item.details.numberOfTrips ?? '',
    ticketPrice: item.details.ticketPrice ?? '',
    tickets: item.details.tickets ?? '',
    companyName: item.details.companyName ?? '',
    numberOfRides: item.details.numberOfRides ?? '',
    cabCost: item.details.cabCost ?? '',
    city: item.details.city ?? '',
    priceExplanation: item.details.priceExplanation ?? '',
    isRoundTrip: Boolean(item.details.isRoundTrip),
    gallons: item.details.gallons ?? '',
    amountOfCompensationBeforeInflation: item.details.amountOfCompensationBeforeInflation ?? '',
    eligibility: item.details.eligibility ?? [],
    travellers: item.details.travellers ?? '',
    totalCost: item.details.totalCost ?? '',
    totalPrice: item.details.totalPrice ?? '',
    kind: item.details.kind ?? null,
    numberOfUnits: item.details.numberOfUnits ?? '',
    unit: item.details.unit ?? '',
    estimatedNumberOfCharters: item.details.estimatedNumberOfCharters ?? '',
    pricePerUnit: item.details.pricePerUnit ?? '',
    charterCostByUnit: item.details.charterCostByUnit ?? '',
    charterCost: item.details.charterCost ?? '',
  }), [item]);


  const defaultMethodData = useMemo(() => {
    const defaultYears = years.map((year: GrantEventParticipantYear, idx: number) => ({
      year: year.year,
      isEnabled: false,
      serialNumber: year.serialNumber,
      numberOfOccurrence: '',
      compensationAfterInflation: '',
      compensationBeforeInflation: '',
      amountWithInflation: '',
    }));

    return {
      nameExpense: item.nameExpense ?? '',
      typeOfExpense: item.typeOfExpense ?? '',
      startLocation: item.startLocation ?? '',
      totalAmountForTrip: item.totalAmountForTrip ?? '',
      endLocation: item.endLocation ?? '',
      compensationBeforeInflation: item.compensationBeforeInflation ?? '',
      years: item.years.length > 0 ? item.years : defaultYears
    }
  }, [item, years]);

  const [ data, onChangeData ] = useState(defaultMethodData);
  const [ details, onChangeDetails ] = useState(defaultDetailsData);


  const handleSubmit = useCallback(() => {
    //@ts-ignore
    onSubmit({
      ...data,
      method,
      details: details
    });
  }, [data, method, details, onSubmit]);

  const onBlur = useCallback((event?: any, newData?: any, newDetails?: T.TransportationMethodPlainDetails) => {
    const correctData = newData ? newData : data;
    const submitData = {
      ...correctData,
      method: method?.toLowerCase(),
      details: newDetails || details,
      years: correctData.years?.map((item: T.TransportationMethodYear) => ({
        ...item,
        isParticipantSupport: true,
        numberOfParticipants: item.numberOfParticipants ?? 0
      })),
    };//@ts-ignore
    previewMethod(grantId, 'something', submitData , (response: T.TransportationMethodData) => {
      onChangeData({
        ...correctData,
        compensationBeforeInflation: response.compensationBeforeInflation,
        years: response.years,
        totalAmountForTrip: response.totalAmountForTrip
      });
      onChangeDetails({
        ...(newDetails || details),
        compensationBeforeInflation: response.details.compensationBeforeInflation,
        gallons: response.details.gallons,
        amountOfCompensationBeforeInflation: response.details.amountOfCompensationBeforeInflation,
        charterCost: response.details.charterCost,
        totalPrice: response.details.totalPrice,
        totalAmountForTickets: response.details.totalAmountForTickets,
      })
    });
  }, [details, previewMethod, grantId, data, method]);

  const handleChangeData = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newData = {
      ...data,
      ...field
    };
    onChangeData(newData);
    if (preview) {
      onBlur(undefined,{
        ...newData,
        method: method?.toLowerCase(),//@ts-ignore
        years: newData?.years?.map((item: T.TransportationMethodYear, index: number) => ({
          ...item,
          isParticipantSupport: true,
          numberOfParticipants: item.numberOfParticipants ?? 0,
          serialNumber: index + 1,
        })),
        details
      });
    }
  }, [data, details, onBlur, method]);

  const handleChangeDetails = useCallback((field: Record<string, any>, preview?: boolean) => {
    const newDetails = {
      ...details,
      ...field
    };
    onChangeDetails(newDetails);
    if (preview) {
      onBlur(undefined,undefined, newDetails);
    }
  }, [details, onBlur]);

  const form = forms[capitalize(method) as Method];

  const props = {
    data,
    key: item.id,
    onChange: handleChangeData,
    details,
    onBlur,
    onChangeDetails: handleChangeDetails,
    onSubmit: handleSubmit,
    loading,
    isUpdate: true,
    participant: true,
    errors,
  };

  const handleChangeMethod = useCallback((method: any) => {
    onChangeMethod(method);
    onChangeData(defaultMethodData);
    onChangeDetails(defaultDetailsData);
    clearErrors();
  }, [defaultMethodData, clearErrors, defaultDetailsData]);

  return (
    <div>
      <Form trackExit={trackExit} trackError={trackError}>
        <Field>
          <Select options={transportationMethod} name="method"
                  value={method} onChange={handleChangeMethod}
                  label="Choose a transportation method template"
                  disabled={Boolean(item.method)}
                  placeholder="Choose a transportation method"
          />
        </Field>
      </Form>
      {//@ts-ignore
        method && form as FC ? createElement(form, props) : null}
    </div>
  );
}

export default GrantEventParticipantTravelForm;
