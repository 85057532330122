import {FC, useEffect} from 'react';
import cx from 'classnames';
import Tabs, { Tab } from 'components/tabs';
import Typo from 'components/typo';
import { Personal, Organization,  Password, Billing } from './tabs';
import styles from './account.module.css';
import {useLocation} from 'react-router';
import {useMixPanel} from "../../../hooks/useMixPanel";

const Account:FC = () => {
  const {pageViewed} = useMixPanel();
  const location = useLocation();

  const tabs: Tab[] = [
    {
      label: 'Personal info',
      id: 'personal',
      hasScroll: Boolean(location.hash),
      element: <Personal/>
    },
    {
      label: 'Organization info',
      id: 'organization',
      element: <Organization/>
    },
    {
      label: 'Password',
      id: 'password',
      element: <Password/>
    },
    {
      label: 'Subscription Plan',
      id: 'billing',
      element: <Billing/>
    },
  ];

  useEffect(() => {
    //track mix panel Page Viewed event
    pageViewed("Account")
  },[])

  return (
    <div className={styles.container}>
      <div className={cx('container', styles.titleWrapper)}>
        <Typo type="h3" semi className={styles.title}>Account setting</Typo>
        <Typo type="p" size={16} className={styles.subtitle}>
          <>
            Changing the data in this section <strong>will not</strong> affect previously created grants in any of the steps.
            This data is only used when completing new grants.
          </>
        </Typo>
      </div>
      <Tabs list={tabs} asRouter path="/settings/account" container name="section" />
    </div>
  )
}

export default Account;
