import React, { FC, useCallback, useMemo, useState } from 'react';
import Tabs, { Tab } from 'components/tabs';
import useUI from 'hooks/useUI';
import Sections from './sections';
import styles from './infoSidebar.module.css';
import info, { InfoField } from './info';
import useUrl from 'hooks/useUrl';
import { personalTypesList } from 'const';
import useQuery from 'helpers/query';

const InfoSidebar:FC = () => {
  const query = useQuery();
  const { category, subCategory, grantId } = useUrl();

  const getCurrent = useCallback(() => {
    if(['rates'].includes(grantId as string)) return 'MTDC-and-FA-rate'
    if (!category) return 'dashboard';
    if (['edit', 'create'].includes(category as string)) return 'general';
    if (['equipment'].includes(category as string)) return 'equipment';
    if (query.method === 'plane') return 'plane';
    if (['travel'].includes(category as string)) return 'travel';
    if (['events'].includes(category as string)) return 'participantSupport';
    if (['fees'].includes(category as string)) return 'fees';
    if (['costSharing'].includes(category as string)) return 'costSharing';
    if (['materials-and-supplies'].includes(subCategory as string)) return 'materialsAndSupplies';
    if (['publications'].includes(subCategory as string)) return 'publications';
    if (['consultantServices'].includes(subCategory as string)) return 'consultantServices';
    if (['computer-services'].includes(subCategory as string)) return 'computerServices';
    if (['subawards'].includes(subCategory as string)) return 'subawards';
    if (['contracts', 'payments', 'otherExpenses'].includes(subCategory as string)) return 'contractsPaymentsOther';
    if (['rates'].includes(subCategory as string)) return 'faRates';
    if (['final'].includes(category as string)) return 'final';
    if (['indirect'].includes(category as string)) return 'indirect';
    if (personalTypesList.includes(subCategory as string)) return subCategory;
    if(['facilities', 'mtdc'].includes(category as string)) return 'MTDC-and-FA-rate'
    return 'general';
  }, [subCategory, query, category]);

  const [ current, onChangeCurrent ] = useState<string | undefined>(getCurrent());

  const handleChangeStep = useCallback((section: Record<string, InfoField>,index: number) => {
    const keys = Object.keys(section);
    const currentIndex = keys.findIndex((item: string) => item === current);
    let nextIndex = currentIndex + index;
    if (nextIndex > keys.length - 1) nextIndex = 0;
    if (nextIndex < 0) nextIndex = keys.length - 1;
    const nextKey = keys[nextIndex];
    onChangeCurrent(nextKey);
  }, [current]);

  const list:Tab[] = useMemo(() => [
    {
      id: 'bm',
      label: 'Our explanation',
      element: <Sections onChange={(id: number) => handleChangeStep(info.bm, id)} info={info.bm[current as string]}/>
    },
    {
      id: 'nsf',
      label: 'NSF guidelines',
      element: <Sections onChange={(id: number) => handleChangeStep(info.nsf, id)} nsf info={info.nsf[current as string]}/>
    },
  ], [current, handleChangeStep]);

  return (
    <div className={styles.wrapper}>
      <Tabs name="info-section" onEnter={() => onChangeCurrent(getCurrent())} tabsClassName={styles.listWrapper} contentClassName={styles.tabContentWrapper} className={styles.tabs} list={list}/>
    </div>
  )
}

const InfoSidebarContainer:FC = () => {
  const { infoSidebar } = useUI();
  return infoSidebar ? <InfoSidebar /> : null;
}

export default InfoSidebarContainer;
