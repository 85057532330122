import {ChangeEvent, FC, useState, useCallback, useMemo, useEffect} from 'react';
import {Input, Form, Button} from 'components/form';
import Wrapper from 'pages/auth/components/wrapper';
import useAuth from 'hooks/useAuth';
import styles from './forgot.module.css';
import useQuery from 'helpers/query';
import {ParsedQuery} from 'query-string';
import {useMixPanel} from "../../../hooks/useMixPanel";
import {useIntercom} from "react-use-intercom";

const Forgot: FC = () => {
  const {pageViewed} = useMixPanel();
  const {forgot, errors, loading} = useAuth();
  const query: ParsedQuery = useQuery();
  const [email, onChangeEmail] = useState<string>('');
  const {
    shutdown,
  } = useIntercom();

  const onSubmit = useCallback(() => {
    forgot({email});
  }, [forgot, email]);

  const backLink = useMemo(() => {
    if (query.back === 'settings') return '/settings/account/password';
  }, [query]);

  useEffect(() => {
    shutdown()
    //track mix panel Page Viewed event
    pageViewed("Forgot Password Page")
  }, []);

  return (
    <Wrapper
      title="Forgot password?"
      subtitle="No worries, we’ll send you reset instructions."
      icon="key-01"
      linkText={backLink ? 'Back to Settings' : undefined}
      link={backLink}
      backToLogin
    >
      <Form onSubmit={onSubmit} className={styles.wrapper}>
        <Input label="Email" required value={email} errors={errors.email}
               onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeEmail(event.target.value)}
               placeholder="Enter your email" type="email" name="email"/>
        <Button full htmlType="submit" loading={loading} name="submit">Send an email</Button>
      </Form>
    </Wrapper>
  )
}

export default Forgot;
