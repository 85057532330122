import React, { FC } from 'react';
import { head } from 'const';
import { Helmet, HelmetProps } from 'react-helmet';

interface Props extends HelmetProps {

}
const Head:FC<Props> = ({ title, ...other }) => {
  const headTitle = title ? `${head} - ${title}` : head;
  return (
    <Helmet title={headTitle} {...other}/>
  )
}

export default Head;
