import { combineReducers, legacy_createStore as createStore } from 'redux';
import exampleStore from './example';
import authStore from './auth';
import enumsStore from './enums';
import uiStore from './ui';
import grantsStore from './grants';
import { devToolsEnhancer } from 'redux-devtools-extension';

const reducers = combineReducers({
  example: exampleStore,
  auth: authStore,
  enums: enumsStore,
  ui: uiStore,
  grants: grantsStore,
});

const store = createStore(reducers, devToolsEnhancer({}));

export default store;
