import {FC, useCallback, useMemo, useState} from 'react';
import styles from './editPerson.module.css';
import useGrants from 'hooks/useGrants';
import GraduatedStudentPersonForm from 'containers/forms/personForm/graduatedStudent';
import {useHistory, useParams} from 'react-router-dom';
import usePersonnel from 'hooks/usePersonnel';
import * as T from 'hooks/requestTypes';
import {personalTypes} from 'const';
import {confirm} from 'components/confirmation';
import Typo from 'components/typo';
import {Toggler, Form} from 'components/form';
import Help from 'components/help';
import {percentToFixed} from 'helpers/numbers';
import GrantFormWrapper from 'pages/grant/common/wrapper';

const CreateEditGraduatedStudents: FC = () => {
  const {grant} = useGrants();
  const history = useHistory();
  const params: Record<string, string> = useParams();

  const {
    errors, loading,
    addOrUpdateStudent,
    trackError,
    trackExit,
    trackFormStarted
  } = usePersonnel({form_page_type:'General Info'});
  const [data, onChangeData] = useState<T.GraduatedStudent>(() => {
    const value = grant.graduateStudents;
    return {
      isAccountOwner: value.isAccountOwner ?? false,
      academicTerm: value.academicTerm ?? '',
      numberOfAcademicTerms: value.numberOfAcademicTerms ?? undefined,
      fringeBenefit: value.fringeBenefit ? percentToFixed(value.fringeBenefit) : undefined,
      stipendAwardPeriod: value.stipendAwardPeriod ?? undefined,
      stipendPerYear: value.stipendPerYear ?? undefined,
      stipendForEachAcademicTerm: value.stipendForEachAcademicTerm ?? undefined,
      stipendForSummer: value.stipendForSummer ?? undefined,
      tuitionForEachAcademicTerm: value.tuitionForEachAcademicTerm ?? undefined,
    }
  });

  const onDuplicateFromProfile = useCallback(() => {
    const {author} = grant;
    const ownerIsStudent = [
      'graduateStudents',
      'undergraduateStudents'
    ].includes(author.personalReference?.kind as string);
    const ownerIsPerson = (author.personalReference && author.personalReference.id !== params.personId);
    if (author.personalReference !== undefined && (ownerIsStudent || ownerIsPerson)) {
      const personnelType = personalTypes[author.personalReference.kind];
      let link = `/grants/${grant.id}/personnel/${personnelType}/${author.personalReference?.id}/edit`;
      if (ownerIsStudent) {
        link = `/grants/${grant.id}/personnel/${personnelType}/edit`
      }
      if (ownerIsStudent && personnelType === personalTypes.graduateStudents) {
        onChangeData({
          ...data,
          isAccountOwner: true
        });
        return;
      }
      confirm({
        text: 'Another person/personnel type is marked as Account owner. Do you want to remove the mark?',
        plain: true,
        onConfirm: () => {
          history.push(link)
        },
        onCancel: () => onChangeData({
          ...data,
          isAccountOwner: false
        })
      })
      return;
    }
    onChangeData({
      ...data,
      isAccountOwner: true
    });

  }, [params, data, history, grant]);

  const onRemoveDuplicateFromProfile = useCallback(() => {
    onChangeData({
      ...data,
      isAccountOwner: false
    });
  }, [data]);

  const isEdit = useMemo(() => grant.graduateStudents.fringeBenefit !== undefined, [grant]);

  const onChange = useCallback((field: Record<string, any>) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const onSubmit = useCallback(() => {
    addOrUpdateStudent(grant.id, 'graduate-students', {
      ...data,
      numberOfAcademicTerms: Number(data.numberOfAcademicTerms),
      fringeBenefit: Number(data.fringeBenefit) / 100,
      stipendPerYear: Number(data.stipendPerYear),
      stipendForEachAcademicTerm: Number(data.stipendForEachAcademicTerm),
      stipendForSummer: Number(data.stipendForSummer),
      tuitionForEachAcademicTerm: Number(data.tuitionForEachAcademicTerm),
    }, isEdit);
  }, [data, addOrUpdateStudent, grant, isEdit]);

  return (
    <GrantFormWrapper title={(
      <div className={styles.subtitleWrapper}>
        <Typo type="h3" bold className={styles.title}>Graduate Students</Typo>
        <div className={styles.btnWrapper}>
          <Form>
            <Toggler
              label="Account owner is a graduate student"
              name="isAccountOwner"
              green
              className={styles.toggler}
              checked={data.isAccountOwner}
              onChange={data.isAccountOwner ? onRemoveDuplicateFromProfile : onDuplicateFromProfile}
            />
          </Form>
          <Help contentClassName={styles.help}>Please use this button if you are the account owner. This is
            necessary to display the “Account owner salary budget” on the Dashboard. This button can only be used
            once for all types of personnel.
          </Help>
        </div>
      </div>
    )}>
      <GraduatedStudentPersonForm edit={isEdit} errors={errors} loading={loading} data={data} onSubmit={onSubmit} onChange={onChange}
                                  trackError={trackError}
                                  trackExit={trackExit}
                                  trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  )
}

export default CreateEditGraduatedStudents;
