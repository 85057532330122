import React, {FC, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation, useHistory, Switch, Route } from 'react-router-dom';
import cx from 'classnames';
import { Button } from 'components/form';
import { ParsedQuery } from 'query-string';
import useQuery, { queryToString } from 'helpers/query';
import styles from './tabs.module.css';
import Icon from '../icon';

export type Tab = {
  label: string;
  disabled?: boolean;
  hasScroll?: boolean;
  onDelete?: () => void;
  id: string | number;
  element: ReactElement;
}

type Props = {
  list: Tab[];
  path?: string;
  fixed?: boolean;
  asRouter?: boolean;
  smallPadding?: boolean;
  name: string;
  className?: string;
  full?: boolean;
  contentClassName?: string;
  tabsClassName?: string;
  actionTitle?: any;
  action?: () => void;
  container?: boolean;
  onEnter?: (id: string | number) => void;
}

const Tabs:FC<Props> = (props: Props) => {
  const {
    list, action, actionTitle, onEnter, full,
    tabsClassName, contentClassName, className,
    smallPadding, container,
    fixed, path, asRouter, name
  } = props;
  const query:ParsedQuery = useQuery();
  const location = useLocation();
  const history = useHistory();

  const currentActiveTab = useMemo(() => {
    if (asRouter && path) {
      return list.findIndex((item: Tab) => `${path}/${item.id}` === location.pathname);
    }
    return list.findIndex((item: Tab) => item.id === query?.[name]);
  }, [list, query, name, asRouter, path, location]);

  const [ active, onChangeActive ] = useState<number>(currentActiveTab < 0 ? 0 : currentActiveTab);

  useEffect(() => {
    if (fixed) return;
    if (asRouter && path) {
      const currentActiveTabIndex = list.findIndex((item: Tab) => `${path}/${item.id}` === location.pathname);
      const currentActiveTab = list[currentActiveTabIndex];
      onChangeActive(currentActiveTabIndex < 0 ? 0 : currentActiveTabIndex);
      if (currentActiveTab?.hasScroll) {
        return;
      }
      window.scrollTo(0, 0);
      return;
    }
    if (query[name]) {
      const currentActiveTab = list.findIndex((tab: Tab) => tab.id === query?.[name]);
      if (currentActiveTab !== active) {
        onChangeActive(currentActiveTab < 0 ? 0 : currentActiveTab);
        window.scrollTo(0, 0);
      }
    }
  }, [name, fixed, list, asRouter, location, path, query]);

  const handleClick = useCallback((idx: number, id: string | number) => {
    onChangeActive(idx);
    if (onEnter) onEnter(id);
    if (asRouter && path) {
      const newLocation = path + '/' + list[idx].id
      history.push(newLocation);
      return;
    }
    const newLocation = location.pathname + '?' + queryToString(Object.assign(query, { [name]: list[idx].id }));
    history.push(newLocation);
  }, [location, onEnter, name, asRouter, path, list, history, query]);

  const handleDelete = useCallback((event: MouseEvent<HTMLElement>, tab: Tab) => {
    event.stopPropagation();
    if (tab.onDelete) tab.onDelete();
  }, []);

  return (
    <div className={cx(className, styles.wrapper)}>
      <div className={cx(styles.tabs, tabsClassName, { full: full, container, [styles.withAction]:action })}>
        {list.map((tab: Tab, idx: number) => (
          <button
            type="button" onClick={tab.disabled ? undefined : () => handleClick(idx, tab.id)}
            cypress-id={`tab-${tab.id}`}
            disabled={tab.disabled}
            className={cx(styles.tab, {
              [styles.active]: idx === active,
              [styles.disabled] : tab.disabled,
              [styles.fullTab]: full
            })}
            key={idx}
            style={full ? {width: `${100/list.length}%`} : undefined}
          >
            <span title={tab.label} className={styles.tabLabel}>{tab.label}</span>
            {tab.onDelete ? <Icon className={styles.delete} size={20} icon="x-close" onClick={(event) => handleDelete(event, tab)} /> : null}
          </button>
        ))}
        {action
          ?
          <Button size="md" className={styles.action} htmlType="button" type="mint" name={`${name}-action`} onClick={action} preIcon="plus">{actionTitle ?? 'Add'}</Button>
          : null
        }
      </div>
      <div className={cx(styles.contentWrapper, contentClassName, {[styles.smallPadding]: smallPadding})}>
        {
          asRouter ?
            (
              <Switch>
                {list.map((item: Tab, idx: number) => (
                  <Route path={`${path}/${item.id}`} key={idx}>
                    <div className={cx(styles.content, { container })}>
                      {item.element}
                    </div>
                  </Route>
                ))}
              </Switch>
            )
            : (
              list[active]?.element
                ? <div className={cx(styles.content, { container })}>{list[active].element}</div>
                : null
            )

        }
      </div>
    </div>
  )
}

export default Tabs;
