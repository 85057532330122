import { FC, useMemo } from 'react';
// hooks
import useUrl from 'hooks/useUrl';
import useEnums from 'hooks/useEnums';
// types
import {FullGrant, TransportationMethod as TransportationMethodType, Travel} from 'store/grants/types';
// components
import GroupedByMethodList from './groupedByMethodList';
import Typo from 'components/typo';
import CreateBtn from 'pages/grant/paper/card/create';
import Folder from 'pages/grant/paper/card/folder';
// styles
import styles from '../travel.module.css';
import Anchor, {TotalAnchor} from 'pages/grant/paper/card/anchor';

type Props = {
  side: FullGrant;
  trip: Travel;
  transportationMethods: Array<TransportationMethodType>;
  handleRemoveTransportationMethod: (id: string) => void;
};

const TransportationMethodsList: FC<Props> = ({
  side,
  trip,
  transportationMethods,
  handleRemoveTransportationMethod,
}) => {
  const url = useUrl();
  const { transportationMethod: transportationMethodsEnum } = useEnums();

  const groupedByMethodLists = useMemo(() => {
    return transportationMethodsEnum.map((item, idx) => {
      return {
        ...item,
        listId: `${idx}_${item.value}`,
        items: transportationMethods.filter((i) => i.method === item.value),
      };
    });
  }, [transportationMethodsEnum, transportationMethods]);

  const folderItem = useMemo(() => ({
    label: `Transportation methods`,
    type: 'transportation',
  }), []);

  const totalsActive = useMemo(() => {
    return trip.id === url.categoryId && url.section === 'transportation' && url.sectionId === 'total';
  }, [trip, url]);

  return (
    <Folder type="section" grantId={side.id} icon item={folderItem}>
      <>
        {groupedByMethodLists.map((list) => {
          return (
            <GroupedByMethodList
              key={list.listId}
              side={side}
              trip={trip}
              list={list}
              handleRemoveTransportationMethod={handleRemoveTransportationMethod}
            />
          );
        })}

        {transportationMethods.length > 0 ? (
          <TotalAnchor total={trip.travelMethodsTotalAmount} to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/transportation/total`} active={totalsActive}>
            Totals. All methods
          </TotalAnchor>
        ) : (
          <Typo className={styles.placeholder} size={14} type="div">
            No transportation methods have been created yet
          </Typo>
        )}
      </>
      <CreateBtn
        to={`/grants/${side.id}/travel/${trip.type}/${trip.id}/transportation/create`}
        name={`travel-${trip.id}-add-transportation-method`}
        side={side}
        tip="To add a method, you must save general info first"
      >
        Add a method
      </CreateBtn>
    </Folder>
  );
};

export default TransportationMethodsList;
