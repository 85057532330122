export default {
  typeOfExpense: "If you have a connecting flight where one leg is inside the US but the other leg is outside of the US, unless this flight is directly split up into two legs with two unique separate costs, this trip would fall under foreign travel.",
  planeLocation: "Remember to use the current price of items. We will automatically apply inflation to costs based on what year of the budget you budget them in under. If you know the starting and ending location of your trip, please feel free to enter the starting and ending locations of the plane trip and check the link to see if the airline you intend to take is a certified US Air Carrier. The NSF has regulations that you must use one unless an exception to the rule is met.",
  planeTotalCost: "This is not participant costs which will be budgeted for in an upcoming section and will be explained for new grant NSF grant applicant there as well. You should add up all small expenses you expect to incur related to only plane travel and write one number that represents all of those costs in the prompt",
  years: `The following check boxes have been auto-populated based on your choice from the "Travel status" tab. You can still check or uncheck the boxes here, and it will not affect what you have selected in the "Travel status" tab.`,
  aircarrier: (
    <span>
      You must use a "US flag" air carrier service to comply with NSF requirements. A US flag air carrier is an airline that the US government has certified and often times are ones based out of the US. Check the <a
      href="https://www.transportation.gov/sites/dot.gov/files/2022-03/Cert Carrier List_March 2022_0.pdf" target="_blank" rel="noreferrer">link</a> to see if there is a US air carrier that you want to use. If you do not see a US air carrier flight that matches the trip you are looking to take, please click “Verify air carrier” to check if you are eligible to use a "Non-US Air Carrier".
    </span>
  ),
  taxiSimilar: `If you need a "round trip" taxi rides, count the second leg of this trip as a second similar trip. For example, if you will have two round trips, please enter in 4 here.`,
  rideShareSimilar: `If you need a "round trip" rideshare rides, count the second leg of this trip as a second similar trip. For example, if you will have two round trips, please enter in 4 here.`,
  boat: "Make sure that you take into account the round trip cost into the price of passage if this meets the criteria of the type of travel you will be doing",
  subwayTrips: "The amount of people doing the subway trips is not included in calculations. In this input, please, provide the total number of trips you expect all personnel will take within a year",
  rentalBasePrice: `Base prices would be any amount that is needed to be paid upfront before any travel takes place. Include costs such as the base price of a helicopter rental before variable costs such as distance or time is taken into account. Insurance costs should also be included in the final value entered here under base costs`,
  charterCost: "This value will be automatically calculated if one of the calculation methods has been selected (by distance or by time)",
}
