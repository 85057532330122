import {FC, useMemo} from 'react';
import Folder, {FolderProps} from 'pages/grant/paper/card/folder';
import MaterialsAndSupplies from './materialsAndSupplies';
import Publications from './publications';
import OtherExpenses from './otherExpenses';
import OtherCostCS from './cs';
import OtherContracts from './contracts';
import OtherPayments from './incentivePayments';
import OtherSubawards from './subawards';
import ConsultantServices from './consultantServices';
import useUrl from 'hooks/useUrl';
import {useParams} from 'react-router-dom';
import {FullGrant, SidebarTotals} from 'store/grants/types';

type Props = {
  side: FullGrant;
  totals: SidebarTotals;
}

const OtherDirectCosts:FC<Props> = ({ side, totals }) => {
  const url = useUrl();
  const params: Record<string, string> = useParams();

  const items = useMemo(() => {
    return [
      {
        item: {
          label: 'Materials and Supplies',
          type: 'materials-and-supplies',
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'materials-and-supplies'
        },
        children: <MaterialsAndSupplies side={side}/>,
      },
      {
        item: {
          label: "Publications",
          type: "publications",
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'publications'
        },
        children: <Publications side={side}/>
      },
      {
        item: {
          label: "Consultant Services",
          type: "consultantServices",
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'consultantServices'
        },
        children: <ConsultantServices side={side}/>
      },
      {
        item: {
          label: 'Computer Services',
          type: 'computer-services',
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'computer-services'
        },
        children: <OtherCostCS side={side}/>
      },
      {
        item: {
          label: 'Subawards',
          type: 'subawards',
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'subawards'
        },
        children: <OtherSubawards side={side}/>
      },
      {
        item: {
          label: 'Contracts',
          type: 'contracts',
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'contracts'
        },
        children: <OtherContracts side={side}/>
      },
      {
        item: {
          label: 'Incentive Payments',
          type: 'payments',
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'payments'
        },
        children: <OtherPayments side={side}/>
      },
      {
        item: {
          label: "Other",
          type: "otherExpenses",
          open: side.id === params.id && url.category === 'otherDC' && url.subCategory === 'otherExpenses'
        },
        children: <OtherExpenses side={side}/>
      },
    ]
  }, [side, params, url]);

  return (
    <>
      {items.map((folder: FolderProps, idx: number) => {
        return (
          <Folder type="subCategory" grantId={side.id} key={idx} item={folder.item}>
            {folder.children}
          </Folder>
        );
      })}
    </>
  );
};

export default OtherDirectCosts;
