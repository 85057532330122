import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

export default function useQuery():ParsedQuery {
  return queryString.parse(useLocation().search);
}

export function queryToString(query: Record<string, any>) {
  return queryString.stringify(query, { arrayFormat: 'bracket', skipEmptyString: true });
}

export function useHash(hash: string = '#'):Record<string, string> {
  return hash.slice(1)
    .split('&')
    .map((item: string) => item.split('='))
    .reduce((result: Record<string, string>, item: string[]) => ({
      ...result,
      [item[0]]: item[1]
    }), {});
}
